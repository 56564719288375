import { useCallback, useState } from "react";
import type { DropzoneOptions } from "react-dropzone";

import type { FormFilesServiceOptions } from "../../../services/formFiles";
import useCompressor from "./useCompressor";
import useDefaultToasts from "./useDefaultToasts";
import useFileUploadService from "./useFileUploadService";

const DEFAULT_MAX_UPLOAD_SIZE = 1;

interface FileUploaderEventHandlerOptions extends FormFilesServiceOptions {
  maxImageSizeMB?: number;
}

function useFileUploader({
  uploadUrl,
  maxImageSizeMB = DEFAULT_MAX_UPLOAD_SIZE,
  onFileUploadError,
  onFileUploadedSuccess,
  onFileUploadProgress,
  onFileDeletedSuccess,
  onFileDeletedError,
  accessToken,
}: FileUploaderEventHandlerOptions) {
  const [imageIsProcessing, setImageIsProcessing] = useState(false);
  const { compress, compressHeic, fileSizeToMB } = useCompressor();
  const { errorToast } = useDefaultToasts();

  const { uploadFile, deleteFile, downloadFile } = useFileUploadService({
    uploadUrl,
    onFileUploadProgress,
    onFileDeletedSuccess,
    onFileDeletedError,
    onFileUploadedSuccess,
    onFileUploadError,
    accessToken,
  });

  const doCompressAndUploadImages = useCallback<NonNullable<DropzoneOptions["onDrop"]>>(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        errorToast("File rejected")(rejectedFiles[0].errors[0]);
        return;
      }
      Promise.all(
        acceptedFiles
          .map((file) => ({
            file,
            shouldCompress: file.type.startsWith("image") && fileSizeToMB(file) > maxImageSizeMB,
          }))
          .map(async ({ file, shouldCompress }) => {
            setImageIsProcessing(true);
            if (!shouldCompress) return Promise.resolve(file);
            if (file.type === "image/heic") return compressHeic(file, maxImageSizeMB);
            return compress(file, maxImageSizeMB);
          })
      )
        .then((compressedFiles) => {
          setImageIsProcessing(false);
          void uploadFile(compressedFiles);
        })
        .catch((error) => {
          setImageIsProcessing(false);
          errorToast(`There's a problem with the file being uploaded`)(error);
        });
    },
    [uploadFile, errorToast, maxImageSizeMB, compress, compressHeic, fileSizeToMB]
  );

  return {
    uploadFile,
    doCompressAndUploadImages,
    deleteFile,
    downloadFile,
    imageIsProcessing,
  };
}

export default useFileUploader;
