import type { ButtonProps } from "@chakra-ui/react";
import { Button, HStack, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface DefaultMenuControlsProps {
  disabled?: boolean;
  loading?: boolean;
  onCancel: () => void;
  onOk?: () => void;
  confirmButtonText?: string;
  confirmButtonType?: ButtonProps["type"];
}

export default function DefaultMenuControls({
  disabled,
  loading,
  onCancel,
  onOk,
  confirmButtonText,
  confirmButtonType,
}: DefaultMenuControlsProps) {
  const { t } = useTranslation();
  return (
    <HStack justifyContent="flex-end" spacing={3} paddingTop={3}>
      <Button variant="outline" onClick={onCancel}>
        {t("global.actions.cancel")}
      </Button>
      <Button
        colorScheme="purple"
        disabled={disabled || loading}
        width={70}
        onClick={onOk}
        type={confirmButtonType}
        isLoading={loading}
      >
        {loading ? (
          <Spinner color="white" size="sm" />
        ) : (
          confirmButtonText || t("global.actions.save")
        )}
      </Button>
    </HStack>
  );
}
