import ImageCompression from "browser-image-compression";
import heic2any from "heic2any";

function useCompressor() {
  const fileSizeToMB = (file: File) => parseFloat((file.size / 1024 / 1024).toFixed(1));

  const compress = async (file: File, maxFileSizeMB: number) => {
    return ImageCompression(file, {
      maxSizeMB: maxFileSizeMB,
      useWebWorker: true,
    }).then((compressed) => new File([compressed], compressed.name, { type: compressed.type }));
  };

  const compressHeic = async (file: File, maxFileSizeMB: number) => {
    return heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: maxFileSizeMB / fileSizeToMB(file),
    }).then((result) => new File([result as Blob], file.name, { type: "image/jpeg" }));
  };

  return { compress, fileSizeToMB, compressHeic };
}

export default useCompressor;
