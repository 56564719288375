import { format, intlFormatDistance } from "date-fns";

export default function formatDate(date: Date, options?: Record<string, string>) {
  if (!date.getMonth && typeof date.getMonth !== "function") {
    throw new Error("Date argument is not a valid Date");
  }

  return date.toLocaleString("en-DE", options);
}

export function formatDateDifferenceFromNow(date: string) {
  return intlFormatDistance(new Date(date), new Date());
}

export function formatDateShort(date: Date) {
  return format(date, "dd.MM.yyyy");
}
