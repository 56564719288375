import { Heading, HStack } from "@chakra-ui/react";
import type { ProjectPhaseFieldsFragment } from "@flows-platform/generated/graphql";
import { useTranslation } from "react-i18next";

import ProjectPhaseSelector from "./components/ProjectPhaseSelector/ProjectPhaseSelector";

interface ProjectPhaseProps {
  phase?: ProjectPhaseFieldsFragment;
}

export default function ProjectPhase({ phase }: ProjectPhaseProps) {
  const { t } = useTranslation();
  return (
    <HStack flexDirection="row" width="100%" gap="20px" alignItems="center">
      <Heading fontSize="md">{t("pages.project_instance.variables.project_phase")}</Heading>
      <ProjectPhaseSelector phase={phase} />
    </HStack>
  );
}
