import { Box, Spinner, Text, VStack } from "@chakra-ui/react";
import { useConnectedFlowQuery, useGetProjectStepsQuery } from "@flows-platform/generated/graphql";
import FlowAssignmentModal from "@flows-platform/modules/FlowTemplates/components/FlowAssignmentModal/FlowAssignmentModal";
import { FlowAssignmentVariablesProvider } from "@flows-platform/modules/Shared/providers/FlowAssignmentVariables";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ConnectedJourneyRow from "./components/ConnectedJourneyRow";

interface ConnectedFlowsViewProps {
  task: {
    id: string;
    connectedFlowId: string;
    connectedFlowInitialVariableValues: string | null;
    connectedJourneysId?: string[];
  };
  openSelf?: boolean;
}

const ConnectedFlowsView = ({ task, openSelf }: ConnectedFlowsViewProps) => {
  const [modalConfig, setModalConfig] = useState({ display: false, autoStartFlow: false });

  const { projectId } = useParams();

  const { data, loading } = useConnectedFlowQuery({
    variables: { flowId: task?.connectedFlowId || "" },
    fetchPolicy: "cache-and-network",
  });

  const { refetch: refetchProjectSteps, loading: loadingSteps } = useGetProjectStepsQuery({
    variables: {
      journeyId: projectId || "",
    },
    skip: true,
  });

  const { t } = useTranslation();

  if (!data?.oneFlow || loading || loadingSteps) return <Spinner />;

  const initialValues = JSON.parse(task?.connectedFlowInitialVariableValues || "{}");

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} w={"full"} gap={2}>
        <Text textStyle={TEXT_STYLES.lead14SemiBold} textTransform={"capitalize"}>
          {t("pages.flows.title")}
        </Text>
        <VStack w={"full"} spacing={3}>
          {task?.connectedJourneysId?.map(
            (connectedJourneyId) =>
              data.oneFlow && (
                <ConnectedJourneyRow
                  connectedJourneyId={connectedJourneyId}
                  flow={data.oneFlow}
                  setModal={setModalConfig}
                />
              )
          )}
          <ConnectedJourneyRow
            connectedJourneyId={null}
            flow={data.oneFlow}
            setModal={setModalConfig}
          />
        </VStack>
      </Box>
      <FlowAssignmentVariablesProvider flowId={data.oneFlow.id} initialValues={initialValues}>
        <FlowAssignmentModal
          flowId={data.oneFlow.id}
          refetchFlow={() => {
            refetchProjectSteps();
          }}
          taskId={task?.id}
          isOpen={modalConfig.display}
          autoStartFlow={modalConfig.autoStartFlow}
          onClose={() => {
            setModalConfig({ display: false, autoStartFlow: false });
          }}
          isInline={openSelf}
        />
      </FlowAssignmentVariablesProvider>
    </>
  );
};

export default ConnectedFlowsView;
