import type { TagProps } from "@chakra-ui/react";
import { Tag } from "@chakra-ui/react";
import type { ProjectPhaseFieldsFragment } from "@flows-platform/generated/graphql";
import { t } from "i18next";

interface ProjectPhaseLabelProps extends TagProps {
  phase?: ProjectPhaseFieldsFragment;
  fallbackText?: string;
}

export default function ProjectPhaseLabel({
  phase,
  fallbackText = t("pages.project_instance.variables.no_phase"),
  ...props
}: ProjectPhaseLabelProps) {
  return (
    <Tag
      display="inline-block"
      px={2}
      py={1}
      bg={phase?.color || "gray.400"}
      borderRadius="sm"
      color="white"
      fontSize="md"
      fontWeight="medium"
      data-testid={`flow-step-label-${phase?.id}`}
      {...props}
    >
      {phase?.name || fallbackText}
    </Tag>
  );
}
