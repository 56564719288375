import { Box, Button, Text, useDisclosure } from "@chakra-ui/react";
import Shortcut from "@flows-platform/modules/Shared/UI/atoms/Decorations/Shortcut/Shortcut";
import { SearchIcon } from "@flows-platform/theme/icons";

import SearchModal from "./SearchModal/SearchModal";

const GlobalSearch = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleModalClose = () => {
    onClose();
  };

  return (
    <Box w={"100%"} px={4}>
      <Button
        bg={"white"}
        borderWidth={"1px"}
        borderColor={"gray.200"}
        color={"gray.500"}
        fontWeight={400}
        w={"100%"}
        px={2}
        _hover={{
          borderColor: "gray.400",
          color: "gray.600",
        }}
        onClick={onOpen}
        size={"sm"}
        justifyContent={"space-between"}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"start"} gap={2}>
          <SearchIcon />
          <Text fontSize={"14px"}> Search</Text>
        </Box>
        <Shortcut>K</Shortcut>
      </Button>
      <SearchModal isOpen={isOpen} onOpen={onOpen} onClose={handleModalClose} />
    </Box>
  );
};

export default GlobalSearch;
