import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  VStack,
} from "@chakra-ui/react";
import HTMLContentRenderer from "@kwest_fe/core/src/components/HTMLContentRenderer";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import { EMAIL_SUBJECT_EXCLUDED_VARIABLE_TYPES } from "@kwest_fe/core/src/constants/email";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import type {
  JourneyStepResourceQuery,
  TodoViewMessageResourceFieldsFragment,
} from "@todo-viewer/generated/graphql";
import { useUpdateAndSendJourneyMessageClientMutation } from "@todo-viewer/generated/graphql";
import messageParent from "@todo-viewer/modules/Core/utils/messageParent";
import FormikField from "@todo-viewer/modules/Shared/components/FormikField/FormikField";
import useRedirectToNextStep from "@todo-viewer/modules/TodoView/hooks/useRedirectToNextStep";
import type { FormikProps } from "formik";
import { Form, Formik } from "formik";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import { messageValidationSchema } from "../../schema";
import TodoViewLayout from "../TodoViewLayout";
import FormikEmailField from "./FormikEmailField";

interface TodoViewMessageProps {
  isPreview?: boolean;
  resource: JourneyStepResourceQuery["getJourneyStepResource"] & {
    __typename: "MessageResourceType";
  };
}

export default function TodoViewMessage({ resource, isPreview }: TodoViewMessageProps) {
  const { t } = useTranslation();

  const [updateMessage, { loading: refreshingMessage }] =
    useUpdateAndSendJourneyMessageClientMutation();

  const { successToast, errorToast } = useDefaultToasts();
  const redirectToNextStep = useRedirectToNextStep();

  const [updateAndSendJourneyMessage] = useUpdateAndSendJourneyMessageClientMutation();

  const sendMessage = (
    messageInput: Pick<
      TodoViewMessageResourceFieldsFragment,
      "bccEmail" | "ccEmail" | "id" | "messageContent" | "replyToEmail" | "subject" | "toEmail"
    >
  ) => {
    const { id, messageContent, subject, toEmail, ccEmail, bccEmail, replyToEmail } = messageInput;
    updateAndSendJourneyMessage({
      variables: {
        input: {
          id,
          content: messageContent,
          subject,
          toEmail,
          ccEmail,
          bccEmail,
          replyToEmail,
        },
        sendMessage: true,
      },
      onCompleted(data) {
        successToast(t("pages.todo_view.toast.update_journey_message.success.title"));
        messageParent("task_completed");
        redirectToNextStep(
          data.updateAndSendJourneyMessage?.nextStep,
          resource?.journeyStep?.projectId
        );
      },
      onError(err) {
        errorToast(t("pages.todo_view.toast.update_journey_message.error.title"))(err);
      },
    });
  };
  const formRef = useRef<FormikProps<typeof initialValues>>(null);
  if (!resource) return null;
  const {
    id,
    messageContent,
    parsedMessageContent,
    subject,
    title,
    receiver,
    toEmail,
    ccEmail,
    bccEmail,
    replyToEmail,
    journeyStep,
    flowStep,
  } = resource;

  const initialValues = {
    subject,
    messageContent,
    parsedMessageContent,
    toEmail,
    ccEmail,
    bccEmail,
    replyToEmail,
    showCc: !!ccEmail,
    showBcc: !!bccEmail,
  };

  const learnerValue = receiver
    ? `${receiver.firstName}_${receiver.lastName}`
    : t("global.recipient");

  const variableStore = JSON.parse(journeyStep?.variableStore || flowStep?.variableStore || "{}");

  const refreshPreview = (setValue: (field: string, value: string) => void) => {
    if (formRef.current?.values.messageContent) {
      updateMessage({
        variables: {
          input: {
            id,
            content: formRef.current?.values.messageContent,
          },
          sendMessage: false,
        },
        onCompleted: (data) => {
          setValue(
            "parsedMessageContent",
            data.updateAndSendJourneyMessage?.message?.parsedMessageContent || ""
          );
        },
      });
    }
  };

  return (
    <TodoViewLayout
      noHeaderPadding
      isCompleted={!!journeyStep?.completedAt}
      heading={
        <VStack w="full" align="start" px={[2, 5]} pb={8} borderBottomWidth={1}>
          <Heading size="md">{title}</Heading>
          <Tag colorScheme="blue">{`@${learnerValue}`}</Tag>
        </VStack>
      }
    >
      <VStack align="stretch" w="full">
        <Formik
          initialValues={initialValues}
          innerRef={formRef}
          onSubmit={(values) => {
            const { showBcc, showCc, ...updatedValues } = values;
            sendMessage({ id, ...updatedValues });
          }}
          validationSchema={messageValidationSchema}
          validateOnMount
        >
          {({ isSubmitting, isValid, values: { showCc, showBcc }, setFieldValue }) => (
            <Form>
              <VStack spacing={5} w="full">
                <VStack spacing={1} w="full" alignItems="flex-start">
                  <FormikField
                    name="toEmail"
                    label="To"
                    required
                    showOptionalIndicator
                    renderElement={() => (
                      <RichTextEditor
                        name="toEmail"
                        isSingleLine
                        hideToolbar
                        variables={variableStore}
                        emailFieldsOnly
                      />
                    )}
                  />
                  <HStack pt={2} spacing={3} color="blue.500" fontWeight="medium">
                    <Link
                      fontSize="md"
                      onClick={() => {
                        if (showCc) {
                          setFieldValue("ccEmail", "");
                        }
                        setFieldValue("showCc", !showCc);
                      }}
                    >
                      {!showCc ? "Cc" : t("forms.hide_cc.label")}
                    </Link>
                    <Link
                      fontSize="md"
                      onClick={() => {
                        if (showBcc) {
                          setFieldValue("bccEmail", "");
                        }
                        setFieldValue("showBcc", !showBcc);
                      }}
                    >
                      {!showBcc ? "Bcc" : t("forms.hide_bcc.label")}
                    </Link>
                  </HStack>
                </VStack>
                {showCc && (
                  <FormikEmailField
                    label="Cc"
                    name="ccEmail"
                    variables={variableStore}
                    emailFieldsOnly
                  />
                )}
                {showBcc && (
                  <FormikEmailField
                    label="Bcc"
                    name="bccEmail"
                    variables={variableStore}
                    emailFieldsOnly
                  />
                )}

                <FormikEmailField
                  label={t("forms.reply_to_email.label")}
                  name="replyToEmail"
                  variables={variableStore}
                  emailFieldsOnly
                />
                <FormikEmailField
                  label={t("forms.subject.label")}
                  name="subject"
                  variables={variableStore}
                  excludedVariableTypes={EMAIL_SUBJECT_EXCLUDED_VARIABLE_TYPES}
                />
                <Divider />
                <Tabs w={"full"} variant={"enclosed-colored"}>
                  <TabList>
                    <Tab
                      onClick={() => {
                        refreshPreview(setFieldValue);
                      }}
                    >
                      {t("global.actions.preview")}
                    </Tab>
                    <Tab>{t("global.actions.edit")}</Tab>
                  </TabList>

                  <TabPanels justifyContent={"start"}>
                    <TabPanel>
                      <Box
                        bgColor={refreshingMessage ? "bg.gray.500" : undefined}
                        borderWidth={1}
                        p={4}
                      >
                        {refreshingMessage ? (
                          <Flex alignItems={"center"} justifyContent={"center"} py={8}>
                            <Spinner />
                          </Flex>
                        ) : (
                          <HTMLContentRenderer htmlContent={parsedMessageContent || ""} />
                        )}
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <RichTextEditor name="messageContent" variables={variableStore} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>

                <Button
                  width="full"
                  colorScheme="purple"
                  type="submit"
                  leftIcon={<CheckIcon />}
                  isLoading={isSubmitting}
                  isDisabled={!isValid || isSubmitting || isPreview}
                >
                  {t("global.actions.save_and_send")}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </VStack>
    </TodoViewLayout>
  );
}
