import { CloseIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tooltip,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import shouldExecuteDragMove from "@flows-platform/utils/shouldExecuteDragMove";
import { KebabIcon } from "@kwest_fe/core/src/theme/icons";
import resolveObjectKey from "@kwest_fe/core/src/utils/resolveObjectKey";
import { useFormikContext } from "formik";
import { throttle } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { AiOutlinePlus as PlusIcon } from "react-icons/ai";
import { CgFileDocument as FileIcon } from "react-icons/cg";
import { FiCopy as DuplicateIcon, FiTrash2 as DeleteIcon } from "react-icons/fi";
import { MdDragIndicator as DragIndicator } from "react-icons/md";
import { v4 as uuidv4 } from "uuid";

import FormikField from "../FormikField/FormikField";
import ResizableTextarea from "../ResizableTextarea/ResizableTextarea";
import TodoItemOuterWrapper from "../StaticTodoItem/components/TodoItemOuterWrapper/TodoItemOuterWrapper";
import type { TodoItemTypeOptionalResource } from "../StaticTodoItem/constants/types";

interface EditableTodoItemProps {
  parent: string;
  todoItem: TodoItemTypeOptionalResource;
  index: number;
  hideResource?: boolean;
}

function EditableTodoItem({
  parent,
  todoItem,
  index,
  hideResource = false,
}: EditableTodoItemProps) {
  const { id, name, description, resourceUrl, resourceDisplayName } = todoItem;

  const initialFocusRef = useRef<HTMLInputElement>(null);
  const itemRef = useRef<HTMLDivElement>(null);
  const [resourceUrlValue, setResourceUrl] = useState("");
  const [resourceDisplayNameValue, setResourceDisplayName] = useState("");
  const { isOpen: isPopoverOpen, onToggle: onTogglePopover } = useDisclosure();

  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const parentValue = resolveObjectKey(values, parent);
  const todoItemPath = `${parent}[${index}]`;

  const { t } = useTranslation();

  const [{ opacity }, drag, dragPreview] = useDrag({
    item: () => ({
      id,
      index,
      name,
      description,
      resourceUrl,
      resourceDisplayName,
    }),
    type: "todo-item",
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.25 : 1,
    }),
    options: {
      dropEffect: "move",
    },
  });

  const [, drop] = useDrop({
    accept: ["todo-item"],
    hover: throttle((draggedStep, monitor) => {
      if (!itemRef.current) {
        return;
      }

      const dragIndex = draggedStep.index;
      const hoverIndex = index;

      if (shouldExecuteDragMove(monitor, itemRef, dragIndex, hoverIndex)) {
        const items = Array.from(parentValue);
        items.splice(dragIndex, 1);
        items.splice(hoverIndex, 0, draggedStep);

        setFieldValue(parent, items);

        /**
         * Note: we're mutating the monitor item here!
         * Generally it's better to avoid mutations,
         * but it's good here for the sake of performance
         * to avoid expensive index searches.
         */
        draggedStep.index = hoverIndex; // eslint-disable-line
      }
    }, 300),
  });

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  });

  drag(drop(itemRef));

  /**
   * Submit resource fields from within popover
   */
  const submitResourceFields = () => {
    onTogglePopover();

    setFieldValue(todoItemPath, {
      ...todoItem,
      resourceUrl: resourceUrlValue,
      resourceDisplayName: resourceDisplayNameValue,
    });
  };

  return (
    <TodoItemOuterWrapper opacity={opacity} ref={itemRef} padding={2}>
      <IconButton
        variant="ghost"
        aria-label="Move flow step"
        cursor="grab"
        color="gray.300"
        icon={<DragIndicator size={20} />}
        size="sm"
        alignSelf="center"
      />
      <TableContainer w="full">
        <Table w="full" variant="unstyled" cellPadding={0} cellSpacing={0}>
          <Tbody>
            <Tr>
              <Td w="40px" padding={1}>
                <Checkbox size="lg" isReadOnly autoComplete="off" />
              </Td>
              <Td padding={1}>
                <FormikField
                  name={`${parent}[${index}].name`}
                  variant="borderOnHover"
                  placeholder={t("pages.todo_list.form.name.placeholder")}
                  renderElement={(props) => (
                    <Input {...props} height={8} px={2} autoComplete="off" />
                  )}
                />
              </Td>
            </Tr>
            <Tr>
              <Td padding={1} />
              <Td padding={1}>
                <FormikField
                  renderElement={(props) => (
                    <ResizableTextarea {...props} resize="none" variant="borderOnHover" px={2} />
                  )}
                  name={`${parent}[${index}].description`}
                  placeholder={t("pages.todo_list.form.description.placeholder")}
                />
                {!hideResource &&
                  (resourceUrl ? (
                    <HStack>
                      <Button
                        onClick={() => window.open(resourceUrl, "_blank")}
                        colorScheme="purple"
                        variant="ghost"
                        leftIcon={<FileIcon />}
                      >
                        {resourceDisplayName || t("pages.todo_list.actions.link_to_resource")}
                      </Button>

                      <Tooltip
                        hasArrow
                        placement="top"
                        label={t("pages.todo_list.tooltips.remove_resource")}
                      >
                        <IconButton
                          aria-label="Remove resource"
                          size="xs"
                          variant="ghost"
                          icon={<CloseIcon />}
                          onClick={() => {
                            setFieldValue(todoItemPath, {
                              ...todoItem,
                              resourceUrl: "",
                              resourceDisplayName: "",
                            });
                          }}
                        />
                      </Tooltip>
                    </HStack>
                  ) : (
                    <Popover
                      isOpen={isPopoverOpen}
                      initialFocusRef={initialFocusRef}
                      placement="bottom-start"
                    >
                      <PopoverTrigger>
                        <Button
                          variant="ghost"
                          colorScheme="purple"
                          leftIcon={<PlusIcon />}
                          onClick={onTogglePopover}
                        >
                          {t("pages.todo_list.actions.link_to_resource")}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent zIndex={1}>
                        <PopoverBody>
                          <VStack>
                            <Input
                              ref={initialFocusRef}
                              value={resourceUrlValue}
                              onBlur={() => {
                                setFieldTouched(`${todoItemPath}.resourceUrl`);
                              }}
                              placeholder={t("pages.todo_list.form.resource_url.placeholder")}
                              onChange={(e) => {
                                setResourceUrl(e.target.value);
                              }}
                            />

                            <Input
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  submitResourceFields();
                                }
                              }}
                              value={resourceDisplayNameValue}
                              placeholder={t("pages.todo_list.form.resource_name.placeholder")}
                              onChange={(e) => {
                                setResourceDisplayName(e.target.value);
                              }}
                            />
                          </VStack>
                        </PopoverBody>
                        <PopoverFooter display="flex" justifyContent="center">
                          <Button
                            w="full"
                            variant="ghost"
                            colorScheme="purple"
                            onClick={submitResourceFields}
                          >
                            {t("global.actions.done")}
                          </Button>
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  ))}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Menu>
        <MenuButton as={IconButton} icon={<KebabIcon />} size="sm" />
        <MenuList>
          <MenuItem
            icon={<DuplicateIcon />}
            onClick={() => {
              const newItems = Array.from(parentValue);
              newItems.splice(index + 1, 0, { ...todoItem, id: uuidv4() });
              setFieldValue(parent, newItems);
            }}
          >
            {t("pages.todo_list.actions.duplicate_item")}
          </MenuItem>
          <MenuItem
            color="red.500"
            icon={<DeleteIcon />}
            onClick={() => {
              const newItems = Array.from(parentValue);
              newItems.splice(index, 1);
              setFieldValue(parent, newItems);
            }}
          >
            {t("pages.todo_list.actions.delete_item")}
          </MenuItem>
        </MenuList>
      </Menu>
    </TodoItemOuterWrapper>
  );
}

export default EditableTodoItem;
