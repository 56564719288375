import { Box, Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import type { AllJourneysQuery } from "@flows-platform/generated/graphql";
import FlowsTableRow from "@flows-platform/modules/FlowsOverview/components/FlowsTableRow";
import InfiniteScrollerWithAutoLoad from "@flows-platform/modules/Shared/UI/layout/InfiniteScrollerWithAutoLoad";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import { useTranslation } from "react-i18next";
import { HiOutlineCursorClick as CursorIcon } from "react-icons/hi";

import { useMyMembership } from "../Core/providers/MyMembershipProvider";
import TablePageLayout from "../Shared/UI/layout/MainLayout/components/TablePageLayout/TablePageLayout";

const COLUMN_WIDTH = "150px";

interface FlowsPageProps {
  allJourneys: AllJourneysQuery["allJourneys"];
  fetchMoreData: () => void;
  refresh: () => void;
  totalRetrieved: number;
}

export default function FlowsOverviewPage({
  allJourneys,
  fetchMoreData,
  refresh,
  totalRetrieved,
}: FlowsPageProps) {
  const { t } = useTranslation();

  const { myMembership } = useMyMembership();

  const getPlaceholder = (message: string) => (
    <Box p={4} w="full" alignItems="center">
      <Placeholder type={"error"} icon={<CursorIcon />} iconSize="lg" description={message} />
    </Box>
  );

  const getRows = () => {
    return allJourneys?.results.map(
      (journey, index) =>
        journey && (
          <FlowsTableRow
            key={journey?.id}
            journey={journey}
            isLastRow={index === allJourneys.results.length - 1}
          />
        )
    );
  };

  return (
    <TablePageLayout title={t("pages.flows.title")} overflow={false}>
      {!allJourneys?.total && getPlaceholder(t("pages.flows.placeholders.flow_not_assigned"))}
      <InfiniteScrollerWithAutoLoad
        refresh={refresh}
        height={"92vh"}
        fetchMoreData={fetchMoreData}
        hasMore={Boolean(allJourneys?.more)}
        totalRetrieved={totalRetrieved}
        scrollThreshold={0.98}
      >
        <TableContainer w="100%">
          <Table variant="simple">
            <Thead mb={4}>
              <Tr>
                <Th minW={COLUMN_WIDTH}>{t("pages.flows.table.headers.flow")}</Th>
                <Th minW={COLUMN_WIDTH}>{t("pages.flows.table.headers.instance_name")}</Th>
                <Th minW={COLUMN_WIDTH}>{t("pages.flows.table.headers.participants")}</Th>
                <Th minW={COLUMN_WIDTH}>{t("pages.flows.table.headers.completed_todo_items")}</Th>
                {myMembership?.isAdmin && <Th />}
              </Tr>
            </Thead>

            <Tbody>{getRows()}</Tbody>
          </Table>
        </TableContainer>
      </InfiniteScrollerWithAutoLoad>
    </TablePageLayout>
  );
}
