import { FlowStepTypeEnum } from "@core/generated/graphql";

export const dateFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

export const CLICKABLE_FLOW_STEPS = [
  FlowStepTypeEnum.Form,
  FlowStepTypeEnum.List,
  FlowStepTypeEnum.Task,
  FlowStepTypeEnum.Approval,
  FlowStepTypeEnum.Email,
];
