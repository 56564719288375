import type { VariableType } from "@core/types";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";

type VariableStore = Record<string, VariableType>;

interface VariableStoreContextInterface {
  variableStore: VariableStore;
}

const VariableStoreContext = createContext<VariableStoreContextInterface | null>(null);

interface VariableStoreProviderProps {
  variables: VariableStore;
}

export function VariableStoreProvider({
  children,
  variables,
}: PropsWithChildren<VariableStoreProviderProps>) {
  const value = useMemo(() => ({ variableStore: variables }), [variables]);
  return <VariableStoreContext.Provider value={value}>{children}</VariableStoreContext.Provider>;
}

export function useVariableStore() {
  const context = useContext(VariableStoreContext);
  if (context === undefined) {
    throw new Error("useVariableStore must be used within a VariableStoreProvider");
  }
  return context;
}
