import { useApolloClient } from "@apollo/client";
import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import {
  FlowStepTypeEnum,
  useApprovalQuery,
  useUpdateApprovalClientMutation,
} from "@flows-platform/generated/graphql";
import { FlowStepObserver } from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import useFormObserver from "@flows-platform/modules/Shared/hooks/useFormObserver";
import omitFields from "@flows-platform/utils/omitFields";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

import FlowStepResourceLoaderContainer from "../../../../../FlowStepResourceLoaderContainer/FlowStepResourceLoaderContainer";
import { useFlowEditorDrawer } from "../../../../providers/FlowEditorDrawerProvider";
import ApprovalEditor from "./ApprovalEditor";

interface ApprovalEditorContainerProps {
  initialFormValuesLoaded: boolean;
}

function ApprovalEditorContainer({ initialFormValuesLoaded }: ApprovalEditorContainerProps) {
  const client = useApolloClient();
  const { flowStep } = useFlowEditorDrawer() || {};
  const { readFlowFromCache } = useFlowEditor();
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [updateApproval] = useUpdateApprovalClientMutation({
    onCompleted() {
      successToast(t("pages.flow_step.toast.update_approval.success.title"));
    },
    onError: errorToast(t("pages.flow_step.toast.update_approval.error.title")),
  });

  const currentFlow =
    flowStep?.flow &&
    readFlowFromCache({
      client,
      flowId: flowStep.flow.id,
    });

  useFormObserver(
    new FlowStepObserver({
      fields: ["approval"],
      flowStepType: FlowStepTypeEnum.Approval,
      async onChanged(modifiedValues) {
        if (!flowStep?.approvalId) return null;
        const { approval } = modifiedValues;
        return updateApproval({
          variables: {
            input: {
              id: flowStep?.approvalId,
              ...approval,
            },
          },
        });
      },
    })
  );

  return (
    <FlowStepResourceLoaderContainer
      query={useApprovalQuery}
      initialValuesKey="approval"
      queryOptions={{ variables: { approvalId: flowStep?.approvalId } }}
      loadingErrorMessage="Error loading approval contents"
      resolveInitialValue={(data) => ({
        ...(data?.getApproval ? omitFields(data?.getApproval, ["returnToStep", "__typename"]) : {}),
        returnToStepId: data?.getApproval?.returnToStep?.id,
      })}
      initialFormValuesLoaded={initialFormValuesLoaded}
    >
      <ApprovalEditor
        flowSteps={
          currentFlow?.orderedFlowSteps
            ? currentFlow.orderedFlowSteps
                .filter((step) => step?.id !== flowStep?.id)
                .map((step) => ({
                  label: step?.name || "Untitled",
                  value: String(step?.id),
                }))
            : []
        }
      />
    </FlowStepResourceLoaderContainer>
  );
}

export default ApprovalEditorContainer;
