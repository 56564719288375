import { Modal, ModalBody, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import SimpleLoaderWithMessage from "@kwest_fe/core/src/components/UI/molecules/display/SimpleLoaderWithMessage";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import ModalFooterTemplate from "./components/ModalFooterTemplate/ModalFooterTemplate";
import ModalHeaderTemplate from "./components/ModalHeaderTemplate/ModalHeaderTemplate";
import TriggerVariablesInput from "./components/TriggerVariablesInput/TriggerVariablesInput";
import FlowAssignmentFormSchema from "./schema";

interface FlowAssignmentModalProps {
  autoStartFlow: boolean;
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  subtitle: string | undefined;
  formInitialValues: Record<string, any>;
  triggerVariables: Record<string, any>;
  requireCustomInstanceName: boolean;
  onSubmit: (values: Record<string, any>, reset: () => void) => void;
}

const FlowAssignmentModalUI = ({
  isOpen,
  isLoading,
  onClose,
  subtitle = "",
  formInitialValues,
  triggerVariables,
  requireCustomInstanceName,
  onSubmit,
}: FlowAssignmentModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="50em">
        <ModalHeaderTemplate title={t("global.actions.start_flow")} subtitle={subtitle} />
        <Formik
          initialValues={formInitialValues}
          validationSchema={FlowAssignmentFormSchema(triggerVariables)}
          onSubmit={(values, actions) => {
            onSubmit(values, () => {
              actions.resetForm();
            });
          }}
        >
          {({ submitForm, isValid }) => (
            <>
              {!!Object.keys(triggerVariables).length || requireCustomInstanceName ? (
                <TriggerVariablesInput
                  triggerVariables={triggerVariables}
                  requireCustomInstanceName={!!requireCustomInstanceName}
                />
              ) : (
                <ModalBody>
                  {isLoading ? (
                    <SimpleLoaderWithMessage w="full" />
                  ) : (
                    <Text fontSize="md">{t("modals.flow_assignment.start_flow_message")}</Text>
                  )}
                </ModalBody>
              )}
              <ModalFooterTemplate
                onCancel={onClose}
                onConfirm={submitForm}
                isLoading={isLoading}
                isDisabled={!isValid || isLoading}
              />
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default FlowAssignmentModalUI;
