import type { ButtonProps, IconButtonProps } from "@chakra-ui/react";
import { Button, HStack } from "@chakra-ui/react";

interface LabelledIconButtonProps extends ButtonProps {
  icon: IconButtonProps["icon"];
  spacing?: number;
}

export default function LabelledIconButton({
  icon,
  spacing = 1,
  children,
  minW = "auto",
  ...props
}: LabelledIconButtonProps) {
  const defaultPadding = children ? 2 : 1;
  return (
    <Button {...props} px={props.px || defaultPadding} minW={minW}>
      <HStack spacing={spacing}>
        {icon}
        {children}
      </HStack>
    </Button>
  );
}
