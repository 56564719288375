import * as Sentry from "@sentry/react";
import FontFaceObserver from "fontfaceobserver";
import { usePostHog } from "posthog-js/react";
import type { PropsWithChildren } from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import type { ConnectionOpen } from "stream-chat";
import { useChatContext } from "stream-chat-react";
import type { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";

import {
  useFetchStreamTokenClientLazyQuery,
  useMyMembershipQuery,
  useMyWorkspaceQuery,
} from "../../../generated/graphql";

export default function ConfigurationProvider({ children }: Readonly<PropsWithChildren>) {
  const posthog = usePostHog();
  const { client } = useChatContext();

  const { data: myMembership } = useMyMembershipQuery();
  const { data: myWorkspace } = useMyWorkspaceQuery();
  const location = useLocation();

  const [, setLoginData] = useState<ConnectionOpen<DefaultStreamChatGenerics> | null>(null);

  const [fetchStreamChatToken] = useFetchStreamTokenClientLazyQuery({
    onCompleted: async (data) => {
      const userData = await client.connectUser(
        {
          id: myMembership?.myMembership?.id || "",
          name: `${myMembership?.myMembership?.firstName || ""} ${
            myMembership?.myMembership?.lastName || ""
          }`,
        },
        data.fetchStreamChatToken
      );
      if (userData) setLoginData(userData);
    },
  });

  useEffect(() => {
    const fontObserver = new FontFaceObserver("Material Symbols Rounded");

    fontObserver.load(null, 10000).then(() => {
      document.documentElement.className += " fonts-loaded";
    });
  }, []);

  useEffect(() => {
    if (myMembership) {
      const { myMembership: user } = myMembership;

      // Identify user in Sentry
      Sentry.setUser({
        id: user?.id,
        email: user?.workspaceEmail,
        username: user?.firstName,
      });

      // Identify user in PostHog
      posthog?.identify(user?.id, {
        email: user?.workspaceEmail,
        name: user?.firstName,
        lastName: user?.lastName,
        company: myWorkspace?.myWorkspace?.name || user?.workspaceEmail.split("@")[1] || "Unknown",
      });
      fetchStreamChatToken();
    }
    return () => {
      client.disconnectUser();
    };
  }, [posthog, myMembership]);

  useEffect(() => {
    posthog?.capture("$pageview");
  }, [location, posthog]);

  return <>{children}</>;
}
