import { Link, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function DisabledView() {
  const { t } = useTranslation();
  return (
    <VStack p={6} spacing={2} align="start">
      <Text>{t("pages.flows_templates.http_request_resource.assistance.configured_by_kwest")}</Text>
      <Text>
        {" "}
        {t("pages.flows_templates.http_request_resource.assistance.email_contact")}{" "}
        <Link href="mailto:team@getkwest.com">team@getkwest.com</Link>{" "}
        {t("pages.flows_templates.http_request_resource.assistance.phone_contact")}{" "}
        <Link href="tel:+493020459784">+49 (0) 30 204 597 84</Link>
      </Text>
    </VStack>
  );
}
