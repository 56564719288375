import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import TablePageLayout from "@flows-platform/modules/Shared/UI/layout/MainLayout/components/TablePageLayout/TablePageLayout";
import { useTranslation } from "react-i18next";

import InstallerTodosBoard from "./components/InstallerTodosBoard/InstallerTodosBoard";
import InstallerTodosUpcomingBoard from "./components/InstallerTodosUpcomingBoard/InstallerTodosUpcomingBoard";

export default function TodosInstallerView() {
  const { t } = useTranslation();
  return (
    <TablePageLayout title={t("pages.inbox.title")}>
      <Tabs colorScheme="purple" isFitted>
        <TabList>
          <Tab fontWeight={700}> {t("pages.inbox.table.tabs.today")} </Tab>
          <Tab fontWeight={700}> {t("pages.inbox.table.tabs.upcoming")} </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <InstallerTodosBoard />
          </TabPanel>
          <TabPanel p={0}>
            <InstallerTodosUpcomingBoard />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </TablePageLayout>
  );
}
