import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";

import useAllJourneysQueryWithPagination from "../Shared/hooks/useAllJourneysQueryWithPagination";
import FlowsOverviewPage from "./FlowsOverviewPage";

const DEFAULT_PAGE_LIMIT = 50;

export default function FlowsContainer() {
  const { data, loading, error, fetchMoreData, refetch, totalRetrieved } =
    useAllJourneysQueryWithPagination({
      variables: {
        filters: JSON.stringify({
          is_project: false,
        }),
        pagination: {
          limit: DEFAULT_PAGE_LIMIT,
          offset: 0,
        },
      },
      fetchPolicy: "cache-and-network",
    });

  if (loading) return <LoadingSpinnerOverlay />;

  if (error)
    return (
      <Placeholder type="error" header="Something went wrong while trying to fetch the flows" />
    );

  return (
    <ErrorBoundary>
      <FlowsOverviewPage
        allJourneys={data?.allJourneys}
        fetchMoreData={fetchMoreData}
        totalRetrieved={totalRetrieved}
        refresh={() => {
          void refetch();
        }}
      />
    </ErrorBoundary>
  );
}
