import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@chakra-ui/react";
import VerifyEmailPage from "@kwest_fe/core/src/components/apollo/VerifyEmailPage";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import type { PropsWithChildren } from "react";
import { MdArrowRightAlt as RightArrowIcon } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import Auth0Wrapper from "./Auth0Wrapper";

// inspired by https://auth0.com/docs/libraries/auth0-react
// this ensures that Apollo has the AccessToken from Auth0,
// as well as that Auth0 authentication process has finished loading.

const Auth0ErrorEnum = {
  UnverifiedEmail: "UNVERIFIED_EMAIL",
};

interface LoadingWrapperProps {
  graphQLEndpoint: string;
}

export default function LoadingWrapper({
  children,
  graphQLEndpoint,
}: PropsWithChildren<LoadingWrapperProps>) {
  const { isLoading, error } = useAuth0();
  const navigate = useNavigate();

  /**
   * If there is an error, also clear any URL state,
   * to avoid ending up with an invalid state error, which will
   * occur if there is a state param in the URL without a corresponding
   * state cookie.
   */

  if (error) {
    // Clean up auth0 params to prevent invalid state
    window.history.pushState({}, "", "/");

    if (error.message === Auth0ErrorEnum.UnverifiedEmail) {
      return (
        <VerifyEmailPage
          heading="Verify your email address"
          message={
            <>
              To confirm your email address, <b>please click on the link</b> in the email we sent
              you.
            </>
          }
          actions={
            <>
              <Button
                onClick={() => {
                  navigate(0);
                }}
                colorScheme="purple"
              >
                Already done? Continue to Kwest
              </Button>

              <Link to="/logout">
                <Button rightIcon={<RightArrowIcon />} colorScheme="gray" variant="ghost">
                  Logout
                </Button>
              </Link>
            </>
          }
        />
      );
    }
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <LoadingSpinnerOverlay />;
  }

  return <Auth0Wrapper graphQLEndpoint={graphQLEndpoint}>{children}</Auth0Wrapper>;
}
