import { Button, Divider, HStack, Spacer, Tag, Text, VStack } from "@chakra-ui/react";
import { useConnectSlackUserClientMutation } from "@flows-platform/generated/graphql";
import { ColouredSlackIcon } from "@flows-platform/theme/icons";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

import type { SanitizedMembershipData } from "./types";

interface SlackConnectionIndicatorProps {
  membershipData: SanitizedMembershipData;
}

function SlackConnectionIndicator({ membershipData }: SlackConnectionIndicatorProps) {
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [connectSlackUser, connectSlackUserResult] = useConnectSlackUserClientMutation({
    variables: {
      membershipId: membershipData.id,
    },
    onCompleted() {
      successToast(
        t("modals.membership.slack.toast.success.title"),
        t("modals.membership.slack.toast.success.description")
      );
    },
    onError() {
      errorToast(t("modals.membership.slack.toast.error.title"))(
        new Error(t("modals.membership.slack.toast.error.description"))
      );
    },
  });

  const hasSlackIntegration = !!membershipData.slackId;

  return (
    <VStack borderWidth={1} borderRadius="lg" paddingX={5} paddingY={3} spacing={3}>
      <HStack width="full">
        <ColouredSlackIcon />
        <Text fontSize="sm" color="gray.600" fontWeight="medium" paddingRight={3}>
          {t("modals.membership.slack.text")}
        </Text>
        <Tag size="sm" colorScheme={hasSlackIntegration ? "green" : "orange"}>
          {hasSlackIntegration
            ? t("modals.membership.slack.status.connected")
            : t("modals.membership.slack.status.not_connected")}
        </Tag>
        {!hasSlackIntegration && (
          <>
            <Spacer />
            <Button
              size="sm"
              onClick={async () => connectSlackUser()}
              isLoading={connectSlackUserResult.loading}
              disabled={connectSlackUserResult.loading}
              colorScheme="purple"
            >
              {t("modals.membership.slack.actions.retry")}
            </Button>
          </>
        )}
      </HStack>
      {!hasSlackIntegration && (
        <>
          <Divider />
          <Text fontSize="sm" color="gray.600">
            {t("modals.membership.slack.info_text")}
          </Text>
        </>
      )}
    </VStack>
  );
}

export default SlackConnectionIndicator;
