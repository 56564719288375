import { Box, Heading, Text } from "@chakra-ui/react";
import type { GetInstallionNotesQueryResult } from "@flows-platform/generated/graphql";
import TruncatedText from "@flows-platform/modules/Shared/UI/atoms/Text/TruncatedText/TruncatedText";
import Avatar from "@kwest_fe/core/src/components/UI/atoms/Avatar";
import parse from "html-react-parser";

import mapRecipientName from "./mapRecipientName";

interface NotesPanelProps {
  notes: GetInstallionNotesQueryResult["data"];
}
const NotesPanel = ({ notes }: NotesPanelProps) => {
  return (
    <Box display={"flex"} flexDirection={"column"} overflowY={"auto"} h={"100%"}>
      {notes?.getInstallionJourneyNotes ? (
        notes.getInstallionJourneyNotes.map((note) => {
          return note ? (
            <Box
              key={`${note.sender}-${note.timestamp}`}
              display={"flex"}
              flexDirection={"column"}
              border={"1px solid"}
              borderColor={"gray.200"}
              borderRadius={"md"}
              p={2}
              mt={2}
            >
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box display={"flex"} alignItems={"center"} gap={2}>
                  <Box display={"flex"} alignItems={"center"} gap={1}>
                    <Avatar size="sm" name={note.sender || "NN"} />
                    <Heading size="sm" color="gray.700" fontSize="sm">
                      {note.sender}
                    </Heading>
                  </Box>
                  <Box display={"flex"} fontSize="xs">
                    {note?.tags?.map((tag) => {
                      return tag ? (
                        <Box bgColor={tag.color || "white"} px={2} key={tag?.label} rounded={"md"}>
                          <TruncatedText maxWidth={{ base: "100px", md: undefined }}>
                            #{tag.label}
                          </TruncatedText>
                        </Box>
                      ) : (
                        <></>
                      );
                    })}
                  </Box>
                </Box>
                <Heading size="xs" color="gray.400" fontSize="xs" ml={2}>
                  {new Date(note.timestamp).toDateString()}
                </Heading>
              </Box>
              {note?.recipients && note.recipients.length > 0 && (
                <Heading size="sm" color="gray.700" fontSize="sm" pt={1} px={2}>
                  to: {note.recipients.map((recipient) => mapRecipientName(recipient)).join(", ")}
                </Heading>
              )}
              <Text size="sm" color="gray.700" fontSize="sm" py={2}>
                {note.content ? parse(note.content) : "This note has no content"}
              </Text>
            </Box>
          ) : (
            <></>
          );
        })
      ) : (
        <Box display={"flex"} justifyContent={"center"} py={6}>
          <Text> No Notes yet</Text>
        </Box>
      )}
    </Box>
  );
};

export default NotesPanel;
