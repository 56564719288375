import { useApolloClient } from "@apollo/client";
import type { BoxProps } from "@chakra-ui/react";
import { FlowStepTypeEnum } from "@flows-platform/generated/graphql";
import shouldExecuteDragMove from "@flows-platform/utils/shouldExecuteDragMove";
import type { FlowStepMode, FlowStepPickableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import { FlowStepModeEnum } from "@kwest_fe/core/src/enums/flowStep";
import { useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useParams } from "react-router-dom";

import { useFlowEditor } from "../../../../../../context/FlowEditor";
import type { OneOrderedFlowStep } from "../../../../../../types";
import useGetDragConfig from "../../../../hooks/useGetDragConfig";
import type { CollectedProps, DragObject, DropResult } from "../../constants/types";
import FlowStepInCanvas from "./components/FlowStepInCanvas/FlowStepInCanvas";
import FlowStepPickable from "./components/FlowStepPickable/FlowStepPickable";
import { flowStepTypesAndPickables } from "./constants/constants";
import type { PickableStep, PreviewStep } from "./constants/types";

type FlowStepProps = Omit<BoxProps, "onClick" | "opacity"> & {
  mode?: FlowStepMode;
  step: NonNullable<OneOrderedFlowStep> | PickableStep | PreviewStep;
  index?: number;
  draggable?: boolean;
  hidePeriodSelector?: boolean;
  showPhases?: boolean;
  onClick?: (step: OneOrderedFlowStep) => void;
};

function FlowStep({
  mode = "canvas",
  step,
  draggable = true,
  hidePeriodSelector = false,
  showPhases = false,
  index = 0,
  onClick = (clickedStep) => {
    return clickedStep;
  },
  ...rest
}: FlowStepProps) {
  const { updateStep } = useFlowEditor();
  const ref = useRef(null);
  const client = useApolloClient();
  const { id: flowId } = useParams() as { id: string };
  const baseIndex = showPhases ? 1 : 0;
  const getDragConfig = useGetDragConfig({
    mode,
    flowId,
    step,
    index,
  });

  const [{ isDragPlaceholder, opacity }, drag, dragPreview] = useDrag<
    DragObject,
    DropResult,
    CollectedProps
  >(getDragConfig);

  const [, drop] = useDrop<DragObject, DropResult, CollectedProps>({
    accept: Object.values(flowStepTypesAndPickables),
    drop: (item) => ({ ...item, selfDrop: true }),
    hover(draggedStep, monitor) {
      if (!ref.current) {
        return;
      }

      if (step.stepType === FlowStepTypeEnum.Trigger) return;

      // Disallow dragging over the first step
      const dragIndex = draggedStep.fromLibrary
        ? draggedStep.index || baseIndex
        : draggedStep.index;
      const hoverIndex = index ?? baseIndex;
      const shouldMoveStep = !showPhases || hoverIndex > 0;

      if (shouldMoveStep && shouldExecuteDragMove(monitor, ref, dragIndex, hoverIndex)) {
        updateStep({
          client,
          flowId,
          dragIndex,
          hoverIndex,
        });

        /**
         * Note: we're mutating the monitor item here!
         * Generally it's better to avoid mutations,
         * but it's good here for the sake of performance
         * to avoid expensive index searches.
         */
        draggedStep.index = hoverIndex; // eslint-disable-line
      }
    },
  });

  // Hide browsers default preview image
  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  });

  // Connect node to drag & drop hooks
  drag(drop(ref));

  if (!step.stepType) return null;
  // Resolve item name for pickable or non-pickable types

  if (mode === FlowStepModeEnum.Pickable) {
    return <FlowStepPickable stepType={step.stepType as FlowStepPickableTypeEnum} ref={ref} />;
  }

  return (
    <FlowStepInCanvas
      draggable={draggable}
      isDragPlaceholder={isDragPlaceholder}
      opacity={opacity}
      hidePeriodSelector={hidePeriodSelector}
      flowId={flowId}
      step={step as NonNullable<OneOrderedFlowStep>}
      index={index}
      onClick={onClick}
      showPhases={showPhases}
      ref={ref}
      {...rest}
    />
  );
}

export default FlowStep;
