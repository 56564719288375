import type { BoxProps } from "@chakra-ui/react";
import { Box, HStack } from "@chakra-ui/react";
import { useVariableStore } from "@core/components/context/VariableStore";
import type { PropsWithChildren } from "react";
import type { RenderElementProps } from "slate-react";
import { useFocused, useSelected } from "slate-react";

import { HighlightStyles, PlaceholderVariableStyles } from "../../styles";
import type { ExtendedElement } from "../../types";

const VARIABLE_SPACING = 0.5;

function VariableTag({ children, ...props }: PropsWithChildren<BoxProps>) {
  return (
    <Box
      as="span"
      {...PlaceholderVariableStyles}
      {...props}
      _first={{
        borderLeftRadius: "md",
      }}
      _last={{
        borderRightRadius: "md",
      }}
    >
      {children}
    </Box>
  );
}

function PlaceholderVariable({
  attributes,
  children,
  element,
}: RenderElementProps & { element: ExtendedElement }) {
  const [variableIdentifier, ...variableSections] = element.character!.split(".");
  const { variableStore } = useVariableStore() || {};
  const placeholderVariable = variableStore?.[variableIdentifier];
  const selected = useSelected();
  const focused = useFocused();

  const getStyles = () => {
    if (!placeholderVariable) return { padding: 0, background: "red.100", color: "red.500" };
    return {};
  };

  return (
    <HStack as="span" {...attributes} display="inline-flex" spacing={VARIABLE_SPACING}>
      <>
        <VariableTag
          {...{
            ...getStyles(),
            ...(selected && focused ? HighlightStyles : {}),
          }}
        >
          {children}
          {placeholderVariable?.label ?? element.character}
        </VariableTag>
        {placeholderVariable && variableSections.length
          ? variableSections.map((variableSection) => (
              <VariableTag key={variableSection}>{variableSection}</VariableTag>
            ))
          : null}
      </>
    </HStack>
  );
}

export default PlaceholderVariable;
