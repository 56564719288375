export const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"] as const;

export const LIST_TYPES = ["numbered-list", "bulleted-list"] as const;

export const BlockTypes = {
  file: "file",
  paragraph: "paragraph",
  image: "image",
  quote: "quote",
  placeholder: "placeholder",
  "numbered-list": "numbered-list",
  "bulleted-list": "bulleted-list",
  "list-item": "list-item",
  "heading-one": "heading-one",
  "heading-two": "heading-two",
};

export const MarkTypes = {
  bold: "bold",
  italic: "italic",
  underline: "underline",
  link: "link",
  code: "code",
};

export const TagTypes = {
  STRONG: "STRONG",
  EM: "EM",
  BODY: "BODY",
  P: "P",
  BR: "BR",
  BLOCKQUOTE: "BLOCKQUOTE",
  OL: "OL",
  UL: "UL",
  LI: "LI",
  A: "A",
  SPAN: "SPAN",
  IMG: "IMG",
};

export const INITIAL_TEXT_EDITOR_VALUE = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

export const imageExtensions = [
  "ase",
  "art",
  "bmp",
  "blp",
  "cd5",
  "cit",
  "cpt",
  "cr2",
  "cut",
  "dds",
  "dib",
  "djvu",
  "egt",
  "exif",
  "gif",
  "gpl",
  "grf",
  "icns",
  "ico",
  "iff",
  "jng",
  "jpeg",
  "jpg",
  "jfif",
  "jp2",
  "jps",
  "lbm",
  "max",
  "miff",
  "mng",
  "msp",
  "nitf",
  "ota",
  "pbm",
  "pc1",
  "pc2",
  "pc3",
  "pcf",
  "pcx",
  "pdn",
  "pgm",
  "PI1",
  "PI2",
  "PI3",
  "pict",
  "pct",
  "pnm",
  "pns",
  "ppm",
  "psb",
  "psd",
  "pdd",
  "psp",
  "px",
  "pxm",
  "pxr",
  "qfx",
  "raw",
  "rle",
  "sct",
  "sgi",
  "rgb",
  "int",
  "bw",
  "tga",
  "tiff",
  "tif",
  "vtf",
  "xbm",
  "xcf",
  "xpm",
  "3dv",
  "amf",
  "ai",
  "awg",
  "cgm",
  "cdr",
  "cmx",
  "dxf",
  "e2d",
  "egt",
  "eps",
  "fs",
  "gbr",
  "odg",
  "svg",
  "stl",
  "vrml",
  "x3d",
  "sxd",
  "v2d",
  "vnd",
  "wmf",
  "emf",
  "art",
  "xar",
  "png",
  "webp",
  "jxr",
  "hdp",
  "wdp",
  "cur",
  "ecw",
  "iff",
  "lbm",
  "liff",
  "nrrd",
  "pam",
  "pcx",
  "pgf",
  "sgi",
  "rgb",
  "rgba",
  "bw",
  "int",
  "inta",
  "sid",
  "ras",
  "sun",
  "tga",
];
