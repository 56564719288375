import type { FormikSelectFieldSelectOption } from "@flows-platform/modules/Shared/components/FormikSelectField/FormikSelectField";

export default function mapSelectOptions(
  options: FormikSelectFieldSelectOption[],
  placeholder = "Select an option"
) {
  return (
    <>
      <option disabled value="">
        {placeholder}
      </option>
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label || option.value}
        </option>
      ))}
    </>
  );
}
