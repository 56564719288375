import { Button, Container } from "@chakra-ui/react";
import { ArrowBackIcon } from "@kwest_fe/core/src/theme/icons";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <Container w={"full"} display={"flex"}>
      <Button
        onClick={() => {
          navigate(-1);
        }}
        mr={{ base: 0, md: -20 }}
        position={{ base: "relative", md: "absolute" }}
        top={{ base: undefined, md: 6 }}
        left={{ base: undefined, md: 10 }}
        mt={{ base: 4, md: 0 }}
        display={"flex"}
        bgColor={"white"}
        border={"1px"}
        borderColor={"gray.300"}
        w={10}
        h={9}
        justifyContent={"center"}
        _hover={{ bgColor: "gray.100" }}
        _active={{ bgColor: "gray.300" }}
      >
        <ArrowBackIcon />
      </Button>
    </Container>
  );
};

export default BackButton;
