import { Box, Text } from "@chakra-ui/react";
import Avatar from "@kwest_fe/core/src/components/UI/atoms/Avatar";

interface UserProps {
  name: string;
}
const User = ({ name }: UserProps) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Avatar name={name} size="sm" />
      <Text fontSize="md" fontWeight={400} whiteSpace={"nowrap"}>
        {name}
      </Text>
    </Box>
  );
};

export default User;
