import { Box, Flex, Text } from "@chakra-ui/react";
import type { InboxStepFragment } from "@flows-platform/generated/graphql";
import ComponentSpinner from "@kwest_fe/core/src/components/UI/atoms/ComponentSpinner";
import { useTranslation } from "react-i18next";

import TodoCard from "./TodoCard";

interface TodoCardsBoardProps {
  todos: (InboxStepFragment | null)[] | null | undefined;
  isLoading: boolean;
}

const TodoCardsBoard = ({ todos, isLoading }: TodoCardsBoardProps) => {
  const { t } = useTranslation();

  if (!todos?.length)
    return (
      <Box ml={5}>
        <Text fontSize={"14px"}>{t("pages.inbox.table.empty_message")}</Text>
      </Box>
    );

  if (isLoading) return <ComponentSpinner />;

  return (
    <Flex flexDir={"column"}>
      {todos?.map((journeyStep, index) => {
        if (journeyStep?.id)
          return (
            <TodoCard
              journeyStep={journeyStep}
              key={journeyStep?.id}
              isLast={index === todos.length - 1}
            />
          );
        return null;
      })}
    </Flex>
  );
};

export default TodoCardsBoard;
