import { HStack, Text } from "@chakra-ui/react";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import { format } from "date-fns";

interface DateViewProps {
  date: Date;
  onClick?: () => void;
}

export default function DateView({ date, onClick }: DateViewProps) {
  return (
    <HStack onClick={onClick} cursor={onClick && "pointer"} whiteSpace={"nowrap"}>
      <Text textStyle={TEXT_STYLES.small12}>{format(date, "dd MMM yyyy")}</Text>
    </HStack>
  );
}
