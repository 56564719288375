import type { BoxProps } from "@chakra-ui/react";
import { Box, GridItem, SimpleGrid } from "@chakra-ui/react";
import type { Element, HTMLReactParserOptions } from "html-react-parser";
import parse, { domToReact } from "html-react-parser";
import { memo } from "react";

import CaptionBoxStyles from "../../constants/captionBoxStyles";
import useFileUploadService from "../../modules/Shared/hooks/useFileUploadService";
import { parseFileName } from "../../utils";
import EncodedImagesViewer from "../EncodedImagesViewer";
import FileListItem from "../FileListItem";
import downloadFileFromResponse from "../FileUploader/utils/downloadFile";
import { TagTypes } from "../RichTextEditor/constants";

interface HTMLContentRendererProps extends BoxProps {
  htmlContent?: string | null;
}

interface HTMLParserOptionsPayload {
  triggerDownload: (fileUrl: string, fileName: string) => void;
}

const htmlParserOptions: ({
  triggerDownload,
}: HTMLParserOptionsPayload) => HTMLReactParserOptions = ({ triggerDownload }) => ({
  replace(domNode: Element) {
    if (domNode.attribs) {
      const { attribs, children, name } = domNode;
      if (name === TagTypes.SPAN.toLowerCase() && attribs.class === "placeholder-variable") {
        if (children.length)
          return (
            <Box className="placeholder-variable" as="span">
              {parse(domToReact(children) as string, htmlParserOptions({ triggerDownload }))}
            </Box>
          );
      }

      if (name === TagTypes.A.toLowerCase() && attribs.class === "file-link") {
        const fileUrl = attribs.href;
        const filePath = domToReact(children) as string;
        return (
          <SimpleGrid columns={2} columnGap={3} rowGap={6} as="span" mt={1}>
            <GridItem as="span">
              <FileListItem
                filePath={filePath}
                onDownloadFile={() => {
                  triggerDownload(fileUrl, filePath);
                }}
              />
            </GridItem>
          </SimpleGrid>
        );
      }
      if (name === TagTypes.IMG.toLowerCase()) {
        return (
          <EncodedImagesViewer images={[attribs.src]} {...(attribs.id ? { id: attribs.id } : {})} />
        );
      }
    }
    return null;
  },
});

function HTMLContentRenderer({ htmlContent, ...boxProps }: HTMLContentRendererProps) {
  const { downloadFile } = useFileUploadService({});

  const triggerDownload = async (fileUrl: string, fileName: string) => {
    const response = await downloadFile(fileUrl);
    downloadFileFromResponse(
      parseFileName(fileName),
      response.data,
      response.headers["content-type"]
    );
  };

  return (
    <Box {...CaptionBoxStyles} {...boxProps} w="full">
      {parse(htmlContent || "", htmlParserOptions({ triggerDownload }))}
    </Box>
  );
}

export default memo(HTMLContentRenderer);
