import { Box } from "@chakra-ui/react";
import type { FlowStepPickableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import type { XYCoord } from "react-dnd";
import { useDragLayer } from "react-dnd";

import { StackingContextEnum } from "../../../../../../utils/enums";
import { flowStepTypePairs } from "../FlowStep/constants/constants";

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

interface DragPreviewProps {
  // Improve typing of renderType => FlowStep | todo | form | email | etc.
  render: (renderType: string, item: any) => JSX.Element;
}

function DragPreview({ render }: DragPreviewProps) {
  const { itemType, item, isDragging, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging || !currentOffset || !initialOffset) return null;

  /**
   * Proxy any pickable dragged from the library
   * to it's base type in order that the drag preview
   * matches what will arrive on the canvas.
   * E.g. EMAIL_PICKABLE > EMAIL
   */
  let renderType = itemType;

  if (itemType && Object.keys(flowStepTypePairs).includes(itemType as string)) {
    renderType = flowStepTypePairs[itemType as FlowStepPickableTypeEnum];
  }

  if (!renderType) return null;

  return (
    <Box
      pos="fixed"
      left={0}
      top={0}
      w="full"
      h="full"
      zIndex={StackingContextEnum.Overlay}
      pointerEvents="none"
    >
      <Box style={getItemStyles(initialOffset, currentOffset)}>
        {render(renderType as string, item)}
      </Box>
    </Box>
  );
}

export default DragPreview;
