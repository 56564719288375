import { Box, Text } from "@chakra-ui/react";
import type { SearchResultJourneyFieldsFragment } from "@flows-platform/generated/graphql";
import { FlowsIcon, ProjectsIcon } from "@flows-platform/theme/icons";

interface SearchResultProps {
  journey: SearchResultJourneyFieldsFragment;
}

const SearchResult = ({ journey }: SearchResultProps) => {
  const handleClick = () => {
    if (journey.isProject) {
      window.open(`/projects/${journey.id}`, "_blank");
    } else {
      window.open(`/flows/${journey.id}`, "_blank");
    }
  };

  return (
    <Box
      color={"gray.700"}
      py={2}
      px={3}
      gap={2}
      alignItems={"center"}
      display={"flex"}
      cursor={"pointer"}
      w={"100%"}
      _hover={{ bg: "blue.50" }}
      onClick={handleClick}
    >
      {journey.isProject ? <ProjectsIcon /> : <FlowsIcon />}
      <Text noOfLines={1}>{journey.instanceName || journey.flow.name}</Text>
    </Box>
  );
};

export default SearchResult;
