import { ListIcon, ListItem, UnorderedList } from "@chakra-ui/react";

import {
  MultiPickerSelectListItemStyles,
  MultiPickerSelectListStyles,
} from "../../constants/styles";
import type { MultiPickerOptionValue } from "../../utils/utils";

interface MultiPickerSelectProps {
  options: MultiPickerOptionValue[];
  handleItemClicked: (value: string) => void;
  index: number;
}

function MultiPickerSelect({ index, options, handleItemClicked }: MultiPickerSelectProps) {
  return (
    <UnorderedList {...MultiPickerSelectListStyles} maxH={200} overflowY="scroll">
      {options.map((option, i) => (
        <ListItem
          key={option.value}
          {...MultiPickerSelectListItemStyles}
          background={i === index ? "gray.50" : "transparent"}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleItemClicked(option.value);
          }}
        >
          {option.icon ? <ListIcon as={option.icon} /> : null}
          {option.label}
        </ListItem>
      ))}
    </UnorderedList>
  );
}

export default MultiPickerSelect;
