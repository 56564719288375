import type { QueryHookOptions, QueryResult } from "@apollo/client";
import LoadingListSkeleton from "@flows-platform/modules/Shared/components/LoadingListSkeleton";
import type { FlowStepInitialValues } from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import type { PropsWithChildren } from "react";
import { useEffect, useMemo } from "react";

interface FlowStepResourceLoaderContainerProps<TData, ResultData> {
  initialFormValuesLoaded: boolean;
  query: (baseOptions?: QueryHookOptions<TData>) => QueryResult<TData>;
  queryOptions?: QueryHookOptions<TData>;
  initialValuesKey: keyof FlowStepInitialValues;
  resolveInitialValue: (data?: TData) => ResultData;
  loadingErrorMessage: string;
}

function FlowStepResourceLoaderContainer<TData, ResultData>({
  initialFormValuesLoaded,
  query,
  queryOptions,
  initialValuesKey,
  resolveInitialValue,
  loadingErrorMessage,
  children,
}: PropsWithChildren<FlowStepResourceLoaderContainerProps<TData, ResultData>>) {
  const { setInitialValueSlice } = useFlowEditorDrawer() || {};
  const { data, loading, error } = query({ ...queryOptions, fetchPolicy: "cache-and-network" });

  const initialValue = useMemo(() => resolveInitialValue(data), [data, resolveInitialValue]);

  useEffect(() => {
    if (!(loading || error)) {
      setInitialValueSlice?.(initialValuesKey, initialValue);
    }
  }, [loading, error, data, setInitialValueSlice, initialValuesKey, initialValue]);

  if ((loading && !error) || !initialFormValuesLoaded) {
    return <LoadingListSkeleton />;
  }

  if (error) {
    return <Placeholder type="error" header={loadingErrorMessage} />;
  }

  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export default FlowStepResourceLoaderContainer;
