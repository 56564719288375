import { InMemoryCache } from "@apollo/client";

import { PermissionLevelEnum } from "../../../generated/graphql";
import { TodoListEnum } from "../../../modules/Core/constants/enums";

export default new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        allJourneys: {
          // Updates the cache after the query has been executed
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    MembershipType: {
      fields: {
        isAdmin(current, { readField }) {
          if (current) return current;
          return readField("permissionLevel") === PermissionLevelEnum.Admin.valueOf();
        },
        isGuest(current, { readField }) {
          if (current) return current;
          return readField("permissionLevel") === PermissionLevelEnum.Guest.valueOf();
        },
      },
    },
    TodoListResourceType: {
      fields: {
        todoListType(current, { readField }) {
          if (current) return current;

          const journeyStep = readField("journeyStep");
          const flowStep = readField("flowStep");

          if (journeyStep) return TodoListEnum.JourneyStep;
          if (flowStep) return TodoListEnum.FlowStep;

          return null;
        },
      },
    },
    DocumentResourceType: {
      fields: {
        documentType(current, { readField }) {
          if (current) return current;

          const journeyStep = readField("journeyStep");
          const flowStep = readField("flowStep");

          if (journeyStep) return TodoListEnum.JourneyStep;
          if (flowStep) return TodoListEnum.FlowStep;

          return null;
        },
      },
    },
    TaskResourceType: {
      fields: {
        taskType(current, { readField }) {
          if (current) return current;

          const journeyStep = readField("journeyStep");
          const flowStep = readField("flowStep");

          if (journeyStep) return TodoListEnum.JourneyStep;
          if (flowStep) return TodoListEnum.FlowStep;

          return null;
        },
      },
    },
  },
});
