import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useBlocker } from "react-router-dom";

interface UnsavedChangesAlertProps {
  hasUnsavedChanges: boolean;
}

function UnsavedChangesAlert({ hasUnsavedChanges }: UnsavedChangesAlertProps) {
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      hasUnsavedChanges && nextLocation.pathname !== currentLocation.pathname
  );

  const leavePage = () => {
    blocker?.proceed?.();
  };

  const stayOnPage = () => {
    blocker?.reset?.();
  };

  return (
    <Modal isOpen={blocker.state === "blocked"} onClose={stayOnPage}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Unsaved changes</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>You have made some changes to your flow that have not yet been saved</Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={leavePage}>
            Proceed without saving
          </Button>
          <Button variant="ghost" onClick={stayOnPage}>
            {t("global.actions.cancel")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default UnsavedChangesAlert;
