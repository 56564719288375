import FormFilesService from "@core/services/formFiles";
import isUrl from "is-url";
import { Transforms } from "slate";
import type { ReactEditor } from "slate-react";

import { imageExtensions } from "./constants";

const isImageUrl = (url: string) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  if (ext) return imageExtensions.includes(ext);
  return false;
};

export default function withImages<T extends ReactEditor>(
  editor: T,
  { uploadUrl, accessToken }: { uploadUrl: string; accessToken: Promise<string> | string }
) {
  const { insertData } = editor;

  const insertImage = (url: string, id?: string) => {
    // TODO: Move function along with other functions in this file to a reusable module
    const defaultText = { text: "" };
    const imageAndParagraph = [
      {
        type: "image",
        fileId: id || null,
        url,
        children: [defaultText],
      },
      {
        type: "paragraph",
        children: [defaultText],
      },
    ];
    Transforms.insertNodes(editor, imageAndParagraph);
  };

  const insertFileAttachment = (url: string, id: string, name: string) => {
    const defaultText = { text: name };
    const imageAndParagraph = [
      {
        type: "file",
        fileId: id || null,
        url,
        children: [defaultText],
      },
    ];
    Transforms.insertNodes(editor, imageAndParagraph, { at: { path: [0, 0], offset: 0 } });
  };

  const { uploadFile } = FormFilesService({
    accessToken,
    uploadUrl,
  });

  const uploadAndRenderFile = (file: File[]) => {
    uploadFile(file, {
      onFileUploadedSuccess({ data }) {
        file.forEach((singleFile, index) => {
          const datum = data[index];
          if (singleFile.type.split("/")[0] === "image") {
            insertImage(`${uploadUrl}/${datum.id}`, datum.id);
          } else {
            insertFileAttachment(`${uploadUrl}/${datum.id}`, datum.id, singleFile.name);
          }
        });
      },
    });
  };

  // eslint-disable-next-line no-param-reassign
  editor.insertData = (attachments) => {
    const text = attachments.getData("text/plain");
    const { files } = attachments;

    if (files && files.length > 0) {
      uploadAndRenderFile(Array.from(files));
    } else if (isImageUrl(text)) {
      insertImage(text);
    } else {
      insertData(attachments);
    }
  };

  return { editor, uploadAndRenderFile, insertImage };
}
