import { Button, HStack, Input, Text, VStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

interface FilterByContentProps {
  contentValue: string;
  onClose: () => void;
  onSaveFilter: (content: string) => void;
}

export default function FilterByContent({
  contentValue,
  onClose,
  onSaveFilter,
}: FilterByContentProps) {
  const [content, setContent] = useState(contentValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <VStack alignItems="flex-end" gap={1} px={3} paddingBottom={1}>
      <VStack alignItems="flex-start" width="full">
        <Text fontSize="md">Filter Content by</Text>
        <Input
          ref={inputRef}
          name="filterContentBy"
          placeholder="Enter"
          value={content}
          onChange={(ev) => {
            setContent(ev.target.value);
          }}
        />
      </VStack>

      <HStack gap={3}>
        <Button
          variant="outline"
          onClick={() => {
            onClose();
            setContent(contentValue);
          }}
        >
          {t("pages.projects.filters.cancel")}
        </Button>
        <Button
          colorScheme="purple"
          onClick={() => {
            if (content.length === 0) return;
            onSaveFilter(content);
          }}
        >
          {t("pages.projects.filters.apply")}
        </Button>
      </HStack>
    </VStack>
  );
}
