import { HStack, Input } from "@chakra-ui/react";
import ConfirmChanges from "@kwest_fe/core/src/components/UI/molecules/forms/ConfirmChanges";
import { useState } from "react";

interface DateFieldProps {
  date: string | null;
  onDateUpdate?: (date: string | null) => Promise<void>;
  onChange?: (date: string | null) => void;
}
export default function DateField({ date, onDateUpdate, onChange }: DateFieldProps) {
  const [dateState, setDateState] = useState<string | null>(date);
  const [loading, setLoading] = useState(false);

  return (
    <HStack>
      <Input
        placeholder="Select Date and Time"
        value={dateState || ""}
        size="sm"
        type="date"
        width={125}
        backgroundColor="gray.100"
        borderWidth={0}
        borderRadius={4}
        onChange={(e) => {
          onChange?.(e.target.value || null);
          setDateState(e.target.value || null);
        }}
      />
      <ConfirmChanges
        display={dateState !== date}
        handleConfirm={async () => {
          try {
            setLoading(true);
            await onDateUpdate?.(dateState);
          } finally {
            setLoading(false);
          }
        }}
        handleCancel={() => {
          setDateState(date);
        }}
        isLoading={loading}
      />
    </HStack>
  );
}
