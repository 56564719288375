import { Button } from "@chakra-ui/react";
import { useConnectedFlow } from "@flows-platform/context/ConnectedFlow";

const ConnectedFlowName = () => {
  const { connectedFlow, availableTemplates } = useConnectedFlow();

  const selectedFlowTemplate = availableTemplates?.find(
    (result) => result?.id === connectedFlow.id
  );

  return (
    <Button size="sm" borderLeftRadius={0} fontWeight="medium" px={1}>
      {selectedFlowTemplate?.name}
    </Button>
  );
};

export default ConnectedFlowName;
