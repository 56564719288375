import type { StackProps } from "@chakra-ui/react";
import { Box, VStack } from "@chakra-ui/react";
import type { OneFlowFieldsFragment } from "@flows-platform/generated/graphql";
import { FlowStepTypeEnum } from "@flows-platform/generated/graphql";
import { FeatureFlags } from "@flows-platform/modules/Core/constants/enums";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import type { OneOrderedFlowStep } from "@flows-platform/types";
import getFeatureFlag from "@flows-platform/utils/getFeatureFlag";
import { StaticTodoItemModeEnum, TodoItemEnum } from "@kwest_fe/core/src/enums/todoItem";
import { t } from "i18next";
import type { useDragDropManager } from "react-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";

import StaticTodoItem from "../../../Shared/components/StaticTodoItem";
import DragPreview from "./components/DragPreview/DragPreview";
import FlowEditorCanvas from "./components/FlowEditorCanvas";
import FlowEditorDroppable from "./components/FlowEditorDroppable/FlowEditorDroppable";
import FlowEditorLibrary from "./components/FlowEditorLibrary/FlowEditorLibrary";
import FlowStep from "./components/FlowStep";
import {
  FLOW_EDITOR_AVAILABLE_TYPES,
  flowStepTypes,
  PROJECT_BUILDER_AVAILABLE_TYPES,
  STEP_SPACING,
} from "./components/FlowStep/constants/constants";
import StaticFormItem from "./components/StaticFormItem";
import FORM_TYPE from "./constants/constants";
import type { DragObject } from "./constants/types";

interface FlowEditorProps {
  flowSteps: OneFlowFieldsFragment["orderedFlowSteps"];
  onFlowStepClicked: (step: OneOrderedFlowStep) => void;
  onFlowStepInserted: (draggedStep: DragObject) => void;
  isProject: boolean;
  onTriggerClicked: () => void;
}

function FlowEditor({
  flowSteps,
  onFlowStepClicked,
  onFlowStepInserted,
  onTriggerClicked,
  isProject,
}: FlowEditorProps) {
  const { myMembership } = useMyMembership();
  const httpRequestEnabled = getFeatureFlag(FeatureFlags.HttpRequest, myMembership);
  const documentCreationEnabled = getFeatureFlag(FeatureFlags.DocumentCreation, myMembership);
  const approvalStepEnabled = getFeatureFlag(FeatureFlags.Approval, myMembership);
  const listStepEnabled = getFeatureFlag(FeatureFlags.ListStep, myMembership);

  const acceptedStepTypes = isProject
    ? PROJECT_BUILDER_AVAILABLE_TYPES
    : FLOW_EDITOR_AVAILABLE_TYPES;

  const canvasProps: StackProps = isProject
    ? {
        spacing: STEP_SPACING,
        py: STEP_SPACING,
      }
    : { spacing: 0, paddingBottom: 6 };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box pos="relative" w="full" h="full" data-testid="flow-editor-canvas">
        <FlowEditorCanvas
          accept={acceptedStepTypes.reduce(
            (acc, stepType) => [...acc, ...[stepType, flowStepTypes[stepType].pickableType]],
            []
          )}
        >
          <DragPreview
            render={(renderType, item) => {
              if (renderType === FORM_TYPE) {
                return <StaticFormItem formItem={item} />;
              }

              if (renderType === TodoItemEnum.TodoItem) {
                return (
                  <StaticTodoItem
                    mode={StaticTodoItemModeEnum.DndPreview}
                    todoItem={{
                      id: uuidv4(),
                      name: item.name || "Todo Name",
                      description: item.description || "Description",
                      isDone: false,
                      resourceUrl: item.resourceUrl,
                      resourceDisplayName: item.resourceDisplayName,
                    }}
                  />
                );
              }

              /**
               * If the render type is any flow step
               */
              if (Object.keys(flowStepTypes).includes(renderType))
                return (
                  <FlowStep
                    w={750}
                    step={{
                      stepType: renderType as FlowStepTypeEnum,
                      name: t(
                        item.name || flowStepTypes[renderType as FlowStepTypeEnum].defaultName
                      ),
                    }}
                    hidePeriodSelector
                  />
                );

              return <div />;
            }}
          />
          <FlowEditorDroppable
            accept={acceptedStepTypes.map((stepType) => flowStepTypes[stepType].pickableType)}
            onDropped={(
              draggedStep: DragObject,
              manager: ReturnType<typeof useDragDropManager> & { lastDroppedStep?: DragObject }
            ) => {
              /**
               * Insert new flow step when it is dragged into the editor.
               * item.flowStepType is cast to the item's canvas type
               * E.g. EMAIL_PICKABLE > EMAIL
               */
              onFlowStepInserted({
                ...draggedStep,
                name: t(flowStepTypes[draggedStep.stepType].defaultName),
              });

              // eslint-disable-next-line no-param-reassign
              manager.lastDroppedStep = draggedStep;
            }}
          >
            <FlowStep
              draggable={false}
              step={{
                stepType: FlowStepTypeEnum.Trigger,
                name: "",
              }}
              onClick={onTriggerClicked}
              data-testid="trigger"
            />
            <VStack w="full" {...canvasProps}>
              {flowSteps?.map(
                (step, index) =>
                  step && (
                    <FlowStep
                      key={step.id}
                      step={step}
                      index={index}
                      style={{ width: "100%" }}
                      onClick={onFlowStepClicked}
                      hidePeriodSelector={isProject}
                      showPhases={isProject}
                    />
                  )
              )}
            </VStack>
          </FlowEditorDroppable>
        </FlowEditorCanvas>
        <FlowEditorLibrary
          accept={acceptedStepTypes}
          excluded={[
            ...(!httpRequestEnabled ? [FlowStepTypeEnum.HttpRequest] : []),
            ...(!documentCreationEnabled ? [FlowStepTypeEnum.Document] : []),
            ...(!approvalStepEnabled ? [FlowStepTypeEnum.Approval] : []),
            ...(!listStepEnabled ? [FlowStepTypeEnum.List] : []),
          ]}
        />
      </Box>
    </DndProvider>
  );
}

export default FlowEditor;
