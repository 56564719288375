import { ModalCloseButton, ModalHeader, Text, VStack } from "@chakra-ui/react";

interface ModalHeaderTemplateProps {
  title: string;
  subtitle: string;
}
const ModalHeaderTemplate = ({ title, subtitle }: ModalHeaderTemplateProps) => {
  return (
    <>
      <ModalHeader>
        <VStack align="start" spacing={1}>
          <Text>{title}</Text>
          <Text fontSize="sm" fontWeight="normal">
            {subtitle}
          </Text>
        </VStack>
      </ModalHeader>
      <ModalCloseButton />
    </>
  );
};

export default ModalHeaderTemplate;
