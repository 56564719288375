import { CheckIcon } from "@chakra-ui/icons";
import { Button, Divider, HStack, VStack } from "@chakra-ui/react";
import HTMLContentRenderer from "@kwest_fe/core/src/components/HTMLContentRenderer";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import type { JourneyStepResourceQuery } from "@todo-viewer/generated/graphql";
import {
  useApproveStepClientMutation,
  useRejectStepClientMutation,
} from "@todo-viewer/generated/graphql";
import messageParent from "@todo-viewer/modules/Core/utils/messageParent";
import FormikField from "@todo-viewer/modules/Shared/components/FormikField/FormikField";
import ResizableTextarea from "@todo-viewer/modules/Shared/components/ResizableTextarea/ResizableTextarea";
import useRedirectToNextStep from "@todo-viewer/modules/TodoView/hooks/useRedirectToNextStep";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { MdChatBubbleOutline as RejectIcon } from "react-icons/md";

import { approvalValidationSchema } from "../../schema";
import TodoViewLayout from "../TodoViewLayout";
import TodoViewPageHeader from "../TodoViewPageHeader";

interface TodoViewApprovalProps {
  resource: JourneyStepResourceQuery["getJourneyStepResource"] & {
    __typename: "ApprovalResourceType";
  };
}

function TodoViewApproval({ resource }: TodoViewApprovalProps) {
  const { t } = useTranslation();
  const { successToast, errorToast } = useDefaultToasts();

  const [acceptApprovalStep] = useApproveStepClientMutation();
  const [rejectApprovalStep] = useRejectStepClientMutation();

  const redirectToNextStep = useRedirectToNextStep();

  const acceptApproval = (id: string) => {
    return acceptApprovalStep({
      variables: { input: { id } },
      onCompleted(data) {
        successToast("Accepted successfully");
        messageParent("task_completed");
        redirectToNextStep(data?.approveStep?.nextStep, resource?.journeyStep?.projectId);
      },
      onError(err) {
        errorToast("There was an error while trying to submit the approval")(err);
      },
    });
  };

  const rejectApproval = async (id: string, comment: string) => {
    const res = await rejectApprovalStep({
      variables: { input: { id, rejectionComment: comment } },
      onCompleted(data) {
        successToast("Rejected successfully");
        redirectToNextStep(data.rejectStep?.nextStep, resource?.journeyStep?.projectId);
      },
      onError(err) {
        errorToast("There was an error while trying to submit the approval")(err);
      },
    });
    return res;
  };
  if (!resource) return null;
  const { content, receiver, title, id, journeyStep, receiverTeam } = resource;

  return (
    <TodoViewLayout
      isCompleted={!!journeyStep?.completedAt}
      heading={
        <TodoViewPageHeader
          journeyStep={journeyStep}
          receiver={receiver}
          receiverTeam={receiverTeam}
          title={title}
        />
      }
    >
      <Formik
        initialValues={{
          reject: false,
          rejectionComment: "",
        }}
        validationSchema={approvalValidationSchema}
        onSubmit={async ({ reject, rejectionComment }, { setSubmitting }) => {
          const action = reject ? rejectApproval(id, rejectionComment) : acceptApproval(id);
          return action.finally(() => {
            setSubmitting(false);
          });
        }}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, submitForm, isValid, values: { reject } }) => (
          <Form style={{ width: "100%" }}>
            <VStack align="start" spacing={5}>
              <HTMLContentRenderer htmlContent={content} />
              <Divider />
              {reject && (
                <FormikField
                  renderElement={(props) => (
                    <ResizableTextarea
                      {...props}
                      style={{ transition: "height none", height: "auto", minHeight: "70px" }}
                      resize="none"
                    />
                  )}
                  name="rejectionComment"
                  placeholder={t("forms.rejection_comment.placeholder")}
                />
              )}
              <HStack display="flex" w="full" align="flex-end">
                {reject ? (
                  <Button
                    variant="outline"
                    onClick={() => {
                      if (reject) {
                        setFieldValue("reject", false);
                      } else {
                        submitForm();
                      }
                    }}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                  >
                    {t("global.actions.cancel")}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      if (reject) {
                        setFieldValue("reject", false);
                      } else {
                        submitForm();
                      }
                    }}
                    leftIcon={<CheckIcon />}
                    isLoading={isSubmitting}
                    bgColor={"white"}
                    border="1px solid #1E7B44"
                    color="#1E7B44"
                    isDisabled={isSubmitting}
                    flex={1}
                    _hover={{
                      backgroundColor: "#1E7B44",
                      borderColor: "#34CD71",
                      color: "white",
                    }}
                  >
                    {t("pages.learner.approval.approve")}
                  </Button>
                )}

                {reject ? (
                  <Button
                    variant={"solid"}
                    colorScheme="red"
                    onClick={() => {
                      submitForm();
                    }}
                    leftIcon={<RejectIcon />}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting || !isValid}
                    flex={1}
                  >
                    {t("pages.learner.approval.reject_with_comment")}
                  </Button>
                ) : (
                  <Button
                    border={"1px solid #de0013"}
                    bgColor={"white"}
                    color={"#de0013"}
                    onClick={() => {
                      setFieldValue("reject", true);
                    }}
                    leftIcon={<RejectIcon />}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting || !isValid}
                    flex={1}
                    _hover={{
                      bgColor: "#CC0011",
                      borderColor: "FF3849",
                      color: "white",
                    }}
                  >
                    {t("pages.learner.approval.reject")}
                  </Button>
                )}
              </HStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </TodoViewLayout>
  );
}

export default TodoViewApproval;
