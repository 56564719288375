import {
  Box,
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useAddCustomTaskClientMutation } from "@flows-platform/generated/graphql";
import FormikField from "@flows-platform/modules/Shared/components/FormikField";
import { useProjectSteps } from "@flows-platform/modules/Shared/providers/ProjectStepContext";
import ShadowButton from "@flows-platform/modules/Shared/UI/atoms/Buttons/ShadowButton";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import { StackingContextEnum } from "@flows-platform/utils/enums";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import { Formik } from "formik";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { inputLabelWidth } from "../../constants/constants";
import DateField from "../DateField";
import ProjectTaskAssigneeSelector from "../ProjectTaskAssigneeSelector";
import type { ProjectAssigneeType } from "../ProjectTaskAssigneeSelector/ProjectTaskAssigneeSelector";
import AddProjectStepFormSchema from "./schema";

interface AddCustomTaskModalProps {
  afterStepId?: string;
  beforeStepId?: string;
  controlPosition?: "bottom" | "top";
}

function AddCustomTaskModal({
  afterStepId,
  beforeStepId,
  controlPosition = "bottom",
}: AddCustomTaskModalProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { projectId, onUpdate } = useProjectSteps();
  const { t } = useTranslation();
  const [addCustomTask, { loading }] = useAddCustomTaskClientMutation({
    onCompleted() {
      onClose();
      onUpdate();
    },
  });

  return (
    <>
      <Box
        data-group
        w="full"
        h={8}
        pos="absolute"
        bottom={controlPosition === "bottom" ? -4 : undefined}
        top={controlPosition === "top" ? -4 : undefined}
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={StackingContextEnum.Foreground}
      >
        <ShadowButton
          size="xs"
          variant="outline"
          bg="white"
          display="none"
          _groupHover={{
            display: "inline-flex",
          }}
          onClick={onOpen}
          fontWeight="normal"
        >
          + {t("pages.project_instance.actions.new_task")}
        </ShadowButton>
      </Box>
      <Modal size="xl" isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader padding={6} display={"flex"} gap={4} alignItems={"center"}>
            <Text textStyle={TEXT_STYLES.h1}>Add a new task</Text>
          </ModalHeader>
          <Formik
            initialValues={{
              name: "",
              status: false,
              dueDate: "",
              receiver: null as ProjectAssigneeType,
              content: "",
            }}
            onSubmit={({ name, content, receiver, dueDate }) => {
              let updatePayload = {};
              if (receiver) {
                updatePayload =
                  receiver._typename === "MembershipType"
                    ? { receiverId: receiver.id }
                    : { receiverTeamId: receiver.id };
              }
              addCustomTask({
                variables: {
                  input: {
                    name,
                    afterStepId,
                    beforeStepId,
                    journeyId: projectId,
                    dueDate: dueDate || null,
                    content,
                    ...updatePayload,
                  },
                },
              });
            }}
            validationSchema={AddProjectStepFormSchema}
            validateOnMount
          >
            {({ setFieldValue, isValid, submitForm }) => (
              <>
                <ModalBody padding={0}>
                  <VStack alignItems="flex-start" px={6} spacing={6} w="full">
                    <FormikField name="name" label="Task Name" />
                    <VStack spacing={3} w="full">
                      <HStack gap={3} width="full">
                        <Text textStyle={TEXT_STYLES.lead14SemiBold} width={inputLabelWidth}>
                          {t("forms.project_task_status.label")}:
                        </Text>
                        <FormikField
                          name="status"
                          renderElement={({ value, onChange, name }) => (
                            <Checkbox name={name} isChecked={value} onChange={onChange}>
                              {t("global.actions.done")}
                            </Checkbox>
                          )}
                        />
                      </HStack>
                      <HStack gap={3} width="full">
                        <Text textStyle={TEXT_STYLES.lead14SemiBold} width={inputLabelWidth}>
                          {t("forms.project_task_due_date.label")}:
                        </Text>
                        <FormikField
                          name="dueDate"
                          renderElement={({ value }) => (
                            <DateField
                              date={value}
                              onChange={(dateValue) => {
                                setFieldValue("dueDate", dateValue);
                              }}
                            />
                          )}
                        />
                      </HStack>
                      <HStack gap={3} width="full">
                        <Text textStyle={TEXT_STYLES.lead14SemiBold} width={inputLabelWidth}>
                          {t("forms.project_task_assignee.label")}:
                        </Text>
                        <FormikField
                          name="receiver"
                          renderElement={({ value }) => (
                            <ProjectTaskAssigneeSelector
                              assignee={value}
                              onChange={(receiver: ProjectAssigneeType) => {
                                setFieldValue("receiver", receiver);
                              }}
                            />
                          )}
                        />
                      </HStack>
                    </VStack>
                    <RichTextEditor name="content" />
                  </VStack>
                </ModalBody>
                <ModalFooter>
                  <Button mr={3} onClick={onClose} disabled={loading}>
                    Close
                  </Button>
                  <Button
                    onClick={submitForm}
                    isDisabled={!isValid || loading}
                    isLoading={loading}
                    colorScheme="purple"
                  >
                    {t("global.actions.save")}
                  </Button>
                </ModalFooter>
              </>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
}

export default memo(AddCustomTaskModal);
