import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
} from "@chakra-ui/react";
import type { InstallionProjectDocumentCategoryType } from "@flows-platform/generated/graphql";

import DocumentsPackage from "./DocumentsPackage";

interface IDcoumentsCategoryProps {
  category: InstallionProjectDocumentCategoryType;
}
const DocumentsCategory = ({ category }: IDcoumentsCategoryProps) => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Heading size="md" color="gray.700" fontSize="md">
          {category.title}
        </Heading>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel display={"flex"} gap={"2"} flexDir={"column"}>
        {category.packages.map((pack) => (
          <DocumentsPackage pack={pack} key={pack.title} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  );
};

export default DocumentsCategory;
