import { AttachmentIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";
import Avatar from "@kwest_fe/core/src/components/UI/atoms/Avatar";
import { Link, useParams } from "react-router-dom";
import type { ChannelPreviewUIComponentProps } from "stream-chat-react";

export const CustomChannelPreview = ({ channel }: ChannelPreviewUIComponentProps) => {
  const { projectId } = useParams<{ projectId: string }>();
  const renderUsername = () => {
    const lastMessageUser = channel.state.messages[channel.state.messages.length - 1].user;
    return lastMessageUser?.name || "Unknown User";
  };

  const lastMessage = channel.state.messages[channel.state.messages.length - 1];

  return (
    <Link
      to={`/projects/${projectId}?task=${channel.id}`}
      style={{ width: "100%", backgroundColor: "white" }}
    >
      <Box
        display={"flex"}
        w={"100%"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        py={2}
        px={4}
        gap={1}
      >
        <Box display={"flex"} w={"full"} justifyContent={"space-between"} alignItems={"center"}>
          <Text fontSize={14} fontWeight={600} color={"gray.700"} noOfLines={1}>
            {channel.data?.name || "Channel"}
          </Text>
          <Text fontSize={10} color={"gray.500"} whiteSpace={"nowrap"}>
            {new Date(channel.state.last_message_at || "").toLocaleString()}
          </Text>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <Box display={"flex"} w={"full"} justifyContent={"space-between"} alignItems={"center"}>
            <Box fontSize={12} display={"flex"} gap={1} w={"full"} alignItems={"center"}>
              <Avatar initials={renderUsername()} size={"sm"} />
              <Text color={"gray.700"} fontWeight={600} fontSize={12}>
                {renderUsername()}
              </Text>
            </Box>
            <Box display={"flex"} gap={1} alignItems={"center"}>
              {lastMessage?.attachments && lastMessage.attachments.length > 0 && <AttachmentIcon />}
              {channel.state.unreadCount > 0 && (
                <Text fontSize={12} color={"blue.600"}>
                  {channel.state.unreadCount} unread messages
                </Text>
              )}
            </Box>
          </Box>
          <Text color={"gray.600"} fontSize={12} noOfLines={5}>
            {lastMessage.text}
          </Text>
        </Box>
      </Box>
    </Link>
  );
};
