import { Box, Heading, Text } from "@chakra-ui/react";

import placeholderTypes from "./constants";
import type PlaceholderType from "./enums";

interface PlaceholderProps {
  type: PlaceholderType;
  header?: string;
  icon?: React.ReactNode | string;
  iconSize?: string;
  description?: string;
  actions?: React.ReactNode;
}

function Placeholder({
  type,
  header,
  description,
  icon,
  iconSize = "3xl",
  actions,
}: PlaceholderProps) {
  return (
    <Box
      p={4}
      display="flex"
      w="full"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Heading size={iconSize} mb={3}>
        {icon || placeholderTypes[type].icon}
      </Heading>

      {header && (
        <Heading size="md" mb={3}>
          {header}
        </Heading>
      )}
      <Text align="center">{description || placeholderTypes[type].description}</Text>
      {actions && (
        <Box alignItems="center" mt={7}>
          {actions}
        </Box>
      )}
    </Box>
  );
}

export default Placeholder;
