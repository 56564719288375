import CompositeLoader from "@kwest_fe/core/src/components/CompositeLoader";

import type {
  AddSlackIntegrationClientMutationFn,
  MyMembershipQuery,
} from "../../generated/graphql";
import { useAddSlackIntegrationClientMutation, useMyWorkspaceQuery } from "../../generated/graphql";
import { useMyMembership } from "../Core/providers/MyMembershipProvider";

interface SettingsPageContainerProps {
  render: (
    membership: MyMembershipQuery["myMembership"],
    addSlackIntegration: AddSlackIntegrationClientMutationFn,
    hasSlackIntegration: boolean
  ) => JSX.Element;
}

export default function SettingsPageContainer({ render }: SettingsPageContainerProps) {
  const { myMembership } = useMyMembership();
  const { loading, error, data } = useMyWorkspaceQuery();
  const [addSlackIntegration] = useAddSlackIntegrationClientMutation();

  return (
    <CompositeLoader
      data-sentry-block
      loading={loading}
      error={error}
      errorMessage="Something went wrong while trying to load the workspace data"
    >
      {render(myMembership, addSlackIntegration, !!data?.myWorkspace?.hasSlackIntegration)}
    </CompositeLoader>
  );
}
