export default function messageParent(
  message:
    | "all_tasks_completed"
    | "authentication_required"
    | "invalid_token"
    | "task_completed"
    | "token_expired"
    | "unknown_message"
    | "update_height",
  payload?: Record<string, unknown>
) {
  if (window.parent !== window) {
    if (payload) {
      window.parent.postMessage({ type: message, payload }, "*");
      return;
    }
    window.parent.postMessage({ type: message }, "*");
  }
}
