import type { ButtonProps } from "@chakra-ui/react";
import { HStack, Spinner } from "@chakra-ui/react";

import IconButton from "../../../atoms/Buttons/IconButton";

interface ConfirmChangesProps {
  display: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  isLoading?: boolean;
  buttonSize?: ButtonProps["size"];
}

const ConfirmChanges = ({
  display,
  handleConfirm,
  handleCancel,
  isLoading = false,
  buttonSize,
}: ConfirmChangesProps) => {
  return (
    <HStack spacing={2} display={display ? "flex" : "none"}>
      {isLoading ? (
        <Spinner color="purple.500" size="sm" />
      ) : (
        <>
          <IconButton
            onClick={handleConfirm}
            type="confirm"
            size={buttonSize}
            data-testid="confirm-changes-confirm-button"
          />
          <IconButton
            onClick={handleCancel}
            type="cancel"
            size={buttonSize}
            data-testid="confirm-changes-cancel-button"
          />
        </>
      )}
    </HStack>
  );
};

export default ConfirmChanges;
