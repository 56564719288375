import { ActionEnum } from "@flows-platform/generated/graphql";
import { FormValidationSchema } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/components/FlowStepTemplateRenderer/components/FormContainer/FormContainer";
import * as Yup from "yup";

import ApprovalSchema from "../components/FlowStepTemplateRenderer/components/ApprovalEditorContainer/schema";
import DocumentSchema from "../components/FlowStepTemplateRenderer/components/DocumentEditorContainer/schema";
import EmailEditorSchema from "../components/FlowStepTemplateRenderer/components/EmailEditorFlowStepContainer/schema";
import HttpRequestSchema from "../components/FlowStepTemplateRenderer/components/HttpRequestResourceFlowStepContainer/schema";
import TaskSchema from "../components/FlowStepTemplateRenderer/components/TaskContainer/components/SelectFlow/ConnectFlow/schema";
import TodoListSchema from "../components/FlowStepTemplateRenderer/components/TodoListEditorContainer/components/TodoListEditor/schema";

export const FlowEditorDrawerSchema = Yup.object({
  name: Yup.string(),
  phaseId: Yup.string(),
  rulesChain: Yup.array(
    Yup.object({
      condition: Yup.string().required(),
      action: Yup.mixed<ActionEnum>().oneOf(Object.values(ActionEnum)).required(),
      evaluateNext: Yup.boolean().required(),
      actionConfig: Yup.object({
        step: Yup.string().nullable().when("action", {
          is: ActionEnum.JumpTo,
          then: Yup.string().required(),
        }),
        variable: Yup.string().nullable(),
        newValue: Yup.string().nullable(),
      }),
    }).nullable()
  ).nullable(),
  ...EmailEditorSchema,
  ...TodoListSchema,
  ...TaskSchema,
  ...DocumentSchema,
  ...ApprovalSchema,
  ...HttpRequestSchema,
  ...FormValidationSchema,
});
