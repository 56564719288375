import { Box, Button, HStack, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import type { FlowInstanceQuery } from "@core/generated/graphql";
import { FlowStepTypeEnum, JourneyStepStatus } from "@core/generated/graphql";
import { ExternalLinkIcon, LibraryIcon } from "@core/theme/icons";
import { formatDate } from "@core/utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { CLICKABLE_FLOW_STEPS, dateFormatOptions } from "../constants";

type FlowInstanceJourneySteps = NonNullable<
  NonNullable<FlowInstanceQuery["oneJourney"]>["orderedJourneySteps"]
>;

interface FlowInstanceTableRowProps {
  step: FlowInstanceJourneySteps[number];
  index: number;
  journeySteps: FlowInstanceJourneySteps;
  stepUrl?: (stepId: string) => string;
}

export default function FlowInstanceTableRow({
  step,
  index,
  journeySteps,
  stepUrl = (stepId: string) => `/todo/${stepId}?back=true`,
}: FlowInstanceTableRowProps) {
  const { t } = useTranslation();
  const TdBorderWidth = index === journeySteps.length - 1 ? 0 : "1px";
  const navigate = useNavigate();
  const lastStepCompleted = journeySteps[index - 1]?.completedAt;
  const isTimedWait = step?.status === JourneyStepStatus.TimedWait;
  const expectedDeliveryTime =
    lastStepCompleted && isTimedWait
      ? formatDate(new Date(step?.expectedDeliveryAt), dateFormatOptions)
      : "-";

  const deliveredDate = step?.deliveredAt
    ? formatDate(new Date(step.deliveredAt), dateFormatOptions)
    : expectedDeliveryTime;

  const completedDate = step?.completedAt
    ? formatDate(new Date(step.completedAt), dateFormatOptions)
    : "-";

  const completedBy = step?.completedBy
    ? `${step?.completedBy?.firstName} ${step?.completedBy?.lastName}`
    : "-";

  if (!step?.stepType) return null;

  const clickActive =
    CLICKABLE_FLOW_STEPS.includes(step.stepType) &&
    (step.stepType !== FlowStepTypeEnum.Email || step.status === JourneyStepStatus.AwaitUser);

  const journeyDeliveryDate = (
    <Box display={"flex"} gap={1} alignItems={"center"}>
      {deliveredDate}
    </Box>
  );

  const handleRowClick = () => {
    if (clickActive) {
      navigate(stepUrl(step.id));
    }
  };

  return (
    <Tr
      key={step.id}
      data-testid="flow-journey-step-row"
      onClick={handleRowClick}
      _hover={{
        background: "gray.50",
        cursor: clickActive ? "pointer" : "default",
      }}
      fontSize={"16px"}
      color={"tako.input.text"}
    >
      <Td borderBottomWidth={TdBorderWidth}>
        <HStack>
          <LibraryIcon background="transparent" color="1E0D35" type={step.stepType} />
          <Text>{step.name}</Text>
        </HStack>
      </Td>
      <Td borderBottomWidth={TdBorderWidth}>{journeyDeliveryDate}</Td>
      {step.stepType === FlowStepTypeEnum.List ? (
        <Td borderBottomWidth={TdBorderWidth}>
          <Tooltip hasArrow placement="top" label={t("pages.journey.tooltips.open_todo_list")}>
            <Button
              variant="ghost"
              colorScheme="purple"
              rightIcon={<ExternalLinkIcon />}
              onClick={() => window.open(`/todo/${step?.id || ""}`, "_blank")}
            >{`${step.todoList?.orderedTodoItems?.filter((item) => item?.isDone === true)
              .length} ${t("global.of")} ${step.todoList?.orderedTodoItems?.length} ${t(
              "global.items"
            )}`}</Button>
          </Tooltip>
        </Td>
      ) : (
        <Td borderBottomWidth={TdBorderWidth}>{completedDate}</Td>
      )}
      <Td borderBottomWidth={TdBorderWidth}>{completedBy}</Td>
    </Tr>
  );
}
