import {
  Button,
  Divider,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  AuthenticatorTypeEnum,
  FlowEnum as AuthenticatorOAuth2FlowEnum,
} from "@flows-platform/generated/graphql";
import FormikField from "@flows-platform/modules/Shared/components/FormikField";
import FormikSelectField from "@kwest_fe/core/src/components/FormikSelectField/FormikSelectField";
import type { FormikHelpers } from "formik";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { AuthenticatorFormValues } from "../../schema";
import NewAuthenticatorSchema, { NewAuthenticatorInitialValues } from "../../schema";

interface AuthenticatorFormProps {
  authValues?: AuthenticatorFormValues;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (authenticator: AuthenticatorFormValues) => Promise<void>;
}

function AuthenticatorForm({ isOpen, onClose, onSubmit, authValues }: AuthenticatorFormProps) {
  const { t } = useTranslation();

  const authorizationFlows = useMemo(
    () =>
      Object.keys(AuthenticatorOAuth2FlowEnum).map(
        (key: keyof typeof AuthenticatorOAuth2FlowEnum) => ({
          value: AuthenticatorOAuth2FlowEnum[key],
          label: key,
        })
      ),
    []
  );
  const authorizationTypes = useMemo(
    () =>
      Object.keys(AuthenticatorTypeEnum).map((key: keyof typeof AuthenticatorTypeEnum) => ({
        value: AuthenticatorTypeEnum[key],
        label: key,
      })),
    []
  );
  const handleFormSubmit = async (
    values: AuthenticatorFormValues,
    { setSubmitting }: FormikHelpers<AuthenticatorFormValues>
  ) => {
    await onSubmit(values);
    setSubmitting(false);
    onClose();
  };

  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <Formik
          initialValues={authValues || NewAuthenticatorInitialValues}
          validationSchema={NewAuthenticatorSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting, isValid, dirty, values: { type } }) => (
            <ModalContent>
              <Form>
                <ModalHeader>
                  {authValues
                    ? t("modals.authenticators.edit.title")
                    : t("modals.authenticators.create.title")}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <SimpleGrid columns={1} columnGap={3} rowGap={6}>
                    <GridItem colSpan={[2, 1]}>
                      <FormikField
                        name="name"
                        label={t("forms.authenticator_name.label")}
                        placeholder={t("forms.authenticator_name.placeholder")}
                      />
                    </GridItem>
                    <GridItem colSpan={[2, 1]}>
                      <FormikSelectField
                        name="type"
                        label="Authorization type"
                        options={authorizationTypes}
                        disabled={authValues !== undefined}
                      />
                    </GridItem>
                    {type === AuthenticatorTypeEnum.Oauth2 && (
                      <>
                        <GridItem colSpan={[2, 1]}>
                          <FormikSelectField
                            name="authorizationFlow"
                            label={t("forms.authorization_flow.label")}
                            placeholder={t("forms.authorization_flow.placeholder")}
                            options={authorizationFlows}
                          />
                        </GridItem>
                        <GridItem colSpan={[2, 1]}>
                          <FormikField name="clientId" label="Client ID" placeholder="Enter ID" />
                        </GridItem>
                        <GridItem colSpan={[2, 1]}>
                          <FormikField
                            name="clientSecret"
                            label={t("forms.client_secret.label")}
                            placeholder={t("forms.client_secret.placeholder")}
                          />
                        </GridItem>
                        <GridItem colSpan={[2, 1]}>
                          <FormikField
                            name="urlToken"
                            label={t("forms.url_token.label")}
                            placeholder={t("forms.url_token.placeholder")}
                          />
                        </GridItem>
                        <GridItem colSpan={[2, 1]}>
                          <FormikField
                            name="scope"
                            label={t("forms.scopes.label")}
                            placeholder={t("forms.scopes.placeholder")}
                          />
                        </GridItem>
                      </>
                    )}
                    {type === AuthenticatorTypeEnum.Basic && (
                      <>
                        <GridItem colSpan={[2, 1]}>
                          <FormikField
                            name="username"
                            label="Username"
                            placeholder="Enter Username"
                          />
                        </GridItem>
                        <GridItem colSpan={[2, 1]}>
                          <FormikField
                            name="password"
                            label="Password"
                            placeholder="Enter Password"
                          />
                        </GridItem>
                      </>
                    )}
                    {type === AuthenticatorTypeEnum.Bearer && (
                      <GridItem colSpan={[2, 1]}>
                        <FormikField
                          name="token"
                          label={t("forms.token.label")}
                          placeholder={t("forms.token.placeholder")}
                        />
                      </GridItem>
                    )}
                  </SimpleGrid>
                </ModalBody>
                <Divider mt={5} mb={1} />
                <ModalFooter>
                  <Button mr={3} variant="outline" onClick={onClose}>
                    {t("global.actions.cancel")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    isLoading={isSubmitting}
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    {authValues !== undefined
                      ? t("global.actions.update")
                      : t("global.actions.create")}
                  </Button>
                </ModalFooter>
              </Form>
            </ModalContent>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default AuthenticatorForm;
