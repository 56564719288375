import type { AllMembershipsWithAdminFieldsQuery } from "@core/generated/graphql";
import { useAllMembershipsWithAdminFieldsLazyQuery } from "@core/generated/graphql";
import { useCallback } from "react";

type SingleMembership = NonNullable<
  NonNullable<AllMembershipsWithAdminFieldsQuery["allMemberships"]>[number]
>;

function useAllMembershipsWithAdminFields() {
  const [loadMemberships, { loading, error: loadingError }] =
    useAllMembershipsWithAdminFieldsLazyQuery();

  const memberWarning = useCallback(({ slackId }: { slackId: string }) => {
    if (!slackId) {
      return "No Slack Integration: messages might be delivered over email";
    }

    return undefined;
  }, []);

  /**
   * This function will call the query to load the memberships that match the given search query and result the formatted options
   */
  const loadAllMemberships = useCallback(
    async (input: string) => {
      const res = await loadMemberships({
        variables: {
          search: input,
        },
      });
      const { allMemberships } = res.data || {};
      const memberships =
        allMemberships?.filter((membership): membership is SingleMembership => !!membership) || [];
      return memberships.map(
        ({ firstName, lastName, workspaceEmail, id, slackId, externalSystem }) => ({
          label: `${firstName} ${lastName}`,
          value: {
            id,
            firstName,
            lastName,
            externalSystem,
            _typename: "MembershipType" as const,
          },
          workspaceEmail,
          ...(memberWarning({ slackId }) ? { warningMessage: memberWarning({ slackId }) } : {}),
        })
      );
    },
    [loadMemberships, memberWarning]
  );

  return { loadAllMemberships, loading, loadingError };
}

export default useAllMembershipsWithAdminFields;
