import type { ListItemProps, ListProps } from "@chakra-ui/react";

export const MultiPickerSelectListStyles: ListProps = {
  position: "absolute",
  left: "100%",
  top: "100%",
  marginInline: 0,
  background: "white",
  borderRadius: "md",
  borderWidth: 1,
  boxShadow: "md",
  listStyleType: "none",
  minWidth: 250,
  maxWidth: 300,
};

export const MultiPickerSelectListItemStyles: ListItemProps = {
  paddingX: 2,
  paddingY: 1,
  _first: {
    borderTopRadius: "md",
  },
  _last: {
    borderBottomRadius: "md",
  },
  _hover: {
    background: "gray.100",
    cursor: "pointer",
  },
  fontSize: "sm",
};
