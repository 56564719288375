import type { TableContainerProps } from "@chakra-ui/react";
import {
  Box,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import type { MyWorkspaceTagsClientQueryResult } from "../../generated/graphql";
import TablePageLayout from "../Shared/UI/layout/MainLayout/components/TablePageLayout/TablePageLayout";
import RemoveTagButton from "./components/RemoveTagsButton";

interface EditTagsPageProps {
  workspaceTags: MyWorkspaceTagsClientQueryResult["data"];
}

const tableContainerProps: TableContainerProps = {
  borderRadius: "xl",
  padding: 3,
  border: 1,
  borderColor: "gray.100",
  borderStyle: "solid",
};

export default function EditTagsPage({ workspaceTags }: EditTagsPageProps) {
  const { t } = useTranslation();

  return (
    <TablePageLayout title={t("pages.edit_tags.title")}>
      <Box p={4} display={"flex"} justifyContent={"center"}>
        <Box display={"flex"} flexDir={"column"} gap={2} py={4} minW={"50%"}>
          <Heading size={"md"}>{t("pages.edit_tags.table.title")}</Heading>
          <TableContainer {...tableContainerProps}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>{t("pages.edit_tags.table.headers.name")}</Th>
                  <Th>
                    <Box display={"flex"} justifyContent={"center"}>
                      {t("pages.edit_tags.table.headers.actions")}
                    </Box>
                  </Th>
                </Tr>
              </Thead>

              <Tbody>
                {workspaceTags?.myWorkspace?.journeyTags.map((tag) => (
                  <Tr key={tag.id}>
                    <Td>
                      <Text color="gray.700">{tag.name}</Text>
                    </Td>
                    <Td>
                      <Box display={"flex"} justifyContent={"center"}>
                        <RemoveTagButton tagId={tag.id} />
                      </Box>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </TablePageLayout>
  );
}
