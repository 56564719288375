import { Box, Skeleton, useDisclosure } from "@chakra-ui/react";
import type { TeamFieldsFragment } from "@flows-platform/generated/graphql";
import { useAllTeamsQuery } from "@flows-platform/generated/graphql";
import SectionHeader from "@flows-platform/modules/Shared/UI/molecules/layout/SectionHeader/SectionHeader";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateEditTeamModal from "./components/CreateEditTeamModal";
import TeamOverviewTable from "./components/TeamOverviewTable";

export default function TeamsSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [teamData, setTeamData] = useState<TeamFieldsFragment | null>(null);

  const { t } = useTranslation();
  const { data: allTeamsData, loading: allTeamsLoading } = useAllTeamsQuery();

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        width="100%"
        alignItems="center"
      >
        <SectionHeader headingText={t("pages.team.title")} />

        <AddButton
          onClick={() => {
            if (teamData) setTeamData(null);
            onOpen();
          }}
        >
          {t("pages.team.actions.create")}
        </AddButton>
      </Box>
      {allTeamsLoading && <Skeleton height="100px" />}
      {!!allTeamsData?.allTeams && allTeamsData.allTeams.length > 0 && (
        <TeamOverviewTable
          teams={allTeamsData}
          onEditTeam={(team: TeamFieldsFragment) => {
            setTeamData(team);
            onOpen();
          }}
        />
      )}
      <CreateEditTeamModal isOpen={isOpen} onClose={onClose} teamData={teamData} />
    </>
  );
}
