import {
  Avatar,
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import MembershipSelector from "@flows-platform/modules/Shared/components/MembershipSelector/MembershipSelector";
import { ProjectLeadIcon } from "@flows-platform/theme/icons";
import { StackingContextEnum } from "@flows-platform/utils/enums";
import { Formik } from "formik";

import DefaultMenuControls from "./components/DefaultMenuControls/DefaultMenuControls";

const CONTAINER_SPACING = 4;

export type ProjectAssigneeType =
  | {
      id: string;
      firstName: string;
      lastName: string;
      _typename: "MembershipType";
    }
  | {
      id: string;
      name: string;
      _typename: "TeamType";
    }
  | null
  | undefined;

interface ProjectTaskAssigneeSelectorProps {
  assignee: ProjectAssigneeType;
  iconOnly?: boolean;
  onChange: (
    receiver:
      | {
          id: string;
          firstName: string;
          lastName: string;
          _typename: "MembershipType";
        }
      | {
          id: string;
          name: string;
          _typename: "TeamType";
        }
      | null
      | undefined
  ) => void;
}

function ProjectTaskAssigneeSelector({
  assignee,
  iconOnly = false,
  onChange,
}: ProjectTaskAssigneeSelectorProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const assigneeFullName = (() => {
    if (!assignee) return null;
    if ("firstName" in assignee) return `${assignee.firstName} ${assignee.lastName}`;
    if ("name" in assignee) return `Team ${assignee.name}`;
    return null;
  })();

  const handleOnChange = (
    receiver:
      | {
          id: string;
          firstName: string;
          lastName: string;
          _typename: "MembershipType";
        }
      | {
          id: string;
          name: string;
          _typename: "TeamType";
        }
      | null
      | undefined
  ) => {
    onChange(receiver);
  };
  return (
    <>
      <Menu onClose={onClose} isOpen={isOpen} computePositionOnMount>
        <MenuButton onClick={onOpen}>
          <HStack>
            <Avatar size={"sm"} name={assigneeFullName || undefined} />
            {!iconOnly && <Text fontSize="md">{assigneeFullName || "Unassigned"}</Text>}
          </HStack>
        </MenuButton>
        {isOpen && (
          <MenuList width="22rem" zIndex={StackingContextEnum.Overlay}>
            <HStack spacing={3} px={CONTAINER_SPACING}>
              <ProjectLeadIcon />
              <Text fontSize="md" fontWeight="bold" textTransform="uppercase">
                Assign To
              </Text>
            </HStack>
            <MenuDivider />
            <Box px={CONTAINER_SPACING} pb={2}>
              <Formik
                initialValues={{
                  assignee,
                }}
                enableReinitialize
                onSubmit={({ assignee: receiver }, { resetForm }) => {
                  handleOnChange(receiver);
                  resetForm();
                  onClose();
                }}
              >
                {({ isValid, dirty, isSubmitting, submitForm }) => (
                  <>
                    <MembershipSelector name="assignee" showTeams />
                    <DefaultMenuControls
                      loading={isSubmitting}
                      disabled={!isValid || !dirty}
                      onCancel={onClose}
                      onOk={submitForm}
                    />
                  </>
                )}
              </Formik>
            </Box>
          </MenuList>
        )}
      </Menu>
    </>
  );
}

export default ProjectTaskAssigneeSelector;
