import { Heading, HStack, Spinner } from "@chakra-ui/react";
import { useAllTeamsQuery, useOneMembershipCoreQuery } from "@flows-platform/generated/graphql";
import type { ProjectAssigneeType } from "@flows-platform/modules/ProjectInstance/ProjectsOverview/components/ProjectsTable/components/ProjectsTableRow/components/ProjectSteps/components/ProjectStepCheckbox/components/ProjectTaskAssigneeSelector/ProjectTaskAssigneeSelector";
import SelectUser from "@flows-platform/modules/Shared/UI/organisms/Select/SelectUser";
import ConfirmChanges from "@kwest_fe/core/src/components/UI/molecules/forms/ConfirmChanges";
import { isObjectValue } from "@kwest_fe/core/src/utils/parseFormInitialValue";
import { useMemo, useState } from "react";

interface RoleMetadataFieldProps {
  identifier: string;
  label: string;
  value: Record<string, string> | number | string;
  handleUpdate: (
    label: string,
    type: string,
    newValue: Record<string, string> | number | string
  ) => void;
  isLoading: boolean;
  type: string;
  readOnly?: boolean;
}

export default function RoleMetadataField({
  identifier,
  label,
  value,
  handleUpdate,
  isLoading,
  type,
  readOnly,
}: RoleMetadataFieldProps) {
  const [valueState, setValueState] = useState(value);
  const [closePopup, setClosePopup] = useState(false);

  const { data: { allTeams } = {} } = useAllTeamsQuery();

  const membershipId = useMemo(
    () => (isObjectValue(valueState) ? valueState.membership_id : String(valueState)),
    [valueState]
  );

  const teamId = useMemo(
    () => (isObjectValue(valueState) ? valueState.team_id : null),
    [valueState]
  );

  const { data: { oneMembership } = {}, loading } = useOneMembershipCoreQuery({
    variables: {
      id: membershipId,
    },
    skip: !membershipId,
  });

  /**
   * This value will be displayed for this role in the component depending on what value is stored
   */
  const displayValue = useMemo(() => {
    if (teamId) {
      const selectedTeam = allTeams?.find((team) => team?.id === teamId);
      return selectedTeam ? `Team ${selectedTeam?.name}` : "Unknown";
    }
    if (membershipId) {
      return oneMembership?.firstName
        ? `${oneMembership.firstName} ${oneMembership.lastName}`
        : "Unknown";
    }
    return "Unknown";
  }, [oneMembership, valueState, allTeams]);

  const handleValueUpdate = (assignee: ProjectAssigneeType) => {
    const updatedValueState =
      assignee && assignee?._typename !== "MembershipType"
        ? { team_id: assignee?.id }
        : assignee?.id || "";
    setValueState(updatedValueState);
    setClosePopup(true);
  };

  const handleConfirm = () => {
    handleUpdate(identifier, type, valueState);
  };

  const handleCancel = () => {
    setValueState(value);
  };

  return (
    <HStack alignItems="center" w="full">
      <Heading fontSize="md" textColor="gray.500" flex="1" w="50%">
        {label}
      </Heading>
      <HStack flex="1" maxW="50%">
        {loading ? (
          <Spinner size={"sm"} my={1} mx={4} />
        ) : (
          <SelectUser
            label={label}
            closePopup={closePopup}
            displayValue={displayValue}
            onSubmit={handleValueUpdate}
            showTeams
            readOnly={readOnly}
          />
        )}
        <ConfirmChanges
          isLoading={isLoading}
          display={JSON.stringify(valueState) !== JSON.stringify(value)}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        />
      </HStack>
    </HStack>
  );
}
