import { Box, HStack } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";

type TodoItemOuterWrapperProps = PropsWithChildren<{
  opacity?: number;
  padding?: number;
}>;

const TodoItemOuterWrapper = forwardRef<HTMLDivElement, TodoItemOuterWrapperProps>(
  ({ opacity, children, padding = 4 }, ref) => (
    <Box
      p={padding}
      borderRadius="md"
      borderWidth="2px"
      borderColor="gray.200"
      opacity={opacity}
      w="full"
      bg="white"
      boxShadow="md"
      ref={ref}
    >
      <HStack justifyContent="space-between" alignItems="flex-start">
        {children}
      </HStack>
    </Box>
  )
);

export default TodoItemOuterWrapper;
