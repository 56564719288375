import type { ButtonProps } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";

export default function ShadowButton({ children, ...buttonProps }: ButtonProps) {
  return (
    <Button
      {...buttonProps}
      boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)"
    >
      {children}
    </Button>
  );
}
