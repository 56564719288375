import { Button, VStack } from "@chakra-ui/react";
import { FlowStepTypeEnum } from "@flows-platform/generated/graphql";
import downloadFileFromResponse from "@kwest_fe/core/src/components/FileUploader/utils/downloadFile";
import NoPreviewUploadedView from "@kwest_fe/core/src/components/UI/organisms/NoPreviewUploadedView";
import config from "@kwest_fe/core/src/config";
import useFileUploader from "@kwest_fe/core/src/modules/Shared/hooks/useFileUploader";
import parseFileName from "@kwest_fe/core/src/utils/parseFileName";
import md5 from "md5";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { JourneyVariableProps } from "../../JourneyVariable";

export default function FileJourneyVariable({
  identifier,
  variable,
  linkedResourceId,
  linkedResourceType,
}: JourneyVariableProps) {
  let uploadUrl: string | undefined;
  const { t } = useTranslation();

  const { is_list: isList } = variable;

  const variableValuesAsArray = useMemo(() => {
    if (variable.value) return isList ? Array.from(variable.value) : [variable.value];
    return [];
  }, [variable.value]);

  if (linkedResourceType === FlowStepTypeEnum.Form) {
    uploadUrl =
      linkedResourceId && `${config.backend.uri}/api/forms/${linkedResourceId}/files/${identifier}`;
  } else {
    uploadUrl =
      linkedResourceId &&
      `${config.backend.uri}/api/journeys/${linkedResourceId}/files/${identifier}`;
  }

  const { downloadFile } = useFileUploader({
    uploadUrl,
  });

  const getFileDownloadPath = (index: number) => {
    if (uploadUrl) {
      const filePath = variableValuesAsArray[index];
      return isList && filePath ? `${uploadUrl}/values/${md5(filePath)}` : uploadUrl;
    }
    return undefined;
  };

  const handleDownloadFile = async (index: number, fileName: string) => {
    const downloadUrl = getFileDownloadPath(index);
    if (downloadUrl) {
      const response = await downloadFile(downloadUrl);
      downloadFileFromResponse(fileName, response?.data, response?.headers["content-type"]);
    }
  };

  const handleDownloadAllFiles = async () => {
    if (uploadUrl) {
      const response = await downloadFile(uploadUrl);
      downloadFileFromResponse("download.zip", response?.data, response?.headers["content-type"]);
    }
  };

  return variableValuesAsArray.length > 0 ? (
    <VStack w="full" alignItems="start">
      {variableValuesAsArray?.map((variableValue, index) => (
        <NoPreviewUploadedView
          type={variable.type}
          fileUrl={getFileDownloadPath(index)}
          fileName={parseFileName(variableValue)}
          onDownloadFile={async (_, fileName) => handleDownloadFile(index, fileName)}
        />
      ))}
      {isList && variableValuesAsArray.length > 1 && (
        <Button onClick={handleDownloadAllFiles}>{t("global.actions.download_all")}</Button>
      )}
    </VStack>
  ) : null;
}
