import { Checkbox, HStack, Text, VStack } from "@chakra-ui/react";
import { useUpdateTaskDueDateClientMutation } from "@flows-platform/generated/graphql";
import { useProjectSteps } from "@flows-platform/modules/Shared/providers/ProjectStepContext";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import React from "react";
import { useTranslation } from "react-i18next";

import { inputLabelWidth } from "../../../../constants/constants";
import DateField from "../../../DateField";
import type { ProjectAssigneeType } from "../../../ProjectTaskAssigneeSelector/ProjectTaskAssigneeSelector";
import ProjectTaskAssigneeSelector from "../../../ProjectTaskAssigneeSelector/ProjectTaskAssigneeSelector";

interface StatusBarProps {
  stepId: string;
  task: {
    dueDate?: string | null;
    id: string;
    receiver?: {
      id: string;
      firstName: string;
      lastName: string;
      _typename: "MembershipType";
    };
    receiverTeam?: {
      id: string;
      name: string;
      _typename: "TeamType";
    } | null;
  };
  isChecked: boolean;
  onToggle?: () => Promise<void>;
}

export default function StatusBar({ stepId, task, isChecked, onToggle }: StatusBarProps) {
  const [loading, setLoading] = React.useState(false);
  const { reassignJourneyStep } = useProjectSteps();
  const { errorToast } = useDefaultToasts();
  const { t } = useTranslation();
  const [dueDate, setDueDate] = React.useState<string | null>(task?.dueDate || null);
  const [updateDueDate] = useUpdateTaskDueDateClientMutation({
    onCompleted: (data) => {
      const updatedTask = data.updateTaskDueDate?.projectStep?.task;
      setDueDate(updatedTask?.dueDate);
    },
    onError(e) {
      errorToast(t("pages.project_instance.toast.update_due_date.error.title"))({
        message: e.message,
      });
    },
  });

  const onTaskAssigneeChanged = async (receiver: ProjectAssigneeType) => {
    const updatePayload =
      receiver?._typename === "MembershipType"
        ? { receiverId: receiver?.id }
        : { receiverTeamId: receiver?.id };

    return reassignJourneyStep({
      variables: {
        input: {
          id: stepId,
          ...updatePayload,
        },
      },
      onError(e) {
        errorToast(t("pages.project_instance.toast.update_task_assignee.error.title"))({
          message: e.message,
        });
      },
    });
  };

  const taskReceiver = task?.receiver || task?.receiverTeam;

  return (
    <VStack width="full" borderColor="gray.100" borderWidth={1} py={2} px={6} borderX="none">
      <HStack gap={3} py={2} width="full">
        <Text textStyle={TEXT_STYLES.lead14SemiBold} width={inputLabelWidth}>
          {t("forms.project_task_status.label")}:
        </Text>
        <Checkbox
          data-testid="status-bar-checkbox"
          isChecked={isChecked}
          onChange={async () => {
            setLoading(true);
            if (onToggle) await onToggle();
            setLoading(false);
          }}
          disabled={loading}
        >
          {t("global.actions.done")}
        </Checkbox>
      </HStack>
      <HStack gap={3} py={2} width="full">
        <Text textStyle={TEXT_STYLES.lead14SemiBold} width={inputLabelWidth}>
          {t("forms.project_task_due_date.label")}:
        </Text>
        <DateField
          date={dueDate}
          onDateUpdate={async (updatedDate) => {
            await updateDueDate({
              variables: {
                taskId: task?.id,
                dueDate: updatedDate,
              },
            });
          }}
        />
      </HStack>
      <HStack gap={3} py={2} width="full">
        <Text textStyle={TEXT_STYLES.lead14SemiBold} width={inputLabelWidth}>
          {t("forms.project_task_assignee.label")}:
        </Text>
        <ProjectTaskAssigneeSelector assignee={taskReceiver} onChange={onTaskAssigneeChanged} />
      </HStack>
    </VStack>
  );
}
