import type { QueryHookOptions } from "@apollo/client";
import type {
  AllJourneysWithProjectTasksQuery,
  AllJourneysWithProjectTasksQueryVariables,
} from "@flows-platform/generated/graphql";
import { useAllJourneysWithProjectTasksQuery } from "@flows-platform/generated/graphql";

function useAllJourneysQueryWithPagination(
  options?: QueryHookOptions<
    AllJourneysWithProjectTasksQuery,
    AllJourneysWithProjectTasksQueryVariables
  >
) {
  const { loading, error, data, fetchMore, refetch } = useAllJourneysWithProjectTasksQuery(options);

  const limit = data?.allJourneys?.limit;
  const totalRetrieved = (data?.allJourneys?.offset ?? 0) + (data?.allJourneys?.count ?? 0);

  const fetchMoreData = () => {
    if (!limit) return;
    void fetchMore({
      variables: {
        pagination: {
          limit,
          offset: totalRetrieved,
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        // // Updates the query after adding in the new results
        if (!fetchMoreResult.allJourneys) return prev;
        const {
          offset,
          limit: fetchMoreLimit,
          count,
          total,
          more,
          first,
        } = fetchMoreResult.allJourneys;
        return {
          allJourneys: {
            __typename: "PaginatedJourneyList",
            offset,
            limit: fetchMoreLimit,
            count,
            total,
            more,
            first,
            results: [
              ...(prev.allJourneys?.results ?? []),
              ...(fetchMoreResult.allJourneys.results ?? []),
            ],
          },
        };
      },
    });
  };

  return { data, loading, error, fetchMore, fetchMoreData, refetch, totalRetrieved };
}

export default useAllJourneysQueryWithPagination;
