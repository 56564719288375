import { Box } from "@chakra-ui/react";
import { LeftSidebarIcon, RightSidebarIcon } from "@core/theme/icons";

interface ToggleSidebarProps {
  side: "left" | "right";
  onClick: () => void;
}
const ToggleSidebar = ({ side, onClick }: ToggleSidebarProps) => {
  return (
    <Box
      display={{ base: "flex", lg: "none" }}
      cursor={"pointer"}
      rounded={"md"}
      p={1}
      _hover={{ bgColor: "gray.100" }}
      _active={{ bgColor: "gray.300" }}
      onClick={onClick}
    >
      {side === "left" ? <LeftSidebarIcon /> : <RightSidebarIcon />}
    </Box>
  );
};

export default ToggleSidebar;
