import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import type { CurrentEditorType } from "../../../../../../types";

interface CurrentEditorModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentEditor: CurrentEditorType;
}

function CurrentEditorModal({ isOpen, onClose, currentEditor }: CurrentEditorModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Flow is currently being edited</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            One moment please. {currentEditor?.firstName} ({currentEditor?.workspaceEmail}) is
            currently editing this flow
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="purple" onClick={onClose}>
            {t("global.actions.ok")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CurrentEditorModal;
