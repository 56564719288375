import type { ModalProps } from "@chakra-ui/react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface GenericDeleteModalProps extends Pick<ModalProps, "isOpen" | "onClose" | "size"> {
  onConfirm: () => void;
  header?: string;
  body?: string;
  isLoading?: boolean;
}

function GenericDeleteModal({
  header = "item",
  isOpen,
  onClose,
  onConfirm,
  body,
  size = "md",
  isLoading,
}: GenericDeleteModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size || "lg"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete the {header}?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {body ? <Text>{body}</Text> : <Text>You will not be able to recover it</Text>}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onClose} data-testid="delete-modal-cancel-button">
            {t("global.actions.cancel")}
          </Button>
          <Button
            colorScheme="red"
            mr={3}
            onClick={onConfirm}
            data-testid="delete-modal-confirm-button"
            isLoading={isLoading}
          >
            {t("global.actions.delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default GenericDeleteModal;
