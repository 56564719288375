import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import queryString from "query-string";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import FlowAssignmentModal from "../../FlowTemplates/components/FlowAssignmentModal/FlowAssignmentModal";
import ProjectAssignmentModal from "../../ProjectTemplates/components/ProjectsOverviewTable/components/ProjectAssignmentModal";
import {
  FlowAssignmentVariablesContextConsumer,
  FlowAssignmentVariablesProvider,
} from "../../Shared/providers/FlowAssignmentVariables";

function FlowRunnerContainer() {
  const { flowId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = queryString.parse(location.search);

  const onClose = () => {
    navigate("/todo/complete");
  };

  if (!flowId)
    return (
      <Placeholder type="error" header="Something went wrong while trying to load the flow data" />
    );

  return (
    <ErrorBoundary>
      <FlowAssignmentVariablesProvider flowId={flowId} initialValues={queryParams}>
        <FlowAssignmentVariablesContextConsumer>
          {({ flow }) =>
            !flow?.isProject ? (
              <FlowAssignmentModal
                flowId={flowId}
                isOpen
                onClose={onClose}
                autoStartFlow
                isInline
              />
            ) : (
              <ProjectAssignmentModal
                projectTemplateId={flowId}
                isOpen
                onClose={onClose}
                autoStartFlow
                isInline
              />
            )
          }
        </FlowAssignmentVariablesContextConsumer>
      </FlowAssignmentVariablesProvider>
    </ErrorBoundary>
  );
}

export default FlowRunnerContainer;
