import {
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { SiMinutemailer as EmailIcon } from "react-icons/si";

interface VerifyEmailPageProps {
  heading: string;
  message: JSX.Element;
  actions: JSX.Element;
}

function VerifyEmailPage({ heading, message, actions }: VerifyEmailPageProps) {
  return (
    <Modal size="xl" isOpen isCentered onClose={() => {}}>
      <ModalOverlay />

      <ModalContent>
        <ModalBody p={4}>
          <VStack spacing={6}>
            <Heading size="xl">
              <EmailIcon />
            </Heading>
            <Heading>{heading && heading}</Heading>
            <Text textAlign="center">{message}</Text>

            {actions}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default VerifyEmailPage;
