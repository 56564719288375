import type { BoxProps } from "@chakra-ui/react";

export const PlaceholderVariableStyles: BoxProps = {
  padding: "2px 5px",
  verticalAlign: "baseline",
  display: "inline",
  // TODO: Replace with theme colors
  background: "#D2D1F9",
  color: "#4947A4",
  contentEditable: false,
  whiteSpace: "nowrap",
};

export const HighlightStyles: BoxProps = {
  boxShadow: "0 0 4px 2px #B4D5FF",
};

export default PlaceholderVariableStyles;
