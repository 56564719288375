import type { StyleFunctionProps } from "@chakra-ui/react";
import { extendTheme, theme as base } from "@chakra-ui/react";

import { alertTheme } from "./alert";
import { menuTheme } from "./menu";
import textStyles from "./text";

const theme = extendTheme({
  textStyles,
  styles: {
    global: {
      body: {
        color: "#1E0D35",
      },
    },
  },
  colors: {
    tako: {
      input: {
        icon: "#483760",
        text: "#1E0D35",
        border: "#291544",
      },
      button: {
        bg: "#721CE3",
      },
    },
    purple: {
      100: "#F0F0FD",
      200: "#D2D1F9",
      300: "#B4B3F5",
      400: "#A4A3F2",
      450: "#8685EE",
      500: "#6866ea",
      600: "#5352BB",
      700: "#4947A4",
      800: "#3E3D8C",
      900: "#2A295E",
    },
    primary: {
      // background: "#fcfdfe",
      background: "#ffffff",
      border: base.colors.gray[100],
      main: base.colors.purple[300],
      xlight: base.colors.purple[50],
      light: base.colors.purple[100],
      dark: base.colors.purple[600],
      xdark: base.colors.purple[900],
    },
  },
  sizes: {
    container: {
      centerStage: "1020px",
      sideNavigation: "216px",
      flowEditorLibrary: "200px",
      ...base.sizes.container,
    },
  },
  fonts: {
    heading: `'Work Sans', sans-serif`,
    body: `'Work Sans', sans-serif`,
    mono: "Inconsolata, Menlo, monospace",
  },
  fontSizes: {
    sm: "12px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  shadows: { outline: "0 0 0 1px var(--chakra-colors-gray-600)" },
  components: {
    Alert: alertTheme,
    Checkbox: {
      baseStyle: {
        label: textStyles.checkbox,
        control: {
          _checked: {
            bg: "#6866EA",
            borderColor: "#6866EA",

            _hover: {
              bg: "#6866EA",
              borderColor: "#6866EA",
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        borderOnHover: (props: StyleFunctionProps) => ({
          ...base.components.Textarea.variants?.outline(props),
          borderColor: "transparent",
          _hover: {
            borderColor: "gray.200",
          },
        }),
      },
    },
    Input: {
      variants: {
        borderOnHover: (props: StyleFunctionProps) => ({
          field: {
            ...base.components.Input.variants?.outline(props).field,
            borderColor: "transparent",
            fontWeight: "600",
            _hover: {
              borderColor: "gray.200",
            },
            _active: {
              borderColor: "gray.200",
            },
          },
        }),
        titleInput: {
          field: {
            height: "30px",
            padding: 0,
            color: "gray.600",
            fontWeight: "600",
            borderRadius: "none",
            borderBottomWidth: "2px",
            borderBottomColor: "transparent",
            borderBottomStyle: "dashed",
            _hover: {
              borderColor: "gray.600",
            },
            _active: {
              borderColor: "gray.600",
            },
          },
        },
        ghost: {
          field: {
            fontSize: "md",
            textColor: "gray.700",
            fontWeight: 400,
            w: "50%",
            px: 3,
            py: 1.5,
            _hover: {
              bg: "gray.100",
              color: "gray.600",
            },
            _focus: {
              bg: "gray.100",
              color: "gray.600",
            },
          },
        },
      },
    },
    Button: {
      variants: {
        solid: {
          h: "32px",
          fontSize: "14px",
        },
        outline: {
          fontSize: "14px",
          h: "32px",
        },
        ghostDark: {
          ...base.components.Button.variants?.ghost,
          _hover: {
            backgroundColor: "gray.200",
          },
        },
        libraryButton: {
          textTransform: "uppercase",
          fontSize: "xs",
          height: "auto",
          padding: 2,
          width: "full",
          justifyContent: "flex-start",
          fontWeight: "700",
          border: "1px solid",
          borderColor: "gray.200",
          borderRadius: "md",
          _hover: {
            borderColor: "gray.400",
          },
          _active: {
            borderColor: "gray.900",
          },
        },
      },
    },
    Text: {
      baseStyle: {
        color: "gray.700",
      },
    },
    Heading: {
      baseStyle: {
        color: "gray.700",
      },
    },
    Menu: menuTheme,
  },

  // withDefaultColorScheme({
  //   colorScheme: 'brand',
  //   components: ['Checkbox'], // now, checkbox component always uses brand colors
  //                        }),
  // withDefaultVariant({
  //   variant: 'filled',
  //   components: ['Input, Select'], // now, all Input and Select appearances use the filled variant.
  //                    }),
});

export default theme;
