import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";

import type {
  AllFlowsQuery,
  DuplicateFlowClientMutationFn,
  SetFlowCurrentEditorClientMutationFn,
} from "../../generated/graphql";
import {
  useAllFlowsQuery,
  useDuplicateFlowClientMutation,
  useSetFlowCurrentEditorClientMutation,
} from "../../generated/graphql";
import ALL_FLOWS from "../../graphql/queries/flows/allFlows";
import LoadingListSkeleton from "../Shared/components/LoadingListSkeleton";

interface FlowsOverviewContainerProps {
  render: (
    flows: AllFlowsQuery["allMyAdminFlows"],
    duplicateFlow: DuplicateFlowClientMutationFn,
    setFlowCurrentEditor: SetFlowCurrentEditorClientMutationFn
  ) => React.ReactNode;
}

function FlowsOverviewContainer({ render }: FlowsOverviewContainerProps) {
  const flowsQuery = useAllFlowsQuery({
    variables: {
      projectsOnly: false,
    },
    fetchPolicy: "cache-and-network",
  });
  const [setFlowCurrentEditor] = useSetFlowCurrentEditorClientMutation();
  const [duplicateFlow] = useDuplicateFlowClientMutation({
    refetchQueries: [ALL_FLOWS, "AllFlows"],
  });

  if (flowsQuery.loading) {
    return <LoadingListSkeleton />;
  }

  if (flowsQuery.error) {
    return <Placeholder type="error" header="Error loading flows" />;
  }

  const flows = flowsQuery.data?.allMyAdminFlows;

  if (!flows?.length) {
    return (
      <Placeholder
        type="empty-state"
        header={"You haven't added any flows"}
        description="Use the purple button to add your first flow"
      />
    );
  }

  return <ErrorBoundary>{render(flows, duplicateFlow, setFlowCurrentEditor)}</ErrorBoundary>;
}

export default FlowsOverviewContainer;
