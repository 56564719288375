import { useApolloClient } from "@apollo/client";
import {
  Button,
  GridItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import type { CreateMembershipClientMutationFn } from "@flows-platform/generated/graphql";
import { useMyMembershipQuery } from "@flows-platform/generated/graphql";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import toastSettings from "@flows-platform/theme/settings";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CreateMembershipSchema from "./schema";

interface CreateMembershipPageProps {
  createMembership: CreateMembershipClientMutationFn;
  workspaceEmail?: string;
}

function CreateMembershipPage({
  createMembership,
  workspaceEmail = "",
}: CreateMembershipPageProps) {
  const myMembershipQuery = useMyMembershipQuery();
  const toast = useToast();
  const navigate = useNavigate();
  const client = useApolloClient();

  useEffect(() => {
    if (!myMembershipQuery.loading && myMembershipQuery.data?.myMembership) {
      navigate("/flow-templates");
    }
  }, [myMembershipQuery.loading]);

  const initialFormValues = {
    firstName: "",
    lastName: "",
    workspaceEmail,
    startDate: "",
  };

  return (
    <Modal size="xl" isOpen onClose={() => {}}>
      <ModalOverlay />
      <Formik
        initialValues={initialFormValues}
        validationSchema={CreateMembershipSchema}
        onSubmit={(values) => {
          void createMembership({
            variables: {
              input: values,
            },
            onCompleted: () => {
              toast({
                ...toastSettings,
                status: "success",
                title: "Successfully created membership",
              });

              // Refetch the membership before redirecting
              void client.refetchQueries({
                include: "active",
              });

              // Force a page reload
              navigate(0);
            },
          });
        }}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <ModalContent>
            <Form>
              <ModalHeader>Create membership</ModalHeader>
              <ModalBody>
                <SimpleGrid columns={2} columnGap={3} rowGap={6}>
                  <GridItem colSpan={[2, 1]}>
                    <FormikField
                      name="firstName"
                      label="First name"
                      placeholder="Dorothy"
                      required
                    />
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormikField name="lastName" label="Last name" placeholder="Gale" required />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormikField
                      name="workspaceEmail"
                      label="Company email"
                      type="email"
                      placeholder="dorothy.gale@emeraldcity.com"
                      required
                    />
                  </GridItem>
                  <GridItem colSpan={[2, 1]}>
                    <FormikField
                      name="startDate"
                      label="Employment start date"
                      type="date"
                      required
                    />
                  </GridItem>
                </SimpleGrid>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="purple"
                  type="submit"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  Create membership
                </Button>
              </ModalFooter>
            </Form>
          </ModalContent>
        )}
      </Formik>
    </Modal>
  );
}

export default CreateMembershipPage;
