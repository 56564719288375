import SideNavigationContainer from "@flows-platform/modules/Shared/UI/layout/MainLayout/components/SideNavigationContainer/SideNavigationContainer";
import { StackingContextEnum } from "@flows-platform/utils/enums";
import { AnimatePresence } from "framer-motion";

import { useLayout } from "../../../LayoutProvider";
import MotionBox from "../../../MotionBox";

const sidebarWidth = 200;

const MobileSidebar = () => {
  const { state, dispatch } = useLayout();

  return (
    <AnimatePresence>
      {state.isLeftSidebarOpen && (
        <MotionBox
          initial={{ x: -sidebarWidth }}
          animate={{ x: 0 }}
          exit={{ x: -sidebarWidth }}
          transition={"ease-in-out"}
          w={sidebarWidth}
          h="full"
          p={0}
          pb={8}
          alignItems="flex-center"
          bg="white"
          borderRight="1px"
          borderColor="primary.border"
          position="fixed"
          display={{ base: "flex", lg: "none" }}
          zIndex={StackingContextEnum.Foreground + 1}
        >
          <SideNavigationContainer
            onChange={() => {
              dispatch({ type: "TOGGLE_LEFT_SIDEBAR" });
            }}
          />
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default MobileSidebar;
