import { Box, Text } from "@chakra-ui/react";
import type { ProjectStepAwaitingUserFieldsFragment } from "@flows-platform/generated/graphql";
import ProjectStepCardsBoard from "@flows-platform/modules/TodoItems/components/TodosView/components/ProjectTasksTodosBoard/components/ProjectStepCardsBoard/ProjectStepCardsBoard";
import ComponentSpinner from "@kwest_fe/core/src/components/UI/atoms/ComponentSpinner";
import { useTranslation } from "react-i18next";

interface ProjectTasksTodosBoardProps {
  todos: (ProjectStepAwaitingUserFieldsFragment | null)[] | null | undefined;
  isLoading: boolean;
}

const ProjectTasksTodosBoard = ({ todos, isLoading }: ProjectTasksTodosBoardProps) => {
  const { t } = useTranslation();

  if (isLoading) return <ComponentSpinner />;

  return (
    <>
      {!todos?.length ? (
        <Box ml={5}>
          <Text fontSize={"14px"}>{t("pages.inbox.table.empty_message")}</Text>
        </Box>
      ) : (
        <ProjectStepCardsBoard projects={todos} />
      )}
    </>
  );
};

export default ProjectTasksTodosBoard;
