import { Alert, Button, Text, VStack } from "@chakra-ui/react";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import config from "@kwest_fe/core/src/config";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import type { FormSubmitInputType, JourneyStepResourceQuery } from "@todo-viewer/generated/graphql";
import { useSubmitFormClientMutation } from "@todo-viewer/generated/graphql";
import messageParent from "@todo-viewer/modules/Core/utils/messageParent";
import useRedirectToNextStep from "@todo-viewer/modules/TodoView/hooks/useRedirectToNextStep";
import { useTodoView } from "@todo-viewer/modules/TodoView/providers/TodoViewProvider";
import compareObjects from "@todo-viewer/utils/compareObjects";
import type { FormikErrors, FormikHelpers } from "formik";
import { Form, Formik } from "formik";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { BsCheck2 as CheckIcon } from "react-icons/bs";

import getFormValidationSchema from "../../schema";
import TodoViewApprovalComment from "../TodoViewApprovalComment/TodoViewApprovalComment";
import TodoViewLayout from "../TodoViewLayout";
import TodoViewPageHeader from "../TodoViewPageHeader";
import FormikFormItem from "./components/FormikFormItem";

interface TodoViewFormProps {
  resource: JourneyStepResourceQuery["getJourneyStepResource"] & {
    __typename: "FormResourceType";
  };
}

function TodoViewForm({ resource }: TodoViewFormProps) {
  const { t } = useTranslation();
  const { offlineImages, uploadOfflineImages, setOfflineImages } = useTodoView();

  const offlineImagesKeys = Object.keys(offlineImages);

  const [submitForm] = useSubmitFormClientMutation();
  const { successToast, errorToast } = useDefaultToasts();
  const redirectToNextStep = useRedirectToNextStep();

  const handleSubmit = async (form: FormSubmitInputType) => {
    const { id, stepVariables } = form || {};
    const res = await submitForm({
      variables: {
        input: {
          id,
          stepVariables: JSON.stringify(stepVariables),
        },
      },
      onCompleted(data) {
        successToast("Form submitted successfully");
        messageParent("task_completed");
        redirectToNextStep(data.submitForm?.nextStep, resource?.journeyStep?.projectId);
      },
      onError(err) {
        errorToast("There was an error while trying to submit the form")(err);
      },
    });

    return res;
  };

  if (!resource) return null;
  const { title, receiver, orderedFormItems, id, journeyStep, rejectedInApproval, receiverTeam } =
    resource;

  if (orderedFormItems?.length === 0) {
    return (
      <Placeholder type="empty-state" header={t("pages.learner.messages.form_with_no_item")} />
    );
  }

  const isCompleted = Boolean(journeyStep?.completedAt);

  const journeyStepVariables = journeyStep?.variables ? JSON.parse(journeyStep.variables) : {};

  const getJourneyStepVariable = (variableIdentifier?: string | null) => {
    if (!variableIdentifier) return null;
    const variable = journeyStepVariables[variableIdentifier];
    if (!variable?.value && variable?.value !== false) return null;
    return variable.value;
  };

  // We are initializing the initial variables using the labels as variable names
  const initialFormValues = {
    form: {
      id,
      stepVariables: orderedFormItems?.reduce((acc, formItem) => {
        const variableValue = getJourneyStepVariable(formItem?.variableIdentifier);
        return {
          ...acc,
          ...(formItem?.variableIdentifier && (variableValue || !formItem.isOptional)
            ? {
                [formItem.variableIdentifier]: variableValue,
              }
            : {}),
        };
      }, {}),
    },
  };

  const hasInvalidNonFileFields = (err: FormikErrors<typeof initialFormValues>) => {
    let onlyInvalidFileFields = false;
    if (typeof err.form?.stepVariables === "object") {
      const variableIdentifiers = Object.keys(offlineImages).filter(
        (variableIdentifier) => (offlineImages[variableIdentifier].files || []).length > 0
      );
      onlyInvalidFileFields = Object.keys(err.form?.stepVariables).every((errorField) =>
        variableIdentifiers.includes(errorField)
      );
    }
    return !onlyInvalidFileFields;
  };

  const onSubmitForm = async (
    values: typeof initialFormValues,
    { setSubmitting }: FormikHelpers<typeof initialFormValues>
  ) => {
    const modifiedValues = compareObjects(values, initialFormValues) as typeof values;
    const { modifiedStepVariables, errorUploads } = await uploadOfflineImages(
      modifiedValues.form.stepVariables,
      (variableIdentifier) => `${config.backend.uri}/api/forms/${id}/files/${variableIdentifier}`
    );
    const uploadComplete = Object.keys(errorUploads).length === 0;
    if (uploadComplete) {
      return handleSubmit({
        ...(modifiedValues.form
          ? {
              ...modifiedValues.form,
              stepVariables: {
                ...modifiedValues.form.stepVariables,
                ...modifiedStepVariables,
              },
            }
          : { id, stepVariables: {} }),
      }).finally(() => {
        setSubmitting(false);
      });
    }
    setOfflineImages((prevState) => ({
      ...prevState,
      ...errorUploads,
    }));
    setSubmitting(false);
    return true;
  };

  return (
    <TodoViewLayout
      isCompleted={!!journeyStep?.completedAt}
      heading={
        <TodoViewPageHeader
          journeyStep={journeyStep}
          receiver={receiver}
          receiverTeam={receiverTeam}
          title={title}
        />
      }
    >
      <Formik
        initialValues={initialFormValues}
        validationSchema={getFormValidationSchema(orderedFormItems, offlineImages)}
        onSubmit={onSubmitForm}
        enableReinitialize
        validateOnMount
      >
        {({ isSubmitting, isValid, errors }) => (
          <Form style={{ width: "100%" }}>
            <VStack spacing={10} alignItems="flex-start">
              {rejectedInApproval && (
                <TodoViewApprovalComment rejectedInApproval={rejectedInApproval} />
              )}
              {offlineImagesKeys.length > 0 && (
                <Alert status="error" borderRadius="md" size="sm">
                  <Text flexGrow={1} fontSize="md">
                    You are currently offline but you can continue working as your changes will be
                    saved
                  </Text>
                </Alert>
              )}
              {orderedFormItems?.map(
                (item) =>
                  item && (
                    <FormikFormItem
                      key={item.id}
                      formItem={item}
                      formId={id}
                      disabled={isCompleted}
                    />
                  )
              )}

              <VStack w={"full"} align={"start"}>
                <Button
                  colorScheme="purple"
                  width="full"
                  type="submit"
                  leftIcon={<CheckIcon />}
                  isLoading={isSubmitting}
                  isDisabled={
                    isSubmitting || (!isValid && hasInvalidNonFileFields(errors)) || isCompleted
                  }
                  data-testid="todo-view-form-done-button"
                >
                  {t("global.actions.done")}
                </Button>
                {!isValid && (
                  <Text color="red.500" fontSize={"small"}>
                    *{t("forms.error.fields_missing")}
                  </Text>
                )}
              </VStack>
            </VStack>
          </Form>
        )}
      </Formik>
    </TodoViewLayout>
  );
}

export default memo(TodoViewForm);
