import { useApolloClient } from "@apollo/client";
import type { IconButtonProps } from "@chakra-ui/react";
import {
  Box,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import { FlowStepTypeEnum } from "@flows-platform/generated/graphql";
import DeleteFlowStepModal from "@flows-platform/modules/Shared/UI/organisms/modals/DeleteFlowStepModal/DeleteFlowStepModal";
import type { OneOrderedFlowStep } from "@flows-platform/types";
import { StackingContextEnum } from "@flows-platform/utils/enums";
import { DragIndicatorIcon, DuplicateIcon, KebabIcon } from "@kwest_fe/core/src/theme/icons";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import FlowStepWarnings from "../../../../../FlowStepWarnings/FlowStepWarnings";
import { flowStepTypes } from "../../constants/constants";
import FlowStepPhaseLabel from "../FlowStepPhaseLabel/FlowStepPhaseLabel";
import FlowStepPeriodSelector from "./components/FlowStepPeriodSelector";

const KebabIconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
  <IconButton
    ref={ref}
    variant="solid"
    size="sm"
    cursor="pointer"
    icon={<KebabIcon />}
    {...props}
    aria-label="Flow step menu"
  />
));

interface FlowStepInCanvasProps {
  flowId: string;
  step: NonNullable<OneOrderedFlowStep>;
  isDragPlaceholder: boolean;
  draggable: boolean;
  opacity: number;
  hidePeriodSelector: boolean;
  showPhases: boolean;
  index: number;
  onClick?: (step: OneOrderedFlowStep) => void;
}

const FlowStepInCanvas = forwardRef<HTMLDivElement, FlowStepInCanvasProps>(
  (
    {
      flowId,
      step,
      isDragPlaceholder,
      draggable,
      opacity,
      hidePeriodSelector,
      showPhases,
      index,
      onClick,
      ...rest
    },
    ref
  ) => {
    const { updateFlowstepFields, duplicateStep } = useFlowEditor();
    const client = useApolloClient();
    const { t } = useTranslation();

    return (
      <Box ref={ref} opacity={opacity} position="relative" {...rest}>
        {draggable && !hidePeriodSelector && (
          <FlowStepPeriodSelector
            periodInDays={step?.waitDuration || 0}
            visible={!hidePeriodSelector}
            onPeriodChanged={(updatedPeriod) => {
              updateFlowstepFields({
                client,
                flowId,
                updateIndex: index,
                updateFields: {
                  waitDuration: updatedPeriod,
                },
              });
            }}
          />
        )}
        {showPhases && step.phase && <FlowStepPhaseLabel phase={step.phase} />}
        <Box
          p={4}
          borderRadius="md"
          position="relative"
          backgroundColor="white"
          cursor="pointer"
          boxShadow="md"
          transition="box-shadow 200ms linear"
          transitionDuration="200ms"
          data-testid={`flow-step-${step.stepType}`}
          sx={{
            ...(step.stepType !== FlowStepTypeEnum.Trigger && { _hover: { boxShadow: "2xl" } }),
          }}
          onClick={() => {
            onClick?.(step as OneOrderedFlowStep);
          }}
          {...rest}
        >
          {isDragPlaceholder && (
            <Box
              pos="absolute"
              top={0}
              left={0}
              right={0}
              bottom={0}
              bg="gray.100"
              borderRadius="md"
              borderWidth="2px"
              borderColor="gray.500"
              borderStyle="dotted"
              zIndex={StackingContextEnum.Overlay}
            />
          )}
          <HStack spacing={4} justifyContent="space-between" width="full">
            <HStack spacing={0} width="full">
              {draggable && (
                <IconButton
                  variant="ghost"
                  aria-label="Move flow step"
                  cursor="grab"
                  color="gray.300"
                  icon={<DragIndicatorIcon />}
                  mr={3}
                />
              )}

              <VStack alignItems="flex-start" flex={1}>
                <HStack>
                  {flowStepTypes[step.stepType]?.icon}
                  <Heading size="sm" textTransform="uppercase">
                    {step.stepType}
                  </Heading>
                </HStack>
                <Text fontWeight="bold">{step.name}</Text>
              </VStack>
              {draggable && (
                <Box
                  display="inline-flex"
                  flexDirection="column"
                  alignItems="flex-end"
                  alignSelf="start"
                  justifyContent="flex-end"
                >
                  <Menu flip placement="bottom-end">
                    <MenuButton
                      as={KebabIconButton}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    <MenuList>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          if (step.id)
                            duplicateStep({
                              variables: {
                                flowStepId: step.id,
                              },
                            });
                        }}
                      >
                        <HStack>
                          <DuplicateIcon />
                          <Text fontSize="md">{t("pages.flow_step.actions.duplicate")}</Text>
                        </HStack>
                      </MenuItem>
                      {step?.id && <DeleteFlowStepModal flowStepId={step?.id} flowId={flowId} />}
                    </MenuList>
                  </Menu>
                  {step?.warnings && <FlowStepWarnings warnings={step.warnings} />}
                </Box>
              )}
            </HStack>
          </HStack>
        </Box>
      </Box>
    );
  }
);

export default FlowStepInCanvas;
