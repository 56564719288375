import type { InputProps } from "@chakra-ui/react";
import { FormControl, FormErrorMessage, FormLabel, HStack, Input, Text } from "@chakra-ui/react";
import CaptionText from "@kwest_fe/core/src/components/UI/atoms/Text/CaptionText/CaptionText";
import type { FieldInputProps, FieldProps } from "formik";
import { ErrorMessage, Field } from "formik";
import type { LegacyRef } from "react";
import { useTranslation } from "react-i18next";

import { TEXT_STYLES } from "../../../../theme/text";

interface FormikFieldProps {
  name: string;
  label?: string;
  type?: InputProps["type"];
  required?: boolean;
  placeholder?: string;
  variant?: string;
  innerRef?: LegacyRef<any>;
  renderElement?: (
    props: FieldInputProps<any> &
      Pick<FormikFieldProps, "placeholder" | "type" | "variant"> & {
        ref: FormikFieldProps["innerRef"];
      }
  ) => JSX.Element;
  showOptionalIndicator?: boolean;
  disabled?: boolean;
  captionText?: string;
}

/**
 * Utility component to use text inputs within Formik forms.
 */
function FormikField({
  name,
  label,
  type = "text",
  required,
  placeholder,
  variant,
  innerRef,
  renderElement,
  showOptionalIndicator = false,
  disabled = false,
  captionText,
}: FormikFieldProps) {
  const { t } = useTranslation();
  const showIndicator = !required && showOptionalIndicator;
  return (
    <Field name={name} innerRef={innerRef}>
      {({ field, meta: { error, touched } }: FieldProps) => {
        const collectedProps = {
          ...field,
          disabled,
          placeholder,
          variant,
          type,
          ref: innerRef,
          onBlur: field.onBlur,
        };
        const render = (props: InputProps) => (
          <Input
            {...props}
            backgroundColor={"#FCFCFC"}
            borderColor={"#e0dee3"}
            color={"tako.input.text"}
            _disabled={{
              backgroundColor: "#E9E9EC",
            }}
          />
        );

        return (
          <FormControl
            isInvalid={!!error && touched}
            isRequired={required}
            data-testid={`formik-field-${name}`}
          >
            {label && (
              <FormLabel
                display="inline-flex"
                flexWrap="wrap"
                requiredIndicator={<Text as="span" />}
              >
                <HStack>
                  <Text as="span" color={"tako.input.text"}>
                    {label}
                  </Text>
                  {showIndicator && (
                    <Text textStyle={TEXT_STYLES.small12} as="span">
                      (optional)
                    </Text>
                  )}
                </HStack>
              </FormLabel>
            )}
            {renderElement ? renderElement(collectedProps) : render(collectedProps)}
            <CaptionText>{captionText}</CaptionText>
            <ErrorMessage
              name={name}
              render={(msg) => <FormErrorMessage>{t(msg)}</FormErrorMessage>}
            />
          </FormControl>
        );
      }}
    </Field>
  );
}

export default FormikField;
