import type { OneOrderedFlowStep } from "@flows-platform/types";

import {
  flowStepTypePairs,
  flowStepTypes,
} from "../components/FlowEditor/components/FlowStep/constants/constants";

export default function getInCanvasConfig(
  step: NonNullable<OneOrderedFlowStep>,
  index: number,
  baseDragConfig: {
    options: {
      dropEffect: string;
    };
    end: (item: any, monitor: any) => void;
    canDrag: () => boolean;
  }
) {
  const { stepType, name, id } = step;
  const itemName = name || flowStepTypes[stepType]?.defaultName;
  return {
    item: () => ({
      stepType,
      name: itemName,
      id,
      index,
      fromLibrary: Object.keys(flowStepTypePairs).includes(stepType),
    }),
    collect: (monitor: any) => ({
      isDragPlaceholder: monitor.getItem()
        ? id === monitor.getItem()?.id && monitor.getItem()?.fromLibrary
        : false,
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    type: stepType,
    ...baseDragConfig,
  };
}
