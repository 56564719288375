import type { InputProps } from "@chakra-ui/react";
import { Box, Input } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useRef } from "react";

interface ResizableInputProps {
  [x: number | string | symbol]: unknown;
  controlled?: boolean;
  variant?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
}

const INPUT_FIELD_HEIGHT = 30;

function ResizableInput({
  controlled = false,
  variant = "outline",
  onChange,
  value,
  placeholder,
  ...rest
}: ResizableInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  let inputProps: InputProps = {
    ...rest,
    value,
  };

  /**
   * If we want to use ResizableInput within a Formik form,
   * we should not pass a value prop to the Input component
   */
  if (controlled) {
    inputProps = { ...rest };
  }

  return (
    <Box pos="relative" height="full" wordBreak="break-word" width="full" overflowY="hidden">
      <Box
        maxHeight={`${INPUT_FIELD_HEIGHT}px`}
        lineHeight={`${INPUT_FIELD_HEIGHT}px`}
        visibility="hidden"
        fontWeight="bold"
        paddingRight="5px"
      >
        {value || placeholder}
      </Box>
      <Input
        {...inputProps}
        ref={inputRef}
        htmlSize={value?.length ? value.length + 5 : placeholder?.length}
        w="auto"
        maxW="full"
        pos="absolute"
        top={0}
        bottom={0}
        left={0}
        margin="auto"
        cursor="pointer"
        placeholder={placeholder}
        onChange={onChange}
        variant={variant}
      />
    </Box>
  );
}

ResizableInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  controlled: PropTypes.bool,
};

export default ResizableInput;
