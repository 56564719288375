/* eslint-disable @typescript-eslint/unbound-method */
import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys
);

const baseStyle = definePartsStyle({
  item: {
    fontSize: "md",
  },
});

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
});
