import { Box, Button, Flex } from "@chakra-ui/react";
import { PermissionLevelEnum } from "@flows-platform/generated/graphql";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import { useTranslation } from "react-i18next";
import { HiOutlineCursorClick as CursorIcon } from "react-icons/hi";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function FlowRunnerStatusPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const myMembership = useMyMembership();
  const { t } = useTranslation();
  const projectId = searchParams.get("projectId") || "";

  const handleGoToInbox = () => {
    navigate(`/todos`);
  };
  return (
    <Box
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      width="full"
      height="full"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Placeholder
          type="empty-state"
          icon={<CursorIcon />}
          iconSize="lg"
          header="You have nothing else to do"
          description="Nothing else is required from you. You may proceed to close the window"
        />
        <Flex gap={4} justifyContent={"center"}>
          <Button colorScheme="purple" onClick={handleGoToInbox}>
            {t("pages.flow_runner.actions.go_to_inbox")}
          </Button>
          {projectId &&
            myMembership.myMembership?.permissionLevel !== PermissionLevelEnum.Guest.valueOf() && (
              <Button
                colorScheme="purple"
                onClick={() => {
                  navigate(`/projects/${projectId}`);
                }}
              >
                {t("pages.flow_runner.actions.go_to_project")}
              </Button>
            )}
        </Flex>
      </Box>
    </Box>
  );
}
