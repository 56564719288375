import { Box, VStack } from "@chakra-ui/react";
import type { ProjectAssigneeType } from "@flows-platform/modules/ProjectInstance/ProjectsOverview/components/ProjectsTable/components/ProjectsTableRow/components/ProjectSteps/components/ProjectStepCheckbox/components/ProjectTaskAssigneeSelector/ProjectTaskAssigneeSelector";
import MembershipSelector from "@flows-platform/modules/Shared/components/MembershipSelector/MembershipSelector";
import { isObjectValue } from "@kwest_fe/core/src/utils/parseFormInitialValue";
import { Form, Formik } from "formik";

interface FilterByProjectLeadProps {
  onSaveFilter: (projectLead: ProjectAssigneeType) => void;
  onClose: () => void;
}

export default function FilterByProjectLead({ onSaveFilter, onClose }: FilterByProjectLeadProps) {
  return (
    <Box px={3} paddingBottom={1}>
      <Formik
        initialValues={{
          assignee: null,
        }}
        onSubmit={(data) => {
          if (!data.assignee) return;
          onSaveFilter(data.assignee);
        }}
      >
        <Form style={{ width: "100%" }}>
          <VStack alignItems="flex-end" width="full" py={1}>
            <MembershipSelector
              showLabel={false}
              mapValue={(value) => (isObjectValue(value) ? value.id : value)}
              name="assignee"
              onChange={(value) => {
                if (!value) return;
                onSaveFilter(value);
              }}
              onBlur={onClose}
            />
          </VStack>
        </Form>
      </Formik>
    </Box>
  );
}
