import { useApolloClient } from "@apollo/client";
import {
  Button,
  Divider,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import {
  useAddProjectPhaseMutation,
  useAllPhasesSettingsQuery,
} from "@flows-platform/generated/graphql";
import InlineFormErrors from "@flows-platform/modules/Settings/components/InlineFormErrors";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { PHASE_COLORS } from "./constants";

interface AddPhaseModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddPhaseSchema = Yup.object().shape({
  name: Yup.string().required("forms.name.errors.required"),
  color: Yup.string(),
  position: Yup.number(),
});

const initialFormValues = {
  name: "",
  color: "",
  position: 0,
};

const AddPhaseModal = ({ isOpen, onClose }: AddPhaseModalProps) => {
  const [submissionErrors, setSubmissionErrors] = useState<string[] | null>(null);

  const [addPhase] = useAddProjectPhaseMutation();
  const { data: allPhases } = useAllPhasesSettingsQuery();
  const { t } = useTranslation();

  const getAvailableColor = () => {
    const usedColors = allPhases?.allProjectPhases?.map((phase) => phase?.color);

    return PHASE_COLORS.find((color) => !usedColors?.includes(color)) || PHASE_COLORS[0];
  };

  const { errorToast } = useDefaultToasts();
  const client = useApolloClient();
  const handleSubmit = (values: { name: string; color: string; position: number }) => {
    void addPhase({
      variables: {
        input: {
          name: values.name,
          color: getAvailableColor(),
          position: allPhases?.allProjectPhases?.length || 0,
        },
      },
      onCompleted: () => {
        client.refetchQueries({
          include: ["AllPhasesSettings"],
        });
        onClose();
      },
      onError(error) {
        setSubmissionErrors([error.message]);
        errorToast(t("modals.team.create.toast.error.title"))(error);
      },
    });
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Formik
        initialValues={initialFormValues}
        validationSchema={AddPhaseSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, dirty, resetForm }) => {
          return (
            <ModalContent>
              <Form data-testid="add-phase-form">
                <ModalHeader>{t("pages.settings.sections.phases.modal.add.title")}</ModalHeader>
                <ModalCloseButton />
                <Divider marginBottom="3" />
                <ModalBody>
                  <Text whiteSpace={"pre-line"}>
                    {t("pages.settings.sections.phases.modal.add.body")}
                  </Text>
                  <SimpleGrid rowGap={4} mt={4}>
                    <GridItem>
                      <FormikField
                        name="name"
                        label={t("forms.name.label")}
                        placeholder={t("forms.name.placeholder")}
                        data-testid="add-phase-form-name"
                      />
                    </GridItem>

                    {submissionErrors && (
                      <InlineFormErrors
                        title={t("modals.team.toast.save.error.title")}
                        warnings={submissionErrors}
                        onToastClose={() => {
                          setSubmissionErrors(null);
                          resetForm();
                        }}
                      />
                    )}
                  </SimpleGrid>
                </ModalBody>

                <Divider marginTop="5" />
                <ModalFooter>
                  <Button mr={3} variant="ghost" onClick={onClose}>
                    {t("global.actions.cancel")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    disabled={!(isValid && dirty) || isSubmitting}
                    data-testid="create-phase-button"
                  >
                    {t("global.actions.create")}
                  </Button>
                </ModalFooter>
              </Form>
            </ModalContent>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddPhaseModal;
