import { Button, Spinner } from "@chakra-ui/react";
import { AddIcon } from "@core/theme/icons";
import type { PropsWithChildren } from "react";

interface AddButtonProps extends PropsWithChildren {
  onClick?: () => void;
  size?: "lg" | "md" | "sm";
  isLoading?: boolean;
}
const AddButton = ({
  onClick,
  children,
  size = "sm",
  isLoading = false,
  ...restProps
}: AddButtonProps) => {
  const getIcon = () => {
    if (isLoading) return <Spinner size="xs" />;
    return <AddIcon size={size} />;
  };

  if (!children)
    return (
      <Button size={size} onClick={onClick} rounded="lg" padding={1.5}>
        {getIcon()}
      </Button>
    );
  return (
    <>
      <Button
        size={size}
        variant="outline"
        onClick={onClick}
        rounded="lg"
        paddingY={1.5}
        paddingX={2}
        leftIcon={getIcon()}
        bgColor={"white"}
        display={{ base: "none", md: "flex" }}
        {...restProps}
      >
        {children}
      </Button>
      <Button
        size={size}
        variant="outline"
        onClick={onClick}
        bgColor={"white"}
        rounded="lg"
        padding={1.5}
        display={{ base: "flex", md: "none" }}
        {...restProps}
      >
        {getIcon()}
      </Button>
    </>
  );
};

export default AddButton;
