import type { TextProps } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";

export default function TruncatedText({
  children,
  maxWidth = 300,
  fontWeight,
  ...rest
}: PropsWithChildren<TextProps>) {
  return (
    <Text
      display="inline-block"
      maxWidth={maxWidth}
      textOverflow="ellipsis"
      overflow="hidden"
      fontWeight={fontWeight}
      whiteSpace={"nowrap"}
      {...rest}
    >
      {children}
    </Text>
  );
}
