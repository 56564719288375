import { ChakraProvider } from "@chakra-ui/react";
import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import config from "@kwest_fe/core/src/config";
import { KwestAuthProvider } from "@kwest_fe/core/src/modules/Core/providers/KwestAuthProvider";
import * as Sentry from "@sentry/react";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { I18nextProvider } from "react-i18next";
import {
  createRoutesFromChildren,
  matchRoutes,
  Outlet,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { StreamChat } from "stream-chat";
import { Chat } from "stream-chat-react";

import i18next from "../../../../services/i18n";
import theme from "../../../../theme";
import Auth0ProviderWithHistory from "../../providers/Auth0ProviderWithHistory";
import LoadingWrapper from "../LoadingWrapper";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  replaysSessionSampleRate: 0.2, // Send 20% of all replays
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0, // Capture 100% of errors
  release: import.meta.env.VITE_SENTRY_RELEASE_VERSION,
});

const PHOptions = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

// Initialize Stream Chat
const client = new StreamChat(import.meta.env.VITE_STREAMCHAT_APIKEY);

export default function Providers() {
  return (
    <Auth0ProviderWithHistory>
      <ChakraProvider theme={theme}>
        <I18nextProvider i18n={i18next}>
          <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={PHOptions}>
            <Chat client={client}>
              <ErrorBoundary>
                <KwestAuthProvider>
                  <LoadingWrapper graphQLEndpoint={config.backend.graphQLEndpoint}>
                    <Outlet />
                  </LoadingWrapper>
                </KwestAuthProvider>
              </ErrorBoundary>
            </Chat>
          </PostHogProvider>
        </I18nextProvider>
      </ChakraProvider>
    </Auth0ProviderWithHistory>
  );
}
