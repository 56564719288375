import { useEffect, useMemo, useRef, useState } from "react";

interface IEditableInput {
  value: number | string;
  handleUpdate: (newValue: number | string) => void;
}

export default function useEditableInput({ value, handleUpdate }: IEditableInput) {
  const [valueState, setValueState] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const isChanged = useMemo(
    () => valueState !== value && valueState !== String(value),
    [valueState, value]
  );

  const toggleEditingOn = () => {
    if (!isEditing) setIsEditing(true);
  };

  const toggleEditingOff = () => {
    if (isEditing && !isChanged) {
      setIsEditing(false);
    }
  };

  const handleConfirm = () => {
    handleUpdate(valueState);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setValueState(value);
    setIsEditing(false);
  };

  useEffect(() => {
    if (inputRef.current) inputRef.current?.focus();
  }, [inputRef]);

  return {
    valueState,
    setValueState,
    inputRef,
    isEditing,
    isChanged,
    setIsEditing,
    toggleEditingOn,
    toggleEditingOff,
    handleConfirm,
    handleCancel,
  };
}
