import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import FormikField from "@todo-viewer/modules/Shared/components/FormikField/FormikField";
import type { VariableType } from "@todo-viewer/types";

interface FormikEmailFieldProps {
  label: string;
  name: string;
  variables: Record<string, VariableType>;
  emailFieldsOnly?: boolean;
  excludedVariableTypes?: string[];
}
const FormikEmailField = ({
  label,
  name,
  variables,
  emailFieldsOnly,
  excludedVariableTypes,
}: FormikEmailFieldProps) => {
  return (
    <FormikField
      label={label}
      name={name}
      renderElement={() => (
        <RichTextEditor
          name={name}
          isSingleLine
          hideToolbar
          variables={variables}
          emailFieldsOnly={emailFieldsOnly}
          excludedVariableTypes={excludedVariableTypes}
        />
      )}
    />
  );
};

export default FormikEmailField;
