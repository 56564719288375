import { Divider, GridItem, Select, SimpleGrid, Text } from "@chakra-ui/react";
import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import { ActionEnum } from "@flows-platform/generated/graphql";
import { functionalMoveObjectToNewIndex } from "@flows-platform/utils/functionalMoveObjectToNewIndex";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";

import SingleRuleConfigForm from "../SingleRuleConfigForm";

export interface SingleRuleCondition {
  conditional: string;
  variable: string;
  operator: string;
  variableValue: string;
}

export const SingleRuleConditionValue = {
  conditional: "if",
  variable: "",
  operator: "===",
  variableValue: "",
};

export interface RulesConfigFormValues {
  rules: {
    conditions: SingleRuleCondition[];
    action: ActionEnum;
    actionValue: "";
  }[];
}

interface RulesConfigFormProps {
  onRuleChanged: (updatedRules: RulesConfigFormValues) => void;
  showOtherwiseSection?: boolean;
  hasInitialValue?: boolean;
}

export default function RulesConfigForm({
  onRuleChanged,
  showOtherwiseSection = false,
  hasInitialValue = false,
}: RulesConfigFormProps) {
  const { currentFlow, activeFlowStepIndex } = useFlowEditor();
  const [displayRuleForm, setDisplayRuleForm] = useState(hasInitialValue);

  const { values, setValues } = useFormikContext<RulesConfigFormValues>();

  const stepOptions = () =>
    currentFlow?.orderedFlowSteps
      ?.filter((_, index) => activeFlowStepIndex === undefined || index > activeFlowStepIndex)
      .map((flowStep) => (
        <option value={flowStep?.id} key={flowStep?.id}>
          {flowStep?.name}
        </option>
      ));

  const onRuleDeleted = (index: number) => () => {
    setValues({
      rules: values.rules.filter((_, idx) => idx !== index),
    });
  };

  const onRuleAdded = () => {
    setDisplayRuleForm(true);
    setValues({
      rules: [
        ...values.rules,
        {
          conditions: [SingleRuleConditionValue],
          action: ActionEnum.JumpTo,
          actionValue: "",
        },
      ],
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onConditionAdded = (index: number) => () => {
    setValues({
      rules: values.rules.map((rule, idx) =>
        idx === index
          ? {
              ...rule,
              conditions: [...(rule.conditions || []), SingleRuleConditionValue],
            }
          : rule
      ),
    });
  };

  const onRuleMoved = (index: number) => (direction: "down" | "up") => () => {
    const nextIndex = direction === "down" ? index + 1 : index - 1;
    if (nextIndex > -1 && nextIndex < values.rules.length) {
      setValues({
        rules: functionalMoveObjectToNewIndex(values.rules, index, nextIndex, values.rules[index]),
      });
    }
  };

  useEffect(() => {
    onRuleChanged(values);
  }, [values]);

  return (
    <>
      {displayRuleForm &&
        values.rules?.map((_, ruleIndex) => (
          <SingleRuleConfigForm
            key={`rule-${ruleIndex}`}
            index={ruleIndex}
            isLast={ruleIndex === values.rules.length - 1}
            prefix={`rules[${ruleIndex}]`}
            onRuleDeleted={onRuleDeleted(ruleIndex)}
            onRuleMoved={onRuleMoved(ruleIndex)}
            value={values.rules[ruleIndex]}
          />
        ))}
      <AddButton onClick={onRuleAdded} data-testid="add-rule-button">
        Rule
      </AddButton>
      {showOtherwiseSection && (
        <>
          <Divider my={5} />
          <SimpleGrid columns={6} alignItems="center" w="full">
            <GridItem colSpan={2}>
              <Text fontSize="md">Otherwise jump to</Text>
            </GridItem>
            <GridItem colSpan={4}>
              <Select>{stepOptions()}</Select>
            </GridItem>
          </SimpleGrid>
        </>
      )}
    </>
  );
}
