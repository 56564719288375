import type { DrawerProps } from "@chakra-ui/react";
import { Drawer, DrawerOverlay } from "@chakra-ui/react";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";

type PreloadableDrawerProps = Omit<DrawerProps, "children"> & {
  preloadAction: () => Promise<any>;
  savingChanges: boolean;
  children: ({ preloadComplete }: { preloadComplete: boolean }) => ReactNode;
};

/**
 * A drawer that will run some preload actions before rendering the child container
 */
export default function PreloadableDrawer({
  preloadAction,
  savingChanges,
  isOpen,
  children,
  ...rest
}: PreloadableDrawerProps) {
  const [preloadComplete, setPreloadComplete] = useState(false);

  useEffect(() => {
    if (isOpen && !savingChanges && !preloadComplete) {
      if (!preloadAction) {
        setPreloadComplete(true);
        return;
      }
      preloadAction().then(() => {
        setPreloadComplete(true);
      });
    }
  }, [isOpen, preloadAction, preloadComplete, savingChanges]);

  return (
    <Drawer isOpen={isOpen} {...rest}>
      <DrawerOverlay />
      {children && typeof children === "function" ? children({ preloadComplete }) : children}
    </Drawer>
  );
}
