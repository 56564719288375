import getConfigDev from "./getConfigDev";
import getConfigProd from "./getConfigProd";

/* eslint-disable global-require */
const getConfig = () => {
  if (import.meta.env?.VITE_NODE_ENV === "production") {
    // import configProd from "./configProd";
    return getConfigProd();
  }

  return getConfigDev();
};

const config = getConfig();
export default config;
