import { VStack } from "@chakra-ui/react";
import {
  ReceiverAssignmentContextConsumer,
  ReceiverAssignmentProvider,
} from "@core/context/ReceiverAssignment";

import type { FormikSelectFieldSelectOption } from "../FormikSelectField/FormikSelectField";
import ExternalSystemSelector from "./components/ExternalSystemSelector";
import ReceiverAssignmentControl from "./components/ReceiverAssigmentControl";

interface ReceiverAssignmentProps {
  parent: string;
  onRoleCreated: (identifier: string) => Promise<string>;
  roleRecipients: FormikSelectFieldSelectOption[];
  showSlackRecipients?: boolean;
}

function ReceiverAssignment({
  parent,
  onRoleCreated,
  roleRecipients,
  showSlackRecipients = false,
}: ReceiverAssignmentProps) {
  return (
    <ReceiverAssignmentProvider parent={parent} showSlackRecipients={showSlackRecipients}>
      <ReceiverAssignmentContextConsumer>
        {({ updateExternalSystem, selectedExternalSystem }) => (
          <VStack align="stretch" spacing={7}>
            <ExternalSystemSelector
              onChange={updateExternalSystem}
              value={selectedExternalSystem}
            />
            <ReceiverAssignmentControl
              roleRecipients={roleRecipients}
              onRoleCreated={onRoleCreated}
            />
          </VStack>
        )}
      </ReceiverAssignmentContextConsumer>
    </ReceiverAssignmentProvider>
  );
}

export default ReceiverAssignment;
