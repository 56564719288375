import type { BoxProps } from "@chakra-ui/react";

const FORM_TYPE = "FORM_TYPE";

export default FORM_TYPE;

export const DescriptionTextBoxStyles: BoxProps = {
  wordBreak: "break-word",
  sx: {
    "ol, ul": {
      paddingLeft: 6,
    },
  },
};
