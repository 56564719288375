import { Button, IconButton, MenuButton } from "@chakra-ui/react";
import { JourneyStepStatus } from "@flows-platform/generated/graphql";
import {
  FilterIcons,
  FilterOptions,
} from "@flows-platform/modules/ProjectInstance/ProjectsOverview/constants/constants";
import TruncatedText from "@flows-platform/modules/Shared/UI/atoms/Text/TruncatedText/TruncatedText";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { AddIcon } from "@kwest_fe/core/src/theme/icons";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { Filter } from "../../types";

interface ProjectFilterMenuButtonProps {
  selectedFilter: Filter | null;
  getMembershipName: (id: string) => string;
  getFieldName: () => string;
  getTaskName: (id: string) => string;
  getTagName: (id: string) => string;
  showCompact: boolean;
}

const FilterButtonTextTranslationKeys = {
  [FilterOptions.PROJECT_LEAD]: "pages.projects.filters.projectLead",
  [FilterOptions.CONTENT]: "pages.projects.filters.content",
  [FilterOptions.CONTENT_IN_CONTEXT_SECTION]: "pages.projects.filters.content",
  [FilterOptions.FIELD_VALUE_IN_VARIABLES]: "pages.projects.filters.field",
  [FilterOptions.FIELD_VALUE_IN_CONTEXT_SECTION]: "pages.projects.filters.field",
  [FilterOptions.ARCHIVE_STATUS]: "pages.projects.filters.archived",
  [FilterOptions.TASK]: "pages.projects.filters.task",
  [FilterOptions.TASK_STATUS]: "pages.projects.filters.task",
  [FilterOptions.TASK_COMPLETED_STATUS]: "pages.projects.filters.task",
  [FilterOptions.TAGS]: "pages.projects.filters.tag",
};

export default function ProjectsFilterMenuButton({
  selectedFilter,
  getMembershipName,
  getFieldName,
  getTaskName,
  getTagName,
  showCompact,
}: ProjectFilterMenuButtonProps) {
  const { t } = useTranslation();

  const selectedFilterButtonText = useMemo(() => {
    if (!selectedFilter?.type) return "";
    return t(FilterButtonTextTranslationKeys[selectedFilter.type]);
  }, [selectedFilter]);
  const taskStatusLabel = useCallback((status: JourneyStepStatus) => {
    return status === JourneyStepStatus.Completed ? "Done" : "Open";
  }, []);

  const selectedFilterButton = useMemo(() => {
    if (selectedFilter === null) {
      return showCompact ? (
        <IconButton
          size="sm"
          aria-label="Add"
          icon={<AddIcon />}
          borderRightRadius={selectedFilter !== null ? 0 : undefined}
        />
      ) : (
        <AddButton>{!showCompact ? t("pages.projects.filters.addFilter") : ""}</AddButton>
      );
    }
    return (
      <Button
        size="sm"
        leftIcon={FilterIcons[selectedFilter.type]}
        borderRightRadius={0}
        iconSpacing={1}
        px={1}
        fontWeight="medium"
      >
        {selectedFilterButtonText}
      </Button>
    );
  }, [selectedFilter, showCompact, selectedFilterButtonText]);

  const selectedFilterValueButtons = useMemo(() => {
    const buttonValueText = [];
    if (selectedFilter?.type) {
      if (selectedFilter.type === FilterOptions.CONTENT)
        buttonValueText.push(`${t("global.contains")} ${selectedFilter.value}`);

      if (selectedFilter.type === FilterOptions.PROJECT_LEAD && selectedFilter.value)
        buttonValueText.push(`${t("global.is")} ${getMembershipName(selectedFilter.value)}`);

      if (
        selectedFilter.type === FilterOptions.FIELD_VALUE_IN_VARIABLES &&
        selectedFilter.parameters
      ) {
        buttonValueText.push(getFieldName());
        if (selectedFilter.value) {
          buttonValueText.push(`${t("global.is")} ${selectedFilter.value}`);
        }
      }

      if (selectedFilter.type === FilterOptions.TASK && selectedFilter.value) {
        buttonValueText.push(getTaskName(selectedFilter.value));
        if (selectedFilter.and?.value) {
          buttonValueText.push(
            `${t("global.is")} ${taskStatusLabel(selectedFilter.and?.value as JourneyStepStatus)}`
          );
        }
      }
      if (selectedFilter.type === FilterOptions.TAGS && selectedFilter.value) {
        buttonValueText.push(`${t("global.has_tag")} "${getTagName(selectedFilter.value)}"`);
      }
    }

    return buttonValueText.map((buttonText) => (
      <Button
        size="sm"
        variant={selectedFilter === null ? "outline" : undefined}
        marginLeft={1}
        borderRadius={0}
        px={1}
        title={buttonText}
      >
        <TruncatedText fontWeight="medium">{buttonText}</TruncatedText>
      </Button>
    ));
  }, [selectedFilter, getMembershipName, getFieldName, getTaskName]);

  return (
    <MenuButton flexWrap="nowrap">
      {selectedFilterButton}
      {selectedFilter !== null && selectedFilterValueButtons}
    </MenuButton>
  );
}
