import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import TodosInstallerView from "@flows-platform/modules/TodoItems/components/TodosInstallerView/TodosInstallerView";
import InboxPage from "@flows-platform/modules/TodoItems/components/TodosView/TodosView";

export default function TodoItemsPage() {
  const { myMembership } = useMyMembership();

  const isFieldWorker = myMembership?.isFieldWorker;

  return isFieldWorker ? <TodosInstallerView /> : <InboxPage />;
}
