import { wrapCreateBrowserRouter } from "@sentry/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Root from "./Root";

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: "*",
    Component: Root,
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
