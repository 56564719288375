import { Box, Button } from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";

import MultiPickerSelect from "./components/MultiPickerSelect/MultiPickerSelect";
import type { MultiPickerOptionValue } from "./utils/utils";
import { getActiveOptionsFromPath, getSelectedOptionsFromPath } from "./utils/utils";

interface MultiPickerProps {
  index: number;
  options: MultiPickerOptionValue[];
  onChanged?: (value: string[]) => void;
  displaySelected?: boolean;
  selected?: string[];
}

const MultiPicker = forwardRef<HTMLDivElement, MultiPickerProps>(
  ({ index, options, displaySelected, onChanged, selected = [] }, ref) => {
    const activeOptions = useMemo(
      () => getActiveOptionsFromPath(selected, options),
      [selected, options]
    );

    const selectedOptions = useMemo(
      () => getSelectedOptionsFromPath(selected, options),
      [options, selected]
    );

    return (
      <Box ref={ref} position="relative" top={-99999} left={-99999} zIndex={2000}>
        {displaySelected &&
          selectedOptions.map((option) => (
            <Button size="sm" fontWeight="medium" key={option.value}>
              {option.label}
            </Button>
          ))}
        {activeOptions && (
          <MultiPickerSelect
            index={index}
            options={Object.values(activeOptions)}
            handleItemClicked={(selectedItem) => {
              if (onChanged) onChanged([...selected, selectedItem]);
            }}
          />
        )}
      </Box>
    );
  }
);

export default MultiPicker;
