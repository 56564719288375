import { FlowStepVariableTypeEnum } from "@core/enums";

import type { VariableType } from "../types";

export function generateOptions(
  variables: Record<string, VariableType> = {},
  allowedTypes: string[] = [],
  excludedIdentifiers: string[] = []
) {
  return Object.keys(variables).reduce(
    (acc, identifier) => [
      ...acc,
      ...(!excludedIdentifiers.includes(identifier) &&
      (!allowedTypes.length || allowedTypes.includes(variables[identifier].type))
        ? [
            {
              value: identifier,
              label: variables[identifier].label || identifier,
            },
          ]
        : []),
    ],
    []
  );
}

export function generateRecipientOptions(variables = {}) {
  return generateOptions(variables, [FlowStepVariableTypeEnum.ROLE], ["initiator"]);
}

export function getOptionsFromList(optionsList?: string[] | null) {
  return optionsList?.map((opt) => ({
    value: opt,
    label: opt,
  }));
}

export default generateRecipientOptions;
