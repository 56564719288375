import { Box, Menu, MenuButton, MenuList, useDisclosure } from "@chakra-ui/react";
import type { ProjectAssigneeType } from "@flows-platform/modules/ProjectInstance/ProjectsOverview/components/ProjectsTable/components/ProjectsTableRow/components/ProjectSteps/components/ProjectStepCheckbox/components/ProjectTaskAssigneeSelector/ProjectTaskAssigneeSelector";
import MembershipSelector from "@flows-platform/modules/Shared/components/MembershipSelector/MembershipSelector";
import DropdownConfirmChanges from "@flows-platform/modules/Shared/UI/molecules/forms/DropdownConfirmChanges";
import { Form, Formik } from "formik";
import { useEffect } from "react";

import User from "../../../molecules/display/User";

interface SelectUserProps {
  label?: string;
  displayValue: string;
  onSubmit: (value: ProjectAssigneeType) => void;
  isLoading?: boolean;
  closePopup?: boolean;
  showTeams?: boolean;
  readOnly?: boolean;
}

const SelectUser = ({
  displayValue,
  onSubmit,
  isLoading = false,
  label = "Select user",
  closePopup = false,
  showTeams = false,
  readOnly = false,
}: SelectUserProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (closePopup) {
      onClose();
    }
  }, [closePopup]);

  return (
    <Menu onClose={onClose} isOpen={isOpen}>
      <MenuButton
        onClick={onOpen}
        _hover={{ bg: "gray.100" }}
        _focus={{ bg: "gray.200" }}
        py={1}
        pr={2}
        pl={1}
        rounded={"lg"}
        disabled={readOnly}
      >
        <User name={displayValue} />
      </MenuButton>
      <MenuList width="22rem">
        <Box paddingBottom="0.4rem" px={4}>
          <Formik
            initialValues={{
              assignee: null,
            }}
            onSubmit={(data) => {
              onSubmit(data.assignee);
            }}
          >
            <Form>
              <MembershipSelector
                name="assignee"
                label={label}
                labelStyles={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
                showTeams={showTeams}
              />
              <DropdownConfirmChanges onClose={onClose} isLoading={isLoading} />
            </Form>
          </Formik>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default SelectUser;
