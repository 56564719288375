import {
  Box,
  Divider,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import type { VariableCategoryType } from "@flows-platform/generated/graphql";
import SortIcons from "@flows-platform/modules/TemplateEditor/components/FlowEditor/components/SortIcons";
import type { VariableType } from "@flows-platform/types";
import { functionalMoveObjectToNewIndex } from "@flows-platform/utils/functionalMoveObjectToNewIndex";
import EditableTextField from "@kwest_fe/core/src/components/EditableTextField";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { DeleteIcon, InformationIcon } from "@kwest_fe/core/src/theme/icons";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  FixedWidthTableHeaderStyle,
  TableHeaderStyle,
} from "./components/Variable/constants/styles";
import Variable from "./components/Variable/Variable";

const ACTION_COLUMN_WIDTH = "40px";
export interface UpdateCategoryPayload {
  name?: string;
  moveDirection?: "down" | "up";
}

interface CategoryVariablesListProps {
  fieldName: string;
  category: VariableCategoryType;
  variables: VariableType[];
  setVariables: (value: VariableType[]) => void;
  onCategoryUpdated: ({ name, moveDirection }: UpdateCategoryPayload) => void;
  onCategoryDeleted: () => void;
  isFirst?: boolean;
  isLast?: boolean;
  triggerVariablesOnly?: boolean;
}

export default function CategoryVariablesList({
  fieldName,
  category,
  variables,
  setVariables,
  onCategoryUpdated,
  onCategoryDeleted,
  isFirst = false,
  isLast = false,
  triggerVariablesOnly = false,
}: CategoryVariablesListProps) {
  const { t } = useTranslation();
  const removeVariable = useCallback(
    (index: number) => {
      const variablesCopy = [...variables];
      variablesCopy.splice(index, 1);
      setVariables(variablesCopy);
    },
    [variables, setVariables]
  );

  const addVariable = useCallback(() => {
    setVariables([
      ...variables,
      {
        label: "",
        type: "STRING",
        in_trigger: triggerVariablesOnly,
        category: category.id,
      },
    ]);
  }, [variables, setVariables, triggerVariablesOnly, category]);

  const moveCategory = useCallback(
    (moveDirection: UpdateCategoryPayload["moveDirection"]) => () => {
      onCategoryUpdated({
        moveDirection,
      });
    },
    [onCategoryUpdated]
  );

  const variablesInCategory = useMemo(
    () =>
      variables
        .map((v, index) => ({ ...v, index }))
        .filter((v) => v.category === category.id || v.category === category.name),
    [variables, category]
  );

  const moveVariable = useCallback(
    (currentIndexInCategory: number) =>
      (moveDirection: UpdateCategoryPayload["moveDirection"]) =>
      () => {
        const nextIndexInCategory =
          moveDirection === "down" ? currentIndexInCategory + 1 : currentIndexInCategory - 1;
        if (nextIndexInCategory > -1 && nextIndexInCategory < variablesInCategory.length) {
          const { index: currentIndex } = variablesInCategory[currentIndexInCategory];
          const { index: nextIndex } = variablesInCategory[nextIndexInCategory];
          setVariables(
            functionalMoveObjectToNewIndex(
              variables,
              currentIndex,
              nextIndex,
              variables[currentIndex]
            )
          );
        }
      },
    [variablesInCategory]
  );

  useEffect(() => {
    if (variables && variablesInCategory.length === 0) {
      addVariable();
    }
  }, []);

  return (
    <Box w="full">
      <Table mb={5} w="full">
        <Thead>
          <Tr>
            <Td {...TableHeaderStyle} w={14}>
              <SortIcons
                disableMoveDown={isLast}
                disableMoveUp={isFirst}
                onMoveDown={moveCategory("down")}
                onMoveUp={moveCategory("up")}
              />
            </Td>
            <Td {...TableHeaderStyle} colSpan={3}>
              <EditableTextField
                text={category.name}
                onChange={(name) => {
                  onCategoryUpdated({ name });
                }}
                textProps={{ fontWeight: "bold" }}
              />
            </Td>
            <Td {...TableHeaderStyle} minW={ACTION_COLUMN_WIDTH} w={ACTION_COLUMN_WIDTH}>
              {!triggerVariablesOnly && (
                <Tooltip
                  hasArrow
                  label={t("pages.flows_templates.drawer.tooltips.delete_category")}
                  isDisabled={variablesInCategory.length === 0}
                >
                  <IconButton
                    _hover={{
                      backgroundColor: "transparent",
                    }}
                    variant="ghost"
                    aria-label="Delete category"
                    icon={<DeleteIcon color="red.400" />}
                    isDisabled={variablesInCategory.length > 0}
                    onClick={onCategoryDeleted}
                    size="xs"
                  />
                </Tooltip>
              )}
            </Td>
          </Tr>
          <Tr>
            <Td {...TableHeaderStyle}></Td>
            <Td {...FixedWidthTableHeaderStyle}>
              {t("pages.flows_templates.drawer.table.headers.label")}
            </Td>
            <Td {...FixedWidthTableHeaderStyle}>
              {t("pages.flows_templates.drawer.table.headers.field_type")}
            </Td>
            <Td {...TableHeaderStyle}>
              <HStack>
                <Tooltip hasArrow label={t("pages.flows_templates.drawer.tooltips.start_info")}>
                  <span>
                    <InformationIcon color="purple.500" />
                  </span>
                </Tooltip>
                <Text>{t("pages.flows_templates.drawer.start_info")}</Text>
              </HStack>
            </Td>
            <Td {...TableHeaderStyle}></Td>
          </Tr>
        </Thead>
        <Tbody>
          {variablesInCategory.map(({ index }, idx) => (
            <Variable
              key={`${fieldName}[${index}]`}
              index={index}
              fieldName={fieldName}
              onRemove={() => {
                removeVariable(index);
              }}
              moveDown={moveVariable(idx)("down")}
              moveUp={moveVariable(idx)("up")}
              triggerVariablesOnly={triggerVariablesOnly}
            />
          ))}
        </Tbody>
      </Table>
      <AddButton onClick={addVariable} data-testid="context-drawer-trigger-add-variable-button">
        {t("pages.projects.filters.field")}
      </AddButton>
      <Divider my={4} />
    </Box>
  );
}
