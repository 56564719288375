import { Box, Heading, HStack, Spinner } from "@chakra-ui/react";
import type { FlowStepTypeEnum } from "@flows-platform/generated/graphql";
import { useOneMembershipQuery } from "@flows-platform/generated/graphql";
import type { VariableType } from "@flows-platform/types";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";

import {
  PROJECT_CUSTOM_VARIABLE_DATA_LINE_HEIGHT,
  PROJECT_META_DATA_LINE_HEIGHT,
} from "../../../../../constants";
import FileJourneyVariable from "./components/FileJourneyVariable/FileJourneyVariable";
import RoleJourneyVariable from "./components/RoleJourneyVariable/RoleJourneyVariable";
import TextJourneyVariable from "./components/TextJourneyVariable";

export interface JourneyVariableProps {
  identifier: string;
  variable: VariableType;
  linkedResourceType?: FlowStepTypeEnum;
  linkedResourceId?: string;
}

const JOURNEY_FIELD_WIDTH = 150;

const TEXT_VARIABLE_TYPES = [
  FlowStepVariableTypeEnum.STRING,
  FlowStepVariableTypeEnum.NUMBER,
  FlowStepVariableTypeEnum.BOOLEAN,
  FlowStepVariableTypeEnum.DATE,
  FlowStepVariableTypeEnum.FILE_URL,
];

const FILE_VARIABLE_TYPES = [FlowStepVariableTypeEnum.IMAGE, FlowStepVariableTypeEnum.FILE];

const parseTextVariableValue = (variable: VariableType) => {
  const variableValue = variable.value;
  if (variable.type === FlowStepVariableTypeEnum.BOOLEAN) {
    // Forcing variable.value to string even though it will actually contain a boolean value
    // when variable.type is BOOLEAN. We should eventually modify the type to cater for this .
    if (String(variableValue) === "true") return "Yes";
    if (String(variableValue) === "false") return "No";
  }

  if (variable.is_list) {
    return variableValue ? Array.from(variableValue).join(", ") : " ";
  }

  return String(variableValue || "");
};

export default function JourneyVariable({
  identifier,
  variable,
  linkedResourceType,
  linkedResourceId,
}: JourneyVariableProps) {
  let membershipId = null;
  if (variable.type === "ROLE") {
    if (typeof variable.value === "object") {
      membershipId = (variable.value as any)?.membership_id || null;
    } else if (typeof variable.value === "string") {
      membershipId = variable.value || null;
    }
  }

  const { data, loading } = useOneMembershipQuery({
    variables: {
      id: membershipId || "",
    },
    skip: !membershipId || !!variable.is_always_available || variable.type !== "ROLE",
  });

  const isFileVariable = FILE_VARIABLE_TYPES.includes(variable.type);

  if (variable.is_always_available) return null;

  if (loading) return <Spinner />;

  return (
    <HStack width="100%" alignItems="flex-start">
      <Heading
        fontSize="md"
        textColor="gray.500"
        flex="1"
        maxWidth={JOURNEY_FIELD_WIDTH}
        lineHeight={
          isFileVariable ? PROJECT_CUSTOM_VARIABLE_DATA_LINE_HEIGHT : PROJECT_META_DATA_LINE_HEIGHT
        }
      >
        {variable.label}
      </Heading>
      <Box flex="1" overflow="hidden">
        {variable.type === "ROLE" && data?.oneMembership && (
          <RoleJourneyVariable
            fullName={`${data?.oneMembership.firstName} ${data?.oneMembership.lastName}`}
          />
        )}

        {TEXT_VARIABLE_TYPES.includes(variable.type) && (
          <TextJourneyVariable value={parseTextVariableValue(variable)} />
        )}

        {isFileVariable && (
          <FileJourneyVariable
            variable={variable}
            identifier={identifier}
            linkedResourceId={linkedResourceId}
            linkedResourceType={linkedResourceType}
          />
        )}
      </Box>
    </HStack>
  );
}
