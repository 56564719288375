import { useReassignJourneyVariablesClientMutation } from "@flows-platform/generated/graphql";
import type { ProjectAssigneeType } from "@flows-platform/modules/ProjectInstance/ProjectsOverview/components/ProjectsTable/components/ProjectsTableRow/components/ProjectSteps/components/ProjectStepCheckbox/components/ProjectTaskAssigneeSelector/ProjectTaskAssigneeSelector";
import SelectUser from "@flows-platform/modules/Shared/UI/organisms/Select/SelectUser/SelectUser";
import { captureException } from "@sentry/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ProjectLeadSelectorProps {
  journeyId: string;
  projectLead: string;
  onUpdate?: () => Promise<any>;
  readOnly?: boolean;
}
export default function ProjectLeadSelector({
  journeyId,
  projectLead,
  onUpdate,
  readOnly = false,
}: ProjectLeadSelectorProps) {
  const [reassignJourneyVariables, { loading }] = useReassignJourneyVariablesClientMutation();

  const { t } = useTranslation();

  const [closePopup, setClosePopup] = useState(false);
  return (
    <SelectUser
      label={t("pages.project_instance.variables.select_assignee")}
      displayValue={projectLead}
      isLoading={loading}
      closePopup={closePopup}
      readOnly={readOnly}
      onSubmit={async (assignee: ProjectAssigneeType) => {
        try {
          await reassignJourneyVariables({
            variables: {
              journeyId,
              variables: JSON.stringify({
                project_lead: assignee?.id,
              }),
            },
            onCompleted: () => {
              setClosePopup(true);
            },
          });
          await onUpdate?.();
        } catch (e) {
          captureException(e);
        }
      }}
    />
  );
}
