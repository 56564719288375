import type {
  AllMembershipsQuery,
  ReassignJourneyStepClientMutationFn,
} from "@flows-platform/generated/graphql";
import {
  useAllMembershipsQuery,
  useReassignJourneyStepClientMutation,
} from "@flows-platform/generated/graphql";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";

interface ProjectStepsContextInterface {
  projectId: string;
  allMemberships: AllMembershipsQuery["allMemberships"];
  reassignJourneyStep: ReassignJourneyStepClientMutationFn;
  onUpdate: () => void;
}

export const ProjectStepsContext = createContext<ProjectStepsContextInterface | null>(null);

interface ProjectStepsProviderProps {
  projectId: string;
  onUpdate: () => void;
}

export function ProjectStepsProvider({
  projectId,
  children,
  onUpdate,
}: PropsWithChildren<ProjectStepsProviderProps>) {
  const { data } = useAllMembershipsQuery();
  const [reassignJourneyStep] = useReassignJourneyStepClientMutation({
    onCompleted() {
      onUpdate();
    },
  });

  const value = useMemo(
    () => ({
      projectId,
      allMemberships: data?.allMemberships,
      reassignJourneyStep,
      onUpdate,
    }),
    [data]
  );

  return <ProjectStepsContext.Provider value={value}>{children}</ProjectStepsContext.Provider>;
}

export function useProjectSteps() {
  const context = useContext(ProjectStepsContext);
  if (context === undefined) {
    throw new Error("useProjectSteps must be used within a ProjectStepsProvider");
  }
  return (context || {}) as ProjectStepsContextInterface;
}
