import { Box, Button, Checkbox, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import {
  useJourneysAwaitingUserTodayQuery,
  useTaskQuery,
  useToggleTaskStatusClientMutation,
} from "@flows-platform/generated/graphql";
import { ArrowBackIcon } from "@flows-platform/theme/icons";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import HTMLContentRenderer from "@kwest_fe/core/src/components/HTMLContentRenderer/HTMLContentRenderer";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import QueryLoaderContainer from "@kwest_fe/core/src/components/QueryLoaderContainer";
import { t } from "i18next";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ChatWindow from "../ProjectInstance/ProjectsOverview/components/ProjectsTable/components/ProjectsTableRow/components/ProjectSteps/components/ProjectStepCheckbox/components/ProjectStepModal/components/ChatWindow";
import { inputLabelWidth } from "../ProjectInstance/ProjectsOverview/components/ProjectsTable/components/ProjectsTableRow/components/ProjectSteps/components/ProjectStepCheckbox/constants/constants";
import ConnectedFlowsView from "../Shared/components/ConnectedFlowsView";
import SubTasksView from "../Shared/components/SubtasksView";
import PageWrapper from "../Shared/UI/layout/PageWrapper";
import ContextDrawerReadOnly from "./ContextDrawerReadOnly";

export default function TaskPage() {
  const { taskId } = useParams<{ taskId: string }>();

  const [toggleTaskStatus, { loading }] = useToggleTaskStatusClientMutation();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  // Refetching data for the inbox, it doesnt happen automatically because of BE typing differences.
  const { refetch } = useJourneysAwaitingUserTodayQuery();

  const handleToggleTaskStatus = (stepId: string) => {
    void toggleTaskStatus({
      variables: { stepId },
      onCompleted: () => {
        refetch();
      },
    });
  };

  if (!taskId) return <LoadingSpinnerOverlay />;

  return (
    <PageWrapper>
      <QueryLoaderContainer
        query={useTaskQuery}
        queryOptions={{ variables: { taskId } }}
        loadingErrorMessage="Task not found"
        render={({ data: task }) => (
          <VStack py={4} px={6} w={"full"}>
            <Flex gap={4} alignItems={"start"} w={"full"}>
              <Button
                px={1}
                rounded={"sm"}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon />
              </Button>
              <Text
                size={"14px"}
                fontWeight={600}
                w={"full"}
                decoration={"underline"}
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
                textUnderlineOffset={4}
              >
                {task.getTask?.journeyStep?.journey.instanceName}
              </Text>
            </Flex>
            <Text size={"16px"} fontWeight={700} w={"full"} pt={2}>
              {task.getTask?.journeyStep?.name}
            </Text>
            <Box w={"full"}>
              <HStack width="full" borderColor="gray.100" py={4} borderBottomWidth={1}>
                <HStack gap={3} py={2} width="full">
                  <Text textStyle={TEXT_STYLES.lead14SemiBold} width={inputLabelWidth}>
                    {t("forms.project_task_due_date.label")}:
                  </Text>
                  <Text>{new Date(task.getTask?.dueDate).toLocaleDateString()}</Text>
                </HStack>
                <Checkbox
                  data-testid="status-bar-checkbox"
                  bg={"gray.50"}
                  borderWidth={1}
                  borderColor={"gray.400"}
                  isChecked={!!task.getTask?.journeyStep?.completedAt}
                  onChange={() => {
                    handleToggleTaskStatus(task.getTask?.journeyStep?.id || "");
                  }}
                  disabled={loading}
                  px={2}
                  py={2}
                  size={"lg"}
                  rounded={"sm"}
                >
                  <Text fontSize={"16px"}> {t("global.actions.done")}</Text>
                </Checkbox>
              </HStack>
              <Box
                w={"full"}
                gap={6}
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                borderBottomWidth={1}
                py={4}
              >
                <Box w="full" textStyle={TEXT_STYLES.lead14Normal}>
                  <HTMLContentRenderer htmlContent={task?.getTask?.content} />
                </Box>
                {(task?.getTask?.orderedSubtasks?.length || 0) > 0 && (
                  <SubTasksView subTasks={task?.getTask?.orderedSubtasks} />
                )}
                {task?.getTask?.connectedFlow?.id && task?.getTask?.journeyStep?.id && (
                  <ConnectedFlowsView
                    task={{
                      ...task?.getTask.journeyStep,
                      id: task?.getTask.id,
                      connectedFlowId: task.getTask.connectedFlow.id,
                      connectedFlowInitialVariableValues:
                        task.getTask.connectedFlowInitialVariableValues,
                      connectedJourneysId: (task.getTask.connectedJourneysId as string[]) || [null],
                    }}
                    openSelf
                  />
                )}
              </Box>
              {task.getTask?.journeyStep?.name && task.getTask?.journeyStep?.id && (
                <ChatWindow
                  mt={4}
                  mx={-4}
                  step={{
                    name: task.getTask.journeyStep.name,
                    id: task.getTask.journeyStep.id,
                  }}
                />
              )}
            </Box>
            <ContextDrawerReadOnly
              isOpen={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
              }}
              projectId={task.getTask?.journeyStep?.journey.id || ""}
            />
          </VStack>
        )}
      />
    </PageWrapper>
  );
}
