import { Box, Divider, Text, VStack } from "@chakra-ui/react";
import type { VariableCategoryType } from "@flows-platform/generated/graphql";
import type { VariableType } from "@flows-platform/types";
import { functionalMoveObjectToNewIndex } from "@flows-platform/utils/functionalMoveObjectToNewIndex";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useField } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidV4, validate } from "uuid";

import type { UpdateCategoryPayload } from "./components/CategoryVariablesList/CategoryVariablesList";
import CategoryVariablesList from "./components/CategoryVariablesList/CategoryVariablesList";

interface ContextDrawerSetupProps {
  isProject?: boolean;
}

export default function ContextDrawerSetup({ isProject }: ContextDrawerSetupProps) {
  const { t } = useTranslation();
  const [{ value: variables }, , { setValue }] = useField<VariableType[]>("variables");
  const [{ value: variableCategories }, , { setValue: setVariableCatgories }] =
    useField<VariableCategoryType[]>("variableCategories");

  const addCategory = useCallback(() => {
    const newCategoryId = uuidV4();
    setVariableCatgories([
      {
        id: newCategoryId,
        name: "Untitled Category",
        position: variableCategories.length,
      },
      ...variableCategories.map((category, position) => ({ ...category, position: position + 1 })),
    ]);
  }, [variableCategories, setVariableCatgories]);

  const updateCategory =
    (categoryIndex: number) =>
    ({ name, moveDirection }: UpdateCategoryPayload) => {
      const originalCategory = variableCategories[categoryIndex];
      let updatedVariableCategories = [...variableCategories];
      let listChanged = false;
      if (name) {
        listChanged = true;
        updatedVariableCategories = variableCategories.map((category, idx) =>
          idx === categoryIndex ? { ...category, name } : category
        );
        // Update variables whose categories are using the name format
        const nonUuidCategoryVariables = variables.filter(
          (variable) => variable.category && !validate(variable.category)
        );
        if (nonUuidCategoryVariables.length)
          setValue(
            variables.map((variable) =>
              variable.category === originalCategory.name
                ? { ...variable, category: originalCategory.id }
                : variable
            )
          );
      }
      if (moveDirection) {
        const newIndex = moveDirection === "up" ? categoryIndex - 1 : categoryIndex + 1;
        if (newIndex > -1 && newIndex < variableCategories.length) {
          listChanged = true;
          // Reorder the category sort order and save the update new positions
          updatedVariableCategories = functionalMoveObjectToNewIndex(
            variableCategories,
            categoryIndex,
            newIndex,
            variableCategories[categoryIndex]
          ).map((category, position) => ({ ...category, position }));
        }
      }
      if (listChanged) setVariableCatgories(updatedVariableCategories);
    };

  const deleteCategory = (index: number) => () => {
    setVariableCatgories(variableCategories.filter((category, idx) => idx !== index));
  };

  return (
    <Box w="full">
      <Text fontWeight="medium">
        {t("pages.flows_templates.drawer.context_drawer_setup.title")}
      </Text>
      <Text mt={1} mb={3} fontSize="md">
        {t("pages.flows_templates.drawer.context_drawer_setup.description")}
      </Text>
      {isProject && (
        <AddButton onClick={addCategory}>
          {t("pages.flows_templates.drawer.context_drawer_setup.actions.add_category")}
        </AddButton>
      )}
      <Divider my={5} />
      <VStack alignItems="flex-start" w="full">
        {variableCategories.map((category, index) => (
          <CategoryVariablesList
            key={category.id}
            onCategoryUpdated={updateCategory(index)}
            onCategoryDeleted={deleteCategory(index)}
            isFirst={index === 0}
            isLast={index === variableCategories.length - 1}
            fieldName={"variables"}
            category={category}
            variables={variables}
            setVariables={setValue}
            triggerVariablesOnly={!isProject}
          />
        ))}
      </VStack>
    </Box>
  );
}
