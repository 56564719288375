export enum FlowStepPickableTypeEnum {
  FormPickable = "form-pickable",
  HttpRequestPickable = "http_request-pickable",
  TaskPickable = "task-pickable",
  SlackPickable = "slack-pickable",
  EmailPickable = "email-pickable",
  ListPickable = "list-pickable",
  TriggerPickable = "trigger-pickable",
  DocumentPickable = "document-pickable",
  ApprovalPickable = "approval-pickable",
}

export type FlowStepMode = "canvas" | "pickable";

const FlowStepModeEnum = {
  Pickable: "pickable",
  Canvas: "canvas",
};

const FlowStepVariableTypeEnum = {
  DATE: "DATE",
  ROLE: "ROLE",
  NUMBER: "NUMBER",
  STRING: "STRING",
  BOOLEAN: "BOOLEAN",
  IMAGE: "IMAGE",
  FILE: "FILE",
  FILE_URL: "FILE_URL",
};

export { FlowStepModeEnum, FlowStepVariableTypeEnum };
