import { Divider, Flex, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import AuthenticatorListContainer from "@flows-platform/modules/Settings/components/AuthenticatorList/AuthenticatorListContainer";
import NewAuthenticatorModal from "@flows-platform/modules/Settings/components/NewAuthenticatorModal";
import SlackIntegrationSection from "@flows-platform/modules/Settings/components/SlackIntegrationSection";
import SettingsPageContainer from "@flows-platform/modules/Settings/SettingsPageContainer";
import PageWrapper from "@flows-platform/modules/Shared/UI/layout/PageWrapper";
import SectionHeader from "@flows-platform/modules/Shared/UI/molecules/layout/SectionHeader";
import getFeatureFlag from "@flows-platform/utils/getFeatureFlag";
import Avatar from "@kwest_fe/core/src/components/UI/atoms/Avatar";
import PageHeader from "@kwest_fe/core/src/components/UI/layout/PageHeader/PageHeader";
import type { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { FeatureFlags } from "../Core/constants/enums";
import type { LayoutReducerAction } from "../Shared/UI/layout/LayoutProvider";
import { useLayout } from "../Shared/UI/layout/LayoutProvider";
import AuthenticatorList from "./components/AuthenticatorList";
import PeoplePage from "./components/PeopleSection";
import PhasesSection from "./components/PhasesSection";
import TeamsSection from "./components/TeamsSection";

function SettingsPageSection({ children }: PropsWithChildren) {
  return (
    <VStack spacing={7} alignItems="flex-start" width="full" pt={5}>
      {children}
    </VStack>
  );
}

export default function SettingsPage() {
  const { t } = useTranslation();
  const { dispatch } = useLayout();

  return (
    <PageWrapper>
      <PageHeader
        title={t("pages.settings.title")}
        toggleSidebar={(toggleString: LayoutReducerAction) => {
          dispatch({ type: toggleString });
        }}
      />
      <SettingsPageContainer
        render={(membership, addSlackIntegration, hasSlackIntegration) => (
          <Flex
            direction={"column"}
            gap={3}
            alignItems="flex-start"
            justifyContent="left"
            width="full"
            h={"94vh"}
          >
            <SettingsPageSection>
              <SectionHeader headingText={t("pages.settings.sections.account.title")} />
              <HStack spacing={2} mb={8}>
                <Avatar
                  name={`${membership?.firstName || ""} ${membership?.lastName || ""}`}
                  size="lg"
                />
                <VStack alignItems="flex-start">
                  <Heading size="sm">{`${membership?.firstName || ""} ${
                    membership?.lastName || ""
                  }`}</Heading>
                  <Text fontSize={"md"}>{membership?.workspaceEmail}</Text>
                </VStack>
              </HStack>
              <Divider />
            </SettingsPageSection>
            <SettingsPageSection>
              <SectionHeader headingText="Workspace settings" />
              {membership?.isAdmin && (
                <SlackIntegrationSection
                  hasSlackIntegration={hasSlackIntegration}
                  addSlackIntegration={addSlackIntegration}
                />
              )}
              <Divider />
            </SettingsPageSection>
            {getFeatureFlag(FeatureFlags.ConnectedApps, membership) ? (
              <Flex direction={"column"} w={"full"} gap={6}>
                <SettingsPageSection>
                  <SectionHeader
                    headingText={t("pages.settings.sections.authenticators.title")}
                    subHeadingText={t("pages.settings.sections.authenticators.subtitle")}
                  />

                  <AuthenticatorListContainer
                    render={({ allAuthenticators }) => (
                      <>
                        <AuthenticatorList allAuthenticators={allAuthenticators} />
                        <NewAuthenticatorModal allAuthenticators={allAuthenticators} />
                      </>
                    )}
                  />
                </SettingsPageSection>
                <Divider />
              </Flex>
            ) : null}

            <SettingsPageSection>
              <SectionHeader headingText={t("pages.settings.sections.phases.title")} />
              <PhasesSection />
            </SettingsPageSection>

            <Divider />

            <SettingsPageSection>
              <TeamsSection />
            </SettingsPageSection>

            <SettingsPageSection>
              <PeoplePage />
            </SettingsPageSection>
          </Flex>
        )}
      />
    </PageWrapper>
  );
}
