import type { ApolloCache, ApolloClient } from "@apollo/client";
import type {
  OneFlowQuery,
  ProjectPhaseFieldsFragment,
  SaveFlowClientMutation,
  SaveFlowClientMutationVariables,
  SaveFlowStepClientMutation,
  SaveFlowStepClientMutationVariables,
} from "@flows-platform/generated/graphql";
import {
  OneFlowDocument,
  SaveFlowClientDocument,
  SaveFlowStepClientDocument,
} from "@flows-platform/generated/graphql";
import type { DragObject } from "@flows-platform/modules/TemplateEditor/components/FlowEditor/constants/types";
import type { OneFlow, OneOrderedFlowStep, OrderedFlowSteps } from "@flows-platform/types";
import setItemPosition from "@flows-platform/utils/setItemPosition";

function setFlowStepPosition<T>(steps: T[]) {
  return steps.map((s, i) => ({ ...s, position: i }));
}

/**
 * __readFlowFromCache()__
 *
 * Call this function to read the flow from the cache
 * @param params Query parameters including an Apollo client and the flow ID
 * @returns OneFlow
 */
export function readFlowFromCache({
  client,
  flowId,
}: {
  client: ApolloCache<object> | ApolloClient<object>;
  flowId: string;
}) {
  const { oneFlow } =
    client.readQuery<OneFlowQuery>({
      query: OneFlowDocument,
      variables: { flowId },
    }) || {};
  return oneFlow;
}

export function writeUpdatedFlowToCache({
  client,
  flowId,
  updatedFields,
}: {
  client: ApolloCache<object> | ApolloClient<object>;
  flowId: string;
  updatedFields: Partial<OneFlow>;
}) {
  const oneFlow = readFlowFromCache({ client, flowId });
  client.writeQuery({
    query: OneFlowDocument,
    variables: { flowId },
    data: {
      oneFlow: {
        ...(oneFlow || {}),
        ...updatedFields,
      },
    },
  });
}

export function updateFlowstepFields({
  client,
  flowId,
  updateIndex,
  updateFields,
}: {
  client: ApolloClient<object>;
  flowId: string;
  updateIndex: number;
  updateFields: Partial<OneOrderedFlowStep>;
}) {
  const oneFlow = readFlowFromCache({ client, flowId });
  const newFlowSteps = Array.from(oneFlow?.orderedFlowSteps || []).map((flowStep, index) => ({
    ...flowStep,
    ...(index === updateIndex ? updateFields : {}),
  })) as NonNullable<OrderedFlowSteps>;
  writeUpdatedFlowToCache({
    client,
    flowId,
    updatedFields: {
      orderedFlowSteps: [...newFlowSteps],
    },
  });
}

export function updateStep({
  client,
  flowId,
  dragIndex,
  hoverIndex,
}: {
  client: ApolloClient<object>;
  flowId: string;
  dragIndex: number;
  hoverIndex: number;
}) {
  const oneFlow = readFlowFromCache({ client, flowId });
  const flowSteps = oneFlow?.orderedFlowSteps || [];
  const newFlowSteps = Array.from(flowSteps);

  const draggedStep = flowSteps[dragIndex];
  const hoveredStep = flowSteps[hoverIndex];

  // Move the phase label to the previous step when dragging the first step downwards
  if (dragIndex === 0 && draggedStep && hoveredStep) {
    newFlowSteps[hoverIndex] = {
      ...hoveredStep,
      phase: draggedStep?.phase,
    };
  }

  newFlowSteps.splice(dragIndex, 1);
  newFlowSteps.splice(
    hoverIndex,
    0,
    // Removes the phase from the first step when going down
    draggedStep && {
      ...draggedStep,
      ...(dragIndex === 0 ? { phase: null } : {}),
    }
  );

  writeUpdatedFlowToCache({
    client,
    flowId,
    updatedFields: {
      orderedFlowSteps: setFlowStepPosition(newFlowSteps),
    },
  });
}

export function removeStep({
  client,
  stepId,
  flowId,
}: {
  client: ApolloClient<object>;
  stepId: string;
  flowId: string;
}) {
  const oneFlow = readFlowFromCache({ client, flowId });
  if (!oneFlow) return;
  const updatedSteps = Array.from(oneFlow.orderedFlowSteps || []).filter(
    (step) => step?.id !== stepId
  );
  writeUpdatedFlowToCache({
    client,
    flowId,
    updatedFields: {
      orderedFlowSteps: setFlowStepPosition(updatedSteps),
    },
  });
}

export function insertStep({
  client,
  draggedStep,
  flowId,
  hoverIndex = 0,
  isProject = false,
  defaultProjectPhase,
}: {
  client: ApolloClient<object>;
  draggedStep: DragObject;
  flowId: string;
  hoverIndex?: number;
  isProject?: boolean;
  defaultProjectPhase?: ProjectPhaseFieldsFragment;
}) {
  const oneFlow = readFlowFromCache({ client, flowId });

  // Default number of days between one step and the next
  const DEFAULT_DAYS_BETWEEN_STEPS = 0;

  // Check if the dragged step exists in state
  const existingStepIndex = oneFlow?.orderedFlowSteps?.findIndex(
    (step) => step?.id === draggedStep.id
  );

  const position =
    isProject && oneFlow?.orderedFlowSteps?.length && hoverIndex < 1 ? 1 : hoverIndex;

  // Insert a step if it's not in state
  if (existingStepIndex === -1) {
    const newFlowSteps = Array.from(oneFlow?.orderedFlowSteps || []);
    const { orderedFlowSteps, ...flowProperties } = oneFlow || {};
    const newStep = {
      ...draggedStep,
      flow: {
        ...flowProperties,
      },
      position,
      message: null,
      todoList: null,
      variableStore: null,
      ruleVariableOptions: null,
      variables: null,
      formId: null,
      httpRequest: null,
      taskId: null,
      documentId: null,
      approvalId: null,
      phase:
        isProject && defaultProjectPhase && !orderedFlowSteps?.length ? defaultProjectPhase : null,
      waitDuration: DEFAULT_DAYS_BETWEEN_STEPS,
      warnings: [],
      rulesChain: [],
      __typename: "FlowStepType",
    } as OneOrderedFlowStep;

    newFlowSteps.splice(position, 0, newStep);

    writeUpdatedFlowToCache({
      client,
      flowId,
      updatedFields: {
        orderedFlowSteps: setItemPosition(newFlowSteps),
      },
    });
  }
}

interface SaveFlowInput {
  client: ApolloClient<object>;
  flow: NonNullable<SaveFlowClientMutationVariables["input"]>;
}

export async function saveFlow({ client, flow }: SaveFlowInput) {
  if (!flow.orderedFlowSteps) throw new Error("No flow steps found");

  return client.mutate<SaveFlowClientMutation>({
    variables: {
      input: {
        ...flow,
      },
    },
    mutation: SaveFlowClientDocument,
  });
}

export async function saveFlowStep({
  client,
  flowStep,
}: {
  client: ApolloClient<object>;
  flowStep: SaveFlowStepClientMutationVariables["input"];
}) {
  return client.mutate<SaveFlowStepClientMutation>({
    variables: {
      input: flowStep,
    },
    mutation: SaveFlowStepClientDocument,
    update(_, { data }) {
      if (flowStep?.flow)
        writeUpdatedFlowToCache({
          client,
          flowId: flowStep.flow.id,
          updatedFields: data?.saveFlowStep?.flowStep?.flow,
        });
    },
  });
}

export function getFlowStepById({
  client,
  flowId,
  flowStepId,
}: {
  client: ApolloClient<object>;
  flowId: string;
  flowStepId: string;
}) {
  const oneFlow = readFlowFromCache({ client, flowId });
  return oneFlow?.orderedFlowSteps?.find((step) => step?.id === flowStepId);
}
