const placeholderTypes = {
  error: {
    icon: "⚠️",
    description:
      "If the error persists, please contact us using the support button in the side navigation.",
  },
  "empty-state": {
    icon: "📭",
    description: "",
  },
} as const;

export default placeholderTypes;
