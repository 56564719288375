import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import type { FlowStepObserver } from "@kwest_fe/core/src/modules/Shared/hooks/useFlowStepUpdater";
import { useEffect } from "react";

export default function useFormObserver(formObserver: FlowStepObserver) {
  const { flowStep, registerFormObserver } = useFlowEditorDrawer() || {};

  useEffect(() => {
    registerFormObserver?.(formObserver);
  }, [flowStep]);
}
