import * as Yup from "yup";

const CreateEditTeamSchema = Yup.object().shape({
  name: Yup.string().required("Please provide a last name"),
  email: Yup.string()
    .email("That's not a valid email address")
    .required("Please provide an email address"),
});

export default CreateEditTeamSchema;
