import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import {
  useOneFlowStepQuery,
  useUpdateMessageClientMutation,
} from "@flows-platform/generated/graphql";
import { FlowStepObserver } from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import useFormObserver from "@flows-platform/modules/Shared/hooks/useFormObserver";
import EmailEditor from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/components/FlowStepTemplateRenderer/components/EmailEditorFlowStepContainer/EmailEditor";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import omitFields from "@flows-platform/utils/omitFields";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

import FlowStepResourceLoaderContainer from "../../../../../FlowStepResourceLoaderContainer/FlowStepResourceLoaderContainer";

interface EmailEditorFlowStepContainerProps {
  initialFormValuesLoaded: boolean;
  enableSlack?: boolean;
}

function EmailEditorFlowStepContainer({
  initialFormValuesLoaded,
  enableSlack = false,
}: EmailEditorFlowStepContainerProps) {
  const { flowStep } = useFlowEditorDrawer() || {};
  const { updateActiveFlowStep } = useFlowEditor();
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [updateMessage] = useUpdateMessageClientMutation({
    onCompleted(data) {
      const { flow, ...updatedFlowStep } = data?.updateMessage?.message?.flowStep || {};
      updateActiveFlowStep(updatedFlowStep);
      successToast(t("pages.flow_step.toast.update_message.success.title"));
    },
    onError: errorToast(t("pages.flow_step.toast.update_message.error.title")),
  });

  useFormObserver(
    new FlowStepObserver({
      fields: ["message"],
      async onChanged(modifiedValues) {
        const { message } = modifiedValues;
        const res = await updateMessage({
          variables: {
            input: {
              id: flowStep?.message?.id,
              ...(message ? omitFields(message, ["showCc", "showBcc", "enableReplyTo"]) : {}),
            },
          },
        });
        return res;
      },
    })
  );

  return (
    <FlowStepResourceLoaderContainer
      query={useOneFlowStepQuery}
      initialValuesKey="message"
      queryOptions={{ variables: { flowStepId: flowStep?.id } }}
      loadingErrorMessage="Error loading email contents"
      resolveInitialValue={(data) => ({
        ...data?.oneFlowStep?.message,
        showCc: !!data?.oneFlowStep?.message?.ccEmail,
        showBcc: !!data?.oneFlowStep?.message?.bccEmail,
        enableReplyTo: !!data?.oneFlowStep?.message?.replyToEmail,
      })}
      initialFormValuesLoaded={initialFormValuesLoaded}
    >
      <EmailEditor useSubjectField={!enableSlack} isSlackMessage={enableSlack} />
    </FlowStepResourceLoaderContainer>
  );
}

export default EmailEditorFlowStepContainer;
