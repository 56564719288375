import TablePage from "@kwest_fe/core/src/components/UI/layout/TablePage/TablePage";
import type { PropsWithChildren } from "react";

import type { LayoutReducerAction } from "../../../LayoutProvider";
import { useLayout } from "../../../LayoutProvider";

interface TablePageLayoutProps {
  title: string;
  actions?: JSX.Element;
  overflow?: boolean;
}

const TablePageLayout = ({
  title,
  actions,
  children,
  overflow = true,
}: PropsWithChildren<TablePageLayoutProps>) => {
  const { dispatch } = useLayout();
  return (
    <TablePage
      title={title}
      actions={actions}
      overflow={overflow}
      toggleSidebar={(toggleString: LayoutReducerAction) => {
        dispatch({ type: toggleString });
      }}
    >
      {children}
    </TablePage>
  );
};

export default TablePageLayout;
