import { useGetTeamClientQuery, useOneMembershipQuery } from "@flows-platform/generated/graphql";
import User from "@flows-platform/modules/Shared/UI/molecules/display/User";

interface MemberProps {
  id: string;
  isTeam?: boolean;
}
const Member = ({ id, isTeam = false }: MemberProps) => {
  const { data: userData, loading: isLoadingUser } = useOneMembershipQuery({
    variables: {
      id,
    },
    skip: !id || isTeam,
  });

  const { data: teamData, loading: isLoadingTeam } = useGetTeamClientQuery({
    variables: {
      teamId: id,
    },
    skip: !id || !isTeam,
  });

  const getName = () => {
    if (!id || isLoadingTeam || isLoadingUser) return "-";
    if (isTeam) return `Team ${teamData?.getTeam?.name}`;
    return `${userData?.oneMembership?.firstName} ${userData?.oneMembership?.lastName}`;
  };

  return <User name={getName()} />;
};

export default Member;
