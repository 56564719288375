import { useApolloClient } from "@apollo/client";
import { Box, Button, HStack, Portal, Tag, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import { FlowVariablesProvider } from "@flows-platform/context/FlowVariables";
import type {
  OneFlowFieldsFragment,
  SetFlowCurrentEditorClientMutationFn,
} from "@flows-platform/generated/graphql";
import { useMyWorkspaceQuery } from "@flows-platform/generated/graphql";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import ResizableInput from "@flows-platform/modules/Shared/components/ResizableInput/ResizableInput";
import type { DragObject } from "@flows-platform/modules/TemplateEditor/components/FlowEditor/constants/types";
import FlowEditor from "@flows-platform/modules/TemplateEditor/components/FlowEditor/FlowEditor";
import FlowEditorDrawer from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/FlowEditorDrawer";
import FlowTriggerDrawer from "@flows-platform/modules/TemplateEditor/components/FlowTriggerDrawer/FlowTriggerDrawer";
import InstanceNameMenu from "@flows-platform/modules/TemplateEditor/components/InstanceNameMenu";
import UnsavedChangesAlert from "@flows-platform/modules/TemplateEditor/components/UnsavedChangesAlert/UnsavedChangesAlert";
import useFlowEditorPage from "@flows-platform/modules/TemplateEditor/hooks/useFlowEditorPage";
import type { VariableType } from "@flows-platform/types";
import PageHeader from "@kwest_fe/core/src/components/UI/layout/PageHeader/PageHeader";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import FlowLabels from "./components/FlowLabels";

interface FlowEditorPageProps {
  flow: OneFlowFieldsFragment;
  setFlowCurrentEditor: SetFlowCurrentEditorClientMutationFn;
  isProject?: boolean;
}

export default function TemplateEditorPage({
  setFlowCurrentEditor,
  isProject = false,
  flow,
}: FlowEditorPageProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: triggerDrawerIsOpen,
    onOpen: onOpenTriggerDrawer,
    onClose: onCloseTriggerDrawer,
  } = useDisclosure();
  const { activeFlowStep, setActiveFlowStep, insertStep } = useFlowEditor();
  const client = useApolloClient();
  const {
    state,
    dispatch,
    updatedFlow,
    handleSaveFlow,
    handleSaveFlowVariables,
    hasUnsavedChanges,
    goBack,
  } = useFlowEditorPage({
    flow,
    isProject,
    setFlowCurrentEditor,
  });
  const { myMembership } = useMyMembership();

  const { data } = useMyWorkspaceQuery();
  const defaultProjectPhase = data?.myWorkspace?.projectPhases.find(
    (phase) => phase.position === 0
  );

  const flowVariables = useMemo<Record<string, VariableType>>(
    () => JSON.parse(flow?.variables || "{}"),
    [flow]
  );

  const flowContextSectionVariables = useMemo<Record<string, VariableType>>(
    () => JSON.parse(flow?.contextSection?.variables || "{}"),
    [flow]
  );

  const { t } = useTranslation();

  const onFlowStepInserted = (draggedStep: DragObject) => {
    insertStep({
      client,
      flowId: flow?.id,
      draggedStep,
      isProject,
      defaultProjectPhase,
    });
  };

  /**
   * If someone else is editing,
   * redirect back to flows.
   */
  useEffect(() => {
    if (flow?.currentEditor && flow.currentEditor.id !== myMembership?.id) {
      goBack();
    }
  }, [flow, goBack, myMembership]);

  const onTriggerClicked = () => {
    handleSaveFlow();
    onOpenTriggerDrawer();
  };
  return (
    <VStack spacing={0} alignItems="flex-start" w="full" h="full">
      <PageHeader
        innerPadding
        headerContent={
          <HStack paddingRight={5}>
            <Box marginRight={2}>
              <ResizableInput
                data-testid="flow-template-title-input"
                placeholder="Enter a title"
                value={state.flowName}
                variant="titleInput"
                backgroundColor="transparent"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch({
                    type: "update-flow-editor-state",
                    payload: { flowName: e.target.value },
                  });
                }}
              />
            </Box>
            {flow?.isDraft && (
              <Tag size="sm" flexShrink={0}>
                <Text fontWeight="bold">{t("pages.flow_templates.status.draft")}</Text>
              </Tag>
            )}
          </HStack>
        }
        actions={
          <HStack spacing={5}>
            {flow?.labels?.length && <FlowLabels labels={flow.labels} />}

            <InstanceNameMenu
              flow={updatedFlow}
              onUpdateFlowState={(updatedFlowState) => {
                dispatch({
                  type: "update-flow-editor-state",
                  payload: updatedFlowState,
                });
              }}
            />
            <Button disabled={false} variant="outline" fontSize="14px" h="32px" onClick={goBack}>
              {t("global.actions.cancel")}
            </Button>
            <Button
              data-testid="flow-editor-save-and-close-button"
              colorScheme="gray"
              variant="outline"
              disabled={false}
              onClick={() => {
                handleSaveFlow(true).then(goBack);
              }}
            >
              {t("global.actions.save_and_close")}
            </Button>
          </HStack>
        }
      />
      <UnsavedChangesAlert hasUnsavedChanges={hasUnsavedChanges && !state.savingChanges} />
      <FlowEditor
        flowSteps={flow?.orderedFlowSteps}
        onFlowStepClicked={(flowStep) => {
          setActiveFlowStep(flowStep);
          onOpen();
        }}
        onFlowStepInserted={onFlowStepInserted}
        isProject={isProject}
        onTriggerClicked={onTriggerClicked}
      />
      <Portal>
        <FlowTriggerDrawer
          flowId={flow?.id}
          isProject={isProject}
          initialVariables={flowVariables}
          variableCategories={flow.variableCategories || []}
          isOpen={triggerDrawerIsOpen}
          onSave={handleSaveFlowVariables}
          onClose={onCloseTriggerDrawer}
        />
        <FlowVariablesProvider variables={{ ...flowVariables, ...flowContextSectionVariables }}>
          <FlowEditorDrawer
            onDrawerOpened={async () => {
              await handleSaveFlow();
            }}
            savingChanges={state.savingChanges}
            key={activeFlowStep?.id}
            onClose={() => {
              onClose();
              setTimeout(() => {
                setActiveFlowStep(null);
              }, 200);
            }}
            isOpen={!!activeFlowStep && isOpen}
          />
        </FlowVariablesProvider>
      </Portal>
    </VStack>
  );
}
