import { Box, VStack } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";

import usePageTitle from "../../../../hooks/usePageTitle";
import PageHeader from "../PageHeader/PageHeader";
import PageWrapper from "../PageWrapper";

interface TablePageProps {
  title: string;
  actions?: JSX.Element;
  overflow?: boolean;
  toggleSidebar: (toggleSide: string) => void;
}

const TablePage = ({
  title,
  actions,
  children,
  overflow = true,
  toggleSidebar,
}: PropsWithChildren<TablePageProps>) => {
  usePageTitle(title);
  return (
    <PageWrapper>
      <PageHeader title={title} actions={actions} toggleSidebar={toggleSidebar} />
      <VStack width="100%" h={"94vh"} overflowY={overflow ? "auto" : "hidden"}>
        <Box w="full">{children}</Box>
      </VStack>
    </PageWrapper>
  );
};

export default TablePage;
