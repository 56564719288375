import type { ButtonProps } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useMemo } from "react";

import { AddIcon, CloseIcon, DoneIcon } from "../../../../../theme/icons";

type IconButtonType = "add" | "cancel" | "confirm";

interface IconButtonProps extends Omit<ButtonProps, "type"> {
  onClick: () => void;
  type: IconButtonType;
  size: ButtonProps["size"];
}

const IconButton = ({ onClick, type, size = "sm", ...props }: IconButtonProps) => {
  const iconType = useMemo(() => {
    switch (type) {
      case "add":
        return { icon: <AddIcon />, color: "gray.700" };
      case "cancel":
        return { icon: <CloseIcon />, color: "red.500" };
      case "confirm":
        return { icon: <DoneIcon />, color: "green.500" };
      default:
        return { icon: <CloseIcon />, color: "red.500" };
    }
  }, [type]);
  return (
    <Button
      onClick={onClick}
      variant="unstyled"
      color={iconType.color}
      size={size}
      _hover={{ bg: "gray.100" }}
      lineHeight="100%"
      {...props}
    >
      {iconType.icon}
    </Button>
  );
};

export default IconButton;
