import { useApolloClient } from "@apollo/client";
import {
  Alert,
  Button,
  Divider,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import type { TeamFieldsFragment } from "@flows-platform/generated/graphql";
import {
  useCreateTeamClientMutation,
  useUpdateTeamClientMutation,
} from "@flows-platform/generated/graphql";
import InlineFormErrors from "@flows-platform/modules/Settings/components/InlineFormErrors";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import toastSettings from "@flows-platform/theme/settings";
import WarningAlert from "@kwest_fe/core/src/components/UI/molecules/display/WarningAlert";
import compareObjects from "@kwest_fe/core/src/utils/compareObjects";
import initializeFormValues from "@kwest_fe/core/src/utils/initializeFormValues";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CreateEditTeamSchema from "./schema";

interface CreateEditPersonModalProps {
  teamData: TeamFieldsFragment | null;
  isOpen: boolean;
  onClose: () => void;
}

export default function CreateEditTeamModal({
  teamData,
  isOpen,
  onClose,
}: CreateEditPersonModalProps) {
  const toast = useToast();
  const [submissionErrors, setSubmissionErrors] = useState<string[] | null>(null);
  const client = useApolloClient();

  const { t } = useTranslation();

  const emptyFormValues = {
    name: "",
    email: "",
    phoneNumber: "",
  };

  const [editTeam] = useUpdateTeamClientMutation();
  const [createTeam] = useCreateTeamClientMutation();

  const initialFormValues: typeof emptyFormValues = teamData
    ? initializeFormValues(teamData)
    : emptyFormValues;

  const handleSubmit = async (values: typeof initialFormValues) => {
    /**
     * Validate form values against the schema.
     * This is primarily to enforce transformation of empty values to null.
     * We store empty values as null in the DB, but Formik requires
     * null values to be represented as empty strings.
     */
    const cleanValues = CreateEditTeamSchema.cast(values);
    const modifiedValues = compareObjects(cleanValues, initialFormValues);

    if (teamData)
      return editTeam({
        variables: {
          teamId: teamData.id,
          input: {
            ...modifiedValues,
          },
        },
        onCompleted() {
          toast({
            ...toastSettings,
            status: "success",
            title: t("modals.team.edit.toast.success.title"),
          });
          // TODO: temporary fix to update the cache. Update types on endpoints BE
          client.refetchQueries({
            include: "active",
          });
          onClose();
        },
        onError({ message }) {
          setSubmissionErrors([message]);
          toast({
            ...toastSettings,
            status: "error",
            title: t("modals.team.create.toast.error.title"),
            description: message,
          });
        },
      });

    return createTeam({
      variables: {
        input: {
          ...values,
        },
      },
      onCompleted() {
        toast({
          ...toastSettings,
          status: "success",
          title: t("modals.team.create.toast.success.title"),
        });
        client.refetchQueries({
          include: "active",
        });
        onClose();
      },
      onError({ message }) {
        setSubmissionErrors([message]);
        toast({
          ...toastSettings,
          status: "error",
          title: t("modals.team.create.toast.error.title"),
          description: message,
        });
      },
    });
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Formik
        initialValues={initialFormValues}
        validationSchema={CreateEditTeamSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, dirty, resetForm }) => {
          return (
            <ModalContent>
              <Form>
                <ModalHeader>
                  {teamData ? t("modals.team.edit.title") : t("modals.team.create.title")}
                </ModalHeader>
                <ModalCloseButton />
                <Divider marginBottom="3" />
                <ModalBody>
                  {teamData?.externalSystem && (
                    <WarningAlert message={t("modals.team.edit.external_team_warning")} />
                  )}
                  <SimpleGrid rowGap={4}>
                    <GridItem>
                      <FormikField
                        name="name"
                        label={t("forms.name.label")}
                        placeholder={t("forms.name.placeholder")}
                      />
                    </GridItem>
                    <GridItem>
                      <FormikField
                        name="email"
                        label={t("forms.edit_email.label")}
                        type="email"
                        placeholder={t("forms.new_email.placeholder")}
                        captionText={t("forms.team_email.caption_text")}
                      />
                    </GridItem>
                    <GridItem>
                      <FormikField
                        showOptionalIndicator
                        name="phoneNumber"
                        label={t("forms.phone_number.label")}
                        type="tel"
                        placeholder={t("forms.phone_number.placeholder")}
                        captionText={t("forms.team_phone_number.caption_text")}
                      />
                    </GridItem>
                  </SimpleGrid>
                  {submissionErrors && (
                    <InlineFormErrors
                      title={t("modals.team.toast.save.error.title")}
                      warnings={submissionErrors}
                      onToastClose={() => {
                        setSubmissionErrors(null);
                        resetForm();
                      }}
                    />
                  )}
                </ModalBody>

                <Divider marginTop="5" />
                <ModalFooter>
                  <Button mr={3} variant="ghost" onClick={onClose}>
                    {t("global.actions.cancel")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    {teamData ? t("global.actions.save") : t("global.actions.create")}
                  </Button>
                </ModalFooter>
              </Form>
            </ModalContent>
          );
        }}
      </Formik>
    </Modal>
  );
}
