import { HStack, Tag } from "@chakra-ui/react";
import UnstyledButton from "@core/components/UI/atoms/Buttons/UnstyledButton";
import { CancelIcon } from "@core/theme/icons";

import type { FormikMembershipSelectFieldSelectOption } from "../../FormikMembershipSelectField";

interface SelectedOptionsTagsProps {
  selectedOptions: FormikMembershipSelectFieldSelectOption[];
  onDeleted: (itemValue: string) => void;
}

export default function SelectedOptionsTags({
  selectedOptions,
  onDeleted,
}: SelectedOptionsTagsProps) {
  return (
    <HStack mb={3} flexWrap="wrap">
      {selectedOptions?.map((selectedOption) => (
        <Tag key={selectedOption.value} display={"flex"} alignItems={"center"} pr={0}>
          {selectedOption.label}
          <UnstyledButton
            size="sm"
            onClick={() => {
              onDeleted(selectedOption.value);
            }}
            aria-label="Delete selected"
          >
            <CancelIcon size="sm" />
          </UnstyledButton>
        </Tag>
      ))}
    </HStack>
  );
}
