import omitFields from "@core/utils/omitFields";
import type { AxiosResponse } from "axios";
import { Buffer } from "buffer";
import { useEffect, useMemo, useState } from "react";

import type {
  EncodedFile,
  FileUploaderFileUrl,
} from "../../../components/FileUploader/constants/types";

interface ImageEncoderOptions {
  imageUrls: FileUploaderFileUrl[];
  downloadFile: (fileUrl: string) => Promise<AxiosResponse>;
}

export const encodeImage = (imageBlob: any, contentType?: string) => {
  if (!contentType) return null;
  const base64encodedImage = Buffer.from(imageBlob, "binary").toString("base64");
  return `data:${contentType.toLowerCase()};base64,${base64encodedImage}`;
};

function useImageEncoder({ imageUrls, downloadFile }: ImageEncoderOptions) {
  const [imageIsDownloading, setImageIsDownloading] = useState(false);
  const [encodedImages, setEncodedImages] = useState<Record<string, { encodedFile: EncodedFile }>>(
    {}
  );

  const visibleImageKeys = imageUrls.map((imageUrl) =>
    typeof imageUrl === "string" ? imageUrl : imageUrl.file.name
  );

  const allEncodedImageKeys = Object.keys(encodedImages);

  const downloadAndEncodeFile = async (url: FileUploaderFileUrl) => {
    if (typeof url !== "string") {
      return { [url.file.name]: url };
    }
    if (url in encodedImages) {
      return { [url]: encodedImages[url] };
    }
    const response = await downloadFile(url);
    const contentType =
      typeof response.headers.getContentType === "function"
        ? response.headers.getContentType()
        : undefined;
    return { [url]: { encodedFile: encodeImage(response.data, contentType?.toString()) } };
  };

  const visibleEncodedImages = useMemo<Record<string, { encodedFile: EncodedFile }>>(() => {
    return omitFields(
      encodedImages,
      allEncodedImageKeys.filter((key) => !visibleImageKeys.includes(key))
    );
  }, [encodedImages, allEncodedImageKeys, visibleImageKeys]);

  useEffect(() => {
    if (imageIsDownloading) {
      return;
    }
    if (!imageUrls.length) {
      setEncodedImages({});
      return;
    }
    if (visibleImageKeys.every((key) => allEncodedImageKeys.includes(key))) {
      return;
    }
    setImageIsDownloading(true);
    Promise.all(imageUrls.map(downloadAndEncodeFile))
      .then((encodedImageUrls) => {
        setEncodedImages((prev) => ({
          ...prev,
          ...encodedImageUrls.reduce(
            (acc, encodeObj) => ({
              ...acc,
              ...encodeObj,
            }),
            {}
          ),
        }));
      })
      .finally(() => {
        setImageIsDownloading(false);
      });
  }, [imageUrls, imageIsDownloading, visibleImageKeys, allEncodedImageKeys]);

  return { encodedImages: visibleEncodedImages, imageIsDownloading };
}

export default useImageEncoder;
