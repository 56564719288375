import { Button, ButtonGroup, IconButton } from "@chakra-ui/react";
import { EventsIcon } from "@flows-platform/theme/icons";
import type { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

interface StartInstanceButtonProps {
  isLoading?: boolean;
  isDisabled?: boolean;
  allowScheduling?: boolean;
  onClickStart: () => void;
  onClickSchedule: MouseEventHandler<HTMLButtonElement>;
}

const StartInstanceButton = ({
  isDisabled = false,
  isLoading = false,
  allowScheduling = true,
  onClickSchedule,
  onClickStart,
}: StartInstanceButtonProps) => {
  const { t } = useTranslation();

  return (
    <ButtonGroup size="sm" isAttached isDisabled={isDisabled}>
      <Button
        colorScheme="purple"
        size="sm"
        isLoading={isLoading}
        onClick={onClickStart}
        data-testid="flow-template-start-button"
      >
        {t("global.actions.start")}
      </Button>
      {allowScheduling && (
        <IconButton
          borderLeftColor="white"
          borderLeftWidth={1}
          colorScheme="purple"
          aria-label={t("pages.flows_templates.actions.schedule")}
          onClick={onClickSchedule}
          icon={<EventsIcon />}
        />
      )}
    </ButtonGroup>
  );
};

export default StartInstanceButton;
