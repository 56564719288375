import { validateHtmlEmailField } from "@kwest_fe/core/src/utils/validateHtmlEmailField";
import { FormItemItemType as FormItemType } from "@todo-viewer/generated/graphql";
import type { OfflineImages } from "@todo-viewer/modules/TodoView/providers/TodoViewProvider";
import type { NonNullableOrderedFormItem, OrderedFormItems } from "@todo-viewer/types";
import * as Yup from "yup";

const getFieldValidation = (formItem: NonNullableOrderedFormItem, offlineImages: OfflineImages) => {
  const formFieldTypeValidatorMap = {
    [FormItemType.Date]: Yup.date().nullable(),
    [FormItemType.Boolean]: Yup.bool().nullable(),
    [FormItemType.Number]: Yup.number().nullable(),
    [FormItemType.FileUpload]: Yup.mixed().nullable(),
    [FormItemType.ImageUpload]: Yup.mixed().nullable(),
  };

  let fieldValidator =
    formItem.itemType in formFieldTypeValidatorMap
      ? formFieldTypeValidatorMap[formItem.itemType as keyof typeof formFieldTypeValidatorMap]
      : Yup.string().nullable();

  const fieldRequired =
    !formItem.isOptional &&
    !(
      [FormItemType.ImageUpload, FormItemType.FileUpload].includes(formItem.itemType) &&
      formItem.variableIdentifier &&
      formItem.variableIdentifier in offlineImages
    );

  if (fieldRequired) {
    fieldValidator = fieldValidator.required("This is a required field");
  }

  if (formItem.isList) return Yup.array(fieldValidator).min(fieldRequired ? 1 : 0);

  return fieldValidator;
};

export const getFormValidationSchema = (
  formItems: OrderedFormItems,
  offlineImages: OfflineImages
) => {
  return Yup.object().shape({
    form: Yup.object().shape({
      id: Yup.string(),
      stepVariables: Yup.object().shape(
        formItems?.reduce(
          (acc, formItem) => ({
            ...acc,
            ...(formItem?.variableIdentifier
              ? {
                  [formItem.variableIdentifier]: getFieldValidation(formItem, offlineImages),
                }
              : {}),
          }),
          {} as Record<string, Yup.AnySchema>
        ) || {}
      ),
    }),
  });
};

export const taskValidationSchema = Yup.object().shape({
  id: Yup.string(),
});

export const messageValidationSchema = Yup.object().shape({
  id: Yup.string(),
  subject: Yup.string().required("forms.subject.validation_message"),
  messageContent: Yup.string().required("forms.message_content.validation_message"),
  showCc: Yup.bool(),
  showBcc: Yup.bool(),
  toEmail: Yup.string()
    .test("valid-email", "forms.email.validation_message", validateHtmlEmailField)
    .required("forms.email.required_message"),
  ccEmail: Yup.string()
    .test("valid-email", "forms.email.validation_message", validateHtmlEmailField)
    .nullable(),
  bccEmail: Yup.string()
    .test("valid-email", "forms.email.validation_message", validateHtmlEmailField)
    .nullable(),
  replyToEmail: Yup.string()
    .test("valid-email", "forms.email.validation_message", validateHtmlEmailField)
    .nullable(),
});

export const approvalValidationSchema = Yup.object().shape({
  id: Yup.string(),
  reject: Yup.boolean().required(),
  rejectionComment: Yup.string().when("reject", {
    is: true,
    then: Yup.string().required("A rejection comment is required"),
  }),
});

export default getFormValidationSchema;
