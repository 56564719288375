import type { OneFlow } from "@flows-platform/types";

export interface FlowEditorPageState {
  flowName: string;
  useCustomInstanceNames: boolean;
  instanceName: string;
  initialFlow: OneFlow;
  savingChanges: boolean;
}

interface FlowEditorPageAction {
  type: "update-flow-editor-state";
  payload: Partial<FlowEditorPageState>;
}

export default function flowEditorPageReducer(
  state: FlowEditorPageState,
  action: FlowEditorPageAction
) {
  switch (action.type) {
    case "update-flow-editor-state":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
