import { Button, HStack, Tag, Text, useToast, VStack } from "@chakra-ui/react";
import type { AddSlackIntegrationClientMutationFn } from "@flows-platform/generated/graphql";
import SectionHeader from "@flows-platform/modules/Shared/UI/molecules/layout/SectionHeader/SectionHeader";
import { ColouredSlackIcon } from "@flows-platform/theme/icons";
import toastSettings from "@flows-platform/theme/settings";
import config from "@kwest_fe/core/src/config";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const APP_REQUESTED_SCOPES =
  "chat:write,users:read,users:read.email,channels:read,groups:read,mpim:read,im:read,channels:join";

const USER_SCOPES = "";

const REDIRECT_URI = `${config.backend.uri}/authorize/slack`.replace(/^http:\/\//i, "https://");

interface SlackIntegrationSectionProps {
  hasSlackIntegration: boolean;
  addSlackIntegration: AddSlackIntegrationClientMutationFn;
}

function SlackIntegrationSection({
  hasSlackIntegration,
  addSlackIntegration,
}: SlackIntegrationSectionProps) {
  const toast = useToast();
  const [tokenLoading, setTokenLoading] = useState(false);
  const { t } = useTranslation();

  const getSlackInstallationToken = useCallback(() => {
    setTokenLoading(true);
    addSlackIntegration()
      .then((res) => {
        setTokenLoading(false);
        const slackInstallationToken = res.data?.addSlackIntegration?.state;
        if (slackInstallationToken) {
          const slackOAuthUrl = `https://slack.com/oauth/v2/authorize?scope=${APP_REQUESTED_SCOPES}${
            USER_SCOPES && `&user_scope=${USER_SCOPES}`
          }${
            REDIRECT_URI && `&redirect_uri=${REDIRECT_URI}`
          }&state=${slackInstallationToken}&client_id=${config.slack.clientId}`;
          window.open(slackOAuthUrl, "_blank");
        }
      })
      .catch((err) => {
        setTokenLoading(false);
        toast({
          ...toastSettings,
          status: "error",
          title: t("pages.settings.sections.slack.toast.add.error.title"),
          description: err.message,
        });
      });
  }, []);

  return (
    <VStack width="full" alignItems="start" spacing={7}>
      <SectionHeader
        headingText={t("pages.settings.sections.slack.title")}
        subHeadingText={t("pages.settings.sections.slack.subtitle")}
      />
      <HStack borderWidth={1} borderRadius="lg" paddingY={3} paddingX={5} width="full">
        <ColouredSlackIcon />
        <HStack flexGrow={1}>
          <Text size="sm" color="gray.600" flexGrow={1}>
            {t("pages.settings.sections.slack.text")}
          </Text>
          {hasSlackIntegration && (
            <Tag bgColor="green.400" borderRadius="xl" color="white" fontSize="sm">
              {t("pages.settings.sections.slack.actions.connected")}
            </Tag>
          )}
        </HStack>
        {!hasSlackIntegration && (
          <Button
            onClick={getSlackInstallationToken}
            disabled={hasSlackIntegration}
            isLoading={tokenLoading}
            variant="outline"
          >
            {t("pages.settings.sections.slack.actions.add")}
          </Button>
        )}
      </HStack>
    </VStack>
  );
}

export default SlackIntegrationSection;
