import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18next
  .use(initReactI18next)
  .use(HttpApi) // Registering the back-end plugin
  .use(LanguageDetector) // Registering the detection plugin
  .init({
    // Remove resources from here
    lng: "en",
    fallbackLng: "en",
    supportedLngs: ["en"],
    load: "currentOnly",
    interpolation: {
      escapeValue: false,
    },
    debug: import.meta?.env?.NODE_ENV === "development",
    returnNull: false,
  })
  .catch((err) => {
    console.error(`Error loading locales: ${err}`);
  });

export default i18next;
