import { Box, Heading, HStack, Icon, ListItem, UnorderedList, VStack } from "@chakra-ui/react";
import { FaInfoCircle, FaTimes } from "react-icons/fa";

interface InlineFormErrorsProps {
  title: string;
  warnings: string[];
  onToastClose: () => void;
}

function InlineFormErrors({ title, warnings, onToastClose }: InlineFormErrorsProps) {
  return (
    <Box
      bg="red.100"
      borderLeftWidth={4}
      borderLeftColor="red.500"
      rounded="lg"
      padding={4}
      position="relative"
    >
      <Icon
        as={FaTimes}
        position="absolute"
        top={4}
        right={4}
        boxSize={3}
        cursor="pointer"
        color="gray.500"
        onClick={() => {
          onToastClose();
        }}
      />
      <HStack align="start">
        <Icon as={FaInfoCircle} color="red.500" />
        <VStack align="start" paddingLeft={2}>
          <Heading as="h5" size="sm">
            {title}
          </Heading>
          <UnorderedList listStyleType="none" padding={0} margin={0}>
            {warnings.map((warning) => (
              <ListItem>{warning}</ListItem>
            ))}
          </UnorderedList>
        </VStack>
      </HStack>
    </Box>
  );
}

export default InlineFormErrors;
