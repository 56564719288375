import { useApolloClient } from "@apollo/client";
import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import { FlowStepTypeEnum } from "@flows-platform/generated/graphql";
import type { OneOrderedFlowStep } from "@flows-platform/types";
import type { FlowStepMode } from "@kwest_fe/core/src/enums/flowStep";
import { FlowStepModeEnum } from "@kwest_fe/core/src/enums/flowStep";
import { useDragDropManager } from "react-dnd";

import type {
  PickableStep,
  PreviewStep,
} from "../components/FlowEditor/components/FlowStep/constants/types";
import type { DragObject } from "../components/FlowEditor/constants/types";

interface BaseDragConfigInput {
  mode: FlowStepMode;
  flowId: string;
  step: NonNullable<OneOrderedFlowStep> | PickableStep | PreviewStep;
}

export default function useGetBaseDragConfig({ mode, flowId, step }: BaseDragConfigInput) {
  const { removeStep } = useFlowEditor();
  const client = useApolloClient();
  const manager: ReturnType<typeof useDragDropManager> & { lastDroppedStep?: DragObject | null } =
    useDragDropManager();

  return {
    options: {
      dropEffect: mode === FlowStepModeEnum.Pickable ? "copy" : "move",
    },
    end: (item: any, monitor: any) => {
      /**
       * getClientOffset reports the last recorded pointer offset from the drag operation
       * It returns null if the drag finished inside a droppable.
       * We also only want to remove the inserted step if there is a drop result, which is only
       * present if the drop target is either another FlowStep or the FlowEditorDroppable.
       * Finally, we want to handle the case that the user drops their step back onto
       * a library step, which are marked with a selfDrop property (see useDrop config below)
       */
      if (monitor.getClientOffset() || monitor.getDropResult()?.selfDrop) {
        /**
         * A drop happend outside a droppable, now check that a step was
         * actually inserted, and remove it.
         * manager.lastDroppedStep is set inside FlowEditorDroppable as a safe
         * way of checking whether a step was actually inserted
         */
        if (manager.lastDroppedStep?.id) {
          removeStep({ client, flowId, stepId: manager.lastDroppedStep.id });
        }
      }

      // Reset
      manager.lastDroppedStep = null;
    },
    canDrag: () => {
      // Disable trigger steps from being draggable
      return !(step.stepType === FlowStepTypeEnum.Trigger);
    },
  };
}
