/**
 * The StaticTodoItem serves 2 uses:
 * - Display TodoItems in the Learner view
 * - Display a drag preview in the TodoListEditor
 */
enum StaticTodoItemModeEnum {
  LearnerView = "learner-view",
  DndPreview = "dnd-preview",
}

enum TodoItemEnum {
  TodoItem = "todo-item",
}

export { StaticTodoItemModeEnum, TodoItemEnum };
