import type { VariableType } from "@flows-platform/types";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";

interface FlowVariablesContextInterface {
  variables: Record<string, VariableType>;
}

const FlowVariablesContext = createContext<FlowVariablesContextInterface | null>(null);

interface FlowVariablesProviderProps {
  variables: Record<string, VariableType>;
}

export function FlowVariablesProvider({
  children,
  variables,
}: PropsWithChildren<FlowVariablesProviderProps>) {
  const value = useMemo(() => ({ variables }), [variables]);
  return <FlowVariablesContext.Provider value={value}>{children}</FlowVariablesContext.Provider>;
}

export function useFlowVariables() {
  const context = useContext(FlowVariablesContext);
  if (context === undefined) {
    throw new Error("useFlowVariables must be used within a FlowVariablesProvider");
  }
  return context;
}
