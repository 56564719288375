import type { ApolloError } from "@apollo/client";
import type { PropsWithChildren } from "react";

import ErrorBoundary from "../ErrorBoundary";
import LoadingSpinnerOverlay from "../LoadingSpinnerOverlay";
import Placeholder from "../UI/molecules/display/Placeholder/Placeholder";

interface CompositeLoaderProps {
  loading: boolean;
  error?: ApolloError;
  errorMessage?: string;
  errorDescription?: string;
  loader?: JSX.Element;
}

function CompositeLoader({
  error,
  loading,
  children,
  errorMessage = "Something went wrong while trying to load the workspace data",
  errorDescription,
  loader = <LoadingSpinnerOverlay />,
}: PropsWithChildren<CompositeLoaderProps>) {
  if (loading && !error) return loader;
  if (error)
    return <Placeholder type="error" header={errorMessage} description={errorDescription} />;
  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export default CompositeLoader;
