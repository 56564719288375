import { VStack } from "@chakra-ui/react";
import HTMLContentRenderer from "@kwest_fe/core/src/components/HTMLContentRenderer";
import DoneButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/DoneButton";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import type {
  JourneyStepResourceQuery,
  SubtaskToggleInput,
  TaskSubmitInputType,
} from "@todo-viewer/generated/graphql";
import {
  useSubmitTaskClientMutation,
  useToggleSubtaskStatusClientMutation,
} from "@todo-viewer/generated/graphql";
import { TodoListEnum } from "@todo-viewer/modules/Core/constants/enums";
import messageParent from "@todo-viewer/modules/Core/utils/messageParent";
import StaticTodoItem from "@todo-viewer/modules/Shared/components/StaticTodoItem";
import useRedirectToNextStep from "@todo-viewer/modules/TodoView/hooks/useRedirectToNextStep";
import { Form, Formik } from "formik";

import { taskValidationSchema } from "../../schema";
import TodoViewApprovalComment from "../TodoViewApprovalComment/TodoViewApprovalComment";
import TodoViewLayout from "../TodoViewLayout";
import TodoViewPageHeader from "../TodoViewPageHeader";

interface TodoViewTaskProps {
  isPreview?: boolean;
  resource: JourneyStepResourceQuery["getJourneyStepResource"] & {
    __typename: "TaskResourceType";
  };
}

function TodoViewTask({ resource, isPreview }: TodoViewTaskProps) {
  const [submitTask] = useSubmitTaskClientMutation();
  const { successToast, errorToast } = useDefaultToasts();
  const redirectToNextStep = useRedirectToNextStep();

  const [toggleSubtaskStatus] = useToggleSubtaskStatusClientMutation();

  const toggleSubtask = (subtask: SubtaskToggleInput) => {
    toggleSubtaskStatus({
      variables: {
        input: {
          id: subtask.id,
          isDone: !subtask.isDone,
        },
      },
      optimisticResponse: {
        toggleSubtaskStatus: {
          subtask: {
            ...subtask,
            isDone: !subtask.isDone,
          },
        },
      },
      onError(err) {
        errorToast("There was an error while trying to toggle the subtask")(err);
      },
    });
  };

  const handleSubmit = (task: TaskSubmitInputType) => {
    const { id } = task;
    return submitTask({
      variables: {
        input: {
          id,
        },
      },
      onCompleted(data) {
        successToast("Task completed successfully");
        messageParent("task_completed");
        redirectToNextStep(data.submitTask?.nextStep, resource?.journeyStep?.projectId);
      },
      onError(err) {
        errorToast("There was an error while trying to submit the task")(err);
      },
    });
  };

  if (!resource) return null;
  const {
    content,
    receiver,
    receiverTeam,
    title,
    id,
    rejectedInApproval,
    orderedSubtasks,
    taskType,
    journeyStep,
  } = resource;
  const isCompleted = Boolean(journeyStep?.completedAt);

  return (
    <TodoViewLayout
      isCompleted={!!journeyStep?.completedAt}
      heading={
        <TodoViewPageHeader
          journeyStep={journeyStep}
          receiver={receiver}
          receiverTeam={receiverTeam}
          title={title}
        />
      }
    >
      <VStack align="start" w="full">
        {rejectedInApproval && <TodoViewApprovalComment rejectedInApproval={rejectedInApproval} />}
        <HTMLContentRenderer htmlContent={content} />
        <VStack spacing={4} w="full" py={3}>
          {orderedSubtasks?.map(
            (subtask) =>
              subtask && (
                <StaticTodoItem
                  checkboxDisabled={taskType === TodoListEnum.FlowStep}
                  toggleTodoItem={toggleSubtask}
                  key={subtask.id}
                  todoItem={subtask}
                />
              )
          )}
        </VStack>
        <Formik
          initialValues={{
            id,
          }}
          validationSchema={taskValidationSchema}
          onSubmit={async (_, { setSubmitting }) => {
            try {
              const res = await handleSubmit({ id });
              return res;
            } finally {
              setSubmitting(false);
            }
          }}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form style={{ width: "100%" }}>
              <DoneButton
                isLoading={isSubmitting}
                isDisabled={isSubmitting || isCompleted || isPreview}
              />
            </Form>
          )}
        </Formik>
      </VStack>
    </TodoViewLayout>
  );
}

export default TodoViewTask;
