import { theme } from "@chakra-ui/react";
import { SearchControl } from "@flows-platform/modules/Shared/components/MembershipSelector/components/FormikMembershipSelectField";
import { useEffect, useRef } from "react";
import type { IndicatorSeparatorProps, SelectInstance } from "react-select";
import Select from "react-select";

import type { SingleOption } from "../../types";

interface GenericFilterDropdownProps {
  placeholder?: string;
  options: SingleOption[];
  onChange: (selectedValue: SingleOption) => void;
  onBlur?: () => void;
}

export function IndicatorSeparator({ innerProps }: IndicatorSeparatorProps) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <span style={{ backgroundColor: "transparent" }} {...innerProps} />;
}

export default function GenericFilterDropdown({
  placeholder,
  options,
  onChange,
  onBlur,
}: GenericFilterDropdownProps) {
  const ref = useRef<SelectInstance>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <Select
      ref={ref}
      placeholder={placeholder}
      isSearchable
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      components={{
        Control: SearchControl,
        IndicatorSeparator,
      }}
      styles={{
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isFocused ? theme.colors.blue[50] : "",
          paddingTop: theme.sizes[1],
          paddingBottom: theme.sizes[1],
          "&:active": {
            backgroundColor: theme.colors.blue[50],
          },
        }),
        menuList: (baseStyles) => ({
          ...baseStyles,
          fontSize: 14,
        }),
      }}
    />
  );
}
