import { MenuButton } from "@chakra-ui/react";
import type { FormikSelectFieldSelectOption } from "@core/components/FormikSelectField/FormikSelectField";
import ProgressiveRevealMenu from "@core/components/ProgressiveRevealMenu";
import { useReceiverAssignment } from "@core/context/ReceiverAssignment";
import {
  useAllMembershipsQuery,
  useAllSlackChannelsQuery,
  useAllTeamsQuery,
} from "@core/generated/graphql";
import { ReceiverTypesEnum } from "@core/modules/Core/constants/enums";

import { ReceiverTypes } from "../../constants/constants";
import ReceiverDisplay from "../ReceiverDisplay";
import ReceiverSelector from "../ReceiverSelector";

interface ReceiverAssignmentControlProps {
  onRoleCreated: (identifier: string) => Promise<string>;
  roleRecipients: FormikSelectFieldSelectOption[];
}

export default function ReceiverAssignmentControl({
  onRoleCreated,
  roleRecipients,
}: ReceiverAssignmentControlProps) {
  const {
    updateFormState,
    clearFormState,
    resolvedReceiverType,
    showSlackRecipients,
    hasSelection,
    isSelectedExternalSystem,
  } = useReceiverAssignment();

  const { data: allMembershipsData } = useAllMembershipsQuery();
  const { data: allTeamsData, loading: allTeamsLoading } = useAllTeamsQuery();
  const { data: allSlackChannelsData, loading: slackChannelsLoading } = useAllSlackChannelsQuery({
    skip: !showSlackRecipients,
  });

  const allMemberships = allMembershipsData?.allMemberships?.filter((membership) =>
    isSelectedExternalSystem(membership?.externalSystem?.id)
  );
  const allSlackChannels = allSlackChannelsData?.allSlackChannels;
  const allTeams = allTeamsData?.allTeams?.filter((team) =>
    isSelectedExternalSystem(team?.externalSystem?.id)
  );

  const onMenuItemClick = ({ itemKey }: { itemKey: string }) => {
    const updatedReceiverRole = itemKey !== ReceiverTypesEnum.role ? "" : undefined;
    updateFormState({
      receiverType: itemKey,
      receiverRole: itemKey === ReceiverTypesEnum.initiator ? "initiator" : updatedReceiverRole,
      receiverId: itemKey !== ReceiverTypesEnum.member ? null : undefined,
      receiverTeamId: itemKey !== ReceiverTypesEnum.team ? null : undefined,
      slackChannelId: itemKey !== ReceiverTypesEnum.slack ? "" : undefined,
    });
  };

  const defaultMenuItemComponent = ({ onClose }: { onClose: () => void }) => (
    <ReceiverSelector
      loading={slackChannelsLoading || allTeamsLoading}
      onClose={onClose}
      roleRecipients={roleRecipients}
      slackChannels={allSlackChannels}
      teams={allTeams}
      onReceiverRoleCreated={onRoleCreated}
    />
  );

  const menuItems = {
    [ReceiverTypesEnum.initiator]: {
      ...ReceiverTypes[ReceiverTypesEnum.initiator],
    },
    [ReceiverTypesEnum.role]: {
      ...ReceiverTypes[ReceiverTypesEnum.role],
      component: defaultMenuItemComponent,
    },
    [ReceiverTypesEnum.member]: {
      ...ReceiverTypes[ReceiverTypesEnum.member],
      component: defaultMenuItemComponent,
    },
    [ReceiverTypesEnum.team]: {
      ...ReceiverTypes[ReceiverTypesEnum.team],
      component: defaultMenuItemComponent,
    },
    ...(showSlackRecipients
      ? {
          [ReceiverTypesEnum.slack]: {
            ...ReceiverTypes[ReceiverTypesEnum.slack],
            component: defaultMenuItemComponent,
          },
        }
      : {}),
  };

  return (
    <ProgressiveRevealMenu
      controlLabel="Assign this step to:"
      menuItems={menuItems}
      menuItemOnClick={onMenuItemClick}
      selected={resolvedReceiverType}
      hideClose={!hasSelection}
      onFilterCleared={clearFormState}
    >
      <MenuButton data-testid="flow-step-assignee-button">
        <ReceiverDisplay
          roleRecipients={roleRecipients}
          workspaceMembers={allMemberships}
          slackChannels={allSlackChannels}
          teams={allTeams}
        />
      </MenuButton>
    </ProgressiveRevealMenu>
  );
}
