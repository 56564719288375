import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import FlowsOverviewTable from "@flows-platform/modules/FlowTemplates/components/FlowsOverviewTable/FlowsOverviewTable";
import FlowsOverviewContainer from "@flows-platform/modules/FlowTemplates/FlowsOverviewContainer";
import { useTranslation } from "react-i18next";

import TablePageLayout from "../Shared/UI/layout/MainLayout/components/TablePageLayout/TablePageLayout";
import NewTemplateButton from "./components/NewTemplateButton/NewTemplateButton";

export default function FlowTemplatesPage() {
  const { myMembership } = useMyMembership();
  const { t } = useTranslation();

  return (
    <TablePageLayout
      title={t("pages.flows_templates.title")}
      actions={myMembership?.isAdmin ? <NewTemplateButton /> : undefined}
    >
      <FlowsOverviewContainer
        render={(flows, duplicateFlow, setFlowCurrentEditor) =>
          flows ? (
            <FlowsOverviewTable
              flows={flows}
              duplicateFlow={duplicateFlow}
              setFlowCurrentEditor={setFlowCurrentEditor}
            />
          ) : null
        }
      />
    </TablePageLayout>
  );
}
