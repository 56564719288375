import type { FileMapping } from "@kwest_fe/core/src/components/FileUploader/constants/types";
import { useKwestAuth } from "@kwest_fe/core/src/modules/Core/providers/KwestAuthProvider";
import type { TriggerVariableFormFieldInitialValue } from "@kwest_fe/core/src/types/TriggerVariableFormFieldInitialValue";
import { toBase64 } from "@todo-viewer/utils/imageToBase64String";
import type { Dispatch, PropsWithChildren, SetStateAction } from "react";
import { createContext, useContext, useMemo, useState } from "react";

import FormFiles from "../../../services/formFiles";

interface OfflineImageForVariable {
  files?: (FileMapping & { position: number })[];
  deleted?: { deleteUrl: string; fileUrl: string }[];
  isList?: boolean;
}

export type OfflineImages = Record<string, OfflineImageForVariable>;

interface TodoViewContextInterface {
  offlineImages: OfflineImages;
  setOfflineImages: Dispatch<SetStateAction<OfflineImages>>;
  uploadOfflineImages: (
    stepVariables: Record<string, TriggerVariableFormFieldInitialValue> | undefined,
    uploadUrlFunc: (variableIdentifier: string) => string
  ) => Promise<{ modifiedStepVariables: Record<string, any>; errorUploads: OfflineImages }>;
  hasOfflineImages: (variableIdentifier: string) => boolean;
  updateOfflineImageFields: (
    variableIdentifier: string,
    updateFields: Partial<OfflineImageForVariable>
  ) => void;
  deleteOfflineFileByFileMapping: (
    variableIdentifier: string | null | undefined,
    deletedFileMapping: FileMapping
  ) => void;
}

const TodoViewContext = createContext<TodoViewContextInterface | null>(null);

export const getEncodedFileMapping = async (files: File[]) =>
  Promise.all(files.map(async (file) => ({ file, encodedFile: await toBase64(file) })));

export function TodoViewProvider({ children }: PropsWithChildren) {
  const [offlineImages, setOfflineImages] = useState<OfflineImages>({});
  const { accessToken } = useKwestAuth();

  const hasOfflineImages = (variableIdentifier: string) => variableIdentifier in offlineImages;

  const uploadOfflineImages = async (
    stepVariables: Record<string, TriggerVariableFormFieldInitialValue> | undefined,
    uploadUrlFunc: (variableIdentifier: string) => string
  ) => {
    const errorUploads: OfflineImages = {};
    const modifiedStepVariables = { ...stepVariables };
    const variableIdentifiers = Object.keys(offlineImages).filter(
      (identifier) => (offlineImages[identifier].files || []).length > 0
    );

    if (variableIdentifiers.length > 0) {
      for (const variableIdentifier of variableIdentifiers) {
        const { isList, deleted, files } = offlineImages[variableIdentifier];
        const formFieldValue = modifiedStepVariables[variableIdentifier];
        const currentFieldValueAsList =
          isList && Array.isArray(formFieldValue) ? formFieldValue : [formFieldValue];
        const uploadUrl = uploadUrlFunc(variableIdentifier);
        const { uploadFile, deleteFile } = FormFiles({
          uploadUrl,
          accessToken,
          onFileUploadedSuccess({ data }) {
            const uploadedImageValues = data.map((singleResponseData) => singleResponseData.path);
            if (isList) {
              modifiedStepVariables[variableIdentifier] = [
                ...currentFieldValueAsList.filter(
                  (fileUrl) => fileUrl && typeof fileUrl !== "string"
                ),
                ...uploadedImageValues,
              ];
            } else {
              modifiedStepVariables[variableIdentifier] = uploadedImageValues.pop();
            }
          },
          async onFileUploadError(data, errorFiles) {
            const encodedFileMappingWithPositions = (await getEncodedFileMapping(errorFiles)).map(
              (fileMapping, index) => ({
                ...fileMapping,
                position: currentFieldValueAsList.filter(Boolean).length + index,
              })
            );
            errorUploads[variableIdentifier] = {
              files: encodedFileMappingWithPositions,
              isList,
            };
          },
          onFileDeletedError(err, deleteFileUrl, fileUrl) {
            const fieldErrors = errorUploads[variableIdentifier];
            errorUploads[variableIdentifier] = {
              ...fieldErrors,
              deleted: [...(fieldErrors.deleted ?? []), { deleteUrl: deleteFileUrl, fileUrl }],
            };
          },
        });
        if (deleted) {
          for (const deletedFile of deleted) {
            await deleteFile(deletedFile.deleteUrl, deletedFile.fileUrl);
          }
        }
        if (files) {
          await uploadFile(files.map((file) => file.file));
        }
      }
    }
    return { modifiedStepVariables, errorUploads };
  };

  const updateOfflineImageFields = (
    variableIdentifier: string,
    updateFields: Partial<OfflineImageForVariable>
  ) => {
    if (variableIdentifier) {
      setOfflineImages((allOfflineImages) => ({
        ...allOfflineImages,
        [variableIdentifier]: {
          ...(allOfflineImages[variableIdentifier] ?? {}),
          ...updateFields,
        },
      }));
    }
  };

  const deleteOfflineFileByFileMapping = (
    variableIdentifier: string | null | undefined,
    deletedFileMapping: FileMapping
  ) => {
    if (variableIdentifier) {
      const variableOfflineFiles = offlineImages[variableIdentifier].files ?? [];
      const deleteIndex = variableOfflineFiles.findIndex(
        (fileUrl) => JSON.stringify(fileUrl) === JSON.stringify(deletedFileMapping)
      );
      if (deleteIndex > -1) {
        updateOfflineImageFields(variableIdentifier, {
          files: variableOfflineFiles.filter((_, index) => index !== deleteIndex),
        });
      }
    }
  };

  const value = useMemo(
    () => ({
      offlineImages,
      setOfflineImages,
      uploadOfflineImages,
      hasOfflineImages,
      updateOfflineImageFields,
      deleteOfflineFileByFileMapping,
    }),
    [offlineImages, uploadOfflineImages, hasOfflineImages]
  );

  return <TodoViewContext.Provider value={value}>{children}</TodoViewContext.Provider>;
}

export function useTodoView() {
  const context = useContext(TodoViewContext);
  if (!context) {
    throw new Error("useTodoView must be used within a TodoViewProvider");
  }
  return context;
}
