import type { TextareaProps } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import autosize from "autosize";
import { useEffect, useRef } from "react";

function ResizableTextarea(props: TextareaProps) {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) autosize(ref.current);
    return () => {
      if (ref.current) autosize.destroy(ref.current);
    };
  }, [ref]);

  return (
    <Textarea
      ref={ref}
      style={{ transition: "height none", height: "auto", minHeight: "auto" }}
      {...props}
    />
  );
}

export default ResizableTextarea;
