import { Icon } from "./icon";

export function CodeIcon() {
  return <Icon name="code" size="sm" />;
}

export function BoldIcon() {
  return <Icon name="format_bold" size="sm" />;
}

export function ItalicIcon() {
  return <Icon name="format_italic" size="sm" />;
}

export function UnderlineIcon() {
  return <Icon name="format_underlined" size="sm" />;
}

export function OrderedListIcon() {
  return <Icon name="format_list_numbered" size="sm" />;
}

export function UnorderedListIcon() {
  return <Icon name="format_list_bulleted" size="sm" />;
}

export function AlignLeftIcon() {
  return <Icon name="format_align_left" size="sm" />;
}

export function AlignCenterIcon() {
  return <Icon name="format_align_center" size="sm" />;
}

export function AlignRightIcon() {
  return <Icon name="format_align_right" size="sm" />;
}

export function AlignJustifyIcon() {
  return <Icon name="format_align_justify" size="sm" />;
}

export function CameraIcon() {
  return <Icon name="photo_camera" size="sm" />;
}

export function FileAttachedIcon() {
  return <Icon name="file_present" size="md" />;
}
