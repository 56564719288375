import type { BoxProps } from "@chakra-ui/react";

export const CaptionBoxStyles: BoxProps = {
  wordBreak: "break-word",
  sx: {
    "ol, ul": {
      paddingLeft: 6,
    },
    "p + p": {
      marginTop: "1em",
    },
    p: {
      fontSize: "14px",
    },
  },
};

export default CaptionBoxStyles;
