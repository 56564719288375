import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const EmptyState = () => {
  const { t } = useTranslation();
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Text>{t("pages.project_instance.comments.no_comments")}</Text>
    </Box>
  );
};

export default EmptyState;
