import { useApolloClient } from "@apollo/client";
import { Box, HStack, Tag, VStack } from "@chakra-ui/react";
import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import EditableTodoItem from "@flows-platform/modules/Shared/components/EditableTodoItem";
import useWorkspaceProjectPhases from "@flows-platform/modules/Shared/hooks/useWorkspaceProjectPhases";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import type { OrderedSubtasks } from "@flows-platform/types";
import FormikSelectField from "@kwest_fe/core/src/components/FormikSelectField/FormikSelectField";
import ReceiverAssignment from "@kwest_fe/core/src/components/ReceiverAssignment/ReceiverAssignment";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useField } from "formik";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import type { OptionProps } from "react-select";
import { components } from "react-select";
import { v4 as uuidv4 } from "uuid";

import ConnectFlow from "./components/SelectFlow/ConnectFlow/ConnectFlow";

const SUBTASKS_FORM_KEY = "task.orderedSubtasks";

function TagOptionDisplay({ children, ...props }: OptionProps & { data: { color: string } }) {
  return (
    <components.Option {...props}>
      <HStack>
        <Tag bg={props.data.color || undefined} color="white">
          {children}
        </Tag>
      </HStack>
    </components.Option>
  );
}

function TaskEditor() {
  const client = useApolloClient();
  const { addRoleVariable, recipients, variableStore } = useFlowEditorDrawer() || {};
  const [{ value: orderedSubtasks }, , { setValue: setOrderedSubtasks }] =
    useField<OrderedSubtasks>(SUBTASKS_FORM_KEY);
  const { t } = useTranslation();
  const { activeFlowStep, readFlowFromCache } = useFlowEditor();
  const { projectPhases, loading, error } = useWorkspaceProjectPhases();
  const { isProject } = activeFlowStep?.flow || {};

  const currentFlow =
    activeFlowStep?.flow &&
    readFlowFromCache({
      client,
      flowId: activeFlowStep.flow.id,
    });

  const usedPhases = new Set(
    currentFlow?.orderedFlowSteps?.map((flowStep) => flowStep?.phase?.id).filter(Boolean) || []
  );

  const projectPhaseOptions = projectPhases
    ?.filter((phase) => phase.id === activeFlowStep?.phase?.id || !usedPhases.has(phase.id))
    .map(({ name, id, color }) => ({
      label: name,
      value: id,
      color,
    }));

  if (!recipients || !addRoleVariable) return <></>;

  return (
    <DndProvider backend={HTML5Backend}>
      <VStack align="stretch" spacing={5} data-testid="task-editor">
        <ReceiverAssignment
          parent="task"
          roleRecipients={recipients}
          onRoleCreated={addRoleVariable}
        />
        <VStack align="stretch" spacing={8}>
          <Box pb={6} borderWidth={1} borderRadius="sm">
            <RichTextEditor
              name="task.content"
              variables={variableStore}
              hideBorder
              enableImageUploads
            />
            <VStack spacing={3} alignItems="flex-start" px={5}>
              {orderedSubtasks?.map(
                (subtask, index) =>
                  subtask && (
                    <EditableTodoItem
                      key={subtask.id}
                      todoItem={subtask}
                      index={index}
                      parent={SUBTASKS_FORM_KEY}
                      hideResource
                    />
                  )
              )}
              <AddButton
                onClick={() => {
                  setOrderedSubtasks([
                    ...(orderedSubtasks || []),
                    {
                      id: uuidv4(),
                      name: "",
                      description: "",
                      isDone: false,
                    },
                  ]);
                }}
              >
                Add subtask
              </AddButton>
            </VStack>
          </Box>
          {isProject && <ConnectFlow name="task.connectedFlow" />}
          {isProject && (
            <FormikSelectField
              data-testid="task-phase-selector"
              name="phaseId"
              label={t("pages.flow_step.forms.phase.label")}
              placeholder={t("pages.flow_step.forms.phase.placeholder")}
              options={projectPhaseOptions}
              showOptionalIndicator
              loading={loading}
              disabled={!!error || activeFlowStep?.phase?.position === 0}
              useTagSingleDisplay
              optionComponent={TagOptionDisplay}
            />
          )}
        </VStack>
      </VStack>
    </DndProvider>
  );
}

export default TaskEditor;
