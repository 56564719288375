import {
  Checkbox,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import type { OneFlow } from "@flows-platform/types";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import { CustomizeIcon } from "@kwest_fe/core/src/theme/icons";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import FlowStepWarnings from "../FlowStepWarnings/FlowStepWarnings";
import LabelSelector from "./components/LabelSelector";

interface InstanceNameMenuProps {
  flow: OneFlow;
  onUpdateFlowState: (updatedFlowState: {
    useCustomInstanceNames?: boolean;
    instanceName?: string;
  }) => void;
}

export default function InstanceNameMenu({ flow, onUpdateFlowState }: InstanceNameMenuProps) {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        instanceName: flow?.instanceName || "",
        useCustomInstanceNames: Boolean(flow?.useCustomInstanceNames),
      }}
      onSubmit={({ instanceName, useCustomInstanceNames }) => {
        onUpdateFlowState({
          instanceName: useCustomInstanceNames ? instanceName : "",
          useCustomInstanceNames: Boolean(useCustomInstanceNames),
        });
      }}
    >
      {({ submitForm, values }) => (
        <HStack align="flex-end">
          {flow?.warnings && <FlowStepWarnings iconOnly warnings={flow?.warnings} />}
          <Popover onClose={() => submitForm} closeOnBlur={false} placement="bottom-end">
            {({ isOpen }) => (
              <>
                <PopoverTrigger>
                  <IconButton
                    size="sm"
                    aria-label="Flow Settings"
                    bgColor={isOpen ? "gray.300" : "gray.100"}
                    icon={<CustomizeIcon />}
                  />
                </PopoverTrigger>
                <PopoverContent width={"sm"}>
                  <PopoverHeader>
                    <HStack>
                      <CustomizeIcon />
                      <Text fontSize="sm" fontWeight="bold" textTransform="uppercase">
                        {t("models.flow.menus.instance_name.title")}
                      </Text>
                    </HStack>
                  </PopoverHeader>
                  <PopoverBody>
                    <VStack gap={2}>
                      <LabelSelector flowId={flow?.id || ""} />

                      <FormikField
                        name="useCustomInstanceNames"
                        renderElement={({ value, onChange, name }) => (
                          <Checkbox
                            name={name}
                            isChecked={value}
                            onChange={(...args) => {
                              onChange(...args);
                              submitForm();
                            }}
                          >
                            <Text fontWeight="normal">{t("forms.flow_use_custom_name.label")}</Text>
                          </Checkbox>
                        )}
                      />
                      {values.useCustomInstanceNames && (
                        <VStack alignItems="flex-start" w="full" maxW={400}>
                          <RichTextEditor
                            name="instanceName"
                            label={t("forms.flow_instance_name.label")}
                            placeholder={t("forms.flow_instance_name.placeholder")}
                            variables={JSON.parse(flow?.allVariables || "{}")}
                            excludedVariableTypes={[
                              FlowStepVariableTypeEnum.FILE,
                              FlowStepVariableTypeEnum.IMAGE,
                            ]}
                            hideToolbar
                            isSingleLine
                            onChange={() => {
                              submitForm();
                            }}
                          />
                          <Text fontSize="md" color="gray.500">
                            {t("models.flow.menus.instance_name.help_text")}
                          </Text>
                        </VStack>
                      )}
                    </VStack>
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </HStack>
      )}
    </Formik>
  );
}
