function mapObjectValues<T, A>(obj: Record<string, T>, func: (key: string, obj: T) => A) {
  const mapped = Object.keys(obj).reduce<Record<string, A>>(
    (acc, key) => ({
      ...acc,
      [key]: func(key, obj[key]),
    }),
    {}
  );
  return mapped;
}

export default mapObjectValues;
