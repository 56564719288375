import {
  Alert,
  Icon,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { WarningIcon, WarningIconCircle } from "@kwest_fe/core/src/theme/icons";
import { useTranslation } from "react-i18next";

interface FlowStepWarningProps {
  warnings: (string | null)[];
  iconOnly?: boolean;
}

function FlowStepWarnings({ warnings, iconOnly = false }: FlowStepWarningProps) {
  const hasWarnings = warnings.length > 0;
  const { t } = useTranslation();

  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <Alert
          bgColor="yellow.100"
          color="gray.600"
          fontSize="sm"
          marginTop={iconOnly ? undefined : 2}
          paddingY={1}
          paddingX={2}
          borderRadius={iconOnly ? "lg" : "xl"}
          boxShadow="sm"
        >
          {iconOnly ? (
            <WarningIcon />
          ) : (
            <>
              <Icon as={WarningIconCircle} marginRight={1} />
              {t("pages.flow_step.warnings.work_to_do")}
            </>
          )}
        </Alert>
      </PopoverTrigger>
      <PopoverContent bg={!hasWarnings ? "gray.600" : undefined}>
        <PopoverArrow bg={!hasWarnings ? "gray.600" : undefined} />
        <PopoverCloseButton />
        <PopoverBody>
          {warnings.length ? (
            <UnorderedList>
              {warnings.map((warningMessage) => (
                <ListItem
                  fontSize="sm"
                  key={warningMessage}
                  color={!hasWarnings ? "white" : undefined}
                >
                  {warningMessage}
                </ListItem>
              ))}
            </UnorderedList>
          ) : (
            <Text fontSize="sm" color={!hasWarnings ? "white" : undefined}>
              {t("pages.flow_step.warnings.step_not_set_up")}
            </Text>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default FlowStepWarnings;
