import { Box, Flex, Text } from "@chakra-ui/react";
import LabelledIconButton from "@core/components/UI/atoms/Buttons/LabelledIconButton";
import UploadIconButton from "@core/components/UI/atoms/Buttons/UploadIconButton";
import { AddIcon, DownloadIcon } from "@core/theme/icons";
import type { DropzoneInputProps } from "react-dropzone";
import { useTranslation } from "react-i18next";

interface FileUploaderMultiFileControlsProps {
  downloadAllFiles: () => void;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
  uploadProgress: number | null;
}

export default function FileUploaderMultiFileControls({
  downloadAllFiles,
  getInputProps,
  uploadProgress,
}: FileUploaderMultiFileControlsProps) {
  const { t } = useTranslation();
  return (
    <Flex alignItems="center" w="full">
      <Box flexGrow={1}>
        <UploadIconButton
          isLoading={uploadProgress !== null}
          aria-label={t("global.actions.upload_more")}
          icon={<AddIcon />}
          {...getInputProps()}
        />
      </Box>
      <LabelledIconButton
        aria-label={t("global.actions.download_all")}
        variant="outline"
        type="button"
        onClick={downloadAllFiles}
        icon={<DownloadIcon />}
      >
        <Text>{t("global.actions.download_all")}</Text>
      </LabelledIconButton>
    </Flex>
  );
}
