import type { TableContainerProps } from "@chakra-ui/react";
import {
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from "@chakra-ui/react";
import type {
  AllMembershipsWithAdminFieldsPaginatedQuery,
  AllMembershipsWithAdminFieldsPaginatedQueryHookResult,
  ExtendedMembershipFieldsFragment,
} from "@flows-platform/generated/graphql";
import { useOneMembershipLazyQuery } from "@flows-platform/generated/graphql";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import InfiniteScrollerWithAutoLoad from "@flows-platform/modules/Shared/UI/layout/InfiniteScrollerWithAutoLoad";
import SortableColumnHeader from "@flows-platform/modules/Shared/UI/molecules/layout/SortableColumnHeader";
import Avatar from "@kwest_fe/core/src/components/UI/atoms/Avatar";
import ContextMenu from "@kwest_fe/core/src/components/UI/organisms/ContextMenu";
import { useTranslation } from "react-i18next";

export interface PeopleOverviewTableProps {
  people: AllMembershipsWithAdminFieldsPaginatedQuery["allMembershipsPaginated"];
  refetch: AllMembershipsWithAdminFieldsPaginatedQueryHookResult["refetch"];
  fetchMoreData: () => void;
  totalRetrieved: number;
  onEditMember: (membership: ExtendedMembershipFieldsFragment) => void;
  toggleSort: (column: string) => () => void;
  sortOrderIcon: (column: string) => JSX.Element | null;
}

export default function PeopleOverviewTable({
  people,
  refetch,
  fetchMoreData,
  totalRetrieved,
  onEditMember,
  toggleSort,
  sortOrderIcon,
}: PeopleOverviewTableProps) {
  const { myMembership } = useMyMembership();
  const { t } = useTranslation();

  const [fetchFullMembership] = useOneMembershipLazyQuery({ fetchPolicy: "cache-first" });
  const typedPeople = people?.results as ExtendedMembershipFieldsFragment[];
  const rows = typedPeople.map(
    ({ id, firstName, lastName, workspaceEmail, permissionLevel, externalSystem }, i) => {
      const borderWidth = i === typedPeople.length - 1 ? "0px" : "1px";
      return (
        <Tr key={id}>
          <Td borderBottomWidth={borderWidth} colSpan={2}>
            <HStack spacing={3}>
              <Avatar size="md" name={`${firstName} ${lastName}`} />
              <VStack alignItems="flex-start" spacing={0}>
                <Text fontSize="sm" fontWeight="bold">{`${firstName} ${lastName}`}</Text>
                <Text fontSize="sm" color="gray.500">
                  {workspaceEmail}
                </Text>
              </VStack>
            </HStack>
          </Td>
          <Td borderBottomWidth={borderWidth}>
            <Text fontSize={"14px"}>{permissionLevel}</Text>
          </Td>
          <Td borderBottomWidth={borderWidth}>
            <Text fontSize={"14px"}>{externalSystem ? externalSystem.name : "-"}</Text>
          </Td>
          {myMembership?.isAdmin && (
            <Td isNumeric borderBottomWidth={borderWidth}>
              <ContextMenu
                onEdit={() => {
                  fetchFullMembership({
                    variables: { id },
                    onCompleted(data) {
                      if (data.oneMembership) onEditMember(data.oneMembership);
                    },
                  });
                }}
              />
            </Td>
          )}
        </Tr>
      );
    }
  );

  const tableContainerProps: TableContainerProps = {
    w: "full",
    borderRadius: "xl",
    padding: 3,
    border: 1,
    borderColor: "gray.100",
    borderStyle: "solid",
  };

  return (
    <VStack alignItems="stretch" w="full">
      <InfiniteScrollerWithAutoLoad
        refresh={refetch}
        fetchMoreData={fetchMoreData}
        hasMore={Boolean(people?.more)}
        totalRetrieved={totalRetrieved}
        scrollableTarget="page-scroll-container"
      >
        <TableContainer {...tableContainerProps}>
          <Table variant="simple">
            <Thead>
              <Tr>
                <SortableColumnHeader
                  sortIcon={sortOrderIcon("first_name")}
                  onClick={toggleSort("first_name")}
                  colSpan={2}
                >
                  {t("pages.people.table.headers.name")}
                </SortableColumnHeader>
                <Th>{t("pages.people.table.headers.role")}</Th>
                <Th>{t("pages.people.table.headers.system")}</Th>
                {myMembership?.isAdmin && <Th />}
              </Tr>
            </Thead>

            <Tbody>{rows}</Tbody>
          </Table>
        </TableContainer>
      </InfiniteScrollerWithAutoLoad>
    </VStack>
  );
}
