import { Box, HStack, Text } from "@chakra-ui/react";
import { useConnectedFlow } from "@flows-platform/context/ConnectedFlow";
import FormikSelectField from "@kwest_fe/core/src/components/FormikSelectField/FormikSelectField";
import { FloatingSelectStyles } from "@kwest_fe/core/src/components/ReceiverAssignment/constants/constants";
import { useMemo } from "react";
import { components } from "react-select";

interface FormikFlowSelectorProps {
  onClose: () => void;
  onChange: (newId: string) => void;
}

const FormikFlowSelector = ({ onClose, onChange }: FormikFlowSelectorProps) => {
  const { availableTemplates } = useConnectedFlow();

  const flowsOptions = useMemo(() => {
    if (!availableTemplates?.length) return [];
    return availableTemplates
      .filter((result) => result?.name)
      .map((result) => ({
        label: result?.name || "",
        value: result?.id,
      }));
  }, [availableTemplates]);

  return (
    <Box
      position="absolute"
      display={"block"}
      top={9}
      left={0}
      minWidth={300}
      borderWidth={1}
      bgColor="white"
      paddingY={3}
      paddingX={3}
      zIndex={10}
    >
      <FormikSelectField
        name="task.connectedFlow.id"
        label="FLOWS"
        labelStyles={{ fontSize: "12px" }}
        placeholder="Select a Flow"
        options={flowsOptions}
        styles={FloatingSelectStyles}
        defaultMenuIsOpen
        onBlur={onClose}
        onChange={onChange}
        optionComponent={({ children, ...props }) => (
          <components.Option {...props}>
            <HStack>
              <Text>{children}</Text>
            </HStack>
          </components.Option>
        )}
      />
    </Box>
  );
};

export default FormikFlowSelector;
