import { gql } from "@apollo/client";

export const MY_MEMBERSHIP_FIELDS = gql`
  fragment MyMembershipFields on MembershipType {
    id
    firstName
    lastName
    privateEmail
    workspaceEmail
    phoneNumber
    startDate
    endDate
    permissionLevel
    isAdmin @client
    isGuest @client
    isFieldWorker
    teams {
      id
      name
      externalSystem {
        id
        name
      }
    }
    featureFlags {
      id
      featureFlag {
        id
        name
      }
    }
  }
`;

export const CORE_MEMBERSHIP_FIELDS = gql`
  fragment CoreMembershipFields on MembershipType {
    id
    firstName
    lastName
    privateEmail
    workspaceEmail
    phoneNumber
    permissionLevel
  }
`;

export const EXTENDED_MEMBERSHIP_FIELDS = gql`
  ${CORE_MEMBERSHIP_FIELDS}
  fragment ExtendedMembershipFields on MembershipType {
    ...CoreMembershipFields
    startDate
    endDate
    slackId
    isFieldWorker
    relationshipsIncoming {
      contact1 {
        id
      }
      roleOf1
    }
    teams {
      id
      name
      email
      phoneNumber
    }
    membershipsToMembersgroups {
      group {
        id
        name
      }
    }
    externalSystem {
      id
      name
      domain
    }
  }
`;

export const TODO_VIEW_MEMBERSHIP_FIELDS = gql`
  fragment TodoViewMembershipFields on MembershipType {
    id
    firstName
    lastName
    workspaceEmail
  }
`;
