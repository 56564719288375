import { Box, Text } from "@chakra-ui/react";
import QueryLoaderContainer from "@kwest_fe/core/src/components/QueryLoaderContainer";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useJourneysAwaitingUserTodayQuery } from "../../../../../../generated/graphql";
import ContextDrawerReadOnly from "../../../../../TaskView/ContextDrawerReadOnly";
import InstallerTodoCard from "./components/InstallerTodoCard";

const InstallerTodosBoard = () => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  return (
    <QueryLoaderContainer
      query={useJourneysAwaitingUserTodayQuery}
      queryOptions={{ fetchPolicy: "cache-and-network" }}
      loadingErrorMessage="Something went wrong while trying to load inbox tasks"
      render={({ data: journeys }) =>
        !journeys?.journeysAwaitingUserToday?.length ? (
          <Box p={5}>
            <Text fontSize={"sm"}>{t("pages.inbox.table.empty_message")}</Text>
          </Box>
        ) : (
          <Box display={"flex"} flexDir={"column"} gap={2}>
            {journeys?.journeysAwaitingUserToday?.map((journey) => (
              <InstallerTodoCard
                onProjectClick={() => {
                  setIsDrawerOpen(true);
                  setSelectedProjectId(journey?.projectId || "");
                }}
                journey={{
                  projectId: journey?.projectId || "",
                  projectName: journey?.projectInstanceName || journey?.projectTemplateName || "",
                  projectTasks: journey?.projectTasks?.map((task) => ({
                    taskId: task?.taskId || "",
                    stepName: task?.stepName || "",
                    dueDate: task?.dueDate || "",
                  })),
                  todoSteps: journey?.todoSteps?.map((step) => ({
                    stepId: step?.stepId || "",
                    stepName: step?.stepName || "",
                  })),
                }}
                key={journey?.projectId}
              />
            ))}
            <ContextDrawerReadOnly
              isOpen={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
              }}
              projectId={selectedProjectId}
            />
          </Box>
        )
      }
    />
  );
};

export default InstallerTodosBoard;
