import { AuthenticatorTypeEnum } from "@flows-platform/generated/graphql";
import * as Yup from "yup";

export default {
  httpRequest: Yup.object({
    id: Yup.string(),
    url: Yup.string().nullable(),
    authenticator: Yup.object({
      id: Yup.string(),
      type: Yup.string().oneOf(Object.values(AuthenticatorTypeEnum)),
    }).nullable(),
    requestBody: Yup.string(),
    variables: Yup.array(
      Yup.object({
        variableId: Yup.string().required(),
        variable: Yup.object({
          label: Yup.string().required(),
          type: Yup.string().required(),
        }),
        responseKey: Yup.string().required(),
      })
    ),
  }).nullable(),
};
