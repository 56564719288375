import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  HStack,
  MenuItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import toastSettings from "@flows-platform/theme/settings";
import type { VariableType } from "@flows-platform/types";
import { DeleteIcon } from "@kwest_fe/core/src/theme/icons";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

interface DeleteFlowStepModalProps {
  flowStepId: string;
  flowId: string;
}

function DeleteFlowStepModal({ flowStepId, flowId }: DeleteFlowStepModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { removeStep, getFlowStepById } = useFlowEditor();
  const client = useApolloClient();
  const toast = useToast();
  const { t } = useTranslation();

  const getStepById = useCallback(
    (stepId: string) => getFlowStepById({ client, flowId, flowStepId: stepId }),
    [getFlowStepById, client, flowId]
  );

  const flowStep = getStepById(flowStepId);
  const flowStepVariables: Record<string, VariableType[]> | object = flowStep?.variables
    ? JSON.parse(flowStep.variables)
    : {};

  const addVariableToAffectedSteps = (
    variable: VariableType,
    init: Record<string, VariableType[]>
  ) => {
    return (variable.usages || []).reduce((acc, stepId) => {
      if (stepId !== flowStepId) {
        return {
          ...acc,
          [stepId]: [...(acc[stepId] || []), variable],
        };
      }
      return acc;
    }, init);
  };

  const affectedSteps = Object.values(flowStepVariables).reduce<Record<string, VariableType[]>>(
    (acc, variable) => {
      if (!variable.is_scoped || variable.defined_at !== flowStepId) return acc;
      return addVariableToAffectedSteps(variable, acc);
    },
    {}
  );

  const doDeleteFlowStep = () => {
    removeStep({ client, stepId: flowStepId, flowId });
    toast({
      ...toastSettings,
      status: "success",
      title: t("modals.flow_step.delete.toast.success.title"),
    });
    onClose();
  };

  return (
    <>
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          onOpen();
        }}
      >
        <HStack>
          <DeleteIcon color="red.500" />
          <Text color="red.500" fontSize="md">
            {t("modals.flow_step.delete.menu_item")}
          </Text>
        </HStack>
      </MenuItem>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {Object.keys(affectedSteps).length
              ? t("modals.flow_step.delete.title_variable_deletion")
              : t("modals.flow_step.delete.title")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!Object.keys(affectedSteps).length ? (
              <Text>{t("modals.flow_step.delete.body")}</Text>
            ) : (
              <>
                <Text as="p" mb={4}>
                  {t("modals.flow_step.delete.body_variable_deletion")}
                </Text>
                <VStack spacing={4} align="left" w="full">
                  {Object.keys(affectedSteps).map((stepId) => (
                    <Box key={stepId}>
                      <Text>
                        <Text as="b">Affected Step:</Text> {getStepById(stepId)?.name}
                      </Text>
                      {affectedSteps[stepId].map((variable) => (
                        <Text key={variable.label}>
                          <Text as="b">Variable:</Text> {variable.label}
                        </Text>
                      ))}
                    </Box>
                  ))}
                </VStack>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={doDeleteFlowStep}>
              {t("global.actions.delete")}
            </Button>
            <Button variant="ghost" onClick={onClose}>
              {t("global.actions.cancel")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteFlowStepModal;
