import { ModalFooter } from "@chakra-ui/react";
import FooterButtons from "@flows-platform/modules/Shared/UI/molecules/forms/FooterButtons";

interface ModalFooterTemplateProps {
  onCancel: () => void;
  onConfirm: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const ModalFooterTemplate = ({
  onCancel,
  onConfirm,
  isDisabled,
  isLoading,
}: ModalFooterTemplateProps) => {
  return (
    <ModalFooter>
      <FooterButtons
        onCancel={onCancel}
        onConfirm={onConfirm}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    </ModalFooter>
  );
};

export default ModalFooterTemplate;
