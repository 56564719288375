import type { ApolloCache } from "@apollo/client";
import { Flex, useDisclosure } from "@chakra-ui/react";
import type {
  AllAuthenticatorsQuery,
  CreateAuthenticatorBasicMutation,
  CreateAuthenticatorBearerMutation,
  CreateAuthenticatorOauth2Mutation,
} from "@flows-platform/generated/graphql";
import {
  AllAuthenticatorsDocument,
  AuthenticatorTypeEnum,
  useCreateBasicAuthenticatorClientMutation,
  useCreateBearerAuthenticatorClientMutation,
  useCreateOAuth2AuthenticatorClientMutation,
} from "@flows-platform/generated/graphql";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

import AuthenticatorForm from "../AuthenticatorList/components/UpdateAuthenticatorModal/components/AuthenticatorForm/AuthenticatorForm";
import type { AuthenticatorFormValues } from "../AuthenticatorList/components/UpdateAuthenticatorModal/schema";

interface NewAuthenticatorModalProps {
  allAuthenticators: AllAuthenticatorsQuery["allAuthenticators"];
}

function NewAuthenticatorModal({ allAuthenticators }: NewAuthenticatorModalProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const addAuthenticatorToCache = ({
    cache,
    createdAuthenticator,
  }: {
    cache: ApolloCache<any>;
    createdAuthenticator:
      | CreateAuthenticatorBasicMutation["authenticator"]
      | CreateAuthenticatorBearerMutation["authenticator"]
      | CreateAuthenticatorOauth2Mutation["authenticator"];
  }) => {
    if (createdAuthenticator) {
      const { id, name, type } = createdAuthenticator;
      cache.writeQuery<AllAuthenticatorsQuery>({
        query: AllAuthenticatorsDocument,
        data: {
          allAuthenticators: [
            ...(allAuthenticators || []),
            { id, name, type, __typename: "BaseAuthenticatorType" },
          ],
        },
      });
    }
  };

  const [createOAuth2Authenticator] = useCreateOAuth2AuthenticatorClientMutation({
    update(cache, { data }) {
      const createdAuthenticator = data?.createAuthenticatorOauth2?.authenticator;
      addAuthenticatorToCache({ cache, createdAuthenticator });
    },
  });
  const [createBasicAuthenticator] = useCreateBasicAuthenticatorClientMutation({
    update(cache, { data }) {
      const createdAuthenticator = data?.createAuthenticatorBasic?.authenticator;
      addAuthenticatorToCache({ cache, createdAuthenticator });
    },
  });
  const [createBearerAuthenticator] = useCreateBearerAuthenticatorClientMutation({
    update(cache, { data }) {
      const createdAuthenticator = data?.createAuthenticatorBearer?.authenticator;
      addAuthenticatorToCache({ cache, createdAuthenticator });
    },
  });

  const handleAuthFormSubmit = async ({
    type,
    name,
    authorizationFlow,
    clientId,
    clientSecret,
    password,
    username,
    token,
    urlToken,
    scope,
  }: AuthenticatorFormValues) => {
    let response;
    if (type === AuthenticatorTypeEnum.Basic) {
      response = await createBasicAuthenticator({
        variables: {
          input: {
            name,
            username,
            password,
          },
        },
      });
    }
    if (type === AuthenticatorTypeEnum.Bearer) {
      response = await createBearerAuthenticator({
        variables: {
          input: {
            name,
            token,
          },
        },
      });
    }
    if (type === AuthenticatorTypeEnum.Oauth2) {
      response = await createOAuth2Authenticator({
        variables: {
          input: {
            name,
            authorizationFlow,
            clientId,
            clientSecret,
            urlToken,
            scope,
          },
        },
      });
    }
    if (response) {
      if (response.errors === undefined) {
        successToast(t("modals.authenticators.create.toast.success.title"));
      } else {
        errorToast(t("modals.authenticators.create.toast.error.title"))({
          message: t("pages.settings.sections.authenticators.save_error"),
        });
      }
    }
  };

  return (
    <Flex justifyContent={"end"} w={"full"}>
      <AddButton onClick={onOpen}>
        {t("pages.settings.sections.authenticators.connection")}
      </AddButton>
      <AuthenticatorForm isOpen={isOpen} onClose={onClose} onSubmit={handleAuthFormSubmit} />
    </Flex>
  );
}

export default NewAuthenticatorModal;
