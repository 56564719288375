import { HStack, Text, useDisclosure } from "@chakra-ui/react";
import RemoveButton from "@core/components/UI/atoms/Buttons/RemoveButton";
import GenericConfirmationModal from "@core/components/UI/organisms/modals/GenericConfirmationModal/GenericConfirmationModal";
import ImagePreviewModal from "@core/components/UI/organisms/NoPreviewUploadedView/components/ImagePreviewModal/ImagePreviewModal";
import { FlowStepVariableTypeEnum } from "@core/enums";
import { ArticleIcon, CameraIcon } from "@core/theme/icons";
import { TEXT_STYLES } from "@core/theme/text";
import { useTranslation } from "react-i18next";

interface NoPreviewUploadedViewProps {
  onDeleteFile?: (index: number) => void;
  onDownloadFile?: (index: number, fileName: string) => void;
  type: string;
  fileUrl?: string;
  fileName: string;
  readOnly?: boolean;
}

export function NoPreviewUploadedView({
  onDeleteFile,
  onDownloadFile,
  type,
  fileUrl,
  fileName,
  readOnly,
}: Readonly<NoPreviewUploadedViewProps>) {
  const { t } = useTranslation();

  const {
    isOpen: isDeleteFileConfirmOpen,
    onOpen: onDeleteFileConfirmOpen,
    onClose: onDeleteFileConfirmClose,
  } = useDisclosure();

  const {
    isOpen: isImagePreviewOpen,
    onOpen: onImagePreviewOpen,
    onClose: onImagePreviewClose,
  } = useDisclosure();

  const onComponentClicked = () => {
    if (type === FlowStepVariableTypeEnum.IMAGE) {
      onImagePreviewOpen();
    } else {
      onDownloadFile?.(0, fileName);
    }
  };

  return (
    <HStack
      w="full"
      overflow="hidden"
      alignItems="center"
      borderWidth={1}
      borderColor="tako.input.border"
      borderRadius={4}
      py={1}
      px={2}
      backgroundColor="white"
      color={"tako.input.border"}
      _hover={{
        backgroundColor: "#F5EFFD",
      }}
      cursor="pointer"
      data-group
      onClick={onComponentClicked}
    >
      {type === FlowStepVariableTypeEnum.IMAGE ? <CameraIcon /> : <ArticleIcon />}
      <Text
        flexGrow={1}
        textStyle={TEXT_STYLES.lead14Normal}
        display="block"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        title={fileName}
      >
        {fileName}
      </Text>
      {onDeleteFile && !readOnly && (
        <>
          <RemoveButton
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
              onDeleteFileConfirmOpen();
            }}
          />
          <GenericConfirmationModal
            onAction={() => {
              onDeleteFile(0);
            }}
            title={t(`pages.projects.modals.delete_file.title`)}
            actionText={t("global.actions.delete")}
            isOpen={isDeleteFileConfirmOpen}
            onClose={onDeleteFileConfirmClose}
            actionColor="red"
          >
            <Text>{t("pages.projects.modals.delete_file.body")}</Text>
          </GenericConfirmationModal>
        </>
      )}
      <ImagePreviewModal
        images={fileUrl ? [fileUrl] : []}
        isOpen={isImagePreviewOpen}
        onClose={onImagePreviewClose}
        onDownloadFile={() => onDownloadFile?.(0, fileName)}
      />
    </HStack>
  );
}
