import type { VariableCategoryType } from "@flows-platform/generated/graphql";
import type { VariableType } from "@flows-platform/types";
import { t } from "i18next";
import { v4 as uuidV4, validate } from "uuid";

export const PROJECT_DEFAULT_CATEGORY_NAME =
  "pages.flows_templates.drawer.context_drawer_setup.default_categories.project_information";
export const FLOW_DEFAULT_CATEGORY_NAME =
  "pages.flows_templates.drawer.context_drawer_setup.default_categories.trigger_variables";

export const getDefaultCategory = (isProject: boolean) => ({
  id: uuidV4(),
  name: isProject ? t(PROJECT_DEFAULT_CATEGORY_NAME) : t(FLOW_DEFAULT_CATEGORY_NAME),
  position: 0,
});

export const getCategoriesFromVariables = (
  initialVariables: VariableType[],
  defaultCategory: VariableCategoryType,
  variableCategories?: VariableCategoryType[]
) => {
  if (variableCategories?.length) return variableCategories;

  const variableCategoriesFromVariables = new Set(
    initialVariables.map((variable) => variable.category).filter(Boolean)
  );

  // Use categories from variables only when available and there are no stored variable categories
  if (variableCategoriesFromVariables.size > 0) {
    // Create categories from non-UUID category names if they exist
    const nonUuidCategories = Array.from(variableCategoriesFromVariables)
      .filter((category) => category && !validate(category))
      .map((categoryName, position) => ({
        id: uuidV4(),
        name: categoryName!,
        position,
      }));
    if (nonUuidCategories.length) return nonUuidCategories;
  }
  return [defaultCategory];
};
