import { Link, Text } from "@chakra-ui/react";

import { PROJECT_META_DATA_LINE_HEIGHT } from "../../../../../../constants";
import isLink from "../../../utils/isLink";

interface TextJourneyVariableProps {
  value: string | undefined;
}
const TextJourneyVariable = ({ value }: TextJourneyVariableProps) => {
  if (!value) return null;

  if (isLink(value))
    return (
      <Link
        href={value || ""}
        target="_blank"
        rel="noreferrer"
        noOfLines={1}
        maxWidth={{ base: 150, sm: 350 }}
        fontSize="md"
        textColor="gray.700"
        fontWeight={400}
        lineHeight={PROJECT_META_DATA_LINE_HEIGHT}
      >
        {value}
      </Link>
    );

  return (
    <Text
      fontSize="md"
      fontWeight={400}
      flex="1"
      w={"full"}
      lineHeight={PROJECT_META_DATA_LINE_HEIGHT}
    >
      {value}
    </Text>
  );
};

export default TextJourneyVariable;
