import { VStack } from "@chakra-ui/react";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import ReceiverAssignment from "@kwest_fe/core/src/components/ReceiverAssignment/ReceiverAssignment";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import { EMAIL_SUBJECT_EXCLUDED_VARIABLE_TYPES } from "@kwest_fe/core/src/constants/email";
import { useTranslation } from "react-i18next";

import EmailEditorFields from "./EmailEditorFields";

interface EmailEditorProps {
  useSubjectField?: boolean;
  isSlackMessage?: boolean;
}

function EmailEditor({ useSubjectField = true, isSlackMessage = false }: EmailEditorProps) {
  const { t } = useTranslation();
  const { addRoleVariable, recipients, variableStore } = useFlowEditorDrawer() || {};

  if (!recipients || !addRoleVariable) return <></>;

  return (
    <VStack align="stretch" spacing={7}>
      {isSlackMessage && (
        <ReceiverAssignment
          parent="message"
          roleRecipients={recipients}
          onRoleCreated={addRoleVariable}
          showSlackRecipients
        />
      )}
      {!isSlackMessage && <EmailEditorFields />}
      {useSubjectField && (
        <FormikField
          name="message.subject"
          label={t("forms.subject.label")}
          renderElement={() => (
            <RichTextEditor
              name="message.subject"
              isSingleLine
              hideToolbar
              variables={variableStore}
              excludedVariableTypes={EMAIL_SUBJECT_EXCLUDED_VARIABLE_TYPES}
            />
          )}
        />
      )}
      <RichTextEditor name="message.content" variables={variableStore} allowAttachments />
    </VStack>
  );
}

export default EmailEditor;
