import type { BoxProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import { useMyWorkspaceQuery } from "@flows-platform/generated/graphql";
import { useMemo } from "react";
import {
  Channel,
  MessageInput,
  MessageList,
  Thread,
  useChatContext,
  Window,
} from "stream-chat-react";

import EmptyChatPlaceholder from "./EmptyChatPlaceholder";

interface IChatWindowProps extends BoxProps {
  step: { name: string; id: string };
}
const ChatWindow = ({ step, ...props }: IChatWindowProps) => {
  const { data: myWorkspace } = useMyWorkspaceQuery();

  const { client } = useChatContext();

  const channel = useMemo(() => {
    const streamChannel = client.channel("messaging", step.id, {
      name: step.name,
      team: myWorkspace?.myWorkspace?.id,
    });
    return streamChannel;
  }, [step.id]);

  return (
    <Box {...props}>
      <Channel channel={channel} EmptyStateIndicator={EmptyChatPlaceholder}>
        <Window>
          <MessageList />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Box>
  );
};

export default ChatWindow;
