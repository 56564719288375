import UploadIconButton from "@core/components/UI/atoms/Buttons/UploadIconButton";
import { AttachmentIcon } from "@core/theme/icons";

interface AttachmentButtonProps {
  onFilesSelected: (fileList: File[]) => void;
}

const AttachmentButton = ({ onFilesSelected }: AttachmentButtonProps) => {
  return (
    <UploadIconButton
      aria-label="upload-file"
      icon={<AttachmentIcon />}
      variant="ghost"
      onChange={(evt) => {
        onFilesSelected(Array.from(evt.target.files || []));
      }}
    />
  );
};

export default AttachmentButton;
