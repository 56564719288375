import {
  flowStepTypePairs,
  flowStepTypes,
} from "../components/FlowEditor/components/FlowStep/constants/constants";
import type { PickableStep } from "../components/FlowEditor/components/FlowStep/constants/types";

export default function getPickableConfig(step: PickableStep, index: number, baseDragConfig: any) {
  const { stepType: pickableType, id } = step;
  const stepType = flowStepTypePairs[pickableType];
  const itemName = flowStepTypes[stepType]?.defaultName || "";
  return {
    item: () => ({
      stepType,
      name: itemName,
      id,
      index,
      fromLibrary: Object.keys(flowStepTypePairs).includes(pickableType),
    }),
    collect: (monitor: any) => ({
      isDragPlaceholder: monitor.getItem()
        ? id === monitor.getItem()?.id && monitor.getItem()?.fromLibrary
        : false,
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    type: pickableType,
    ...baseDragConfig,
  };
}
