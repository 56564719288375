import { Button } from "@chakra-ui/react";
import AddButton from "@core/components/UI/atoms/Buttons/AddButton";
import { useReceiverAssignment } from "@core/context/ReceiverAssignment";
import type {
  AllMembershipsQuery,
  AllSlackChannelsQuery,
  AllTeamsQuery,
} from "@core/generated/graphql";
import { ReceiverTypesEnum } from "@core/modules/Core/constants/enums";
import { useEffect, useMemo } from "react";

import type { FormikSelectFieldSelectOption } from "../../../FormikSelectField/FormikSelectField";
import { ReceiverTypes } from "../../constants/constants";

interface ReceiverDisplayProps {
  roleRecipients: FormikSelectFieldSelectOption[];
  slackChannels: AllSlackChannelsQuery["allSlackChannels"];
  teams: AllTeamsQuery["allTeams"];
  workspaceMembers: AllMembershipsQuery["allMemberships"];
}

export default function ReceiverDisplay({
  roleRecipients,
  workspaceMembers,
  slackChannels,
  teams,
}: ReceiverDisplayProps) {
  const {
    updateFormState,
    state,
    resolvedReceiverType,
    setSelectedExternalSystem,
    selectedExternalSystem,
  } = useReceiverAssignment();
  const selectedRoleRecipient = useMemo(
    () => roleRecipients.find((recipient) => recipient.value === state.receiverRole),
    [state.receiverRole, roleRecipients]
  );

  const selectedReceiver = useMemo(
    () => workspaceMembers?.find((member) => member?.id === state.receiverId),
    [workspaceMembers, state.receiverId]
  );

  const selectedChannel = useMemo(
    () => slackChannels?.find((channel) => channel?.id === state.slackChannelId),
    [slackChannels, state.slackChannelId]
  );

  const selectedTeam = useMemo(
    () => teams?.find((team) => team?.id === state.receiverTeamId),
    [teams, state.receiverTeamId]
  );

  const [resolvedReceiverTypeIcon, resolvedReceiverTypeLabel] = [
    resolvedReceiverType && ReceiverTypes[resolvedReceiverType]?.icon,
    resolvedReceiverType && ReceiverTypes[resolvedReceiverType]?.label,
  ];

  const recipient = selectedRoleRecipient ?? selectedReceiver ?? selectedChannel ?? selectedTeam;

  const showReceiver = recipient ?? resolvedReceiverType === ReceiverTypesEnum.initiator;

  const recipientLabel = useMemo(() => {
    if (selectedRoleRecipient) return selectedRoleRecipient?.label;
    if (selectedTeam) return selectedTeam.name;
    if (selectedReceiver) return `${selectedReceiver?.firstName} ${selectedReceiver?.lastName}`;
    if (selectedChannel) return `${selectedChannel?.name}`;
    return undefined;
  }, [selectedRoleRecipient, selectedTeam, selectedReceiver, selectedChannel]);

  useEffect(() => {
    if (!selectedExternalSystem) {
      if (selectedTeam) {
        setSelectedExternalSystem(selectedTeam.externalSystem?.id);
      }
      if (selectedReceiver) {
        setSelectedExternalSystem(selectedReceiver.externalSystem?.id);
      }
    }
  }, [selectedTeam, selectedReceiver, selectedExternalSystem]);

  return (
    <>
      {resolvedReceiverTypeLabel && resolvedReceiverTypeIcon && showReceiver ? (
        <>
          <Button
            size="sm"
            leftIcon={resolvedReceiverTypeIcon}
            borderRightRadius={0}
            fontWeight="medium"
          >
            {resolvedReceiverTypeLabel}
          </Button>
          {recipient && (
            <Button
              size="sm"
              onClick={() => {
                updateFormState({
                  receiverType: resolvedReceiverType,
                });
              }}
              borderRadius={0}
              fontWeight="medium"
              ml={1}
            >
              {recipientLabel}
            </Button>
          )}
        </>
      ) : (
        <AddButton>Add Assignee</AddButton>
      )}
    </>
  );
}
