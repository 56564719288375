import { useApolloClient } from "@apollo/client";
import {
  Divider,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import type { ProjectPhaseFieldsFragment } from "@flows-platform/generated/graphql";
import {
  AllPhasesSettingsDocument,
  useUpdateJourneyPhaseMutation,
} from "@flows-platform/generated/graphql";
import useWorkspaceProjectPhases from "@flows-platform/modules/Shared/hooks/useWorkspaceProjectPhases";
import ProjectPhaseLabel from "@flows-platform/modules/Shared/UI/atoms/ProjectPhaseLabel/ProjectPhaseLabel";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface ProjectPaseSelectorProps {
  phase?: ProjectPhaseFieldsFragment;
}

export default function ProjectPhaseSelector({ phase }: ProjectPaseSelectorProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const { errorToast } = useDefaultToasts();
  const { projectPhases } = useWorkspaceProjectPhases();
  const client = useApolloClient();

  const [updateJourney] = useUpdateJourneyPhaseMutation({
    onError() {
      client.refetchQueries({
        include: [AllPhasesSettingsDocument],
      });
      onOpen();
      errorToast(t(`pages.project_instance.toast.update_project_phase.error.title`));
    },
  });

  const updatePhase = (phaseId: string, phaseName: string) => {
    if (projectId)
      updateJourney({
        variables: {
          journeyId: projectId,
          phaseId,
        },
        optimisticResponse: {
          updateJourney: {
            journey: {
              id: projectId,
              phase: {
                id: phaseId,
                name: phaseName,
                __typename: "ProjectPhaseType",
              },
              __typename: "JourneyType",
            },
            __typename: "UpdateJourneyMutation",
          },
        },
      });
  };

  return (
    <Menu
      onClose={onClose}
      isOpen={isOpen}
      preventOverflow
      computePositionOnMount
      placement="bottom"
    >
      <MenuButton onClick={onOpen}>
        <ProjectPhaseLabel phase={phase} />
      </MenuButton>
      <MenuList>
        <Heading fontSize="md" pb={2} px={3}>
          {t("pages.project_instance.phases.select.title")}
        </Heading>
        <Divider />

        {projectPhases?.map((oneProjectPhase) => {
          const isPhaseActive = phase?.id === oneProjectPhase.id;
          return (
            <MenuItem
              _focus={{ bg: "blue.50" }}
              closeOnSelect={false}
              isDisabled={isPhaseActive}
              key={oneProjectPhase?.id}
              onClick={() => {
                if (!isPhaseActive) {
                  updatePhase(oneProjectPhase.id, oneProjectPhase.name);
                }
              }}
            >
              <ProjectPhaseLabel phase={oneProjectPhase} />
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
