import {
  HStack,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import type {
  DeleteFlowClientMutationFn,
  DuplicateFlowClientMutationFn,
  SetFlowCurrentEditorClientMutationFn,
} from "@flows-platform/generated/graphql";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import ProjectAssignmentModal from "@flows-platform/modules/ProjectTemplates/components/ProjectsOverviewTable/components/ProjectAssignmentModal";
import StartInstanceButton from "@flows-platform/modules/Shared/components/StartInstanceButton";
import { FlowAssignmentVariablesProvider } from "@flows-platform/modules/Shared/providers/FlowAssignmentVariables";
import TruncatedText from "@flows-platform/modules/Shared/UI/atoms/Text/TruncatedText/TruncatedText";
import CurrentEditorModal from "@flows-platform/modules/Shared/UI/organisms/modals/CurrentEditorModal/CurrentEditorModal";
import toastSettings from "@flows-platform/theme/settings";
import type { AllMyAdminFlows, CurrentEditorType } from "@flows-platform/types";
import ContextMenu from "@kwest_fe/core/src/components/UI/organisms/ContextMenu";
import GenericDeleteModal from "@kwest_fe/core/src/components/UI/organisms/modals/GenericDeleteModal";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ProjectsOverviewTableProps {
  flows: AllMyAdminFlows;
  deleteFlow: DeleteFlowClientMutationFn;
  duplicateFlow: DuplicateFlowClientMutationFn;
  setFlowCurrentEditor: SetFlowCurrentEditorClientMutationFn;
}

export default function ProjectsOverviewTable({
  flows,
  deleteFlow,
  duplicateFlow,
  setFlowCurrentEditor,
}: ProjectsOverviewTableProps) {
  const {
    isOpen: isOpenDeleteFlow,
    onOpen: onOpenDeleteFlow,
    onClose: onCloseDeleteFlow,
  } = useDisclosure();
  const {
    isOpen: isOpenCurrentEditor,
    onOpen: onOpenCurrentEditor,
    onClose: onCloseCurrentEditor,
  } = useDisclosure();
  const [activeFlow, setActiveFlow] = useState<string | null>(null);
  const [assigningFlow, setAssigningFlow] = useState(false);
  const [currentEditorForSelectedFlow, setCurrentEditorForSelectedFlow] =
    useState<CurrentEditorType>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { myMembership } = useMyMembership();
  const { errorToast } = useDefaultToasts();

  const { t } = useTranslation();

  const selectedFlow = useMemo(() => {
    if (!activeFlow) return null;
    return flows?.find((flow) => flow?.id === activeFlow);
  }, [activeFlow, flows]);

  const setCurrentEditor = async (id: string, resetCurrentEditor = false) =>
    setFlowCurrentEditor({
      variables: {
        input: {
          id,
          ...(resetCurrentEditor && { resetCurrentEditor: true }),
        },
      },
    })
      .then(() => {
        navigate(`/project-templates/${id}`);
      })
      .catch(errorToast(t("pages.project_templates.toast.project_save.error.title")));

  const startProject = useCallback((id: string) => {
    setActiveFlow(id);
    setAssigningFlow(true);
  }, []);

  const handleDuplicateFlow = (id: string) => {
    duplicateFlow({ variables: { flowId: id } })
      .then(({ data }) =>
        toast({
          ...toastSettings,
          status: "success",
          title: t("pages.project_templates.toast.project_duplicate.success.title"),
          description: t("pages.project_templates.toast.project_duplicate.success.description", {
            name: data?.duplicateFlow?.newFlow?.name,
          }),
        })
      )
      .catch((error) =>
        toast({
          ...toastSettings,
          status: "error",
          title: t("pages.project_templates.toast.project_duplicate.error.title"),
          description: error.message,
        })
      );
  };

  const handleDeleteFlow = (id: string) => {
    deleteFlow({ variables: { flowId: id } })
      .then(() => {
        onCloseDeleteFlow();
        toast({
          ...toastSettings,
          status: "success",
          title: t("pages.project_templates.toast.project_delete.success.title"),
        });
      })
      .catch((error) =>
        toast({
          ...toastSettings,
          status: "error",
          title: t("pages.project_templates.toast.project_delete.error.title"),
          description: error.message,
        })
      );
  };

  const rows = flows?.map((flow) => {
    if (!flow) return null;
    const { id, name, isDraft, currentEditor, editorAssignTime, canModifyFlow } = flow;
    return (
      <Tr
        key={id}
        fontSize="md"
        data-testid="project-template-row"
        cursor={canModifyFlow ? "pointer" : undefined}
        sx={{ _hover: canModifyFlow ? { bg: "gray.100" } : {} }}
        onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
          if ((e.target as HTMLElement).nodeName !== "BUTTON") {
            if (!canModifyFlow) return;

            /**
             * Assign currentEditor and open flow
             */
            if (!currentEditor) {
              setCurrentEditor(id);
              return;
            }

            /**
             * If the current user themself is currently editing.
             * E.g. they have multiple tabs open
             */
            const TWO_HOURS = 2 * 60 * 60 * 1000;
            const differenceInMs = new Date().getTime() - new Date(editorAssignTime).getTime();

            /**
             * If the current user is the editor,
             * or the flow started being edited more than two
             * hours ago, reset editor to the current user.
             */
            if (currentEditor.id === myMembership?.id || differenceInMs > TWO_HOURS) {
              setCurrentEditor(id, true);
              return;
            }

            /**
             * Someone is currently editing the flow
             */
            setCurrentEditorForSelectedFlow(currentEditor);
            onOpenCurrentEditor();
          }
        }}
      >
        <Td colSpan={2}>
          <TruncatedText>{name}</TruncatedText>
        </Td>
        <Td px={2}>{isDraft && <Tag>{t("pages.flows_templates.status.draft")}</Tag>}</Td>
        <Td px={2}>{currentEditor?.workspaceEmail}</Td>
        <Td px={2}>
          <HStack spacing={2} justifyContent={"end"}>
            <StartInstanceButton
              isDisabled={!!isDraft}
              isLoading={id === activeFlow && assigningFlow}
              allowScheduling={false}
              onClickStart={() => {
                startProject(id);
              }}
              onClickSchedule={(e) => {
                e.stopPropagation();
                startProject(id);
              }}
            />
            <ContextMenu
              onDuplicate={() => {
                handleDuplicateFlow(id);
              }}
              onRemove={() => {
                onOpenDeleteFlow();
                setActiveFlow(id);
              }}
            />
          </HStack>
        </Td>
      </Tr>
    );
  });

  return (
    <>
      <TableContainer>
        <Table variant="simple">
          <Thead mb={4}>
            <Tr>
              <Th>{t("pages.project_templates.table.headers.project_name")}</Th>
              <Th colSpan={4} />
            </Tr>
          </Thead>

          <Tbody>{rows}</Tbody>
        </Table>
      </TableContainer>
      {selectedFlow && (
        <FlowAssignmentVariablesProvider flowId={selectedFlow.id}>
          <ProjectAssignmentModal
            key={selectedFlow.id}
            projectTemplateId={selectedFlow.id}
            isOpen={assigningFlow}
            autoStartFlow
            onClose={() => {
              setAssigningFlow(false);
            }}
          />
        </FlowAssignmentVariablesProvider>
      )}
      <GenericDeleteModal
        header="project template"
        isOpen={isOpenDeleteFlow}
        onClose={onCloseDeleteFlow}
        onConfirm={() => {
          handleDeleteFlow(activeFlow || "");
        }}
      />

      <CurrentEditorModal
        currentEditor={currentEditorForSelectedFlow}
        isOpen={isOpenCurrentEditor}
        onClose={() => {
          setCurrentEditorForSelectedFlow(null);
          onCloseCurrentEditor();
        }}
      />
    </>
  );
}
