import type { AxiosError, AxiosProgressEvent } from "axios";
import axios from "axios";

export interface FormFilesServiceOptions {
  uploadUrl?: string;
  accessToken: Promise<string> | string;
  onFileUploadError?: (err: AxiosError, files: File[]) => Promise<void> | void;
  // TODO: Correctly type this callback function. It is not clear what type we should assign for data below as it is used in only one case
  onFileUploadedSuccess?: ({ data, files }: { data: any[]; files: File[] }) => void;
  onFileUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  onFileDeletedSuccess?: (fileUrl: string) => void;
  onFileDeletedError?: (err: AxiosError, deleteFileUrl: string, fileUrl: string) => void;
}

const FormFiles = ({
  uploadUrl,
  accessToken,
  onFileUploadProgress = () => {},
  onFileUploadedSuccess,
  onFileUploadError,
  onFileDeletedSuccess,
  onFileDeletedError,
}: FormFilesServiceOptions) => {
  const getDefaultHeaders = async () => ({
    "Content-Type": "application/json",
    ...(accessToken
      ? {
          Authorization: `Bearer ${
            typeof accessToken === "string" ? accessToken : await accessToken
          }`,
        }
      : {}),
  });

  const deleteFile = async (deleteUrl: string, fileUrl: string) => {
    return axios
      .delete(deleteUrl, {
        headers: await getDefaultHeaders(),
      })
      .then(() => {
        if (onFileDeletedSuccess) onFileDeletedSuccess(fileUrl);
      })
      .catch((err) => onFileDeletedError?.(err, deleteUrl, fileUrl));
  };

  const downloadFile = async (fileUrl: string) =>
    axios.get(fileUrl, {
      responseType: "arraybuffer",
      headers: await getDefaultHeaders(),
    });

  const uploadFile = async (files: File[], options?: FormFilesServiceOptions) => {
    if (!uploadUrl) return Promise.reject(new Error("No upload url specified"));
    const responses = [];
    try {
      for (const file of files) {
        const res = await axios.postForm(
          uploadUrl,
          {
            file,
          },
          {
            headers: await getDefaultHeaders(),
            onUploadProgress: onFileUploadProgress,
          }
        );
        if (!res || res.status !== 200) {
          throw new Error(res?.statusText || "Unable to upload file");
        } else {
          responses.push(res);
        }
      }
      const responseData = responses.map((response) => response.data);
      if (options?.onFileUploadedSuccess) {
        options.onFileUploadedSuccess({ data: responseData, files });
      } else if (onFileUploadedSuccess) {
        onFileUploadedSuccess({ data: responseData, files });
      }
      return responses;
    } catch (err) {
      onFileUploadError?.(err, files.splice(responses.length));
      return responses;
    }
  };

  return {
    uploadFile,
    deleteFile,
    downloadFile,
  };
};

export default FormFiles;
