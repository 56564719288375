import { Tag, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { ArchiveIcon, PauseIcon } from "@core/theme/icons";
import { t } from "i18next";
import { useMemo } from "react";

interface BadgeProps {
  icon: "archived" | "paused";
}

const Badge = ({ icon }: BadgeProps) => {
  const selectedIcon = useMemo(() => {
    switch (icon) {
      case "archived":
        return ArchiveIcon;
      case "paused":
        return PauseIcon;
      default:
        return PauseIcon;
    }
  }, [icon]);

  return (
    <Tag size="md" bg="gray.400" color="white">
      <TagLeftIcon as={selectedIcon} size="xs" />
      <TagLabel ml={1}>{t(`pages.project_instance.states.${icon}`)}</TagLabel>
    </Tag>
  );
};

export default Badge;
