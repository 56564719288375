import { useAuth0 } from "@auth0/auth0-react";
import {
  filterOptionsByPath,
  generateMultiPickerOptionsFromVariables,
  getActiveOptionsFromPath,
} from "@core/components/RichTextEditor/components/MultiPicker/utils/utils";
import { INITIAL_TEXT_EDITOR_VALUE } from "@core/components/RichTextEditor/constants";
import { deserialize, serialize } from "@core/components/RichTextEditor/transformers";
import withImages from "@core/components/RichTextEditor/withImages";
import withMentions from "@core/components/RichTextEditor/withMentions";
import config from "@core/config";
import slateActions from "@core/utils/slateActions";
import { useField } from "formik";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import type { Descendant } from "slate";
import { createEditor, Editor, Range, Transforms } from "slate";
import { withHistory } from "slate-history";
import type { ReactEditor } from "slate-react";
import { withReact } from "slate-react";

import richTextEditorReducer from "../utils/richTextEditorReducer";

function useRichTextEditor({
  name,
  variables,
  onChange,
  emailFieldsOnly = false,
  excludedVariableTypes = [],
}: {
  name: string;
  variables: Record<string, any>;
  onChange?: (value: any) => void;
  emailFieldsOnly?: boolean;
  excludedVariableTypes?: string[];
}) {
  const [state, dispatch] = useReducer(richTextEditorReducer, {
    target: null,
    index: -1,
    search: "",
    initialValue: null,
  });
  const [selected, setSelected] = useState<string[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  const [{ value }, , { setValue }] = useField(name);

  /* Multi-picker parameters */
  const multiSelectVariableOptions = generateMultiPickerOptionsFromVariables(
    variables,
    emailFieldsOnly,
    excludedVariableTypes
  );
  const filteredPlaceholderVariables = filterOptionsByPath(
    state.search,
    selected,
    multiSelectVariableOptions
  );
  const activeOptions = getActiveOptionsFromPath(selected, filteredPlaceholderVariables);

  const deserializedContent = useMemo(() => {
    if (!value) return null;
    const document = new DOMParser().parseFromString(value, "text/html");

    // Deserialize only if the value is valid HTML
    if (Array.from(document.body.childNodes).some((node) => node.nodeType === 1))
      return deserialize(document.body) as Descendant[];

    // Put all the content in a single paragraph if not valid HTML
    return [
      {
        type: "paragraph",
        children: [{ text: value }],
      },
    ];
  }, [value]);

  const { editor, uploadAndRenderFile } = useMemo(
    () =>
      withImages(withMentions(withHistory(withReact(createEditor() as ReactEditor))), {
        uploadUrl: `${config.backend.uri}/api/stored/files`,
        accessToken: getAccessTokenSilently(),
      }),
    [getAccessTokenSilently]
  );

  const {
    isMarkActive,
    toggleMark,
    isBlockActive,
    toggleBlock,
    insertPlaceholder,
    findPlaceholderNode,
  } = slateActions(editor);

  const updateFieldValue = useCallback(() => {
    const serializedEditorValue = serialize(editor);
    setValue(serializedEditorValue);
    if (onChange) onChange(serializedEditorValue);
  }, [editor, onChange, setValue]);

  const onVariableSelected = useCallback(() => {
    const { target } = state;
    if (!target || !selected.length) return;
    const blockBeforeCurrentTarget =
      selected.length > 1 && Editor.before(editor, target, { unit: "block" });
    const blockBeforeCurrentTargetRange =
      blockBeforeCurrentTarget && Editor.range(editor, blockBeforeCurrentTarget, target);
    const [matchedNode] = findPlaceholderNode(blockBeforeCurrentTargetRange || undefined);
    const resolvedTarget = (matchedNode && blockBeforeCurrentTargetRange) || target;
    insertPlaceholder(selected, resolvedTarget);
  }, [editor, insertPlaceholder, state, selected]);

  const handleEditorContentChange = useCallback(() => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      /**
       * We are checking to see if the the word at the cursor starts with an $ character
       */
      const [start] = Range.edges(selection);
      const wordBefore = Editor.before(editor, start, { unit: "word" });
      const beforeRange = wordBefore && Editor.range(editor, wordBefore, start);
      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      /* Enable the variable popover if text is preceeded by a $ sign */
      const beforeMatch = beforeText && /^\$(\w*)$/.exec(beforeText.trimStart());

      const after = Editor.after(editor, start);
      const afterRange = Editor.range(editor, start, after);
      const afterText = Editor.string(editor, afterRange);
      const afterMatch = /^(\s|$)/.exec(afterText);

      /* Check if there is a placeholder block before the cursor */
      const placeHolderBeforeMatch = selected.length && (!beforeText || /^(\w*)$/.exec(beforeText));

      /* Only enable the popover when there's a variable with more options before */
      if (placeHolderBeforeMatch) {
        if (activeOptions) {
          dispatch({
            type: "update-text-editor-state",
            payload: { target: afterRange, search: beforeText || "", index: 0 },
          });
          return;
        }
        if (after)
          Transforms.select(editor, {
            anchor: after,
            focus: after,
          });
      }

      if (beforeMatch && afterMatch) {
        dispatch({
          type: "update-text-editor-state",
          payload: { target: beforeRange, search: beforeMatch ? beforeMatch[1] : "", index: 0 },
        });
        return;
      }
    }
    dispatch({ type: "clear-target" });
    setSelected([]);
    updateFieldValue();
  }, [updateFieldValue, editor, activeOptions, selected]);

  const handleEditableOnKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      const { target, index } = state;
      if (target && activeOptions) {
        const nextIndex = index >= activeOptions.length - 1 ? 0 : index + 1;
        const prevIndex = index <= 0 ? activeOptions.length - 1 : index - 1;
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            dispatch({ type: "update-text-editor-state", payload: { index: nextIndex } });
            break;
          case "ArrowUp":
            event.preventDefault();
            dispatch({ type: "update-text-editor-state", payload: { index: prevIndex } });
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();
            setSelected((prevState) => [...prevState, activeOptions[index].value]);
            break;
          case "Escape":
            event.preventDefault();
            dispatch({ type: "clear-target" });
            break;
          default:
            break;
        }
      }
    },
    [state, activeOptions]
  );

  const undefinedVariables = useMemo(() => {
    if (!value) return new Set();
    const variableMatcher = /\{\{\s*(\w+|\w+\.\w+)\s*\}\}/g;
    const trackedVariables = Array.from(
      value.matchAll(variableMatcher),
      (m: RegExpMatchArray) => m[1]
    );
    const availableVariables = new Set(Object.keys(variables));
    return new Set(
      trackedVariables.filter((variable) => !availableVariables.has(variable.split(".")[0]))
    );
  }, [value, variables]);

  useEffect(() => {
    if (!deserializedContent) return;
    if (!state.initialValue) {
      dispatch({
        type: "update-text-editor-state",
        payload: { initialValue: deserializedContent },
      });
      if (editor.children === INITIAL_TEXT_EDITOR_VALUE) editor.children = deserializedContent;
    }
  }, [editor, state.initialValue, deserializedContent]);

  useEffect(() => {
    if (selected.length) onVariableSelected();
  }, [selected]);

  return {
    editor,
    index: state.index,
    target: state.target,
    isMarkActive,
    toggleMark,
    isBlockActive,
    toggleBlock,
    filteredPlaceholderVariables,
    handleEditorContentChange,
    handleEditableOnKeyDown,
    onVariableSelected,
    undefinedVariables,
    selected,
    setSelected,
    onImageSelected: uploadAndRenderFile,
  };
}

export default useRichTextEditor;
