import type { DrawerProps } from "@chakra-ui/react";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Text,
} from "@chakra-ui/react";
import type { VariableCategoryType } from "@flows-platform/generated/graphql";
import type { VariableType } from "@flows-platform/types";
import {
  getCategoriesFromVariables,
  getDefaultCategory,
} from "@flows-platform/utils/getCategoriesFromVariables";
import makeIdentifier from "@flows-platform/utils/makeIdentifier";
import pickFields from "@flows-platform/utils/pickFields";
import type { FormikHelpers, FormikProps } from "formik";
import { Formik } from "formik";
import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { MdPlayArrow } from "react-icons/md";

import FlowTriggerDrawerForm from "./components/FlowTriggerDrawerForm/FlowTriggerDrawerForm";
import FlowTriggerDrawerSchema from "./constants/schema";

interface FlowTriggerDrawerFormInitialValues {
  variables: (VariableType & { category: string })[];
  variableCategories: VariableCategoryType[];
}

interface FlowTriggerDrawerProps {
  flowId: string;
  isProject: boolean;
  initialVariables: Record<string, VariableType>;
  onSave: (variablesJSONString: string, updatedVariableCategories?: string) => void;
  variableCategories?: VariableCategoryType[];
}

function FlowTriggerDrawer({
  flowId,
  isProject,
  initialVariables,
  onSave,
  variableCategories,
  ...drawerProps
}: FlowTriggerDrawerProps & Omit<DrawerProps, "children">) {
  const { onClose } = drawerProps;
  const { t } = useTranslation();

  // Variable categories to be used in the drawer
  const computedVariableCategories = getCategoriesFromVariables(
    Object.values(initialVariables),
    getDefaultCategory(isProject),
    variableCategories
  );

  const computedVariableCategoryIds = new Set(
    computedVariableCategories.map((category) => category.id)
  );

  const initialVars = useMemo(
    () =>
      Object.values(initialVariables)
        // Flow variables with invalid categories are removed
        .map(({ category, ...restVariable }) => ({
          ...restVariable,
          category: category && computedVariableCategoryIds.has(category) ? category : undefined,
        }))
        // Flow variables are put in the default category
        .map((variable) => ({
          ...variable,
          category: (isProject && variable.category) || computedVariableCategories[0].id,
        })),
    [initialVariables, computedVariableCategoryIds]
  );

  const saveVariables = useCallback(
    (
      values: FlowTriggerDrawerFormInitialValues,
      { setSubmitting }: FormikHelpers<FlowTriggerDrawerFormInitialValues>
    ) => {
      try {
        setSubmitting(true);
        const { variables, variableCategories: updatedVariableCategories } = values;
        const variablesWithIdentifiers = [...variables].reduce<Record<string, VariableType>>(
          (acc, { label, type, in_trigger, category }) => ({
            ...acc,
            [makeIdentifier(label)]: {
              label,
              type,
              in_trigger,
              category: isProject ? category : undefined,
            },
          }),
          {}
        );
        onSave(
          JSON.stringify(variablesWithIdentifiers),
          isProject
            ? JSON.stringify(
                updatedVariableCategories.map((category) =>
                  pickFields(category, ["id", "name", "position"])
                )
              )
            : undefined
        );
      } finally {
        setSubmitting(false);
      }
    },
    [onSave]
  );

  const formRef = useRef<FormikProps<any>>(null);

  const onDrawerClosed = useCallback(() => {
    formRef.current?.submitForm();
    onClose();
  }, [formRef, onClose]);

  return (
    <Drawer {...drawerProps} size="lg" placement="right" onClose={onDrawerClosed}>
      <DrawerOverlay />
      <Formik
        innerRef={formRef}
        initialValues={{
          variables: initialVars,
          variableCategories: computedVariableCategories,
        }}
        validationSchema={FlowTriggerDrawerSchema}
        onSubmit={saveVariables}
        enableReinitialize
        validateOnChange
      >
        {({ isValid, isSubmitting, dirty, submitForm, values: { variables } }) => (
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">
              <HStack spacing={3} justifyContent="space-between">
                <HStack>
                  <Icon as={MdPlayArrow} boxSize={5} />
                  <Text fontSize="md">Trigger</Text>
                </HStack>
                <HStack>
                  <Button disabled={false} variant="outline" onClick={onClose}>
                    {t("global.actions.cancel")}
                  </Button>
                  <Button
                    onClick={submitForm}
                    disabled={!(isValid && dirty) || isSubmitting}
                    isLoading={isSubmitting}
                    loadingText="Saving"
                    colorScheme="purple"
                    data-testid="context-drawer-trigger-save-button"
                  >
                    {t("global.actions.save")}
                  </Button>
                </HStack>
              </HStack>
            </DrawerHeader>
            <DrawerBody p={0}>
              <FlowTriggerDrawerForm flowId={flowId} variables={variables} isProject={isProject} />
            </DrawerBody>
          </DrawerContent>
        )}
      </Formik>
    </Drawer>
  );
}

export default FlowTriggerDrawer;
