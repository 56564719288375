import { IconButton, Input } from "@chakra-ui/react";
import { CameraIcon } from "@core/theme/icons";
import { useRef } from "react";

interface ImageUploadButtonProps {
  onFilesSelected: (fileList: File[]) => void;
}

function ImageUploadButton({ onFilesSelected }: ImageUploadButtonProps) {
  const imageButtonRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Input
        ref={imageButtonRef}
        type="file"
        display="none"
        onChange={(evt) => {
          onFilesSelected(Array.from(evt.target.files || []));
        }}
      />
      <IconButton
        aria-label="upload-image"
        icon={<CameraIcon />}
        variant="ghost"
        onClick={(event) => {
          event.preventDefault();
          imageButtonRef.current?.click();
        }}
      />
    </>
  );
}

export default ImageUploadButton;
