const TodoListEnum = {
  JourneyStep: "journey-step",
  FlowStep: "flow-step",
};

const MessageChannelEnum = {
  Email: "EMAIL",
  PersonalEmail: "PERSONAL_EMAIL",
  SlackMessage: "SLACK_MESSAGE",
};

enum FeatureFlags {
  HttpRequest = "HTTP_REQUEST",
  DocumentCreation = "DOCUMENT_CREATION",
  RemoteDataConnectionField = "REMOTE_DATA_CONNECTION_FIELD",
  MembershipFormItem = "MEMBERSHIP_FORM_ITEM",
  ConnectedApps = "CONNECTED_APPS",
  Approval = "APPROVAL",
  InstallionExtrasEnabled = "INSTALLION",
}

const EmailRecipientsEnum = {
  LEARNER: "Employee",
  MANAGER: "Manager",
};

const FormItemTypeEnum = {
  DescriptionText: "DESCRIPTION_TEXT",
  ShortText: "SHORT_TEXT",
  LongText: "LONG_TEXT",
  ImageUpload: "IMAGE_UPLOAD",
  Number: "NUMBER",
  Option: "OPTION",
  Date: "DATE",
};

const ReceiverTypesEnum = {
  role: "role",
  initiator: "initiator",
  member: "member",
  slack: "slack",
  team: "team",
};

export {
  TodoListEnum,
  MessageChannelEnum,
  FeatureFlags,
  EmailRecipientsEnum,
  FormItemTypeEnum,
  ReceiverTypesEnum,
};
