/* eslint-disable no-console */
import { ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { captureException } from "@sentry/react";
import type { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

import { getClient } from "./config/client";

interface CustomApolloProviderProps {
  graphQLEndpoint: string;
  accessToken: string;
}

export default function CustomApolloProvider({
  children,
  graphQLEndpoint,
  accessToken,
}: PropsWithChildren<CustomApolloProviderProps>) {
  const navigate = useNavigate();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      if (Array.isArray(graphQLErrors)) {
        graphQLErrors.forEach((error) => {
          const { message, locations, path } = error;
          captureException(
            new Error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          );

          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
        });

        if (graphQLErrors.some(({ message }) => message === "Authentication required")) {
          navigate("/logout");
        }
      } else {
        captureException(graphQLErrors);
        console.log(`[GraphQL error]: ${graphQLErrors}`);
      }
    }
    if (networkError) {
      captureException(networkError);
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
      },
    };
  });

  const client = getClient({ authLink, errorLink, graphQLEndpoint });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
