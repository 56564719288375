import { Box } from "@chakra-ui/react";
import { FlowStepTypeEnum } from "@flows-platform/generated/graphql";
import HttpRequestResourceFlowStepContainer from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/components/FlowStepTemplateRenderer/components/HttpRequestResourceFlowStepContainer/HttpRequestResourceContainer";
import TodoListEditorContainer from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/components/FlowStepTemplateRenderer/components/TodoListEditorContainer/TodoListEditorContainer";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";

import LoadingListSkeleton from "../../../../../Shared/components/LoadingListSkeleton";
import { flowEditorDrawerSpacing } from "../../../FlowEditor/components/FlowStep/constants/constants";
import ApprovalEditorContainer from "./components/ApprovalEditorContainer/ApprovalEditorContainer";
import DocumentEditorContainer from "./components/DocumentEditorContainer/DocumentEditorContainer";
import EmailEditorFlowStepContainer from "./components/EmailEditorFlowStepContainer/EmailEditorFlowStepContainer";
import FormContainer from "./components/FormContainer/FormContainer";
import TaskContainer from "./components/TaskContainer/TaskContainer";

interface FlowStepTemplateRendererProps {
  loading: boolean;
}

function FlowStepTemplateRenderer({ loading }: FlowStepTemplateRendererProps) {
  const { initialFormValuesLoaded, flowStep } = useFlowEditorDrawer() || {};

  const loadedStatus = (initialFormValuesLoaded || false) && !loading;

  const templates = {
    [FlowStepTypeEnum.Slack]: (
      <Box {...flowEditorDrawerSpacing}>
        <EmailEditorFlowStepContainer enableSlack initialFormValuesLoaded={loadedStatus} />
      </Box>
    ),
    [FlowStepTypeEnum.Email]: (
      <Box {...flowEditorDrawerSpacing}>
        <EmailEditorFlowStepContainer initialFormValuesLoaded={loadedStatus} />
      </Box>
    ),
    [FlowStepTypeEnum.List]: (
      <Box {...flowEditorDrawerSpacing}>
        <TodoListEditorContainer initialFormValuesLoaded={loadedStatus} />
      </Box>
    ),
    [FlowStepTypeEnum.Form]: <FormContainer initialFormValuesLoaded={loadedStatus} />,
    [FlowStepTypeEnum.HttpRequest]: (
      <Box {...flowEditorDrawerSpacing}>
        <HttpRequestResourceFlowStepContainer initialFormValuesLoaded={loadedStatus} />
      </Box>
    ),
    [FlowStepTypeEnum.Task]: (
      <Box {...flowEditorDrawerSpacing}>
        <TaskContainer initialFormValuesLoaded={loadedStatus} data-testid="task-container" />
      </Box>
    ),
    [FlowStepTypeEnum.Document]: (
      <Box {...flowEditorDrawerSpacing}>
        <DocumentEditorContainer initialFormValuesLoaded={loadedStatus} />
      </Box>
    ),
    [FlowStepTypeEnum.Approval]: (
      <Box {...flowEditorDrawerSpacing}>
        <ApprovalEditorContainer initialFormValuesLoaded={loadedStatus} />
      </Box>
    ),
    [FlowStepTypeEnum.Trigger]: <div />,
  };

  if (loading) {
    return (
      <Box {...flowEditorDrawerSpacing}>
        <LoadingListSkeleton />
      </Box>
    );
  }

  return flowStep?.stepType ? templates[flowStep.stepType] : null;
}

export default FlowStepTemplateRenderer;
