import { HStack, Text } from "@chakra-ui/react";
import { MdOutlineFileUpload } from "react-icons/md";

import type { FileUploaderCaptionProps } from "../FileUploader/constants/types";

function FileUploadCaption({ isDragActive }: FileUploaderCaptionProps) {
  return (
    <HStack justifyContent="center" color="gray.600">
      <MdOutlineFileUpload />
      {isDragActive ? (
        <Text fontWeight="bold">Drop files here</Text>
      ) : (
        <Text fontWeight="bold">File Upload</Text>
      )}
    </HStack>
  );
}

export default FileUploadCaption;
