import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  HStack,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import type {
  AllWorkspacesQuery,
  ChangeWorkspaceClientMutationFn,
  MyMembershipFieldsFragment,
  MyMembershipQuery,
  MyWorkspaceQuery,
  TeamType,
} from "@flows-platform/generated/graphql";
import { MembershipPermissionLevel } from "@flows-platform/generated/graphql";
import { KWEST_EXTERNAL_SYSTEM_ID } from "@kwest_fe/core/src/context/ReceiverAssignment";
import ToggleSidebar from "@kwest_fe/core/src/components/UI/layout/PageHeader/ToggleSidebar";
import { useTranslation } from "react-i18next";

import type { IRoute } from "../../constants/settings";
import { SIDE_NAVIGATION_ROUTES } from "../../constants/settings";
import SideNavigationHeader from "./components/SideNavigationHeader/SideNavigationHeader";
import GlobalSearch from "./GlobalSearch/GlobalSearch";
import RouteComponent from "./RouteComponent";
import TruncatedText from "@flows-platform/modules/Shared/UI/atoms/Text/TruncatedText";

interface SideNavigationProps {
  onChange: () => void;
  isLoading: boolean;
  workspaceData: MyWorkspaceQuery["myWorkspace"];
  myMembership: MyMembershipQuery["myMembership"];
  allWorkspaces: AllWorkspacesQuery["allWorkspaces"];
  changeWorkspace: ChangeWorkspaceClientMutationFn;
}

export default function SideNavigation({
  onChange,
  isLoading,
  workspaceData,
  myMembership,
  allWorkspaces,
  changeWorkspace,
}: SideNavigationProps) {
  const { t } = useTranslation();
  const switchWorkspace = (workspaceId: string) => {
    changeWorkspace({
      variables: {
        workspaceId,
      },
      onCompleted(res) {
        if (res.changeWorkspace?.ok) {
          window.location.reload();
        }
      },
    });
  };

  function getNavigationTabs(RoutesArr: IRoute[], permissionLevel?: MembershipPermissionLevel) {
    if (!permissionLevel) return null;

    let routes = [...RoutesArr];

    if (permissionLevel === MembershipPermissionLevel.Guest)
      routes = routes.filter((route) => route.access === "GUEST");

    return routes.map((route) => (
      <Box
        w={"100%"}
        key={route.name}
        data-testid={`side-navigation-${route.key}`}
        onClick={() => {
          onChange?.();
        }}
      >
        <RouteComponent {...route} membership={myMembership} />
      </Box>
    ));
  }

  const teamNavItems = (teams?: MyMembershipFieldsFragment["teams"]) =>
    teams?.map((team) => {
      if (!team) return null;
      return (
        <RouteComponent
          name={team.name}
          key={team.id}
          route={`/team/${team.id}/inbox`}
          icon={<span />}
          access={MembershipPermissionLevel.Guest}
          membership={myMembership}
        />
      );
    });

  const teamsByExternalSystem =
    myMembership?.teams?.reduce(
      (acc, team) => {
        const externalSystemId = team?.externalSystem?.id || KWEST_EXTERNAL_SYSTEM_ID;
        const externalSystemName = team?.externalSystem?.name || "Kwest";
        return {
          ...acc,
          [externalSystemId]: {
            teams: [...(acc[externalSystemId]?.teams || []), team],
            name: externalSystemName,
          },
        };
      },
      {} as Record<string, { teams: MyMembershipFieldsFragment["teams"]; name: string }>
    ) || {};

  return (
    <VStack justifyContent="space-between" h="100vh" py={6} w="100%" data-testid="side-navigation">
      <VStack w="full" spacing={0} alignItems="flex-start">
        <Skeleton w="full" isLoaded={!isLoading} mb={6}>
          <HStack alignContent={"center"} pb={3} mx={4}>
            <SideNavigationHeader
              workspaceName={workspaceData?.name || "Unknown"}
              allWorkspaces={allWorkspaces}
              switchWorkspace={switchWorkspace}
            />
            <ToggleSidebar onClick={onChange} side="left" />
          </HStack>
        </Skeleton>
        <GlobalSearch />
        <VStack spacing={0} py={4} pb={3} w="full" alignItems="flex-start">
          <Box w={"100%"}>
            <Box
              display="inline-flex"
              pl={4}
              alignItems="center"
              w="100%"
              pb={1}
              pt={1}
              color={"gray.600"}
            >
              <Box ml={2} fontSize="14px" fontWeight={500}>
                {t("pages.inbox.title")}
              </Box>
            </Box>

            <VStack gap={1} pl={8} alignItems="stretch">
              <RouteComponent
                name="pages.inbox.forMe"
                key="inbox"
                route="/todos"
                icon={<span />}
                access={MembershipPermissionLevel.Guest}
                membership={myMembership}
              />
              {Object.keys(teamsByExternalSystem).length > 1 ? (
                <Accordion
                  allowMultiple
                  defaultIndex={Object.keys(teamsByExternalSystem).map((_, i) => i)}
                >
                  {Object.keys(teamsByExternalSystem).map((externalSystemId) => {
                    return (
                      <AccordionItem border="none" key={externalSystemId}>
                        <AccordionButton pl={6}>
                          <TruncatedText flex="1" align="left" mr={3}>
                            {teamsByExternalSystem[externalSystemId].name}
                          </TruncatedText>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel pr={0} pb={2}>
                          {teamNavItems(teamsByExternalSystem[externalSystemId].teams)}
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              ) : (
                teamNavItems(myMembership?.teams)
              )}
            </VStack>
          </Box>
        </VStack>
        <Box width={"99%"} px={2}>
          <Divider py={1} />
        </Box>
        <VStack spacing={0} py={4} w="full" alignItems="flex-start">
          {getNavigationTabs(SIDE_NAVIGATION_ROUTES.GENERAL, myMembership?.permissionLevel)}
        </VStack>
      </VStack>
    </VStack>
  );
}
