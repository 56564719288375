import { Box, FormControl, FormLabel } from "@chakra-ui/react";
import { KWEST_EXTERNAL_SYSTEM_ID } from "@core/context/ReceiverAssignment";
import { useMyWorkspaceQuery } from "@core/generated/graphql";
import Select from "react-select";

interface ExternalSystemSelectorProps {
  value: string | null;
  onChange: (selectedExternalSystem: string | null) => void;
}

export default function ExternalSystemSelector({ value, onChange }: ExternalSystemSelectorProps) {
  const { data: myWorkspace } = useMyWorkspaceQuery();
  const externalSystems = myWorkspace?.myWorkspace?.externalSystems;
  const externalSystemOptions = [
    ...(externalSystems?.map((externalSystem) => ({
      label: externalSystem.name,
      value: externalSystem.id,
    })) || []),
    { label: "Kwest", value: KWEST_EXTERNAL_SYSTEM_ID },
  ];

  return externalSystems?.length ? (
    <FormControl>
      <FormLabel>Select System</FormLabel>
      <Box maxW="80%">
        <Select
          options={externalSystemOptions}
          value={externalSystemOptions?.find((option) => option.value === value)}
          onChange={(selectedOption) => {
            onChange(selectedOption?.value);
          }}
        />
      </Box>
    </FormControl>
  ) : null;
}
