import { Button, Flex } from "@chakra-ui/react";
import { todoViewerUrl } from "@flows-platform/config/todoViewer";
import type { InboxStepFragment } from "@flows-platform/generated/graphql";
import TruncatedText from "@flows-platform/modules/Shared/UI/atoms/Text/TruncatedText/TruncatedText";
import User from "@flows-platform/modules/Shared/UI/molecules/display/User";
import { LibraryIcons } from "@flows-platform/theme/icons";
import { formatDateDifferenceFromNow } from "@flows-platform/utils/formatDate";
import { useTranslation } from "react-i18next";

interface TodoCardProps {
  journeyStep: InboxStepFragment;
  isLast: boolean;
}

const TodoCard = ({ journeyStep, isLast }: TodoCardProps) => {
  const taskLink = !journeyStep.journey.isProject
    ? todoViewerUrl(`todo/${journeyStep.id}`)
    : todoViewerUrl(`projects/${journeyStep.journey.id}?task=${journeyStep.id}`);

  const { t } = useTranslation();

  return (
    <Flex
      px={5}
      py={1}
      border={"gray.300"}
      borderTopWidth={"1px"}
      borderBottomWidth={isLast ? "1px" : undefined}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Flex direction={"column"} gap={1} w="23%" justifyContent={"center"}>
        <Flex gap={1} alignItems="center">
          {LibraryIcons[journeyStep.stepType]}
          <TruncatedText fontWeight={"medium"} fontSize={"md"}>
            {journeyStep?.name}
          </TruncatedText>
        </Flex>
        <TruncatedText fontSize={"md"} color={"gray.500"}>
          {formatDateDifferenceFromNow(journeyStep.deliveredAt)}
        </TruncatedText>
      </Flex>
      <Flex direction={"column"} gap={1} w="23%" fontSize={"md"}>
        <TruncatedText>{journeyStep.journey.flow.name}</TruncatedText>
        <TruncatedText color={"gray.500"}>{journeyStep.journey.instanceName}</TruncatedText>
      </Flex>
      <Flex direction={"column"} gap={1} w="23%" fontSize={"md"}>
        <TruncatedText>{journeyStep.projectTemplateName}</TruncatedText>
        <TruncatedText color={"gray.500"}>{journeyStep.projectInstanceName}</TruncatedText>
      </Flex>
      <Flex w="23%">
        <User
          name={`${journeyStep?.journey.initiator?.firstName} ${journeyStep.journey.initiator?.lastName}`}
        />
      </Flex>
      <Flex w="1/13">
        <Button colorScheme="purple" size="sm" onClick={() => window.open(taskLink, "_blank")}>
          {t("global.actions.do")}
        </Button>
      </Flex>
    </Flex>
  );
};

export default TodoCard;
