import "@fontsource/inconsolata/500.css";

import { Box, Text, VStack } from "@chakra-ui/react";
import type { VariableType } from "@flows-platform/types";
import makeIdentifier from "@flows-platform/utils/makeIdentifier";
import config from "@kwest_fe/core/src/config";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import { useTranslation } from "react-i18next";

import { flowEditorDrawerSpacing } from "../../../FlowEditor/components/FlowStep/constants/constants";
import ContextDrawerSetup from "./components/ContextDrawerSetup/ContextDrawerSetup";

const TRIGGER_INCLUDABLE_VARIABLES = [
  FlowStepVariableTypeEnum.STRING,
  FlowStepVariableTypeEnum.NUMBER,
  FlowStepVariableTypeEnum.ROLE,
  FlowStepVariableTypeEnum.IMAGE,
];

interface FlowTriggerDrawerFormProps {
  flowId: string;
  isProject: boolean;
  variables: VariableType[];
}

export default function FlowTriggerDrawerForm({
  flowId,
  variables,
  isProject,
}: FlowTriggerDrawerFormProps) {
  const { t } = useTranslation();
  const variableQueryString = variables
    .filter(
      (variable) => variable.in_trigger && TRIGGER_INCLUDABLE_VARIABLES.includes(variable.type)
    )
    .reduce(
      (qs, v) => (!v.label ? qs : `${qs ? `${qs}&` : "?"}${makeIdentifier(v.label)}={variable}`),
      ""
    );

  return (
    <VStack alignItems="flex-start" spacing={10} {...flowEditorDrawerSpacing} w="full">
      <Box w="80%">
        <Text fontWeight="medium">Run Link</Text>
        <Text color="gray.500" fontSize="md">
          {t("pages.flows_templates.drawer.run_link.description")}
        </Text>
      </Box>
      <Box w="80%">
        <Text fontWeight="medium">{t("pages.flows_templates.drawer.run_link.label")}</Text>
        <Box bg="gray.100" borderColor="gray.300" borderRadius="md" p={2}>
          <Text color="purple.500" fontFamily="mono">
            {config.auth0.redirectUri}/run/{flowId}
            {variableQueryString}
          </Text>
        </Box>
      </Box>
      <Box width="full">
        <ContextDrawerSetup isProject={isProject} />
      </Box>
    </VStack>
  );
}
