import { Box, Text } from "@chakra-ui/react";
import type { ProjectStepFieldsFragment } from "@flows-platform/generated/graphql";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import { useTranslation } from "react-i18next";

import SubTaskBox from "./components/SubTasksBox";

interface SubTasksViewProps {
  subTasks: ProjectStepFieldsFragment["orderedSubtasks"];
}

export default function SubTasksView({ subTasks }: SubTasksViewProps) {
  const { t } = useTranslation();
  return (
    <Box width="full">
      <Text textStyle={TEXT_STYLES.lead14SemiBold} paddingBottom={3}>
        {t("pages.project_instance.tasks.subtasks")}
      </Text>
      <Box display="flex" alignItems="flex-start" flexDirection="column" gap={5}>
        {subTasks?.map((subTask) => {
          if (!subTask) return null;
          return <SubTaskBox key={subTask.id} subTask={subTask} />;
        })}
      </Box>
    </Box>
  );
}
