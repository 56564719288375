import { Box, useDisclosure } from "@chakra-ui/react";
import type { ExtendedMembershipFieldsFragment } from "@flows-platform/generated/graphql";
import { RelationshipRoleEnum } from "@flows-platform/graphql/types/membership";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import CreateEditPersonModal from "@flows-platform/modules/Settings/components/PeopleSection/components/CreateEditPersonModal/CreateEditPersonModal";
import PeopleOverviewContainer from "@flows-platform/modules/Settings/components/PeopleSection/components/PeopleOverviewContainer/PeopleOverviewContainer";
import PeopleOverviewTable from "@flows-platform/modules/Settings/components/PeopleSection/components/PeopleOverviewTable/PeopleOverviewTable";
import SectionHeader from "@flows-platform/modules/Shared/UI/molecules/layout/SectionHeader/SectionHeader";
import useTableSort from "@flows-platform/modules/Shared/hooks/useTableSort";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function PeopleSection() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { myMembership } = useMyMembership();
  const [selectedMembershipId, setSelectedMembershipId] = useState<string | null>(null);

  const sanitizedMembershipData = (membershipData?: ExtendedMembershipFieldsFragment | null) => {
    if (!membershipData) return null;
    const { relationshipsIncoming, membershipsToMembersgroups, ...rest } = membershipData;
    const manager = relationshipsIncoming.find(
      ({ roleOf1 }) => String(roleOf1) === RelationshipRoleEnum.manager
    );
    return {
      ...rest,
      managerMembershipId: manager ? manager.contact1.id : "",
      teams: membershipData?.teams?.map((team) => team?.id as string),
    };
  };

  const { t } = useTranslation();

  const { activeSort, sortOrderIcon, toggleSort } = useTableSort("first_name");

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        width="100%"
        alignItems="center"
      >
        <SectionHeader headingText={t("pages.settings.sections.people.title")} />

        {myMembership?.isAdmin && (
          <AddButton
            onClick={() => {
              if (selectedMembershipId) setSelectedMembershipId(null);
              onOpen();
            }}
          >
            {t("pages.people.actions.invite")}
          </AddButton>
        )}
      </Box>
      <PeopleOverviewContainer
        activeSort={activeSort}
        render={({
          people,
          refetch,
          inviteUser,
          updateOtherMembership,
          fetchMoreData,
          totalRetrieved,
        }) => (
          <>
            <PeopleOverviewTable
              people={people}
              refetch={refetch}
              fetchMoreData={fetchMoreData}
              totalRetrieved={totalRetrieved}
              onEditMember={(membership: ExtendedMembershipFieldsFragment) => {
                setSelectedMembershipId(membership.id);
                onOpen();
              }}
              sortOrderIcon={sortOrderIcon}
              toggleSort={toggleSort}
            />
            <CreateEditPersonModal
              isOpen={isOpen}
              onClose={onClose}
              createAction={inviteUser}
              editAction={updateOtherMembership}
              membershipData={sanitizedMembershipData(
                people?.results.find((person) => person?.id === selectedMembershipId)
              )}
            />
          </>
        )}
      />
    </>
  );
}
