import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

interface LogoutProps {
  redirectUri: string;
}
export default function Logout({ redirectUri }: LogoutProps) {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: redirectUri });
  }, [logout]);

  return null;
}
