/**
 * This method can be use to resolve object keys (and nested keys) using a dot notation
 *
 * @param {*} obj Object to check
 * @param {*} key Path to access. Can be a direct child or a nested key specified using the dot notation. Non existent keys will result in an undefined value.
 * @returns Value at path
 *
 * e.g
 * const obj = { "foo": { "bar": 6 } }
 *
 * resolveObjectKey('foo.bar') => 6
 */
function resolveObjectKey(obj, key) {
  const path = key.split(".");
  let resolved = obj;
  for (let i = 0; i < path.length; i += 1) {
    if (!resolved) {
      break;
    }
    resolved = resolved[path[i]];
  }
  return resolved;
}

export default resolveObjectKey;
