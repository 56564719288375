import {
  ArchiveIcon,
  ContentSearchIcon,
  ProjectLeadIcon,
  TagIcon,
  TaskIcon,
  TextFieldsIcon,
} from "@kwest_fe/core/src/theme/icons";

export enum FilterOptions {
  PROJECT_LEAD = "variables__project_lead__value",
  CONTENT = "variables__varValueContains",
  CONTENT_IN_CONTEXT_SECTION = "context_section__variables__varValueContains",
  FIELD_VALUE_IN_VARIABLES = "variables__{fieldName}__value",
  FIELD_VALUE_IN_CONTEXT_SECTION = "context_section__variables__{fieldName}__value",
  ARCHIVE_STATUS = "is_archived",
  TASK = "journey_steps__name",
  TASK_STATUS = "journey_steps__status",
  TASK_COMPLETED_STATUS = "journey_steps__completed_at__isnull",
  TAGS = "tags__id",
}

export const FilterIcons = {
  [FilterOptions.PROJECT_LEAD]: <ProjectLeadIcon />,
  [FilterOptions.CONTENT]: <ContentSearchIcon />,
  [FilterOptions.CONTENT_IN_CONTEXT_SECTION]: <ContentSearchIcon />,
  [FilterOptions.FIELD_VALUE_IN_VARIABLES]: <TextFieldsIcon />,
  [FilterOptions.FIELD_VALUE_IN_CONTEXT_SECTION]: <TextFieldsIcon />,
  [FilterOptions.ARCHIVE_STATUS]: <ArchiveIcon />,
  [FilterOptions.TASK]: <TaskIcon />,
  [FilterOptions.TASK_STATUS]: <TaskIcon />,
  [FilterOptions.TASK_COMPLETED_STATUS]: <TaskIcon />,
  [FilterOptions.TAGS]: <TagIcon />,
};
