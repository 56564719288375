import * as Yup from "yup";

export default {
  task: Yup.object({
    receiverId: Yup.string().nullable(),
    receiverRole: Yup.string(),
    content: Yup.string().nullable(),
    connectedFlow: Yup.object({
      id: Yup.string(),
      name: Yup.string(),
    }).nullable(),
    connectedFlowInitialVariableValues: Yup.object(),
    orderedSubtasks: Yup.array(
      Yup.object({
        id: Yup.string().required(),
        name: Yup.string().nullable().required("A name is required for this subtask"),
        description: Yup.string().nullable(),
      })
    ),
  }).nullable(),
};
