import type { ApolloError } from "@apollo/client";
import type { AssignmentModalFlowQuery } from "@flows-platform/generated/graphql";
import { useAssignmentModalFlowQuery } from "@flows-platform/generated/graphql";
import DATE_FORMAT from "@flows-platform/modules/Core/constants/dateFormat";
import type { VariableType } from "@flows-platform/types";
import mapObjectValues from "@flows-platform/utils/mapUtils";
import type { TriggerVariableFormFieldInitialValue } from "@kwest_fe/core/src/types/TriggerVariableFormFieldInitialValue";
import parseFormInitialValue, {
  reverseParseFormInitialValue,
} from "@kwest_fe/core/src/utils/parseFormInitialValue";
import stripHtml from "@kwest_fe/core/src/utils/stripHtml";
import { format } from "date-fns";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";

const EXCLUDED_VARIABLE_TYPES: string[] = [];

const isRequiredVariable = (key: string, allVariables: Record<string, VariableType>) =>
  allVariables[key].in_trigger ?? allVariables[key].in_trigger === undefined;

interface FlowAssignmentVariablesContextInterface {
  flow: AssignmentModalFlowQuery["oneFlow"];
  triggerVariables: Record<string, VariableType>;
  flowQueryLoading: boolean;
  flowQueryLoadingError?: ApolloError;
  formInitialValues: {
    journeyStartDate: string;
    variables: Record<string, TriggerVariableFormFieldInitialValue>;
  };
  requireCustomInstanceName?: boolean;
  hasPendingVariables: boolean;
  formatFormVariables: (variables: Record<string, TriggerVariableFormFieldInitialValue>) => string;
}

export const FlowAssignmentVariablesContext =
  createContext<FlowAssignmentVariablesContextInterface | null>(null);

export const FlowAssignmentVariablesContextConsumer: React.Consumer<FlowAssignmentVariablesContextInterface> =
  FlowAssignmentVariablesContext.Consumer;

interface FlowAssignmentVariablesProviderProps {
  flowId: string;
  initialValues?: Record<string, (string | null)[] | string | null>;
}

export function FlowAssignmentVariablesProvider({
  children,
  flowId,
  initialValues,
}: PropsWithChildren<FlowAssignmentVariablesProviderProps>) {
  const {
    data,
    loading: flowQueryLoading,
    error: flowQueryLoadingError,
  } = useAssignmentModalFlowQuery({
    variables: {
      flowId,
    },
    fetchPolicy: "network-only",
  });

  const { useCustomInstanceNames, instanceName: templateInstanceName } = data?.oneFlow || {};

  const triggerVariables = useMemo<Record<string, VariableType>>(() => {
    if (!data?.oneFlow?.searchableVariables) return [];

    const allVariables: Record<string, VariableType> = JSON.parse(
      data?.oneFlow?.searchableVariables
    );

    return Object.keys(allVariables)
      .filter((key) => isRequiredVariable(key, allVariables))
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: allVariables[key],
        }),
        {}
      );
  }, [data?.oneFlow?.searchableVariables]);

  const formInitialValues = useMemo(
    () => ({
      journeyStartDate: format(new Date(), DATE_FORMAT),
      variables: mapObjectValues(
        triggerVariables,
        (key, variable) =>
          (!EXCLUDED_VARIABLE_TYPES.includes(variable.type) &&
            initialValues &&
            parseFormInitialValue(key, initialValues, variable.type)) ||
          ""
      ),
    }),
    [initialValues, triggerVariables]
  );

  const hasPendingVariables = Boolean(
    Object.values(formInitialValues.variables).filter((v) => !reverseParseFormInitialValue(v))
      .length
  );

  /**
   * This variable indicates when a custom instance name needs to be provided by the user.
   * This is only the case when no custom instance name template is provided already.
   * If a template name is provided, then we don't require anything from the user
   * as that will always be used on the backend
   */
  const requireCustomInstanceName =
    useCustomInstanceNames && !stripHtml(templateInstanceName || "");

  const formatFormVariables = (variables: Record<string, TriggerVariableFormFieldInitialValue>) =>
    JSON.stringify(
      mapObjectValues(variables, (_, variableValue) => reverseParseFormInitialValue(variableValue))
    );

  return (
    <FlowAssignmentVariablesContext.Provider
      value={{
        flow: data?.oneFlow,
        triggerVariables,
        flowQueryLoading,
        flowQueryLoadingError,
        formInitialValues,
        requireCustomInstanceName,
        hasPendingVariables,
        formatFormVariables,
      }}
    >
      {children}
    </FlowAssignmentVariablesContext.Provider>
  );
}

export function useFlowAssignmentVariables() {
  const context = useContext(FlowAssignmentVariablesContext);
  if (context === undefined) {
    throw new Error(
      "useFlowAssignmentVariables must be used within a FlowAssignmentVariablesProvider"
    );
  }
  return context!;
}
