import { useDisclosure } from "@chakra-ui/react";
import { useDeleteJourneyClientMutation } from "@flows-platform/generated/graphql";
import ContextMenu from "@kwest_fe/core/src/components/UI/organisms/ContextMenu";
import GenericDeleteModal from "@kwest_fe/core/src/components/UI/organisms/modals/GenericDeleteModal";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface FlowContextMenuProps {
  id: string;
}
const FlowContextMenu = ({ id }: FlowContextMenuProps) => {
  const { successToast, errorToast } = useDefaultToasts();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [deleteFlow, { loading: isLoading }] = useDeleteJourneyClientMutation({
    onCompleted: () => {
      successToast(t("pages.flows.toast.delete_flow.success.title"));
      navigate("/flows");
    },
    onError: (error) => {
      errorToast(error.message);
    },
  });

  return (
    <>
      <ContextMenu onRemove={onOpen} />
      <GenericDeleteModal
        header={"Flow"}
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isLoading}
        onConfirm={() => {
          deleteFlow({ variables: { journeyId: id } });
        }}
      />
    </>
  );
};

export default FlowContextMenu;
