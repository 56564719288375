import type { MaterialIconProps } from "./icon";
import { Icon } from "./icon";

export function CancelIcon({ ...props }: Pick<MaterialIconProps, "color" | "onClick" | "size">) {
  return <Icon name="cancel" {...props} />;
}

export function DropdownArrow() {
  return <Icon name="keyboard_arrow_down" />;
}

export function PowerIcon() {
  return <Icon name="power" size={"md"} />;
}

export function DuplicateIcon() {
  return <Icon name="content_copy" size="sm" />;
}

export function DeleteIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="delete" size={size} color={color} />;
}

export function RoleIcon() {
  return <Icon name="data_object" size="sm" />;
}

export function KebabIcon() {
  return <Icon name="more_horiz" size="xs" />;
}

export function CustomizeIcon() {
  return <Icon name="tune" size="sm" />;
}

export function InitiatorIcon() {
  return <Icon name="record_voice_over" size="sm" />;
}

export function MemberIcon() {
  return <Icon name="badge" size="sm" />;
}

export function TeamIcon() {
  return <Icon name="group" size="sm" />;
}

export function TagIcon() {
  return <Icon name="tag" size="sm" />;
}

export function DragIndicatorIcon() {
  return <Icon name="drag_indicator" />;
}

export function AddIcon({ size = "md" }: Omit<MaterialIconProps, "name">) {
  return <Icon name="add" size={size} />;
}

export function InboxIcon() {
  return <Icon name="center_focus_strong" size="sm" color="gray.600" />;
}

export function WarningIcon({
  size = "sm",
  color = "yellow.500",
}: Omit<MaterialIconProps, "name">) {
  return <Icon name="warning" size={size} color={color} />;
}

export function WarningIconCircle() {
  return <Icon name="error" size="sm" />;
}

export function PauseIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="pause_circle" size={size} color={color} />;
}

export function ArchiveIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="inventory_2" size={size} color={color} />;
}

export function CameraIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="photo_camera" size={size} color={color} />;
}

export function ArticleIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="article" size={size} color={color} />;
}

export function DownloadIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="download" size={size} color={color} />;
}

export function ClockIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="schedule" size={size} color={color} />;
}

export function PreviewIcon({ size, color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="visibility" size={size} color={color} />;
}

export function FlowInProjectIcon({ size = "xs", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="conversion_path" size={size} color={color} />;
}
