import { useAuth0 } from "@auth0/auth0-react";
import CustomApolloProvider from "@kwest_fe/core/src/components/apollo/CustomApolloProvider";
import { useKwestAuth } from "@kwest_fe/core/src/modules/Core/providers/KwestAuthProvider";
import type { PropsWithChildren } from "react";
import React, { useEffect } from "react";

interface Auth0WrapperProps {
  children: React.ReactNode;
  graphQLEndpoint: string;
}
const Auth0Wrapper = ({ children, graphQLEndpoint }: PropsWithChildren<Auth0WrapperProps>) => {
  const { setAccessToken, accessToken } = useKwestAuth();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      const token = await getAccessTokenSilently();
      setAccessToken(token);
    };
    if (isAuthenticated) getAccessToken();
  }, [isAuthenticated]);

  /* Ensure access token is set after authentication */
  if (!accessToken && isAuthenticated) return null;

  return (
    <CustomApolloProvider graphQLEndpoint={graphQLEndpoint} accessToken={accessToken}>
      {children}
    </CustomApolloProvider>
  );
};

export default Auth0Wrapper;
