import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import {
  FlowStepTypeEnum,
  useTodoListQuery,
  useUpdateListClientMutation,
} from "@flows-platform/generated/graphql";
import { FlowStepObserver } from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import useFormObserver from "@flows-platform/modules/Shared/hooks/useFormObserver";
import setItemPosition from "@flows-platform/utils/setItemPosition";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

import FlowStepResourceLoaderContainer from "../../../../../FlowStepResourceLoaderContainer/FlowStepResourceLoaderContainer";
import { useFlowEditorDrawer } from "../../../../providers/FlowEditorDrawerProvider";
import TodoListEditor from "./components/TodoListEditor/TodoListEditor";

interface TodoListEditorContainerProps {
  initialFormValuesLoaded: boolean;
}

function TodoListEditorContainer({ initialFormValuesLoaded }: TodoListEditorContainerProps) {
  const { updateActiveFlowStep } = useFlowEditor();
  const { flowStep } = useFlowEditorDrawer() || {};
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [updateList] = useUpdateListClientMutation({
    onCompleted(data) {
      const { flow, ...updatedFlowStep } = data?.updateList?.updatedList?.flowStep ?? {};
      updateActiveFlowStep(updatedFlowStep);
      successToast(t("pages.flow_step.toast.update_todo_list.success.title"));
    },
    onError: errorToast(t("pages.flow_step.toast.update_todo_list.error.title")),
  });

  useFormObserver(
    new FlowStepObserver({
      fields: ["todoList"],
      flowStepType: FlowStepTypeEnum.List,
      async onChanged(modifiedValues) {
        const items = setItemPosition(modifiedValues.todoList?.orderedTodoItems, true);
        /**
         * Spread changed values, and optionally set `position` property
         * on todo items
         */
        const list = {
          ...modifiedValues.todoList,
          orderedTodoItems: modifiedValues.todoList?.orderedTodoItems && items,
        };

        return updateList({
          variables: {
            input: {
              ...list,
              id: flowStep?.todoList?.id,
            },
          },
        });
      },
    })
  );

  return (
    <FlowStepResourceLoaderContainer
      query={useTodoListQuery}
      initialValuesKey="todoList"
      queryOptions={{ variables: { todoListId: flowStep?.todoList?.id } }}
      loadingErrorMessage="Error loading todo list"
      resolveInitialValue={(data) => data?.getTodoList}
      initialFormValuesLoaded={initialFormValuesLoaded}
    >
      <TodoListEditor />
    </FlowStepResourceLoaderContainer>
  );
}

export default TodoListEditorContainer;
