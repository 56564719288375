import { Box, Heading } from "@chakra-ui/react";

import ProjectLeadSelector from "./ProjectLeadSelector";

interface ProjectLeadProps {
  title?: string;
  projectLead: string;
  journeyId?: string;
  readOnly?: boolean;
}

export default function ProjectLead({ title, projectLead, journeyId, readOnly }: ProjectLeadProps) {
  return (
    <Box display="flex" flexDirection="row" width="100%" gap="20px" alignItems="center">
      <Heading fontSize="md">{title}</Heading>
      <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
        {journeyId && (
          <ProjectLeadSelector
            journeyId={journeyId}
            projectLead={projectLead}
            readOnly={readOnly}
          />
        )}
      </Box>
    </Box>
  );
}
