import { Divider, Heading, HStack, Tag, Text, VStack } from "@chakra-ui/react";
import type {
  Maybe,
  TodoViewJourneyStepFieldsFragment,
  TodoViewMembershipFieldsFragment,
} from "@todo-viewer/generated/graphql";
import { ProjectsIcon } from "@todo-viewer/theme/icons";
import { useTranslation } from "react-i18next";

interface TodoViewPageHeaderProps {
  journeyStep?: Maybe<TodoViewJourneyStepFieldsFragment>;
  receiver?: Maybe<TodoViewMembershipFieldsFragment>;
  receiverTeam?: Maybe<{
    id: string;
    name: string;
  }>;
  title?: Maybe<string>;
}

export default function TodoViewPageHeader({
  journeyStep,
  receiver,
  receiverTeam,
  title,
}: TodoViewPageHeaderProps) {
  const { t } = useTranslation();
  const getRecipient = () => {
    if (receiver) return `${receiver.firstName}_${receiver.lastName}`;
    if (receiverTeam) return receiverTeam.name;
    return t("global.recipient");
  };

  return (
    <VStack spacing={4} alignItems="flex-start" w="full">
      <Heading fontSize={"20px"} fontWeight={500}>
        {title}
      </Heading>
      {journeyStep?.projectId && (
        <HStack>
          <ProjectsIcon />
          <Text fontSize="16px" fontWeight={600}>
            {journeyStep?.projectInstanceName || journeyStep?.projectTemplateName}
          </Text>
        </HStack>
      )}
      <Divider color={"#E0DEE3"} opacity={1} />
      <Tag
        backgroundColor={"#7ed4f9"}
        color={"tako.input.text"}
        fontWeight={600}
      >{`@${getRecipient()}`}</Tag>
    </VStack>
  );
}
