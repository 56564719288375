import type { ReactEditor } from "slate-react";

import { BlockTypes } from "./constants";
import type { ExtendedElement } from "./types";

export default function withMentions<T extends ReactEditor>(editor: T) {
  const { isInline, isVoid } = editor;
  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element: ExtendedElement) =>
    element.type === BlockTypes.placeholder ? true : isInline(element);
  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element: ExtendedElement) =>
    element.type === BlockTypes.placeholder ? true : isVoid(element);

  return editor;
}
