import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useDeleteCustomTaskClientMutation } from "@flows-platform/generated/graphql";
import GenericConfirmationModal from "@kwest_fe/core/src/components/UI/organisms/modals/GenericConfirmationModal";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { DeleteIcon, KebabIcon } from "@kwest_fe/core/src/theme/icons";
import { useTranslation } from "react-i18next";

interface ProjectStepModalMenuProps {
  taskId: string;
  modalOnClose: () => void;
}

export default function ProjectStepModalMenu({ taskId, modalOnClose }: ProjectStepModalMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const { successToast, errorToast } = useDefaultToasts();
  const [deleteCustomTask] = useDeleteCustomTaskClientMutation({
    onCompleted() {
      successToast(t(`pages.project_instance.toast.delete_custom_task.success.title`));
      onClose();
      modalOnClose();
    },
    onError(e) {
      errorToast(t(`pages.project_instance.toast.delete_custom_task.error.title`))({
        message: e.message,
      });
    },
  });

  const handleDeleteCustomTask = async () =>
    deleteCustomTask({
      variables: {
        taskId,
      },
    });

  return (
    <>
      <GenericConfirmationModal
        onAction={handleDeleteCustomTask}
        title={t(`pages.project_instance.modals.delete_custom_task.title`)}
        actionText={t(`global.actions.delete`)}
        isOpen={isOpen}
        onClose={onClose}
        actionColor="purple"
      >
        <Text fontSize="md">{t(`pages.project_instance.modals.delete_custom_task.body`)}</Text>
      </GenericConfirmationModal>
      <Menu closeOnSelect={false}>
        <MenuButton as={IconButton} icon={<KebabIcon />} size="sm" />
        <MenuList>
          <MenuItem onClick={onOpen}>
            <HStack color="red.500">
              <DeleteIcon />
              <Text fontSize="md">{t(`pages.project_instance.actions.delete_custom_task`)}</Text>
            </HStack>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
