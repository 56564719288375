import { useApolloClient } from "@apollo/client";
import {
  Button,
  HStack,
  Progress,
  Tag,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import type {
  JourneyFieldsWithTodoStatusesFragment,
  JourneyParticipantType,
} from "@flows-platform/generated/graphql";
import {
  JourneyStepStatus,
  useDeleteJourneyClientMutation,
} from "@flows-platform/generated/graphql";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import ContextMenu from "@kwest_fe/core/src/components/UI/organisms/ContextMenu";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DeleteJourneyModal from "./components/DeleteJourneyModal/DeleteJourneyModal";

interface FlowsTableRowProps {
  journey: JourneyFieldsWithTodoStatusesFragment;
  isLastRow: boolean;
}

function FlowsTableRow({ journey, isLastRow }: FlowsTableRowProps) {
  const navigate = useNavigate();
  const TdBorderWidth = isLastRow ? 0 : "1px";
  const completedSteps =
    journey?.orderedJourneySteps?.filter((j) => j?.status === JourneyStepStatus.Completed).length ||
    0;
  const totalSteps =
    journey?.orderedJourneySteps?.filter((step) => step?.status !== JourneyStepStatus.Skipped)
      ?.length || 0;
  const percentageComplete = totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;

  const client = useApolloClient();
  const { myMembership } = useMyMembership();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successToast, errorToast } = useDefaultToasts();
  const [deleteJourney] = useDeleteJourneyClientMutation();
  const { t } = useTranslation();

  const onDeleteJourney = () => {
    deleteJourney({
      variables: { journeyId: journey.id },
      onCompleted: () => {
        successToast(t("pages.flows.toast.delete_journey.success.title"));
        // Refetch AllJourneysQuery
        client.refetchQueries({
          include: "active",
        });
        onClose();
      },
      onError: () => errorToast(t("pages.flows.toast.delete_journey.error.title")),
    });
  };

  const participantTag = (participant: JourneyParticipantType | null) => {
    const { person, team, role } = participant ?? {};
    let tagText = [];
    if (person || team) {
      if (team) tagText.push(`Team ${team.name}`);
      if (person) tagText.push(`${person.firstName} ${person.lastName}`);
    } else {
      tagText = [t("pages.flows.placeholders.unknown_member")];
    }

    return (
      <Tag
        key={role}
        size="md"
        colorScheme="gray"
        textTransform="capitalize"
      >{`${role}: ${tagText.join(", ")}`}</Tag>
    );
  };

  return (
    <Tr
      key={journey?.id}
      fontSize="md"
      cursor="pointer"
      sx={{ _hover: { bg: "gray.50" } }}
      onClick={(e) => {
        // Only navigate if the menu button is not the target
        if ((e.target as HTMLElement).nodeName !== "BUTTON") {
          navigate(`/flows/${journey?.id}`);
        }
      }}
      data-testid={`flows-table-row`}
    >
      <Td borderBottomWidth={TdBorderWidth} py={3}>
        <Text
          w={200}
          display="block"
          textOverflow="ellipsis"
          overflow="hidden"
          title={journey?.flow.name}
        >
          {journey?.flow.name}
        </Text>
      </Td>
      <Td borderBottomWidth={TdBorderWidth}>
        <Text>{journey?.instanceName}</Text>
      </Td>
      <Td borderBottomWidth={TdBorderWidth}>
        <VStack justifyContent="flex-start" alignItems="start">
          {journey?.participants?.map(participantTag)}
        </VStack>
      </Td>
      <Td borderBottomWidth={TdBorderWidth}>
        <HStack w="75%" spacing={4}>
          <Progress w="full" value={percentageComplete} colorScheme="green" size="lg" />
          <Button variant="link">{`${completedSteps} of ${totalSteps}`}</Button>
        </HStack>
      </Td>
      {myMembership?.isAdmin && (
        <Td>
          <ContextMenu
            onRemove={() => {
              onOpen();
            }}
          />
          <DeleteJourneyModal onDeleteJourney={onDeleteJourney} isOpen={isOpen} onClose={onClose} />
        </Td>
      )}
    </Tr>
  );
}

export default FlowsTableRow;
