import { Divider, HStack, Text, VStack } from "@chakra-ui/react";
import type { RejectedInApprovalResourceFieldsFragment } from "@todo-viewer/generated/graphql";
import { formatDateDifferenceFromNow } from "@todo-viewer/utils/formatDate";

interface TodoViewApprovalCommentProps {
  rejectedInApproval: RejectedInApprovalResourceFieldsFragment;
}

export default function TodoViewApprovalComment({
  rejectedInApproval,
}: TodoViewApprovalCommentProps) {
  const rejecter = rejectedInApproval.receiver || rejectedInApproval?.journeyStep?.completedBy;
  const rejecterName = `${rejecter?.firstName} ${rejecter?.lastName}`;
  return (
    <VStack borderWidth={1} color="gray.600" borderColor="red.500" borderRadius="md" w="full" p={3}>
      <HStack w="full">
        <Text flex={1}>
          This task has been rejected by {rejecterName}
          {rejectedInApproval.receiverRole && (
            <Text as="span" textTransform="capitalize">
              : {rejectedInApproval.receiverRole}
            </Text>
          )}
        </Text>
        {rejectedInApproval.journeyStep?.completedAt && (
          <Text fontStyle="italic" color="gray.500" fontSize="sm">
            {formatDateDifferenceFromNow(rejectedInApproval.journeyStep.completedAt)}
          </Text>
        )}
      </HStack>
      <Divider borderColor="gray.200" />
      <VStack w="full" align="flex-start">
        <Text>
          <Text fontWeight="bold" as="span">
            {rejecter?.firstName}
          </Text>{" "}
          commented:
        </Text>
        <Text fontStyle="italic">"{rejectedInApproval.rejectionComment}"</Text>
      </VStack>
    </VStack>
  );
}
