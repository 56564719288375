import { HStack, Text, Textarea, VStack } from "@chakra-ui/react";
import { FeatureFlags } from "@flows-platform/modules/Core/constants/enums";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import getFeatureFlag from "@flows-platform/utils/getFeatureFlag";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useField } from "formik";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import AuthenticatorSelector from "./components/AuthenticatorSelector/AuthenticatorSelector";
import DisabledView from "./components/DisabledView/DisabledView";
import VariableToResponseKeyField from "./components/VariableToResponseKeyField/VariableToResponseKeyField";
import type { VariableToResponseKeyType } from "./utils";
import { instantiateNewVariable } from "./utils";

export const FLOW_STEP_VARIABLES_KEY = "httpRequest.flowStepVariables";

interface VariablesViewProps {
  fieldName: string;
}

function VariablesView({ fieldName }: VariablesViewProps) {
  const [variables, , helpers] = useField<VariableToResponseKeyType[]>("httpRequest.variables");

  const handleAddVariable = () => {
    const newVariable = instantiateNewVariable();
    helpers.setValue([...variables.value, newVariable]);
  };

  if (!variables.value) {
    return null;
  }

  return (
    <VStack alignItems="flex-start" gap={6}>
      {variables.value.map((variable, i) => {
        return (
          <VariableToResponseKeyField
            fieldName={`${fieldName}.${i}`}
            key={variable.variableId}
            onRemove={() => {
              const updatedVariables = variables.value.filter((_, index) => index !== i);
              helpers.setValue(updatedVariables);
            }}
          />
        );
      })}
      <AddButton onClick={handleAddVariable}>Add Variable</AddButton>
    </VStack>
  );
}

export default function HttpRequestEditor() {
  const { myMembership } = useMyMembership();
  const httpRequestEnabled = getFeatureFlag(FeatureFlags.HttpRequest, myMembership);
  const [jsonHasError, setJsonHasError] = useState(false);

  const { t } = useTranslation();

  const parseJSON = useCallback((value: string) => {
    try {
      const jsonValue = JSON.parse(value);
      setJsonHasError(false);
      return JSON.stringify(jsonValue, null, 2);
    } catch (e) {
      setJsonHasError(true);
      return value;
    }
  }, []);

  if (!httpRequestEnabled) {
    return <DisabledView />;
  }

  return (
    <VStack spacing={9} pt={3} align="start">
      <FormikField
        name="httpRequest.url"
        label="URL Field"
        renderElement={(props) => <Textarea {...props} />}
      />
      <AuthenticatorSelector fieldName="httpRequest.authenticator" />
      <FormikField
        showOptionalIndicator
        name="httpRequest.requestBody"
        label="Request Body"
        renderElement={(props) => {
          if (!props.value) return <Textarea {...props} value={"{}"} />;
          return <Textarea {...props} value={parseJSON(props.value)} />;
        }}
        captionText={jsonHasError ? t("forms.http_request_body.hints.json_error") : undefined}
      />
      <VStack gap={6} alignItems="flex-start">
        <HStack>
          <Text textStyle={TEXT_STYLES.h2}>Returned Variables</Text>
          <Text textStyle={TEXT_STYLES.small12}>(optional)</Text>
        </HStack>
        <VariablesView fieldName="httpRequest.variables" />
      </VStack>
    </VStack>
  );
}
