import { Alert, Box, Container, HStack, Text, VStack } from "@chakra-ui/react";
import { WarningIcon } from "@kwest_fe/core/src/theme/icons";
import { useTranslation } from "react-i18next";

interface TodoViewLayoutProps {
  heading: React.ReactNode;
  caption?: string;
  children: React.ReactNode;
  noHeaderPadding?: boolean;
  isCompleted?: boolean;
}

function TodoViewLayout({
  heading,
  noHeaderPadding = false,
  children,
  caption,
  isCompleted = false,
}: TodoViewLayoutProps) {
  const { t } = useTranslation();
  return (
    <Container size="md" my={[0, 6]}>
      <Box boxShadow={["none", "md"]} px={[2, 5]} py={[4, 8]} backgroundColor="white">
        <VStack spacing={3} alignItems="flex-start" mx={noHeaderPadding ? [-2, -5] : undefined}>
          {heading}
        </VStack>

        <Box my={6}>
          <Text>{caption}</Text>
        </Box>
        {isCompleted && (
          <Alert status="warning" mb={6} borderRadius="lg" variant="custom-warning">
            <HStack alignItems="center" spacing={4}>
              <Box>
                <WarningIcon />
              </Box>
              <Text fontSize="md">{t("pages.todo_view.warnings.completed_step_message")}</Text>
            </HStack>
          </Alert>
        )}
        <VStack spacing={2}>{children}</VStack>
      </Box>
    </Container>
  );
}

export default TodoViewLayout;
