import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import {
  FlowStepTypeEnum,
  useUpdateFlowStepVariablesClientMutation,
} from "@flows-platform/generated/graphql";
import type { FlowStepInitialValues } from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import useFlowStepUpdater, {
  FlowStepObserver,
} from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import { flowStepTypes } from "@flows-platform/modules/TemplateEditor/components/FlowEditor/components/FlowStep/constants/constants";
import type { OneOrderedFlowStep, VariableType } from "@flows-platform/types";
import makeIdentifier from "@flows-platform/utils/makeIdentifier";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import generateRecipientOptions, {
  generateOptions,
} from "@kwest_fe/core/src/utils/generateOptions";
import type { PropsWithChildren } from "react";
import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface FlowEditorDrawerContextInterface {
  flowStep: OneOrderedFlowStep;
  initialFormValues: FlowStepInitialValues;
  initialFormValuesLoaded: boolean;
  setInitialFormValues: (initialFormValues: FlowStepInitialValues) => void;
  setInitialValueSlice: (key: keyof FlowStepInitialValues, initialValue: any) => void;
  setInitialFormValuesLoaded?: (loaded: boolean) => void;
  updateFlowStep: (modifiedValues: Partial<FlowStepInitialValues>) => Promise<any>;
  variableStore: Record<string, any>;
  ruleVariableOptions: Record<string, VariableType & { options?: string[] }>;
  recipients: { label: string; value: string }[];
  allPlaceholderVariables: { label: string; value: string }[];
  addRoleVariable: (label: string) => Promise<any>;
  registerFormObserver: (observer: FlowStepObserver) => void;
}

const FlowEditorDrawerContext = createContext<FlowEditorDrawerContextInterface | null>(null);

export const FlowEditorDrawerContextConsumer: React.Consumer<FlowEditorDrawerContextInterface> =
  FlowEditorDrawerContext.Consumer;

/**
 * This provider wraps the respective flow step forms and provides relevant information
 * about the active flowsteps and methods manipulate it
 */
export function FlowEditorDrawerProvider({ children }: PropsWithChildren) {
  const { successToast, errorToast } = useDefaultToasts();
  const { saveFlowStep, activeFlowStep: flowStep, updateActiveFlowStep } = useFlowEditor();
  const { t } = useTranslation();

  // Created a default observer for the top level form fields
  const [formObservers, setFormObservers] = useState<FlowStepObserver[]>([
    new FlowStepObserver({
      fields: ["name", "phaseId", "rulesChain"],
      async onChanged(modifiedValues) {
        if (!flowStep) return Promise.resolve();
        const { name, phaseId, rulesChain } = modifiedValues;
        return saveFlowStep({
          variables: {
            input: {
              id: flowStep.id,
              position: flowStep.position,
              stepType: flowStep.stepType,
              waitDuration: flowStep.waitDuration,
              name:
                (name ?? flowStep.name) ||
                t(flowStepTypes[flowStep.stepType || FlowStepTypeEnum.Task].defaultName),
              phaseId: phaseId ?? flowStep.phase?.id,
              rulesChain: rulesChain ?? flowStep.rulesChain,
            },
          },
          onCompleted(data) {
            const { flow, ...updatedFlowStep } = data?.saveFlowStep?.flowStep ?? {};
            updateActiveFlowStep(updatedFlowStep);
            successToast(t("pages.flow_step.toast.update_flow_step.success.title"));
          },
          onError: errorToast(t("pages.flow_step.toast.update_flow_step.error.title")),
        });
      },
    }),
  ]);

  const [initialFormValues, setInitialFormValues] = useState<FlowStepInitialValues>({
    name: flowStep?.name,
    phaseId: flowStep?.phase?.id,
    rulesChain: flowStep?.rulesChain,
    todoList: null,
    message: null,
    form: null,
    task: null,
    document: null,
    approval: null,
    httpRequest: null,
  });
  // TODO: apply this way of handling the response to every mutation

  const [updateFlowStepVariables] = useUpdateFlowStepVariablesClientMutation();
  const [initialFormValuesLoaded, setInitialFormValuesLoaded] = useState(false);

  const variableStore = useMemo(
    () => (flowStep?.variableStore ? JSON.parse(flowStep?.variableStore) : {}),
    [flowStep]
  );

  const ruleVariableOptions = useMemo(
    () => (flowStep?.ruleVariableOptions ? JSON.parse(flowStep?.ruleVariableOptions) : {}),
    [flowStep]
  );

  const recipients = useMemo(() => generateRecipientOptions(variableStore), [variableStore]);
  const allPlaceholderVariables = useMemo(() => generateOptions(variableStore), [variableStore]);

  /**
   * Helper to set a slice of initial values which can
   * be called by a child container.
   */
  const setInitialValueSlice = useCallback(
    (slice: string, value: any) => {
      if (!initialFormValuesLoaded) {
        setInitialFormValues({
          ...initialFormValues,
          [slice]: value,
        });
        setInitialFormValuesLoaded(true);
      }
    },
    [initialFormValues, setInitialFormValues, initialFormValuesLoaded, setInitialFormValuesLoaded]
  );

  /**
   * Adds a new role variable
   */
  const addRoleVariable = useCallback(
    async (label: string) => {
      const identifier = makeIdentifier(label);
      if (!flowStep) return null;
      const res = await updateFlowStepVariables({
        variables: {
          flowStepId: flowStep?.id,
          variables: JSON.stringify({
            [identifier]: {
              type: FlowStepVariableTypeEnum.ROLE,
              label,
              in_trigger: true,
            },
          }),
        },
      });
      updateActiveFlowStep(res.data?.updateFlowStepVariables?.flowStep);
      return identifier;
    },
    [updateFlowStepVariables, updateActiveFlowStep, flowStep]
  );

  const registerFormObserver = useCallback(
    (observer: FlowStepObserver) => {
      setFormObservers([
        observer,
        ...formObservers.filter(
          (formObserver) => formObserver.fields.toString() !== observer.fields.toString()
        ),
      ]);
    },
    [formObservers]
  );

  /** FlowEditorDrawer forms submission configuration */
  /** TODO: Split out the observers and locate them closer to their respective forms */
  const { updateFlowStep } = useFlowStepUpdater(flowStep, formObservers);

  const value = useMemo(
    () => ({
      flowStep,
      initialFormValues,
      initialFormValuesLoaded,
      setInitialFormValues,
      setInitialValueSlice,
      setInitialFormValuesLoaded,
      updateFlowStep,
      variableStore,
      ruleVariableOptions,
      recipients,
      allPlaceholderVariables,
      addRoleVariable,
      registerFormObserver,
    }),
    [
      flowStep,
      initialFormValues,
      initialFormValuesLoaded,
      setInitialFormValues,
      setInitialValueSlice,
      setInitialFormValuesLoaded,
      updateFlowStep,
      variableStore,
      ruleVariableOptions,
      recipients,
      allPlaceholderVariables,
      addRoleVariable,
      registerFormObserver,
    ]
  );

  return (
    <FlowEditorDrawerContext.Provider value={value}>{children}</FlowEditorDrawerContext.Provider>
  );
}

export function useFlowEditorDrawer() {
  const context = useContext(FlowEditorDrawerContext);
  const { t } = useTranslation();

  if (!context) {
    throw new Error(t("pages.flow_step.use_flow_error"));
  }
  return context;
}
