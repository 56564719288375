import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import type { AllWorkspacesQuery } from "@flows-platform/generated/graphql";
import Avatar from "@kwest_fe/core/src/components/UI/atoms/Avatar";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

interface SideNavigationHeaderProps {
  workspaceName: string;
  allWorkspaces: AllWorkspacesQuery["allWorkspaces"];
  switchWorkspace: (workspaceId: string) => void;
}

export default function SideNavigationHeader({
  workspaceName,
  allWorkspaces,
  switchWorkspace,
}: SideNavigationHeaderProps) {
  const { t } = useTranslation();

  return (
    <HStack w="full">
      <Box>
        <Menu>
          <MenuButton data-testid="side-navigation-header-menu-button">
            <Box display="inline-flex">
              <Avatar name={workspaceName} size="sm" borderRadius="md" color="white" />
              <Box ml={2} display="inline-flex" alignItems="center">
                <Heading size="m">{workspaceName}</Heading>
                <Box ml={1}>
                  <ChevronDownIcon />
                </Box>
              </Box>
            </Box>
          </MenuButton>
          <MenuList data-testid="side-navigation-header-menu">
            {allWorkspaces && allWorkspaces.length > 1 && (
              <MenuGroup title="Workspaces">
                {allWorkspaces.map((workspace) => (
                  <MenuItem
                    key={workspace?.id}
                    onClick={() => {
                      switchWorkspace(workspace?.id);
                    }}
                  >
                    {workspace?.name}
                  </MenuItem>
                ))}
                <MenuDivider />
              </MenuGroup>
            )}
            <MenuItem key="settings">
              <NavLink
                to="/settings"
                key="settings"
                style={{ width: "100%" }}
                data-testid="side-navigation-header-menu-item-settings"
              >
                {t("pages.settings.title")}
              </NavLink>
            </MenuItem>
            <MenuDivider />
            <MenuItem color="red.500">
              <NavLink to="/logout" key="logout" style={{ textDecoration: "none", width: "100%" }}>
                {t("global.actions.log_out")}
              </NavLink>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </HStack>
  );
}
