import {
  Box,
  Divider,
  HStack,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MdAccessTime } from "react-icons/md";

import { StackingContextEnum } from "../../../../../../../../../../utils/enums";
/**
 * Generates CSS styles for a downward pointing triangle using the options specified
 */
function triangleStyles({ size = 3, color = "gray.600" }) {
  return {
    width: 0,
    height: 0,
    borderTopWidth: `${size}px`,
    borderLeftWidth: `${size}px`,
    borderRightWidth: `${size}px`,
    borderTopColor: color,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
  };
}

interface FlowStepPeriodSelectorProps {
  periodInDays: number;
  visible: boolean;
  editing?: boolean;
  onPeriodChanged: (value: number) => void;
}

function FlowStepPeriodSelector({
  periodInDays,
  visible,
  editing = false,
  onPeriodChanged,
}: FlowStepPeriodSelectorProps) {
  const [isEditing, setIsEditing] = useState(editing);
  const inputRef = useRef<HTMLInputElement>(null);

  const toggleInput = useCallback(
    (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.stopPropagation();
      setIsEditing((prevState) => !prevState);
    },
    [setIsEditing]
  );

  const deactivateInput = useCallback(
    (eventTarget: HTMLInputElement) => {
      const { value } = eventTarget;
      setIsEditing(false);
      if (onPeriodChanged) onPeriodChanged(parseInt(value, 10));
    },
    [onPeriodChanged, setIsEditing]
  );

  useEffect(() => {
    if (isEditing && inputRef.current) {
      const inputElement = inputRef.current.querySelector("input");
      inputElement?.focus();
    }
  }, [inputRef, isEditing]);

  return (
    <Box paddingLeft={10} opacity={visible ? 1 : 0}>
      <Box
        py={4}
        display="inline-flex"
        alignItems="center"
        position="relative"
        borderLeftWidth="2px"
        borderLeftColor="gray.600"
        paddingLeft="1em"
        _before={{
          position: "absolute",
          left: "-7px",
          bottom: "0px",
          content: '""',
          ...triangleStyles({ size: 6 }),
        }}
      >
        <HStack align="center" display="inline-flex">
          <IconButton
            aria-label="Set delay"
            icon={<MdAccessTime size={17} />}
            boxSize={9}
            bg="gray.300"
            _hover={{
              backgroundColor: "gray.400",
            }}
            onClick={toggleInput}
          />
          {isEditing && (
            <Box position="relative" alignSelf="flex-start">
              <Box
                position="absolute"
                bg="white"
                top={0}
                boxShadow="md"
                zIndex={StackingContextEnum.Foreground}
                borderRadius="md"
              >
                <HStack paddingX={3} paddingY={2} spacing={2}>
                  <MdAccessTime size={17} />
                  <Text fontWeight="bold" fontSize="sm" textTransform="uppercase">
                    Schedule Delay
                  </Text>
                </HStack>
                <Divider />
                <HStack spacing={3} padding={2}>
                  <NumberInput
                    ref={inputRef}
                    step={1}
                    min={0}
                    max={30}
                    minW="80px"
                    value={periodInDays}
                    onChange={(_, numberValue) => {
                      if (onPeriodChanged && !Number.isNaN(numberValue))
                        onPeriodChanged(numberValue);
                    }}
                    onKeyDown={(evt) => {
                      if (evt.code === "Enter") {
                        deactivateInput(evt.target as HTMLInputElement);
                      }
                    }}
                    onBlur={(evt) => {
                      deactivateInput(evt.target as HTMLInputElement);
                    }}
                  >
                    <NumberInputField bg="white" />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <Text whiteSpace="nowrap">
                    weekday
                    {Math.abs(periodInDays) > 1 || periodInDays === 0 ? "s" : ""} later
                  </Text>
                </HStack>
              </Box>
            </Box>
          )}
          {!isEditing && periodInDays && (
            <Text>
              + {periodInDays} weekday{Math.abs(periodInDays) > 1 || periodInDays === 0 ? "s" : ""}{" "}
              later
            </Text>
          )}
        </HStack>
      </Box>
    </Box>
  );
}

export default FlowStepPeriodSelector;
