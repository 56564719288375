import type { BaseRange, Location, Span } from "slate";
import { Editor, Element as SlateElement, Transforms } from "slate";
import type { ReactEditor } from "slate-react";

import type { MarkTypes } from "../components/RichTextEditor/constants";
import { BlockTypes, LIST_TYPES, TEXT_ALIGN_TYPES } from "../components/RichTextEditor/constants";
import type {
  BlockFormatOperations,
  ExtendedElement,
  ListType,
  TextAlignType,
  TextProperties,
} from "../components/RichTextEditor/types";

function slateActions(editor: ReactEditor) {
  /** Return a boolean indicating whether or not a mark is active for the current selection */
  const isMarkActive = (format: keyof typeof MarkTypes) => {
    const marks = Editor.marks(editor) as TextProperties;
    return marks ? marks[format] === true : false;
  };

  /** Return a boolean indicating whether or not specific block formatting is active for the current block */
  const isBlockActive = (format: BlockFormatOperations, blockType: "align" | "type" = "type") => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes<ExtendedElement>(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n: ExtendedElement) =>
          !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
      })
    );

    return !!match;
  };

  /** Toggle a mark on or off for current editor selection */
  const toggleMark = (format: keyof typeof MarkTypes) => {
    const isActive = isMarkActive(format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  /** Toggle block formatting for the current block in the editor */
  const toggleBlock = (format: BlockFormatOperations) => {
    const isActive = isBlockActive(
      format,
      TEXT_ALIGN_TYPES.includes(format as TextAlignType) ? "align" : "type"
    );
    const isList = LIST_TYPES.includes(format as ListType);

    Transforms.unwrapNodes<ExtendedElement>(editor, {
      match: (n: ExtendedElement) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        !!n.type &&
        LIST_TYPES.includes(n.type as ListType) &&
        !TEXT_ALIGN_TYPES.includes(format as TextAlignType),
      split: true,
    });
    let newProperties;
    if (TEXT_ALIGN_TYPES.includes(format as TextAlignType)) {
      newProperties = {
        align: isActive ? undefined : (format as TextAlignType),
      };
    } else {
      newProperties = {
        // eslint-disable-next-line no-nested-ternary
        type: (isActive ? "paragraph" : isList ? "list-item" : format) as ExtendedElement["type"],
      };
    }
    Transforms.setNodes<ExtendedElement>(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const findPlaceholderNode = (nodesRange?: Location | Span | undefined) => {
    if (!nodesRange) return [];

    return Array.from(
      Editor.nodes<ExtendedElement>(editor, {
        at: nodesRange,
        mode: "highest",
        match(n: ExtendedElement) {
          return (
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === BlockTypes.placeholder
          );
        },
      })
    );
  };

  const insertPlaceholder = (placeholderVariable: string[], target: BaseRange) => {
    Transforms.insertNodes<ExtendedElement>(
      editor,
      {
        type: BlockTypes.placeholder,
        character: placeholderVariable.join("."),
        children: [{ text: "" }],
      } as ExtendedElement,
      { at: target, select: true }
    );
  };

  return {
    isBlockActive,
    isMarkActive,
    toggleMark,
    toggleBlock,
    insertPlaceholder,
    findPlaceholderNode,
  };
}

export default slateActions;
