import { Accordion, Box, Text } from "@chakra-ui/react";
import type { GetInstallionDocumentsQueryResult } from "@flows-platform/generated/graphql";

import DocumentsCategory from "./DocumentsCategory";

interface DocumentsPanelProps {
  documents: GetInstallionDocumentsQueryResult["data"];
}
const DocumentsPanel = ({ documents }: DocumentsPanelProps) => {
  const allIndexes = documents?.getInstallionJourneyDocuments?.map((_, index) => index);

  return documents?.getInstallionJourneyDocuments.length ? (
    <Accordion defaultIndex={allIndexes} allowMultiple overflowY={"auto"}>
      {documents?.getInstallionJourneyDocuments?.map((category) => (
        <DocumentsCategory category={category} key={category.title} />
      ))}
    </Accordion>
  ) : (
    <Box display={"flex"} justifyContent={"center"} py={6}>
      <Text p={2}>No Documents Found</Text>
    </Box>
  );
};

export default DocumentsPanel;
