import { todoViewerUrl } from "@flows-platform/config/todoViewer";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import type { TriggerVariableFormFieldInitialValue } from "@kwest_fe/core/src/types/TriggerVariableFormFieldInitialValue";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useActivateFlowClientMutation } from "../../../../generated/graphql";
import { useFlowAssignmentVariables } from "../../../Shared/providers/FlowAssignmentVariables";
import FlowAssignmentModalUI from "./FlowAssignmentModalUI";

interface FlowAssignmentModalProps {
  flowId: string;
  taskId?: string;
  refetchFlow?: () => void;
  autoStartFlow?: boolean;
  isOpen: boolean;
  onClose: () => void;
  isInline?: boolean;
}

function FlowAssignmentModal({
  flowId,
  taskId,
  refetchFlow,
  isOpen: modalIsOpen,
  onClose: onModalClosed,
  autoStartFlow = false,
  isInline = false,
}: FlowAssignmentModalProps) {
  const navigate = useNavigate();
  const { successToast, errorToast } = useDefaultToasts();

  const { t } = useTranslation();

  const {
    flow,
    triggerVariables,
    flowQueryLoading,
    flowQueryLoadingError,
    formInitialValues,
    hasPendingVariables,
    requireCustomInstanceName,
    formatFormVariables,
  } = useFlowAssignmentVariables();

  const [activateFlow, { loading, error }] = useActivateFlowClientMutation({
    onCompleted(res) {
      successToast(
        t("modals.flow_assignment.toast.success.title"),
        t("modals.flow_assignment.toast.success.description")
      );

      refetchFlow?.();

      const nextJourneyStep = res.activateFlow?.nextStep;
      if (!nextJourneyStep) {
        onModalClosed();
        return;
      }

      successToast(t("modals.flow_assignment.toast.next_step.title"));

      const nextTodoPageUrl = todoViewerUrl(`todo/${nextJourneyStep.id}`);
      if (isInline) {
        navigate(nextTodoPageUrl);
        return;
      }
      onModalClosed();
      window.open(nextTodoPageUrl, "_blank");
    },
    onError(err) {
      errorToast(t("modals.flow_assignment.toast.error.title"))(err);
    },
  });

  const runInstantly =
    autoStartFlow && !hasPendingVariables && !requireCustomInstanceName && !flowQueryLoadingError;

  useEffect(() => {
    if (runInstantly && modalIsOpen && !flowQueryLoading && flow && !loading && !error) {
      activateFlow({
        variables: {
          input: {
            ...formInitialValues,
            flowId,
            variables: formatFormVariables(formInitialValues.variables),
            ...(taskId ? { connectedTaskId: taskId } : {}),
          },
        },
      });
    }
  }, [
    runInstantly,
    flow,
    loading,
    error,
    autoStartFlow,
    modalIsOpen,
    formInitialValues,
    formInitialValues.variables,
    flowQueryLoading,
  ]);

  const formikFormInitialValues = useMemo(
    () => ({
      ...formInitialValues,
      requireCustomInstanceName,
      instanceName: null,
    }),
    [requireCustomInstanceName, formInitialValues]
  );

  const handleSubmit = (
    values: {
      instanceName?: string | null;
      journeyStartDate: string;
      variables: Record<string, TriggerVariableFormFieldInitialValue>;
    },
    resetForm: () => void
  ) => {
    const { variables, journeyStartDate, instanceName } = values;
    void activateFlow({
      variables: {
        input: {
          flowId,
          journeyStartDate,
          variables: formatFormVariables(variables),
          ...(instanceName ? { instanceName } : {}),
          connectedTaskId: taskId,
        },
      },
    }).then(({ errors }) => {
      if (!errors) resetForm();
    });
  };

  if (modalIsOpen && runInstantly && loading && isInline)
    return <LoadingSpinnerOverlay message="Activating flow" />;

  return (
    <FlowAssignmentModalUI
      autoStartFlow={autoStartFlow}
      isOpen={modalIsOpen && !runInstantly}
      isLoading={loading || flowQueryLoading}
      onClose={onModalClosed}
      subtitle={flow?.name}
      formInitialValues={formikFormInitialValues}
      triggerVariables={triggerVariables}
      requireCustomInstanceName={!!requireCustomInstanceName}
      onSubmit={handleSubmit}
    />
  );
}

export default FlowAssignmentModal;
