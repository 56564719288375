import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";

import TodoItemsPage from "./TodoItemsPage";

function InboxPageContainer() {
  return (
    <ErrorBoundary>
      <TodoItemsPage />
    </ErrorBoundary>
  );
}

export default InboxPageContainer;
