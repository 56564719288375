import type {
  AllGroupsQuery,
  AllMembershipsWithAdminFieldsQuery,
  InviteUserClientMutationFn,
  UpdateOtherMembershipClientMutationFn,
} from "@flows-platform/generated/graphql";
import {
  AllMembershipsWithAdminFieldsDocument,
  useAllGroupsQuery,
  useAllMembershipsWithAdminFieldsPaginatedQuery,
  useInviteUserClientMutation,
  useUpdateOtherMembershipClientMutation,
} from "@flows-platform/generated/graphql";
import LoadingListSkeleton from "@flows-platform/modules/Shared/components/LoadingListSkeleton";
import usePaginatedQuery from "@flows-platform/modules/Shared/hooks/usePaginatedQuery";
import CompositeLoader from "@kwest_fe/core/src/components/CompositeLoader";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";

import type { PeopleOverviewTableProps } from "../PeopleOverviewTable/PeopleOverviewTable";

const PEOPLE_DISPLAY_PAGE_LIMIT = 10;

interface PeopleOverviewContainerProps {
  activeSort: string;
  render: ({
    people,
    refetch,
    fetchMoreData,
    inviteUser,
    totalRetrieved,
    updateOtherMembership,
    groups,
  }: Pick<PeopleOverviewTableProps, "fetchMoreData" | "people" | "refetch" | "totalRetrieved"> & {
    inviteUser: InviteUserClientMutationFn;
    updateOtherMembership: UpdateOtherMembershipClientMutationFn;
    groups: AllGroupsQuery["allGroups"];
  }) => JSX.Element;
}

export default function PeopleOverviewContainer({
  activeSort,
  render,
}: PeopleOverviewContainerProps) {
  const allMembershipsQuery = usePaginatedQuery(
    "allMembershipsPaginated",
    useAllMembershipsWithAdminFieldsPaginatedQuery,
    {
      variables: {
        pagination: {
          limit: PEOPLE_DISPLAY_PAGE_LIMIT,
          offset: 0,
        },
        sort: activeSort,
      },
    }
  );
  const allGroups = useAllGroupsQuery();

  /**
   * This is the best practice when adding new records.
   * By using the update method we save a re-fetching of the entire
   * set of memberships.
   */
  const [inviteUser] = useInviteUserClientMutation({
    update(cache, { data }) {
      const newMembershipFromResponse = data?.inviteUser?.membership;
      const existingMemberships = cache.readQuery<AllMembershipsWithAdminFieldsQuery>({
        query: AllMembershipsWithAdminFieldsDocument,
      });

      if (existingMemberships && newMembershipFromResponse) {
        cache.writeQuery({
          query: AllMembershipsWithAdminFieldsDocument,
          data: {
            allMemberships: [
              ...(existingMemberships.allMemberships || []),
              newMembershipFromResponse,
            ],
          },
        });
      }
    },
  });

  /**
   * Update mutations that modify one item can be optimised in the mutation itself,
   * by returning the same fields as the fetch query.
   * Apollo then inserts that into the cache automatically.
   */
  const [updateOtherMembership] = useUpdateOtherMembershipClientMutation();

  const { data, refetch, error, loading, fetchMoreData, totalRetrieved } = allMembershipsQuery;
  const { allMembershipsPaginated: people } = data || {};

  if (!people?.total && !loading) {
    return (
      <Placeholder
        type="empty-state"
        header="You haven't added any people"
        description="Use the purple button to start adding people"
      />
    );
  }

  const groups = allGroups.data ? allGroups.data.allGroups : [];

  return (
    <CompositeLoader
      loading={loading}
      error={error}
      loader={<LoadingListSkeleton />}
      errorDescription="If the error persists, please contact us using the support button in the side navigation."
      errorMessage="Error loading people"
    >
      {render({
        people,
        refetch,
        fetchMoreData,
        inviteUser,
        updateOtherMembership,
        groups,
        totalRetrieved,
      })}
    </CompositeLoader>
  );
}
