import type { MaterialIconProps } from "./icon";
import { Icon } from "./icon";

export function ProjectsIcon({ size = "sm", color = "gray.600" }: Omit<MaterialIconProps, "name">) {
  return <Icon name="atr" size={size} color={color} />;
}

export function TemplateIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="auto_awesome_motion" color={color} size={size} />;
}

export function TextFieldsIcon() {
  return <Icon name="text_fields" color="gray.600" size="sm" />;
}

export function ContentSearchIcon() {
  return <Icon name="content_paste_search" color="gray.600" size="sm" />;
}

export function ProjectLeadIcon() {
  return <Icon name="person" color="gray.600" size="sm" />;
}

export function CalendarIcon({ size = "md", color = "gray.600" }: Omit<MaterialIconProps, "name">) {
  return <Icon name="event_available" color={color} size={size} />;
}

export function TaskIcon() {
  return <Icon name="center_focus_strong" color="gray.600" size="sm" />;
}

export function ChatBubbleIcon({ color = "gray.600" }: { color?: string }) {
  return <Icon name="chat_bubble" color={color} size="sm" />;
}

export function CustomTaskIcon({
  size = "sm",
  color = "gray.600",
}: Omit<MaterialIconProps, "name">) {
  return <Icon name="screenshot_region" color={color} size={size} />;
}

export function AttachmentIcon({
  size = "sm",
  color = "gray.600",
}: Omit<MaterialIconProps, "name">) {
  return <Icon name="attach_file" color={color} size={size} />;
}

export function ArrowDownwardIcon({
  size = "sm",
  color = "gray.600",
}: Omit<MaterialIconProps, "name">) {
  return <Icon name="expand_less" color={color} size={size} />;
}

export function ArrowUpwardIcon({
  size = "sm",
  color = "gray.600",
}: Omit<MaterialIconProps, "name">) {
  return <Icon name="expand_more" color={color} size={size} />;
}

export function MoveDownIcon({ size = "xs", color = "gray.600" }: Omit<MaterialIconProps, "name">) {
  return <Icon name="arrow_downward" color={color} size={size} />;
}

export function MoveUpIcon({ size = "xs", color = "gray.600" }: Omit<MaterialIconProps, "name">) {
  return <Icon name="arrow_upward" color={color} size={size} />;
}

export function SortIndicatorIcon({
  size = "sm",
  color = "gray.600",
}: Omit<MaterialIconProps, "name">) {
  return <Icon name="unfold_more" color={color} size={size} />;
}
