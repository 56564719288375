import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import SimpleLoaderWithMessage from "@kwest_fe/core/src/components/UI/molecules/display/SimpleLoaderWithMessage";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import type { TriggerVariableFormFieldInitialValue } from "@kwest_fe/core/src/types/TriggerVariableFormFieldInitialValue";
import { Formik } from "formik";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useActivateProjectClientMutation } from "../../../../../../generated/graphql";
import TriggerVariablesInput from "../../../../../FlowTemplates/components/FlowAssignmentModal/components/TriggerVariablesInput/TriggerVariablesInput";
import { useFlowAssignmentVariables } from "../../../../../Shared/providers/FlowAssignmentVariables";
import FooterButtons from "../../../../../Shared/UI/molecules/forms/FooterButtons/FooterButtons";
import ProjectAssignmentFormSchema from "./schema";

interface ProjectAssignmentModalProps {
  projectTemplateId: string;
  autoStartFlow?: boolean;
  isOpen: boolean;
  onClose: () => void;
  isInline?: boolean;
  initialValues?: Record<string, any>;
}

function ProjectAssignmentModal({
  projectTemplateId,
  isOpen: modalIsOpen,
  onClose: onModalClosed,
  autoStartFlow = false,
  isInline = false,
}: ProjectAssignmentModalProps) {
  const navigate = useNavigate();
  const { successToast, errorToast } = useDefaultToasts();

  const { t } = useTranslation();

  const {
    flow,
    triggerVariables,
    flowQueryLoading,
    flowQueryLoadingError,
    formInitialValues,
    hasPendingVariables,
    requireCustomInstanceName,
    formatFormVariables,
  } = useFlowAssignmentVariables();

  const { useCustomInstanceNames } = flow || {};

  const [activateProject, { loading, error }] = useActivateProjectClientMutation({
    onCompleted(res) {
      successToast(
        t("modals.flow_assignment.toast.success.title"),
        t("modals.flow_assignment.toast.success.description")
      );

      const projectId = res.activateFlow?.journey?.id;

      if (!projectId) {
        errorToast(t("modals.flow_assignment.toast.error.title"));
      }

      if (isInline) {
        navigate(`/projects/${projectId}`);
        return;
      }

      onModalClosed();
      navigate(`/projects/${projectId}`);
    },
    onError(err) {
      errorToast(t("modals.flow_assignment.toast.error.title"))(err);
    },
  });

  const runInstantly =
    autoStartFlow && !hasPendingVariables && !requireCustomInstanceName && !flowQueryLoadingError;

  useEffect(() => {
    if (runInstantly && modalIsOpen && !flowQueryLoading && flow && !loading && !error) {
      activateProject({
        variables: {
          input: {
            ...formInitialValues,
            flowId: projectTemplateId,
            variables: formatFormVariables(formInitialValues.variables),
          },
        },
      });
    }
  }, [
    runInstantly,
    flow,
    loading,
    error,
    autoStartFlow,
    modalIsOpen,
    formInitialValues,
    formInitialValues.variables,
    flowQueryLoading,
  ]);

  if (modalIsOpen && runInstantly && loading && isInline)
    return <LoadingSpinnerOverlay message="Activating flow" />;

  const handleSubmit = (
    values: {
      instanceName?: string | null;
      journeyStartDate: string;
      variables: Record<string, TriggerVariableFormFieldInitialValue>;
    },
    { onCompleted, onError }: { onCompleted: () => void; onError: () => void }
  ) => {
    const { variables, journeyStartDate, instanceName } = values;
    activateProject({
      variables: {
        input: {
          flowId: projectTemplateId,
          journeyStartDate,
          variables: formatFormVariables(variables),
          ...(instanceName ? { instanceName } : {}),
        },
      },
    })
      .then(({ errors }) => {
        if (!errors) {
          onCompleted();
        } else {
          onError();
        }
      })
      .catch(onError);
  };

  return (
    <Modal size="xl" isOpen={modalIsOpen && !runInstantly} onClose={onModalClosed}>
      <ModalOverlay />
      <ModalContent maxW="50em">
        <Formik
          initialValues={{
            ...formInitialValues,
            /**
             * This assignment is necessary for the form validation. Instance name is required when
             * useCustomInstanceNames is true.
             */
            useCustomInstanceNames: requireCustomInstanceName,
            instanceName: null,
          }}
          validationSchema={ProjectAssignmentFormSchema(triggerVariables)}
          onSubmit={(values, actions) => {
            handleSubmit(values, {
              onCompleted: () => {
                actions.resetForm();
                actions.setSubmitting(false);
              },
              onError: () => {
                actions.setSubmitting(false);
              },
            });
          }}
        >
          {({ submitForm, isValid, isSubmitting }) => (
            <>
              <ModalHeader>
                <VStack align="start" spacing={1}>
                  <Text>Start Project</Text>
                  <Text fontSize="sm" fontWeight="normal">
                    {flow?.name}
                  </Text>
                </VStack>
              </ModalHeader>
              <ModalCloseButton />
              {Boolean(Object.keys(triggerVariables).length) || useCustomInstanceNames ? (
                <TriggerVariablesInput
                  triggerVariables={triggerVariables}
                  requireCustomInstanceName={!!requireCustomInstanceName}
                />
              ) : (
                <ModalBody>
                  {flowQueryLoading ? (
                    <SimpleLoaderWithMessage w="full" />
                  ) : (
                    <Text fontSize="md">{t("modals.flow_assignment.start_flow_message")}</Text>
                  )}
                </ModalBody>
              )}
              <ModalFooter>
                <FooterButtons
                  isLoading={loading || isSubmitting}
                  isDisabled={!isValid || flowQueryLoading}
                  onConfirm={submitForm}
                  onCancel={onModalClosed}
                />
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
}

export default ProjectAssignmentModal;
