import { Button } from "@chakra-ui/react";
import type { FlowStepPickableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import { t } from "i18next";
import { forwardRef } from "react";

import { flowStepTypePairs, flowStepTypes } from "../../constants/constants";

interface FlowStepPickableProps {
  stepType: FlowStepPickableTypeEnum;
}

const FlowStepPickable = forwardRef<HTMLButtonElement, FlowStepPickableProps>(
  ({ stepType, ...rest }, ref) => {
    const renderType = flowStepTypePairs[stepType];

    return (
      <Button
        ref={ref}
        variant="libraryButton"
        leftIcon={flowStepTypes[renderType]?.icon}
        {...rest}
      >
        {t(flowStepTypes[renderType].label)}
      </Button>
    );
  }
);

export default FlowStepPickable;
