import { Box } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";
import type { ChannelListMessengerProps } from "stream-chat-react";

const CustomChannelList = ({ children }: PropsWithChildren<ChannelListMessengerProps>) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      bgColor={"gray.100"}
      p={2}
      rowGap={2}
      overflowY={"auto"}
    >
      {children}
    </Box>
  );
};

export default CustomChannelList;
