import type { ChakraProps, TextProps } from "@chakra-ui/react";
import { HStack, IconButton, Input, Text } from "@chakra-ui/react";
import useEditableInput from "@core/modules/Shared/hooks/useEditableInput";
import { EditIcon } from "@core/theme/icons";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import ConfirmChanges from "../UI/molecules/forms/ConfirmChanges";

interface EditableTextFieldProps extends ChakraProps {
  text: string;
  textProps?: TextProps;
  onChange: (value: number | string) => void;
}

export default function EditableTextField({
  text,
  textProps,
  onChange,
  w,
}: EditableTextFieldProps) {
  const {
    inputRef,
    isEditing,
    toggleEditingOn,
    toggleEditingOff,
    isChanged,
    handleCancel,
    handleConfirm,
    valueState,
    setValueState,
  } = useEditableInput({
    value: text,
    handleUpdate: onChange,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  return (
    <HStack data-group display="inline-flex" w={w}>
      {/* Is editing */}
      <Input
        flex={1}
        ref={inputRef}
        px={2}
        type="text"
        onChange={(e) => {
          setValueState(e.target.value);
        }}
        onBlur={toggleEditingOff}
        value={valueState}
        data-testid="editable-text-edit-input"
        hidden={!isEditing}
      />

      {/* Is not editing */}
      <HStack hidden={isEditing}>
        <Text
          {...textProps}
          onClick={toggleEditingOn}
          borderBottomWidth={1}
          borderBottomStyle="dotted"
          borderColor="transparent"
          cursor="pointer"
          _hover={{ borderColor: "gray.300" }}
          data-testid="editable-text-edit-display"
        >
          {text}
        </Text>
        <IconButton
          aria-label={t("global.actions.edit")}
          icon={<EditIcon />}
          variant="ghost"
          onClick={toggleEditingOn}
          size="sm"
          opacity={0}
          _groupHover={{ opacity: 1 }}
          data-testid="editable-text-edit-button"
        />
      </HStack>
      <ConfirmChanges
        display={isChanged}
        handleConfirm={handleConfirm}
        handleCancel={handleCancel}
        buttonSize="xs"
      />
    </HStack>
  );
}
