import { Box, HStack, Text } from "@chakra-ui/react";
import Avatar from "@core/components/UI/atoms/Avatar/Avatar";
import { useReceiverAssignment } from "@core/context/ReceiverAssignment";
import { StackingContextEnum } from "@core/enums/stackingContext";
import type { AllSlackChannelsQuery, AllTeamsQuery } from "@core/generated/graphql";
import { ReceiverTypesEnum } from "@core/modules/Core/constants/enums";
import { RoleIcon, TagIcon } from "@core/theme/icons";
import { isObjectValue } from "@core/utils/parseFormInitialValue";
import type { OptionProps } from "react-select";
import { components } from "react-select";

import type { FormikSelectFieldSelectOption } from "../../../FormikSelectField/types";
import FormikSelectField from "../../../FormikSelectField/FormikSelectField";
import MembershipSelector from "../../../MembershipSelector/MembershipSelector";
import { FloatingSelectStyles } from "../../constants/constants";

type AllSlackChannelsQueryType = AllSlackChannelsQuery["allSlackChannels"];
type OneSlackChannel = NonNullable<AllSlackChannelsQueryType>[number];

interface ReceiverSelectorProps {
  loading?: boolean;
  onReceiverRoleCreated: (identifier: string) => Promise<string>;
  roleRecipients: FormikSelectFieldSelectOption[];
  slackChannels: AllSlackChannelsQueryType;
  teams?: AllTeamsQuery["allTeams"];
  onClose: () => void;
}

function SelectSlack({
  onClose,
  slackChannels,
  loading,
}: Pick<ReceiverSelectorProps, "loading" | "onClose" | "slackChannels">) {
  const { parent, state } = useReceiverAssignment();

  const slackChannelToOption = (channel: OneSlackChannel) => ({
    label: channel?.name || "",
    value: channel?.id || "",
  });

  const filterSlackChannels = async (input: string) =>
    (slackChannels || [])
      ?.filter((channel): channel is NonNullable<OneSlackChannel> => {
        if (!input || channel?.name?.toLowerCase().includes(input.toLowerCase())) {
          return true;
        }
        return false;
      })
      .map(slackChannelToOption)
      .slice(0, 20);

  return (
    <FormikSelectField
      key={state.receiverType}
      name={`${parent}.slackChannelId`}
      label="Search channels"
      loading={loading}
      placeholder="Select channel..."
      onChange={(selectedChannel: string) => {
        if (selectedChannel) onClose();
      }}
      styles={FloatingSelectStyles}
      defaultMenuIsOpen
      onBlur={onClose}
      onSearch={filterSlackChannels}
      optionComponent={({ children, ...props }) => (
        <components.Option {...props}>
          <HStack>
            <TagIcon /> <Text>{children}</Text>
          </HStack>
        </components.Option>
      )}
    />
  );
}

function SelectRole({
  roleRecipients,
  onClose,
  onReceiverRoleCreated,
}: Pick<ReceiverSelectorProps, "onClose" | "onReceiverRoleCreated" | "roleRecipients">) {
  const { parent, state, updateFormState } = useReceiverAssignment();
  return (
    <FormikSelectField
      key={state.receiverType}
      name={`${parent}.receiverRole`}
      label="Search roles"
      placeholder="Select or create role..."
      options={roleRecipients}
      canCreate
      onCreate={async (createdLabel) =>
        onReceiverRoleCreated(createdLabel).then((identifier) => {
          updateFormState({
            receiverRole: identifier,
          });
          onClose();
        })
      }
      onChange={(selectedRole: string) => {
        if (selectedRole) onClose();
      }}
      styles={FloatingSelectStyles}
      defaultMenuIsOpen
      onBlur={onClose}
      optionComponent={({ children, ...props }) => (
        <components.Option {...props}>
          <HStack>
            <RoleIcon /> <Text>{children}</Text>
          </HStack>
        </components.Option>
      )}
    />
  );
}

function SelectMember({ onClose }: Pick<ReceiverSelectorProps, "onClose">) {
  const { parent, state, updateFormState, isSelectedExternalSystem } = useReceiverAssignment();

  return (
    <MembershipSelector
      name={`${parent}.receiverId`}
      defaultMenuIsOpen
      onChange={(selectedMember: string) => {
        if (state.receiverRole) {
          updateFormState({
            receiverRole: "",
          });
        }
        if (selectedMember) {
          onClose();
        }
      }}
      mapValue={(value) => (isObjectValue(value) ? value.id : value)}
      styles={FloatingSelectStyles}
      onBlur={onClose}
      filterMemberships={(membershipOption) =>
        isSelectedExternalSystem(membershipOption.value.externalSystem?.id)
      }
      optionComponent={({ children, ...props }: OptionProps & { data: { initials: string } }) => (
        <components.Option {...props}>
          <HStack>
            <Avatar size="md" name={props.data?.initials} />
            <Text>{children}</Text>
          </HStack>
        </components.Option>
      )}
    />
  );
}

function SelectTeam({ onClose, teams }: Pick<ReceiverSelectorProps, "onClose" | "teams">) {
  const { parent, state, updateFormState, isSelectedExternalSystem } = useReceiverAssignment();
  const filteredTeams = teams?.filter((team) => isSelectedExternalSystem(team?.externalSystem?.id));
  return (
    <FormikSelectField
      name={`${parent}.receiverTeamId`}
      onBlur={onClose}
      defaultMenuIsOpen
      options={filteredTeams?.map((team) => ({
        label: team?.name || "",
        value: team?.id || "",
      }))}
      onChange={(selectedTeam) => {
        if (state.receiverRole) {
          updateFormState({
            receiverRole: "",
          });
        }
        if (selectedTeam) {
          onClose();
        }
      }}
    />
  );
}

export default function ReceiverSelector({
  loading = false,
  onReceiverRoleCreated,
  roleRecipients,
  slackChannels,
  teams,
  onClose,
}: ReceiverSelectorProps) {
  const { state } = useReceiverAssignment();
  return (
    <Box
      minWidth={300}
      bgColor="white"
      paddingY={3}
      paddingX={3}
      zIndex={StackingContextEnum.Foreground}
    >
      {state.receiverType === ReceiverTypesEnum.slack && (
        <SelectSlack onClose={onClose} loading={loading} slackChannels={slackChannels} />
      )}
      {state.receiverType === ReceiverTypesEnum.role && (
        <SelectRole
          roleRecipients={roleRecipients}
          onClose={onClose}
          onReceiverRoleCreated={onReceiverRoleCreated}
        />
      )}
      {state.receiverType === ReceiverTypesEnum.member && <SelectMember onClose={onClose} />}
      {state.receiverType === ReceiverTypesEnum.team && (
        <SelectTeam onClose={onClose} teams={teams} />
      )}
    </Box>
  );
}
