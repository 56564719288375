import { PermissionLevelEnum } from "@flows-platform/generated/graphql";
import * as Yup from "yup";

const CreateEditPersonSchema = Yup.object().shape({
  firstName: Yup.string().required("Please provide a first name"),
  lastName: Yup.string().required("Please provide a last name"),
  workspaceEmail: Yup.string()
    .email("That's not a valid email address")
    .required("Please provide an email address"),
  privateEmail: Yup.string().email("That's not a valid email address"),
  permissionLevel: Yup.mixed().oneOf(Object.values(PermissionLevelEnum)),
  teams: Yup.array().of(Yup.string()),
  slackId: Yup.string(),
});

export default CreateEditPersonSchema;
