import type { IconButtonProps } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import type { MaterialIconProps } from "@core/theme/icons";
import { CancelIcon } from "@core/theme/icons";

interface RemoveButtonProps {
  onClick: IconButtonProps["onClick"];
  size?: MaterialIconProps["size"];
}

export default function RemoveButton({ onClick, size = "sm" }: RemoveButtonProps) {
  return (
    <IconButton
      alignSelf="flex-end"
      aria-label="Remove"
      icon={<CancelIcon size={size} />}
      variant="link"
      minWidth="auto"
      _hover={{
        color: "black",
      }}
      onClick={onClick}
    />
  );
}
