/**
 * https://stackoverflow.com/a/57272491
 *
 * @param file File to convert
 * @returns Base64 encoded string representation of the file
 */
export const toBase64 = async (file: File) =>
  new Promise<ArrayBuffer | string | null>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
