import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import { useParams } from "react-router-dom";

import { FlowEditorProvider } from "../../context/FlowEditor";
import { useOneFlowQuery, useSetFlowCurrentEditorClientMutation } from "../../generated/graphql";
import FlowEditorPage from "./TemplateEditorPage";

export default function TemplateEditorContainer() {
  const { id } = useParams();
  const oneFlowQuery = useOneFlowQuery({
    variables: {
      flowId: id!,
    },
  });

  const [setFlowCurrentEditor] = useSetFlowCurrentEditorClientMutation();

  if (oneFlowQuery.loading) return <LoadingSpinnerOverlay />;
  if (oneFlowQuery.error)
    return (
      <Placeholder type="error" header="Something went wrong while trying to load the flow data" />
    );

  if (!oneFlowQuery?.data?.oneFlow)
    return (
      <Placeholder type="empty-state" header="The flow you are trying to load does not exist" />
    );

  const { oneFlow } = oneFlowQuery.data;

  return (
    <ErrorBoundary>
      <FlowEditorProvider>
        <FlowEditorPage
          flow={oneFlow}
          setFlowCurrentEditor={setFlowCurrentEditor}
          isProject={oneFlow.isProject}
        />
      </FlowEditorProvider>
    </ErrorBoundary>
  );
}
