import type { StackProps } from "@chakra-ui/react";
import { Skeleton, Stack } from "@chakra-ui/react";

interface LoadingListSkeletonProps {
  itemHeight?: number;
  itemCount?: number;
}

function LoadingListSkeleton({
  itemHeight = 10,
  itemCount = 5,
  ...rest
}: LoadingListSkeletonProps & StackProps) {
  const items = Array(itemCount)
    .fill(0)
    .map((_, i) => i);

  return (
    <Stack w="full" className="skele" {...rest}>
      {items.map((key) => (
        <Skeleton key={key} h={itemHeight} />
      ))}
    </Stack>
  );
}

export default LoadingListSkeleton;
