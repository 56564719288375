import {
  Button,
  Checkbox,
  Divider,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import type {
  CreateMembershipInput,
  InviteUserClientMutation,
  InviteUserClientMutationFn,
  UpdateMembershipInput,
  UpdateOtherMembershipClientMutation,
  UpdateOtherMembershipClientMutationFn,
} from "@flows-platform/generated/graphql";
import { PermissionLevelEnum } from "@flows-platform/generated/graphql";
import FormikField from "@flows-platform/modules/Shared/components/FormikField";
import toastSettings from "@flows-platform/theme/settings";
import FormikSelectField from "@kwest_fe/core/src/components/FormikSelectField/FormikSelectField";
import WarningAlert from "@kwest_fe/core/src/components/UI/molecules/display/WarningAlert";
import compareObjects from "@kwest_fe/core/src/utils/compareObjects";
import initializeFormValues from "@kwest_fe/core/src/utils/initializeFormValues";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import InlineFormErrors from "../../../InlineFormErrors";
import MemberTeams from "./MemberTeams/MemberTeams";
import CreateEditPersonSchema from "./schema";
import SlackConnectionIndicator from "./SlackConnectionIndicator";
import type { SanitizedMembershipData } from "./types";

interface CreateEditPersonModalProps {
  membershipData: SanitizedMembershipData | null;
  createAction: InviteUserClientMutationFn;
  editAction: UpdateOtherMembershipClientMutationFn;
  isOpen: boolean;
  onClose: () => void;
}

function CreateEditPersonModal({
  membershipData,
  createAction,
  editAction,
  isOpen,
  onClose,
}: CreateEditPersonModalProps) {
  const toast = useToast();
  const [submissionErrors, setSubmissionErrors] = useState<string[] | null>(null);

  const { t } = useTranslation();

  const emptyFormValues = {
    firstName: "",
    lastName: "",
    workspaceEmail: "",
    privateEmail: "",
    phoneNumber: "",
    teams: [] as string[],
    isFieldWorker: false,
    permissionLevel: PermissionLevelEnum.Standard,
  };

  const initialFormValues: typeof emptyFormValues = membershipData
    ? initializeFormValues(membershipData)
    : emptyFormValues;

  const handleSubmit = (values: typeof initialFormValues) => {
    /**
     * Validate form values against the schema.
     * This is primarily to enforce transformation of empty values to null.
     * We store empty values as null in the DB, but Formik requires
     * null values to be represented as empty strings.
     */
    const cleanValues = CreateEditPersonSchema.cast(values);
    const modifiedValues = compareObjects(cleanValues, initialFormValues);
    delete modifiedValues.slackId;
    /**
     * Conditionally pass membership.id if we are modifying a membership
     */

    const editActionMessages = {
      success: {
        title: t("modals.membership.edit.toast.success.title"),
        description: "",
      },
      error: {
        title: t("modals.membership.edit.toast.error.title"),
        description: (msg: string) => msg,
      },
    };

    const createActionMessages = {
      success: {
        title: t("modals.membership.create.toast.success.title"),
        description: (name: string) => name,
      },
      error: {
        title: t("modals.membership.create.toast.error.title"),
        description: (msg: string) => msg,
      },
    };

    const submitAction = async () =>
      membershipData
        ? editAction({
            variables: {
              input: {
                id: membershipData.id,
                ...modifiedValues,
                teams: modifiedValues.teams?.map((teamId) => teamId || null),
              } as UpdateMembershipInput,
            },
          })
        : createAction({
            variables: {
              input: modifiedValues as CreateMembershipInput,
            },
          });

    submitAction()
      .then(({ data }) => {
        if (!data) return;

        const responseData = membershipData
          ? (data as UpdateOtherMembershipClientMutation).updateOtherMembership
          : (data as InviteUserClientMutation).inviteUser;

        if (!responseData) return;

        const { membership, errors } = { errors: null, ...responseData };

        if (errors) {
          setSubmissionErrors(errors);
          return;
        }

        const fullName = `${membership?.firstName} ${membership?.lastName}`;

        toast({
          ...toastSettings,
          status: "success",
          title: membershipData
            ? editActionMessages.success.title
            : createActionMessages.success.title,
          description: membershipData
            ? editActionMessages.success.description
            : createActionMessages.success.description(fullName),
        });
        onClose();
      })
      .catch((error) =>
        toast({
          ...toastSettings,
          status: "error",
          title: membershipData ? editActionMessages.error.title : createActionMessages.error.title,
          description: error.message,
        })
      );
  };

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Formik
        initialValues={initialFormValues}
        validationSchema={CreateEditPersonSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, dirty, resetForm, values, handleChange }) => {
          return (
            <ModalContent>
              <Form>
                <ModalHeader>
                  {membershipData
                    ? t("modals.membership.edit.title")
                    : t("modals.membership.create.title")}
                </ModalHeader>
                <ModalCloseButton />
                <Divider marginBottom="5" />
                <ModalBody>
                  {membershipData?.externalSystem && (
                    <WarningAlert message={t("modals.membership.edit.external_user_warning")} />
                  )}
                  <SimpleGrid columns={2} columnGap={3} rowGap={6}>
                    <GridItem colSpan={[2, 1]}>
                      <FormikField
                        name="firstName"
                        label={t("forms.first_name.label")}
                        placeholder={t("forms.first_name.placeholder")}
                      />
                    </GridItem>
                    <GridItem colSpan={[2, 1]}>
                      <FormikField
                        name="lastName"
                        label={t("forms.last_name.label")}
                        placeholder={t("forms.last_name.placeholder")}
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormikField
                        name="workspaceEmail"
                        label={
                          membershipData ? t("forms.edit_email.label") : t("forms.new_email.label")
                        }
                        type="email"
                        placeholder={t("forms.new_email.placeholder")}
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormikField
                        showOptionalIndicator
                        name="phoneNumber"
                        label={t("forms.phone_number.label")}
                        type="tel"
                        placeholder={t("forms.phone_number.placeholder")}
                        captionText={t("forms.phone_number.caption_text")}
                      />
                    </GridItem>

                    <GridItem colSpan={2}>
                      <FormikField
                        name="isFieldWorker"
                        renderElement={({ value, onChange, name }) => (
                          <Checkbox name={name} isChecked={value} onChange={onChange}>
                            <Text fontWeight="normal">{t("forms.field_worker.label")}</Text>
                          </Checkbox>
                        )}
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <FormikSelectField
                        name="permissionLevel"
                        label={
                          membershipData
                            ? t("forms.edit_permission_level.label")
                            : t("forms.new_permission_level.label")
                        }
                        placeholder={t("forms.new_permission_level.placeholder")}
                        options={(
                          Object.keys(PermissionLevelEnum) as (keyof typeof PermissionLevelEnum)[]
                        ).map((key) => ({
                          label: key,
                          value: PermissionLevelEnum[key],
                        }))}
                      />
                    </GridItem>

                    {membershipData && (
                      <>
                        <GridItem colSpan={2}>
                          <SlackConnectionIndicator membershipData={membershipData} />
                        </GridItem>
                      </>
                    )}
                    <GridItem colSpan={2}>
                      <MemberTeams teams={values.teams} onChange={handleChange} />
                    </GridItem>
                  </SimpleGrid>
                  {submissionErrors && (
                    <InlineFormErrors
                      title={t("modals.membership.toast.save.error.title")}
                      warnings={submissionErrors}
                      onToastClose={() => {
                        setSubmissionErrors(null);
                        resetForm();
                      }}
                    />
                  )}
                </ModalBody>

                <Divider marginTop="5" />
                <ModalFooter>
                  <Button mr={3} variant="ghost" onClick={onClose}>
                    {t("global.actions.cancel")}
                  </Button>
                  <Button
                    colorScheme="purple"
                    type="submit"
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    {membershipData ? t("global.actions.save") : t("global.actions.invite")}
                  </Button>
                </ModalFooter>
              </Form>
            </ModalContent>
          );
        }}
      </Formik>
    </Modal>
  );
}

export default CreateEditPersonModal;
