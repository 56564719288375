import theme from "../theme";

const StackingContextEnum = {
  Background: 1,
  Foreground: 2,
  Widgets: theme.zIndices.tooltip - 1,
  Overlay: theme.zIndices.tooltip, // 1800: Must be above the Modal which is 1400
};

export { StackingContextEnum }; // eslint-disable-line
