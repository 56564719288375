import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import EncodedImagesViewer from "@core/components/EncodedImagesViewer/EncodedImagesViewer";
import { useTranslation } from "react-i18next";

interface ImagePreviewModalProps {
  images: string[];
  isOpen: boolean;
  onClose: () => void;
  onDownloadFile: () => void;
}

export default function ImagePreviewModal({
  isOpen,
  onClose,
  images,
  onDownloadFile,
}: Readonly<ImagePreviewModalProps>) {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("modals.image_preview.title")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <EncodedImagesViewer images={images} />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            {t("global.actions.cancel")}
          </Button>
          <Button colorScheme="purple" onClick={onDownloadFile}>
            {t("global.actions.download_file")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
