import { VStack } from "@chakra-ui/react";
import type { FormikSelectFieldSelectOption } from "@kwest_fe/core/src/components/FormikSelectField/FormikSelectField";
import FormikSelectField from "@kwest_fe/core/src/components/FormikSelectField/FormikSelectField";
import ReceiverAssignment from "@kwest_fe/core/src/components/ReceiverAssignment/ReceiverAssignment";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import { useTranslation } from "react-i18next";

import { useFlowEditorDrawer } from "../../../../providers/FlowEditorDrawerProvider";

interface ApprovalEditorProps {
  flowSteps: FormikSelectFieldSelectOption[];
}

function ApprovalEditor({ flowSteps }: ApprovalEditorProps) {
  const { addRoleVariable, recipients, variableStore } = useFlowEditorDrawer() || {};
  const { t } = useTranslation();

  if (!recipients || !addRoleVariable) return <></>;

  return (
    <VStack align="stretch" spacing={10}>
      <ReceiverAssignment
        parent="approval"
        roleRecipients={recipients}
        onRoleCreated={addRoleVariable}
      />
      <RichTextEditor name="approval.content" variables={variableStore} />
      <FormikSelectField
        name="approval.returnToStepId"
        label={t("forms.return_step.label")}
        placeholder={t("forms.return_step.placeholder")}
        options={flowSteps}
      />
    </VStack>
  );
}

export default ApprovalEditor;
