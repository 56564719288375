import { Box } from "@chakra-ui/react";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import ProjectsOverviewTable from "@flows-platform/modules/ProjectTemplates/components/ProjectsOverviewTable";
import ProjectsOverviewContainer from "@flows-platform/modules/ProjectTemplates/ProjectsOverviewContainer";
import useCreateFlowMutationWithCacheUpdate from "@flows-platform/modules/Shared/hooks/useCreateFlowMutationWithCacheUpdate";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import TablePageLayout from "../Shared/UI/layout/MainLayout/components/TablePageLayout/TablePageLayout";

export default function ProjectTemplatesPage() {
  const { myMembership } = useMyMembership();
  const navigate = useNavigate();
  const { errorToast } = useDefaultToasts();
  const { createFlow } = useCreateFlowMutationWithCacheUpdate({ isProject: true });
  const { t } = useTranslation();

  return (
    <TablePageLayout
      title={t("pages.project_templates.title")}
      actions={
        myMembership?.isAdmin ? (
          <Box>
            <AddButton
              data-testid="create-project-button"
              onClick={() => {
                createFlow({
                  variables: {
                    isProject: true,
                  },
                })
                  .then(({ data }) => {
                    if (data?.createFlow?.flow)
                      navigate(`/project-templates/${data.createFlow.flow.id}`);
                  })
                  .catch(errorToast("Error creating project"));
              }}
            >
              {t("models.project.actions.new")}
            </AddButton>
          </Box>
        ) : undefined
      }
    >
      <ProjectsOverviewContainer
        render={(flows, deleteFlow, duplicateFlow, setFlowCurrentEditor) =>
          flows ? (
            <ProjectsOverviewTable
              flows={flows}
              deleteFlow={deleteFlow}
              duplicateFlow={duplicateFlow}
              setFlowCurrentEditor={setFlowCurrentEditor}
            />
          ) : null
        }
      />
    </TablePageLayout>
  );
}
