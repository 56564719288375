import { gql } from "@apollo/client";
import { EXTENDED_MEMBERSHIP_FIELDS } from "@flows-platform/graphql/fragments/membership";

const ALL_MEMBERSHIPS_WITH_ADMIN_FIELDS = gql`
  ${EXTENDED_MEMBERSHIP_FIELDS}
  query AllMembershipsWithAdminFields($search: String) {
    allMemberships(search: $search) {
      ...ExtendedMembershipFields
    }
  }
`;

export const ALL_MEMBERSHIPS_WITH_ADMIN_FIELDS_PAGINATED = gql`
  ${EXTENDED_MEMBERSHIP_FIELDS}
  query AllMembershipsWithAdminFieldsPaginated(
    $sort: String
    $filters: JSONString
    $pagination: PaginationInputType
  ) {
    allMembershipsPaginated(sort: $sort, filters: $filters, pagination: $pagination) {
      results {
        ...ExtendedMembershipFields
      }
      offset
      limit
      total
      count
      first
      more
    }
  }
`;

export default ALL_MEMBERSHIPS_WITH_ADMIN_FIELDS;
