import { Button, MenuItem, Text } from "@chakra-ui/react";

interface RemoteDataConnectionIndicatorProps {
  remoteDataEnabled: boolean;
  disconnectRemoteData: () => void;
}

export default function RemoteDataConnectionIndicator({
  remoteDataEnabled,
  disconnectRemoteData,
}: RemoteDataConnectionIndicatorProps) {
  return (
    <>
      <MenuItem as="span" _focus={{ bg: "white" }} _active={{ bg: "white" }}>
        <Text>Custom data connected</Text>
      </MenuItem>
      {remoteDataEnabled && (
        <MenuItem as="span" _focus={{ bg: "white" }} _active={{ bg: "white" }}>
          <Button size="sm" onClick={disconnectRemoteData}>
            Disconnect
          </Button>
        </MenuItem>
      )}
    </>
  );
}
