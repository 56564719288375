import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { FilterOptions } from "@flows-platform/modules/ProjectInstance/ProjectsOverview/constants/constants";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import {
  ContentSearchIcon,
  ProjectLeadIcon,
  TagIcon,
  TaskIcon,
  TextFieldsIcon,
} from "@kwest_fe/core/src/theme/icons";
import { useTranslation } from "react-i18next";

import type { Filter } from "../../types";

interface ProjectsFilterMenuHeaderProps {
  filterDisplayed: FilterOptions | null;
  selectedFilter: Filter | null;
  getFieldName: () => string;
}

interface ProjectFilterMenuHeaderTextProps {
  icon?: () => JSX.Element;
  text: string;
}

function ProjectFilterMenuHeaderText({ icon, text }: ProjectFilterMenuHeaderTextProps) {
  return (
    <>
      {icon && <Icon as={icon} />}
      <Text textStyle={TEXT_STYLES.h3} verticalAlign="center">
        {text}
      </Text>
    </>
  );
}

export default function ProjectsFilterMenuHeader({
  filterDisplayed,
  selectedFilter,
  getFieldName,
}: ProjectsFilterMenuHeaderProps) {
  const { t } = useTranslation();

  return (
    <Box pb={2} pt={1} px={4} borderBottomWidth={1} borderBottomColor="gray.200" marginBottom={3}>
      <HStack alignItems="center">
        {filterDisplayed === FilterOptions.PROJECT_LEAD && (
          <ProjectFilterMenuHeaderText
            icon={ProjectLeadIcon}
            text={t("pages.projects.filters.projectLead")}
          />
        )}
        {filterDisplayed === FilterOptions.CONTENT && (
          <ProjectFilterMenuHeaderText
            icon={ContentSearchIcon}
            text={t("pages.projects.filters.content")}
          />
        )}
        {filterDisplayed === FilterOptions.FIELD_VALUE_IN_VARIABLES && (
          <ProjectFilterMenuHeaderText
            icon={TextFieldsIcon}
            text={selectedFilter?.parameters ? getFieldName() : t("pages.projects.filters.field")}
          />
        )}
        {filterDisplayed === FilterOptions.TASK && (
          <ProjectFilterMenuHeaderText
            icon={TaskIcon}
            text={selectedFilter?.value ? "Status" : t("pages.projects.filters.task")}
          />
        )}

        {filterDisplayed === FilterOptions.TAGS && (
          <ProjectFilterMenuHeaderText icon={TagIcon} text={t("pages.projects.filters.tag")} />
        )}
        {filterDisplayed === null && (
          <ProjectFilterMenuHeaderText text={t("pages.projects.filters.filter")} />
        )}
      </HStack>
    </Box>
  );
}
