import { HStack, Switch, Td, Tr } from "@chakra-ui/react";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import SortIcons from "@flows-platform/modules/TemplateEditor/components/FlowEditor/components/SortIcons";
import FormikSelectField from "@kwest_fe/core/src/components/FormikSelectField/FormikSelectField";
import CloseButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/CloseButton";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";

import { TableColumnStyle } from "./constants/styles";

const availableVariableTypes = [
  { label: "Text", value: FlowStepVariableTypeEnum.STRING },
  { label: "Number", value: FlowStepVariableTypeEnum.NUMBER },
  { label: "Date", value: FlowStepVariableTypeEnum.DATE },
  { label: "File", value: FlowStepVariableTypeEnum.FILE },
  { label: "Role", value: FlowStepVariableTypeEnum.ROLE },
  { label: "Image", value: FlowStepVariableTypeEnum.IMAGE },
];

interface VariableProps {
  fieldName: string;
  index: number;
  onRemove: () => void;
  moveUp: () => void;
  moveDown: () => void;
  triggerVariablesOnly?: boolean;
  disabled?: boolean;
}

export default function Variable({
  fieldName,
  index,
  onRemove,
  moveDown,
  moveUp,
  triggerVariablesOnly = false,
  disabled = false,
}: VariableProps) {
  return (
    <Tr key={`${fieldName}[${index}]`} data-testid="variable-row">
      <Td {...TableColumnStyle}>
        <SortIcons onMoveDown={moveDown} onMoveUp={moveUp} />
      </Td>
      <Td {...TableColumnStyle}>
        <FormikField
          name={`${fieldName}[${index}].label`}
          disabled={disabled}
          data-testid="context-drawer-trigger-variable-label"
        />
      </Td>
      <Td {...TableColumnStyle}>
        <FormikSelectField
          name={`${fieldName}[${index}].type`}
          options={availableVariableTypes}
          disabled={disabled}
          data-testid="context-drawer-trigger-variable-type"
        />
      </Td>
      <Td {...TableColumnStyle} textAlign="center">
        <HStack minH={10}>
          <FormikField
            name={`${fieldName}[${index}].in_trigger`}
            disabled={triggerVariablesOnly || disabled}
            renderElement={(field) => (
              <Switch
                {...field}
                isDisabled={triggerVariablesOnly || disabled}
                isChecked={field.value}
                colorScheme="purple"
              />
            )}
          />
        </HStack>
      </Td>
      <Td {...TableColumnStyle}>
        <CloseButton onClick={onRemove} isDisabled={disabled} />
      </Td>
    </Tr>
  );
}
