import { HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AiOutlineCamera } from "react-icons/ai";

interface ImageUploadCaptionProps {
  isDragActive: boolean;
}

function ImageUploadCaption({ isDragActive }: ImageUploadCaptionProps) {
  const { t } = useTranslation();
  return (
    <HStack justifyContent="center" color="tako.input.icon">
      <AiOutlineCamera />
      <Text color="tako.input.text">
        {isDragActive ? t("global.actions.drop_images") : t("global.actions.image_upload")}
      </Text>
    </HStack>
  );
}

export default ImageUploadCaption;
