import {
  HStack,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import type {
  DuplicateFlowClientMutationFn,
  SetFlowCurrentEditorClientMutationFn,
} from "@flows-platform/generated/graphql";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import StartInstanceButton from "@flows-platform/modules/Shared/components/StartInstanceButton";
import { FlowAssignmentVariablesProvider } from "@flows-platform/modules/Shared/providers/FlowAssignmentVariables";
import CurrentEditorModal from "@flows-platform/modules/Shared/UI/organisms/modals/CurrentEditorModal";
import FlowLabels from "@flows-platform/modules/TemplateEditor/components/FlowLabels";
import type { AllMyAdminFlows, CurrentEditorType } from "@flows-platform/types";
import ContextMenu from "@kwest_fe/core/src/components/UI/organisms/ContextMenu";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import toastSettings from "@kwest_fe/core/src/theme/settings";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import FlowAssignmentModal from "../FlowAssignmentModal";
import DeleteFlowModal from "./components/DeleteFlowModal";

interface FlowsOverviewTableProps {
  flows: AllMyAdminFlows;
  duplicateFlow: DuplicateFlowClientMutationFn;
  setFlowCurrentEditor: SetFlowCurrentEditorClientMutationFn;
}

export default function FlowsOverviewTable({
  flows,
  duplicateFlow,
  setFlowCurrentEditor,
}: FlowsOverviewTableProps) {
  const {
    isOpen: isOpenDeleteFlow,
    onOpen: onOpenDeleteFlow,
    onClose: onCloseDeleteFlow,
  } = useDisclosure();
  const {
    isOpen: isOpenCurrentEditor,
    onOpen: onOpenCurrentEditor,
    onClose: onCloseCurrentEditor,
  } = useDisclosure();
  const [activeFlow, setActiveFlow] = useState<string | null>(null);
  const [assigningFlow, setAssigningFlow] = useState(false);
  const [autoStartFlow, setAutoStartFlow] = useState(false);
  const [currentEditorForSelectedFlow, setCurrentEditorForSelectedFlow] =
    useState<CurrentEditorType>(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { myMembership } = useMyMembership();
  const { errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const selectedFlow = useMemo(() => {
    if (!activeFlow) return null;
    return flows?.find((flow) => flow?.id === activeFlow);
  }, [activeFlow, flows]);

  const setCurrentEditor = async (id: string, resetCurrentEditor = false) =>
    setFlowCurrentEditor({
      variables: {
        input: {
          id,
          ...(resetCurrentEditor && { resetCurrentEditor: true }),
        },
      },
    })
      .then(() => {
        navigate(`/flow-templates/${id}`);
      })
      .catch(errorToast(t("pages.flows_templates.toast.flow_save.error.title")));

  const startFlow = useCallback((id: string, autoStart = false) => {
    setActiveFlow(id);
    setAssigningFlow(true);
    setAutoStartFlow(autoStart);
  }, []);

  const handleDuplicateFlow = (id: string) => {
    duplicateFlow({ variables: { flowId: id } })
      .then(({ data }) =>
        toast({
          ...toastSettings,
          status: "success",
          title: t("pages.flows_templates.toast.flow_duplicate.success.title"),
          description: t("pages.flows_templates.toast.flow_duplicate.success.description", {
            name: data?.duplicateFlow?.newFlow?.name,
          }),
        })
      )
      .catch((error) =>
        toast({
          ...toastSettings,
          status: "error",
          title: t("pages.flows_templates.toast.flow_duplicate.error.title"),
          description: error.message,
        })
      );
  };
  const rows = flows?.map((flow) => {
    if (!flow) return null;
    const { id, name, isDraft, currentEditor, editorAssignTime, canModifyFlow } = flow;
    return (
      <Tr
        data-testid="template-row"
        key={id}
        fontSize="md"
        cursor={canModifyFlow ? "pointer" : undefined}
        sx={{ _hover: canModifyFlow ? { bg: "gray.100" } : {} }}
        onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
          if ((e.target as HTMLElement).nodeName !== "BUTTON") {
            if (!canModifyFlow) return;

            /**
             * Assign currentEditor and open flow
             */
            if (!currentEditor) {
              setCurrentEditor(id);
              return;
            }

            /**
             * If the current user themself is currently editing.
             * E.g. they have multiple tabs open
             */
            const TWO_HOURS = 2 * 60 * 60 * 1000;
            const differenceInMs = new Date().getTime() - new Date(editorAssignTime).getTime();

            /**
             * If the current user is the editor,
             * or the flow started being edited more than two
             * hours ago, reset editor to the current user.
             */
            if (currentEditor.id === myMembership?.id || differenceInMs > TWO_HOURS) {
              setCurrentEditor(id, true);
              return;
            }

            /**
             * Someone is currently editing the flow
             */
            setCurrentEditorForSelectedFlow(currentEditor);
            onOpenCurrentEditor();
          }
        }}
      >
        <Td colSpan={2}>{name}</Td>
        <Td px={2}>{isDraft && <Tag>{t("pages.flows_templates.status.draft")}</Tag>}</Td>
        <Td px={2}>{currentEditor?.workspaceEmail}</Td>
        <Td px={2}>
          <HStack spacing={2} justifyContent={"end"}>
            {flow?.labels?.length && <FlowLabels labels={flow.labels} />}
            <StartInstanceButton
              isDisabled={!!isDraft}
              isLoading={id === activeFlow && assigningFlow && autoStartFlow}
              onClickStart={() => {
                startFlow(id, true);
              }}
              onClickSchedule={(e) => {
                e.stopPropagation();
                startFlow(id);
              }}
            />
            <ContextMenu
              onDuplicate={() => {
                handleDuplicateFlow(id);
              }}
              onRemove={() => {
                onOpenDeleteFlow();
                setActiveFlow(id);
              }}
            />
          </HStack>
        </Td>
      </Tr>
    );
  });

  return (
    <>
      <Table variant="simple">
        <Thead mb={4}>
          <Tr>
            <Th>{t("pages.flows_templates.table.headers.flow_name")}</Th>
            <Th colSpan={4} />
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
      {selectedFlow?.id && (
        <FlowAssignmentVariablesProvider flowId={selectedFlow.id}>
          <FlowAssignmentModal
            key={selectedFlow.id}
            flowId={selectedFlow.id}
            isOpen={assigningFlow}
            autoStartFlow={autoStartFlow}
            onClose={() => {
              setAutoStartFlow(false);
              setAssigningFlow(false);
            }}
          />
        </FlowAssignmentVariablesProvider>
      )}

      <DeleteFlowModal
        activeFlow={activeFlow}
        isOpen={isOpenDeleteFlow}
        onClose={onCloseDeleteFlow}
      />

      <CurrentEditorModal
        currentEditor={currentEditorForSelectedFlow}
        isOpen={isOpenCurrentEditor}
        onClose={() => {
          setCurrentEditorForSelectedFlow(null);
          onCloseCurrentEditor();
        }}
      />
    </>
  );
}
