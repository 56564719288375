import Badge from "@kwest_fe/core/src/components/UI/atoms/Badge";

interface ProjectBadgesProps {
  isArchived: boolean;
  isPaused: boolean;
}

const ProjectBadges = ({ isArchived, isPaused }: ProjectBadgesProps) => {
  return (
    <>
      {isArchived && <Badge icon="archived" />}
      {!isArchived && isPaused && <Badge icon="paused" />}
    </>
  );
};

export default ProjectBadges;
