import { gql } from "@apollo/client";

const ALL_FLOWS = gql`
  query AllFlows($projectsOnly: Boolean) {
    allMyAdminFlows(projectsOnly: $projectsOnly) {
      id
      name
      isDraft
      isProject
      useCustomInstanceNames
      instanceName
      canModifyFlow
      currentEditor {
        id
        firstName
        workspaceEmail
      }
      editorAssignTime
      variables
      searchableVariables
      labels {
        id
        name
      }
    }
  }
`;

export default ALL_FLOWS;
