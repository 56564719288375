import { AccordionButton, AccordionItem, AccordionPanel, Box, Heading } from "@chakra-ui/react";
import { ArrowDropdownDownIcon } from "@flows-platform/theme/icons";
import type { PropsWithChildren } from "react";

interface AccordionItemWithHeaderProps {
  title: string;
  withPadding?: boolean;
}

export default function AccordionItemWithHeader({
  title,
  children,
  withPadding = false,
}: PropsWithChildren<AccordionItemWithHeaderProps>) {
  return (
    <AccordionItem borderTop="none" _last={{ borderBottom: "none" }}>
      {({ isExpanded }) => (
        <>
          <AccordionButton px={0} gap={1}>
            <Heading size="md" color="gray.700" fontSize="md" px={withPadding ? 5 : undefined}>
              {title}
            </Heading>
            <Box transform={isExpanded ? "rotate(180deg)" : undefined} transition="transform .3s">
              <ArrowDropdownDownIcon />
            </Box>
          </AccordionButton>
          <AccordionPanel px={0}>{children}</AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}
