import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useToggleJourneyArchiveStatusClientMutation,
  useToggleJourneyPauseStatusClientMutation,
  useUpdateJourneyClientMutation,
} from "@flows-platform/generated/graphql";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import { StackingContextEnum } from "@flows-platform/utils/enums";
import GenericConfirmationModal from "@kwest_fe/core/src/components/UI/organisms/modals/GenericConfirmationModal";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { ArchiveIcon, EditIcon, KebabIcon, PauseIcon } from "@kwest_fe/core/src/theme/icons";
import { Formik } from "formik";
import { t } from "i18next";
import { useCallback } from "react";
import * as Yup from "yup";

interface RenameProjectModalProps {
  projectId: string;
}

export const projectRenameValidationSchema = Yup.object().shape({
  updatedName: Yup.string().required("forms.instance_name.validation_message"),
});

function RenameProjectModal({ projectId }: RenameProjectModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { successToast, errorToast } = useDefaultToasts();
  const [updateJourney] = useUpdateJourneyClientMutation({
    onCompleted() {
      successToast(t(`pages.projects.toast.rename_project.success.title`));
      onClose();
    },
    onError() {
      errorToast(t(`pages.projects.toast.rename_project.error.title`));
    },
  });

  return (
    <>
      <Formik
        initialValues={{ updatedName: "" }}
        validationSchema={projectRenameValidationSchema}
        onSubmit={(values) => {
          updateJourney({
            variables: {
              journeyId: projectId,
              instanceName: values.updatedName,
            },
          });
        }}
      >
        {({ submitForm, isValid }) => (
          <GenericConfirmationModal
            onAction={submitForm}
            title={t(`pages.projects.modals.rename_project.title`)}
            actionText={t(`pages.projects.actions.rename`)}
            isOpen={isOpen}
            onClose={onClose}
            actionColor="purple"
            actionDisabled={!isValid}
          >
            <FormikField name="updatedName" label={t(`forms.project_name.label`)} />
          </GenericConfirmationModal>
        )}
      </Formik>
      <MenuItem onClick={onOpen}>
        <HStack>
          <EditIcon />
          <Text fontSize="md">{t(`pages.projects.actions.rename_project`)}</Text>
        </HStack>
      </MenuItem>
    </>
  );
}

interface ArchiveProjectModalProps {
  projectId: string;
  isArchived: boolean;
  isPaused: boolean;
}

function ArchiveProjectModal({ projectId, isArchived, isPaused }: ArchiveProjectModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [toggleJourneyArchiveStatus] = useToggleJourneyArchiveStatusClientMutation();
  const { successToast, errorToast } = useDefaultToasts();

  const onTogglePauseStatus = useCallback(() => {
    toggleJourneyArchiveStatus({
      variables: { journeyId: projectId },
      onCompleted() {
        successToast(
          t(
            `pages.projects.toast.${
              isArchived ? "unarchive_project" : "archive_project"
            }.success.title`
          )
        );
        onClose();
      },
      onError() {
        errorToast(
          t(
            `pages.projects.toast.${
              isArchived ? "unarchive_project" : "archive_project"
            }.error.title`
          )
        );
      },
    });
  }, [successToast, errorToast, projectId, isArchived, isPaused]);

  return (
    <>
      <GenericConfirmationModal
        onAction={onTogglePauseStatus}
        title={t(
          `pages.projects.modals.${isArchived ? "unarchive_project" : "archive_project"}.title`
        )}
        actionText={t(`pages.projects.actions.${isArchived ? "unarchive" : "archive"}`)}
        isOpen={isOpen}
        onClose={onClose}
        actionColor="purple"
      >
        <Text fontSize="md">
          {t(`pages.projects.modals.${isArchived ? "unarchive_project" : "archive_project"}.body`)}
        </Text>
      </GenericConfirmationModal>
      <MenuItem onClick={onOpen}>
        <HStack>
          <ArchiveIcon />
          <Text fontSize="md">
            {t(`pages.projects.actions.${isArchived ? "unarchive" : "archive"}`)} project
          </Text>
        </HStack>
      </MenuItem>
    </>
  );
}

interface PauseProjectModalProps {
  projectId: string;
  isPaused: boolean;
  isArchived: boolean;
}
function PauseProjectModal({ projectId, isPaused, isArchived }: PauseProjectModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [toggleJourneyPauseStatus] = useToggleJourneyPauseStatusClientMutation();
  const { successToast, errorToast } = useDefaultToasts();

  const onTogglePauseStatus = useCallback(() => {
    toggleJourneyPauseStatus({
      variables: { journeyId: projectId },
      onCompleted() {
        successToast(
          t(`pages.projects.toast.${isPaused ? "unpause_project" : "pause_project"}.success.title`)
        );
        onClose();
      },
      onError() {
        errorToast(
          t(`pages.projects.toast.${isPaused ? "unpause_project" : "pause_project"}.error.title`)
        );
      },
    });
  }, [successToast, errorToast, projectId, isArchived, isPaused]);

  return (
    <>
      <GenericConfirmationModal
        onAction={onTogglePauseStatus}
        title={t(`pages.projects.modals.${isPaused ? "unpause_project" : "pause_project"}.title`)}
        actionText={t(`pages.projects.actions.${isPaused ? "unpause" : "pause"}`)}
        isOpen={isOpen}
        onClose={onClose}
        actionColor="purple"
      >
        <Text fontSize="md">
          {t(`pages.projects.modals.${isPaused ? "unpause_project" : "pause_project"}.body`)}
        </Text>
      </GenericConfirmationModal>
      <MenuItem onClick={onOpen}>
        <HStack>
          <PauseIcon />
          <Text fontSize="md">
            {t(`pages.projects.actions.${isPaused ? "unpause" : "pause"}`)} project
          </Text>
        </HStack>
      </MenuItem>
    </>
  );
}

interface ProjectContextMenuProps {
  projectId: string;
  isArchived: boolean;
  isPaused: boolean;
}

export default function ProjectContextMenu({
  projectId,
  isPaused,
  isArchived,
}: ProjectContextMenuProps) {
  return (
    <Box zIndex={StackingContextEnum.Foreground + 1}>
      <Menu>
        <MenuButton as={IconButton} size="sm" icon={<KebabIcon />} />
        <MenuList>
          <RenameProjectModal projectId={projectId} />
          <ArchiveProjectModal
            projectId={projectId}
            isArchived={isArchived}
            isPaused={!!isPaused}
          />
          {!isArchived && (
            <PauseProjectModal
              projectId={projectId}
              isArchived={isArchived}
              isPaused={!!isPaused}
            />
          )}
        </MenuList>
      </Menu>
    </Box>
  );
}
