import { Box, Heading } from "@chakra-ui/react";
import type { ProjectStepFieldsFragment } from "@flows-platform/generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChannelList } from "stream-chat-react";

import CustomChannelList from "./CustomChannelList";
import { CustomChannelPreview } from "./CustomChannelPreview";
import EmptyState from "./EmptyState";

interface ICommentsAggregatorProps {
  projectSteps: (ProjectStepFieldsFragment | null)[];
}

const CommentsAggregator = ({ projectSteps }: ICommentsAggregatorProps) => {
  const projectStepIds = useMemo(() => projectSteps.map((step) => step?.id), [projectSteps]);

  const { t } = useTranslation();
  return (
    <Box display={"flex"} flexDir={"column"} px={"20px"} h={"48%"}>
      <Heading size="md" color="gray.700" fontSize="md" mb={5}>
        {t("pages.project_instance.variables.messages")}
      </Heading>
      <ChannelList
        EmptyStateIndicator={EmptyState}
        List={CustomChannelList}
        Preview={CustomChannelPreview}
        filters={{
          // Show only channels that contain a message
          last_message_at: { $gte: "2020-01-01T00:00:00.00Z" },
          id: { $in: projectStepIds },
        }}
      />
    </Box>
  );
};

export default CommentsAggregator;
