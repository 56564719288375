import type { StackProps } from "@chakra-ui/react";
import { Spinner, Text, VStack } from "@chakra-ui/react";

interface SimpleLoaderWithMessageProps extends StackProps {
  message?: string;
}

export default function SimpleLoaderWithMessage({
  message,
  ...restProps
}: SimpleLoaderWithMessageProps) {
  return (
    <VStack alignItems="center" justifyContent="center" {...restProps}>
      <Spinner color="purple.500" size="lg" emptyColor="purple.200" />
      {message && <Text>{message}</Text>}
    </VStack>
  );
}
