import { Tag } from "@chakra-ui/react";
import type { JourneyFieldsFragment } from "@flows-platform/generated/graphql";

import RemoveTagButton from "../../../../../Tags/components/RemoveTagsButton/RemoveTagButton";

interface CurrentTagsProps {
  tags: JourneyFieldsFragment["tags"];
  readOnly?: boolean;
}
const CurrentTags = ({ tags, readOnly }: CurrentTagsProps) => {
  return (
    <>
      {tags?.map((tag) => (
        <Tag key={tag?.id} display={"flex"} alignItems={"center"} gap={1} py={1}>
          {tag?.name}
          {!readOnly && <RemoveTagButton tagId={tag?.id} />}
        </Tag>
      ))}
    </>
  );
};

export default CurrentTags;
