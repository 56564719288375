import { Tag } from "@chakra-ui/react";
import type { SingleValueProps } from "react-select";
import { components } from "react-select";

export default function TagSingleValueDisplay({
  children,
  ...props
}: SingleValueProps & { data: { color: string } }) {
  return (
    <components.SingleValue {...props}>
      <Tag bg={props.data.color} color="white">
        {children}
      </Tag>
    </components.SingleValue>
  );
}
