type ObjectType = Record<string, any>;

function compareObjects<T extends ObjectType, P extends ObjectType>(newObject: T, oldObject: P) {
  const modifiedValues: Partial<T> = {};

  if (newObject) {
    Object.entries(newObject).forEach((entry) => {
      const [key, value] = entry;

      if (value !== oldObject[key]) {
        modifiedValues[key as keyof T] = value;
      }
    });
  }

  return modifiedValues;
}

export default compareObjects;
