import {
  FlowStepTypeEnum,
  useDocumentQuery,
  useUpdateDocumentClientMutation,
} from "@flows-platform/generated/graphql";
import { FlowStepObserver } from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import useFormObserver from "@flows-platform/modules/Shared/hooks/useFormObserver";
import DocumentEditor from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/components/FlowStepTemplateRenderer/components/DocumentEditorContainer/DocumentEditor";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

import FlowStepResourceLoaderContainer from "../../../../../FlowStepResourceLoaderContainer/FlowStepResourceLoaderContainer";
import { useFlowEditorDrawer } from "../../../../providers/FlowEditorDrawerProvider";

interface DocumentEditorContainerProps {
  initialFormValuesLoaded: boolean;
}

function DocumentEditorContainer({ initialFormValuesLoaded }: DocumentEditorContainerProps) {
  const { flowStep } = useFlowEditorDrawer() || {};
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [updateDocument] = useUpdateDocumentClientMutation({
    onCompleted() {
      successToast(t("pages.flow_step.toast.update_document.success.title"));
    },
    onError: errorToast(t("pages.flow_step.toast.update_document.error.title")),
  });

  useFormObserver(
    new FlowStepObserver({
      fields: ["document"],
      flowStepType: FlowStepTypeEnum.Document,
      async onChanged(modifiedValues) {
        if (!flowStep?.documentId) return null;
        const { document } = modifiedValues;
        return updateDocument({
          variables: {
            input: {
              id: flowStep?.documentId,
              ...document,
            },
          },
        });
      },
    })
  );

  return (
    <FlowStepResourceLoaderContainer
      query={useDocumentQuery}
      initialValuesKey="document"
      queryOptions={{ variables: { documentId: flowStep?.documentId } }}
      loadingErrorMessage="Error loading document"
      resolveInitialValue={(data) => data?.getDocument}
      initialFormValuesLoaded={initialFormValuesLoaded}
    >
      <DocumentEditor />
    </FlowStepResourceLoaderContainer>
  );
}

export default DocumentEditorContainer;
