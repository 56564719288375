import type { FileUploaderFileUrl } from "@kwest_fe/core/src/components/FileUploader/constants/types";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { FormItemItemType as FormItemType } from "@todo-viewer/generated/graphql";
import {
  getEncodedFileMapping,
  useTodoView,
} from "@todo-viewer/modules/TodoView/providers/TodoViewProvider";
import type { AxiosError } from "axios";
import md5 from "md5";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import useOnlineStatus from "./useOnlineStatus";

interface OfflineEnabledFileFieldProps {
  itemType: FormItemType;
  variableIdentifier?: string | null;
  isList: boolean;
  value: any;
  setValue: (value: any, shouldValidate?: boolean) => void;
  fileUploadUrl: string;
}

export default function useOfflineEnabledFileField({
  itemType,
  variableIdentifier,
  isList,
  value,
  setValue,
  fileUploadUrl,
}: OfflineEnabledFileFieldProps) {
  const { t } = useTranslation();
  const { online } = useOnlineStatus();
  const { errorToast } = useDefaultToasts();
  const { offlineImages, updateOfflineImageFields, deleteOfflineFileByFileMapping } = useTodoView();

  const uploadedFilePaths: string[] = useMemo(
    () => (Array.isArray(value) ? value : [value]).filter(Boolean),
    [value]
  );

  const filePaths = useMemo<FileUploaderFileUrl[]>(() => {
    if (variableIdentifier && variableIdentifier in offlineImages) {
      const variableOfflineFiles = offlineImages[variableIdentifier].files ?? [];
      const filePathsList = variableOfflineFiles?.reduce(
        (acc, encodedImageFile) => [
          ...acc.slice(0, encodedImageFile.position),
          encodedImageFile,
          ...acc.slice(encodedImageFile.position),
        ],
        uploadedFilePaths
      );
      return filePathsList.filter(Boolean);
    }
    return uploadedFilePaths;
  }, [uploadedFilePaths, offlineImages]);

  const getDownloadUrl = (filePath: FileUploaderFileUrl) => {
    if (typeof filePath !== "string") return filePath;
    return isList ? `${fileUploadUrl}/values/${md5(filePath)}` : fileUploadUrl;
  };

  const fileUrls = useMemo(() => {
    /** Convert image URLs to donwload URLs */
    return itemType === FormItemType.ImageUpload ? filePaths.map(getDownloadUrl) : filePaths;
  }, [filePaths]);

  const deleteUrlByIndex = (index: number) => getDownloadUrl(filePaths[index]);

  const deleteFileByFileUrl = (deletedFileUrl: FileUploaderFileUrl) => {
    if (typeof deletedFileUrl === "string") {
      if (isList) {
        const deleteIndex = uploadedFilePaths.findIndex(
          (_, index) => deleteUrlByIndex(index) === deletedFileUrl
        );
        if (deleteIndex > -1) {
          setValue((value as string[]).filter((_, index) => index !== deleteIndex));
        }
      } else {
        setValue("");
      }
    } else {
      deleteOfflineFileByFileMapping(variableIdentifier, deletedFileUrl);
    }
  };

  const cacheDeletedImages = (err: AxiosError, deleteFileUrl: string, fileUrl: string) => {
    if (variableIdentifier) {
      const variableOfflineFiles = offlineImages[variableIdentifier] || {};
      updateOfflineImageFields(variableIdentifier, {
        deleted: [...(variableOfflineFiles.deleted ?? []), { deleteUrl: deleteFileUrl, fileUrl }],
      });
      deleteFileByFileUrl(deleteFileUrl);
    }
  };

  const onFileUploaded = (files: File[], responseData: any[]) => {
    const uploadedImageValues = responseData.map((singleResponseData) => singleResponseData.path);
    if (isList) {
      setValue([...value, ...uploadedImageValues]);
    } else {
      setValue(uploadedImageValues[0]);
    }
  };

  const onFileUploadError = async (err: AxiosError, files: File[]) => {
    if (online) {
      errorToast(t("forms.file_uploader.toasts.upload.error.title"))(err);
      return;
    }
    if (variableIdentifier) {
      const variableOfflineFiles = offlineImages[variableIdentifier] || {};
      const encodedFileMappingWithPositions = (await getEncodedFileMapping(files)).map(
        (fileMapping, index) => ({
          ...fileMapping,
          position:
            uploadedFilePaths.filter(Boolean).length +
            (variableOfflineFiles.files ?? []).length +
            index,
        })
      );
      updateOfflineImageFields(variableIdentifier, {
        files: [...(variableOfflineFiles.files ?? []), ...encodedFileMappingWithPositions],
        isList,
      });
    }
  };
  return {
    fileUrls,
    onFileUploaded,
    onFileUploadError,
    deleteUrlByIndex,
    deleteFileByFileUrl,
    cacheDeletedImages,
  };
}
