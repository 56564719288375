import { FlowStepTypeEnum } from "@flows-platform/generated/graphql";
import { FlowStepPickableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import { LibraryIcon } from "@kwest_fe/core/src/theme/icons";

// This is Required to ensure there's no space between the flow steps
const STEP_SPACING = 10;

const PROJECT_BUILDER_AVAILABLE_TYPES = [FlowStepTypeEnum.Task];

const FLOW_EDITOR_AVAILABLE_TYPES = [
  FlowStepTypeEnum.Task,
  FlowStepTypeEnum.HttpRequest,
  FlowStepTypeEnum.Form,
  FlowStepTypeEnum.Slack,
  FlowStepTypeEnum.Email,
  FlowStepTypeEnum.List,
  FlowStepTypeEnum.Document,
  FlowStepTypeEnum.Approval,
];

export const flowStepTypesAndPickables = {
  ...FlowStepTypeEnum,
  ...FlowStepPickableTypeEnum,
} as const;

interface FlowStepDetails {
  pickableType: FlowStepPickableTypeEnum;
  label: string;
  sidebarLabel?: string;
  defaultName: string;
  icon: React.ReactElement;
}

const flowStepTypes: Record<FlowStepTypeEnum, FlowStepDetails> = {
  [FlowStepTypeEnum.Task]: {
    pickableType: flowStepTypesAndPickables.TaskPickable,
    label: "models.task.label",
    sidebarLabel: "models.task.sidebar_label",
    defaultName: "models.task.default_name",
    icon: <LibraryIcon type={FlowStepTypeEnum.Task} />,
  },
  [FlowStepTypeEnum.HttpRequest]: {
    pickableType: flowStepTypesAndPickables.HttpRequestPickable,
    label: "models.http_request.label",
    sidebarLabel: "models.http_request.sidebar_label",
    defaultName: "models.http_request.default_name",
    icon: <LibraryIcon type={FlowStepTypeEnum.HttpRequest} />,
  },
  [FlowStepTypeEnum.Form]: {
    pickableType: flowStepTypesAndPickables.FormPickable,
    label: "models.form.label",
    sidebarLabel: "models.form.sidebar_label",
    defaultName: "models.form.default_name",
    icon: <LibraryIcon type={FlowStepTypeEnum.Form} />,
  },
  [FlowStepTypeEnum.Slack]: {
    pickableType: flowStepTypesAndPickables.SlackPickable,
    label: "models.slack.label",
    sidebarLabel: "models.slack.sidebar_label",
    defaultName: "models.slack.default_name",
    icon: <LibraryIcon type={FlowStepTypeEnum.Slack} />,
  },
  [FlowStepTypeEnum.Email]: {
    pickableType: flowStepTypesAndPickables.EmailPickable,
    label: "models.email.label",
    sidebarLabel: "models.email.sidebar_label",
    defaultName: "models.email.default_name",
    icon: <LibraryIcon type={FlowStepTypeEnum.Email} />,
  },
  [FlowStepTypeEnum.List]: {
    pickableType: flowStepTypesAndPickables.ListPickable,
    label: "models.list.label",
    sidebarLabel: "models.list.sidebar_label",
    defaultName: "models.list.default_name",
    icon: <LibraryIcon type={FlowStepTypeEnum.List} />,
  },
  [FlowStepTypeEnum.Trigger]: {
    pickableType: flowStepTypesAndPickables.TriggerPickable,
    label: "models.trigger.label",
    defaultName: "models.trigger.default_name",
    icon: <LibraryIcon type={FlowStepTypeEnum.Trigger} background="yellow.200" />,
  },
  [FlowStepTypeEnum.Document]: {
    pickableType: flowStepTypesAndPickables.DocumentPickable,
    label: "models.document.label",
    defaultName: "models.document.default_name",
    icon: <LibraryIcon type={FlowStepTypeEnum.Document} />,
  },
  [FlowStepTypeEnum.Approval]: {
    pickableType: flowStepTypesAndPickables.ApprovalPickable,
    label: "models.approval.label",
    defaultName: "models.approval.default_name",
    icon: <LibraryIcon type={FlowStepTypeEnum.Approval} />,
  },
};

const flowStepTypePairs = {
  [FlowStepPickableTypeEnum.TaskPickable]: FlowStepTypeEnum.Task,
  [FlowStepPickableTypeEnum.HttpRequestPickable]: FlowStepTypeEnum.HttpRequest,
  [FlowStepPickableTypeEnum.FormPickable]: FlowStepTypeEnum.Form,
  [FlowStepPickableTypeEnum.SlackPickable]: FlowStepTypeEnum.Slack,
  [FlowStepPickableTypeEnum.EmailPickable]: FlowStepTypeEnum.Email,
  [FlowStepPickableTypeEnum.ListPickable]: FlowStepTypeEnum.List,
  [FlowStepPickableTypeEnum.TriggerPickable]: FlowStepTypeEnum.Trigger,
  [FlowStepPickableTypeEnum.DocumentPickable]: FlowStepTypeEnum.Document,
  [FlowStepPickableTypeEnum.ApprovalPickable]: FlowStepTypeEnum.Approval,
};

const flowEditorDrawerSpacing = { py: 4, px: 6 };

export {
  flowStepTypes,
  flowStepTypePairs,
  flowEditorDrawerSpacing,
  STEP_SPACING,
  PROJECT_BUILDER_AVAILABLE_TYPES,
  FLOW_EDITOR_AVAILABLE_TYPES,
};
