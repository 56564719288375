import { HStack, Tag, TagLabel, VStack } from "@chakra-ui/react";
import {
  JourneyStepStatus,
  useAllMembershipsQuery,
  useAllProjectTasksQuery,
  useAllSearchableFieldsQuery,
  useMyWorkspaceTagsClientQuery,
} from "@flows-platform/generated/graphql";
import SwitchWithLabel from "@flows-platform/modules/Shared/UI/atoms/Forms/SwitchWithLabel";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FilterOptions } from "../../constants/constants";
import ProjectsFilter from "./components/ProjectsFilter/ProjectsFilter";
import type { ComplexQueryFilter, Filter } from "./components/ProjectsFilter/types";

interface ProjectsFilterGroupProps {
  setShowProjectTasks: (value: boolean) => void;
  showProjectTasks: boolean;
  onFilterUpdate: (filter: string) => void;
}

export default function ProjectsFilterGroup({
  setShowProjectTasks,
  showProjectTasks,
  onFilterUpdate,
}: ProjectsFilterGroupProps) {
  const [selectedFilters, setSelectedFilters] = useState<(Filter | null)[]>([null]);
  const [showArchived, setShowArchived] = useState(false);
  const allMembershipsQuery = useAllMembershipsQuery({});
  const allSearchableFields = useAllSearchableFieldsQuery();
  const allProjectTasks = useAllProjectTasksQuery();
  const allProjectTags = useMyWorkspaceTagsClientQuery();

  const { t } = useTranslation();

  const normalizeFilter = (filter?: Filter | null): ComplexQueryFilter => {
    if (
      !filter?.value ||
      (!!filter.and && !filter.and?.value) ||
      (!!filter.or && !filter.or?.value)
    )
      return {};
    let filterString: FilterOptions | string = filter.type;

    // Replace parameters in the filter if present
    if (filter.parameters) {
      for (const param of Object.keys(filter.parameters)) {
        const regexString = `{${param}}`;
        const paramValue = filter.parameters[param as keyof Filter["parameters"]];
        filterString = filterString.replace(new RegExp(regexString, "g"), paramValue);
      }
    }

    let filterValue: boolean | string = filter.value;

    // Transform the TASK_STATUS filter to use FilterOptions.TASK_COMPLETED_STATUS instead
    if (filter.type === FilterOptions.TASK_STATUS) {
      filterString = FilterOptions.TASK_COMPLETED_STATUS;
      filterValue = filter.value === JourneyStepStatus.AwaitUser;
    }

    return {
      [filterString]: filterValue,
      ...(filter.and ? normalizeFilter(filter.and) : {}),
      ...(filter.or ? { or: normalizeFilter(filter.or) } : {}),
    };
  };

  const archivedFilter = !showArchived
    ? {
        [FilterOptions.ARCHIVE_STATUS]: showArchived,
        is_project: true,
      }
    : {};

  const normalizedSelectedFilters = useMemo(
    () =>
      selectedFilters.reduce<ComplexQueryFilter>((acc, filter) => {
        const normalized = normalizeFilter(filter);
        const andFilters = [
          ...(!acc.and || Array.isArray(acc.and) ? acc.and || [] : [acc.and]),
          ...(JSON.stringify(normalized) !== "{}" ? [normalized] : []),
        ];
        return {
          ...acc,
          ...(andFilters.length ? { and: andFilters } : {}),
        };
      }, archivedFilter),
    [selectedFilters, archivedFilter]
  );

  const filterExists = (filters: Filter[], singleFilter: Filter) =>
    filters.find((f) => JSON.stringify(f) === JSON.stringify(singleFilter));

  const updateFilterAtIndex = useCallback(
    (index: number) => (updatedFilter: Filter) => {
      setSelectedFilters((prevState) => [
        ...prevState
          .map((filter, i) => (i === index ? updatedFilter : filter))
          .reduce((acc, singleFilter) => {
            if (!singleFilter || filterExists(acc, singleFilter)) {
              return acc;
            }
            return [...acc, singleFilter];
          }, []),
        null,
      ]);
    },
    []
  );

  useEffect(() => {
    onFilterUpdate(JSON.stringify(normalizedSelectedFilters));
  }, [normalizedSelectedFilters]);

  return (
    <HStack w="full" px={5} py={4} borderBottomWidth={1} wrap="wrap">
      <HStack spacing={1} flex={1} flexWrap="wrap">
        {selectedFilters.reduce((acc, filter, index) => {
          const filterComponent = (
            <HStack spacing={1} pt={1} key={filter?.value}>
              {acc.length > 0 && index + 1 < selectedFilters.length && (
                <Tag size="lg" px={2}>
                  <TagLabel fontSize="sm">and</TagLabel>
                </Tag>
              )}
              <ProjectsFilter
                key={`${index + 1}`}
                allMemberships={allMembershipsQuery.data?.allMemberships}
                allSearchableFields={allSearchableFields.data?.allSearchableFields}
                allProjectTasks={allProjectTasks.data?.allProjectTasks}
                allProjectTags={allProjectTags.data?.myWorkspace?.journeyTags || []}
                selectedFilter={filter}
                onFilterUpdate={updateFilterAtIndex(index)}
                filterIndex={index}
              />
            </HStack>
          );
          return [...acc, filterComponent];
        }, [])}
      </HStack>
      <VStack w="auto" alignItems="start">
        <SwitchWithLabel
          isChecked={showProjectTasks}
          setChecked={setShowProjectTasks}
          label={t("pages.projects.filters.show_project_tasks")}
        />
        <SwitchWithLabel
          isChecked={showArchived}
          setChecked={setShowArchived}
          label={t("pages.projects.filters.archived")}
        />
      </VStack>
    </HStack>
  );
}
