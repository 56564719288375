import type { ApolloError } from "@apollo/client/errors";
import type { AllFlowsQuery, CreateFlowClientMutation } from "@flows-platform/generated/graphql";
import { AllFlowsDocument, useCreateFlowClientMutation } from "@flows-platform/generated/graphql";

interface UseCreateFlowMutationWithCacheUpdateOptions {
  isProject?: boolean;
  onCompleted?: (data: CreateFlowClientMutation) => void;
  onError?: (error: ApolloError) => void;
}

export default function useCreateFlowMutationWithCacheUpdate({
  isProject = false,
  onCompleted,
  onError,
}: UseCreateFlowMutationWithCacheUpdateOptions) {
  const [createFlow] = useCreateFlowClientMutation({
    onCompleted(data) {
      onCompleted?.(data);
    },
    onError(error) {
      onError?.(error);
    },
    update(cache, { data }) {
      const newFlowFromResponse = data?.createFlow?.flow;
      const existingFlows = cache.readQuery<AllFlowsQuery>({
        query: AllFlowsDocument,
        ...(isProject
          ? {
              variables: {
                projectsOnly: true,
              },
            }
          : {}),
      });
      if (newFlowFromResponse && existingFlows?.allMyAdminFlows) {
        cache.writeQuery<AllFlowsQuery>({
          query: AllFlowsDocument,
          ...(isProject
            ? {
                variables: {
                  projectsOnly: true,
                },
              }
            : {}),
          data: {
            allMyAdminFlows: [...existingFlows.allMyAdminFlows, newFlowFromResponse],
          },
        });
      }
    },
  });
  return { createFlow };
}
