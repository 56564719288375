import {
  Divider,
  GridItem,
  IconButton,
  Input,
  Link,
  Select,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import { ActionEnum, FormItemItemType } from "@flows-platform/generated/graphql";
import FormikField from "@flows-platform/modules/Shared/components/FormikField";
import SortIcons from "@flows-platform/modules/TemplateEditor/components/FlowEditor/components/SortIcons";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import type { FormikFieldProps } from "@kwest_fe/core/src/components/FormikField/FormikField";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import { DeleteIcon } from "@kwest_fe/core/src/theme/icons";
import capitalizeText from "@kwest_fe/core/src/utils/capitalizeText";
import { useMemo } from "react";

import type {
  RulesConfigFormValues,
  SingleRuleCondition,
} from "../RulesConfigForm/RulesConfigForm";
import mapSelectOptions from "./utils/mapSelectOptions";

interface SingleRuleConfigFormProps {
  prefix: string;
  index: number;
  isLast: boolean;
  onRuleMoved: (direction: "down" | "up") => () => void;
  onRuleAdded?: () => void;
  onRuleDeleted: () => void;
  onConditionAdded?: () => void;
  value: RulesConfigFormValues["rules"][number];
}

interface RuleConditionProps {
  prefix: string;
  value: SingleRuleCondition;
}

function RuleCondition({ prefix, value }: Readonly<RuleConditionProps>) {
  const { ruleVariableOptions } = useFlowEditorDrawer();

  const selectedVariable = useMemo(
    () => ruleVariableOptions?.[value.variable] || {},
    [value.variable, ruleVariableOptions]
  );

  const operatorsOptions = useMemo(() => {
    switch (selectedVariable.type) {
      case FlowStepVariableTypeEnum.NUMBER:
        return mapSelectOptions([
          {
            label: "equals",
            value: "===",
          },
          {
            label: "greater than",
            value: ">",
          },
          {
            label: "greater than or equal to",
            value: ">=",
          },
          {
            label: "less than",
            value: "<",
          },
          {
            label: "less than or equal to",
            value: "<=",
          },
        ]);
      case FlowStepVariableTypeEnum.BOOLEAN:
      case FlowStepVariableTypeEnum.STRING:
      default:
        return mapSelectOptions([
          {
            label: "is",
            value: "===",
          },
          {
            label: "is not",
            value: "!==",
          },
        ]);
    }
  }, [selectedVariable.type]);

  const variableOptions = mapSelectOptions(
    Object.keys(ruleVariableOptions).map((identifier) => ({
      label: ruleVariableOptions[identifier].label ?? identifier,
      value: identifier,
    }))
  );

  const variableValueOptions = useMemo((): FormikFieldProps["renderElement"] => {
    switch (selectedVariable?.type) {
      case FlowStepVariableTypeEnum.BOOLEAN:
        return (props) => (
          <Select {...props}>
            {mapSelectOptions([
              {
                label: "Yes",
                value: "true",
              },
              {
                label: "No",
                value: "false",
              },
            ])}
          </Select>
        );
      case FlowStepVariableTypeEnum.STRING:
        return (props) => (
          <Select {...props}>
            {mapSelectOptions(
              selectedVariable.options?.map((option) => ({ label: option, value: option })) ?? []
            )}
          </Select>
        );
      case FormItemItemType.Number:
        return (props) => <Input {...props} type="number" />;
      default:
        return () => <></>;
    }
  }, [selectedVariable.type]);

  return (
    <>
      <GridItem colSpan={2}>
        <FormikField
          name={`${prefix}.conditional`}
          renderElement={(props) => (
            <Select {...props}>
              <option value="if">If</option>
              <option value="always">Always</option>
            </Select>
          )}
        />
      </GridItem>
      {value.conditional === "if" ? (
        <>
          <GridItem colSpan={4}>
            <FormikField
              name={`${prefix}.variable`}
              renderElement={(props) => (
                <Select {...props} fontSize="md">
                  {variableOptions}
                </Select>
              )}
            />
          </GridItem>
          <GridItem colStart={3} colSpan={1}>
            <FormikField
              name={`${prefix}.operator`}
              renderElement={(props) => <Select {...props}>{operatorsOptions}</Select>}
            />
          </GridItem>
          <GridItem colSpan={3}>
            <FormikField name={`${prefix}.variableValue`} renderElement={variableValueOptions} />
          </GridItem>
        </>
      ) : (
        <GridItem colSpan={4} />
      )}
    </>
  );
}

export default function SingleRuleConfigForm({
  index,
  isLast,
  onRuleMoved,
  prefix,
  onRuleDeleted,
  onConditionAdded,
  value,
}: Readonly<SingleRuleConfigFormProps>) {
  const { currentFlow, activeFlowStepIndex } = useFlowEditor();

  const actionValueOptions = mapSelectOptions(
    currentFlow?.orderedFlowSteps
      ?.filter((_, idx) => activeFlowStepIndex === undefined || idx > activeFlowStepIndex)
      .map((flowStep) => ({
        label: flowStep?.name ?? "",
        value: flowStep?.id,
      })) ?? []
  );

  return (
    <>
      <SimpleGrid
        columns={6}
        gap={2}
        alignItems="center"
        data-testid={`${prefix}-single-rule-config-form`}
      >
        {value.conditions.map((condition, conditionIndex) => (
          <RuleCondition
            prefix={`${prefix}.conditions[${conditionIndex}]`}
            key={conditionIndex}
            value={condition}
          />
        ))}
        {onConditionAdded && (
          <GridItem colStart={3} colSpan={4}>
            <Link onClick={onConditionAdded} fontSize="md">
              Add condition
            </Link>
          </GridItem>
        )}
        <GridItem colSpan={2}>
          <Text fontSize="md" fontWeight="medium">
            Then
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <FormikField
            name={`${prefix}.action`}
            renderElement={(props) => (
              <Select {...props}>
                {mapSelectOptions([
                  {
                    label: capitalizeText(ActionEnum.JumpTo.split("_").join(" ")),
                    value: ActionEnum.JumpTo,
                  },
                ])}
              </Select>
            )}
          />
        </GridItem>
        <GridItem colSpan={3}>
          <FormikField
            name={`${prefix}.actionValue`}
            renderElement={(props) => <Select {...props}>{actionValueOptions}</Select>}
          />
        </GridItem>
        <GridItem colSpan={3}>
          <SortIcons
            disableMoveDown={isLast}
            disableMoveUp={index === 0}
            onMoveDown={onRuleMoved("down")}
            onMoveUp={onRuleMoved("up")}
          />
        </GridItem>
        <GridItem colSpan={3} textAlign="right">
          <IconButton
            aria-label="delete-rule"
            onClick={onRuleDeleted}
            fontSize="md"
            icon={<DeleteIcon color="red.500" />}
          />
        </GridItem>
      </SimpleGrid>
      {!isLast && <Divider my={3} />}
    </>
  );
}
