import { useApolloClient } from "@apollo/client";
import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import type {
  OneFlowFieldsFragment,
  SetFlowCurrentEditorClientMutationFn,
} from "@flows-platform/generated/graphql";
import flowEditorPageReducer from "@flows-platform/modules/TemplateEditor/utils/flowEditorPageReducer";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useNavigate } from "react-router-dom";

interface FlowEditorPageOptions {
  flow: OneFlowFieldsFragment;
  isProject: boolean;
  setFlowCurrentEditor: SetFlowCurrentEditorClientMutationFn;
}

export default function useFlowEditorPage({
  flow,
  isProject,
  setFlowCurrentEditor,
}: FlowEditorPageOptions) {
  const { saveFlow, activeFlowStep, updateActiveFlowStep, updateFlowVariables } = useFlowEditor();
  const { successToast, errorToast } = useDefaultToasts();
  const navigate = useNavigate();
  const client = useApolloClient();

  const [state, dispatch] = useReducer(flowEditorPageReducer, {
    initialFlow: flow,
    flowName: flow?.name || "",
    savingChanges: false,
    useCustomInstanceNames: Boolean(flow?.useCustomInstanceNames),
    instanceName: flow?.instanceName || "",
  });

  const goBack = useCallback(() => {
    navigate(isProject ? "/project-templates" : "/flow-templates");
  }, [isProject, navigate]);

  const updatedFlow = useMemo(
    () => ({
      ...flow,
      useCustomInstanceNames: state.useCustomInstanceNames,
      instanceName: state.instanceName,
      name: state.flowName,
    }),
    [flow, state]
  );

  const hasUnsavedChanges = useMemo(
    () => JSON.stringify(updatedFlow) !== JSON.stringify(state.initialFlow),
    [state, updatedFlow]
  );

  const refreshInitialFlow = useCallback(() => {
    if (hasUnsavedChanges) {
      dispatch({ type: "update-flow-editor-state", payload: { initialFlow: updatedFlow } });
    }
  }, [hasUnsavedChanges, updatedFlow]);

  const saveAndRemoveCurrentEditor = useCallback(async () => {
    try {
      if (!flow) return;
      await setFlowCurrentEditor({
        variables: {
          input: {
            id: flow?.id,
            resetCurrentEditor: true,
          },
        },
      });
    } catch (err) {
      errorToast(
        t(
          isProject
            ? "pages.project_templates.toast.project_save.error.title"
            : "pages.flows_templates.toast.flow_save.error.title"
        )
      );
    }
  }, [isProject, flow, setFlowCurrentEditor, errorToast]);

  /**
   * Save the current flow and then perform an action afterwards. Forward the results of the saveFlow action to the consecutive function.
   */
  const handleSaveFlow = useCallback(
    async (showToast?: boolean) => {
      dispatch({ type: "update-flow-editor-state", payload: { savingChanges: true } });
      try {
        const flowToSave = {
          client,
          flow: {
            id: updatedFlow.id,
            name: updatedFlow.name,
            isDraft: !!updatedFlow.isDraft,
            useCustomInstanceNames: updatedFlow.useCustomInstanceNames,
            instanceName: updatedFlow.instanceName,
            version: updatedFlow.version,
            orderedFlowSteps: updatedFlow.orderedFlowSteps?.map((step) => {
              if (!step) return null;
              return {
                flow: {
                  isDraft: !!step.flow.isDraft,
                  id: step.flow.id,
                  name: step.flow.name,
                  version: step.flow.version,
                },
                // The mutation requires objects with the id field instead of an id field with a string value
                approval: step.approvalId ? { id: step.approvalId } : null,
                document: step.documentId ? { id: step.documentId } : null,
                form: step.formId ? { id: step.formId } : null,
                id: step.id,
                message: step.message,
                name: step.name,
                position: step.position,
                stepType: step.stepType,
                task: step.taskId ? { id: step.taskId } : null,
                todoList: step.todoList,
                waitDuration: step.waitDuration,
                phaseId: step.phase?.id,
              };
            }),
          },
        };
        const res = await saveFlow(flowToSave);
        if (showToast)
          successToast(
            t(
              isProject
                ? "pages.project_templates.toast.project_save.success.title"
                : "pages.flows_templates.toast.flow_save.success.title"
            )
          );
        refreshInitialFlow();
        const activeFlowStepFromServer = res.data?.saveFlow?.flow?.orderedFlowSteps?.find(
          (step) => step?.id === activeFlowStep?.id
        );
        if (activeFlowStepFromServer) updateActiveFlowStep(activeFlowStepFromServer);
      } catch (e) {
        errorToast(
          t(
            isProject
              ? "pages.project_templates.toast.project_save.error.title"
              : "pages.flows_templates.toast.flow_save.error.title"
          )
        );
      } finally {
        dispatch({ type: "update-flow-editor-state", payload: { savingChanges: false } });
      }
    },
    [
      isProject,
      saveFlow,
      client,
      updatedFlow,
      errorToast,
      successToast,
      refreshInitialFlow,
      updateActiveFlowStep,
      activeFlowStep?.id,
    ]
  );

  /**
   * Update flow variables
   */
  const handleSaveFlowVariables = useCallback(
    (variables: string, variableCategories?: string) => {
      if (!flow) return;
      void updateFlowVariables({
        variables: {
          flowId: flow.id,
          variables,
          variableCategories,
        },
        onCompleted() {
          successToast("Updated flow variables!");
        },
        onError(e) {
          errorToast("Failed to update flow variables")(e);
        },
      });
    },
    [updateFlowVariables, flow, successToast, errorToast]
  );

  /**
   * Remove the current editor whenever leaving
   * the editor page.
   */
  useEffect(
    () => () => {
      void saveAndRemoveCurrentEditor();
    },
    []
  );

  return {
    state,
    dispatch,
    updatedFlow,
    handleSaveFlow,
    hasUnsavedChanges,
    goBack,
    handleSaveFlowVariables,
  };
}
