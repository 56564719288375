import type { Dispatch, PropsWithChildren } from "react";
import { createContext, useContext, useMemo, useReducer } from "react";

interface LayoutState {
  isLeftSidebarOpen: boolean;
  isRightSidebarOpen: boolean;
}

interface LayoutValues {
  state: LayoutState;
  dispatch: Dispatch<{ type: LayoutReducerAction }>;
}

export type LayoutReducerAction =
  | "HIDE_LEFT_SIDEBAR"
  | "HIDE_RIGHT_SIDEBAR"
  | "TOGGLE_LEFT_SIDEBAR"
  | "TOGGLE_RIGHT_SIDEBAR";

const layoutContext = createContext<LayoutValues>({} as LayoutValues);

const layoutReducer = (state: LayoutState, action: { type: LayoutReducerAction }) => {
  switch (action.type) {
    case "TOGGLE_LEFT_SIDEBAR":
      return { ...state, isLeftSidebarOpen: !state.isLeftSidebarOpen };
    case "TOGGLE_RIGHT_SIDEBAR":
      return { ...state, isRightSidebarOpen: !state.isRightSidebarOpen };
    default:
      return state;
  }
};

const LayoutProvider = ({ children }: PropsWithChildren) => {
  const initialState = {
    isLeftSidebarOpen: false,
    isRightSidebarOpen: false,
  };

  const [state, dispatch] = useReducer(layoutReducer, initialState);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <layoutContext.Provider value={value}>{children}</layoutContext.Provider>;
};

export default LayoutProvider;

export const useLayout = () => {
  const context = useContext(layoutContext);
  if (context === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};

export const withLayout = (Component: React.FC) => {
  return (props: any) => {
    return (
      <LayoutProvider>
        <Component {...props} />
      </LayoutProvider>
    );
  };
};
