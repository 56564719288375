import "focus-visible/dist/focus-visible";
import "./theme/styles.css";

import { Spinner } from "@chakra-ui/react";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

const container = document.getElementById("root");

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Suspense fallback={<Spinner />}>
    <App />
  </Suspense>
);
