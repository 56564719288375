import { Box } from "@chakra-ui/react";

import SimpleLoaderWithMessage from "../UI/molecules/display/SimpleLoaderWithMessage/SimpleLoaderWithMessage";

interface LoadingSpinnerOverlayProps {
  message?: string;
}

function LoadingSpinnerOverlay({ message }: LoadingSpinnerOverlayProps) {
  return (
    <Box pos="absolute" w="100vw" h="100vh" top={0} left={0}>
      <SimpleLoaderWithMessage message={message} h="full" />
    </Box>
  );
}

export default LoadingSpinnerOverlay;
