import { CheckIcon } from "@chakra-ui/icons";
import type { ButtonProps } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const DoneButton = (props: ButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      backgroundColor="tako.button.bg"
      color={"white"}
      width="full"
      type="submit"
      fontWeight={600}
      leftIcon={<CheckIcon fontSize={"11px"} />}
      {...props}
      data-cy="form-done-button"
      _hover={{
        backgroundColor: "#741EE5",
      }}
      _active={{
        backgroundColor: "#6818D9",
      }}
    >
      {t("global.actions.done")}
    </Button>
  );
};

export default DoneButton;
