import type { MenuItemProps } from "@chakra-ui/react";
import { HStack, Menu, MenuItem, MenuList, Text, useDisclosure } from "@chakra-ui/react";
import CloseButton from "@core/components/UI/atoms/Buttons/CloseButton/CloseButton";
import type { PropsWithChildren } from "react";
import { memo, useState } from "react";

const MenuItemStyles: MenuItemProps = {
  gap: 3,
  py: 1,
  _hover: {
    bg: "blue.50",
  },
};

export type ProgressiveRevealMenuItems<FilterKey extends string> = {
  [key in FilterKey]?: {
    label: string;
    caption?: string;
    icon?: JSX.Element;
    component?: ({ onClose }: { onClose: () => void }) => JSX.Element;
    visible?: boolean;
  };
};

interface ProgressiveRevealMenuProps<FilterKey extends string> {
  /**
   * Label for the menu control
   */
  controlLabel?: string;
  /**
   * Menu header that will be shown on top of the menu list
   * @param props Current selected menu properties
   * @returns JSX Element
   */
  menuHeader?: ({ filterDisplayed }: { filterDisplayed: FilterKey | null }) => JSX.Element;
  /**
   * Menu items to show in the menu along with corresponding components that show up
   * when the menu item is selected.
   */
  menuItems: ProgressiveRevealMenuItems<FilterKey>;
  /**
   * What happens when a menu item is clicked
   * @param props Current selected menu properties
   * @returns void
   */
  menuItemOnClick?: ({ itemKey, onClose }: { itemKey: FilterKey; onClose: () => void }) => void;
  /**
   * Selected item key
   */
  selected?: FilterKey | null;
  /**
   * What hapens when the filter is cleared
   * @returns void
   */
  onFilterCleared: () => void;
  /**
   * Whether or not to hide the close button
   */
  hideClose: boolean;
}

/**
 * ProgressiveRevealMenu provides a way to display a menu with multiple selections. Using this control
 * users can configure what components show at each point
 * @param ProgressiveRevealMenuProps
 * @returns JSX.Element
 */
function ProgressiveRevealMenu<FilterKey extends string>({
  controlLabel,
  menuHeader,
  menuItems,
  menuItemOnClick,
  children,
  selected,
  onFilterCleared,
  hideClose = false,
}: PropsWithChildren<ProgressiveRevealMenuProps<FilterKey>>) {
  const [filterDisplayed, setFilterDisplayed] = useState<FilterKey | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <HStack spacing={1}>
      {controlLabel && (
        <Text fontWeight="medium" marginRight={1}>
          {controlLabel}
        </Text>
      )}
      <HStack spacing={0}>
        <Menu
          isOpen={isOpen}
          onOpen={() => {
            if (!selected) {
              setFilterDisplayed(null);
            } else if (menuItems[selected]?.component) {
              setFilterDisplayed(selected);
            }
            onOpen();
          }}
          closeOnSelect={false}
          closeOnBlur={true}
          onClose={onClose}
        >
          {children}
          <MenuList minWidth="20rem">
            {menuHeader?.({ filterDisplayed })}
            {!filterDisplayed && (
              <>
                {Object.keys(menuItems)
                  .filter((menuItemKey: FilterKey) => menuItems[menuItemKey]?.visible !== false)
                  .map((menuItemKey: FilterKey) => (
                    <MenuItem
                      {...MenuItemStyles}
                      key={menuItemKey}
                      onClick={() => {
                        if (menuItems[menuItemKey]?.component) {
                          setFilterDisplayed(menuItemKey);
                        } else {
                          setFilterDisplayed(null);
                          onClose();
                        }
                        menuItemOnClick?.({ itemKey: menuItemKey, onClose });
                      }}
                    >
                      {menuItems[menuItemKey]?.icon}
                      <Text fontSize="md">{menuItems[menuItemKey]?.label}</Text>
                      {menuItems[menuItemKey]?.caption && (
                        <Text fontSize="md" color="gray.500">
                          {menuItems[menuItemKey]?.caption}
                        </Text>
                      )}
                    </MenuItem>
                  ))}
              </>
            )}
            {/* Show the submenu only when one of the menu items has been selected */}
            {filterDisplayed && menuItems[filterDisplayed]?.component?.({ onClose })}
          </MenuList>
        </Menu>
        {selected && !hideClose && (
          <CloseButton
            borderLeftRadius={0}
            variant="solid"
            px={1.5}
            onClick={() => {
              onFilterCleared();
              onClose();
            }}
          />
        )}
      </HStack>
    </HStack>
  );
}

export default memo(ProgressiveRevealMenu);
