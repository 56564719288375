import { Box, Heading } from "@chakra-ui/react";
import type { JourneyFieldsFragment } from "@flows-platform/generated/graphql";
import { SettingsIcon } from "@flows-platform/theme/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import CurrentTags from "../../CurrentTags";
import AddTagButton from "./components/AddTagButton";

interface ProjectTagsProps {
  tags: JourneyFieldsFragment["tags"];
  readOnly?: boolean;
}

const ProjectTags = ({ tags, readOnly }: ProjectTagsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {!readOnly ? (
        <Link to="/projects/tags">
          <Box display={"flex"} gap={2} alignItems={"center"} role="group">
            <Heading size="md" color="gray.700" fontSize="md">
              {t("pages.project_instance.tags.title")}
            </Heading>

            <Box color={"gray.400"} _groupHover={{ color: "purple.500" }}>
              <SettingsIcon />
            </Box>
          </Box>
        </Link>
      ) : (
        <Heading size="md" color="gray.700" fontSize="md">
          {t("pages.project_instance.tags.title")}
        </Heading>
      )}
      <Box display="flex" gap={2} alignItems={"center"} position={"relative"} flexWrap={"wrap"}>
        <CurrentTags tags={tags} readOnly={readOnly} />
        {!readOnly && <AddTagButton tags={tags} />}
      </Box>
    </>
  );
};

export default ProjectTags;
