import { useApolloClient } from "@apollo/client";
import { useDisclosure } from "@chakra-ui/react";
import { useDeleteJourneyClientMutation } from "@flows-platform/generated/graphql";
import DeleteJourneyModal from "@flows-platform/modules/FlowsOverview/components/FlowsTableRow/components/DeleteJourneyModal/DeleteJourneyModal";
import ContextMenu from "@kwest_fe/core/src/components/UI/organisms/ContextMenu";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

function ContextMenuProject({ projectId }: { projectId: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteJourney] = useDeleteJourneyClientMutation();
  const { successToast, errorToast } = useDefaultToasts();
  const client = useApolloClient();

  const { t } = useTranslation();

  const onDeleteJourney = async () =>
    deleteJourney({
      variables: { journeyId: projectId },
      onCompleted: () => {
        successToast(t("pages.flows.toast.delete_project.success.title"));
        void client.refetchQueries({
          include: "active",
        });
        onClose();
      },
      onError: () => errorToast(t("pages.flows.toast.delete_project.error.title")),
    });

  return (
    <>
      <ContextMenu onRemove={onOpen} />
      {/* TODO: When PR #251 gets merged, implement genericDeleteModal */}
      <DeleteJourneyModal onDeleteJourney={onDeleteJourney} isOpen={isOpen} onClose={onClose} />
    </>
  );
}

export default ContextMenuProject;
