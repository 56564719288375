import * as Yup from "yup";

export default {
  todoList: Yup.object({
    receiverId: Yup.string().nullable(),
    receiverRole: Yup.string().nullable(),
    caption: Yup.string().nullable(),
    orderedTodoItems: Yup.array(
      Yup.object({
        id: Yup.string().required(),
        name: Yup.string().nullable().required("A name is required for this todo item"),
        description: Yup.string().nullable(),
        resourceUrl: Yup.string().nullable(),
        resourceDisplayName: Yup.string().nullable(),
        isDone: Yup.bool(),
      })
    ).nullable(),
  }).nullable(),
};
