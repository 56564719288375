import type { TypographyProps } from "@chakra-ui/react";
import { Flex, Heading, Text } from "@chakra-ui/react";

interface SectionHeaderProps {
  headingText: string;
  subHeadingText?: string;
  headingSize?: TypographyProps["fontSize"];
}

function SectionHeader({ headingText, subHeadingText, headingSize = "lg" }: SectionHeaderProps) {
  return (
    <Flex direction="column" gap={2}>
      <Heading fontSize={headingSize} fontWeight={600} mb={2}>
        {headingText}
      </Heading>
      {subHeadingText && <Text fontSize={"md"}>{subHeadingText}</Text>}
    </Flex>
  );
}

export default SectionHeader;
