import { Box, Heading } from "@chakra-ui/react";
import type { InstallionProjectDocumentPackageType } from "@flows-platform/generated/graphql";
import parse from "html-react-parser";

import Document from "./Document";

interface IDocumentsPackageProps {
  pack: InstallionProjectDocumentPackageType;
}
const DocumentsPackage = ({ pack }: IDocumentsPackageProps) => {
  return (
    <Box display={"flex"} flexDir={"column"} gap={2}>
      <Heading fontWeight={500} color="gray.700" fontSize="md">
        {parse(pack.title || "")}
      </Heading>

      <Box display={"flex"} flexDirection={"column"} gap={1}>
        {pack.files.map((doc) => (
          <Document doc={doc} key={doc.name} />
        ))}
      </Box>
    </Box>
  );
};

export default DocumentsPackage;
