import { Checkbox, HStack, Link, Text, VStack } from "@chakra-ui/react";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import type { InferType } from "yup";

import type { FlowEditorDrawerSchema } from "../../../../constants/schema";

export default function EmailEditorFields() {
  const { variableStore } = useFlowEditorDrawer() || {};
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<InferType<typeof FlowEditorDrawerSchema>>();
  const { message } = values;

  if (!message) return <></>;

  return (
    <>
      <VStack spacing={3}>
        <FormikField
          name="message.reviewBeforeSending"
          renderElement={({ value, onChange, name }) => (
            <Checkbox name={name} isChecked={value} onChange={onChange}>
              <Text fontWeight="normal">{t("forms.review_before_sending.label")}</Text>
            </Checkbox>
          )}
        />
        <VStack spacing={1} w="full" alignItems="flex-start">
          <FormikField
            name="message.toEmail"
            label="To"
            required={!message?.reviewBeforeSending}
            showOptionalIndicator
            renderElement={() => (
              <RichTextEditor
                name="message.toEmail"
                isSingleLine
                hideToolbar
                variables={variableStore}
                emailFieldsOnly
              />
            )}
          />
          <HStack pt={2} spacing={3} color="blue.500" fontWeight="medium">
            <Link
              fontSize="md"
              onClick={() => {
                if (message?.showCc) {
                  setFieldValue("message.ccEmail", "");
                }
                setFieldValue("message.showCc", !message?.showCc);
              }}
            >
              {!message?.showCc ? "Cc" : t("forms.hide_cc.label")}
            </Link>
            <Link
              fontSize="md"
              onClick={() => {
                if (message?.showBcc) {
                  setFieldValue("message.bccEmail", "");
                }
                setFieldValue("message.showBcc", !message?.showBcc);
              }}
            >
              {!message?.showBcc ? "Bcc" : t("forms.hide_bcc.label")}
            </Link>
          </HStack>
        </VStack>
      </VStack>
      {message?.showCc && (
        <FormikField
          name="message.ccEmail"
          label="Cc"
          renderElement={() => (
            <RichTextEditor
              name="message.ccEmail"
              isSingleLine
              hideToolbar
              variables={variableStore}
              emailFieldsOnly
            />
          )}
        />
      )}
      {message?.showBcc && (
        <FormikField
          name="message.bccEmail"
          label="Bcc"
          renderElement={() => (
            <RichTextEditor
              name="message.bccEmail"
              isSingleLine
              hideToolbar
              variables={variableStore}
              emailFieldsOnly
            />
          )}
        />
      )}
      <VStack spacing={3}>
        <FormikField
          name="message.enableReplyTo"
          renderElement={({ value, onChange, name }) => (
            <Checkbox
              name={name}
              isChecked={value}
              onChange={(evt) => {
                if (message?.enableReplyTo) {
                  setFieldValue("message.replyToEmail", "");
                }
                onChange(evt);
              }}
            >
              <Text fontWeight="normal">{t("forms.enable_reply_to.label")}</Text>
            </Checkbox>
          )}
        />
        {message?.enableReplyTo && (
          <FormikField
            label={t("forms.reply_to_email.label")}
            name="message.replyToEmail"
            renderElement={() => (
              <RichTextEditor
                name="message.replyToEmail"
                isSingleLine
                hideToolbar
                variables={variableStore}
                emailFieldsOnly
              />
            )}
          />
        )}
      </VStack>
    </>
  );
}
