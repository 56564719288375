import { IconButton, useDisclosure } from "@chakra-ui/react";
import { ArrowDownAltIcon, ArrowUpAltIcon } from "@flows-platform/theme/icons";
import GenericConfirmationModal from "@kwest_fe/core/src/components/UI/organisms/modals/GenericConfirmationModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface PhaseReorderProps {
  reorderFn: (sourcePosition: number, destinationPosition: number) => void;
  position: number;
}
const PhaseReorder = ({ reorderFn, position }: PhaseReorderProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newPosition, setNewPosition] = useState(0);
  return (
    <>
      <IconButton
        _hover={{
          backgroundColor: "gray.100",
        }}
        variant="ghost"
        aria-label="Move phase after"
        icon={<ArrowDownAltIcon />}
        onClick={() => {
          setNewPosition(position + 1);
          onOpen();
        }}
        size="xs"
        isDisabled={position === 0}
        data-testid="move-phase-down-button"
      />
      <IconButton
        _hover={{
          backgroundColor: "gray.100",
        }}
        variant="ghost"
        aria-label="Move phase before"
        icon={<ArrowUpAltIcon />}
        onClick={() => {
          setNewPosition(position - 1);
          onOpen();
        }}
        isDisabled={position === 1 || position === 0}
        size="xs"
        data-testid="move-phase-up-button"
      />
      <GenericConfirmationModal
        size="2xl"
        title={t("pages.settings.sections.phases.modal.reorder.title")}
        isOpen={isOpen}
        onClose={onClose}
        onAction={() => {
          reorderFn(position, newPosition);
          onClose();
        }}
        actionColor="purple"
      >
        {t("pages.settings.sections.phases.modal.reorder.body")}
      </GenericConfirmationModal>
    </>
  );
};

export default PhaseReorder;
