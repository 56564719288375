import { CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Button, Checkbox, Heading, HStack, IconButton, Text } from "@chakra-ui/react";
import { StaticTodoItemModeEnum } from "@kwest_fe/core/src/enums/todoItem";
import type { TodoItemToggleInput } from "@todo-viewer/generated/graphql";
import { AiOutlinePlus as PlusIcon } from "react-icons/ai";
import { CgFileDocument as FileIcon } from "react-icons/cg";
import { MdDragIndicator as DragIndicator } from "react-icons/md";

import TodoItemInnerStack from "./components/TodoItemInnerStack";
import TodoItemInnerWrapper from "./components/TodoItemInnerWrapper";
import TodoItemOuterWrapper from "./components/TodoItemOuterWrapper";
import type { TodoItemTypeOptionalResource } from "./constants/types";

interface StaticTodoItemProps {
  checkboxDisabled?: boolean;
  toggleTodoItem?: (payload: TodoItemToggleInput) => void;
  mode?: StaticTodoItemModeEnum;
  todoItem: TodoItemTypeOptionalResource;
}

function StaticTodoItem({
  checkboxDisabled = false,
  toggleTodoItem,
  mode = StaticTodoItemModeEnum.LearnerView,
  todoItem,
}: StaticTodoItemProps) {
  const { isDone, name, description, resourceUrl, resourceDisplayName } = todoItem;
  const textColor = isDone ? "gray.400" : "gray.600";

  return (
    <TodoItemOuterWrapper>
      {mode === StaticTodoItemModeEnum.DndPreview && (
        <IconButton
          aria-label="Drag handle"
          variant="ghost"
          color="gray.300"
          icon={<DragIndicator size={20} />}
          alignSelf="center"
        />
      )}
      <TodoItemInnerWrapper>
        <Checkbox
          size="lg"
          isChecked={isDone}
          disabled={checkboxDisabled}
          onChange={() => {
            if (toggleTodoItem) toggleTodoItem(todoItem);
          }}
        />

        <TodoItemInnerStack>
          <Heading
            color={textColor}
            textDecoration={isDone ? "line-through" : "auto"}
            fontSize="md"
          >
            {name}
          </Heading>

          <Text color={textColor} wordBreak={"break-word"}>
            {description}
          </Text>

          {mode === StaticTodoItemModeEnum.LearnerView && resourceUrl && (
            <Button
              onClick={() => window.open(resourceUrl, "_blank")}
              colorScheme="purple"
              variant="ghost"
              rightIcon={<ExternalLinkIcon />}
            >
              {resourceDisplayName || "Link to resource"}
            </Button>
          )}

          {mode === StaticTodoItemModeEnum.DndPreview && resourceUrl && (
            <HStack>
              <Button colorScheme="purple" variant="ghost" leftIcon={<FileIcon />}>
                {resourceDisplayName || "Link to resource"}
              </Button>
              <IconButton aria-label="delete" size="xs" variant="ghost" icon={<CloseIcon />} />
            </HStack>
          )}

          {mode === StaticTodoItemModeEnum.DndPreview && !resourceUrl && (
            <Button variant="ghost" colorScheme="purple" leftIcon={<PlusIcon />}>
              Link a resource
            </Button>
          )}
        </TodoItemInnerStack>
      </TodoItemInnerWrapper>
    </TodoItemOuterWrapper>
  );
}

export default StaticTodoItem;
