import { Avatar, Box, Text } from "@chakra-ui/react";

interface RoleJourneyVariableProps {
  fullName: string;
}

const RoleJourneyVariable = ({ fullName }: RoleJourneyVariableProps) => {
  return (
    <Box display={"flex"} alignItems={"center"} gap={2}>
      <Avatar name={fullName} size="sm" />
      <Text fontSize="md" fontWeight={400}>
        {fullName}
      </Text>
    </Box>
  );
};

export default RoleJourneyVariable;
