import { Box } from "@chakra-ui/react";
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
  BoldIcon,
  CodeIcon,
  ItalicIcon,
  OrderedListIcon,
  UnderlineIcon,
  UnorderedListIcon,
} from "@core/theme/icons/richTextEditor";

import type { MarkTypes } from "../../constants";
import type { BlockFormatOperations } from "../../types";
import AttachmentButton from "./components/AttachmentButton";
import BlockButton from "./components/BlockButton";
import ImageUploadButton from "./components/ImageUploadButton";
import MarkButton from "./components/MarkButton";

interface ToolbarProps {
  isMarkActive: (format: keyof typeof MarkTypes) => boolean;
  toggleMark: (format: keyof typeof MarkTypes) => void;
  isBlockActive: (format: BlockFormatOperations, blockType: "align" | "type") => boolean;
  toggleBlock: (format: BlockFormatOperations) => void;
  enableImageUploads?: boolean;
  onImageSelected?: (files: File[]) => void;
  onFileSelected?: (files: File[]) => void;
}

export default function Toolbar({
  isMarkActive,
  toggleMark,
  isBlockActive,
  toggleBlock,
  onImageSelected,
  onFileSelected,
}: ToolbarProps) {
  return (
    <Box
      py={2}
      px={3}
      borderBottomWidth={1}
      w="full"
      display={"flex"}
      justifyContent={"space-around"}
    >
      <MarkButton
        icon={<BoldIcon />}
        format="bold"
        isMarkActive={isMarkActive}
        toggleMark={toggleMark}
      />
      <MarkButton
        icon={<ItalicIcon />}
        format="italic"
        isMarkActive={isMarkActive}
        toggleMark={toggleMark}
      />
      <MarkButton
        icon={<UnderlineIcon />}
        format="underline"
        isMarkActive={isMarkActive}
        toggleMark={toggleMark}
      />
      <MarkButton
        icon={<CodeIcon />}
        format="code"
        isMarkActive={isMarkActive}
        toggleMark={toggleMark}
      />
      <BlockButton
        icon={<OrderedListIcon />}
        format="numbered-list"
        toggleBlock={toggleBlock}
        isBlockActive={isBlockActive}
      />
      <BlockButton
        icon={<UnorderedListIcon />}
        format="bulleted-list"
        toggleBlock={toggleBlock}
        isBlockActive={isBlockActive}
      />
      <BlockButton
        icon={<AlignLeftIcon />}
        format="left"
        toggleBlock={toggleBlock}
        isBlockActive={isBlockActive}
      />
      <BlockButton
        icon={<AlignCenterIcon />}
        format="center"
        toggleBlock={toggleBlock}
        isBlockActive={isBlockActive}
      />
      <BlockButton
        icon={<AlignRightIcon />}
        format="right"
        toggleBlock={toggleBlock}
        isBlockActive={isBlockActive}
      />
      <BlockButton
        icon={<AlignJustifyIcon />}
        format="justify"
        toggleBlock={toggleBlock}
        isBlockActive={isBlockActive}
      />
      {onFileSelected && <AttachmentButton onFilesSelected={onFileSelected} />}

      {onImageSelected && <ImageUploadButton onFilesSelected={onImageSelected} />}
    </Box>
  );
}
