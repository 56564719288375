import { useTranslation } from "react-i18next";
import { ValueContainerProps, components } from "react-select";
import { FormikSelectFieldSelectOption } from "../types";
import { Text } from "@chakra-ui/react";

export default function ValueContainer({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
  ...props
}: ValueContainerProps<FormikSelectFieldSelectOption, true>) {
  const selectedOptions = props.selectProps.value;
  const { t } = useTranslation();

  return (
    <components.ValueContainer {...props}>
      {Array.isArray(selectedOptions) && (
        <Text color="gray.500">
          {selectedOptions?.length
            ? `${selectedOptions.length} selected`
            : `${t("global.actions.select")}...`}
        </Text>
      )}
    </components.ValueContainer>
  );
}
