const BASE_BRANCH = "master";

// Create URL for the current build on AWS Amplify
const AMPLIFY_BRANCH = import.meta.env?.VITE_AWS_BRANCH;
const AMPLIFY_APP_ID = import.meta.env?.VITE_AWS_APP_ID;
const AWS_PULL_REQUEST_ID = import.meta.env?.VITE_AWS_PULL_REQUEST_ID;

let AMPLIFY_APP_URL;

if (AMPLIFY_BRANCH && AMPLIFY_APP_ID) {
  AMPLIFY_APP_URL = `https://${AMPLIFY_BRANCH}.${AMPLIFY_APP_ID}.amplifyapp.com`;
}

if (AWS_PULL_REQUEST_ID && AMPLIFY_APP_ID) {
  AMPLIFY_APP_URL = `https://pr-${AWS_PULL_REQUEST_ID}.${AMPLIFY_APP_ID}.amplifyapp.com`;
}

const configProd = {
  auth0: {
    clientId: import.meta.env?.VITE_AUTH0_CLIENT_ID,
    domain: import.meta.env?.VITE_AUTH0_DOMAIN,
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    // Set redirect URI to AWS Amplify URL for preview branches
    redirectUri:
      AMPLIFY_BRANCH !== BASE_BRANCH && AMPLIFY_APP_URL
        ? AMPLIFY_APP_URL
        : import.meta.env?.VITE_BASE_URL,
  },
  backend: {
    uri: import.meta.env?.VITE_BACKEND_URL,
    graphQLEndpoint: `${import.meta.env?.VITE_BACKEND_URL}/gql`,
  },
  slack: {
    clientId: import.meta.env?.VITE_SLACK_CLIENT_ID,
  },
};

export default configProd;
