import { VStack } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";

function TodoItemInnerStack({ children }: PropsWithChildren) {
  return (
    <VStack spacing={3} alignItems="flex-start" maxW="80%" w="full">
      {children}
    </VStack>
  );
}

export default TodoItemInnerStack;
