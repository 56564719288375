import { Box, Text } from "@chakra-ui/react";
import { useProjectAwaitingUserUpcomingQuery } from "@flows-platform/generated/graphql";
import QueryLoaderContainer from "@kwest_fe/core/src/components/QueryLoaderContainer";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ContextDrawerReadOnly from "../../../../../TaskView/ContextDrawerReadOnly";
import InstallerUpcomingTodoCard from "./components/InstallerUpcomingTodoCard";

const InstallerTodosUpcomingBoard = () => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  return (
    <QueryLoaderContainer
      query={useProjectAwaitingUserUpcomingQuery}
      loadingErrorMessage="Something went wrong while trying to load inbox tasks"
      render={({ data: journeys }) =>
        !journeys?.projectsAwaitingUserUpcoming?.length ? (
          <Box p={5}>
            <Text fontSize={"14px"}>{t("pages.inbox.table.empty_message")}</Text>
          </Box>
        ) : (
          <Box display={"flex"} flexDir={"column"} gap={2}>
            {journeys.projectsAwaitingUserUpcoming?.map(
              (journey) =>
                journey?.tasks?.length && (
                  <InstallerUpcomingTodoCard
                    onProjectSelect={() => {
                      setSelectedProjectId(journey.id || "");
                      setIsDrawerOpen(true);
                    }}
                    journey={{
                      projectId: journey.id || "",
                      projectName: journey.name || "",
                      projectTasks: journey.tasks.map((task) => ({
                        stepId: task?.stepId || "",
                        stepName: task?.stepName || "",
                        dueDate: task?.dueDate || "",
                        taskId: task?.taskId || "",
                      })),
                    }}
                    key={journey?.id}
                  />
                )
            )}
            <ContextDrawerReadOnly
              isOpen={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
              }}
              projectId={selectedProjectId}
            />
          </Box>
        )
      }
    />
  );
};

export default InstallerTodosUpcomingBoard;
