import { VStack } from "@chakra-ui/react";
import type { VariableCategoryType } from "@flows-platform/generated/graphql";
import {
  GetProjectStepsDocument,
  useUpdateJourneyVariablesClientMutation,
} from "@flows-platform/generated/graphql";
import AccordionItemWithHeader from "@flows-platform/modules/Shared/UI/molecules/layout/AccordionItemWithHeader/AccordionItemWithHeader";
import type { VariableType } from "@flows-platform/types";
import { PROJECT_DEFAULT_CATEGORY_NAME } from "@flows-platform/utils/getCategoriesFromVariables";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import DateInputMetaDataField from "./DateInputMetadataField";
import FileInputMetaDataField from "./FileInputMetadataField";
import InputMetaDataField from "./InputMetadataField";
import RoleMetadataField from "./RoleMetadataField";

interface ContextVariablesProps {
  projectVariableCategories: VariableCategoryType[];
  projectVariables: Record<string, VariableType>;
  journeyId: string;
  readOnly?: boolean;
}

interface VariableTypeWithId extends VariableType {
  id: string;
}

const ContextVariables = ({
  projectVariableCategories,
  projectVariables,
  journeyId,
  readOnly,
}: ContextVariablesProps) => {
  const { errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [updateVariable, { loading }] = useUpdateJourneyVariablesClientMutation({
    onError: (error) => {
      errorToast(t("pages.projects.toast.update_variable.error.title"))(error);
    },
  });

  const variablesArray = useMemo(() => {
    const keys = Object.keys(projectVariables);
    const variablesWithId = Object.values(projectVariables).reduce<VariableTypeWithId[]>(
      (acc, curr) => {
        const id = keys.find((key) => projectVariables[key] === curr) || curr.label;
        return [
          ...acc,
          { ...curr, id, category: curr.category || t(PROJECT_DEFAULT_CATEGORY_NAME) },
        ];
      },
      []
    );

    return variablesWithId.filter(
      (scopeVariable: VariableType) =>
        (scopeVariable.type !== FlowStepVariableTypeEnum.ROLE ||
          (scopeVariable.type === FlowStepVariableTypeEnum.ROLE &&
            scopeVariable.label !== "Project Lead")) &&
        !scopeVariable.is_always_available
    );
  }, [projectVariables]);

  const variablesForCategory = (category: { id: string; name: string }) =>
    variablesArray.filter(
      (variable) => variable.category === category.id || variable.category === category.name
    );

  const handleUpdateVariables = (
    identifier: string,
    type: string,
    newValue: Record<string, string> | number | string
  ) => {
    updateVariable({
      variables: {
        journeyId,
        variables: JSON.stringify({
          [identifier]: type === "NUMBER" ? Number(newValue) : newValue,
        }),
      },
      refetchQueries: [
        {
          query: GetProjectStepsDocument,
          variables: {
            journeyId,
          },
        },
      ],
    });
  };

  return (
    <>
      {projectVariableCategories.map((category) => {
        const variablesForSingleCategory = variablesForCategory(category);
        return variablesForSingleCategory.length ? (
          <AccordionItemWithHeader title={category.name} key={category.id}>
            <VStack alignItems={"start"} w="full">
              {variablesForSingleCategory.map((scopeVariable) => {
                if (
                  scopeVariable.type === FlowStepVariableTypeEnum.FILE ||
                  scopeVariable.type === FlowStepVariableTypeEnum.IMAGE
                )
                  return (
                    <FileInputMetaDataField
                      journeyId={journeyId}
                      identifier={scopeVariable.id}
                      handleUpdate={handleUpdateVariables}
                      key={scopeVariable.label}
                      label={scopeVariable.label}
                      isLoading={loading}
                      type={scopeVariable.type}
                      value={scopeVariable.value || ""}
                      readOnly={readOnly}
                    />
                  );

                if (scopeVariable.type === FlowStepVariableTypeEnum.DATE)
                  return (
                    <DateInputMetaDataField
                      identifier={scopeVariable.id}
                      handleUpdate={handleUpdateVariables}
                      key={scopeVariable.label}
                      label={scopeVariable.label}
                      isLoading={loading}
                      type={scopeVariable.type}
                      value={scopeVariable.value || ""}
                      readOnly={readOnly}
                    />
                  );

                if (scopeVariable.type === FlowStepVariableTypeEnum.ROLE)
                  return (
                    <RoleMetadataField
                      identifier={scopeVariable.id}
                      handleUpdate={handleUpdateVariables}
                      key={scopeVariable.label}
                      label={scopeVariable.label}
                      isLoading={loading}
                      type={scopeVariable.type}
                      value={scopeVariable.value || ""}
                      readOnly={readOnly}
                    />
                  );

                return (
                  <InputMetaDataField
                    identifier={scopeVariable.id}
                    handleUpdate={handleUpdateVariables}
                    key={scopeVariable.label}
                    label={scopeVariable.label}
                    isLoading={loading}
                    type={scopeVariable.type}
                    value={scopeVariable.value || ""}
                    readOnly={readOnly}
                  />
                );
              })}
            </VStack>
          </AccordionItemWithHeader>
        ) : null;
      })}
    </>
  );
};

export default ContextVariables;
