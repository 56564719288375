import {
  Button,
  Divider,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import { IntegrationIcon } from "@flows-platform/theme/icons";
import { useTranslation } from "react-i18next";

interface RemoteModalProps {
  prefix: string;
  onSave: () => void;
}

function RemoteDataModal({ prefix, onSave }: RemoteModalProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { t } = useTranslation();

  const handleModalClosed = () => {
    if (onSave) onSave();
    onClose();
  };

  return (
    <>
      <IconButton
        aria-label="Connect to remote data"
        icon={<IntegrationIcon />}
        size="sm"
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={handleModalClosed}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Custom Data Configuration</ModalHeader>
          <ModalCloseButton />
          <Divider />
          <ModalBody py={6}>
            <FormikField
              name={`${prefix}.remoteDataUrl`}
              label="URL"
              placeholder="Enter custom data URL"
              renderElement={(props) => <Textarea {...props} variant="outline" borderWidth={1} />}
            />
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Button mr={3} onClick={handleModalClosed}>
              Close
            </Button>
            <Button
              colorScheme="purple"
              onClick={handleModalClosed}
              // disabled={formErrors?.remoteDataUrl}
            >
              {t("global.actions.save")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RemoteDataModal;
