import type { OneOrderedFlowStep } from "@flows-platform/types";
import type { FlowStepMode } from "@kwest_fe/core/src/enums/flowStep";
import { FlowStepModeEnum } from "@kwest_fe/core/src/enums/flowStep";

import type {
  PickableStep,
  PreviewStep,
} from "../components/FlowEditor/components/FlowStep/constants/types";
import getInCanvasConfig from "../utils/getInCanvasConfig";
import getPickableConfig from "../utils/getPickableConfig";
import getPreviewConfig from "../utils/getPreviewConfig";
import useGetBaseDragConfig from "../utils/useGetBaseDragConfig";

interface GetDragConfigInput {
  mode: FlowStepMode;
  flowId: string;
  step: NonNullable<OneOrderedFlowStep> | PickableStep | PreviewStep;
  index: number;
}

export default function useGetDragConfig({ mode, flowId, step, index }: GetDragConfigInput) {
  const baseDragConfig = useGetBaseDragConfig({ flowId, step, mode });

  if (mode === FlowStepModeEnum.Pickable && !Object.keys(step).includes("name")) {
    return getPickableConfig(step as PickableStep, index, baseDragConfig);
  }

  if (Object.keys(step).length === 2 && Object.keys(step).includes("name")) {
    return getPreviewConfig(step as PreviewStep, index, baseDragConfig);
  }

  return getInCanvasConfig(step, index, baseDragConfig);
}
