import { Heading, HStack, Input, Text } from "@chakra-ui/react";
import useEditableInput from "@flows-platform/modules/Shared/hooks/useEditableInput";
import ConfirmChanges from "@kwest_fe/core/src/components/UI/molecules/forms/ConfirmChanges";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { PROJECT_META_DATA_LINE_HEIGHT } from "../../constants";

interface InputMetaDataFieldProps {
  identifier: string;
  label: string;
  value: number | string;
  handleUpdate: (label: string, type: string, newValue: number | string) => void;
  isLoading: boolean;
  type: string;
  readOnly?: boolean;
}

function InputMetaDataField({
  identifier,
  label,
  value,
  handleUpdate,
  isLoading,
  type,
  readOnly,
}: InputMetaDataFieldProps) {
  const {
    inputRef,
    isEditing,
    toggleEditingOn,
    toggleEditingOff,
    isChanged,
    handleCancel,
    handleConfirm,
    valueState,
    setValueState,
  } = useEditableInput({
    value,
    handleUpdate: (newValue) => {
      handleUpdate(identifier, type, newValue);
    },
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  return (
    <HStack alignItems="flex-start" w="100%">
      <Heading
        fontSize="md"
        textColor="gray.500"
        flex="1"
        w="50%"
        lineHeight={PROJECT_META_DATA_LINE_HEIGHT}
      >
        {label}
      </Heading>
      <HStack alignItems="center" flex="1" onClick={toggleEditingOn}>
        {(!isEditing || readOnly) && (
          <Text
            fontSize="md"
            textColor="gray.500"
            flex="1"
            minHeight={PROJECT_META_DATA_LINE_HEIGHT}
            lineHeight={PROJECT_META_DATA_LINE_HEIGHT}
          >
            {valueState || t("pages.project_instance.variables.placeholder")}
          </Text>
        )}

        {!readOnly && isEditing && (
          <Input
            flex={1}
            variant="ghost"
            placeholder={t("pages.project_instance.variables.placeholder")}
            ref={inputRef}
            height={PROJECT_META_DATA_LINE_HEIGHT}
            px={1}
            type={type === "NUMBER" ? "number" : "text"}
            onChange={(e) => {
              setValueState(e.target.value);
            }}
            onBlur={toggleEditingOff}
            value={valueState}
          />
        )}
        <ConfirmChanges
          isLoading={isLoading}
          display={isChanged}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          buttonSize="xs"
        />
      </HStack>
    </HStack>
  );
}

export default InputMetaDataField;
