import type { IconButtonProps } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/react";
import { CancelIcon } from "@core/theme/icons";

export default function CloseButton({
  onClick,
  variant = "ghost",
  size = "sm",
  ...rest
}: Omit<IconButtonProps, "aria-label">) {
  return (
    <IconButton
      onClick={onClick}
      aria-label="close"
      variant={variant}
      icon={<CancelIcon size="sm" />}
      size={size}
      _hover={
        variant === "ghost"
          ? {
              bg: "transparent",
            }
          : undefined
      }
      {...rest}
    />
  );
}
