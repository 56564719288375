import {
  Box,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FormItemItemType as FormItemType } from "@flows-platform/generated/graphql";
import { FeatureFlags } from "@flows-platform/modules/Core/constants/enums";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import type { OrderedFormItems } from "@flows-platform/types";
import getFeatureFlag from "@flows-platform/utils/getFeatureFlag";
import ReceiverAssignment from "@kwest_fe/core/src/components/ReceiverAssignment/ReceiverAssignment";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useField } from "formik";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { v4 as uuidv4 } from "uuid";

import FormikFormItemDraggable from "./components/FormikFormItemDraggable";
import RulesConfigSection from "./components/RulesConfigSection";
import FormItemTypes from "./constants/formItemTypes";

function FormEditor() {
  const { myMembership } = useMyMembership();
  const { addRoleVariable, recipients, variableStore } = useFlowEditorDrawer() || {};
  const orderedFormItemsField = useField<OrderedFormItems>("form.orderedFormItems");
  const orderedFormItems = orderedFormItemsField[0].value || [];
  const [, , { setValue: setOrderedFormItems }] = orderedFormItemsField;

  const membershipFieldEnabled = Boolean(
    getFeatureFlag(FeatureFlags.MembershipFormItem, myMembership)
  );
  const conditionalLogicEnabled = Boolean(
    getFeatureFlag(FeatureFlags.ConditionalLogic, myMembership)
  );

  const addItem = (itemType: FormItemType) => {
    setOrderedFormItems([
      ...(orderedFormItems || []),
      {
        id: uuidv4(),
        itemType,
        text: "",
        label: "",
        options: ["Option 1", "Option 2"],
        useRemoteData: false,
        useCaptionText: false,
        useLabel: true,
        isOptional: false,
        isList: false,
        remoteDataUrl: "",
        defaultText: "",
        position: orderedFormItems.length || 0,
      },
    ]);
  };

  const FormEditorTypesEnabled = {
    [FormItemType.DescriptionText]: true,
    [FormItemType.ShortText]: true,
    [FormItemType.LongText]: true,
    [FormItemType.Number]: true,
    [FormItemType.Option]: true,
    [FormItemType.Date]: true,
    [FormItemType.ImageUpload]: true,
    [FormItemType.Boolean]: true,
    [FormItemType.Membership]: membershipFieldEnabled,
    [FormItemType.FileUpload]: true,
  };

  if (!recipients || !addRoleVariable) return <></>;

  return (
    <DndProvider backend={HTML5Backend}>
      <VStack align="stretch">
        <Box bg="gray.50" px={6} py={6}>
          <ReceiverAssignment
            parent="form"
            roleRecipients={recipients}
            onRoleCreated={addRoleVariable}
          />
        </Box>

        <VStack spacing={0}>
          {orderedFormItems?.map(
            (formItem, index) =>
              formItem && (
                <FormikFormItemDraggable
                  key={formItem.id}
                  formItem={formItem}
                  index={index}
                  variables={variableStore}
                  remoteDataEnabled={Boolean(
                    getFeatureFlag(FeatureFlags.RemoteDataConnectionField, myMembership)
                  )}
                />
              )
          )}
        </VStack>

        <Box px={5}>
          <Menu>
            <MenuButton data-testid="add-form-field-button">
              <AddButton>Field</AddButton>
            </MenuButton>
            <MenuList>
              <MenuOptionGroup title="Field type" textTransform="uppercase" />

              <MenuDivider />
              {(Object.keys(FormItemTypes) as FormItemType[]).map((key) => {
                return (
                  FormEditorTypesEnabled[key] && (
                    <MenuItem
                      key={key}
                      onClick={() => {
                        addItem(key);
                      }}
                    >
                      <HStack>
                        {FormItemTypes[key]?.icon}
                        <Text>{FormItemTypes[key].label}</Text>
                      </HStack>
                    </MenuItem>
                  )
                );
              })}
            </MenuList>
          </Menu>
        </Box>
        {conditionalLogicEnabled && (
          <>
            <Divider mt={2} />
            <RulesConfigSection />
            <Divider />
          </>
        )}
      </VStack>
    </DndProvider>
  );
}

export default FormEditor;
