import { useAuth0 } from "@auth0/auth0-react";
import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";

import { useCreateMembershipClientMutation } from "../../../generated/graphql";
import CreateMembershipPage from "./CreateMembershipPage";

function CreateMembershipContainer() {
  const { user } = useAuth0();

  const [createMembership] = useCreateMembershipClientMutation();

  return (
    <ErrorBoundary>
      <CreateMembershipPage createMembership={createMembership} workspaceEmail={user?.email} />
    </ErrorBoundary>
  );
}

export default CreateMembershipContainer;
