import { Button, FormLabel, HStack, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react";

import { DropdownArrow } from "../../theme/icons";
import { TEXT_STYLES } from "../../theme/text";

interface DropdownProps {
  menuItems: React.ReactNode;
  label: string;
  value: string;
  optionalSubText?: boolean;
}

export default function Dropdown({ value, label, menuItems, optionalSubText }: DropdownProps) {
  return (
    <Menu>
      <MenuButton width="100%" minWidth="10rem">
        <FormLabel>
          <HStack>
            <Text>{label}</Text>
            {optionalSubText && <Text textStyle={TEXT_STYLES.small12}>(optional)</Text>}
          </HStack>
        </FormLabel>
        <Button variant="outline" width="full" rightIcon={<DropdownArrow />}>
          <Text textAlign="left" width="full" textStyle={TEXT_STYLES.lead14Normal} fontSize="1rem">
            {value}
          </Text>
        </Button>
      </MenuButton>
      <MenuList>{menuItems}</MenuList>
    </Menu>
  );
}
