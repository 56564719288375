// eslint-disable-next-line @typescript-eslint/max-params
export default function shouldExecuteDragMove(monitor, ref, sourceIndex, targetIndex) {
  // Don't replace items with themselves
  if (sourceIndex === targetIndex) return false;

  // Determine rectangle on screen
  const targetBoundingRect = ref.current?.getBoundingClientRect();

  // Get vertical middle
  const minShift = (targetBoundingRect.bottom - targetBoundingRect.top) / 4;

  // Determine mouse position
  const clientOffset = monitor.getClientOffset();
  if (!clientOffset) return false;

  // Get pixels to the top
  const hoverClientYDown = clientOffset.y - targetBoundingRect.top;

  // Get pixels to the bottom
  const hoverClientYUp = targetBoundingRect.bottom - clientOffset.y;

  /**
   * Perform the move when the mouse has crossed 25% either of the top or
   * bottom boundary when dragging downwards or updwards respectively
   */
  if (sourceIndex < targetIndex && hoverClientYDown < minShift) return false;

  // Dragging upwards
  if (sourceIndex > targetIndex && hoverClientYUp < minShift) return false;

  // It's now safe to execute move
  return true;
}
