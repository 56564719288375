import { FlowStepVariableTypeEnum } from "@core/enums";
import type { VariableType } from "@core/types";
import { getOptionsFromList } from "@core/utils/generateOptions";
import type { IconType } from "react-icons";
import { BiUserVoice as InitiatorIcon } from "react-icons/bi";
import { BsBraces as RoleIcon } from "react-icons/bs";

import { VARIABLE_EXTRA_PROPERTIES } from "../constants/constants";

export interface MultiPickerOptionValue {
  label: string;
  value: string;
  icon?: IconType;
  options?: MultiPickerOptionValue[];
}

export function generateMultiPickerOptionsFromVariables(
  variables: Record<string, VariableType>,
  emailFieldsOnly = false,
  excludedVariableTypes: string[] = []
) {
  return Object.keys(variables).reduce((acc, identifier) => {
    const isEmailField = [FlowStepVariableTypeEnum.STRING, FlowStepVariableTypeEnum.ROLE].includes(
      variables[identifier].type
    );
    const isExcluded = excludedVariableTypes.includes(variables[identifier].type);
    return isExcluded || (emailFieldsOnly && !isEmailField)
      ? acc
      : [
          ...acc,
          {
            value: identifier,
            label: variables[identifier].label || identifier,
            icon: variables[identifier].type === "ROLE" ? InitiatorIcon : RoleIcon,
            ...(variables[identifier].type in VARIABLE_EXTRA_PROPERTIES
              ? {
                  options: getOptionsFromList(
                    VARIABLE_EXTRA_PROPERTIES[variables[identifier].type].filter(
                      (property) => !emailFieldsOnly || property === "email"
                    )
                  ),
                }
              : {}),
          },
        ];
  }, []);
}

export function getActiveOptionsFromPath(path: string[], options: MultiPickerOptionValue[]) {
  return path.reduce(
    (nextOptions, level) => nextOptions?.find((option) => option.value === level)?.options,
    options
  );
}

export function getSelectedOptionsFromPath(path: string[], options: MultiPickerOptionValue[]) {
  return path.reduce<MultiPickerOptionValue[]>((allSelectedOptions, selectedValue) => {
    const previousOptions = !allSelectedOptions.length ? options : allSelectedOptions[-1]?.options;
    const currentSelection = previousOptions?.find((option) => option.value === selectedValue);
    return [...allSelectedOptions, ...(currentSelection ? [currentSelection] : [])];
  }, []);
}

export const filterOptionsByPath = (
  search: string,
  path: string[],
  options: MultiPickerOptionValue[]
): MultiPickerOptionValue[] => {
  const [pathValue, ...restPath] = path;
  if (!pathValue) {
    const filteredOptions = options.filter(
      (option) => !search.trim() || option.label.toLowerCase().includes(search.toLowerCase())
    );
    return filteredOptions;
  }

  return options.map((option) => {
    return option.value === pathValue
      ? {
          ...option,
          ...(option.options
            ? { options: filterOptionsByPath(search, restPath, option.options) }
            : {}),
        }
      : option;
  });
};
