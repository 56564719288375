import { Box, HStack } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";

type BrowserFrameProps = PropsWithChildren;
function BrowserFrame({ children }: BrowserFrameProps) {
  return (
    <Box>
      <Box borderWidth="1px" borderBottomWidth={0} borderColor="gray.300" borderTopRadius="lg">
        <HStack spacing={2} p={2} justifyContent="flex-end">
          <Box w="10px" h="10px" borderWidth="1px" borderColor="gray.300" borderRadius="full" />
          <Box w="10px" h="10px" borderWidth="1px" borderColor="gray.300" borderRadius="full" />
          <Box w="10px" h="10px" borderWidth="1px" borderColor="gray.300" borderRadius="full" />
        </HStack>
      </Box>

      <Box p={4} borderWidth="1px" borderColor="gray.300" borderBottomRadius="lg">
        {children}
      </Box>
    </Box>
  );
}

export default BrowserFrame;
