import { GridItem, SimpleGrid } from "@chakra-ui/react";

import FileListItem from "../FileListItem";
import type {
  FileUploaderFileUrl,
  FileUploaderObjectRendererProps,
} from "../FileUploader/constants/types";

function FileListViewer({
  fileUrls,
  onDeleteFile,
  onDownloadFile,
}: FileUploaderObjectRendererProps) {
  return (
    <SimpleGrid columns={1} columnGap={3} rowGap={2} w="full" py={2}>
      {fileUrls
        .map((fileUrl: FileUploaderFileUrl) =>
          typeof fileUrl === "string" ? fileUrl : fileUrl.file.name
        )
        .map((filePath, index) => (
          <GridItem key={filePath}>
            <FileListItem
              filePath={filePath}
              onDownloadFile={() => {
                onDownloadFile?.(index);
              }}
              onDeleteFile={
                onDeleteFile &&
                (() => {
                  onDeleteFile(index);
                })
              }
            />
          </GridItem>
        ))}
    </SimpleGrid>
  );
}

export default FileListViewer;
