import { useApolloClient } from "@apollo/client";
import type { TableContainerProps } from "@chakra-ui/react";
import {
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from "@chakra-ui/react";
import type {
  AllTeamsQueryHookResult,
  TeamFieldsFragment,
} from "@flows-platform/generated/graphql";
import { useDeleteTeamClientMutation } from "@flows-platform/generated/graphql";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import toastSettings from "@flows-platform/theme/settings";
import ContextMenu from "@kwest_fe/core/src/components/UI/organisms/ContextMenu";
import { useTranslation } from "react-i18next";

interface TeamOverviewTableProps {
  teams: AllTeamsQueryHookResult["data"];
  onEditTeam: (team: TeamFieldsFragment) => void;
}

const tableContainerProps: TableContainerProps = {
  w: "full",
  borderRadius: "xl",
  padding: 3,
  border: 1,
  borderColor: "gray.100",
  borderStyle: "solid",
};

export default function TeamOverviewTable({ teams, onEditTeam }: TeamOverviewTableProps) {
  const { myMembership } = useMyMembership();
  const { t } = useTranslation();
  const client = useApolloClient();
  const toast = useToast();

  const [deleteTeam] = useDeleteTeamClientMutation();
  const rows = !teams?.allTeams?.length ? (
    <Tr>
      <Td colSpan={3}> No teams</Td>
    </Tr>
  ) : (
    teams.allTeams.map((team, i) => {
      if (!team) return null;
      const borderWidth = i === teams.allTeams!.length - 1 ? "0px" : "1px";

      return (
        <Tr key={team.id}>
          <Td borderBottomWidth={borderWidth}>
            <HStack spacing={3}>
              <VStack alignItems="flex-start" spacing={0}>
                <Text fontWeight="semibold" fontSize={"14px"}>
                  {team.name}
                </Text>
              </VStack>
            </HStack>
          </Td>
          <Td borderBottomWidth={borderWidth}>
            <Text fontSize={"14px"}>{team.email}</Text>
          </Td>
          <Td borderBottomWidth={borderWidth}>
            <Text fontSize={"14px"}>{team.phoneNumber}</Text>
          </Td>
          <Td borderBottomWidth={borderWidth}>
            <Text fontSize={"14px"}>{team.externalSystem ? team.externalSystem.name : "-"}</Text>
          </Td>
          {myMembership?.isAdmin && (
            <Td isNumeric borderBottomWidth={borderWidth}>
              <ContextMenu
                onEdit={() => {
                  onEditTeam({
                    id: team.id,
                    name: team.name,
                    email: team.email,
                    phoneNumber: team.phoneNumber,
                    externalSystem: team.externalSystem,
                  });
                }}
                onRemove={() => {
                  deleteTeam({
                    variables: { teamId: team.id },
                    onCompleted: () => {
                      client.refetchQueries({
                        include: "active",
                      });
                      toast({
                        ...toastSettings,
                        status: "success",
                        title: t("pages.team.toast.delete_team.success.title"),
                      });
                    },
                    onError(error) {
                      toast({
                        ...toastSettings,
                        status: "error",
                        title: t("pages.team.toast.delete_team.error.title"),
                        description: error.message,
                      });
                    },
                  });
                }}
              />
            </Td>
          )}
        </Tr>
      );
    })
  );

  return (
    <TableContainer {...tableContainerProps}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{t("pages.team.table.headers.name")}</Th>
            <Th>{t("pages.team.table.headers.email")}</Th>
            <Th>{t("pages.team.table.headers.phone_number")}</Th>
            <Th>{t("pages.team.table.headers.system")}</Th>

            {myMembership?.isAdmin && <Th />}
          </Tr>
        </Thead>

        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  );
}
