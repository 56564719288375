import { useState } from "react";

import { ArrowDownwardIcon, ArrowUpwardIcon } from "@flows-platform/theme/icons";

const removePrefix = (stringWithPrefix: string, prefix: string) => {
  const regex = new RegExp(`^${prefix}*`, "g");
  return stringWithPrefix.replace(regex, "");
};

export default function useTableSort(default_sort: string) {
  const [activeSort, setActiveSort] = useState(default_sort);

  const activeSortWithoutPrefix = activeSort
    .split(",")
    .map((activeSortKey) => removePrefix(activeSortKey, "-"))
    .join(",");

  const sortOrderIcon = (sortKey: string) => {
    if (sortKey !== activeSortWithoutPrefix) return null;
    return activeSort.startsWith("-") ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />;
  };

  const toggleSort = (sortKey: string) => () => {
    if (!activeSort.startsWith("-") && sortKey === activeSortWithoutPrefix) {
      setActiveSort(
        sortKey
          .split(",")
          .map((activeSortKey) => `-${activeSortKey}`)
          .join(",")
      );
    } else {
      setActiveSort(sortKey);
    }
  };

  return { activeSort, sortOrderIcon, toggleSort };
}
