import { Box, HStack, IconButton, Input, Text, VStack } from "@chakra-ui/react";
import { useConnectedFlow } from "@flows-platform/context/ConnectedFlow";
import { useFlowVariables } from "@flows-platform/context/FlowVariables";
import type { VariableType } from "@flows-platform/types";
import omitFields from "@flows-platform/utils/omitFields";
import FormikSelectField from "@kwest_fe/core/src/components/FormikSelectField/FormikSelectField";
import CloseButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/CloseButton";
import { AddIcon } from "@kwest_fe/core/src/theme/icons";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import type { StylesConfig } from "react-select";

const ProjectPropertySelectorStyles: StylesConfig = {
  control: (baseStyles) => ({
    ...baseStyles,
    borderColor: "gray.200",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    fontSize: "14px",
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: "2px 6px",
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    backgroundColor: "transparent",
  }),
};

interface ProjectPropertySelectorProps {
  name: string;
  type: string;
}

function ProjectPropertySelector({ name, type }: ProjectPropertySelectorProps) {
  const context = useFlowVariables();
  const { variables = {} } = context || {};

  return (
    <FormikSelectField
      styles={ProjectPropertySelectorStyles}
      name={name}
      isSearchable
      options={Object.keys(variables)
        .filter((identifier) => variables[identifier].type === type)
        .map((identifier) => ({
          label: variables[identifier].label,
          value: identifier,
        }))}
    />
  );
}

export default function ConnectedFlowVariables() {
  const [{ value }, , { setValue }] = useField("task.connectedFlowInitialVariableValues");

  const { availableTemplates, connectedFlow } = useConnectedFlow();

  const { t } = useTranslation();

  const selectedFlowTemplate = availableTemplates?.find(
    (result) => result?.id === connectedFlow.id
  );

  const allConnectedFlowSearchableVariables: Record<string, VariableType> = JSON.parse(
    selectedFlowTemplate?.searchableVariables || "{}"
  );

  const variableIdentifiers = Object.keys(allConnectedFlowSearchableVariables);

  if (!variableIdentifiers.length) return null;

  const propertyIsSet = (variableIdentifier: string) => value && variableIdentifier in value;

  return (
    <VStack spacing={5} w="full" pb={10}>
      <Text fontSize="md">
        {t("pages.flows_templates.flows_in_project.connect_flow_variables")}
      </Text>
      <HStack w="full" spacing={3}>
        <Box flex={1}>
          <Text fontSize="md" fontWeight="medium">
            Flow Variables
          </Text>
        </Box>
        <Box flex={1}>
          <Text fontSize="md" fontWeight="medium">
            Project Properties
          </Text>
        </Box>
      </HStack>
      {Object.keys(allConnectedFlowSearchableVariables).map((variableIdentifier) => (
        <HStack w="full" key={variableIdentifier} spacing={4}>
          <Box flex={1}>
            <Input
              value={allConnectedFlowSearchableVariables[variableIdentifier].label}
              disabled
              background="gray.100"
            />
          </Box>
          <Box flex={1}>
            {propertyIsSet(variableIdentifier) && (
              <HStack>
                <ProjectPropertySelector
                  type={allConnectedFlowSearchableVariables[variableIdentifier].type}
                  name={`task.connectedFlowInitialVariableValues.[${variableIdentifier}]`}
                />
                <CloseButton
                  onClick={() => {
                    setValue(omitFields(value, [variableIdentifier]));
                  }}
                />
              </HStack>
            )}
            {!propertyIsSet(variableIdentifier) && (
              <IconButton
                aria-label="Add linked project property"
                variant="outline"
                size="sm"
                onClick={() => {
                  setValue({
                    ...(value || {}),
                    [variableIdentifier]: "",
                  });
                }}
              >
                <AddIcon />
              </IconButton>
            )}
          </Box>
        </HStack>
      ))}
    </VStack>
  );
}
