import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import type { ProjectStepFieldsFragment } from "@flows-platform/generated/graphql";
import { useUpdateCustomTaskClientMutation } from "@flows-platform/generated/graphql";
import ConnectedFlowsView from "@flows-platform/modules/Shared/components/ConnectedFlowsView/ConnectedFlowsView";
import SubTasksView from "@flows-platform/modules/Shared/components/SubtasksView/SubtasksView";
import { useProjectSteps } from "@flows-platform/modules/Shared/providers/ProjectStepContext";
import FlowConnectedBadge from "@flows-platform/modules/Shared/UI/atoms/Decorations/Shortcut/FlowConnectedBadge";
import { CustomTaskIcon } from "@flows-platform/theme/icons";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import HTMLContentRenderer from "@kwest_fe/core/src/components/HTMLContentRenderer";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ChatWindow from "./components/ChatWindow";
import ProjectStepModalMenu from "./components/ProjectStepModalMenu/ProjectStepModalMenu";
import StatusBar from "./components/StatusBar/StatusBar";

interface ProjectStepModalProps {
  step: ProjectStepFieldsFragment;
  description?: string | null;
  isChecked: boolean;
  isOpen: boolean;
  onClose: () => void;
  onToggle: () => Promise<void>;
}

export default function ProjectStepModal({
  step,
  isChecked,
  isOpen,
  onClose,
  onToggle,
}: ProjectStepModalProps) {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation();
  const { onUpdate } = useProjectSteps();
  const { errorToast } = useDefaultToasts();
  const [updateCustomTask, { loading }] = useUpdateCustomTaskClientMutation({
    onCompleted() {
      setIsEditing(false);
      onUpdate();
    },
    onError(e) {
      errorToast(t(`pages.project_instance.toast.update_custom_task.error.title`))({
        message: e.message,
      });
    },
  });

  const { name: stepName, task, id: stepId, orderedSubtasks } = step;

  return (
    <Modal size="xl" isOpen={isOpen} isCentered onClose={onClose} key={stepId}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader padding={6} display={"flex"} gap={4} alignItems={"center"}>
          <HStack spacing={4} flexGrow={1}>
            <Text textStyle={TEXT_STYLES.h1}>{stepName}</Text>
            {task?.connectedFlowId && <FlowConnectedBadge />}
            {task?.isCustom && <CustomTaskIcon />}
          </HStack>
          {task?.isCustom && (
            <ProjectStepModalMenu
              taskId={task.id}
              modalOnClose={() => {
                onClose();
                onUpdate();
              }}
            />
          )}
        </ModalHeader>
        <ModalBody padding={0}>
          {task && (
            <StatusBar
              stepId={stepId}
              task={{
                ...task,
                receiver: task.receiver?.id
                  ? {
                      id: task.receiver?.id,
                      firstName: task.receiver?.firstName || "",
                      lastName: task.receiver?.lastName || "",
                      _typename: "MembershipType" as const,
                    }
                  : undefined,
                receiverTeam: task.receiverTeam?.id
                  ? {
                      id: task.receiverTeam?.id,
                      name: task.receiverTeam?.name || "",
                      _typename: "TeamType" as const,
                    }
                  : undefined,
              }}
              isChecked={isChecked}
              onToggle={onToggle}
            />
          )}
          {!isEditing && (
            <Box padding={6} gap={6} display="flex" alignItems="flex-start" flexDirection="column">
              <Box
                w="full"
                _hover={
                  task?.isCustom
                    ? {
                        cursor: "pointer",
                        backgroundColor: "gray.100",
                      }
                    : undefined
                }
                onClick={() => {
                  if (task?.isCustom) setIsEditing(true);
                }}
              >
                <HTMLContentRenderer htmlContent={task?.content} />
                {!task?.content && task?.isCustom && (
                  <Text fontSize="md" color="gray.500">
                    {t("pages.project_instance.placeholders.custom_task.content")}
                  </Text>
                )}
              </Box>
              {(orderedSubtasks?.length || 0) > 0 && <SubTasksView subTasks={orderedSubtasks} />}
              {task?.connectedFlowId && task.connectedJourneysId && stepId && (
                <ConnectedFlowsView
                  task={{
                    id: task.id,
                    connectedFlowId: task.connectedFlowId,
                    connectedFlowInitialVariableValues: task.connectedFlowInitialVariableValues,
                    connectedJourneysId: task.connectedJourneysId as string[],
                  }}
                />
              )}
            </Box>
          )}
          {isEditing && (
            <Formik
              initialValues={{ content: task?.content }}
              onSubmit={({ content }) => {
                updateCustomTask({
                  variables: {
                    input: {
                      id: task?.id,
                      content,
                    },
                  },
                });
              }}
            >
              {({ isValid, submitForm }) => (
                <>
                  <ModalBody padding={6}>
                    <RichTextEditor name="content" />
                  </ModalBody>
                  <ModalFooter borderTopWidth={1}>
                    <Button
                      mr={3}
                      onClick={() => {
                        setIsEditing(false);
                      }}
                      disabled={loading}
                    >
                      {t("global.actions.cancel")}
                    </Button>
                    <Button
                      onClick={submitForm}
                      isDisabled={!isValid || loading}
                      isLoading={loading}
                      colorScheme="purple"
                    >
                      {t("global.actions.save")}
                    </Button>
                  </ModalFooter>
                </>
              )}
            </Formik>
          )}
          <ChatWindow step={step} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
