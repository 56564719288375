import QueryLoaderContainer from "@kwest_fe/core/src/components/QueryLoaderContainer";

import type { AllAuthenticatorsQuery } from "../../../../generated/graphql";
import { useAllAuthenticatorsQuery } from "../../../../generated/graphql";

interface AuthenticatorListContainerProps {
  render: ({
    allAuthenticators,
  }: {
    allAuthenticators: AllAuthenticatorsQuery["allAuthenticators"];
  }) => JSX.Element;
}

function AuthenticatorListContainer({ render }: AuthenticatorListContainerProps) {
  return (
    <QueryLoaderContainer
      query={useAllAuthenticatorsQuery}
      loadingErrorMessage="Could not load authenticators"
      render={({ data }) =>
        render({
          allAuthenticators: data.allAuthenticators,
        })
      }
    />
  );
}

export default AuthenticatorListContainer;
