import { Box, Table, TableContainer, Tbody, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import Placeholder from "@core/components/UI/molecules/display/Placeholder";
import type { FlowInstanceQuery } from "@core/generated/graphql";
import { JourneyStepStatus } from "@core/generated/graphql";
import { CursorIcon } from "@core/theme/icons";
import type { PropsWithChildren } from "react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import FlowInstanceTableRow from "./FlowInstanceTableRow";

const COLUMN_WIDTH = "150px";

interface FlowInstanceSummaryTableProps {
  flow: FlowInstanceQuery["oneJourney"];
  stepUrl?: (stepId: string) => string;
}

const TableHeader = ({ children }: PropsWithChildren) => (
  <Th
    minW={COLUMN_WIDTH}
    textTransform={"capitalize"}
    fontSize={"18px"}
    fontWeight={500}
    textColor={"tako.input.text"}
  >
    {children}
  </Th>
);

const FlowInstanceSummaryTable = forwardRef(
  (
    {
      flow,
      stepUrl = (stepId: string) => `/todo/${stepId}?back=true`,
    }: FlowInstanceSummaryTableProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();

    const getRows = () => {
      if (!flow) return null;
      return flow.orderedJourneySteps
        ?.filter((step) => step?.status !== JourneyStepStatus.Skipped)
        .map((step, index, journeySteps) => (
          <FlowInstanceTableRow
            step={step}
            index={index}
            journeySteps={journeySteps}
            stepUrl={stepUrl}
          />
        ));
    };

    return (
      <VStack spacing={10} alignItems="flex-start" justifyContent="left" w="full" ref={ref}>
        <TableContainer w="full">
          <Table>
            <Thead mb={4}>
              <Tr>
                <TableHeader>{t("pages.journey.table.headers.step")}</TableHeader>
                <TableHeader>{t("pages.journey.table.headers.sent_out")}</TableHeader>
                <TableHeader>{t("pages.journey.table.headers.completed")}</TableHeader>
                <TableHeader>{t("pages.journey.table.headers.completed_by")}</TableHeader>
              </Tr>
            </Thead>

            <Tbody>{flow && getRows()}</Tbody>
          </Table>
        </TableContainer>
        {!flow && (
          <Box p={4} w="full" alignItems="center">
            <Placeholder
              type="empty-state"
              icon={<CursorIcon />}
              iconSize="lg"
              description={t("pages.journey.placeholders.no_journey_selected")}
            />
          </Box>
        )}

        {!flow?.orderedJourneySteps?.length && (
          <Box p={4} w="full" alignItems="center">
            <Placeholder
              type="empty-state"
              icon={<CursorIcon />}
              iconSize="lg"
              description={t("pages.journey.placeholders.no_journey_steps")}
            />
          </Box>
        )}
      </VStack>
    );
  }
);

export default FlowInstanceSummaryTable;
