import { HStack, Square } from "@chakra-ui/react";
import type { PhaseSettingsFragment } from "@flows-platform/generated/graphql";

import DeletePhase from "../DeletePhase";
import PhaseName from "./PhaseName";
import PhaseReorder from "./PhaseReorder";

interface PhaseRowProps {
  reOrder: (sourcePosition: number, destinationPosition: number) => void;
  phase: PhaseSettingsFragment;
}

const PhaseRow = ({ phase, reOrder }: PhaseRowProps) => {
  return (
    <HStack gap={4} w={"full"} data-testid="phase-row">
      <PhaseReorder position={phase.position} reorderFn={reOrder} />

      <HStack w={"full"} gap={4}>
        <Square size="20px" rounded={"sm"} bg={phase?.color} />
        <PhaseName phase={phase} />
      </HStack>

      {phase.position > 0 && <DeletePhase id={phase?.id} />}
    </HStack>
  );
};

export default PhaseRow;
