import type { FlowEditorDrawerSchema } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/constants/schema";
import resolveObjectKey from "@kwest_fe/core/src/utils/resolveObjectKey";
import type { InferType } from "yup";

import type { FlowStepTypeEnum } from "../../../generated/graphql";
import type { OneOrderedFlowStep } from "../../../types";

export type FlowStepInitialValues = InferType<typeof FlowEditorDrawerSchema>;

interface FlowStepObserverOptions {
  fields: string[];
  onChanged: (modifiedValues: Partial<FlowStepInitialValues>) => Promise<any>;
  flowStepType?: FlowStepTypeEnum;
}

export class FlowStepObserver {
  onChanged: FlowStepObserverOptions["onChanged"];

  fields: FlowStepObserverOptions["fields"];

  flowStepType?: FlowStepObserverOptions["flowStepType"];

  constructor(options: FlowStepObserverOptions) {
    const { fields, onChanged, flowStepType } = options;
    this.fields = fields;
    this.flowStepType = flowStepType;
    this.onChanged = onChanged;
  }

  /**
   * Method that determines whether or not the saved method should be triggered
   */
  shouldSave(flowStep: OneOrderedFlowStep, modifiedValues: Partial<FlowStepInitialValues>) {
    if (this.flowStepType && flowStep?.stepType !== this.flowStepType) {
      return false;
    }
    for (let i = 0; i < this.fields.length; i += 1) {
      // TODO: Compare the resolved value with the previous value before assuming a change occurred.
      if (resolveObjectKey(modifiedValues, this.fields[i]) !== undefined) {
        return true;
      }
    }
    return false;
  }
}

function useFlowStepUpdater(flowStep: OneOrderedFlowStep, observers: FlowStepObserver[]) {
  const updateFlowStep = async (modifiedValues: Partial<FlowStepInitialValues>) =>
    Promise.all(
      observers.map(async (observer) =>
        observer.shouldSave(flowStep, modifiedValues)
          ? observer.onChanged(modifiedValues)
          : Promise.resolve(null)
      )
    );
  return { updateFlowStep };
}

export default useFlowStepUpdater;
