import { ActionEnum } from "@flows-platform/generated/graphql";
import * as Yup from "yup";

const RulesConfigSectionSchema = (allowedVariableIdentifiers: string[]) =>
  Yup.object().shape({
    rules: Yup.array(
      Yup.object({
        conditions: Yup.array(
          Yup.object({
            conditional: Yup.string().required(),
            variable: Yup.string()
              .oneOf(allowedVariableIdentifiers, "Variable needs to be one of the ones available")
              .when("conditional", {
                is: "if",
                then: Yup.string().required("A variable must be selected"),
              }),
            operator: Yup.string().when("conditional", {
              is: "if",
              then: Yup.string().required("Required"),
            }),
            variableValue: Yup.string().required("A variable value must be provided"),
          })
        ),
        action: Yup.string().oneOf(Object.values(ActionEnum)).required(),
        actionValue: Yup.string().required("You must select a step to navigate to"),
      })
    ),
  });

export default RulesConfigSectionSchema;
