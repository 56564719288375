import { useAuth0 } from "@auth0/auth0-react";
import type { MyMembershipQueryResult } from "@flows-platform/generated/graphql";
import { useMyMembershipQuery } from "@flows-platform/generated/graphql";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import { createContext, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MyMembershipContext = createContext<MyMembershipQueryResult["data"] | { myMembership: null }>(
  { myMembership: null }
);

export const GUEST_ROUTES = ["/todo", "/run/\\w+", "/todos"];

function MyMembershipProvider({ children }: { children: React.ReactNode }) {
  const { data, loading, error } = useMyMembershipQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isAuthenticated } = useAuth0();

  if (loading) {
    return <LoadingSpinnerOverlay />;
  }

  if (error) {
    return (
      <Placeholder
        type="empty-state"
        header="Error loading membership"
        description="If the error persists, please contact us using the support button in the side navigation."
      />
    );
  }

  /**
   * Prevent any route other than create-membership from being rendered
   * and throwing errors due to missing membership.
   */
  if (!data?.myMembership && location.pathname !== "/account/create-membership") {
    navigate("/account/create-membership");
    return null;
  }

  /**
   * If no membership exists, let's create one
   */
  if (user?.email_verified && isAuthenticated && !loading && !data?.myMembership) {
    navigate("/account/create-membership");
    return null;
  }

  /**
   * Restrict limited operators to todo route only
   */
  if (user?.email_verified && isAuthenticated && !loading && data?.myMembership?.isGuest) {
    if (location.pathname === "/flow-templates") {
      navigate("/todos");
    }
    const isGuestRoute = GUEST_ROUTES.some((routePath) => location.pathname.match(routePath));

    if (!isGuestRoute) {
      navigate("/access-restricted");
      return null;
    }
  }

  return <MyMembershipContext.Provider value={data}>{children}</MyMembershipContext.Provider>;
}

function useMyMembership() {
  const context = useContext(MyMembershipContext);
  if (context === undefined) {
    throw new Error("useMyMembership must be used within a MyMembershipProvider");
  }
  return context;
}

export { MyMembershipProvider, useMyMembership };
