import makeIdentifier from "@flows-platform/utils/makeIdentifier";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";

export interface VariableToResponseKeyType {
  variableId: string;
  variable: {
    label: string;
    type: string;
  };
  responseKey: string;
}

export function parseVariableToResponseKeys(
  variableToResponseKeysData: string,
  flowStepVariablesData: string
): VariableToResponseKeyType[] {
  const jsonValue = variableToResponseKeysData ? JSON.parse(variableToResponseKeysData) : {};
  const flowStepVariables = flowStepVariablesData ? JSON.parse(flowStepVariablesData) : {};
  return Object.keys(jsonValue)
    .filter((key) => flowStepVariables[key])
    .map((key) => {
      const flowStepVariable = flowStepVariables[key];
      return {
        variableId: key,
        variable: flowStepVariable,
        responseKey: jsonValue[key],
      };
    });
}

export function serializeVariableToResponseKeys(
  variableToResponseKeys: VariableToResponseKeyType[]
) {
  const variableToResponseKeysData = JSON.stringify(
    variableToResponseKeys.reduce(
      (acc, variableToResponseKey) => ({
        ...acc,
        [makeIdentifier(variableToResponseKey.variable.label)]: variableToResponseKey.responseKey,
      }),
      {}
    )
  );
  const flowStepVariablesData = JSON.stringify(
    variableToResponseKeys.reduce(
      (acc, variableToResponseKey) => ({
        ...acc,
        [makeIdentifier(variableToResponseKey.variable.label)]: variableToResponseKey.variable,
      }),
      {}
    )
  );

  return {
    variableToResponseKeys: variableToResponseKeysData,
    flowStepVariables: flowStepVariablesData,
  };
}

export function instantiateNewVariable() {
  const varName = "Variable name";
  return {
    variableId: makeIdentifier(varName),
    variable: {
      type: FlowStepVariableTypeEnum.STRING,
      is_scoped: true,
      label: varName,
    },
    responseKey: "",
  };
}
