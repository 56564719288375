import { Box } from "@chakra-ui/react";
import type {
  JourneyStepFieldsWithProjectTasksSummaryFragment,
  ProjectStepFieldsFragment,
} from "@flows-platform/generated/graphql";

import ProjectStepCheckbox from "./components/ProjectStepCheckbox/ProjectStepCheckbox";

interface ProjectStepsProps {
  projectSteps: (
    | JourneyStepFieldsWithProjectTasksSummaryFragment
    | ProjectStepFieldsFragment
    | null
  )[];
  dense?: boolean;
}

export default function ProjectSteps({ projectSteps, dense = false }: ProjectStepsProps) {
  return (
    <Box display="flex" flexDirection="column" width="100%">
      {projectSteps.map((step, index) => {
        if (!step) return null;
        return (
          <ProjectStepCheckbox
            step={step}
            key={step.id}
            isChecked={step.completedAt !== null}
            isFirst={index === 0}
            isLast={index === projectSteps.length - 1}
            dense={dense}
            hideControls={dense}
          />
        );
      })}
    </Box>
  );
}
