import type { FlexProps } from "@chakra-ui/react";
import { Box, Divider, Flex, Heading, Link as ChakraLink, Text } from "@chakra-ui/react";
import { todoViewerUrl } from "@flows-platform/config/todoViewer";
import DoButton from "@flows-platform/modules/Shared/UI/atoms/Buttons/DoButton";
import isTruthy from "@kwest_fe/core/src/utils/isTruthy";
import { isBefore, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";

import { formatDateShort } from "../../../../../../../../utils/formatDate";

interface InstallerTodoCardProps {
  journey: {
    projectId?: string;
    projectName?: string;
    projectTasks?: {
      taskId: string;
      stepName: string;
      dueDate?: string;
    }[];
    todoSteps?: {
      stepId: string;
      stepName: string;
    }[];
  };
  onProjectClick: () => void;
}

const InstallerTodoCard = ({ journey, onProjectClick }: InstallerTodoCardProps) => {
  const navigate = useNavigate();

  const taskDueDates = journey.projectTasks
    ?.map((task) => task.dueDate)
    .filter(isTruthy)
    .map((dueDate) => parseISO(dueDate));

  const sortedTaskDueDates = taskDueDates?.sort();

  const hasOverdueTask =
    sortedTaskDueDates?.length &&
    sortedTaskDueDates[0] &&
    isBefore(sortedTaskDueDates[0], new Date());

  const overdueTaskStyling: FlexProps = hasOverdueTask
    ? {
        borderWidth: 1,
        borderColor: "red.500",
        bg: "red.50",
      }
    : {};

  return (
    <Flex flexDir={"column"} gap={1}>
      <Flex
        rounded={"sm"}
        flexDirection={"column"}
        bg={"gray.50"}
        p={2}
        m={2}
        {...overdueTaskStyling}
      >
        {journey?.projectName && (
          <Flex flexDirection={"column"}>
            <Flex alignItems="center">
              <Box flexGrow={1}>
                <ChakraLink
                  fontSize={"14px"}
                  color={"gray.700"}
                  fontWeight={600}
                  textDecoration={"underline"}
                  textUnderlineOffset={"3px"}
                  mb={4}
                  onClick={onProjectClick}
                >
                  {journey?.projectName}
                </ChakraLink>
              </Box>
              {sortedTaskDueDates?.length ? (
                <Heading color="red.400" fontSize="md">
                  {formatDateShort(sortedTaskDueDates[0])}
                </Heading>
              ) : null}
            </Flex>
            {journey.projectTasks?.map((task) => (
              <Flex flexDir={"column"} key={task.taskId}>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Text fontSize={"14px"} color={"gray.700"} fontWeight={600} noOfLines={1} mr={12}>
                    {task?.stepName}
                  </Text>
                </Flex>
                <Flex justifyContent={"flex-end"}>
                  <DoButton
                    onClick={() => {
                      navigate(`/projects/tasks/${task?.taskId}`);
                    }}
                  />
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}
        {journey.todoSteps?.map((step) => (
          <Flex flexDir={"column"} key={step.stepId}>
            <Text fontSize={"14px"} color={"gray.700"} fontWeight={600} noOfLines={1} mr={12}>
              {step.stepName}
            </Text>
            <Flex justifyContent={"flex-end"}>
              <DoButton
                onClick={() => {
                  navigate(todoViewerUrl(`todo/${step.stepId}`));
                }}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Divider />
    </Flex>
  );
};

export default InstallerTodoCard;
