import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";

interface KwestAuthContextInterface {
  setAccessToken: (token: string) => void;
  accessToken: string;
}

const KwestAuthContext = createContext<KwestAuthContextInterface | null>(null);

interface KwestAuthProviderProps {}

export function KwestAuthProvider({ children }: PropsWithChildren<KwestAuthProviderProps>) {
  const [accessToken, setAccessToken] = useState("");

  return (
    <KwestAuthContext.Provider
      value={{
        setAccessToken,
        accessToken,
      }}
    >
      {children}
    </KwestAuthContext.Provider>
  );
}

export function useKwestAuth() {
  const context = useContext(KwestAuthContext);
  if (!context) throw new Error("useKwestAuth must be used within a KwestAuthProvider");
  return context;
}
