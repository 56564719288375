import { Heading, HStack } from "@chakra-ui/react";

import { PROJECT_META_DATA_LABEL_WIDTH, PROJECT_META_DATA_LINE_HEIGHT } from "../../../constants";
import TextJourneyVariable from "../ConnectedJourneyMetadata/components/JourneyVariable/components/TextJourneyVariable";

interface MetaDataFieldProps {
  label: string;
  value: string;
}

export default function MetaDataField({ label, value }: MetaDataFieldProps) {
  return (
    <HStack width="100%" alignItems="flex-start">
      <Heading
        fontSize="md"
        textColor="gray.500"
        flex="1"
        maxWidth={PROJECT_META_DATA_LABEL_WIDTH}
        lineHeight={PROJECT_META_DATA_LINE_HEIGHT}
      >
        {label}
      </Heading>
      <TextJourneyVariable value={value} />
    </HStack>
  );
}
