import type { ApolloError } from "@apollo/client";
import { Button, HStack, Tag, Text, useDisclosure, VStack } from "@chakra-ui/react";
import type {
  AllAuthenticatorsQuery,
  AuthenticatorTypeEnum,
} from "@flows-platform/generated/graphql";
import {
  AllAuthenticatorsDocument,
  useDeleteAuthenticatorClientMutation,
} from "@flows-platform/generated/graphql";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { PowerIcon } from "@kwest_fe/core/src/theme/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import DeleteAuthenticatorModal from "./components/DeleteAuthenticatorModal";
import UpdateAuthenticatorModal from "./components/UpdateAuthenticatorModal/UpdateAuthenticatorModal";

interface AuthenticatorListProps {
  allAuthenticators: AllAuthenticatorsQuery["allAuthenticators"];
}

function AuthenticatorList({ allAuthenticators }: AuthenticatorListProps) {
  const [authenticatorId, setAuthenticatorId] = useState(null);
  const [authenticatorType, setAuthenticatorType] = useState<AuthenticatorTypeEnum | undefined>();
  const { successToast, errorToast } = useDefaultToasts();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const {
    onClose: onEditWindowClose,
    isOpen: isEditWindowOpen,
    onOpen: onEditWindowOpen,
  } = useDisclosure();

  const [deleteAuthenticator] = useDeleteAuthenticatorClientMutation({
    refetchQueries: [AllAuthenticatorsDocument],
  });

  const { t } = useTranslation();

  return (
    <>
      {authenticatorId && authenticatorType && (
        <>
          <DeleteAuthenticatorModal
            isOpen={isOpen}
            onClose={onClose}
            onDelete={() => {
              const variables = {
                authenticatorId,
                authenticatorType,
              };
              const onCompleted = () => {
                successToast(t("modals.authenticators.delete.toast.success.title"));
                onClose();
              };
              const onError = (e: ApolloError) => {
                errorToast(t("modals.authenticators.delete.toast.success.title"))(e);
              };
              deleteAuthenticator?.({
                variables,
                onCompleted,
                onError,
              });
            }}
          />
          <UpdateAuthenticatorModal
            allAuthenticators={allAuthenticators}
            isOpen={isEditWindowOpen}
            onClose={onEditWindowClose}
            authId={authenticatorId}
          />
        </>
      )}
      <VStack w="full" gap={3}>
        {allAuthenticators?.map((authenticator) => (
          <HStack
            key={authenticator?.id}
            paddingX={5}
            paddingY={3}
            borderRadius="lg"
            borderWidth={1}
            width="full"
            spacing={3}
          >
            <PowerIcon />
            <HStack flexGrow={1} spacing={4}>
              <Text>{authenticator?.name}</Text>
              <Tag bgColor="green.400" borderRadius="xl" color="white" fontSize="sm">
                {t("global.connected")}
              </Tag>
            </HStack>
            <HStack gap={3}>
              <Button
                variant="outline"
                onClick={() => {
                  setAuthenticatorId(authenticator?.id);
                  setAuthenticatorType(authenticator?.type);
                  onEditWindowOpen();
                }}
              >
                {t("global.actions.edit")}
              </Button>
              <Button
                variant="outline"
                onClick={() => {
                  setAuthenticatorId(authenticator?.id);
                  setAuthenticatorType(authenticator?.type);
                  onOpen();
                }}
              >
                {t("global.actions.delete")}
              </Button>
            </HStack>
          </HStack>
        ))}
      </VStack>
    </>
  );
}

export default AuthenticatorList;
