import { useApolloClient } from "@apollo/client";
import type {
  DuplicateFlowStepClientMutationFn,
  SaveFlowStepClientMutationFn,
  UpdateFlowVariablesClientMutationFn,
} from "@flows-platform/generated/graphql";
import {
  useDuplicateFlowStepClientMutation,
  useSaveFlowStepClientMutation,
  useUpdateFlowVariablesClientMutation,
} from "@flows-platform/generated/graphql";
import type { OneOrderedFlowStep } from "@flows-platform/types";
import omitFields from "@flows-platform/utils/omitFields";
import setItemPosition from "@flows-platform/utils/setItemPosition";
import type { Dispatch, SetStateAction } from "react";
import { createContext, useCallback, useContext, useMemo, useState } from "react";

import {
  getFlowStepById,
  insertStep,
  readFlowFromCache,
  removeStep,
  saveFlow,
  updateFlowstepFields,
  updateStep,
  writeUpdatedFlowToCache,
} from "./operations";

interface FlowContextInterface {
  activeFlowStepIndex?: number;
  currentFlow: ReturnType<typeof readFlowFromCache>;
  insertStep: typeof insertStep;
  removeStep: typeof removeStep;
  updateStep: typeof updateStep;
  updateFlowstepFields: typeof updateFlowstepFields;
  saveFlow: typeof saveFlow;
  saveFlowStep: SaveFlowStepClientMutationFn;
  activeFlowStep: OneOrderedFlowStep;
  updateActiveFlowStep: (fields?: Partial<OneOrderedFlowStep & { id: string }> | null) => void;
  setActiveFlowStep: Dispatch<SetStateAction<OneOrderedFlowStep>>;
  readFlowFromCache: typeof readFlowFromCache;
  getFlowStepById: typeof getFlowStepById;
  updateFlowVariables: UpdateFlowVariablesClientMutationFn;
  duplicateStep: DuplicateFlowStepClientMutationFn;
}

const FlowContext = createContext<FlowContextInterface | null>(null);

function FlowEditorProvider({ children }: React.PropsWithChildren) {
  const client = useApolloClient();
  const [activeFlowStep, setActiveFlowStep] = useState<OneOrderedFlowStep>(null);
  const [updateFlowVariables] = useUpdateFlowVariablesClientMutation();
  const [duplicateStep] = useDuplicateFlowStepClientMutation({
    update(cache, { data }) {
      const { newFlowStep } = data?.duplicateFlowStep || {};
      if (newFlowStep) {
        const flowId = newFlowStep?.flow.id;
        const oneFlow = readFlowFromCache({ client: cache, flowId });
        const allFlowSteps = Array.from(oneFlow?.orderedFlowSteps || []);
        allFlowSteps.splice(newFlowStep.position, 0, newFlowStep);
        writeUpdatedFlowToCache({
          client: cache,
          flowId,
          updatedFields: {
            orderedFlowSteps: setItemPosition(allFlowSteps),
          },
        });
      }
    },
  });

  const [saveFlowStep] = useSaveFlowStepClientMutation({
    update(_, { data }) {
      if (activeFlowStep?.flow)
        writeUpdatedFlowToCache({
          client,
          flowId: activeFlowStep.flow.id,
          updatedFields: data?.saveFlowStep?.flowStep?.flow,
        });
    },
  });

  const updateActiveFlowStep = useCallback(
    (updatedFlowStepFields?: Partial<OneOrderedFlowStep & { id: string }> | null) => {
      setActiveFlowStep((prevState) =>
        !prevState
          ? prevState
          : {
              ...prevState,
              ...(updatedFlowStepFields ? omitFields(updatedFlowStepFields, ["flow"]) : {}),
            }
      );
    },
    []
  );

  const currentFlow =
    activeFlowStep?.flow &&
    readFlowFromCache({
      client,
      flowId: activeFlowStep.flow.id,
    });

  const activeFlowStepIndex = currentFlow?.orderedFlowSteps?.findIndex(
    (flowStep) => flowStep?.id === activeFlowStep?.id
  );

  const value = useMemo(
    () => ({
      activeFlowStepIndex,
      currentFlow,
      insertStep,
      removeStep,
      updateStep,
      updateFlowstepFields,
      saveFlow,
      saveFlowStep,
      activeFlowStep,
      setActiveFlowStep,
      updateActiveFlowStep,
      updateFlowVariables,
      readFlowFromCache,
      getFlowStepById,
      duplicateStep,
    }),
    [
      activeFlowStepIndex,
      currentFlow,
      activeFlowStep,
      updateActiveFlowStep,
      updateFlowVariables,
      duplicateStep,
      saveFlowStep,
    ]
  );

  return <FlowContext.Provider value={value}>{children}</FlowContext.Provider>;
}

function useFlowEditor() {
  const context = useContext(FlowContext);
  if (!context) {
    throw new Error("useFlow must be used within a FlowProvider");
  }
  return context;
}

export { FlowEditorProvider, useFlowEditor };
