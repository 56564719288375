import { Box, Button, HStack, Input, VStack } from "@chakra-ui/react";
import type { AllSearchableFieldsQuery } from "@flows-platform/generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import type { Filter, SingleOption } from "../../types";
import GenericFilterDropdown from "../GenericFilterDropdown/GenericFilterDropdown";

interface FilterByFieldProps {
  selectedFilter: Filter | null;
  onClose: () => void;
  onSaveFilter: (field: string, content?: string) => void;
  allSearchableFields: AllSearchableFieldsQuery["allSearchableFields"];
}

export default function FilterByField({
  allSearchableFields,
  selectedFilter,
  onClose,
  onSaveFilter,
}: FilterByFieldProps) {
  const [content, setContent] = useState<string | undefined>(selectedFilter?.value);

  const { t } = useTranslation();

  const fields =
    allSearchableFields?.map((field) => ({
      label: field?.label || "",
      value: field?.identifier || "",
    })) || [];

  const selectedField = useMemo(
    () => fields.find((field) => field.value === selectedFilter?.parameters?.fieldName),
    [fields, selectedFilter]
  );

  const applyFilter = useCallback(() => {
    if (content?.length === 0) return;
    if (!selectedField) return;
    onSaveFilter(selectedField.value, content);
    onClose();
  }, [content, onSaveFilter, onClose, selectedField]);

  const onFieldChanged = useCallback((field: SingleOption) => {
    if (field) {
      onSaveFilter(field.value);
    }
  }, []);

  return (
    <VStack px={3} paddingBottom={1} alignItems="flex-end" gap={1} w="full">
      {!selectedField && (
        <Box w="full">
          <GenericFilterDropdown
            placeholder={t("pages.projects.placeholders.field")}
            options={fields}
            onChange={onFieldChanged}
            onBlur={onClose}
          />
        </Box>
      )}
      {selectedField && (
        <Input
          name="filterFieldBy"
          placeholder="Enter"
          defaultValue={content}
          onKeyUp={(e) => {
            if (e.key === "Enter") applyFilter();
          }}
          onChange={(ev) => {
            setContent(ev.target.value);
          }}
        />
      )}
      {selectedField && (
        <HStack gap={3}>
          <Button
            variant="outline"
            onClick={() => {
              onClose();
              setContent(selectedFilter?.value);
            }}
          >
            {t("pages.projects.filters.cancel")}
          </Button>
          <Button colorScheme="purple" onClick={applyFilter}>
            {t("pages.projects.filters.apply")}
          </Button>
        </HStack>
      )}
    </VStack>
  );
}
