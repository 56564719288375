import { HStack, Text } from "@chakra-ui/react";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import { ArticleIcon } from "@kwest_fe/core/src/theme/icons";

interface IDocumentProps {
  doc: {
    name: string;
    url: string;
  };
}
const Document = ({ doc }: IDocumentProps) => {
  return (
    <a href={doc.url} target="_blank" rel="noreferrer">
      <HStack
        w="full"
        overflow="hidden"
        alignItems="center"
        borderWidth={1}
        borderColor="gray.200"
        borderRadius={4}
        py={1}
        px={2}
        backgroundColor="white"
        _hover={{
          backgroundColor: "gray.200",
        }}
        cursor="pointer"
        data-group
      >
        <ArticleIcon />
        <Text
          flexGrow={1}
          textStyle={TEXT_STYLES.lead14Normal}
          display="block"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          _groupHover={{
            textDecoration: "underline",
          }}
          title={doc.name}
        >
          {doc.name}
        </Text>
      </HStack>
    </a>
  );
};

export default Document;
