import { Box, Container, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";

interface BannerProps extends PropsWithChildren {
  bg: string;
}

function Banner({ children, bg = "bg-surface" }: BannerProps) {
  return (
    <Box as="section" pos="absolute" left={0} right={0} bottom={0}>
      <Box bg={bg} boxShadow={useColorModeValue("sm", "sm-dark")}>
        <Container py={3}>
          <Stack alignItems="center">
            <Text fontWeight="medium" color="white">
              {children}
            </Text>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default Banner;
