import { Flex, Spinner } from "@chakra-ui/react";

const ComponentSpinner = () => {
  return (
    <Flex w="full" justifyContent={"center"}>
      <Spinner color="purple.300" />
    </Flex>
  );
};

export default ComponentSpinner;
