import * as Yup from "yup";

export default {
  approval: Yup.object({
    receiverId: Yup.string().nullable(),
    receiverRole: Yup.string(),
    content: Yup.string(),
    returnToStepId: Yup.string(),
  }).nullable(),
};
