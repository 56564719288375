const textStylesConfig = {
  h1: {
    fontSize: 18,
    fontWeight: 700,
    color: "gray.700",
  },
  h2: {
    fontSize: 16,
    fontWeight: 700,
    color: "gray.700",
  },
  h3: {
    fontSize: 12,
    fontWeight: 700,
    color: "gray.600",
    textTransform: "uppercase",
  },
  lead14SemiBold: {
    fontSize: 14,
    fontWeight: 600,
    color: "gray.700",
  },
  lead14Medium: {
    fontSize: 14,
    fontWeight: 500,
    color: "gray.700",
  },
  lead14Normal: {
    fontSize: 14,
    fontWeight: 400,
    color: "gray.700",
  },
  small12: {
    fontSize: 12,
    fontWeight: 400,
    color: "gray.500",
  },
  checkbox: {
    fontSize: "14px",
    fontWeight: 600,
    color: "gray.600",
  },
};

export default textStylesConfig;

export const TEXT_STYLES = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  lead14SemiBold: "lead14SemiBold",
  lead14Medium: "lead14Medium",
  lead14Normal: "lead14Normal",
  small12: "small12",
  checkbox: "checkbox",
};
