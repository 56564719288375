import { Box, CircularProgress, Text } from "@chakra-ui/react";
import type {
  JourneyStepFieldsWithProjectTasksSummaryFragment,
  ProjectStepFieldsFragment,
} from "@flows-platform/generated/graphql";
import { useMemo } from "react";

type SubtasksType =
  | NonNullable<JourneyStepFieldsWithProjectTasksSummaryFragment["task"]>["orderedSubtasks"]
  | ProjectStepFieldsFragment["orderedSubtasks"];

const calculateSubTaskSteps = (subTasks: SubtasksType) => {
  if (!subTasks) return { taskCompletionPercentage: 0, totalTasks: 0, completedTasks: 0 };

  const totalTasks = subTasks.length || 0;
  const completedTasks = subTasks.filter((j) => j?.isDone).length || 0;
  const taskCompletionPercentage = totalTasks > 0 ? (completedTasks / totalTasks) * 100 : 0;
  return { taskCompletionPercentage, totalTasks, completedTasks };
};

export default function SubtaskProgress({ subTasks }: { subTasks: SubtasksType }) {
  const { taskCompletionPercentage, totalTasks, completedTasks } = useMemo(
    () => calculateSubTaskSteps(subTasks),
    [subTasks]
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      border="1px"
      rounded="md"
      borderColor="gray.200"
      p={0.5}
      bg="white"
    >
      <CircularProgress value={taskCompletionPercentage} size="20px" color="green.500" />
      <Text color="gray.500" fontSize="14px" px={1}>
        {completedTasks}/{totalTasks}
      </Text>
    </Box>
  );
}
