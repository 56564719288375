import { useNavigate } from "react-router-dom";

const useRedirectToNextStep = () => {
  const navigate = useNavigate();

  const redirectToNextStep = (
    step: { id?: string | null } | null | undefined,
    projectId: string | null | undefined
  ) => {
    if (step) {
      const stepId = step.id;
      navigate(`/todo/${stepId || ""}`);
    } else if (projectId) {
      navigate(`/todo/complete?projectId=${projectId}`);
    } else {
      navigate(`/todo/complete`);
    }
  };

  return redirectToNextStep;
};

export default useRedirectToNextStep;
