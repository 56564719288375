import { Alert, Box, HStack, Text } from "@chakra-ui/react";
import { WarningIcon } from "@core/theme/icons";

interface WarningAlertProps {
  message: string;
}

export default function WarningAlert({ message }: WarningAlertProps) {
  return (
    <Alert status="warning" mb={6} borderRadius="lg" variant="custom-warning">
      <HStack alignItems="flex-start" spacing={4}>
        <Box pt={1}>
          <WarningIcon />
        </Box>
        <Text fontSize="md">{message}</Text>
      </HStack>
    </Alert>
  );
}
