import { Box, Button, VStack } from "@chakra-ui/react";
import EditableTodoItem from "@flows-platform/modules/Shared/components/EditableTodoItem";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import type { OrderedTodoItems } from "@flows-platform/types";
import ReceiverAssignment from "@kwest_fe/core/src/components/ReceiverAssignment/ReceiverAssignment";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import { useField } from "formik";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AiOutlinePlus as PlusIcon } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";

import BrowserFrame from "./components/BrowserFrame";

const TODO_ITEMS_FORM_KEY = "todoList.orderedTodoItems";

function TodoListEditor() {
  const { addRoleVariable, recipients, variableStore } = useFlowEditorDrawer() || {};
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
  const [{ value: orderedTodoItems }, , { setValue: setOrderedTodoItems }] =
    useField<OrderedTodoItems>(TODO_ITEMS_FORM_KEY);

  if (!recipients || !addRoleVariable) return <></>;

  return (
    <DndProvider backend={HTML5Backend}>
      <ReceiverAssignment
        parent="todoList"
        roleRecipients={recipients}
        onRoleCreated={addRoleVariable}
      />
      <Box py={6}>
        <BrowserFrame>
          <Box pb={6}>
            <RichTextEditor
              name="todoList.caption"
              placeholder="Write a description of the list here"
              minHeight={200}
              hideBorder
              variables={variableStore}
            />
          </Box>

          <VStack spacing={3} alignItems="flex-start">
            {orderedTodoItems?.map(
              (todoItem, index) =>
                todoItem && (
                  <EditableTodoItem
                    key={todoItem.id}
                    todoItem={todoItem}
                    index={index}
                    parent={TODO_ITEMS_FORM_KEY}
                  />
                )
            )}
            <Button
              onClick={() => {
                setOrderedTodoItems([
                  ...(orderedTodoItems || []),
                  {
                    id: uuidv4(),
                    name: "",
                    resourceUrl: "",
                    resourceDisplayName: "",
                    description: "",
                    isDone: false,
                  },
                ]);
              }}
              variant="ghost"
              colorScheme="purple"
              leftIcon={<PlusIcon />}
            >
              Add task
            </Button>
          </VStack>
        </BrowserFrame>
      </Box>
    </DndProvider>
  );
}

export default TodoListEditor;
