import { Button, Text } from "@chakra-ui/react";
import { PreviewIcon } from "@kwest_fe/core/src/theme/icons";
import { useTranslation } from "react-i18next";

interface IPreviewButtonProps {
  onClick?: () => void;
}
const PreviewButton = ({ onClick }: IPreviewButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button variant="outline" leftIcon={<PreviewIcon size="sm" />} px={2} onClick={onClick}>
      <Text fontSize={"14px"}>{t("forms.preview.label")}</Text>
    </Button>
  );
};

export default PreviewButton;
