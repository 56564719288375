import { Box, Flex, Text } from "@chakra-ui/react";
import type { ProjectStepAwaitingUserFieldsFragment } from "@flows-platform/generated/graphql";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ProjectStepCard from "./components/ProjectStepCard/ProjectStepCard";

interface ProjectStepCardsBoardProps {
  projects: (ProjectStepAwaitingUserFieldsFragment | null)[];
}
const ProjectStepCardsBoard = ({ projects }: ProjectStepCardsBoardProps) => {
  const { t } = useTranslation();
  if (!projects?.length)
    return (
      <Box p={5}>
        <Text fontSize={"sm"}>{t("pages.inbox.table.empty_message")}</Text>
      </Box>
    );
  return (
    <Box p={2} display={"flex"} flexDir={"column"} gap={2}>
      <>
        {projects?.map((project) => {
          if (!project) return null;
          return (
            <Box
              p={1}
              key={project.id}
              rounded={"sm"}
              border={"gray.300"}
              borderWidth={"1px"}
              display={"flex"}
              flexDir={"column"}
              gap={2}
            >
              <Flex gap={1} alignItems="center" ml={2}>
                <Link to={`/projects/${project.id}`}>
                  <Text fontWeight={"semibold"} fontSize={"md"} pt={1}>
                    {project.instanceName
                      ? `${project.flow.name}: ${project.instanceName}`
                      : project.flow.name}
                  </Text>
                </Link>
              </Flex>

              {project?.journeySteps?.map((step) =>
                step ? (
                  <ProjectStepCard projectStep={step} projectId={project.id} key={step.id} />
                ) : null
              )}

              {!!project?.totalPendingSteps && project.totalPendingSteps > 1 && (
                <Text fontSize={"12px"} color={"gray.800"}>
                  +{project.totalPendingSteps - 1} other{" "}
                  {project.totalPendingSteps > 2 ? "items" : "item"}
                </Text>
              )}
            </Box>
          );
        })}
      </>
    </Box>
  );
};

export default ProjectStepCardsBoard;
