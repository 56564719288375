/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: { input: any; output: any; }
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: { input: any; output: any; }
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: { input: any; output: any; }
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: { input: any; output: any; }
};

export type AbstractStepModelType = {
  __typename?: 'AbstractStepModelType';
  allVariables?: Maybe<Scalars['JSONString']['output']>;
  approvalId?: Maybe<Scalars['String']['output']>;
  documentId?: Maybe<Scalars['String']['output']>;
  filteredVariables?: Maybe<Scalars['JSONString']['output']>;
  formId?: Maybe<Scalars['String']['output']>;
  httpRequestId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  initialVariables?: Maybe<Scalars['JSONString']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  rulesChain?: Maybe<Array<Maybe<StepRuleType>>>;
  scopeVariables?: Maybe<Scalars['JSONString']['output']>;
  searchableVariables?: Maybe<Scalars['JSONString']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
  variableCategories?: Maybe<Array<VariableCategoryType>>;
  variableStore?: Maybe<Scalars['JSONString']['output']>;
  variables?: Maybe<Scalars['JSONString']['output']>;
};


export type AbstractStepModelTypeFilteredVariablesArgs = {
  filterParams?: InputMaybe<Scalars['JSONString']['input']>;
};

/** An enumeration. */
export enum ActionEnum {
  JumpTo = 'JUMP_TO',
  SetValue = 'SET_VALUE'
}

export type ActivateFlowInputType = {
  connectedTaskId?: InputMaybe<Scalars['String']['input']>;
  flowId: Scalars['String']['input'];
  instanceName?: InputMaybe<Scalars['String']['input']>;
  journeyStartDate: Scalars['Date']['input'];
  variables: Scalars['JSONString']['input'];
};

export type ActivateFlowMutation = {
  __typename?: 'ActivateFlowMutation';
  journey?: Maybe<JourneyType>;
  nextStep?: Maybe<JourneyStepType>;
};

export type AddCustomTaskInputType = {
  afterStepId?: InputMaybe<Scalars['String']['input']>;
  beforeStepId?: InputMaybe<Scalars['String']['input']>;
  connectedFlowId?: InputMaybe<Scalars['String']['input']>;
  connectedFlowInitialVariableValues?: InputMaybe<Scalars['JSONString']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  journeyId: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orderedSubtasks?: InputMaybe<Array<InputMaybe<SubtaskInputType>>>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  receiverRole?: InputMaybe<Scalars['String']['input']>;
  receiverTeamId?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a custom task attached to a journey step */
export type AddCustomTaskMutation = {
  __typename?: 'AddCustomTaskMutation';
  task?: Maybe<TaskResourceType>;
};

export type AddJourneyTagMutation = {
  __typename?: 'AddJourneyTagMutation';
  journey?: Maybe<JourneyType>;
  workspace?: Maybe<WorkspaceType>;
};

export type AddSlackIntegrationMutation = {
  __typename?: 'AddSlackIntegrationMutation';
  state?: Maybe<Scalars['String']['output']>;
};

export type ApprovalInputType = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  receiverId?: InputMaybe<Scalars['String']['input']>;
  receiverRole?: InputMaybe<Scalars['String']['input']>;
  receiverTeamId?: InputMaybe<Scalars['String']['input']>;
  returnToStepId?: InputMaybe<Scalars['String']['input']>;
};

export type ApprovalResourceType = {
  __typename?: 'ApprovalResourceType';
  ReturnToStepId?: Maybe<Scalars['UUID']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  flowStep?: Maybe<FlowStepType>;
  id: Scalars['UUID']['output'];
  journeyStep?: Maybe<JourneyStepType>;
  receiver?: Maybe<MembershipType>;
  receiverId?: Maybe<Scalars['String']['output']>;
  receiverRole?: Maybe<Scalars['String']['output']>;
  receiverTeam?: Maybe<TeamType>;
  receiverTeamId?: Maybe<Scalars['String']['output']>;
  rejectedInApproval?: Maybe<ApprovalResourceType>;
  rejectionComment?: Maybe<Scalars['String']['output']>;
  returnToStep?: Maybe<AbstractStepModelType>;
  shouldBeCompleted?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Scalars['JSONString']['output'];
};

export type ApproveStepInputType = {
  id: Scalars['String']['input'];
};

export type ApproveStepMutation = {
  __typename?: 'ApproveStepMutation';
  approval?: Maybe<ApprovalResourceType>;
  nextStep?: Maybe<JourneyStepType>;
};

export type AuthenticatorBasicType = AuthenticatorType & {
  __typename?: 'AuthenticatorBasicType';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  password?: Maybe<Scalars['String']['output']>;
  type: AuthenticatorTypeEnum;
  username?: Maybe<Scalars['String']['output']>;
};

export type AuthenticatorBearerType = AuthenticatorType & {
  __typename?: 'AuthenticatorBearerType';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
  type: AuthenticatorTypeEnum;
};

export type AuthenticatorInputType = {
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthenticatorTypeEnum>;
};

export type AuthenticatorOAuth2Type = AuthenticatorType & {
  __typename?: 'AuthenticatorOAuth2Type';
  authorizationFlow: Scalars['String']['output'];
  clientId?: Maybe<Scalars['String']['output']>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  scope?: Maybe<Scalars['String']['output']>;
  type: AuthenticatorTypeEnum;
  urlToken?: Maybe<Scalars['String']['output']>;
};

export type AuthenticatorType = {
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  type: AuthenticatorTypeEnum;
};

/** An enumeration. */
export enum AuthenticatorTypeEnum {
  Basic = 'BASIC',
  Bearer = 'BEARER',
  Oauth2 = 'OAUTH2'
}

export type AuthenticatorUnionType = AuthenticatorBasicType | AuthenticatorBearerType | AuthenticatorOAuth2Type;

export type BaseAuthenticatorType = {
  __typename?: 'BaseAuthenticatorType';
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  type: AuthenticatorTypeEnum;
};

export type BaseMembershipInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isFieldWorker?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  permissionLevel?: InputMaybe<PermissionLevelEnum>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  privateEmail?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  workspaceEmail?: InputMaybe<Scalars['String']['input']>;
};

export type BaseMembershipType = {
  __typename?: 'BaseMembershipType';
  approvals: Array<ApprovalResourceType>;
  createdAt: Scalars['DateTime']['output'];
  createdJourneys: Array<ProjectAwaitingType>;
  endDate?: Maybe<Scalars['Date']['output']>;
  externalSystem?: Maybe<ExternalSystemType>;
  externalUserId?: Maybe<Scalars['String']['output']>;
  featureFlags?: Maybe<Array<Maybe<FeatureFlagToMemberType>>>;
  featureflagtomemberSet: Array<FeatureFlagToMemberType>;
  firstName: Scalars['String']['output'];
  flowSet: Array<FlowType>;
  forms: Array<FormResourceType>;
  id: Scalars['UUID']['output'];
  inviteSentAt?: Maybe<Scalars['Date']['output']>;
  inviteStatus?: Maybe<MemberInviteStatusEnum>;
  isFieldWorker: Scalars['Boolean']['output'];
  journeyStepsCompleted: Array<JourneyStepType>;
  lastInviteAction?: Maybe<MembershipLastInviteAction>;
  lastName: Scalars['String']['output'];
  membershipsToFlows: Array<MembershipsToFlowsType>;
  membershipsToMembersgroups: Array<MembershipToMembersGroupType>;
  messagesFrom: Array<MessageResourceType>;
  messagesTo: Array<MessageResourceType>;
  permissionLevel: MembershipPermissionLevel;
  phoneNumber: Scalars['String']['output'];
  /** If not provided, using email of User instead. */
  privateEmail: Scalars['String']['output'];
  relationshipsIncoming: Array<RelationshipType>;
  relationshipsOutgoing: Array<RelationshipType>;
  signingDate?: Maybe<Scalars['Date']['output']>;
  slackId: Scalars['String']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
  tasksTo: Array<TaskResourceType>;
  todoLists: Array<TodoListResourceType>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserType>;
  workspace: WorkspaceType;
  workspaceEmail: Scalars['String']['output'];
};

export type BaseTeamType = {
  __typename?: 'BaseTeamType';
  approvals: Array<ApprovalResourceType>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  externalSystem?: Maybe<ExternalSystemType>;
  forms: Array<FormResourceType>;
  id: Scalars['UUID']['output'];
  messages: Array<MessageResourceType>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  tasks: Array<TaskResourceType>;
  todoLists: Array<TodoListResourceType>;
  updatedAt: Scalars['DateTime']['output'];
  workspace: WorkspaceType;
};

export type ChangeWorkspaceMutation = {
  __typename?: 'ChangeWorkspaceMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum ChannelEnum {
  Email = 'EMAIL',
  PersonalEmail = 'PERSONAL_EMAIL',
  SlackMessage = 'SLACK_MESSAGE'
}

/**
 * Try to connect the given user's account to the corresponding user in the Slack workspace by email address
 *
 */
export type ConnectSlackUserMutation = {
  __typename?: 'ConnectSlackUserMutation';
  membership?: Maybe<MembershipType>;
};

export type ContextSectionType = {
  __typename?: 'ContextSectionType';
  allVariables?: Maybe<Scalars['JSONString']['output']>;
  filteredVariables?: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['String']['output'];
  initialVariables?: Maybe<Scalars['JSONString']['output']>;
  searchableVariables?: Maybe<Scalars['JSONString']['output']>;
  variableCategories?: Maybe<Array<VariableCategoryType>>;
  variables?: Maybe<Scalars['JSONString']['output']>;
};


export type ContextSectionTypeFilteredVariablesArgs = {
  filterParams?: InputMaybe<Scalars['JSONString']['input']>;
};

export type CreateAuthenticatorBasicInputType = {
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type CreateAuthenticatorBasicMutation = {
  __typename?: 'CreateAuthenticatorBasicMutation';
  authenticator?: Maybe<AuthenticatorBasicType>;
};

export type CreateAuthenticatorBearerInputType = {
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type CreateAuthenticatorBearerMutation = {
  __typename?: 'CreateAuthenticatorBearerMutation';
  authenticator?: Maybe<AuthenticatorBearerType>;
};

export type CreateAuthenticatorOAuth2InputType = {
  authorizationFlow: FlowEnum;
  clientId: Scalars['String']['input'];
  clientSecret: Scalars['String']['input'];
  name: Scalars['String']['input'];
  scope?: InputMaybe<Scalars['String']['input']>;
  urlToken: Scalars['String']['input'];
};

export type CreateAuthenticatorOauth2Mutation = {
  __typename?: 'CreateAuthenticatorOauth2Mutation';
  authenticator?: Maybe<AuthenticatorOAuth2Type>;
};

export type CreateEditGroupInput = {
  color?: InputMaybe<GroupColorEnum>;
  name: Scalars['String']['input'];
};

export type CreateFlowMutation = {
  __typename?: 'CreateFlowMutation';
  flow?: Maybe<FlowType>;
};

/** Create a new group within a workspace */
export type CreateGroupMutation = {
  __typename?: 'CreateGroupMutation';
  group?: Maybe<MembersGroupType>;
};

export type CreateMembershipInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  firstName: Scalars['String']['input'];
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  isFieldWorker?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  managerMembershipId?: InputMaybe<Scalars['String']['input']>;
  permissionLevel?: InputMaybe<PermissionLevelEnum>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  privateEmail?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  teams?: InputMaybe<Array<Scalars['String']['input']>>;
  workspaceEmail: Scalars['String']['input'];
};

export type CreateMembershipMutation = {
  __typename?: 'CreateMembershipMutation';
  membership?: Maybe<MembershipType>;
};

export type CreateProjectPhaseInput = {
  color: Scalars['String']['input'];
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
};

export type CreateProjectPhaseMutation = {
  __typename?: 'CreateProjectPhaseMutation';
  phase?: Maybe<ProjectPhaseType>;
};

export type CreateTeamInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTeamMutation = {
  __typename?: 'CreateTeamMutation';
  team?: Maybe<TeamType>;
};

export type DeleteAuthenticatorMutation = {
  __typename?: 'DeleteAuthenticatorMutation';
  id?: Maybe<Scalars['String']['output']>;
};

export type DeleteCustomTaskMutation = {
  __typename?: 'DeleteCustomTaskMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteFlowMutation = {
  __typename?: 'DeleteFlowMutation';
  id?: Maybe<Scalars['String']['output']>;
};

/** Delete a group */
export type DeleteGroupMutation = {
  __typename?: 'DeleteGroupMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteJourneyMutation = {
  __typename?: 'DeleteJourneyMutation';
  id?: Maybe<Scalars['String']['output']>;
};

export type DeleteJourneyTagMutation = {
  __typename?: 'DeleteJourneyTagMutation';
  journey?: Maybe<JourneyType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  workspace?: Maybe<WorkspaceType>;
};

export type DeleteProjectPhaseMutation = {
  __typename?: 'DeleteProjectPhaseMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteTeamMutation = {
  __typename?: 'DeleteTeamMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DocumentInputType = {
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
};

export type DocumentResourceType = {
  __typename?: 'DocumentResourceType';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  flowStep?: Maybe<FlowStepType>;
  id: Scalars['UUID']['output'];
  journeyStep?: Maybe<JourneyStepType>;
  label: Scalars['String']['output'];
  receiver?: Maybe<MembershipType>;
  receiverId?: Maybe<Scalars['String']['output']>;
  receiverRole?: Maybe<Scalars['String']['output']>;
  receiverTeam?: Maybe<TeamType>;
  receiverTeamId?: Maybe<Scalars['String']['output']>;
  rejectedInApproval?: Maybe<ApprovalResourceType>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Scalars['JSONString']['output'];
};

export type DuplicateFlowMutation = {
  __typename?: 'DuplicateFlowMutation';
  newFlow?: Maybe<FlowType>;
};

export type DuplicateFlowStepMutation = {
  __typename?: 'DuplicateFlowStepMutation';
  newFlowStep?: Maybe<FlowStepType>;
};

/** Edit a group */
export type EditGroupMutation = {
  __typename?: 'EditGroupMutation';
  group?: Maybe<MembersGroupType>;
};

export type ExternalSystemType = {
  __typename?: 'ExternalSystemType';
  createdAt: Scalars['DateTime']['output'];
  domain?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  memberships: Array<MembershipType>;
  name: Scalars['String']['output'];
  teams: Array<TeamType>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserType>;
  workspace: WorkspaceType;
};

export type FeatureFlagToMemberType = {
  __typename?: 'FeatureFlagToMemberType';
  createdAt: Scalars['DateTime']['output'];
  featureFlag: FeatureFlagType;
  id: Scalars['UUID']['output'];
  membership: MembershipType;
  updatedAt: Scalars['DateTime']['output'];
};

export type FeatureFlagType = {
  __typename?: 'FeatureFlagType';
  createdAt: Scalars['DateTime']['output'];
  featureflagtomemberSet: Array<FeatureFlagToMemberType>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** An enumeration. */
export enum FlowEnum {
  ClientCredentials = 'CLIENT_CREDENTIALS'
}

export type FlowInputType = {
  currentEditor?: InputMaybe<Scalars['String']['input']>;
  editorAssignTime?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  instanceName?: InputMaybe<Scalars['String']['input']>;
  isDraft: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  orderedFlowSteps?: InputMaybe<Array<InputMaybe<FlowStepInputType>>>;
  useCustomInstanceNames?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowLabelType = {
  __typename?: 'FlowLabelType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workspace: WorkspaceType;
};

export type FlowStepInputType = {
  approval?: InputMaybe<ApprovalInputType>;
  document?: InputMaybe<DocumentInputType>;
  flow?: InputMaybe<FlowInputType>;
  form?: InputMaybe<FormInputType>;
  id: Scalars['String']['input'];
  message?: InputMaybe<MessageInputType>;
  name: Scalars['String']['input'];
  phaseId?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  rulesChain?: InputMaybe<Array<InputMaybe<StepRuleInputType>>>;
  stepType: Scalars['String']['input'];
  task?: InputMaybe<TaskInputType>;
  todoList?: InputMaybe<TodoListInputType>;
  version?: InputMaybe<Scalars['Int']['input']>;
  waitDuration: Scalars['Int']['input'];
  workPackage?: InputMaybe<Scalars['String']['input']>;
};

/** An enumeration. */
export enum FlowStepTriggerEvent {
  /** COMPLETION */
  Completion = 'COMPLETION',
  /** DELIVERY */
  Delivery = 'DELIVERY'
}

export type FlowStepType = {
  __typename?: 'FlowStepType';
  allVariables?: Maybe<Scalars['JSONString']['output']>;
  approval?: Maybe<ApprovalResourceType>;
  approvalId?: Maybe<Scalars['String']['output']>;
  categoriesDisplayOrder?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  document?: Maybe<DocumentResourceType>;
  documentId?: Maybe<Scalars['String']['output']>;
  filteredVariables?: Maybe<Scalars['JSONString']['output']>;
  flow: FlowType;
  form?: Maybe<FormResourceType>;
  formId?: Maybe<Scalars['String']['output']>;
  httpRequest?: Maybe<HttpRequestResourceType>;
  httpRequestId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  initialVariables?: Maybe<Scalars['JSONString']['output']>;
  journeySteps: Array<JourneyStepType>;
  membershipsToFlows: Array<MembershipsToFlowsType>;
  message?: Maybe<MessageResourceType>;
  messageId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phase?: Maybe<ProjectPhaseType>;
  position: Scalars['Int']['output'];
  ruleVariableOptions?: Maybe<Scalars['JSONString']['output']>;
  rulesChain?: Maybe<Array<Maybe<StepRuleType>>>;
  scopeVariables?: Maybe<Scalars['JSONString']['output']>;
  searchableVariables?: Maybe<Scalars['JSONString']['output']>;
  stepType: FlowStepTypeEnum;
  stepsToTrigger: Array<FlowStepType>;
  task?: Maybe<TaskResourceType>;
  taskId?: Maybe<Scalars['String']['output']>;
  todoList?: Maybe<TodoListResourceType>;
  triggerEvent: FlowStepTriggerEvent;
  triggeringStep?: Maybe<FlowStepType>;
  updatedAt: Scalars['DateTime']['output'];
  variableCategories?: Maybe<Array<VariableCategoryType>>;
  variableStore?: Maybe<Scalars['JSONString']['output']>;
  variables?: Maybe<Scalars['JSONString']['output']>;
  variablesDisplayOrder: Array<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
  waitDuration: Scalars['Float']['output'];
  warnings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  workPackage?: Maybe<Scalars['String']['output']>;
};


export type FlowStepTypeFilteredVariablesArgs = {
  filterParams?: InputMaybe<Scalars['JSONString']['input']>;
};

/** An enumeration. */
export enum FlowStepTypeEnum {
  Approval = 'APPROVAL',
  Document = 'DOCUMENT',
  Email = 'EMAIL',
  Form = 'FORM',
  HttpRequest = 'HTTP_REQUEST',
  List = 'LIST',
  Slack = 'SLACK',
  Task = 'TASK',
  Trigger = 'TRIGGER'
}

export type FlowType = {
  __typename?: 'FlowType';
  allVariables?: Maybe<Scalars['JSONString']['output']>;
  canModifyFlow?: Maybe<Scalars['Boolean']['output']>;
  categoriesDisplayOrder?: Maybe<Array<Scalars['String']['output']>>;
  contextSection?: Maybe<ContextSectionType>;
  contextSectionId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  currentEditor?: Maybe<MembershipType>;
  editorAssignTime?: Maybe<Scalars['DateTime']['output']>;
  filteredVariables?: Maybe<Scalars['JSONString']['output']>;
  flowSteps?: Maybe<Array<Maybe<FlowStepType>>>;
  id: Scalars['String']['output'];
  initialVariables?: Maybe<Scalars['JSONString']['output']>;
  instanceName?: Maybe<Scalars['String']['output']>;
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  isProject: Scalars['Boolean']['output'];
  journeys: Array<ProjectAwaitingType>;
  labels?: Maybe<Array<Maybe<FlowLabelType>>>;
  membershipsToFlows: Array<MembershipsToFlowsType>;
  name: Scalars['String']['output'];
  orderedFlowSteps?: Maybe<Array<Maybe<FlowStepType>>>;
  searchableVariables?: Maybe<Scalars['JSONString']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  useCustomInstanceNames: Scalars['Boolean']['output'];
  variableCategories?: Maybe<Array<VariableCategoryType>>;
  variables?: Maybe<Scalars['JSONString']['output']>;
  variablesDisplayOrder: Array<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
  warnings?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  workspace: WorkspaceType;
};


export type FlowTypeFilteredVariablesArgs = {
  filterParams?: InputMaybe<Scalars['JSONString']['input']>;
};

export type FormInputType = {
  id: Scalars['String']['input'];
  orderedFormItems?: InputMaybe<Array<InputMaybe<FormItemInputType>>>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  receiverRole?: InputMaybe<Scalars['String']['input']>;
  receiverTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type FormItemInputType = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  defaultText?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isList?: InputMaybe<Scalars['Boolean']['input']>;
  isOptional?: InputMaybe<Scalars['Boolean']['input']>;
  itemType: Scalars['String']['input'];
  label: Scalars['String']['input'];
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  position: Scalars['Int']['input'];
  remoteDataUrl?: InputMaybe<Scalars['String']['input']>;
  text: Scalars['String']['input'];
  useCaptionText?: InputMaybe<Scalars['Boolean']['input']>;
  useLabel?: InputMaybe<Scalars['Boolean']['input']>;
  useRemoteData?: InputMaybe<Scalars['Boolean']['input']>;
  variableIdentifier?: InputMaybe<Scalars['String']['input']>;
};

/** An enumeration. */
export enum FormItemItemType {
  /** BOOLEAN */
  Boolean = 'BOOLEAN',
  /** DATE */
  Date = 'DATE',
  /** DESCRIPTION_TEXT */
  DescriptionText = 'DESCRIPTION_TEXT',
  /** FILE_UPLOAD */
  FileUpload = 'FILE_UPLOAD',
  /** IMAGE_UPLOAD */
  ImageUpload = 'IMAGE_UPLOAD',
  /** LONG_TEXT */
  LongText = 'LONG_TEXT',
  /** MEMBERSHIP */
  Membership = 'MEMBERSHIP',
  /** NUMBER */
  Number = 'NUMBER',
  /** OPTION */
  Option = 'OPTION',
  /** SHORT_TEXT */
  ShortText = 'SHORT_TEXT'
}

export type FormItemType = {
  __typename?: 'FormItemType';
  createdAt: Scalars['DateTime']['output'];
  date?: Maybe<Scalars['Date']['output']>;
  defaultText: Scalars['String']['output'];
  form: FormResourceType;
  id: Scalars['UUID']['output'];
  isList: Scalars['Boolean']['output'];
  isOptional: Scalars['Boolean']['output'];
  itemType: FormItemItemType;
  label: Scalars['String']['output'];
  options?: Maybe<Array<Scalars['String']['output']>>;
  position: Scalars['Int']['output'];
  remoteDataUrl?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  useCaptionText: Scalars['Boolean']['output'];
  useLabel: Scalars['Boolean']['output'];
  useRemoteData: Scalars['Boolean']['output'];
  variableIdentifier?: Maybe<Scalars['String']['output']>;
  warnings: Scalars['JSONString']['output'];
};

export type FormResourceType = {
  __typename?: 'FormResourceType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  flowStep?: Maybe<FlowStepType>;
  formItems?: Maybe<Array<Maybe<FormItemType>>>;
  id: Scalars['UUID']['output'];
  journeyStep?: Maybe<JourneyStepType>;
  orderedFormItems?: Maybe<Array<Maybe<FormItemType>>>;
  receiver?: Maybe<MembershipType>;
  receiverId?: Maybe<Scalars['String']['output']>;
  receiverRole?: Maybe<Scalars['String']['output']>;
  receiverTeam?: Maybe<TeamType>;
  receiverTeamId?: Maybe<Scalars['String']['output']>;
  rejectedInApproval?: Maybe<ApprovalResourceType>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Scalars['JSONString']['output'];
};

export type FormSubmitInputType = {
  id: Scalars['String']['input'];
  /** JSON String containing the values assigned for the different form fields */
  stepVariables: Scalars['JSONString']['input'];
};

/** An enumeration. */
export enum GroupColorEnum {
  Blue = 'BLUE',
  Darkgreen = 'DARKGREEN',
  Gray = 'GRAY',
  Indianred = 'INDIANRED',
  Limegreen = 'LIMEGREEN',
  Navy = 'NAVY',
  Orangered = 'ORANGERED',
  Skyblue = 'SKYBLUE',
  Turquoise = 'TURQUOISE',
  Yellow = 'YELLOW'
}

export type HttpRequestResourceType = {
  __typename?: 'HTTPRequestResourceType';
  authenticator?: Maybe<BaseAuthenticatorType>;
  flowStepVariables?: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['UUID']['output'];
  method: Scalars['String']['output'];
  receiver?: Maybe<MembershipType>;
  receiverId?: Maybe<Scalars['String']['output']>;
  receiverRole?: Maybe<Scalars['String']['output']>;
  receiverTeam?: Maybe<TeamType>;
  receiverTeamId?: Maybe<Scalars['String']['output']>;
  rejectedInApproval?: Maybe<ApprovalResourceType>;
  requestBody: Scalars['JSONString']['output'];
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  variableToResponseKeys: Scalars['JSONString']['output'];
};

/** An enumeration. */
export enum HttpMethodEnum {
  Delete = 'DELETE',
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST'
}

/** An enumeration. */
export enum InstallionNotePrivateTypeEnum {
  Auftraggeber = 'AUFTRAGGEBER',
  Installateur = 'INSTALLATEUR',
  Installion = 'INSTALLION'
}

/** An enumeration. */
export enum InstallionNoteRecipientTypeEnum {
  Auftraggeber = 'AUFTRAGGEBER',
  Ausgangsrechnungen = 'AUSGANGSRECHNUNGEN',
  Customer = 'CUSTOMER',
  Einkauf = 'EINKAUF',
  Installateur = 'INSTALLATEUR',
  LichtBlick = 'LICHT_BLICK',
  Netzanmeldung = 'NETZANMELDUNG',
  PartnerCompany = 'PARTNER_COMPANY',
  Planung = 'PLANUNG',
  Service = 'SERVICE'
}

export type InstallionNoteType = {
  __typename?: 'InstallionNoteType';
  attachmentUrl?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  isEdited: Scalars['Boolean']['output'];
  isHidden: Scalars['Boolean']['output'];
  isPrivate: Scalars['Boolean']['output'];
  isRead: Scalars['Boolean']['output'];
  isSend: Scalars['Boolean']['output'];
  privateType?: Maybe<InstallionNotePrivateTypeEnum>;
  recipients?: Maybe<Array<Maybe<InstallionNoteRecipientTypeEnum>>>;
  sender?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<InstallionTagType>>>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<InstallionNoteTypeEnum>;
};

/** An enumeration. */
export enum InstallionNoteTypeEnum {
  Complete = 'COMPLETE',
  Finalize = 'FINALIZE',
  Match = 'MATCH',
  Note = 'NOTE',
  Unmatch = 'UNMATCH'
}

export type InstallionProjectDocumentCategoryType = {
  __typename?: 'InstallionProjectDocumentCategoryType';
  packages: Array<InstallionProjectDocumentPackageType>;
  title: Scalars['String']['output'];
};

export type InstallionProjectDocumentFileType = {
  __typename?: 'InstallionProjectDocumentFileType';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type InstallionProjectDocumentPackageType = {
  __typename?: 'InstallionProjectDocumentPackageType';
  files: Array<InstallionProjectDocumentFileType>;
  title?: Maybe<Scalars['String']['output']>;
};

export type InstallionTagType = {
  __typename?: 'InstallionTagType';
  color?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

/** Invite a new user to a workspace */
export type InviteUserMutation = {
  __typename?: 'InviteUserMutation';
  membership?: Maybe<MembershipType>;
};

export type JourneyAwaitingTodayType = {
  __typename?: 'JourneyAwaitingTodayType';
  projectId?: Maybe<Scalars['String']['output']>;
  projectInstanceName?: Maybe<Scalars['String']['output']>;
  projectTasks?: Maybe<Array<Maybe<JourneyProjectTaskAwaitingTodayType>>>;
  projectTemplateName?: Maybe<Scalars['String']['output']>;
  todoSteps?: Maybe<Array<Maybe<JourneyTodoStepAwaitingTodayType>>>;
};

export type JourneyParticipantType = {
  __typename?: 'JourneyParticipantType';
  person?: Maybe<MembershipType>;
  role?: Maybe<Scalars['String']['output']>;
  team?: Maybe<TeamType>;
};

export type JourneyProjectTaskAwaitingTodayType = {
  __typename?: 'JourneyProjectTaskAwaitingTodayType';
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  phase?: Maybe<ProjectPhaseType>;
  stepId?: Maybe<Scalars['String']['output']>;
  stepName?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum JourneyStepStatus {
  /** AWAIT_USER */
  AwaitUser = 'AWAIT_USER',
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** NOT_STARTED */
  NotStarted = 'NOT_STARTED',
  /** SKIPPED */
  Skipped = 'SKIPPED',
  /** TIMED_WAIT */
  TimedWait = 'TIMED_WAIT'
}

/** An enumeration. */
export enum JourneyStepTriggerEvent {
  /** COMPLETION */
  Completion = 'COMPLETION',
  /** DELIVERY */
  Delivery = 'DELIVERY'
}

export type JourneyStepType = {
  __typename?: 'JourneyStepType';
  allVariables?: Maybe<Scalars['JSONString']['output']>;
  approval?: Maybe<ApprovalResourceType>;
  approvalId?: Maybe<Scalars['String']['output']>;
  categoriesDisplayOrder?: Maybe<Array<Scalars['String']['output']>>;
  chronologicalPosition?: Maybe<Scalars['Int']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedBy?: Maybe<MembershipType>;
  createdAt: Scalars['DateTime']['output'];
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  document?: Maybe<DocumentResourceType>;
  documentId?: Maybe<Scalars['String']['output']>;
  expectedDeliveryAt?: Maybe<Scalars['DateTime']['output']>;
  filteredVariables?: Maybe<Scalars['JSONString']['output']>;
  flowStep?: Maybe<FlowStepType>;
  form?: Maybe<FormResourceType>;
  formId?: Maybe<Scalars['String']['output']>;
  httpRequest?: Maybe<HttpRequestResourceType>;
  httpRequestId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  initialVariables?: Maybe<Scalars['JSONString']['output']>;
  journey: ProjectAwaitingType;
  message?: Maybe<MessageResourceType>;
  messageId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  overwrittenSectionId?: Maybe<Scalars['Int']['output']>;
  phase?: Maybe<ProjectPhaseType>;
  position: Scalars['Int']['output'];
  projectId?: Maybe<Scalars['String']['output']>;
  projectInstanceName?: Maybe<Scalars['String']['output']>;
  projectTemplateName?: Maybe<Scalars['String']['output']>;
  rejectedInApproval?: Maybe<ApprovalResourceType>;
  rulesChain?: Maybe<Array<Maybe<StepRuleType>>>;
  scopeVariables?: Maybe<Scalars['JSONString']['output']>;
  searchableVariables?: Maybe<Scalars['JSONString']['output']>;
  status: JourneyStepStatus;
  stepType: FlowStepTypeEnum;
  stepsToTrigger: Array<JourneyStepType>;
  task?: Maybe<TaskResourceType>;
  taskId?: Maybe<Scalars['String']['output']>;
  todoList?: Maybe<TodoListResourceType>;
  triggerEvent: JourneyStepTriggerEvent;
  triggeringStep?: Maybe<JourneyStepType>;
  updatedAt: Scalars['DateTime']['output'];
  variableCategories?: Maybe<Array<VariableCategoryType>>;
  variableStore?: Maybe<Scalars['JSONString']['output']>;
  variables?: Maybe<Scalars['JSONString']['output']>;
  variablesDisplayOrder: Array<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
  waitDuration: Scalars['Float']['output'];
  workPackage?: Maybe<Scalars['String']['output']>;
};


export type JourneyStepTypeFilteredVariablesArgs = {
  filterParams?: InputMaybe<Scalars['JSONString']['input']>;
};

export type JourneyTagType = {
  __typename?: 'JourneyTagType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  linkedJourneys: Array<ProjectAwaitingType>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workspace: WorkspaceType;
};

export type JourneyTodoStepAwaitingTodayType = {
  __typename?: 'JourneyTodoStepAwaitingTodayType';
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  journeyId?: Maybe<Scalars['String']['output']>;
  phase?: Maybe<ProjectPhaseType>;
  stepId?: Maybe<Scalars['String']['output']>;
  stepName?: Maybe<Scalars['String']['output']>;
};

export type JourneyType = {
  __typename?: 'JourneyType';
  allVariables?: Maybe<Scalars['JSONString']['output']>;
  categoriesDisplayOrder?: Maybe<Array<Scalars['String']['output']>>;
  contextSection?: Maybe<ContextSectionType>;
  contextSectionId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  filteredVariables?: Maybe<Scalars['JSONString']['output']>;
  flow: FlowType;
  id: Scalars['String']['output'];
  initialVariables?: Maybe<Scalars['JSONString']['output']>;
  initiator?: Maybe<MembershipType>;
  instanceName?: Maybe<Scalars['String']['output']>;
  isArchived: Scalars['Boolean']['output'];
  isPaused?: Maybe<Scalars['Boolean']['output']>;
  isProject: Scalars['Boolean']['output'];
  journeySteps?: Maybe<Array<Maybe<JourneyStepType>>>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  myTasks?: Maybe<Array<Maybe<JourneyStepType>>>;
  orderedJourneySteps?: Maybe<Array<Maybe<JourneyStepType>>>;
  participants?: Maybe<Array<Maybe<JourneyParticipantType>>>;
  pausedUntil?: Maybe<Scalars['DateTime']['output']>;
  phase?: Maybe<ProjectPhaseType>;
  searchableVariables?: Maybe<Scalars['JSONString']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  tags?: Maybe<Array<Maybe<JourneyTagType>>>;
  updatedAt: Scalars['DateTime']['output'];
  variableCategories?: Maybe<Array<VariableCategoryType>>;
  variables?: Maybe<Scalars['JSONString']['output']>;
  variablesDisplayOrder: Array<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};


export type JourneyTypeFilteredVariablesArgs = {
  filterParams?: InputMaybe<Scalars['JSONString']['input']>;
};

export type LoadContextSectionMutation = {
  __typename?: 'LoadContextSectionMutation';
  variables?: Maybe<Scalars['JSONString']['output']>;
};

/** An enumeration. */
export enum MemberInviteStatusEnum {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Expired = 'EXPIRED',
  NeverSent = 'NEVER_SENT',
  Pending = 'PENDING'
}

export type MembersGroupType = {
  __typename?: 'MembersGroupType';
  color?: Maybe<GroupColorEnum>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  membershipsToMembersgroups: Array<MembershipToMembersGroupType>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workspace: WorkspaceType;
};

/** An enumeration. */
export enum MembershipLastInviteAction {
  /** ACCEPTED */
  Accepted = 'ACCEPTED',
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** SENT */
  Sent = 'SENT'
}

/** An enumeration. */
export enum MembershipPermissionLevel {
  /** ADMIN */
  Admin = 'ADMIN',
  /** GUEST */
  Guest = 'GUEST',
  /** STANDARD */
  Standard = 'STANDARD'
}

export type MembershipToMembersGroupType = {
  __typename?: 'MembershipToMembersGroupType';
  createdAt: Scalars['DateTime']['output'];
  group: MembersGroupType;
  id: Scalars['UUID']['output'];
  membership: MembershipType;
  updatedAt: Scalars['DateTime']['output'];
};

export type MembershipType = {
  __typename?: 'MembershipType';
  approvals: Array<ApprovalResourceType>;
  createdAt: Scalars['DateTime']['output'];
  createdJourneys: Array<ProjectAwaitingType>;
  endDate?: Maybe<Scalars['Date']['output']>;
  externalSystem?: Maybe<ExternalSystemType>;
  externalUserId?: Maybe<Scalars['String']['output']>;
  featureFlags?: Maybe<Array<Maybe<FeatureFlagToMemberType>>>;
  featureflagtomemberSet: Array<FeatureFlagToMemberType>;
  firstName: Scalars['String']['output'];
  flowSet: Array<FlowType>;
  forms: Array<FormResourceType>;
  id: Scalars['UUID']['output'];
  inviteSentAt?: Maybe<Scalars['Date']['output']>;
  inviteStatus?: Maybe<MemberInviteStatusEnum>;
  isAdmin: Scalars['Boolean']['output'];
  isFieldWorker: Scalars['Boolean']['output'];
  isGuest: Scalars['Boolean']['output'];
  journeyStepsCompleted: Array<JourneyStepType>;
  lastInviteAction?: Maybe<MembershipLastInviteAction>;
  lastName: Scalars['String']['output'];
  membershipsToFlows: Array<MembershipsToFlowsType>;
  membershipsToMembersgroups: Array<MembershipToMembersGroupType>;
  messagesFrom: Array<MessageResourceType>;
  messagesTo: Array<MessageResourceType>;
  permissionLevel: MembershipPermissionLevel;
  phoneNumber: Scalars['String']['output'];
  /** If not provided, using email of User instead. */
  privateEmail: Scalars['String']['output'];
  relationshipsIncoming: Array<RelationshipType>;
  relationshipsOutgoing: Array<RelationshipType>;
  signingDate?: Maybe<Scalars['Date']['output']>;
  slackId: Scalars['String']['output'];
  startDate?: Maybe<Scalars['Date']['output']>;
  tasksTo: Array<TaskResourceType>;
  teams?: Maybe<Array<Maybe<BaseTeamType>>>;
  todoLists: Array<TodoListResourceType>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserType>;
  workspace: WorkspaceType;
  workspaceEmail: Scalars['String']['output'];
};

/** An enumeration. */
export enum MembershipsToFlowsRole {
  /** COORDINATOR */
  Coordinator = 'COORDINATOR'
}

export type MembershipsToFlowsType = {
  __typename?: 'MembershipsToFlowsType';
  createdAt: Scalars['DateTime']['output'];
  flow: FlowType;
  id: Scalars['UUID']['output'];
  lastCompletedStep?: Maybe<FlowStepType>;
  membership: MembershipType;
  role: MembershipsToFlowsRole;
  startDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MessageInputType = {
  bccEmail?: InputMaybe<Scalars['String']['input']>;
  ccEmail?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<ChannelEnum>;
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  receiverId?: InputMaybe<Scalars['String']['input']>;
  receiverRole?: InputMaybe<Scalars['String']['input']>;
  receiverTeamId?: InputMaybe<Scalars['String']['input']>;
  replyToEmail?: InputMaybe<Scalars['String']['input']>;
  reviewBeforeSending?: InputMaybe<Scalars['Boolean']['input']>;
  slackChannelId?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  toEmail?: InputMaybe<Scalars['String']['input']>;
};

export type MessageResourceType = {
  __typename?: 'MessageResourceType';
  bccEmail?: Maybe<Scalars['String']['output']>;
  ccEmail?: Maybe<Scalars['String']['output']>;
  channel?: Maybe<ChannelEnum>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  flowStep?: Maybe<FlowStepType>;
  id: Scalars['UUID']['output'];
  isStepReminder: Scalars['Boolean']['output'];
  journeyStep?: Maybe<JourneyStepType>;
  receiver?: Maybe<MembershipType>;
  receiverId?: Maybe<Scalars['String']['output']>;
  receiverRole?: Maybe<Scalars['String']['output']>;
  receiverTeam?: Maybe<TeamType>;
  receiverTeamId?: Maybe<Scalars['String']['output']>;
  rejectedInApproval?: Maybe<ApprovalResourceType>;
  replyToEmail?: Maybe<Scalars['String']['output']>;
  reviewBeforeSending: Scalars['Boolean']['output'];
  sender?: Maybe<MembershipType>;
  slackChannelId: Scalars['String']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  toEmail?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Scalars['JSONString']['output'];
};


export type MessageResourceTypeContentArgs = {
  parsed?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MessageResourceTypeSubjectArgs = {
  parsed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Mutations = {
  __typename?: 'Mutations';
  activateFlow?: Maybe<ActivateFlowMutation>;
  /** Updates a custom task attached to a journey step */
  addCustomTask?: Maybe<AddCustomTaskMutation>;
  addJourneyTag?: Maybe<AddJourneyTagMutation>;
  addSlackIntegration?: Maybe<AddSlackIntegrationMutation>;
  approveStep?: Maybe<ApproveStepMutation>;
  changeWorkspace?: Maybe<ChangeWorkspaceMutation>;
  /**
   * Try to connect the given user's account to the corresponding user in the Slack workspace by email address
   *
   */
  connectSlackUser?: Maybe<ConnectSlackUserMutation>;
  createAuthenticatorBasic?: Maybe<CreateAuthenticatorBasicMutation>;
  createAuthenticatorBearer?: Maybe<CreateAuthenticatorBearerMutation>;
  createAuthenticatorOauth2?: Maybe<CreateAuthenticatorOauth2Mutation>;
  createFlow?: Maybe<CreateFlowMutation>;
  /** Create a new group within a workspace */
  createGroup?: Maybe<CreateGroupMutation>;
  createMembership?: Maybe<CreateMembershipMutation>;
  createProjectPhase?: Maybe<CreateProjectPhaseMutation>;
  createTeam?: Maybe<CreateTeamMutation>;
  deleteAuthenticator?: Maybe<DeleteAuthenticatorMutation>;
  deleteCustomTask?: Maybe<DeleteCustomTaskMutation>;
  deleteFlow?: Maybe<DeleteFlowMutation>;
  /** Delete a group */
  deleteGroup?: Maybe<DeleteGroupMutation>;
  deleteJourney?: Maybe<DeleteJourneyMutation>;
  deleteJourneyTag?: Maybe<DeleteJourneyTagMutation>;
  deleteProjectPhase?: Maybe<DeleteProjectPhaseMutation>;
  deleteTeam?: Maybe<DeleteTeamMutation>;
  duplicateFlow?: Maybe<DuplicateFlowMutation>;
  duplicateFlowStep?: Maybe<DuplicateFlowStepMutation>;
  /** Edit a group */
  editGroup?: Maybe<EditGroupMutation>;
  /** Invite a new user to a workspace */
  inviteUser?: Maybe<InviteUserMutation>;
  loadContextSection?: Maybe<LoadContextSectionMutation>;
  reassignJourneyStep?: Maybe<ReassignJourneyStepMutation>;
  reassignJourneyVariables?: Maybe<ReassignJourneyVariablesMutation>;
  rejectStep?: Maybe<RejectStepMutation>;
  reorderProjectPhases?: Maybe<ReorderProjectPhasesMutation>;
  /** Saves a flow (including flow name, flow step order and wait times) */
  saveFlow?: Maybe<SaveFlowMutation>;
  /** Updates a flow step */
  saveFlowStep?: Maybe<SaveFlowStepMutation>;
  setFlowCurrentEditor?: Maybe<SetFlowCurrentEditorMutation>;
  snoozeJourney?: Maybe<SnoozeJourneyMutation>;
  submitForm?: Maybe<SubmitFormMutation>;
  submitTask?: Maybe<SubmitTaskMutation>;
  toggleJourneyArchiveStatus?: Maybe<ToggleJourneyArchiveStatusMutation>;
  toggleJourneyPauseStatus?: Maybe<ToggleJourneyPauseStatusMutation>;
  toggleSubtaskStatus?: Maybe<ToggleSubtaskStatusMutation>;
  toggleTaskStatus?: Maybe<ToggleTaskStatusMutation>;
  toggleTodoItem?: Maybe<TodoItemToggleMutation>;
  /** Sends a message that has been marked as in-review */
  updateAndSendJourneyMessage?: Maybe<UpdateAndSendJourneyMessageMutation>;
  /** Updates the approval attached to a flow step */
  updateApproval?: Maybe<UpdateApprovalMutation>;
  updateAuthenticatorBasic?: Maybe<UpdateAuthenticatorBasicMutation>;
  updateAuthenticatorBearer?: Maybe<UpdateAuthenticatorBearerMutation>;
  updateAuthenticatorOauth2?: Maybe<UpdateAuthenticatorOauth2Mutation>;
  /** Updates a custom task attached to a journey step */
  updateCustomTask?: Maybe<UpdateCustomTaskMutation>;
  /** Updates the document attached to a flow step */
  updateDocument?: Maybe<UpdateDocumentMutation>;
  updateFlowLabels?: Maybe<UpdateFlowLabelsMutation>;
  /**
   * This mutation is used ONLY to create a new variable for the current flow step on the frontend.
   * Updates to variable usages are handled when saving the respective resources.
   */
  updateFlowStepVariables?: Maybe<UpdateFlowStepVariablesMutation>;
  updateFlowVariables?: Maybe<UpdateFlowVariablesMutation>;
  /** Updates the Form attached to a flow step */
  updateForm?: Maybe<UpdateFormMutation>;
  updateHttpRequestResource?: Maybe<UpdateHttpRequestResourceMutation>;
  updateJourney?: Maybe<UpdateJourneyMutation>;
  /**
   * This mutation is used to update variables in a journey. For now, we only allow updating of
   * project variables
   *
   * Raises:
   *     GraphQLError: When a journey with the specified ID was not found
   *     GraphQLError: When there's a validation error due to one of the variables being updated
   */
  updateJourneyVariables?: Maybe<UpdateJourneyVariablesMutation>;
  /** Updates the TodoList attached to a flow step */
  updateList?: Maybe<UpdateListMutation>;
  /** Updates the message attached to a flow step */
  updateMessage?: Maybe<UpdateFlowMessageMutation>;
  updateOtherMembership?: Maybe<UpdateOtherMembershipMutation>;
  updateOwnMembership?: Maybe<UpdateOwnMembershipMutation>;
  updateProjectPhase?: Maybe<UpdateProjectPhaseMutation>;
  /** Updates the task attached to a flow step */
  updateTask?: Maybe<UpdateTaskMutation>;
  updateTaskDueDate?: Maybe<UpdateTaskDueDateMutation>;
  updateTeam?: Maybe<UpdateTeamMutation>;
};


export type MutationsActivateFlowArgs = {
  input: ActivateFlowInputType;
};


export type MutationsAddCustomTaskArgs = {
  taskInput: AddCustomTaskInputType;
};


export type MutationsAddJourneyTagArgs = {
  journeyId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};


export type MutationsApproveStepArgs = {
  input: ApproveStepInputType;
};


export type MutationsChangeWorkspaceArgs = {
  workspaceId: Scalars['String']['input'];
};


export type MutationsConnectSlackUserArgs = {
  membershipId: Scalars['String']['input'];
};


export type MutationsCreateAuthenticatorBasicArgs = {
  authenticatorInput: CreateAuthenticatorBasicInputType;
};


export type MutationsCreateAuthenticatorBearerArgs = {
  authenticatorInput: CreateAuthenticatorBearerInputType;
};


export type MutationsCreateAuthenticatorOauth2Args = {
  authenticatorInput: CreateAuthenticatorOAuth2InputType;
};


export type MutationsCreateFlowArgs = {
  isProject?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsCreateGroupArgs = {
  input: CreateEditGroupInput;
};


export type MutationsCreateMembershipArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  membershipInput: CreateMembershipInput;
  permissionLevel?: InputMaybe<PermissionLevelEnum>;
  privateEmail?: InputMaybe<Scalars['String']['input']>;
  signingDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationsCreateProjectPhaseArgs = {
  data: CreateProjectPhaseInput;
};


export type MutationsCreateTeamArgs = {
  teamInput: CreateTeamInput;
};


export type MutationsDeleteAuthenticatorArgs = {
  authenticatorId: Scalars['String']['input'];
  authenticatorType: AuthenticatorTypeEnum;
};


export type MutationsDeleteCustomTaskArgs = {
  taskId: Scalars['String']['input'];
};


export type MutationsDeleteFlowArgs = {
  flowId: Scalars['String']['input'];
};


export type MutationsDeleteGroupArgs = {
  groupId: Scalars['String']['input'];
};


export type MutationsDeleteJourneyArgs = {
  hardDelete?: InputMaybe<Scalars['Boolean']['input']>;
  journeyId: Scalars['String']['input'];
};


export type MutationsDeleteJourneyTagArgs = {
  journeyId?: InputMaybe<Scalars['String']['input']>;
  tagId: Scalars['String']['input'];
};


export type MutationsDeleteProjectPhaseArgs = {
  id: Scalars['String']['input'];
};


export type MutationsDeleteTeamArgs = {
  teamId: Scalars['String']['input'];
};


export type MutationsDuplicateFlowArgs = {
  flowId: Scalars['String']['input'];
};


export type MutationsDuplicateFlowStepArgs = {
  flowStepId: Scalars['String']['input'];
};


export type MutationsEditGroupArgs = {
  groupId: Scalars['String']['input'];
  input: CreateEditGroupInput;
};


export type MutationsInviteUserArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  membershipInput: CreateMembershipInput;
  permissionLevel?: InputMaybe<PermissionLevelEnum>;
  privateEmail?: InputMaybe<Scalars['String']['input']>;
  signingDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationsLoadContextSectionArgs = {
  contextId: Scalars['String']['input'];
};


export type MutationsReassignJourneyStepArgs = {
  input: ReassignJourneyStepInputType;
};


export type MutationsReassignJourneyVariablesArgs = {
  journeyId: Scalars['String']['input'];
  variables: Scalars['JSONString']['input'];
};


export type MutationsRejectStepArgs = {
  input: RejectStepInputType;
};


export type MutationsReorderProjectPhasesArgs = {
  order: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationsSaveFlowArgs = {
  saveFlowInput: FlowInputType;
};


export type MutationsSaveFlowStepArgs = {
  saveFlowStepInput: FlowStepInputType;
};


export type MutationsSetFlowCurrentEditorArgs = {
  setFlowCurrentEditorInput: SetFlowCurrentEditorInputType;
};


export type MutationsSnoozeJourneyArgs = {
  days: Scalars['Int']['input'];
  journeyId: Scalars['String']['input'];
};


export type MutationsSubmitFormArgs = {
  formInput: FormSubmitInputType;
};


export type MutationsSubmitTaskArgs = {
  taskInput: TaskSubmitInputType;
};


export type MutationsToggleJourneyArchiveStatusArgs = {
  journeyId: Scalars['String']['input'];
};


export type MutationsToggleJourneyPauseStatusArgs = {
  journeyId: Scalars['String']['input'];
};


export type MutationsToggleSubtaskStatusArgs = {
  subtaskToggleInput: SubtaskToggleInput;
};


export type MutationsToggleTaskStatusArgs = {
  journeyStepId: Scalars['String']['input'];
};


export type MutationsToggleTodoItemArgs = {
  todoItemToggleInput: TodoItemToggleInput;
};


export type MutationsUpdateAndSendJourneyMessageArgs = {
  messageInput?: InputMaybe<UpdateMessageInputType>;
  sendMessage?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationsUpdateApprovalArgs = {
  updateApprovalInput: ApprovalInputType;
};


export type MutationsUpdateAuthenticatorBasicArgs = {
  authenticatorInput: UpdateAuthenticatorBasicInputType;
};


export type MutationsUpdateAuthenticatorBearerArgs = {
  authenticatorInput: UpdateAuthenticatorBearerInputType;
};


export type MutationsUpdateAuthenticatorOauth2Args = {
  authenticatorInput: UpdateAuthenticatorOAuth2InputType;
};


export type MutationsUpdateCustomTaskArgs = {
  taskInput: UpdateCustomTaskInputType;
};


export type MutationsUpdateDocumentArgs = {
  updateDocumentInput: DocumentInputType;
};


export type MutationsUpdateFlowLabelsArgs = {
  flowId: Scalars['String']['input'];
  labelsId: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationsUpdateFlowStepVariablesArgs = {
  flowStepId: Scalars['String']['input'];
  variables: Scalars['JSONString']['input'];
};


export type MutationsUpdateFlowVariablesArgs = {
  flowId: Scalars['String']['input'];
  variableCategories?: InputMaybe<Scalars['JSONString']['input']>;
  variables: Scalars['JSONString']['input'];
};


export type MutationsUpdateFormArgs = {
  updateFormInput: FormInputType;
};


export type MutationsUpdateHttpRequestResourceArgs = {
  httpRequestResourceInput: UpdateHttpRequestResourceInputType;
};


export type MutationsUpdateJourneyArgs = {
  instanceName?: InputMaybe<Scalars['String']['input']>;
  journeyId: Scalars['String']['input'];
  phaseId?: InputMaybe<Scalars['String']['input']>;
  variables?: InputMaybe<Scalars['JSONString']['input']>;
};


export type MutationsUpdateJourneyVariablesArgs = {
  journeyId: Scalars['String']['input'];
  variables: Scalars['JSONString']['input'];
};


export type MutationsUpdateListArgs = {
  updateListInput: TodoListInputType;
};


export type MutationsUpdateMessageArgs = {
  updateMessageInput: MessageInputType;
};


export type MutationsUpdateOtherMembershipArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  membershipInput: UpdateMembershipInput;
  permissionLevel?: InputMaybe<PermissionLevelEnum>;
  privateEmail?: InputMaybe<Scalars['String']['input']>;
  signingDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationsUpdateOwnMembershipArgs = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  membershipInput: UpdateOwnMembershipInput;
  permissionLevel?: InputMaybe<PermissionLevelEnum>;
  privateEmail?: InputMaybe<Scalars['String']['input']>;
  signingDate?: InputMaybe<Scalars['Date']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};


export type MutationsUpdateProjectPhaseArgs = {
  data: UpdateProjectPhaseInput;
  id: Scalars['String']['input'];
};


export type MutationsUpdateTaskArgs = {
  updateTaskInput: TaskInputType;
};


export type MutationsUpdateTaskDueDateArgs = {
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  taskId: Scalars['String']['input'];
};


export type MutationsUpdateTeamArgs = {
  teamId: Scalars['String']['input'];
  teamInput: UpdateTeamInput;
};

export type PaginatedJourneyList = {
  __typename?: 'PaginatedJourneyList';
  /** The number of items in the current page */
  count: Scalars['Int']['output'];
  /** Whether or not this is the first page */
  first: Scalars['Boolean']['output'];
  /** The number of items per page */
  limit: Scalars['Int']['output'];
  /** Whether or not this list has more entries */
  more: Scalars['Boolean']['output'];
  /** The current offset */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** The items in the list. */
  results: Array<Maybe<JourneyType>>;
  /** The total number of items in the list. */
  total: Scalars['Int']['output'];
};

export type PaginatedMembershipList = {
  __typename?: 'PaginatedMembershipList';
  /** The number of items in the current page */
  count: Scalars['Int']['output'];
  /** Whether or not this is the first page */
  first: Scalars['Boolean']['output'];
  /** The number of items per page */
  limit: Scalars['Int']['output'];
  /** Whether or not this list has more entries */
  more: Scalars['Boolean']['output'];
  /** The current offset */
  offset: Scalars['Int']['output'];
  /** The current page */
  page: Scalars['Int']['output'];
  /** The items in the list. */
  results: Array<Maybe<MembershipType>>;
  /** The total number of items in the list. */
  total: Scalars['Int']['output'];
};

export type PaginationInputType = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

/** An enumeration. */
export enum PermissionLevelEnum {
  Admin = 'ADMIN',
  Guest = 'GUEST',
  Standard = 'STANDARD'
}

export type ProjectAwaitingType = {
  __typename?: 'ProjectAwaitingType';
  allVariables?: Maybe<Scalars['JSONString']['output']>;
  categoriesDisplayOrder?: Maybe<Array<Scalars['String']['output']>>;
  contextSection?: Maybe<ContextSectionType>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  filteredVariables?: Maybe<Scalars['JSONString']['output']>;
  flow: FlowType;
  id: Scalars['String']['output'];
  initialVariables?: Maybe<Scalars['JSONString']['output']>;
  initiator?: Maybe<MembershipType>;
  instanceName?: Maybe<Scalars['String']['output']>;
  isArchived: Scalars['Boolean']['output'];
  isProject: Scalars['Boolean']['output'];
  journeySteps?: Maybe<Array<Maybe<ProjectStepType>>>;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  nextPendingStep?: Maybe<ProjectStepType>;
  pausedUntil?: Maybe<Scalars['DateTime']['output']>;
  phase?: Maybe<ProjectPhaseType>;
  searchableVariables?: Maybe<Scalars['JSONString']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  tags: Array<JourneyTagType>;
  totalPendingSteps?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  variableCategories?: Maybe<Array<VariableCategoryType>>;
  variables?: Maybe<Scalars['JSONString']['output']>;
  variablesDisplayOrder: Array<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};


export type ProjectAwaitingTypeFilteredVariablesArgs = {
  filterParams?: InputMaybe<Scalars['JSONString']['input']>;
};

export type ProjectAwaitingUpcomingType = {
  __typename?: 'ProjectAwaitingUpcomingType';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  tasks?: Maybe<Array<Maybe<JourneyProjectTaskAwaitingTodayType>>>;
};

export type ProjectPhaseType = {
  __typename?: 'ProjectPhaseType';
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  flowSteps: Array<FlowStepType>;
  id: Scalars['UUID']['output'];
  journeySteps: Array<JourneyStepType>;
  journeys: Array<ProjectAwaitingType>;
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workspace: WorkspaceType;
};

export type ProjectStepType = {
  __typename?: 'ProjectStepType';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  orderedSubtasks?: Maybe<Array<Maybe<SubtaskType>>>;
  phase?: Maybe<ProjectPhaseType>;
  position: Scalars['Int']['output'];
  subtasks?: Maybe<Array<Maybe<SubtaskType>>>;
  task?: Maybe<TaskResourceType>;
  taskContent?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['UUID']['output']>;
};

export type Query = {
  __typename?: 'Query';
  allAuthenticators?: Maybe<Array<Maybe<BaseAuthenticatorType>>>;
  allFlowLabels?: Maybe<Array<Maybe<FlowLabelType>>>;
  allGroups?: Maybe<Array<Maybe<MembersGroupType>>>;
  allJourneys?: Maybe<PaginatedJourneyList>;
  allMemberships?: Maybe<Array<Maybe<MembershipType>>>;
  allMembershipsPaginated?: Maybe<PaginatedMembershipList>;
  allMyAdminFlows?: Maybe<Array<Maybe<FlowType>>>;
  allProjectPhases?: Maybe<Array<Maybe<ProjectPhaseType>>>;
  allProjectTasks?: Maybe<Array<Maybe<FlowStepType>>>;
  allSearchableFields?: Maybe<Array<Maybe<SearchableField>>>;
  allSlackChannels?: Maybe<Array<Maybe<SlackChannelType>>>;
  allTeams?: Maybe<Array<Maybe<BaseTeamType>>>;
  allWorkspaces?: Maybe<Array<Maybe<WorkspaceType>>>;
  fetchStreamChatToken?: Maybe<Scalars['String']['output']>;
  getApproval?: Maybe<ApprovalResourceType>;
  getAuthenticator?: Maybe<AuthenticatorUnionType>;
  getDocument?: Maybe<DocumentResourceType>;
  getFlowStepResource?: Maybe<StepResourceType>;
  getForm?: Maybe<FormResourceType>;
  getInstallionJourneyDocuments: Array<InstallionProjectDocumentCategoryType>;
  getInstallionJourneyNotes?: Maybe<Array<Maybe<InstallionNoteType>>>;
  getJourneyStepResource?: Maybe<StepResourceType>;
  getProjectSteps?: Maybe<Array<Maybe<ProjectStepType>>>;
  getTask?: Maybe<TaskResourceType>;
  getTeam?: Maybe<TeamType>;
  getTodoList?: Maybe<TodoListResourceType>;
  journeyStepsAwaitingUser?: Maybe<Array<Maybe<JourneyStepType>>>;
  journeysAwaitingUserToday?: Maybe<Array<Maybe<JourneyAwaitingTodayType>>>;
  journeysForFlow?: Maybe<Array<Maybe<JourneyType>>>;
  myMembership?: Maybe<MembershipType>;
  myTeams?: Maybe<Array<Maybe<BaseTeamType>>>;
  myWorkspace?: Maybe<WorkspaceType>;
  nextJourneyStep?: Maybe<JourneyStepType>;
  oneFlow?: Maybe<FlowType>;
  oneFlowStep?: Maybe<FlowStepType>;
  oneJourney?: Maybe<JourneyType>;
  oneMembership?: Maybe<MembershipType>;
  projectsAwaitingUser?: Maybe<Array<Maybe<ProjectAwaitingType>>>;
  projectsAwaitingUserUpcoming?: Maybe<Array<Maybe<ProjectAwaitingUpcomingType>>>;
  remoteFormItemData?: Maybe<Scalars['JSONString']['output']>;
};


export type QueryAllJourneysArgs = {
  filters?: InputMaybe<Scalars['JSONString']['input']>;
  pagination?: InputMaybe<PaginationInputType>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllMembershipsArgs = {
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllMembershipsPaginatedArgs = {
  filters?: InputMaybe<Scalars['JSONString']['input']>;
  pagination?: InputMaybe<PaginationInputType>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllMyAdminFlowsArgs = {
  projectsOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetApprovalArgs = {
  approvalId: Scalars['String']['input'];
};


export type QueryGetAuthenticatorArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetDocumentArgs = {
  documentId: Scalars['String']['input'];
};


export type QueryGetFlowStepResourceArgs = {
  flowStepId: Scalars['String']['input'];
};


export type QueryGetFormArgs = {
  formId: Scalars['String']['input'];
};


export type QueryGetInstallionJourneyDocumentsArgs = {
  journeyId: Scalars['String']['input'];
};


export type QueryGetInstallionJourneyNotesArgs = {
  journeyId: Scalars['String']['input'];
};


export type QueryGetJourneyStepResourceArgs = {
  journeyStepId: Scalars['String']['input'];
};


export type QueryGetProjectStepsArgs = {
  journeyId: Scalars['String']['input'];
};


export type QueryGetTaskArgs = {
  taskId: Scalars['String']['input'];
};


export type QueryGetTeamArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetTodoListArgs = {
  todoListId: Scalars['String']['input'];
};


export type QueryJourneyStepsAwaitingUserArgs = {
  forMemberships?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  forTeams?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryJourneysForFlowArgs = {
  flowId: Scalars['String']['input'];
};


export type QueryNextJourneyStepArgs = {
  journeyId: Scalars['String']['input'];
};


export type QueryOneFlowArgs = {
  flowId: Scalars['String']['input'];
};


export type QueryOneFlowStepArgs = {
  flowStepId: Scalars['String']['input'];
};


export type QueryOneJourneyArgs = {
  journeyId: Scalars['String']['input'];
};


export type QueryOneMembershipArgs = {
  id: Scalars['String']['input'];
};


export type QueryProjectsAwaitingUserArgs = {
  forMemberships?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  forTeams?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryRemoteFormItemDataArgs = {
  formItemId: Scalars['String']['input'];
};

export type ReassignJourneyStepInputType = {
  id: Scalars['String']['input'];
  receiverId?: InputMaybe<Scalars['String']['input']>;
  receiverTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type ReassignJourneyStepMutation = {
  __typename?: 'ReassignJourneyStepMutation';
  journeyStep?: Maybe<JourneyStepType>;
  receiverId?: Maybe<Scalars['String']['output']>;
};

export type ReassignJourneyVariablesMutation = {
  __typename?: 'ReassignJourneyVariablesMutation';
  journey?: Maybe<JourneyType>;
};

export type RejectStepInputType = {
  id: Scalars['String']['input'];
  rejectionComment: Scalars['String']['input'];
};

export type RejectStepMutation = {
  __typename?: 'RejectStepMutation';
  approval?: Maybe<ApprovalResourceType>;
  nextStep?: Maybe<JourneyStepType>;
};

/** An enumeration. */
export enum RelationshipRoleOf1 {
  /** BUDDY */
  Buddy = 'BUDDY',
  /** MANAGER */
  Manager = 'MANAGER'
}

export type RelationshipType = {
  __typename?: 'RelationshipType';
  contact1: MembershipType;
  contact2: MembershipType;
  createdAt: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['UUID']['output'];
  roleOf1: RelationshipRoleOf1;
  startDate?: Maybe<Scalars['Date']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ReorderProjectPhasesMutation = {
  __typename?: 'ReorderProjectPhasesMutation';
  phases?: Maybe<Array<Maybe<ProjectPhaseType>>>;
};

/** Saves a flow (including flow name, flow step order and wait times) */
export type SaveFlowMutation = {
  __typename?: 'SaveFlowMutation';
  flow?: Maybe<FlowType>;
};

/** Updates a flow step */
export type SaveFlowStepMutation = {
  __typename?: 'SaveFlowStepMutation';
  flowStep?: Maybe<FlowStepType>;
};

export type SearchableField = {
  __typename?: 'SearchableField';
  identifier?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type SetFlowCurrentEditorInputType = {
  currentEditor?: InputMaybe<BaseMembershipInput>;
  editorAssignTime?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  resetCurrentEditor?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetFlowCurrentEditorMutation = {
  __typename?: 'SetFlowCurrentEditorMutation';
  flow?: Maybe<FlowType>;
};

export type SlackChannelType = {
  __typename?: 'SlackChannelType';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SnoozeJourneyMutation = {
  __typename?: 'SnoozeJourneyMutation';
  journey?: Maybe<JourneyType>;
};

export type StepResourceType = ApprovalResourceType | DocumentResourceType | FormResourceType | HttpRequestResourceType | MessageResourceType | TaskResourceType | TodoListResourceType;

export type StepRuleActionConfigInputType = {
  newValue?: InputMaybe<Scalars['String']['input']>;
  step?: InputMaybe<Scalars['String']['input']>;
  variable?: InputMaybe<Scalars['String']['input']>;
};

export type StepRuleActionConfigType = {
  __typename?: 'StepRuleActionConfigType';
  newValue?: Maybe<Scalars['String']['output']>;
  step?: Maybe<Scalars['String']['output']>;
  variable?: Maybe<Scalars['String']['output']>;
};

export type StepRuleInputType = {
  action: ActionEnum;
  actionConfig: StepRuleActionConfigInputType;
  condition: Scalars['String']['input'];
  evaluateNext: Scalars['Boolean']['input'];
};

export type StepRuleType = {
  __typename?: 'StepRuleType';
  action: ActionEnum;
  actionConfig: StepRuleActionConfigType;
  condition: Scalars['String']['output'];
  evaluateNext: Scalars['Boolean']['output'];
};

export type SubmitFormMutation = {
  __typename?: 'SubmitFormMutation';
  form?: Maybe<FormResourceType>;
  nextStep?: Maybe<JourneyStepType>;
};

export type SubmitTaskMutation = {
  __typename?: 'SubmitTaskMutation';
  nextStep?: Maybe<JourneyStepType>;
  task?: Maybe<TaskResourceType>;
};

export type SubtaskInputType = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  receiverId?: InputMaybe<Scalars['String']['input']>;
  receiverRole?: InputMaybe<Scalars['String']['input']>;
  receiverTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type SubtaskToggleInput = {
  id: Scalars['String']['input'];
  isDone: Scalars['Boolean']['input'];
};

export type SubtaskType = {
  __typename?: 'SubtaskType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isDone: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  task: TaskResourceType;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Scalars['JSONString']['output'];
};

export type TaskInputType = {
  connectedFlowId?: InputMaybe<Scalars['String']['input']>;
  connectedFlowInitialVariableValues?: InputMaybe<Scalars['JSONString']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  orderedSubtasks?: InputMaybe<Array<InputMaybe<SubtaskInputType>>>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  receiverRole?: InputMaybe<Scalars['String']['input']>;
  receiverTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type TaskResourceType = {
  __typename?: 'TaskResourceType';
  connectedFlow?: Maybe<FlowType>;
  connectedFlowId?: Maybe<Scalars['String']['output']>;
  connectedFlowInitialVariableValues?: Maybe<Scalars['JSONString']['output']>;
  connectedJourneysId?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dueDate?: Maybe<Scalars['Date']['output']>;
  flowStep?: Maybe<FlowStepType>;
  id: Scalars['UUID']['output'];
  isCustom: Scalars['Boolean']['output'];
  journeyStep?: Maybe<JourneyStepType>;
  orderedSubtasks?: Maybe<Array<Maybe<SubtaskType>>>;
  receiver?: Maybe<MembershipType>;
  receiverId?: Maybe<Scalars['String']['output']>;
  receiverRole?: Maybe<Scalars['String']['output']>;
  receiverTeam?: Maybe<TeamType>;
  receiverTeamId?: Maybe<Scalars['String']['output']>;
  rejectedInApproval?: Maybe<ApprovalResourceType>;
  subtasks?: Maybe<Array<Maybe<SubtaskType>>>;
  taskType: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Scalars['JSONString']['output'];
};


export type TaskResourceTypeConnectedFlowInitialVariableValuesArgs = {
  parsed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TaskSubmitInputType = {
  id: Scalars['String']['input'];
};

export type TeamType = {
  __typename?: 'TeamType';
  approvals: Array<ApprovalResourceType>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  externalSystem?: Maybe<ExternalSystemType>;
  forms: Array<FormResourceType>;
  id: Scalars['UUID']['output'];
  members?: Maybe<Array<Maybe<BaseMembershipType>>>;
  messages: Array<MessageResourceType>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  tasks: Array<TaskResourceType>;
  todoLists: Array<TodoListResourceType>;
  updatedAt: Scalars['DateTime']['output'];
  workspace: WorkspaceType;
};

export type TodoItemInputType = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isDone?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  resourceDisplayName?: InputMaybe<Scalars['String']['input']>;
  resourceUrl?: InputMaybe<Scalars['String']['input']>;
};

export type TodoItemToggleInput = {
  id: Scalars['String']['input'];
  isDone: Scalars['Boolean']['input'];
};

export type TodoItemToggleMutation = {
  __typename?: 'TodoItemToggleMutation';
  nextStep?: Maybe<JourneyStepType>;
  todoItem?: Maybe<TodoItemType>;
};

export type TodoItemType = {
  __typename?: 'TodoItemType';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  isDone: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  resourceDisplayName: Scalars['String']['output'];
  resourceUrl: Scalars['String']['output'];
  todoList: TodoListResourceType;
  updatedAt: Scalars['DateTime']['output'];
  warnings: Scalars['JSONString']['output'];
};

export type TodoListInputType = {
  caption?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  orderedTodoItems?: InputMaybe<Array<InputMaybe<TodoItemInputType>>>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  receiverRole?: InputMaybe<Scalars['String']['input']>;
  receiverTeamId?: InputMaybe<Scalars['String']['input']>;
};

export type TodoListResourceType = {
  __typename?: 'TodoListResourceType';
  caption?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  flowStep?: Maybe<FlowStepType>;
  id: Scalars['UUID']['output'];
  journeyStep?: Maybe<JourneyStepType>;
  orderedTodoItems?: Maybe<Array<Maybe<TodoItemType>>>;
  receiver?: Maybe<MembershipType>;
  receiverId?: Maybe<Scalars['String']['output']>;
  receiverRole?: Maybe<Scalars['String']['output']>;
  receiverTeam?: Maybe<TeamType>;
  receiverTeamId?: Maybe<Scalars['String']['output']>;
  rejectedInApproval?: Maybe<ApprovalResourceType>;
  shouldBeCompleted?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  todoItems?: Maybe<Array<Maybe<TodoItemType>>>;
  todoListType: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  warnings: Scalars['JSONString']['output'];
};

export type ToggleJourneyArchiveStatusMutation = {
  __typename?: 'ToggleJourneyArchiveStatusMutation';
  journey?: Maybe<JourneyType>;
};

export type ToggleJourneyPauseStatusMutation = {
  __typename?: 'ToggleJourneyPauseStatusMutation';
  journey?: Maybe<JourneyType>;
};

export type ToggleSubtaskStatusMutation = {
  __typename?: 'ToggleSubtaskStatusMutation';
  subtask?: Maybe<SubtaskType>;
};

export type ToggleTaskStatusMutation = {
  __typename?: 'ToggleTaskStatusMutation';
  journeyStep?: Maybe<JourneyStepType>;
};

/** Sends a message that has been marked as in-review */
export type UpdateAndSendJourneyMessageMutation = {
  __typename?: 'UpdateAndSendJourneyMessageMutation';
  message?: Maybe<MessageResourceType>;
  nextStep?: Maybe<JourneyStepType>;
};

/** Updates the approval attached to a flow step */
export type UpdateApprovalMutation = {
  __typename?: 'UpdateApprovalMutation';
  approval?: Maybe<ApprovalResourceType>;
};

export type UpdateAuthenticatorBasicInputType = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAuthenticatorBasicMutation = {
  __typename?: 'UpdateAuthenticatorBasicMutation';
  authenticator?: Maybe<AuthenticatorBasicType>;
};

export type UpdateAuthenticatorBearerInputType = {
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAuthenticatorBearerMutation = {
  __typename?: 'UpdateAuthenticatorBearerMutation';
  authenticator?: Maybe<AuthenticatorBearerType>;
};

export type UpdateAuthenticatorOAuth2InputType = {
  authorizationFlow?: InputMaybe<Scalars['String']['input']>;
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  scope?: InputMaybe<Scalars['String']['input']>;
  urlToken?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAuthenticatorOauth2Mutation = {
  __typename?: 'UpdateAuthenticatorOauth2Mutation';
  authenticator?: Maybe<AuthenticatorOAuth2Type>;
};

export type UpdateCustomTaskInputType = {
  connectedFlowId?: InputMaybe<Scalars['String']['input']>;
  connectedFlowInitialVariableValues?: InputMaybe<Scalars['JSONString']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  orderedSubtasks?: InputMaybe<Array<InputMaybe<SubtaskInputType>>>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  receiverRole?: InputMaybe<Scalars['String']['input']>;
  receiverTeamId?: InputMaybe<Scalars['String']['input']>;
};

/** Updates a custom task attached to a journey step */
export type UpdateCustomTaskMutation = {
  __typename?: 'UpdateCustomTaskMutation';
  task?: Maybe<TaskResourceType>;
};

/** Updates the document attached to a flow step */
export type UpdateDocumentMutation = {
  __typename?: 'UpdateDocumentMutation';
  document?: Maybe<DocumentResourceType>;
};

export type UpdateFlowLabelsMutation = {
  __typename?: 'UpdateFlowLabelsMutation';
  flow?: Maybe<FlowType>;
};

/** Updates the message attached to a flow step */
export type UpdateFlowMessageMutation = {
  __typename?: 'UpdateFlowMessageMutation';
  message?: Maybe<MessageResourceType>;
};

/**
 * This mutation is used ONLY to create a new variable for the current flow step on the frontend.
 * Updates to variable usages are handled when saving the respective resources.
 */
export type UpdateFlowStepVariablesMutation = {
  __typename?: 'UpdateFlowStepVariablesMutation';
  flowStep?: Maybe<FlowStepType>;
};

export type UpdateFlowVariablesMutation = {
  __typename?: 'UpdateFlowVariablesMutation';
  flow?: Maybe<FlowType>;
};

/** Updates the Form attached to a flow step */
export type UpdateFormMutation = {
  __typename?: 'UpdateFormMutation';
  form?: Maybe<FormResourceType>;
};

export type UpdateHttpRequestResourceInputType = {
  authenticator?: InputMaybe<AuthenticatorInputType>;
  flowStepVariables?: InputMaybe<Scalars['JSONString']['input']>;
  id: Scalars['String']['input'];
  method?: InputMaybe<HttpMethodEnum>;
  requestBody?: InputMaybe<Scalars['JSONString']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  variableToResponseKeys?: InputMaybe<Scalars['JSONString']['input']>;
};

export type UpdateHttpRequestResourceMutation = {
  __typename?: 'UpdateHttpRequestResourceMutation';
  httpRequestResource?: Maybe<HttpRequestResourceType>;
};

export type UpdateJourneyMutation = {
  __typename?: 'UpdateJourneyMutation';
  journey?: Maybe<JourneyType>;
};

/**
 * This mutation is used to update variables in a journey. For now, we only allow updating of
 * project variables
 *
 * Raises:
 *     GraphQLError: When a journey with the specified ID was not found
 *     GraphQLError: When there's a validation error due to one of the variables being updated
 */
export type UpdateJourneyVariablesMutation = {
  __typename?: 'UpdateJourneyVariablesMutation';
  journey?: Maybe<JourneyType>;
};

/** Updates the TodoList attached to a flow step */
export type UpdateListMutation = {
  __typename?: 'UpdateListMutation';
  updatedList?: Maybe<TodoListResourceType>;
};

export type UpdateMembershipInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
  isFieldWorker?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  managerMembershipId?: InputMaybe<Scalars['String']['input']>;
  permissionLevel?: InputMaybe<PermissionLevelEnum>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  privateEmail?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  teams?: InputMaybe<Array<Scalars['String']['input']>>;
  workspaceEmail?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMessageInputType = {
  bccEmail?: InputMaybe<Scalars['String']['input']>;
  ccEmail?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  replyToEmail?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  toEmail?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOtherMembershipMutation = {
  __typename?: 'UpdateOtherMembershipMutation';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  membership?: Maybe<MembershipType>;
};

export type UpdateOwnMembershipInput = {
  endDate?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isFieldWorker?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  permissionLevel?: InputMaybe<PermissionLevelEnum>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  privateEmail?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
  workspaceEmail?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOwnMembershipMutation = {
  __typename?: 'UpdateOwnMembershipMutation';
  membership?: Maybe<MembershipType>;
};

export type UpdateProjectPhaseInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProjectPhaseMutation = {
  __typename?: 'UpdateProjectPhaseMutation';
  phase?: Maybe<ProjectPhaseType>;
};

export type UpdateTaskDueDateMutation = {
  __typename?: 'UpdateTaskDueDateMutation';
  projectStep?: Maybe<ProjectStepType>;
};

/** Updates the task attached to a flow step */
export type UpdateTaskMutation = {
  __typename?: 'UpdateTaskMutation';
  task?: Maybe<TaskResourceType>;
};

export type UpdateTeamInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTeamMutation = {
  __typename?: 'UpdateTeamMutation';
  team?: Maybe<BaseTeamType>;
};

/** An enumeration. */
export enum UserGender {
  /** FEMALE */
  F = 'F',
  /** MALE */
  M = 'M',
  /** OTHER */
  X = 'X'
}

export type UserType = {
  __typename?: 'UserType';
  auth0Id?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dateJoined: Scalars['DateTime']['output'];
  /** Required. */
  email: Scalars['String']['output'];
  externalSystem?: Maybe<ExternalSystemType>;
  firstName: Scalars['String']['output'];
  gender?: Maybe<UserGender>;
  id: Scalars['UUID']['output'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  isSystem: Scalars['Boolean']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  memberships: Array<MembershipType>;
  password: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  username?: Maybe<Scalars['String']['output']>;
};

export type VariableCategoryType = {
  __typename?: 'VariableCategoryType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Int']['output'];
};

export type WorkspaceType = {
  __typename?: 'WorkspaceType';
  createdAt: Scalars['DateTime']['output'];
  /** Ending of the client email, e.g. "getkwest.com" */
  emailDomain: Scalars['String']['output'];
  endDate?: Maybe<Scalars['Date']['output']>;
  externalSystems: Array<ExternalSystemType>;
  flowLabels: Array<FlowLabelType>;
  flows: Array<FlowType>;
  groups: Array<MembersGroupType>;
  hasSlackIntegration?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  journeyTags: Array<JourneyTagType>;
  legalName: Scalars['String']['output'];
  memberships: Array<MembershipType>;
  name: Scalars['String']['output'];
  projectPhases: Array<ProjectPhaseType>;
  startDate?: Maybe<Scalars['Date']['output']>;
  teams: Array<TeamType>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MyMembershipFieldsFragment = { __typename?: 'MembershipType', id: any, firstName: string, lastName: string, privateEmail: string, workspaceEmail: string, phoneNumber: string, startDate?: any | null, endDate?: any | null, permissionLevel: MembershipPermissionLevel, isAdmin: boolean, isGuest: boolean, isFieldWorker: boolean, teams?: Array<{ __typename?: 'BaseTeamType', id: any, name: string } | null> | null, featureFlags?: Array<{ __typename?: 'FeatureFlagToMemberType', id: any, featureFlag: { __typename?: 'FeatureFlagType', id: any, name: string } } | null> | null };

export type CoreMembershipFieldsFragment = { __typename?: 'MembershipType', id: any, firstName: string, lastName: string, privateEmail: string, workspaceEmail: string, phoneNumber: string, permissionLevel: MembershipPermissionLevel, externalSystem?: { __typename?: 'ExternalSystemType', id: any, name: string, domain?: string | null } | null };

export type ExtendedMembershipFieldsFragment = { __typename?: 'MembershipType', startDate?: any | null, endDate?: any | null, slackId: string, isFieldWorker: boolean, id: any, firstName: string, lastName: string, privateEmail: string, workspaceEmail: string, phoneNumber: string, permissionLevel: MembershipPermissionLevel, relationshipsIncoming: Array<{ __typename?: 'RelationshipType', roleOf1: RelationshipRoleOf1, contact1: { __typename?: 'MembershipType', id: any } }>, teams?: Array<{ __typename?: 'BaseTeamType', id: any, name: string, email?: string | null, phoneNumber?: string | null } | null> | null, membershipsToMembersgroups: Array<{ __typename?: 'MembershipToMembersGroupType', group: { __typename?: 'MembersGroupType', id: any, name: string } }>, externalSystem?: { __typename?: 'ExternalSystemType', id: any, name: string, domain?: string | null } | null };

export type TodoViewMembershipFieldsFragment = { __typename?: 'MembershipType', id: any, firstName: string, lastName: string, workspaceEmail: string };

export type TeamFieldsFragment = { __typename?: 'TeamType', id: any, name: string, email?: string | null, phoneNumber?: string | null };

export type CreateTeamClientMutationVariables = Exact<{
  input: CreateTeamInput;
}>;


export type CreateTeamClientMutation = { __typename?: 'Mutations', createTeam?: { __typename?: 'CreateTeamMutation', team?: { __typename?: 'TeamType', id: any, name: string, email?: string | null, phoneNumber?: string | null } | null } | null };

export type UpdateTeamClientMutationVariables = Exact<{
  teamId: Scalars['String']['input'];
  input: UpdateTeamInput;
}>;


export type UpdateTeamClientMutation = { __typename?: 'Mutations', updateTeam?: { __typename?: 'UpdateTeamMutation', team?: { __typename?: 'BaseTeamType', id: any, name: string, email?: string | null, phoneNumber?: string | null } | null } | null };

export type FlowInstanceQueryVariables = Exact<{
  journeyId: Scalars['String']['input'];
}>;


export type FlowInstanceQuery = { __typename?: 'Query', oneJourney?: { __typename?: 'JourneyType', id: string, flow: { __typename?: 'FlowType', name: string }, orderedJourneySteps?: Array<{ __typename?: 'JourneyStepType', id: string, status: JourneyStepStatus, completedAt?: any | null, name: string, stepType: FlowStepTypeEnum, deliveredAt?: any | null, expectedDeliveryAt?: any | null, completedBy?: { __typename?: 'MembershipType', id: any, firstName: string, lastName: string } | null, todoList?: { __typename?: 'TodoListResourceType', id: any, orderedTodoItems?: Array<{ __typename?: 'TodoItemType', id: any, isDone: boolean } | null> | null } | null } | null> | null } | null };

export type AllMembershipsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type AllMembershipsQuery = { __typename?: 'Query', allMemberships?: Array<{ __typename?: 'MembershipType', id: any, firstName: string, lastName: string, privateEmail: string, workspaceEmail: string, phoneNumber: string, permissionLevel: MembershipPermissionLevel, externalSystem?: { __typename?: 'ExternalSystemType', id: any, name: string, domain?: string | null } | null } | null> | null };

export type AllMembershipsWithAdminFieldsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']['input']>;
}>;


export type AllMembershipsWithAdminFieldsQuery = { __typename?: 'Query', allMemberships?: Array<{ __typename?: 'MembershipType', startDate?: any | null, endDate?: any | null, slackId: string, isFieldWorker: boolean, id: any, firstName: string, lastName: string, privateEmail: string, workspaceEmail: string, phoneNumber: string, permissionLevel: MembershipPermissionLevel, relationshipsIncoming: Array<{ __typename?: 'RelationshipType', roleOf1: RelationshipRoleOf1, contact1: { __typename?: 'MembershipType', id: any } }>, teams?: Array<{ __typename?: 'BaseTeamType', id: any, name: string, email?: string | null, phoneNumber?: string | null } | null> | null, membershipsToMembersgroups: Array<{ __typename?: 'MembershipToMembersGroupType', group: { __typename?: 'MembersGroupType', id: any, name: string } }>, externalSystem?: { __typename?: 'ExternalSystemType', id: any, name: string, domain?: string | null } | null } | null> | null };

export type AllTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTeamsQuery = { __typename?: 'Query', allTeams?: Array<{ __typename?: 'BaseTeamType', id: any, name: string, email?: string | null, phoneNumber?: string | null, externalSystem?: { __typename?: 'ExternalSystemType', id: any, name: string } | null } | null> | null };

export type AllSlackChannelsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSlackChannelsQuery = { __typename?: 'Query', allSlackChannels?: Array<{ __typename?: 'SlackChannelType', id?: string | null, name?: string | null } | null> | null };

export type MyWorkspaceQueryVariables = Exact<{ [key: string]: never; }>;


export type MyWorkspaceQuery = { __typename?: 'Query', myWorkspace?: { __typename?: 'WorkspaceType', id: any, externalSystems: Array<{ __typename?: 'ExternalSystemType', id: any, name: string }> } | null };

export const MyMembershipFieldsFragmentDoc = gql`
    fragment MyMembershipFields on MembershipType {
  id
  firstName
  lastName
  privateEmail
  workspaceEmail
  phoneNumber
  startDate
  endDate
  permissionLevel
  isAdmin @client
  isGuest @client
  isFieldWorker
  teams {
    id
    name
  }
  featureFlags {
    id
    featureFlag {
      id
      name
    }
  }
}
    `;
export const CoreMembershipFieldsFragmentDoc = gql`
    fragment CoreMembershipFields on MembershipType {
  id
  firstName
  lastName
  privateEmail
  workspaceEmail
  phoneNumber
  permissionLevel
  externalSystem {
    id
    name
    domain
  }
}
    `;
export const ExtendedMembershipFieldsFragmentDoc = gql`
    fragment ExtendedMembershipFields on MembershipType {
  ...CoreMembershipFields
  startDate
  endDate
  slackId
  isFieldWorker
  relationshipsIncoming {
    contact1 {
      id
    }
    roleOf1
  }
  teams {
    id
    name
    email
    phoneNumber
  }
  membershipsToMembersgroups {
    group {
      id
      name
    }
  }
}
    ${CoreMembershipFieldsFragmentDoc}`;
export const TodoViewMembershipFieldsFragmentDoc = gql`
    fragment TodoViewMembershipFields on MembershipType {
  id
  firstName
  lastName
  workspaceEmail
}
    `;
export const TeamFieldsFragmentDoc = gql`
    fragment TeamFields on TeamType {
  id
  name
  email
  phoneNumber
}
    `;
export const CreateTeamClientDocument = gql`
    mutation CreateTeamClient($input: CreateTeamInput!) {
  createTeam(teamInput: $input) {
    team {
      ...TeamFields
    }
  }
}
    ${TeamFieldsFragmentDoc}`;
export type CreateTeamClientMutationFn = Apollo.MutationFunction<CreateTeamClientMutation, CreateTeamClientMutationVariables>;

/**
 * __useCreateTeamClientMutation__
 *
 * To run a mutation, you first call `useCreateTeamClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamClientMutation, { data, loading, error }] = useCreateTeamClientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTeamClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateTeamClientMutation, CreateTeamClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTeamClientMutation, CreateTeamClientMutationVariables>(CreateTeamClientDocument, options);
      }
export type CreateTeamClientMutationHookResult = ReturnType<typeof useCreateTeamClientMutation>;
export type CreateTeamClientMutationResult = Apollo.MutationResult<CreateTeamClientMutation>;
export type CreateTeamClientMutationOptions = Apollo.BaseMutationOptions<CreateTeamClientMutation, CreateTeamClientMutationVariables>;
export const UpdateTeamClientDocument = gql`
    mutation UpdateTeamClient($teamId: String!, $input: UpdateTeamInput!) {
  updateTeam(teamId: $teamId, teamInput: $input) {
    team {
      id
      name
      email
      phoneNumber
    }
  }
}
    `;
export type UpdateTeamClientMutationFn = Apollo.MutationFunction<UpdateTeamClientMutation, UpdateTeamClientMutationVariables>;

/**
 * __useUpdateTeamClientMutation__
 *
 * To run a mutation, you first call `useUpdateTeamClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamClientMutation, { data, loading, error }] = useUpdateTeamClientMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTeamClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamClientMutation, UpdateTeamClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamClientMutation, UpdateTeamClientMutationVariables>(UpdateTeamClientDocument, options);
      }
export type UpdateTeamClientMutationHookResult = ReturnType<typeof useUpdateTeamClientMutation>;
export type UpdateTeamClientMutationResult = Apollo.MutationResult<UpdateTeamClientMutation>;
export type UpdateTeamClientMutationOptions = Apollo.BaseMutationOptions<UpdateTeamClientMutation, UpdateTeamClientMutationVariables>;
export const FlowInstanceDocument = gql`
    query FlowInstance($journeyId: String!) {
  oneJourney(journeyId: $journeyId) {
    id
    flow {
      name
    }
    orderedJourneySteps {
      id
      status
      completedAt
      completedBy {
        id
        firstName
        lastName
      }
      name
      stepType
      deliveredAt
      expectedDeliveryAt
      todoList {
        id
        orderedTodoItems {
          id
          isDone
        }
      }
    }
  }
}
    `;

/**
 * __useFlowInstanceQuery__
 *
 * To run a query within a React component, call `useFlowInstanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlowInstanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlowInstanceQuery({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useFlowInstanceQuery(baseOptions: Apollo.QueryHookOptions<FlowInstanceQuery, FlowInstanceQueryVariables> & ({ variables: FlowInstanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlowInstanceQuery, FlowInstanceQueryVariables>(FlowInstanceDocument, options);
      }
export function useFlowInstanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlowInstanceQuery, FlowInstanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlowInstanceQuery, FlowInstanceQueryVariables>(FlowInstanceDocument, options);
        }
export function useFlowInstanceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FlowInstanceQuery, FlowInstanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FlowInstanceQuery, FlowInstanceQueryVariables>(FlowInstanceDocument, options);
        }
export type FlowInstanceQueryHookResult = ReturnType<typeof useFlowInstanceQuery>;
export type FlowInstanceLazyQueryHookResult = ReturnType<typeof useFlowInstanceLazyQuery>;
export type FlowInstanceSuspenseQueryHookResult = ReturnType<typeof useFlowInstanceSuspenseQuery>;
export type FlowInstanceQueryResult = Apollo.QueryResult<FlowInstanceQuery, FlowInstanceQueryVariables>;
export const AllMembershipsDocument = gql`
    query AllMemberships($search: String) {
  allMemberships(search: $search) {
    ...CoreMembershipFields
  }
}
    ${CoreMembershipFieldsFragmentDoc}`;

/**
 * __useAllMembershipsQuery__
 *
 * To run a query within a React component, call `useAllMembershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMembershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMembershipsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAllMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<AllMembershipsQuery, AllMembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMembershipsQuery, AllMembershipsQueryVariables>(AllMembershipsDocument, options);
      }
export function useAllMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMembershipsQuery, AllMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMembershipsQuery, AllMembershipsQueryVariables>(AllMembershipsDocument, options);
        }
export function useAllMembershipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllMembershipsQuery, AllMembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllMembershipsQuery, AllMembershipsQueryVariables>(AllMembershipsDocument, options);
        }
export type AllMembershipsQueryHookResult = ReturnType<typeof useAllMembershipsQuery>;
export type AllMembershipsLazyQueryHookResult = ReturnType<typeof useAllMembershipsLazyQuery>;
export type AllMembershipsSuspenseQueryHookResult = ReturnType<typeof useAllMembershipsSuspenseQuery>;
export type AllMembershipsQueryResult = Apollo.QueryResult<AllMembershipsQuery, AllMembershipsQueryVariables>;
export const AllMembershipsWithAdminFieldsDocument = gql`
    query AllMembershipsWithAdminFields($search: String) {
  allMemberships(search: $search) {
    ...ExtendedMembershipFields
  }
}
    ${ExtendedMembershipFieldsFragmentDoc}`;

/**
 * __useAllMembershipsWithAdminFieldsQuery__
 *
 * To run a query within a React component, call `useAllMembershipsWithAdminFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMembershipsWithAdminFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMembershipsWithAdminFieldsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAllMembershipsWithAdminFieldsQuery(baseOptions?: Apollo.QueryHookOptions<AllMembershipsWithAdminFieldsQuery, AllMembershipsWithAdminFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMembershipsWithAdminFieldsQuery, AllMembershipsWithAdminFieldsQueryVariables>(AllMembershipsWithAdminFieldsDocument, options);
      }
export function useAllMembershipsWithAdminFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMembershipsWithAdminFieldsQuery, AllMembershipsWithAdminFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMembershipsWithAdminFieldsQuery, AllMembershipsWithAdminFieldsQueryVariables>(AllMembershipsWithAdminFieldsDocument, options);
        }
export function useAllMembershipsWithAdminFieldsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllMembershipsWithAdminFieldsQuery, AllMembershipsWithAdminFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllMembershipsWithAdminFieldsQuery, AllMembershipsWithAdminFieldsQueryVariables>(AllMembershipsWithAdminFieldsDocument, options);
        }
export type AllMembershipsWithAdminFieldsQueryHookResult = ReturnType<typeof useAllMembershipsWithAdminFieldsQuery>;
export type AllMembershipsWithAdminFieldsLazyQueryHookResult = ReturnType<typeof useAllMembershipsWithAdminFieldsLazyQuery>;
export type AllMembershipsWithAdminFieldsSuspenseQueryHookResult = ReturnType<typeof useAllMembershipsWithAdminFieldsSuspenseQuery>;
export type AllMembershipsWithAdminFieldsQueryResult = Apollo.QueryResult<AllMembershipsWithAdminFieldsQuery, AllMembershipsWithAdminFieldsQueryVariables>;
export const AllTeamsDocument = gql`
    query AllTeams {
  allTeams {
    id
    name
    email
    phoneNumber
    externalSystem {
      id
      name
    }
  }
}
    `;

/**
 * __useAllTeamsQuery__
 *
 * To run a query within a React component, call `useAllTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTeamsQuery(baseOptions?: Apollo.QueryHookOptions<AllTeamsQuery, AllTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTeamsQuery, AllTeamsQueryVariables>(AllTeamsDocument, options);
      }
export function useAllTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTeamsQuery, AllTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTeamsQuery, AllTeamsQueryVariables>(AllTeamsDocument, options);
        }
export function useAllTeamsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllTeamsQuery, AllTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllTeamsQuery, AllTeamsQueryVariables>(AllTeamsDocument, options);
        }
export type AllTeamsQueryHookResult = ReturnType<typeof useAllTeamsQuery>;
export type AllTeamsLazyQueryHookResult = ReturnType<typeof useAllTeamsLazyQuery>;
export type AllTeamsSuspenseQueryHookResult = ReturnType<typeof useAllTeamsSuspenseQuery>;
export type AllTeamsQueryResult = Apollo.QueryResult<AllTeamsQuery, AllTeamsQueryVariables>;
export const AllSlackChannelsDocument = gql`
    query AllSlackChannels {
  allSlackChannels {
    id
    name
  }
}
    `;

/**
 * __useAllSlackChannelsQuery__
 *
 * To run a query within a React component, call `useAllSlackChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSlackChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSlackChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSlackChannelsQuery(baseOptions?: Apollo.QueryHookOptions<AllSlackChannelsQuery, AllSlackChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSlackChannelsQuery, AllSlackChannelsQueryVariables>(AllSlackChannelsDocument, options);
      }
export function useAllSlackChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSlackChannelsQuery, AllSlackChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSlackChannelsQuery, AllSlackChannelsQueryVariables>(AllSlackChannelsDocument, options);
        }
export function useAllSlackChannelsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllSlackChannelsQuery, AllSlackChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllSlackChannelsQuery, AllSlackChannelsQueryVariables>(AllSlackChannelsDocument, options);
        }
export type AllSlackChannelsQueryHookResult = ReturnType<typeof useAllSlackChannelsQuery>;
export type AllSlackChannelsLazyQueryHookResult = ReturnType<typeof useAllSlackChannelsLazyQuery>;
export type AllSlackChannelsSuspenseQueryHookResult = ReturnType<typeof useAllSlackChannelsSuspenseQuery>;
export type AllSlackChannelsQueryResult = Apollo.QueryResult<AllSlackChannelsQuery, AllSlackChannelsQueryVariables>;
export const MyWorkspaceDocument = gql`
    query MyWorkspace {
  myWorkspace {
    id
    externalSystems {
      id
      name
    }
  }
}
    `;

/**
 * __useMyWorkspaceQuery__
 *
 * To run a query within a React component, call `useMyWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyWorkspaceQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyWorkspaceQuery(baseOptions?: Apollo.QueryHookOptions<MyWorkspaceQuery, MyWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyWorkspaceQuery, MyWorkspaceQueryVariables>(MyWorkspaceDocument, options);
      }
export function useMyWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyWorkspaceQuery, MyWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyWorkspaceQuery, MyWorkspaceQueryVariables>(MyWorkspaceDocument, options);
        }
export function useMyWorkspaceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MyWorkspaceQuery, MyWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MyWorkspaceQuery, MyWorkspaceQueryVariables>(MyWorkspaceDocument, options);
        }
export type MyWorkspaceQueryHookResult = ReturnType<typeof useMyWorkspaceQuery>;
export type MyWorkspaceLazyQueryHookResult = ReturnType<typeof useMyWorkspaceLazyQuery>;
export type MyWorkspaceSuspenseQueryHookResult = ReturnType<typeof useMyWorkspaceSuspenseQuery>;
export type MyWorkspaceQueryResult = Apollo.QueryResult<MyWorkspaceQuery, MyWorkspaceQueryVariables>;