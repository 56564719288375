import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface FooterButtonsProps {
  onCancel?: () => void;
  onConfirm?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
}

function FooterButtons({ onCancel, onConfirm, isDisabled, isLoading }: FooterButtonsProps) {
  const { t } = useTranslation();

  return (
    <>
      <Button
        mr={3}
        variant="ghost"
        onClick={onCancel}
        data-testid="generic-modal-footer-cancel-button"
      >
        {t("global.actions.cancel")}
      </Button>
      <Button
        onClick={onConfirm}
        data-testid="generic-modal-footer-confirm-button"
        colorScheme="purple"
        disabled={isDisabled}
        isLoading={isLoading}
      >
        {t("global.actions.confirm")}
      </Button>
    </>
  );
}

export default FooterButtons;
