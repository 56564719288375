import { useLazyQuery } from "@apollo/client";
import type { AllMembershipsQueryResult, MembershipType } from "@flows-platform/generated/graphql";
import { useCallback } from "react";

import ALL_MEMBERSHIPS_WITH_ADMIN_FIELDS from "../../../graphql/queries/people/allMembershipsWithAdminFields";

function useAllMembershipsWithAdminFields() {
  const [loadMemberships, { loading, error: loadingError }] = useLazyQuery(
    ALL_MEMBERSHIPS_WITH_ADMIN_FIELDS
  );

  const memberWarning = useCallback(({ slackId }: { slackId: string }) => {
    if (!slackId) {
      return "No Slack Integration: messages might be delivered over email";
    }

    return undefined;
  }, []);

  /**
   * This function will call the query to load the memberships that match the given search query and result the formatted options
   */
  const loadAllMemberships = useCallback(
    async (input: string) =>
      loadMemberships({
        variables: {
          search: input,
        },
      }).then((res: AllMembershipsQueryResult) =>
        res.data
          ? res.data.allMemberships?.map(
              ({ firstName, lastName, workspaceEmail, id, slackId }: MembershipType) => ({
                label: `${firstName} ${lastName}`,
                value: { id, firstName, lastName, _typename: "MembershipType" as const },
                workspaceEmail,
                ...(memberWarning({ slackId })
                  ? { warningMessage: memberWarning({ slackId }) }
                  : {}),
              })
            )
          : []
      ),
    [loadMemberships, memberWarning]
  );

  return { loadAllMemberships, loading, loadingError };
}

export default useAllMembershipsWithAdminFields;
