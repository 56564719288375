import { useAllTeamsLazyQuery } from "@core/generated/graphql";
import { useCallback, useMemo } from "react";
import type { OptionProps } from "react-select";

import useAllMembershipsWithAdminFields from "../../modules/Shared/hooks/useAllMembershipsWithAdminFields";
import type { FormikSelectFieldSelectOption } from "../FormikSelectField/FormikSelectField";
import type { FormikMembershipSelectFieldProps } from "./components/FormikMembershipSelectField/FormikMembershipSelectField";
import FormikMembershipSelectField from "./components/FormikMembershipSelectField/FormikMembershipSelectField";
import type { CustomUserSelectOptionData } from "./CustomUserSelectOption";
import CustomUserSelectOption, { SelectFieldStyles } from "./CustomUserSelectOption";

interface MembershipSelectorProps {
  name?: string;
  loading?: boolean;
  assignedMembershipIds?: string[];
  label?: string;
  placeholder?: string;
  defaultMenuIsOpen?: boolean;
  loadMemberships?: (input: string) => Promise<FormikSelectFieldSelectOption[]>;
  formatMemberships?: (membership: FormikSelectFieldSelectOption) => FormikSelectFieldSelectOption;
  showLabel?: boolean;
  showTeams?: boolean;
  mapValue?: (value: string[] | string) => Record<string, string> | string[] | string;
  filterMemberships?: (membership: FormikSelectFieldSelectOption) => boolean;
}

function MembershipSelector({
  name,
  loading: externalLoading,
  assignedMembershipIds = [],
  label = "Select a person to assign",
  placeholder = "Start typing",
  defaultMenuIsOpen,
  loadMemberships,
  formatMemberships,
  showLabel = true,
  showTeams = false,
  mapValue,
  filterMemberships = () => true,
  ...rest
}: FormikMembershipSelectFieldProps & MembershipSelectorProps) {
  const { loadAllMemberships, loading, loadingError } = useAllMembershipsWithAdminFields();
  const [getAllTeams, { loading: allTeamsLoading }] = useAllTeamsLazyQuery();

  const assignedMembershipIdsMap = useMemo(
    () =>
      assignedMembershipIds.reduce<Record<string, boolean>>(
        (objectMap, id) => ({
          ...objectMap,
          [id]: true,
        }),
        {}
      ),
    [assignedMembershipIds]
  );

  const formatMembershipOptions = (member: FormikSelectFieldSelectOption) => ({
    ...member,
    ...(assignedMembershipIdsMap[member.value] ? { isAssigned: true, isDisabled: true } : {}),
  });

  const getAllTeamMembers = useCallback(async (input: string) => {
    const { data } = await getAllTeams();

    return (
      data?.allTeams
        ?.filter((team) => team?.name.toLowerCase().includes(input.toLowerCase()))
        .map((team) => ({
          label: team?.name || "",
          value: { id: team?.id, __typename: "BaseTeamType" as const, name: team?.name || "" },
        })) || []
    );
  }, []);

  const loadAndFilterMemberships = async (input: string) => {
    const allMemberships = await (loadMemberships || loadAllMemberships)(input);
    return allMemberships.filter(filterMemberships);
  };

  const onSelectSearch = async (input: string) => {
    const formattedMemberships = (await loadAndFilterMemberships(input)).map(
      formatMemberships || formatMembershipOptions
    );
    const teams = showTeams ? await getAllTeamMembers(input) : [];
    return [...(formattedMemberships || []), ...teams];
  };

  return (
    <FormikMembershipSelectField
      name={name}
      label={showLabel ? label : undefined}
      placeholder={placeholder}
      loading={loading || allTeamsLoading || externalLoading}
      optionComponent={(props) => {
        // TODO: Fix typing issues
        return (
          <CustomUserSelectOption
            useTeamIndicators={showTeams}
            {...(props as OptionProps<CustomUserSelectOptionData>)}
          />
        );
      }}
      styles={SelectFieldStyles}
      onSearch={onSelectSearch}
      defaultMenuIsOpen={defaultMenuIsOpen}
      disabled={!!loadingError}
      mapValue={mapValue}
      {...rest}
    />
  );
}

export default MembershipSelector;
