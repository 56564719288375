import type { AppState } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "@kwest_fe/core/src/config";
import React from "react";
import { useNavigate } from "react-router-dom";

function Auth0ProviderWithHistory({ children }: React.PropsWithChildren) {
  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.auth0.domain}
      clientId={config.auth0.clientId}
      redirectUri={config.auth0.redirectUri}
      audience={config.auth0.audience}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
