import { Flex, useDisclosure } from "@chakra-ui/react";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useTranslation } from "react-i18next";

import AddPhaseModal from "./AddPhaseModal";
import PhasesTable from "./PhasesTable";

const PhasesSection = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex flexDir={"column"} gap={8} w={"full"} alignItems={"end"} mb={6}>
      <PhasesTable />
      <div>
        <AddButton onClick={onOpen} isLoading={isOpen} data-testid="add-phase-button">
          {t("pages.settings.sections.phases.actions.add")}
        </AddButton>
      </div>
      <AddPhaseModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

export default PhasesSection;
