import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Text,
  VStack,
} from "@chakra-ui/react";
import { MdRefresh } from "react-icons/md";

interface ErrorFallbackProps {
  error: { message: string };
  componentStack: string | null;
  resetError: () => void;
}

function ErrorFallback({ error, componentStack, resetError }: ErrorFallbackProps) {
  const isProduction = import.meta.env.NODE_ENV === "production";
  return (
    <VStack
      alignItems={isProduction ? "center" : "flex-start"}
      p={6}
      py={isProduction ? 12 : undefined}
      role="alert"
      w="full"
      spacing={4}
    >
      <Text fontSize="md" fontWeight="bold">
        Something went wrong
      </Text>
      {isProduction && (
        <Box m="0 auto" w="90%" textAlign="center">
          <Text>
            Our engineers have been notified and will work on this as soon as possible. Your work
            has not been lost, a simple retry should bring you back. Please click to refresh.
          </Text>
        </Box>
      )}
      {!isProduction && (
        <>
          <Text color="red">{JSON.stringify(error)}</Text>
          {componentStack && (
            <Accordion w="full" pb={3}>
              <AccordionItem>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Component stack
                  </Box>
                </AccordionButton>
                <AccordionPanel>{componentStack}</AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </>
      )}
      {resetError &&
        (!isProduction ? (
          <Button onClick={resetError}>Click here to reset</Button>
        ) : (
          <Button onClick={resetError} leftIcon={<MdRefresh />} variant="outline" size="sm">
            Reset
          </Button>
        ))}
    </VStack>
  );
}

export default ErrorFallback;
