import { Box } from "@chakra-ui/react";
import { StackingContextEnum } from "@flows-platform/utils/enums";
import type { PropsWithChildren } from "react";
import { useDragDropManager, useDrop } from "react-dnd";

export interface FlowEditorDroppableProps<TDragObj> {
  accept: string[];
  onDropped: (draggedStep: TDragObj, manager: ReturnType<typeof useDragDropManager>) => void;
}

export default function FlowEditorDroppable<
  TDragObject,
  TDropResult extends TDragObject,
  TCollectedProps,
>({ children, accept, onDropped }: PropsWithChildren<FlowEditorDroppableProps<TDragObject>>) {
  const manager = useDragDropManager();

  const [, drop] = useDrop<TDragObject, TDropResult, TCollectedProps>(() => ({
    accept,
    drop: (item) => item as TDropResult,
    hover: (draggedStep) => {
      onDropped(draggedStep, manager);
    },
  }));

  return (
    <Box ref={drop} pos="relative" minH="full" zIndex={StackingContextEnum.Foreground}>
      {children}
    </Box>
  );
}
