/**
 * Component specific settings that can be re-used
 */

const toastSettings = {
  variant: "subtle",
  isClosable: false,
};

export default toastSettings;
