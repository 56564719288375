import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const EmptyChatPlaceholder = () => {
  const { t } = useTranslation();
  return (
    <Box
      w={"full"}
      display={"flex"}
      flexDir={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Text color={"gray.500"} fontSize={"xl"} textAlign={"center"}>
        {t("pages.project_instance.comments.no_comments")}
      </Text>
    </Box>
  );
};

export default EmptyChatPlaceholder;
