import { Box, Text } from "@chakra-ui/react";
import { AvatarIcon } from "@core/theme/icons";

import getInitials from "./utils/getInitials";

interface AvatarProps {
  name?: string;
  initials?: string;
  size?: AvatarSize;
  color?: AvatarColor;
  borderRadius?: string;
}

type AvatarSize = "lg" | "md" | "sm";
type AvatarColor = "primary" | "white";

const sizeProps = {
  sm: {
    fontSize: "11px",
    w: "24px",
    h: "24px",
    lineHeight: "24px",
  },
  md: {
    fontSize: "sm",
    w: "32px",
    h: "32px",
    lineHeight: "32px",
  },
  lg: {
    fontSize: "4xl",
    w: "96px",
    h: "96px",
    lineHeight: "96px",
  },
};

const colorProps = {
  white: {
    color: "white",
  },
  primary: {
    color: "primary.main",
  },
};

export default function Avatar({
  size = "md",
  borderRadius = "full",
  name,
  color = "white",
  initials = "AA",
}: AvatarProps) {
  const topPadding = size === "sm" ? 1 : 2;
  const scaledIconSizeInPx = size === "sm" ? 20 : 32;

  return (
    <Box
      w={sizeProps[size].w}
      h={sizeProps[size].h}
      bg="gray.300"
      display="flex"
      borderRadius={borderRadius}
      lineHeight={sizeProps[size].lineHeight}
      alignItems="center"
      justifyContent="center"
      pt={!name ? topPadding : undefined}
      overflow="hidden"
    >
      {name ? (
        <Text
          color={colorProps[color].color}
          fontWeight="black"
          fontSize={sizeProps[size].fontSize}
        >
          {getInitials(name) || initials}
        </Text>
      ) : (
        <AvatarIcon scaledSize={scaledIconSizeInPx} />
      )}
    </Box>
  );
}
