import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Tag,
  Text,
} from "@chakra-ui/react";
import CaptionText from "@kwest_fe/core/src/components/UI/atoms/Text/CaptionText/CaptionText";
import { SearchIcon } from "@todo-viewer/theme/icons";
import { TEXT_STYLES } from "@todo-viewer/theme/text";
import type { FieldProps } from "formik";
import { ErrorMessage, Field, useField } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type {
  ControlProps,
  OptionProps,
  Options,
  SingleValueProps,
  StylesConfig,
  ValueContainerProps,
} from "react-select";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";

import SelectedOptionsTags from "./SelectOptionsTags";

function TagSingleValueDisplay({
  children,
  ...props
}: SingleValueProps & { data: { color: string } }) {
  return (
    <components.SingleValue {...props}>
      <Tag bg={props.data.color} color="white">
        {children}
      </Tag>
    </components.SingleValue>
  );
}

export function SearchControl({ children, ...props }: ControlProps) {
  return (
    <components.Control {...props}>
      <Box
        fontSize={"14px"}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
        marginLeft={".7em"}
      >
        <SearchIcon />
        {children}
      </Box>
    </components.Control>
  );
}

export function MultiSelectOption({ children, ...props }: OptionProps) {
  const { isSelected } = props;
  return (
    <components.Option {...props}>
      <HStack pointerEvents="none">
        <Checkbox isChecked={isSelected} />
        <Text>{children}</Text>
      </HStack>
    </components.Option>
  );
}

export const ValueContainer = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  children,
  ...props
}: ValueContainerProps<FormikSelectFieldSelectOption, true>) => {
  const selectedOptions = props.selectProps.value;
  const { t } = useTranslation();

  return (
    <components.ValueContainer {...props}>
      {Array.isArray(selectedOptions) && (
        <Text color="gray.500">
          {selectedOptions?.length
            ? `${selectedOptions.length} selected`
            : `${t("global.actions.select")}...`}
        </Text>
      )}
    </components.ValueContainer>
  );
};

export interface FormikSelectFieldSelectOption {
  label: string;
  value: any;
}

export interface FormikSelectFieldProps<
  T extends FormikSelectFieldSelectOption = FormikSelectFieldSelectOption,
> {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  options?: Options<T> | (() => Promise<Options<T>>);
  isSearchable?: boolean;
  canCreate?: boolean;
  isMulti?: boolean;
  onCreate?: (value: string) => Promise<void>;
  loading?: boolean;
  loadingText?: string;
  disabled?: boolean;
  optionComponent?: React.ComponentType<OptionProps>;
  styles?: StylesConfig;
  labelStyles?: React.CSSProperties;
  onSearch?: (input: string) => Promise<Options<T>>;
  onChange?: (selected: string[] | string, oldValue: string[] | string) => void;
  onBlur?: () => void;
  defaultMenuIsOpen?: boolean;
  captionText?: string;
  showOptionalIndicator?: boolean;
  useTagSingleDisplay?: boolean;
}

/**
 * Utility select input to use within Formik forms.
 */
function FormikSelectField<OptionType extends FormikSelectFieldSelectOption>({
  name,
  label,
  required = false,
  placeholder,
  options,
  isMulti,
  canCreate,
  onCreate,
  loading,
  loadingText,
  disabled,
  optionComponent,
  styles = {},
  onSearch,
  onChange,
  labelStyles,
  isSearchable,
  defaultMenuIsOpen = false,
  captionText,
  onBlur,
  showOptionalIndicator,
  useTagSingleDisplay = false,
}: FormikSelectFieldProps<OptionType>) {
  const [{ value }] = useField(name);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);
  const [cachedOptions, setCachedOptions] = useState<Options<OptionType>>([]);
  const showIndicator = !required && showOptionalIndicator;
  /**
   * Since the selected value is initialized as a string (or string array) in the FormikContext, we do this to format the selected value in a way that understandable by the Select menu i.e the Option format => { label: "", value: "" }.
   * The logic here would also handle cases where we are loading the options asynchronously.
   * TODO: Improve how we are loading/formatting the Selected option(s) for AsyncSelect. We might need to split the components out to avoid having this cachedOptions which can be a bit more complicated to correctly maintain especially for the async case.
   */
  const selectedValue = useMemo(() => {
    if (!value) return null;
    if (isMulti) {
      return cachedOptions.filter((option) => value.includes(option.value));
    }
    return cachedOptions.find((option) => value === option.value);
  }, [value, cachedOptions, isMulti]);

  let SelectComponent: AsyncCreatableSelect | AsyncSelect | CreatableSelect | Select = canCreate
    ? CreatableSelect
    : Select;

  if (onSearch) {
    SelectComponent = canCreate ? AsyncCreatableSelect : AsyncSelect;
  }

  /**
   * This function is called by the Select menu to load options that match the input text
   */
  const loadOptions = (inputValue: string, callback: (options: Options<OptionType>) => void) => {
    if (!onSearch) return;
    onSearch(inputValue).then((resultOptions) => {
      setCachedOptions(resultOptions);
      callback(resultOptions);
    });
  };

  const loadAsyncOptions = useCallback(() => {
    setIsLoadingRequest(true);
    if (typeof options === "function")
      options().then((loadedOptions) => {
        setIsLoadingRequest(false);
        setCachedOptions(loadedOptions);
      });
  }, [options, setCachedOptions]);

  const showSelectedOptions = isMulti && Array.isArray(selectedValue) && selectedValue.length > 0;

  const onDeleted =
    (setFieldValue: (field: string, value: any) => void) => (selectedOptionValue: string) => {
      if (!showSelectedOptions) return;
      const newValue = selectedValue
        .filter((option) => option.value !== selectedOptionValue)
        .map((item) => item.value);
      if (onChange) onChange(newValue, value);
      setFieldValue(name, newValue);
    };

  useEffect(() => {
    if (options) {
      if (typeof options !== "function") {
        setCachedOptions(options);
      } else {
        loadAsyncOptions();
      }
    }
  }, [options, setCachedOptions, loadAsyncOptions]);

  return (
    <Field name={name}>
      {({ form: { setFieldValue }, meta: { error, touched } }: FieldProps) => (
        <FormControl isInvalid={!!error && touched} isRequired={required}>
          {label && (
            <FormLabel style={labelStyles} requiredIndicator={<Text as="span" />}>
              <HStack>
                <Text as="span">{label}</Text>
                {showIndicator && (
                  <Text textStyle={TEXT_STYLES.small12} as="span">
                    (optional)
                  </Text>
                )}
              </HStack>
            </FormLabel>
          )}
          {showSelectedOptions && (
            <SelectedOptionsTags
              selectedOptions={selectedValue}
              onDeleted={onDeleted(setFieldValue)}
            />
          )}
          <div data-testid={`formik-select-${name}`}>
            <SelectComponent
              aria-label={label}
              isSearchable={isSearchable}
              isMulti={isMulti}
              options={cachedOptions}
              placeholder={placeholder}
              value={selectedValue}
              onCreateOption={(optionValue) => {
                if (onCreate) {
                  setIsLoadingRequest(true);
                  onCreate(optionValue).then(() => {
                    setIsLoadingRequest(false);
                  });
                }
              }}
              onChange={(
                selected: FormikSelectFieldSelectOption | FormikSelectFieldSelectOption[]
              ) => {
                let fieldValue: string[] | string = isMulti ? [] : "";
                if (selected) {
                  if (!isMulti) {
                    fieldValue = (selected as FormikSelectFieldSelectOption).value;
                  } else if ((selected as FormikSelectFieldSelectOption[]).length) {
                    fieldValue = (selected as FormikSelectFieldSelectOption[]).map(
                      (item) => item.value
                    );
                  }
                }
                if (onChange) onChange(fieldValue, value);
                setFieldValue(name, fieldValue);
              }}
              loadOptions={loadOptions}
              defaultOptions={onSearch ? true : undefined}
              isClearable
              name={name}
              components={{
                Control: SearchControl,
                ...(isMulti ? { Option: MultiSelectOption, ValueContainer } : {}),
                ...(optionComponent ? { Option: optionComponent } : {}),
                ...(useTagSingleDisplay ? { SingleValue: TagSingleValueDisplay } : {}),
              }}
              styles={styles}
              isLoading={loading || isLoadingRequest}
              loadingMessage={() => loadingText}
              isDisabled={disabled || isLoadingRequest}
              defaultMenuIsOpen={defaultMenuIsOpen}
              onBlur={onBlur}
              menuPlacement="auto"
              closeMenuOnSelect={!isMulti}
              hideSelectedOptions={false}
              className="react-select-container"
              classNamePrefix="react-select"
            />
          </div>
          <CaptionText>{captionText}</CaptionText>

          <ErrorMessage name={name} render={(msg) => <FormErrorMessage>{msg}</FormErrorMessage>} />
        </FormControl>
      )}
    </Field>
  );
}

export default FormikSelectField;
