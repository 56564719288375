import {
  Box,
  Heading,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import type { JourneyFieldsFragment } from "@flows-platform/generated/graphql";
import {
  useAddJourneyTagClientMutation,
  useMyWorkspaceTagsClientQuery,
} from "@flows-platform/generated/graphql";
import { StackingContextEnum } from "@flows-platform/utils/enums";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import type Select from "react-select/dist/declarations/src/Select";

interface AddTagButtonProps {
  tags: JourneyFieldsFragment["tags"];
}

const AddTagButton = ({ tags }: AddTagButtonProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const { projectId } = useParams<{ projectId: string }>();

  const { data } = useMyWorkspaceTagsClientQuery();
  const [addTagMutation, { loading }] = useAddJourneyTagClientMutation();
  const selectInputRef = useRef<Select<{ id: string; label: string } | undefined> | null>(null);

  const { t } = useTranslation();
  const clearSelectInput = () => {
    selectInputRef.current?.clearValue();
  };

  const addTag = async (name: string) => {
    await addTagMutation({
      variables: { journeyId: projectId, name },
      onCompleted: () => {
        clearSelectInput();
        onClose();
      },
    });
  };

  const selectTag = (tagId: string) => {
    const selectedOption = data?.myWorkspace?.journeyTags.find((tag) => tag.id === tagId);
    if (selectedOption) {
      addTag(selectedOption.name);
    }
    return "";
  };

  const options = useMemo(() => {
    return (
      data?.myWorkspace?.journeyTags
        .map((tag) => ({ id: tag.id, label: tag.name }))
        .filter(
          (workspaceTag) => !tags?.some((projectTag) => projectTag?.id === workspaceTag.id)
        ) || []
    );
  }, [data?.myWorkspace?.journeyTags, tags]);

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box>
          <AddButton onClick={onToggle} isLoading={loading} />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody m={1}>
          <Heading size="md" color="gray.700" fontSize="md" mb={2} fontWeight={500}>
            {t("pages.project_instance.tags.select_label")}
          </Heading>
          {data?.myWorkspace && isOpen ? (
            <CreatableSelect
              menuPortalTarget={document.body}
              ref={selectInputRef}
              blurInputOnSelect
              isClearable
              options={options}
              onCreateOption={addTag}
              styles={{
                input: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
                placeholder: (provided) => ({
                  ...provided,
                  fontSize: "14px",
                }),
                menuList: (provided) => ({
                  ...provided,
                  maxHeight: 150,
                  fontSize: "14px",
                  zIndex: 10,
                }),
                menuPortal: (base) => ({ ...base, zIndex: StackingContextEnum.Widgets }),
              }}
              placeholder={t("pages.project_instance.tags.select_placeholder")}
              onChange={(option) => {
                if (option?.id) selectTag(option?.id);
              }}
            />
          ) : (
            <Text>{t("global.loaders.loading")} </Text>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AddTagButton;
