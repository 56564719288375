import CompositeLoader from "@kwest_fe/core/src/components/CompositeLoader";

import { useMyWorkspaceTagsClientQuery } from "../../generated/graphql";
import EditTagsPage from "./EditTagsPage";

export default function EditTagsContainer() {
  const { data, loading } = useMyWorkspaceTagsClientQuery();

  return (
    <CompositeLoader loading={loading}>
      <EditTagsPage workspaceTags={data} />
    </CompositeLoader>
  );
}
