import { Box, Text } from "@chakra-ui/react";

interface PhaseBadgeProps {
  name: string;
  color: string;
}
const PhaseBadge = ({ name, color }: PhaseBadgeProps) => {
  return (
    <Box bg={color} rounded={"sm"} w="fit-content" px={2} py={0.5}>
      <Text color={"white"} fontWeight={500}>
        {name}
      </Text>
    </Box>
  );
};

export default PhaseBadge;
