import * as Sentry from "@sentry/react";
import type { PropsWithChildren } from "react";

import ErrorFallback from "./ErrorFallback";

function ErrorBoundary({ children }: PropsWithChildren) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <ErrorFallback error={error} componentStack={componentStack} resetError={resetError} />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default ErrorBoundary;
