/* eslint-disable react/jsx-props-no-spreading */
import { Box, IconButton } from "@chakra-ui/react";
import { CancelIcon } from "@core/theme/icons";
import { FileAttachedIcon } from "@core/theme/icons/richTextEditor";
import { Transforms } from "slate";
import type { RenderElementProps } from "slate-react";
import { ReactEditor, useSlateStatic } from "slate-react";

import PlaceholderVariableStyles from "../../styles";
import type { ExtendedElement } from "../../types";

export default function EmbeddedFile({
  attributes,
  element,
  children,
}: RenderElementProps & { element: ExtendedElement }) {
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, element);

  const handleRemoveNode = () => {
    Transforms.removeNodes(editor, { at: path });
  };
  return (
    <Box {...attributes} _first={{ marginTop: 0 }} my={5}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        py={2}
        px={3}
        h="full"
        bg="gray.50"
        _hover={{ bg: "gray.100" }}
      >
        <Box
          gap={2}
          fontSize="sm"
          fontWeight="bold"
          display={"flex"}
          alignItems={"center"}
          onClick={() => window.open(element.url, "_blank")}
          cursor={"pointer"}
        >
          <FileAttachedIcon />
          <Box as="span" {...PlaceholderVariableStyles} bgColor={"transparent"}>
            {children}
          </Box>
        </Box>
        <IconButton
          aria-label="Delete File"
          bgColor={"transparent"}
          icon={<CancelIcon size="sm" />}
          onClick={handleRemoveNode}
        />
      </Box>
    </Box>
  );
}
