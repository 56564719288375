import { HStack } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";

function TodoItemInnerWrapper({ children }: PropsWithChildren) {
  return (
    <HStack spacing={6} alignItems="flex-start" w="full">
      {children}
    </HStack>
  );
}

export default TodoItemInnerWrapper;
