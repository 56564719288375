import { Box, HStack, TableColumnHeaderProps, Text, Th } from "@chakra-ui/react";
import { SortIndicatorIcon } from "@flows-platform/theme/icons";

interface SortableColumnHeaderProps extends TableColumnHeaderProps {
  sortIcon?: JSX.Element | null;
}

export default function SortableColumnHeader({
  children,
  sortIcon,
  ...restProps
}: SortableColumnHeaderProps) {
  return (
    <Th {...restProps} cursor="pointer" data-group>
      <HStack>
        <Text>{children}</Text>
        <Box
          opacity={sortIcon ? 0.6 : 0.3}
          transition="all .3s"
          _groupHover={{
            opacity: 1,
          }}
        >
          {sortIcon || <SortIndicatorIcon />}
        </Box>
      </HStack>
    </Th>
  );
}
