import { Box } from "@chakra-ui/react";
import type { ProjectPhaseFieldsFragment } from "@flows-platform/generated/graphql";
import ProjectPhaseLabel from "@flows-platform/modules/Shared/UI/atoms/ProjectPhaseLabel/ProjectPhaseLabel";

interface IProjectStepPhaseIndicatorProps {
  phase: ProjectPhaseFieldsFragment;
}
export default function ProjectStepPhaseIndicator({ phase }: IProjectStepPhaseIndicatorProps) {
  return (
    <Box bg="gray.100" px={2} py={1}>
      <ProjectPhaseLabel phase={phase} />
    </Box>
  );
}
