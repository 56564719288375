import type { AuthenticatorTypeEnum } from "@flows-platform/generated/graphql";
import {
  useOneFlowStepQuery,
  useUpdateHttpRequestResourceClientMutation,
} from "@flows-platform/generated/graphql";
import { FlowStepObserver } from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import useFormObserver from "@flows-platform/modules/Shared/hooks/useFormObserver";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

import FlowStepResourceLoaderContainer from "../../../../../FlowStepResourceLoaderContainer/FlowStepResourceLoaderContainer";
import HttpRequestEditor from "./HttpRequestEditor";
import { parseVariableToResponseKeys, serializeVariableToResponseKeys } from "./utils";

interface HttpRequestResourceFlowStepContainerProps {
  initialFormValuesLoaded: boolean;
}

function HttpRequestResourceFlowStepContainer({
  initialFormValuesLoaded,
}: HttpRequestResourceFlowStepContainerProps) {
  const flowEditorDrawerContext = useFlowEditorDrawer();
  if (!flowEditorDrawerContext) {
    return null;
  }
  const { flowStep } = flowEditorDrawerContext;
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [updateHttpRequestResource] = useUpdateHttpRequestResourceClientMutation({
    onCompleted() {
      successToast(t("pages.flow_step.toast.update_http_request.success.title"));
    },
    onError: errorToast(t("pages.flow_step.toast.update_http_request.error.title")),
  });

  useFormObserver(
    new FlowStepObserver({
      fields: ["httpRequest"],
      async onChanged(modifiedValues) {
        if (!modifiedValues.httpRequest?.id) return;
        const {
          httpRequest: { authenticator, id, url, requestBody, variables },
        } = modifiedValues;
        const serializedVariables = serializeVariableToResponseKeys(variables || []);
        await updateHttpRequestResource({
          variables: {
            input: {
              id,
              url,
              variableToResponseKeys: serializedVariables.variableToResponseKeys,
              requestBody: requestBody || "{}",
              flowStepVariables: serializedVariables.flowStepVariables,
              authenticator: {
                id: authenticator?.id,
                type: authenticator?.type as AuthenticatorTypeEnum,
              },
            },
          },
        });
      },
    })
  );

  return (
    <FlowStepResourceLoaderContainer
      query={useOneFlowStepQuery}
      initialValuesKey="httpRequest"
      queryOptions={{ variables: { flowStepId: flowStep?.id } }}
      loadingErrorMessage="Error loading http request resource contents"
      resolveInitialValue={(data) => {
        const httpRequestData = data?.oneFlowStep?.httpRequest;

        return {
          ...httpRequestData,
          variables: parseVariableToResponseKeys(
            httpRequestData?.variableToResponseKeys,
            httpRequestData?.flowStepVariables
          ),
        };
      }}
      initialFormValuesLoaded={initialFormValuesLoaded}
    >
      <HttpRequestEditor />
    </FlowStepResourceLoaderContainer>
  );
}

export default HttpRequestResourceFlowStepContainer;
