import type { BoxProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import clsx from "clsx";

export interface IconProps {
  w?: number;
  h?: number;
  background?: string;
  color?: string;
  scaledSize?: number;
}

export interface MaterialIconProps extends BoxProps {
  name: string;
  size?: "lg" | "md" | "sm" | "xs";
}

export function Icon({ name, size = "md", color, onClick }: MaterialIconProps) {
  return (
    <Box
      as="span"
      className={clsx(
        "material-symbols-rounded",
        size === "lg" && "big",
        size === "sm" && "small",
        size === "xs" && "xsmall"
      )}
      color={color}
      maxW={size === "xs" ? "32px" : undefined}
      onClick={onClick}
    >
      {name}
    </Box>
  );
}

interface IconButtonProps extends BoxProps {
  onClick: () => void;
}

export function IconButton({ onClick, children }: IconButtonProps) {
  return (
    <Box
      _hover={{
        backgroundColor: "gray.200",
      }}
      rounded="full"
      cursor="pointer"
      onClick={onClick}
      display="flex"
      alignItems="center"
    >
      {children}
    </Box>
  );
}
