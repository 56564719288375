import { ReceiverTypesEnum } from "@core/modules/Core/constants/enums";
import { useFormikContext } from "formik";
import type { PropsWithChildren } from "react";
import { createContext, useCallback, useContext, useMemo, useState } from "react";

export const KWEST_EXTERNAL_SYSTEM_ID = "KWEST";

interface ReceiverAssignmentState {
  receiverId?: string | null;
  receiverTeamId?: string | null;
  receiverType?: string | null;
  receiverRole?: string;
  slackChannelId?: string;
}

interface ReceiverAssignmentContextInterface {
  state: ReceiverAssignmentState;
  resolvedReceiverType: string | null;
  updateFormState: (state: ReceiverAssignmentState) => void;
  updateExternalSystem: (externalSystem: string | null) => void;
  clearFormState: () => void;
  parent: string;
  showSlackRecipients: boolean;
  selectedExternalSystem: string | null;
  setSelectedExternalSystem: (externalSystem: string | null) => void;
  isSelectedExternalSystem: (externalSystemId: string | null) => boolean;
  hasSelection: boolean;
}

export const ReceiverAssignmentContext = createContext<ReceiverAssignmentContextInterface | null>(
  null
);

export const ReceiverAssignmentContextConsumer: React.Consumer<ReceiverAssignmentContextInterface> =
  ReceiverAssignmentContext.Consumer;

interface ReceiverAssignmentProviderProps {
  parent: string;
  showSlackRecipients: boolean;
}

export function ReceiverAssignmentProvider({
  children,
  parent,
  showSlackRecipients,
}: PropsWithChildren<ReceiverAssignmentProviderProps>) {
  const [selectedExternalSystem, setSelectedExternalSystem] = useState<string | null>(null);
  const [receiverType, setReceiverType] = useState<string | null>(null);
  const { values, setFieldValue } = useFormikContext<any>();

  const state = useMemo(
    () => ({
      receiverType,
      receiverId: values[parent]?.receiverId,
      receiverTeamId: values[parent]?.receiverTeamId,
      receiverRole: values[parent]?.receiverRole,
      slackChannelId: values[parent]?.slackChannelId,
    }),
    [parent, receiverType, values]
  );

  const resolvedReceiverType = useMemo(() => {
    let resolved = null;
    if (state.receiverType) return state.receiverType;
    if (state.receiverRole) {
      resolved =
        state.receiverRole === "initiator" ? ReceiverTypesEnum.initiator : ReceiverTypesEnum.role;
    }
    if (state.receiverId) resolved = ReceiverTypesEnum.member;
    if (state.receiverTeamId) resolved = ReceiverTypesEnum.team;
    if (state.slackChannelId) resolved = ReceiverTypesEnum.slack;
    return resolved;
  }, [state]);

  const updateFormState = useCallback(
    ({
      receiverType: updatedReceiverType,
      receiverId,
      receiverRole,
      receiverTeamId,
      slackChannelId,
    }: ReceiverAssignmentState) => {
      if (receiverRole !== undefined) setFieldValue(`${parent}.receiverRole`, receiverRole);
      if (receiverId !== undefined) setFieldValue(`${parent}.receiverId`, receiverId);
      if (receiverTeamId !== undefined) setFieldValue(`${parent}.receiverTeamId`, receiverTeamId);
      if (showSlackRecipients && slackChannelId !== undefined)
        setFieldValue(`${parent}.slackChannelId`, slackChannelId);
      setReceiverType(updatedReceiverType || null);
    },
    [parent, setFieldValue, showSlackRecipients]
  );

  const clearFormState = () => {
    updateFormState({
      receiverId: null,
      receiverRole: "",
      receiverType: null,
      receiverTeamId: null,
      slackChannelId: "",
    });
  };

  const updateExternalSystem = (externalSystem: string | null) => {
    setSelectedExternalSystem(externalSystem);
    if (resolvedReceiverType && resolvedReceiverType !== ReceiverTypesEnum.initiator) {
      clearFormState();
    }
  };

  const isSelectedExternalSystem = (externalSystemId: string | null) =>
    !selectedExternalSystem ||
    selectedExternalSystem === (externalSystemId || KWEST_EXTERNAL_SYSTEM_ID);

  const hasSelection =
    state.receiverId ||
    state.receiverRole ||
    state.receiverTeamId ||
    state.slackChannelId ||
    resolvedReceiverType === ReceiverTypesEnum.initiator;

  const value = useMemo(
    () => ({
      parent,
      showSlackRecipients,
      state: {
        ...state,
        receiverType: receiverType || resolvedReceiverType,
      },
      resolvedReceiverType,
      updateFormState,
      updateExternalSystem,
      clearFormState,
      selectedExternalSystem,
      setSelectedExternalSystem,
      isSelectedExternalSystem,
      hasSelection,
    }),
    [
      parent,
      showSlackRecipients,
      state,
      receiverType,
      resolvedReceiverType,
      updateFormState,
      selectedExternalSystem,
      hasSelection,
    ]
  );

  return (
    <ReceiverAssignmentContext.Provider value={value}>
      {children}
    </ReceiverAssignmentContext.Provider>
  );
}

export function useReceiverAssignment() {
  const context = useContext(ReceiverAssignmentContext);
  if (!context) {
    throw new Error("useReceiverAssignment must be used within a ReceiverAssignmentProvider");
  }
  return context;
}
