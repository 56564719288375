import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import ConfigurationProvider from "../../../Core/providers/ConfigurationProvider";
import { MyMembershipProvider } from "../../../Core/providers/MyMembershipProvider";
import Banner from "../../UI/layout/Banner";
import MainLayout from "../../UI/layout/MainLayout/MainLayout";

interface PrivateRouteProps {
  isDashboardRoute?: boolean;
  isFullWidth?: boolean;
}

function PrivateRoute({ isDashboardRoute = true, isFullWidth }: PrivateRouteProps) {
  const [online, isOnline] = useState(navigator.onLine);

  const setOnline = () => {
    isOnline(true);
  };
  const setOffline = () => {
    isOnline(false);
  };

  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  const content = isDashboardRoute ? (
    <MainLayout isFullWidth={isFullWidth}>
      <Outlet />
    </MainLayout>
  ) : (
    <Outlet />
  );

  return (
    <>
      <ConfigurationProvider>
        <MyMembershipProvider>{content}</MyMembershipProvider>
      </ConfigurationProvider>
      {!online && (
        <Banner bg="red.500">No internet connection. Your changes will not be saved.</Banner>
      )}
    </>
  );
}

export default withAuthenticationRequired(PrivateRoute, {
  onRedirecting: () => <div>Authorizing your credentials...</div>,
});
