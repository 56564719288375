import { useToast } from "@chakra-ui/react";

import toastSettings from "../../../theme/settings";

function useDefaultToasts() {
  const toast = useToast();
  const successToast = (title: string, description?: string) =>
    toast({
      ...toastSettings,
      status: "success",
      title,
      description,
    });

  const errorToast = (title: string) => (error: { message: string }) =>
    toast({
      ...toastSettings,
      status: "error",
      title,
      description: error.message,
    });

  return { successToast, errorToast };
}

export default useDefaultToasts;
