import { Heading, HStack } from "@chakra-ui/react";

import ToggleSidebar from "./ToggleSidebar";

export const PAGE_HEADER_HEIGHT = "65px";

interface PageHeaderProps {
  title?: string;
  actions?: JSX.Element;
  innerPadding?: boolean;
  headerContent?: JSX.Element;
  hasRightSidebar?: boolean;
  toggleSidebar: (toggleSide: "TOGGLE_LEFT_SIDEBAR" | "TOGGLE_RIGHT_SIDEBAR") => void;
}

export default function PageHeader({
  title,
  actions,
  innerPadding = true,
  headerContent,
  hasRightSidebar = false,
  toggleSidebar,
}: PageHeaderProps) {
  const padding = innerPadding ? 5 : 0;

  return (
    <HStack
      w="full"
      h={PAGE_HEADER_HEIGHT}
      borderBottomWidth="1px"
      borderColor="gray.200"
      justifyContent="space-between"
      px={padding}
    >
      <HStack>
        <ToggleSidebar
          side="left"
          onClick={() => {
            toggleSidebar("TOGGLE_LEFT_SIDEBAR");
          }}
        />
        {title && (
          <Heading size="md" noOfLines={1} maxW={{ base: "250px", md: "600px", "2xl": undefined }}>
            {title}
          </Heading>
        )}
        {headerContent}
      </HStack>
      {hasRightSidebar && (
        <ToggleSidebar
          side="right"
          onClick={() => {
            toggleSidebar("TOGGLE_RIGHT_SIDEBAR");
          }}
        />
      )}
      {actions}
    </HStack>
  );
}
