import type { MembershipPermissionLevel } from "@flows-platform/generated/graphql";
import type { FeatureFlags } from "@flows-platform/modules/Core/constants/enums";
import { FlowsIcon, ProjectsIcon, TemplateIcon } from "@flows-platform/theme/icons";

export interface IRoute {
  name: string;
  key: string;
  route: string;
  icon: JSX.Element;
  featureFlag?: FeatureFlags;
  personalWorkspace?: boolean;
  access?: MembershipPermissionLevel;
  desktopOnly?: boolean;
}

export const SIDE_NAVIGATION_ROUTES = {
  GENERAL: [
    {
      name: "pages.flows_templates.title",
      key: "flow-templates",
      route: "/flow-templates",
      icon: <TemplateIcon />,
      desktopOnly: true,
    },
    {
      name: "pages.flows.title",
      key: "flows",
      route: "/flows",
      icon: <FlowsIcon />,
    },
    {
      name: "pages.project_templates.title",
      key: "project-templates",
      route: "/project-templates",
      icon: <TemplateIcon />,
      desktopOnly: true,
    },
    {
      name: "pages.projects.title",
      key: "projects",
      route: "/projects",
      icon: <ProjectsIcon />,
    },
  ],
};
