import Logout from "@kwest_fe/core/src/components/Logout";
import config from "@kwest_fe/core/src/config";
import TodoViewContainer from "@kwest_fe/todo-viewer/src/modules/TodoView/TodoViewContainer";

import FlowInstanceContainer from "../../FlowInstance/FlowInstanceContainer";
import FlowRunnerStatusPage from "../../FlowRunnerStatus/FlowRunnerStatusPage";
import FlowsContainer from "../../FlowsOverview/FlowsContainer";
import FlowTemplatesPage from "../../FlowTemplates/FlowTemplatesPage";
import ProjectInstanceContainer from "../../ProjectInstance/ProjectInstanceContainer";
import ProjectsPage from "../../ProjectInstance/ProjectsOverview/ProjectsOverviewPage";
import ProjectTemplatesPage from "../../ProjectTemplates/ProjectTemplatesPage";
import SettingsPage from "../../Settings/SettingsPage";
import EditTagsContainer from "../../Tags/EditTagsContainer";
import TaskPage from "../../TaskView/TaskPage";
import TemplateEditorContainer from "../../TemplateEditor/TemplateEditorContainer";
import InboxPage from "../../TodoItems/components/TodosView";
import TodoItemsPageContainer from "../../TodoItems/TodoItemsPageContainer";
import CreateMembershipContainer from "../CreateMembership/CreateMembershipContainer";
import FlowRunnerContainer from "../FlowRunner/FlowRunnerContainer";

export interface RouteType {
  key: string;
  route: string;
  page: JSX.Element;
  isDashboardRoute?: boolean;
  isPublicRoute?: boolean;
  isFullWidth?: boolean;
  collapse?: RouteType[];
}

const routes = [
  {
    key: "flows",
    route: "/flow-templates",
    page: <FlowTemplatesPage />,
    isDashboardRoute: true,
  },
  {
    key: "flow-editor",
    route: "/flow-templates/:id",
    page: <TemplateEditorContainer />,
    isDashboardRoute: true,
  },
  {
    key: "analytics",
    route: "/flows",
    page: <FlowsContainer />,
    isDashboardRoute: true,
  },
  {
    key: "flow-view",
    route: "/flows/:journeyId",
    page: <FlowInstanceContainer />,
    isDashboardRoute: true,
  },
  {
    key: "learner-view-preview",
    route: "/todo/:flowStepId/preview",
    page: <TodoViewContainer />,
    isDashboardRoute: false,
  },
  {
    key: "learner-view",
    route: "/todo/:journeyStepId",
    page: <TodoViewContainer />,
    isDashboardRoute: false,
  },
  {
    key: "flow-complete",
    route: "/todo/complete",
    page: <FlowRunnerStatusPage />,
    isDashboardRoute: false,
  },
  {
    key: "flow-runner",
    route: "/run/:flowId",
    page: <FlowRunnerContainer />,
    isDashboardRoute: false,
  },

  {
    key: "settings",
    route: "/settings",
    page: <SettingsPage />,
    isDashboardRoute: true,
    isFullWidth: false,
  },
  {
    key: "inbox",
    route: "/todos",
    page: <TodoItemsPageContainer />,
    isDashboardRoute: true,
  },
  {
    key: "inbox-teams",
    route: "/team/:teamId/inbox",
    page: <InboxPage />,
    isDashboardRoute: true,
  },
  {
    key: "account-create-membership",
    route: "/account/create-membership",
    page: <CreateMembershipContainer />,
    isDashboardRoute: false,
    isPublicRoute: true,
  },
  {
    key: "logout",
    route: "/logout",
    page: <Logout redirectUri={config.auth0.redirectUri} />,
    isDashboardRoute: false,
    isPublicRoute: true,
  },
  {
    key: "access-restricted",
    route: "/access-restricted",
    page: <h1>Access restricted</h1>,
    isDashboardRoute: false,
    isPublicRoute: true,
  },
  {
    key: "projects",
    route: "/projects",
    page: <ProjectsPage />,
    isDashboardRoute: true,
  },
  {
    key: "project-templates",
    route: "/project-templates",
    page: <ProjectTemplatesPage />,
    isDashboardRoute: true,
  },
  {
    key: "projectView",
    route: "/projects/:projectId",
    page: <ProjectInstanceContainer />,
  },
  {
    key: "edit-tags",
    route: "/projects/tags",
    page: <EditTagsContainer />,
  },
  {
    key: "project-editor",
    route: "/project-templates/:id",
    page: <TemplateEditorContainer />,
    isDashboardRoute: true,
  },
  {
    key: "task-view",
    route: "/projects/tasks/:taskId",
    page: <TaskPage />,
  },
];

export default routes;
