import { Box, useBreakpointValue } from "@chakra-ui/react";
import type { MyMembershipQuery } from "@flows-platform/generated/graphql";
import { DisabledOnMobileIcon } from "@flows-platform/theme/icons";
import getFeatureFlag from "@flows-platform/utils/getFeatureFlag";
import { t } from "i18next";
import { NavLink, useLocation } from "react-router-dom";

import type { IRoute } from "../../constants/settings";

interface RouteComponentProps extends IRoute {
  membership: MyMembershipQuery["myMembership"];
}

function RouteComponent({
  route,
  featureFlag,
  icon,
  name,
  membership,
  desktopOnly,
}: RouteComponentProps) {
  const location = useLocation();
  const linkDisabled = useBreakpointValue({ base: !!desktopOnly, md: false });

  const isSelected = location.pathname.includes(route);
  const background = isSelected ? "gray.300" : undefined;

  const hidden = featureFlag !== undefined && getFeatureFlag(featureFlag, membership) === undefined;
  if (hidden) {
    return null;
  }

  const linkComponent = (
    <Box
      display="inline-flex"
      pl={4}
      bg={background}
      alignItems="center"
      _hover={!linkDisabled ? { backgroundColor: "gray: 200" } : undefined}
      w="100%"
      pb={1}
      pt={1}
      color={linkDisabled ? "gray.500" : "gray.600"}
    >
      <Box display="inline-flex" alignItems="center">
        {icon}
      </Box>
      <Box ml={2} fontSize="14px" fontWeight={isSelected ? 700 : 500}>
        {t(name)}
      </Box>
      {linkDisabled ? (
        <Box ml={2}>
          <DisabledOnMobileIcon />
        </Box>
      ) : null}
    </Box>
  );

  return linkDisabled ? (
    linkComponent
  ) : (
    <NavLink
      to={route}
      style={{
        width: "100%",
      }}
    >
      {linkComponent}
    </NavLink>
  );
}

export default RouteComponent;
