import type { ProjectPhaseFieldsFragment } from "@flows-platform/generated/graphql";
import ProjectPhaseLabel from "@flows-platform/modules/Shared/UI/atoms/ProjectPhaseLabel/ProjectPhaseLabel";
import { StackingContextEnum } from "@flows-platform/utils/enums";

interface IFlowStepPhaseLabelProps {
  phase: ProjectPhaseFieldsFragment;
}

export default function FlowStepPhaseLabel({ phase }: IFlowStepPhaseLabelProps) {
  return (
    <ProjectPhaseLabel
      position="absolute"
      left={0}
      top={-6}
      marginBottom="-2px"
      zIndex={StackingContextEnum.Widgets}
      phase={phase}
    />
  );
}
