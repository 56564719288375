import type { PreviewStep } from "../components/FlowEditor/components/FlowStep/constants/types";

export default function getPreviewConfig(step: PreviewStep, index: number, baseDragConfig: any) {
  const { stepType, name } = step;

  return {
    item: () => ({
      stepType,
      name,
      index,
      fromLibrary: false,
    }),
    collect: (monitor: any) => ({
      isDragPlaceholder: false,
      opacity: monitor.isDragging() ? 0 : 1,
    }),
    type: stepType,
    ...baseDragConfig,
  };
}
