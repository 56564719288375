import { CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { FormItemItemType as FormItemType } from "@flows-platform/generated/graphql";
import type { NonNullableOrderedFormItem } from "@flows-platform/types";
import { DuplicateIcon, KebabIcon } from "@kwest_fe/core/src/theme/icons";
import { useEffect, useRef, useState } from "react";
import { AiOutlinePlus as PlusIcon } from "react-icons/ai";
import { v4 as uuidv4 } from "uuid";

import FormItemTypes from "../../../../constants/formItemTypes";
import RemoteDataConnectionIndicator from "./components/RemoteDataConnectionIndicator";

const NON_OPTIONAL_FIELDS = [FormItemType.DescriptionText, FormItemType.Option];
const LIST_FIELDS = [FormItemType.Option, FormItemType.ImageUpload, FormItemType.FileUpload];

const useForceLabelToTrue = (useLabel: boolean, toggleLabel: (toggle: boolean) => void) => {
  useEffect(() => {
    toggleLabel(true);
  }, [useLabel]);
};

interface FormikFormItemDraggableContextMenuProps {
  remoteDataEnabled: boolean;
  formItem: NonNullableOrderedFormItem;
  index: number;
  setFieldValue: (field: string, value: any) => void;
  orderedFormItems: NonNullableOrderedFormItem[];
  orderedFormItemsPath: string;
  disconnectRemoteData: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  isOpen?: boolean;
}

function FormikFormItemDraggableContextMenu({
  remoteDataEnabled,
  formItem,
  index,
  setFieldValue,
  orderedFormItems,
  orderedFormItemsPath,
  disconnectRemoteData,
  isOpen,
  onOpen,
  onClose,
}: FormikFormItemDraggableContextMenuProps) {
  const itemCount = orderedFormItems[index]?.options?.length || 0;
  const { useRemoteData, useCaptionText, useLabel, defaultText, isOptional, isList } = formItem;
  const [useDefaultValue, setUseDefaultValue] = useState(Boolean(defaultText));

  const optionListScrollRef = useRef<HTMLDivElement>(null);

  const toggleField = (fieldName: string) => (checked: boolean) => {
    if (!checked) {
      setFieldValue(`form.orderedFormItems[${index}].${fieldName}`, "");
    }
    switch (fieldName) {
      case "defaultText":
        setUseDefaultValue(checked);
        break;
      case "label":
        setFieldValue(`form.orderedFormItems[${index}].useLabel`, checked);
        break;
      case "text":
        setFieldValue(`form.orderedFormItems[${index}].useCaptionText`, checked);
        break;
      case "isOptional":
      case "isList":
        setFieldValue(`form.orderedFormItems[${index}].${fieldName}`, checked);
        break;
      default:
        break;
    }
  };

  const toggleCaptionText = toggleField("text");
  const toggleLabel = toggleField("label");
  useForceLabelToTrue(useLabel, toggleLabel);
  const toggleDefaultText = toggleField("defaultText");
  const toggleIsOptional = toggleField("isOptional");
  const toggleIsList = toggleField("isList");

  useEffect(() => {
    if (optionListScrollRef.current)
      optionListScrollRef.current.scrollTop = optionListScrollRef.current?.scrollHeight;
  }, [optionListScrollRef.current?.scrollHeight]);

  const ContextMenuMap = {
    [FormItemType.LongText]: (
      <>
        <MenuItem as="span" _focus={{ bg: "white" }} _active={{ bg: "white" }}>
          <Checkbox
            isChecked={useDefaultValue || useRemoteData}
            disabled={useRemoteData}
            onChange={(e) => {
              toggleDefaultText(e.target.checked);
            }}
          >
            Default Value
          </Checkbox>
        </MenuItem>
        {useDefaultValue && !useRemoteData && (
          <MenuItem as="span" _focus={{ bg: "white" }} _active={{ bg: "white" }}>
            <Textarea
              placeholder="This will be the default text for field"
              defaultValue={defaultText}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                setFieldValue(`form.orderedFormItems[${index}].defaultText`, e.target.value);
              }}
            />
          </MenuItem>
        )}
        {useRemoteData && (
          <RemoteDataConnectionIndicator
            remoteDataEnabled={remoteDataEnabled}
            disconnectRemoteData={disconnectRemoteData}
          />
        )}
      </>
    ),
    [FormItemType.Option]: (
      <>
        {!useRemoteData && (
          <>
            <VStack
              alignItems="flex-start"
              maxH="30vh"
              overflowY="auto"
              spacing={0}
              ref={optionListScrollRef}
            >
              {orderedFormItems[index]?.options?.map((opt, optionIndex) => (
                <MenuItem
                  as="span"
                  key={`${opt}-${optionIndex}`} // eslint-disable-line
                  _focus={{ bg: "white" }}
                  _active={{ bg: "white" }}
                >
                  <HStack w="full">
                    <Input
                      placeholder="Option label"
                      defaultValue={opt}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onBlur={(e) => {
                        setFieldValue(
                          `form.orderedFormItems[${index}].options[${optionIndex}]`,
                          e.target.value
                        );
                      }}
                    />

                    <IconButton
                      aria-label={`Delete Option ${optionIndex}`}
                      isDisabled={itemCount <= 2}
                      size="sm"
                      onClick={() => {
                        if (itemCount > 2) {
                          const { options } = Array.from(orderedFormItems)[index];
                          (options || []).splice(optionIndex, 1);
                          setFieldValue(`form.orderedFormItems[${index}].options`, options);
                        }
                      }}
                      variant="link"
                      icon={<CloseIcon />}
                    />
                  </HStack>
                </MenuItem>
              ))}
            </VStack>
            <MenuItem as="span" _focus={{ bg: "white" }} _active={{ bg: "white" }}>
              <Button
                onClick={() => {
                  setFieldValue(`form.orderedFormItems[${index}].options`, [
                    ...(orderedFormItems[index].options || []),
                    `Option ${itemCount + 1}`,
                  ]);
                }}
                leftIcon={<PlusIcon />}
                colorScheme="purple"
                variant="ghost"
              >
                Add option
              </Button>
            </MenuItem>
          </>
        )}
        {useRemoteData && (
          <RemoteDataConnectionIndicator
            remoteDataEnabled={remoteDataEnabled}
            disconnectRemoteData={disconnectRemoteData}
          />
        )}
      </>
    ),
  };

  return (
    <Menu
      closeOnSelect={false}
      preventOverflow
      placement="auto"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <>
        <MenuButton
          as={IconButton}
          icon={<KebabIcon />}
          size="sm"
          data-testid="form-item-context-menu-button"
        />
        <MenuList overflow="auto" w="sm" draggable="false">
          <MenuGroup title={FormItemTypes[formItem.itemType].label.toUpperCase()}>
            <MenuDivider />
            {/* Shared menu items */}
            {!NON_OPTIONAL_FIELDS.includes(formItem.itemType) && (
              <MenuItem as="span" _focus={{ bg: "white" }} _active={{ bg: "white" }}>
                <Checkbox
                  isChecked={isOptional}
                  onChange={(e) => {
                    toggleIsOptional(e.target.checked);
                  }}
                >
                  Optional
                </Checkbox>
              </MenuItem>
            )}
            {formItem.itemType in ContextMenuMap
              ? ContextMenuMap[formItem.itemType as keyof typeof ContextMenuMap]
              : null}
            {LIST_FIELDS.includes(formItem.itemType) && (
              <MenuItem as="span" _focus={{ bg: "white" }} _active={{ bg: "white" }}>
                <Checkbox
                  isChecked={isList}
                  onChange={(e) => {
                    toggleIsList(e.target.checked);
                  }}
                >
                  Allow multi answers
                </Checkbox>
              </MenuItem>
            )}
            <MenuItem as="span" _focus={{ bg: "white" }} _active={{ bg: "white" }}>
              <Checkbox
                isChecked={useCaptionText}
                onChange={(e) => {
                  toggleCaptionText(e.target.checked);
                }}
              >
                Helper Text
              </Checkbox>
            </MenuItem>
            {formItem.itemType !== FormItemType.DescriptionText && <MenuDivider />}
          </MenuGroup>
          <MenuItem
            icon={<DuplicateIcon />}
            onClick={() => {
              const newItems = Array.from(orderedFormItems);
              newItems.splice(index + 1, 0, {
                ...formItem,
                id: uuidv4(),
                label: `[Copy] ${formItem.label}`,
              });
              setFieldValue(orderedFormItemsPath, newItems);
              onClose?.();
            }}
          >
            Duplicate item
          </MenuItem>
          <MenuItem
            color="red.500"
            icon={<DeleteIcon />}
            onClick={() => {
              const newItems = Array.from(orderedFormItems);
              newItems.splice(index, 1);
              setFieldValue(orderedFormItemsPath, newItems);
              onClose?.();
            }}
          >
            Delete item
          </MenuItem>
        </MenuList>
      </>
    </Menu>
  );
}

export default FormikFormItemDraggableContextMenu;
