import { Box, Divider, HStack, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import type {
  JourneyFieldsFragment,
  ProjectStepFieldsFragment,
} from "@flows-platform/generated/graphql";
import {
  useGetInstallionDocumentsQuery,
  useGetInstallionNotesQuery,
  useMyWorkspaceQuery,
} from "@flows-platform/generated/graphql";
import { FeatureFlags } from "@flows-platform/modules/Core/constants/enums";
import useClientFeatureFlags from "@flows-platform/modules/Shared/hooks/useClientFeatureFlags";
import TruncatedText from "@flows-platform/modules/Shared/UI/atoms/Text/TruncatedText/TruncatedText";
import CompositeLoader from "@kwest_fe/core/src/components/CompositeLoader";
import LoadingListSkeleton from "@kwest_fe/core/src/components/LoadingListSkeleton";
import ToggleSidebar from "@kwest_fe/core/src/components/UI/layout/PageHeader/ToggleSidebar";
import { useParams } from "react-router-dom";

import ProjectContextMenu from "../ProjectContextMenu/ProjectContextMenu";
import CommentsAggregator from "./CommentsAggregator";
import DocumentsPanel from "./DocumentsPanel";
import NotesPanel from "./NotesPanel";
import ProjectBadges from "./ProjectBadges";
import ProjectDataPanel from "./ProjectDataPanel/ProjectDataPanel";

interface ProjectMetadataProps {
  project: JourneyFieldsFragment;
  projectSteps: (ProjectStepFieldsFragment | null)[];
  showHeader?: boolean;
}

export default function ProjectMetadata({
  project,
  projectSteps,
  showHeader = false,
}: ProjectMetadataProps) {
  const { data: myWorkspace } = useMyWorkspaceQuery();

  const [installionExtrasEnabled] = useClientFeatureFlags([FeatureFlags.InstallionExtrasEnabled]);

  const { projectId } = useParams<{ projectId: string }>();

  const { data: documents, loading: loadingDocuments } = useGetInstallionDocumentsQuery({
    variables: {
      journeyId: projectId || "",
    },
    fetchPolicy: "cache-first",
    skip: !installionExtrasEnabled,
  });

  const { data: notes, loading: loadingNotes } = useGetInstallionNotesQuery({
    variables: {
      journeyId: projectId || "",
    },
    fetchPolicy: "cache-first",
    skip: !installionExtrasEnabled,
  });

  return (
    <Box display={"flex"} flexDirection={"column"} width="100%" maxH={"99vh"} bgColor={"white"}>
      {showHeader && (
        <HStack alignItems="center" pb={1.5} mb={1.5} px={"20px"} py={"14px"}>
          <ToggleSidebar side="right" />
          <HStack alignItems="center" flex={1} gap={2}>
            <TruncatedText maxW={{ base: 350, xl: 450 }} fontWeight={600} color="gray.700">
              {project.flow.name}
            </TruncatedText>
            <ProjectBadges isArchived={project.isArchived} isPaused={!!project.isPaused} />
          </HStack>
          {project && (
            <ProjectContextMenu
              projectId={project.id}
              isArchived={project.isArchived}
              isPaused={!!project.isPaused}
            />
          )}
        </HStack>
      )}
      {installionExtrasEnabled ? (
        <Box display="flex" flexDirection="column" maxH={"60%"} overflowY={"auto"}>
          <Tabs variant="enclosed" isFitted isLazy colorScheme="primary">
            <TabList>
              <Tab>Project data</Tab>
              <Tab>Documents</Tab>
              <Tab>Notes</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ProjectDataPanel projectId={project.id} />
              </TabPanel>
              <TabPanel p={0}>
                <CompositeLoader
                  loading={loadingDocuments}
                  loader={<LoadingListSkeleton py={2} px={4} />}
                >
                  <DocumentsPanel documents={documents} />
                </CompositeLoader>
              </TabPanel>
              <TabPanel>
                <CompositeLoader
                  loading={loadingNotes}
                  loader={<LoadingListSkeleton py={2} px={4} />}
                >
                  <NotesPanel notes={notes} />
                </CompositeLoader>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      ) : (
        <Box px={6} minH={"40%"}>
          <ProjectDataPanel projectId={project.id} />
        </Box>
      )}
      <Divider my={4} />
      {/* Comments list */}
      {myWorkspace?.myWorkspace && <CommentsAggregator projectSteps={projectSteps} />}
    </Box>
  );
}
