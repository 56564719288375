import { Flex, Text } from "@chakra-ui/react";
import { FlowInProjectIcon } from "@kwest_fe/core/src/theme/icons";
import { useTranslation } from "react-i18next";

interface FlowConnectedBadgeProps {
  compact?: boolean;
}
const FlowConnectedBadge = ({ compact = false }: FlowConnectedBadgeProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      alignItems={"center"}
      rounded={"lg"}
      bgColor="blue.100"
      textColor="gray.600"
      fontSize="md"
      px={compact ? 1 : 2}
      py={1}
      gap={1}
      fontWeight="500"
      w={"fit-content"}
      height="full"
    >
      <FlowInProjectIcon />
      {!compact && <Text fontSize="md">{t("pages.project_instance.tasks.flow_connected")}</Text>}
    </Flex>
  );
};

export default FlowConnectedBadge;
