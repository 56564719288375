import { Alert, Text, VStack } from "@chakra-ui/react";
import FormikField from "@flows-platform/modules/Shared/components/FormikField";
import TinyMCERichTextEditor from "@kwest_fe/core/src/components/TinyMCERichTextEditor";
import { useTranslation } from "react-i18next";

import { useFlowEditorDrawer } from "../../../../providers/FlowEditorDrawerProvider";

function DocumentEditor() {
  const { t } = useTranslation();
  const { variableStore } = useFlowEditorDrawer() || {};
  return (
    <VStack align="stretch" spacing={5}>
      <FormikField
        name="document.label"
        label={t("forms.document_label.label")}
        placeholder={t("forms.document_label.placeholder")}
      />
      <TinyMCERichTextEditor name="document.content" variables={variableStore} />
      <Alert borderRadius="md">
        <Text fontSize="sm">{t("forms.tiny_mce_editor.hints.placeholders")}</Text>
      </Alert>
    </VStack>
  );
}

export default DocumentEditor;
