import { Box } from "@chakra-ui/react";
import theme from "@flows-platform/theme";
import { StackingContextEnum } from "@flows-platform/utils/enums";
import { PAGE_HEADER_HEIGHT } from "@kwest_fe/core/src/components/UI/layout/PageHeader/PageHeader";
import { throttle } from "lodash";
import type { PropsWithChildren } from "react";
import { useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";

interface FlowEditorCanvasProps {
  accept: string[];
}

/**
 * FlowEditorCanvas is a component used to represent an area that is scrollable and that will accept a droppable
 * item which is of one of the types listed in the accept parameter.
 *
 * @param FlowEditorCanvasProps
 * @returns JSX.Element
 */

export default function FlowEditorCanvas({
  children,
  accept,
}: PropsWithChildren<FlowEditorCanvasProps>) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [fullWidth, setFullWidth] = useState(0);
  // Declaring the variable here give the animation accurate access to the variable
  let isScrolling = false;
  let scrollIncrement = 0;

  const [, drop] = useDrop(() => ({
    accept,
    hover: throttle((item, monitor) => {
      /**
       * Solution heavily informed by this helper
       * https://gist.github.com/orlandovallejos/ed9c79dd67e96c25cfcc8f1008df9489
       */
      const cursor = monitor.getClientOffset();

      if (!cursor) return;
      if (!scrollContainerRef.current) return;

      const rect = scrollContainerRef.current.getBoundingClientRect();

      const OFFSET = 200;
      const PX_DIFF = 7;

      const isMouseOnTop =
        scrollIncrement >= 0 && cursor.y > rect.top && cursor.y < rect.top + OFFSET;

      const isMouseOnBottom =
        scrollIncrement <= scrollContainerRef.current.scrollHeight &&
        cursor.y > window.innerHeight - OFFSET &&
        cursor.y <= window.innerHeight;

      const goUp = () => {
        if (!scrollContainerRef.current) return;
        scrollIncrement -= PX_DIFF;
        scrollContainerRef.current.scrollTop = scrollIncrement;

        if (isScrolling && scrollIncrement >= 0) {
          window.requestAnimationFrame(goUp);
        }
      };

      const goDown = () => {
        if (!scrollContainerRef.current) return;
        scrollIncrement += PX_DIFF;
        scrollContainerRef.current.scrollTop = scrollIncrement;

        if (isScrolling && scrollIncrement <= scrollContainerRef.current.scrollHeight) {
          window.requestAnimationFrame(goDown);
        }
      };

      if (!isScrolling && (isMouseOnTop || isMouseOnBottom)) {
        isScrolling = true;
        scrollIncrement = scrollContainerRef.current.scrollTop;

        if (isMouseOnTop) {
          window.requestAnimationFrame(goUp);
        } else {
          window.requestAnimationFrame(goDown);
        }

        return;
      }

      isScrolling = false;
    }, 300),
  }));

  useEffect(() => {
    setFullWidth(scrollContainerRef.current?.offsetWidth || 0);
  }, []);

  return (
    <Box
      ref={scrollContainerRef}
      overflowY="scroll"
      h={`calc(100vh - ${PAGE_HEADER_HEIGHT})`}
      p={6}
    >
      {fullWidth ? (
        <Box ref={drop} h="full">
          <Box h="full" w={`${fullWidth - 250}px`}>
            {children}

            <Box
              pos="absolute"
              top={0}
              left={0}
              w="full"
              h="full"
              bgColor="blackAlpha.300"
              bgImage={`radial-gradient(${theme.colors.blackAlpha[400]}, 1px, transparent 0)`}
              bgSize="16px 16px"
              opacity={0.5}
              zIndex={StackingContextEnum.Background}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
