import ProjectsFilterGroup from "@flows-platform/modules/ProjectInstance/ProjectsOverview/components/ProjectsFilterGroup";
import TablePageLayout from "@flows-platform/modules/Shared/UI/layout/MainLayout/components/TablePageLayout/TablePageLayout";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ProjectsTable from "./components/ProjectsTable";

export default function ProjectsOverviewPage() {
  const [showProjectTasks, setShowProjectTasks] = useState(false);
  const [projectFilters, setProjectFilters] = useState("{}");
  const { t } = useTranslation();

  return (
    <TablePageLayout title={t("pages.projects.title")} overflow={false}>
      <ProjectsFilterGroup
        onFilterUpdate={setProjectFilters}
        showProjectTasks={showProjectTasks}
        setShowProjectTasks={setShowProjectTasks}
      />
      <ProjectsTable projectFilters={projectFilters} showProjectTasks={showProjectTasks} />
    </TablePageLayout>
  );
}
