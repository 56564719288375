import { Heading, HStack } from "@chakra-ui/react";
import ConfirmChanges from "@kwest_fe/core/src/components/UI/molecules/forms/ConfirmChanges";
import config from "@kwest_fe/core/src/config";
import { useState } from "react";

import FileField from "./FileField";

interface FileInputMetaDataFieldProps {
  journeyId: string;
  identifier: string;
  label: string;
  value: number | string;
  handleUpdate: (label: string, type: string, newValue: number | string) => void;
  isLoading: boolean;
  type: string;
  readOnly?: boolean;
}

function FileInputMetaDataField({
  journeyId,
  identifier,
  label,
  value,
  handleUpdate,
  isLoading,
  type,
  readOnly,
}: FileInputMetaDataFieldProps) {
  const [valueState, setValueState] = useState(value);

  const handleConfirm = () => {
    handleUpdate(identifier, type, valueState);
  };

  const handleCancel = () => {
    setValueState(value);
  };

  return (
    <HStack alignItems="center" w="full">
      <Heading fontSize="md" textColor="gray.500" flex="1" w="50%">
        {label}
      </Heading>
      <HStack flex="1" maxW="50%">
        <FileField
          variableValue={String(value)}
          uploadUrl={`${config.backend.uri}/api/journeys/${journeyId}/files/${identifier}`}
          fileUrl={
            value
              ? `${config.backend.uri}/api/journeys/${journeyId}/files/${identifier}`
              : undefined
          }
          type={type}
          readOnly={readOnly}
        />
        <ConfirmChanges
          isLoading={isLoading}
          display={valueState !== value && valueState !== String(value)}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        />
      </HStack>
    </HStack>
  );
}

export default FileInputMetaDataField;
