import { Box, Text, VStack } from "@chakra-ui/react";
import { ConnectedFlowProvider } from "@flows-platform/context/ConnectedFlow";
import { useAvailableTemplatesQuery } from "@flows-platform/generated/graphql";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useField } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SelectFlow from "../SelectFlow";
import ConnectedFlowVariables from "./ConnectedFlowVariables";

interface ConnectFlowProps {
  name: string;
}

const ConnectFlow = ({ name }: ConnectFlowProps) => {
  const [{ value: connectedFlow }] = useField(name);
  const [menuVisible, setMenuVisible] = useState(!!connectedFlow?.id);

  const { t } = useTranslation();

  const { data } = useAvailableTemplatesQuery({
    variables: {
      projectsOnly: false,
    },
    fetchPolicy: "cache-and-network",
  });

  const allAvailableFlowTemplates = data?.allMyAdminFlows;

  useEffect(() => {
    setMenuVisible(!!connectedFlow?.id);
  }, [connectedFlow?.id]);

  return (
    <ConnectedFlowProvider
      connectedFlow={connectedFlow}
      availableTemplates={allAvailableFlowTemplates}
    >
      <VStack alignItems="start" gap={4} position="relative">
        <Box display="inline-flex">
          <Text fontWeight="medium" marginRight={1}>
            {t("pages.flows.title")}
          </Text>
          <Text size="12px" color={"gray.500"}>
            {t("global.optional")}
          </Text>
        </Box>
        {!menuVisible ? (
          <AddButton
            data-testid="connect-flow-button"
            onClick={() => {
              setMenuVisible(true);
            }}
          >
            {t("pages.flows_templates.flows_in_project.connect_flow")}
          </AddButton>
        ) : (
          <SelectFlow
            onClose={() => {
              setMenuVisible(false);
            }}
          />
        )}
        {connectedFlow && <ConnectedFlowVariables />}
      </VStack>
    </ConnectedFlowProvider>
  );
};

export default ConnectFlow;
