import { useAuth0 } from "@auth0/auth0-react";
import { GridItem, SimpleGrid } from "@chakra-ui/react";
import FileUploadCaption from "@flows-platform/modules/Shared/components/FileUploadCaption/FileUploadCaption";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import MembershipSelector from "@flows-platform/modules/Shared/components/MembershipSelector/MembershipSelector";
import type { VariableType } from "@flows-platform/types";
import FileListViewer from "@kwest_fe/core/src/components/FileListViewer/FileListViewer";
import FileUploader from "@kwest_fe/core/src/components/FileUploader/FileUploader";
import config from "@kwest_fe/core/src/config";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import type { TriggerVariableFormFieldInitialValue } from "@kwest_fe/core/src/types/TriggerVariableFormFieldInitialValue";
import { useField } from "formik";
import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface TriggerVariableFormFieldProps {
  identifier: string;
  variable: VariableType;
  parentField: string;
}

function TriggerVariableFormField({
  identifier,
  variable,
  parentField,
}: TriggerVariableFormFieldProps) {
  const { t } = useTranslation();
  const label = variable.label || identifier;
  const fieldName = `${parentField}['${identifier}']`;
  const [, , { setValue }] = useField<TriggerVariableFormFieldInitialValue>(fieldName);
  const { getAccessTokenSilently } = useAuth0();

  const FormField = useMemo(() => {
    switch (variable.type) {
      case FlowStepVariableTypeEnum.DATE:
        return (
          <FormikField
            name={fieldName}
            required
            label={label}
            placeholder={t("modals.flow_assignment.flow_variable_form_field.placeholder")}
            type="date"
          />
        );
      case FlowStepVariableTypeEnum.FILE:
      case FlowStepVariableTypeEnum.IMAGE:
        return (
          <FormikField
            label={label}
            name={fieldName}
            showOptionalIndicator
            required
            renderElement={({ value }) => {
              return (
                <FileUploader
                  accessToken={getAccessTokenSilently()}
                  disableMultiple
                  acceptedFileTypes={
                    variable.type === FlowStepVariableTypeEnum.FILE
                      ? { "application/pdf": [".pdf"] }
                      : undefined
                  }
                  uploadUrl={`${config.backend.uri}/api/temp/files`}
                  fileUrls={[value].filter(Boolean)}
                  renderInactive={FileUploadCaption}
                  renderUploaded={FileListViewer}
                  loadingText={`Uploading ${variable.type.toLowerCase()}`}
                  onFileUploaded={(files, data) => {
                    setValue(data?.[0].path);
                  }}
                  onFileDeleted={() => {
                    setValue("");
                  }}
                />
              );
            }}
          />
        );
      case FlowStepVariableTypeEnum.ROLE:
        return (
          <FormikField
            name={`${fieldName}.value`}
            renderElement={() => (
              <SimpleGrid columns={2} columnGap={3} w="full">
                <GridItem colSpan={[2, 1]} key="team-or-membership-selector">
                  <MembershipSelector
                    key={`${fieldName}.value`}
                    name={`${fieldName}.value`}
                    label={label}
                    showTeams
                  />
                </GridItem>
              </SimpleGrid>
            )}
          />
        );
      default:
        return (
          <FormikField
            name={fieldName}
            required
            label={label}
            placeholder={t("modals.flow_assignment.flow_variable_form_field.placeholder")}
            type={variable.type === FlowStepVariableTypeEnum.NUMBER ? "number" : "text"}
          />
        );
    }
  }, [fieldName, variable, label]);

  return FormField;
}

export default memo(TriggerVariableFormField);
