import { useAllTeamsLazyQuery } from "@flows-platform/generated/graphql";
import { useCallback, useMemo } from "react";
import type { OptionProps } from "react-select";

import useAllMembershipsWithAdminFields from "../../hooks/useAllMembershipsWithAdminFields";
import type {
  FormikMembershipSelectFieldProps,
  FormikMembershipSelectFieldSelectOption,
} from "./components/FormikMembershipSelectField/FormikMembershipSelectField";
import FormikMembershipSelectField from "./components/FormikMembershipSelectField/FormikMembershipSelectField";
import type { CustomUserSelectOptionData } from "./CustomUserSelectOption";
import CustomUserSelectOption, { SelectFieldStyles } from "./CustomUserSelectOption";

interface MembershipSelectorProps {
  name?: string;
  loading?: boolean;
  assignedMembershipIds?: string[];
  label?: string;
  placeholder?: string;
  defaultMenuIsOpen?: boolean;
  loadMemberships?: (input: string) => Promise<FormikMembershipSelectFieldSelectOption[]>;
  formatMemberships?: (
    membership: FormikMembershipSelectFieldSelectOption
  ) => FormikMembershipSelectFieldSelectOption;
  showLabel?: boolean;
  showTeams?: boolean;
  mapValue?: (value: string[] | string) => Record<string, string> | string[] | string;
}

function MembershipSelector({
  name,
  loading: externalLoading,
  assignedMembershipIds = [],
  label = "Select a person to assign",
  placeholder = "Start typing",
  defaultMenuIsOpen,
  loadMemberships,
  formatMemberships,
  showLabel = true,
  showTeams = false,
  mapValue,
  ...rest
}: FormikMembershipSelectFieldProps & MembershipSelectorProps) {
  const { loadAllMemberships, loading, loadingError } = useAllMembershipsWithAdminFields();
  const [getAllTeams, { loading: allTeamsLoading }] = useAllTeamsLazyQuery();

  const assignedMembershipIdsMap = useMemo(
    () =>
      assignedMembershipIds.reduce<Record<string, boolean>>(
        (objectMap, id) => ({
          ...objectMap,
          [id]: true,
        }),
        {}
      ),
    [assignedMembershipIds]
  );

  const formatMembershipOptions = useCallback(
    (member: FormikMembershipSelectFieldSelectOption) => ({
      ...member,
      ...(assignedMembershipIdsMap[member.value] ? { isAssigned: true, isDisabled: true } : {}),
    }),
    [assignedMembershipIdsMap]
  );

  const getAllTeamMembers = useCallback(async (input: string) => {
    const { data } = await getAllTeams();

    return (
      data?.allTeams
        ?.filter((team) => team?.name.toLowerCase().includes(input.toLowerCase()))
        .map((team) => ({
          label: team?.name || "",
          value: { id: team?.id, __typename: "BaseTeamType" as const, name: team?.name || "" },
        })) || []
    );
  }, []);

  const onSelectSearch = useCallback(
    async (input: string) => {
      const allMemberships = await (loadMemberships || loadAllMemberships)(input);
      const formattedMemberships = allMemberships?.map(
        formatMemberships || formatMembershipOptions
      );
      const teams = showTeams ? await getAllTeamMembers(input) : [];
      return [...(formattedMemberships || []), ...teams];
    },
    [
      loadMemberships,
      loadAllMemberships,
      formatMembershipOptions,
      formatMemberships,
      getAllTeamMembers,
    ]
  );

  return (
    <FormikMembershipSelectField
      name={name}
      label={showLabel ? label : undefined}
      placeholder={placeholder}
      loading={loading || allTeamsLoading || externalLoading}
      optionComponent={(props) => {
        // TODO: Fix typing issues
        return (
          <CustomUserSelectOption
            useTeamIndicators={showTeams}
            {...(props as OptionProps<CustomUserSelectOptionData>)}
          />
        );
      }}
      styles={SelectFieldStyles}
      onSearch={onSelectSearch}
      defaultMenuIsOpen={defaultMenuIsOpen}
      disabled={!!loadingError}
      mapValue={mapValue}
      {...rest}
    />
  );
}

export default MembershipSelector;
