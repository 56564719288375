import type { MaterialIconProps } from "./icon";
import { Icon } from "./icon";

export function LeftSidebarIcon() {
  return <Icon name="dock_to_right" />;
}

export function RightSidebarIcon() {
  return <Icon name="dock_to_left" />;
}

export function SearchIcon() {
  return <Icon name="search" />;
}

export function DoneIcon() {
  return <Icon name="done" size="sm" />;
}

export function CloseIcon() {
  return <Icon name="close" size="sm" />;
}

export function ArrowDropdownDownIcon() {
  return <Icon name="arrow_drop_down" />;
}

export function ArrowBackIcon() {
  return <Icon name="arrow_back_ios_new" size="sm" />;
}

export function SettingsIcon() {
  return <Icon name="settings" size="xs" />;
}

export function EditIcon() {
  return <Icon name="edit" size="sm" />;
}

export function InformationIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="info" size={size} color={color} />;
}

export function DisabledOnMobileIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="app_blocking" size={size} color={color} />;
}

export function FileUploadIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="file_upload" size={size} color={color} />;
}

export function ArrowUpAltIcon() {
  return <Icon name="arrow_upward_alt" size="sm" />;
}

export function ArrowDownAltIcon() {
  return <Icon name="arrow_downward_alt" size="sm" />;
}

export function CursorIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="web_traffic" size={size} color={color} />;
}

export function ExternalLinkIcon({ size = "sm", color }: Omit<MaterialIconProps, "name">) {
  return <Icon name="open_in_new" size={size} color={color} />;
}
