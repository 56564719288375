import { IconButton } from "@chakra-ui/react";

import type { MarkTypes } from "../../../../constants";

interface MarkButtonProps {
  icon: JSX.Element;
  format: keyof typeof MarkTypes;
  toggleMark: (format: keyof typeof MarkTypes) => void;
  isMarkActive: (format: keyof typeof MarkTypes) => boolean;
}

function MarkButton({ icon, format, toggleMark, isMarkActive }: MarkButtonProps) {
  return (
    <IconButton
      aria-label={format}
      variant={isMarkActive(format) ? "solid" : "ghost"}
      icon={icon}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(format);
      }}
    />
  );
}

export default MarkButton;
