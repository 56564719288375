export const functionalMoveObjectToNewIndex = <T>(
  listOfObjects: T[],
  oldIndex: number,
  newIndex: number,
  replacementObject: T
) => {
  return [...listOfObjects].map((c, i) => {
    // Moving  up
    if (newIndex < oldIndex) {
      if (i === newIndex) return replacementObject;
      if (i > newIndex && i <= oldIndex) return listOfObjects[i - 1];
    }
    // Moving down
    if (newIndex > oldIndex) {
      if (i === newIndex) return replacementObject;
      if (i < newIndex && i >= oldIndex) return listOfObjects[i + 1];
    }
    return c;
  });
};
