import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface DeleteAuthenticatorModalProps {
  isOpen: boolean;
  onDelete: () => void;
  onClose: () => void;
}

function DeleteAuthenticatorModal({ isOpen, onClose, onDelete }: DeleteAuthenticatorModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("modals.authenticators.delete.title")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{t("modals.authenticators.delete.body")}</Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onDelete}>
            {t("global.actions.delete")}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            {t("global.actions.cancel")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteAuthenticatorModal;
