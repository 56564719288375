import { Button, HStack, Progress, Tag, Td, Text, Tr } from "@chakra-ui/react";
import type { JourneyFieldsWithProjectTasksFragment } from "@flows-platform/generated/graphql";
import { JourneyStepStatus } from "@flows-platform/generated/graphql";
import { FeatureFlags } from "@flows-platform/modules/Core/constants/enums";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import useClientFeatureFlags from "@flows-platform/modules/Shared/hooks/useClientFeatureFlags";
import { ProjectStepsProvider } from "@flows-platform/modules/Shared/providers/ProjectStepContext";
import PhaseBadge from "@flows-platform/modules/Shared/UI/atoms/Phase";
import TruncatedText from "@flows-platform/modules/Shared/UI/atoms/Text/TruncatedText/TruncatedText";
import type { VariableType } from "@flows-platform/types";
import { formatDateDifferenceFromNow } from "@flows-platform/utils/formatDate";
import { ArchiveIcon, PauseIcon } from "@kwest_fe/core/src/theme/icons";
import type { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";

import ContextMenuProject from "./components/ContextMenuProject/ContextMenuProject";
import ProjectSteps from "./components/ProjectSteps/ProjectSteps";

interface ProjectsTableRowProps {
  project: JourneyFieldsWithProjectTasksFragment;
  isLastRow: boolean;
  showProjectTasks?: boolean;
  onUpdate?: () => void;
}

export default function ProjectsTableRow({
  project,
  isLastRow,
  showProjectTasks = false,
  onUpdate,
}: ProjectsTableRowProps) {
  const { myMembership } = useMyMembership();
  const { t } = useTranslation();

  const [installionExtrasEnabled] = useClientFeatureFlags([FeatureFlags.InstallionExtrasEnabled]);

  const borderWidth = showProjectTasks ? "2px" : "1px";
  const TdBorderWidth = isLastRow ? 0 : borderWidth;

  if (!project) return null;

  const { instanceName, flow, contextSection } = project;

  const completedSteps =
    project.orderedJourneySteps?.filter((j) => j?.status === JourneyStepStatus.Completed).length ??
    0;
  const totalSteps = project.orderedJourneySteps?.length ?? 0;
  const percentageComplete = totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;
  const projectLead = project.participants?.find(
    (participant) => participant?.role === "project_lead"
  )?.person;

  const onRowClicked: MouseEventHandler<HTMLTableRowElement> = (e) => {
    // Only navigate if the menu button is not the target
    if ((e.target as HTMLElement).nodeName !== "BUTTON") {
      window.open(`/projects/${project.id}`, "_blank");
    }
  };

  const projectLastActivity = formatDateDifferenceFromNow(project.lastActivityAt);
  const projectSteps = project.orderedJourneySteps || [];
  const projectName = `${flow.name}${instanceName ? `: ${instanceName}` : ""}`;

  /** Installion specific fields from the project context variables */
  const parsedContextVariables: Record<string, VariableType> = JSON.parse(
    contextSection?.variables || "{}"
  );
  const { companyName = null, partnerName = null } =
    installionExtrasEnabled && parsedContextVariables
      ? {
          companyName: parsedContextVariables.niederlassung_company_name?.value,
          partnerName: parsedContextVariables.partner_company_name?.value,
        }
      : {};

  return (
    <>
      <Tr
        key={project.id}
        fontSize="md"
        cursor="pointer"
        sx={{ _hover: { bg: showProjectTasks ? "blue.50" : "gray.50" } }}
        onClick={onRowClicked}
      >
        <Td borderBottomWidth={TdBorderWidth} py={2}>
          <TruncatedText maxWidth={400} title={projectName}>
            {projectName}
          </TruncatedText>
        </Td>
        <Td borderBottomWidth={TdBorderWidth} py={2}>
          <HStack spacing={2}>
            {project.tags?.map((tag) => <Tag key={tag?.id}>{tag?.name}</Tag>)}
            {project.isArchived && <ArchiveIcon color="gray.500" />}
            {project.isPaused && <PauseIcon color="gray.500" />}
          </HStack>
        </Td>
        <Td borderBottomWidth={TdBorderWidth} py={2}>
          <Text>
            {projectLead
              ? `${projectLead.firstName} ${projectLead.lastName}`
              : t("pages.flows.placeholders.unknown_member")}
          </Text>
        </Td>
        <Td borderBottomWidth={TdBorderWidth} py={2}>
          <HStack w="90%" spacing={6}>
            <Progress w="full" value={percentageComplete} colorScheme="green" size="lg" />
            <Button variant="link">{`${completedSteps} of ${project.orderedJourneySteps?.length}`}</Button>
          </HStack>
        </Td>
        <Td borderBottomWidth={TdBorderWidth} py={2}>
          {project.phase?.name && project.phase.color && (
            <PhaseBadge name={project.phase.name} color={project.phase.color} />
          )}
        </Td>
        {installionExtrasEnabled && (
          <>
            <Td borderBottomWidth={TdBorderWidth} py={2}>
              <Text>{companyName}</Text>
            </Td>
            <Td borderBottomWidth={TdBorderWidth} py={2}>
              <Text>{partnerName}</Text>
            </Td>
          </>
        )}
        <Td borderBottomWidth={TdBorderWidth} py={2}>
          <Text>{projectLastActivity}</Text>
        </Td>
        {myMembership?.isAdmin && (
          <Td borderBottomWidth={TdBorderWidth} py={2}>
            <ContextMenuProject projectId={project.id} />
          </Td>
        )}
      </Tr>
      {showProjectTasks && (
        <Tr>
          <Td colSpan={7} p={0}>
            <ProjectStepsProvider
              projectId={project.id}
              onUpdate={() => {
                onUpdate?.();
              }}
            >
              <ProjectSteps projectSteps={projectSteps} dense />
            </ProjectStepsProvider>
          </Td>
        </Tr>
      )}
    </>
  );
}
