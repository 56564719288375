import { Box, Button, Flex, Text } from "@chakra-ui/react";
import type { JourneyStepsProjectAwaitingUserFragment } from "@flows-platform/generated/graphql";
import { formatDateDifferenceFromNow } from "@flows-platform/utils/formatDate";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import Member from "./components/Member/Member";

interface ProjectStepCardProps {
  projectStep: JourneyStepsProjectAwaitingUserFragment;
  projectId: string;
}

const ProjectStepCard = ({ projectStep, projectId }: ProjectStepCardProps) => {
  const { t } = useTranslation();

  const taskLink = `/projects/${projectId}?task=${projectStep.id}`;
  return (
    <Flex
      p={2}
      rounded={"sm"}
      bg={"gray.50"}
      alignItems={"flex-end"}
      justifyContent={"space-between"}
      gap={2}
    >
      <Flex flexDir={"column"} gap={2}>
        <Flex gap={1} alignItems={"center"}>
          <Text fontWeight={"semibold"} fontSize={"md"}>
            {projectStep.name}
          </Text>
        </Flex>
        <Box w={"full"} display={"flex"} alignItems={"center"} gap={4}>
          <Text fontSize={"md"} color={"gray.500"}>
            {formatDateDifferenceFromNow(projectStep.task?.updatedAt || "")}
          </Text>
          {projectStep.task?.receiverId ? (
            <Member id={projectStep.task?.receiverId || ""} />
          ) : (
            <Member id={projectStep.task?.receiverTeamId || ""} isTeam />
          )}
          {projectStep.task?.dueDate && (
            <Text fontSize={"md"} color={"gray.500"}>
              Due date: {format(new Date(projectStep.task?.dueDate), "dd/MM/yyyy")}
            </Text>
          )}
        </Box>
      </Flex>

      <Button colorScheme="purple" size="sm" onClick={() => window.open(taskLink, "_blank")}>
        {t("global.actions.do")}
      </Button>
    </Flex>
  );
};

export default ProjectStepCard;
