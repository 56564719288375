/* eslint-disable @typescript-eslint/unbound-method */
import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  alertAnatomy.keys
);

const customVariant = definePartsStyle({
  container: {
    background: "yellow.100",
    _dark: {
      background: "yellow.100",
    },
  },
  icon: {
    color: "yellow.500",
  },
});

export const alertTheme = defineMultiStyleConfig({
  variants: { "custom-warning": customVariant },
});
