import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import {
  useFlowStepResourceQuery,
  useJourneyStepResourceQuery,
} from "@todo-viewer/generated/graphql";
import { useParams } from "react-router-dom";

import { TodoViewProvider } from "./providers/TodoViewProvider";
import TodoViewPage from "./TodoViewPage";

function TodoViewContainer() {
  const { flowStepId, journeyStepId } = useParams();

  const flowStepResourceQuery = useFlowStepResourceQuery({
    variables: {
      flowStepId: flowStepId!,
    },
    skip: !flowStepId,
  });
  const journeyStepResourceQuery = useJourneyStepResourceQuery({
    variables: {
      journeyStepId: journeyStepId!,
    },
    skip: !journeyStepId,
    fetchPolicy: "cache-and-network",
  });

  const flowStepResource = flowStepResourceQuery.data?.getFlowStepResource;
  const journeyStepResource = journeyStepResourceQuery.data?.getJourneyStepResource;

  const stepResource = flowStepResource || journeyStepResource;

  if (flowStepResourceQuery?.error || journeyStepResourceQuery?.error) {
    return <Placeholder type="empty-state" header="Error loading todo list" />;
  }

  if (flowStepResourceQuery.loading || journeyStepResourceQuery.loading || !stepResource) {
    return <LoadingSpinnerOverlay />;
  }

  return (
    <ErrorBoundary>
      <TodoViewProvider>
        <TodoViewPage stepResource={stepResource} />
      </TodoViewProvider>
    </ErrorBoundary>
  );
}

export default TodoViewContainer;
