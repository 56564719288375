import MotionBox from "@flows-platform/modules/Shared/UI/layout/MotionBox";
import { AnimatePresence } from "framer-motion";

import InstallerProjectMetadata from "./InstallerProjectMetadata/InstallerProjectMetadata";

interface ContextDrawerReadOnlyProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
}
const ContextDrawerReadOnly = ({ isOpen, onClose, projectId }: ContextDrawerReadOnlyProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <MotionBox
          initial={{ x: window.innerWidth }}
          animate={{ x: 0 }}
          exit={{ x: window.innerWidth }}
          transition={"ease-in-out"}
          flexGrow={1}
          width={{ base: "100%", md: "450px" }}
          position="fixed"
          top={{ base: 0, md: undefined }}
          right={0}
          bg={"white"}
          zIndex={10}
          h={"100%"}
        >
          <InstallerProjectMetadata projectId={projectId} onClose={onClose} />
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export default ContextDrawerReadOnly;
