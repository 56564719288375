import { Accordion, Box, VStack } from "@chakra-ui/react";
import { useGetProjectStepsQuery, useOneJourneyQuery } from "@flows-platform/generated/graphql";
import useProjectContext from "@flows-platform/modules/ProjectInstance/components/ProjectMetadata/ProjectDataPanel/hooks/useProjectContext";
import LoadingListSkeleton from "@flows-platform/modules/Shared/components/LoadingListSkeleton";
import AccordionItemWithHeader from "@flows-platform/modules/Shared/UI/molecules/layout/AccordionItemWithHeader/AccordionItemWithHeader";
import type { VariableType } from "@flows-platform/types";
import {
  getCategoriesFromVariables,
  getDefaultCategory,
} from "@flows-platform/utils/getCategoriesFromVariables";
import CompositeLoader from "@kwest_fe/core/src/components/CompositeLoader";
import { useTranslation } from "react-i18next";

import ConnectedJourneyMetadata from "./components/ConnectedJourneyMetadata";
import MetaDataField from "./components/MetadataField";
import ProjectLead from "./components/ProjectLead";
import ProjectPhase from "./components/ProjectPhase";
import ProjectTags from "./components/ProjectTags";
import ContextVariables from "./ContextVariables";

interface IProjectDataPanelProps {
  projectId: string;
  readOnly?: boolean;
}

const ProjectDataPanel = ({ projectId, readOnly }: IProjectDataPanelProps) => {
  const { t } = useTranslation();

  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useOneJourneyQuery({
    variables: {
      journeyId: projectId || "",
    },
  });

  const {
    data: projectStepsData,
    loading: projectStepsLoading,
    error: projectStepsError,
  } = useGetProjectStepsQuery({
    variables: {
      journeyId: projectId || "",
    },
    fetchPolicy: "network-only",
    skip: !projectId,
  });

  const project = projectData?.oneJourney;
  const projectSteps = projectStepsData?.getProjectSteps || [];

  const [projectContext] = useProjectContext(project);

  const projectLead = project?.participants?.find(
    (participant) => participant?.role === "project_lead"
  )?.person;

  const projectVariables: Record<string, VariableType> = JSON.parse(project?.allVariables || "{}");

  const projectVariableCategories = (
    variableCategories: {
      __typename?: "VariableCategoryType" | undefined;
      id: string;
      name: string;
      position: number;
    }[]
  ) =>
    getCategoriesFromVariables(
      Object.values(projectVariables),
      getDefaultCategory(true),
      variableCategories
    );

  return (
    <CompositeLoader
      loading={projectLoading || projectStepsLoading}
      error={projectError || projectStepsError}
      errorMessage="Project not found"
      loader={<LoadingListSkeleton />}
    >
      {project && (
        <VStack spacing={4} pt={2} alignItems="flex-start" overflowY="auto" h={"full"}>
          <ProjectLead
            title={t("pages.project_instance.variables.project_lead")}
            projectLead={
              projectLead
                ? `${projectLead.firstName} ${projectLead.lastName}`
                : t("pages.flows.placeholders.unknown_member")
            }
            journeyId={project.id}
            readOnly={readOnly}
          />
          <ProjectTags tags={project.tags} readOnly={readOnly} />
          <ProjectPhase phase={project.phase || undefined} />
          <Accordion allowMultiple w="full" defaultIndex={[0, 1, 2]}>
            {/* External Data - Project ContextSection variables */}
            {projectContext.length ? (
              <AccordionItemWithHeader
                title={t("pages.project_instance.variables.external_data")}
                key="external-data"
              >
                <Box display="flex" flexDirection="column" gap="12px">
                  {projectContext.map((projectContextField) => (
                    <MetaDataField
                      key={projectContextField.label}
                      label={projectContextField.label}
                      value={projectContextField.value}
                    />
                  ))}
                </Box>
              </AccordionItemWithHeader>
            ) : null}
            {/* Project Data - Project context drawer variables */}
            <ContextVariables
              projectVariableCategories={projectVariableCategories(
                project?.variableCategories || []
              )}
              projectVariables={projectVariables}
              journeyId={project.id}
              readOnly={readOnly}
            />
            {/* Connected Journey variables */}
            {projectSteps.map((step) => {
              if (!step?.task?.connectedJourneysId) return null;
              return step.task.connectedJourneysId.map(
                (connectedJourneyId) =>
                  connectedJourneyId && (
                    <ConnectedJourneyMetadata
                      key={connectedJourneyId}
                      journeyId={connectedJourneyId}
                    />
                  )
              );
            })}
          </Accordion>
        </VStack>
      )}
    </CompositeLoader>
  );
};

export default ProjectDataPanel;
