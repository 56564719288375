import type { BaseRange, Descendant } from "slate";

interface RichTextEditorState {
  target: BaseRange | null;
  index: number;
  search: string;
  initialValue: Descendant[] | null;
}

interface RichTextEditorAction {
  type: "clear-target" | "update-text-editor-state";
  payload?: Partial<RichTextEditorState>;
}

function richTextEditorReducer(state: RichTextEditorState, action: RichTextEditorAction) {
  const { type: actionType, payload } = action;
  switch (actionType) {
    case "update-text-editor-state":
      return {
        ...state,
        ...(payload || {}),
      };
    case "clear-target":
      return {
        ...state,
        target: null,
        fileUploadTarget: null,
      };
    default:
      return state;
  }
}

export default richTextEditorReducer;
