import { Divider, ModalBody, VStack } from "@chakra-ui/react";
import TriggerVariableFormField from "@flows-platform/modules/FlowTemplates/components/FlowAssignmentModal/components/TriggerVariablesInput/components/TriggerVariableFormField";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import type { VariableType } from "@flows-platform/types";
import { useTranslation } from "react-i18next";

interface TriggerVariablesInputProps {
  triggerVariables: Record<string, VariableType>;
  requireCustomInstanceName: boolean;
}

const TriggerVariablesInput = ({
  triggerVariables,
  requireCustomInstanceName,
}: TriggerVariablesInputProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Divider />
      <ModalBody my={5}>
        <VStack spacing={5} align="start">
          {Object.keys(triggerVariables).map((identifier) => (
            <TriggerVariableFormField
              key={identifier}
              parentField="variables"
              identifier={identifier}
              variable={triggerVariables[identifier]}
            />
          ))}
          {requireCustomInstanceName && (
            <FormikField
              name="instanceName"
              label={t("forms.instance_name.label")}
              placeholder={t("forms.instance_name.placeholder")}
            />
          )}
        </VStack>
      </ModalBody>
    </>
  );
};

export default TriggerVariablesInput;
