import type { ApolloCache } from "@apollo/client";
import type {
  AllAuthenticatorsQuery,
  UpdateAuthenticatorBasicMutation,
  UpdateAuthenticatorBearerMutation,
  UpdateAuthenticatorOauth2Mutation,
} from "@flows-platform/generated/graphql";
import {
  AllAuthenticatorsDocument,
  AuthenticatorTypeEnum,
  FlowEnum,
  useGetAuthenticatorQuery,
  useUpdateBasicAuthenticatorClientMutation,
  useUpdateBearerAuthenticatorClientMutation,
  useUpdateOAuth2AuthenticatorClientMutation,
} from "@flows-platform/generated/graphql";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import isTruthy from "@kwest_fe/core/src/utils/isTruthy";
import { useTranslation } from "react-i18next";

import AuthenticatorForm from "./components/AuthenticatorForm/AuthenticatorForm";
import type { AuthenticatorFormValues } from "./schema";

interface UpdateAuthenticatorModalProps {
  authId: string;
  isOpen: boolean;
  onClose: () => void;
  allAuthenticators: AllAuthenticatorsQuery["allAuthenticators"];
}

function UpdateAuthenticatorModal({
  isOpen,
  onClose,
  authId,
  allAuthenticators,
}: UpdateAuthenticatorModalProps) {
  const { t } = useTranslation();

  // Wrting the updated authenticator to cache ensures the types are properly converted
  const writeUpdatedAuthenticatorToCache = ({
    cache,
    updatedAuthenticator,
  }: {
    cache: ApolloCache<any>;
    updatedAuthenticator:
      | UpdateAuthenticatorBasicMutation["authenticator"]
      | UpdateAuthenticatorBearerMutation["authenticator"]
      | UpdateAuthenticatorOauth2Mutation["authenticator"];
  }) => {
    if (updatedAuthenticator) {
      const { id, name, type } = updatedAuthenticator;
      cache.writeQuery<AllAuthenticatorsQuery>({
        query: AllAuthenticatorsDocument,
        data: {
          allAuthenticators: allAuthenticators?.filter(isTruthy).map((connection) => ({
            ...(connection?.id === id
              ? {
                  id,
                  name,
                  type,
                  __typename: "BaseAuthenticatorType",
                }
              : connection),
          })),
        },
      });
    }
  };

  const [updateOAuth2Authenticator] = useUpdateOAuth2AuthenticatorClientMutation({
    update(cache, { data }) {
      const updatedAuthenticator = data?.updateAuthenticatorOauth2?.authenticator;
      writeUpdatedAuthenticatorToCache({ cache, updatedAuthenticator });
    },
  });
  const [updateBasicAuthenticator] = useUpdateBasicAuthenticatorClientMutation({
    update(cache, { data }) {
      const updatedAuthenticator = data?.updateAuthenticatorBasic?.authenticator;
      writeUpdatedAuthenticatorToCache({ cache, updatedAuthenticator });
    },
  });
  const [updateBearerAuthenticator] = useUpdateBearerAuthenticatorClientMutation({
    update(cache, { data }) {
      const updatedAuthenticator = data?.updateAuthenticatorBearer?.authenticator;
      writeUpdatedAuthenticatorToCache({ cache, updatedAuthenticator });
    },
  });

  const getAuthenticatorQuery = useGetAuthenticatorQuery({
    variables: {
      id: authId,
    },
  });
  const { successToast, errorToast } = useDefaultToasts();

  if (!getAuthenticatorQuery.data?.getAuthenticator) {
    return null;
  }

  const queryData = getAuthenticatorQuery.data.getAuthenticator;
  const authValues: AuthenticatorFormValues = {
    name: queryData.name,
    authorizationFlow: FlowEnum.ClientCredentials,
    type: queryData.type,
    clientId: "",
    clientSecret: "",
    urlToken: "",
    scope: "",
    username: "",
    password: "",
    token: "",
  };

  if (queryData.__typename === "AuthenticatorBasicType") {
    authValues.username = queryData.username || "";
    authValues.password = queryData.password || "";
  } else if (queryData.__typename === "AuthenticatorBearerType") {
    authValues.token = queryData.token || "";
  } else if (queryData.__typename === "AuthenticatorOAuth2Type") {
    authValues.authorizationFlow = queryData.authorizationFlow as FlowEnum;
    authValues.clientId = queryData.clientId || "";
    authValues.clientSecret = queryData.clientSecret || "";
    authValues.urlToken = queryData.urlToken || "";
    authValues.scope = queryData.scope || "";
  }

  const handleFormSubmit = async ({
    type,
    authorizationFlow,
    clientId,
    clientSecret,
    name,
    password,
    token,
    urlToken,
    username,
    scope,
  }: AuthenticatorFormValues) => {
    let isError = false;
    let response;
    if (type === AuthenticatorTypeEnum.Basic) {
      response = await updateBasicAuthenticator({
        variables: {
          input: {
            id: authId,
            name,
            username,
            password,
          },
        },
      });
    } else if (type === AuthenticatorTypeEnum.Oauth2) {
      response = await updateOAuth2Authenticator({
        variables: {
          input: {
            id: authId,
            name,
            authorizationFlow,
            clientId,
            clientSecret,
            urlToken,
            scope,
          },
        },
      });
    } else if (type === AuthenticatorTypeEnum.Bearer) {
      response = await updateBearerAuthenticator({
        variables: {
          input: {
            id: authId,
            name,
            token,
          },
        },
      });
    }
    if (response?.errors) {
      isError = true;
    }

    if (!isError) {
      successToast(t("modals.authenticators.create.toast.success.title"));
    } else {
      errorToast(t("modals.authenticators.create.toast.error.title"))({
        message: t("pages.settings.sections.authenticators.save_error"),
      });
    }
  };

  return (
    <>
      <AuthenticatorForm
        authValues={authValues}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={handleFormSubmit}
      />
    </>
  );
}

export default UpdateAuthenticatorModal;
