import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";

import type {
  AllFlowsQuery,
  DeleteFlowClientMutationFn,
  DuplicateFlowClientMutationFn,
  SetFlowCurrentEditorClientMutationFn,
} from "../../generated/graphql";
import {
  useAllFlowsQuery,
  useDeleteFlowClientMutation,
  useDuplicateFlowClientMutation,
  useSetFlowCurrentEditorClientMutation,
} from "../../generated/graphql";
import ALL_FLOWS from "../../graphql/queries/flows/allFlows";
import LoadingListSkeleton from "../Shared/components/LoadingListSkeleton";

interface ProjectOverviewContainerProps {
  render: (
    flows: AllFlowsQuery["allMyAdminFlows"],
    deleteFlow: DeleteFlowClientMutationFn,
    duplicateFlow: DuplicateFlowClientMutationFn,
    setFlowCurrentEditor: SetFlowCurrentEditorClientMutationFn
  ) => React.ReactNode;
}

function ProjectsOverviewContainer({ render }: ProjectOverviewContainerProps) {
  const flowsQuery = useAllFlowsQuery({
    variables: {
      projectsOnly: true,
    },
  });
  const [setFlowCurrentEditor] = useSetFlowCurrentEditorClientMutation();
  const [deleteFlow] = useDeleteFlowClientMutation({ refetchQueries: [ALL_FLOWS, "AllFlows"] });
  const [duplicateFlow] = useDuplicateFlowClientMutation({
    refetchQueries: [ALL_FLOWS, "AllFlows"],
  });

  if (flowsQuery.loading) {
    return <LoadingListSkeleton />;
  }

  if (flowsQuery.error) {
    return <Placeholder type="error" header="Error loading projects" />;
  }

  const flows = flowsQuery.data?.allMyAdminFlows;

  if (!flows?.length) {
    return (
      <Placeholder
        type="empty-state"
        header="You haven't added any projects"
        description="Use the purple button to add your first project"
      />
    );
  }

  return (
    <ErrorBoundary>{render(flows, deleteFlow, duplicateFlow, setFlowCurrentEditor)}</ErrorBoundary>
  );
}

export default ProjectsOverviewContainer;
