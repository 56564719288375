import type { AvailableTemplatesQuery } from "@flows-platform/generated/graphql";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";

interface ConnectedFlowContextInterface {
  connectedFlow: { id: string; name: string };
  availableTemplates: AvailableTemplatesQuery["allMyAdminFlows"];
}

export const ConnectedFlowContext = createContext<ConnectedFlowContextInterface | null>(null);

interface ConnectedFlowProviderProps {
  connectedFlow: { id: string; name: string };
  availableTemplates: AvailableTemplatesQuery["allMyAdminFlows"];
}

export function ConnectedFlowProvider({
  connectedFlow,
  availableTemplates,
  children,
}: PropsWithChildren<ConnectedFlowProviderProps>) {
  const value = useMemo(
    () => ({
      connectedFlow,
      availableTemplates,
    }),
    [connectedFlow, availableTemplates]
  );

  return <ConnectedFlowContext.Provider value={value}>{children}</ConnectedFlowContext.Provider>;
}

export function useConnectedFlow() {
  const context = useContext(ConnectedFlowContext);
  if (context === undefined) {
    throw new Error("useConnectedFlow must be used within a ConnectedFlowProvider");
  }
  return (context || {}) as ConnectedFlowContextInterface;
}
