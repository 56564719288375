import { Checkbox, Text, VStack } from "@chakra-ui/react";
import { useToggleSubtaskStatusClientMutation } from "@flows-platform/generated/graphql";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import { captureException } from "@sentry/react";

interface SubTaskBoxProps {
  subTask: {
    id: string;
    name: string;
    description: string;
    isDone: boolean;
  };
}

export default function SubTaskBox({ subTask }: SubTaskBoxProps) {
  const [toggleSubTaskStatus, { loading }] = useToggleSubtaskStatusClientMutation();

  const handleChange = async () => {
    try {
      await toggleSubTaskStatus({
        variables: {
          input: {
            id: subTask.id,
            isDone: !subTask.isDone,
          },
        },
      });
    } catch (e) {
      captureException(e);
    }
  };

  return (
    <VStack
      key={subTask.id}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      padding={6}
      gap={3}
      borderWidth={2}
      borderColor="gray.200"
      borderRadius="lg"
      width="full"
    >
      <Checkbox isChecked={subTask.isDone} disabled={loading} onChange={handleChange}>
        {subTask.name}
      </Checkbox>
      <Text textStyle={TEXT_STYLES.lead14Normal}>{subTask.description}</Text>
    </VStack>
  );
}
