import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { FeatureFlags } from "@flows-platform/modules/Core/constants/enums";
import ProjectsContainer from "@flows-platform/modules/ProjectInstance/ProjectsOverview/components/ProjectsTable/components/ProjectsContainer/ProjectsContainer";
import ProjectsTableRow from "@flows-platform/modules/ProjectInstance/ProjectsOverview/components/ProjectsTable/components/ProjectsTableRow/ProjectsTableRow";
import useClientFeatureFlags from "@flows-platform/modules/Shared/hooks/useClientFeatureFlags";
import useTableSort from "@flows-platform/modules/Shared/hooks/useTableSort";
import InfiniteScrollerWithAutoLoad from "@flows-platform/modules/Shared/UI/layout/InfiniteScrollerWithAutoLoad";
import SortableColumnHeader from "@flows-platform/modules/Shared/UI/molecules/layout/SortableColumnHeader/SortableColumnHeader";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const COLUMN_WIDTH = "50px";

const removePrefix = (stringWithPrefix: string, prefix: string) => {
  const regex = new RegExp(`^${prefix}*`, "g");
  return stringWithPrefix.replace(regex, "");
};

interface ProjectsTableProps {
  showProjectTasks?: boolean;
  projectFilters: string;
}

export default function ProjectsTable({
  projectFilters,
  showProjectTasks = false,
}: ProjectsTableProps) {
  const { t } = useTranslation();
  const [installionExtrasEnabled] = useClientFeatureFlags([FeatureFlags.InstallionExtrasEnabled]);
  const { activeSort, sortOrderIcon, toggleSort } = useTableSort("-last_activity_at");

  return (
    <ProjectsContainer
      projectFilters={projectFilters}
      projectSort={activeSort}
      showProjectTasks={showProjectTasks}
    >
      {({ allProjects, totalRetrieved, fetchMoreData, refresh }) => {
        if (!allProjects?.total)
          return <Placeholder type="empty-state" header="You haven't added any projects" />;

        return (
          <InfiniteScrollerWithAutoLoad
            refresh={refresh}
            height={"85vh"}
            fetchMoreData={fetchMoreData}
            hasMore={Boolean(allProjects.more)}
            totalRetrieved={totalRetrieved}
            scrollThreshold={0.98}
          >
            <TableContainer w="full">
              <Table variant="simple" width="full">
                <Thead mb={4}>
                  <Tr>
                    <SortableColumnHeader
                      sortIcon={sortOrderIcon("flow__name,instance_name")}
                      onClick={toggleSort("flow__name,instance_name")}
                    >
                      {t("pages.projects.table.headers.name")}
                    </SortableColumnHeader>
                    <Th></Th>
                    {/* <Th minW={COLUMN_WIDTH}>{t("pages.projects.table.headers.instance")}</Th> */}
                    <SortableColumnHeader
                      minW={COLUMN_WIDTH}
                      sortIcon={sortOrderIcon("project_lead_name")}
                      onClick={toggleSort("project_lead_name")}
                    >
                      {t("pages.projects.table.headers.responsible")}
                    </SortableColumnHeader>
                    <SortableColumnHeader
                      minW={300}
                      sortIcon={sortOrderIcon("journey_step_progress")}
                      onClick={toggleSort("journey_step_progress")}
                    >
                      {t("pages.projects.table.headers.progress")}
                    </SortableColumnHeader>
                    <SortableColumnHeader
                      minW={300}
                      sortIcon={sortOrderIcon("phase__position")}
                      onClick={toggleSort("phase__position")}
                    >
                      {t("pages.projects.table.headers.phase")}
                    </SortableColumnHeader>
                    {/** Installion specific fields from the project context variables */}
                    {installionExtrasEnabled && (
                      <>
                        <SortableColumnHeader
                          minW={300}
                          sortIcon={sortOrderIcon(
                            "context_section__variables__niederlassung_company_name__value"
                          )}
                          onClick={toggleSort(
                            "context_section__variables__niederlassung_company_name__value"
                          )}
                        >
                          {t("pages.projects.table.headers.niederlassung_company_name")}
                        </SortableColumnHeader>
                        <SortableColumnHeader
                          minW={300}
                          sortIcon={sortOrderIcon(
                            "context_section__variables__partner_company_name__value"
                          )}
                          onClick={toggleSort(
                            "context_section__variables__partner_company_name__value"
                          )}
                        >
                          {t("pages.projects.table.headers.partner_company_name")}
                        </SortableColumnHeader>
                      </>
                    )}
                    <SortableColumnHeader
                      minW={COLUMN_WIDTH}
                      sortIcon={sortOrderIcon("last_activity_at")}
                      onClick={toggleSort("last_activity_at")}
                    >
                      {t("pages.projects.table.headers.last_activity")}
                    </SortableColumnHeader>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {allProjects.results.map(
                    (project, index) =>
                      project && (
                        <ProjectsTableRow
                          key={project.id}
                          project={project}
                          isLastRow={index === totalRetrieved - 1}
                          showProjectTasks={showProjectTasks}
                          onUpdate={refresh}
                        />
                      )
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </InfiniteScrollerWithAutoLoad>
        );
      }}
    </ProjectsContainer>
  );
}
