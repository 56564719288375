import {
  Box,
  HStack,
  IconButton,
  Input,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FormItemItemType as FormItemType } from "@flows-platform/generated/graphql";
import BooleanFormField from "@flows-platform/modules/Shared/components/BooleanFormField/BooleanFormField";
import FileUploadCaption from "@flows-platform/modules/Shared/components/FileUploadCaption/FileUploadCaption";
import FormikField from "@flows-platform/modules/Shared/components/FormikField";
import { SearchControl } from "@flows-platform/modules/Shared/components/MembershipSelector/components/FormikMembershipSelectField";
import ResizableInput from "@flows-platform/modules/Shared/components/ResizableInput";
import type { NonNullableOrderedFormItem } from "@flows-platform/types";
import shouldExecuteDragMove from "@flows-platform/utils/shouldExecuteDragMove";
import FileUploader from "@kwest_fe/core/src/components/FileUploader/FileUploader";
import RichTextEditor from "@kwest_fe/core/src/components/RichTextEditor";
import { useFormikContext } from "formik";
import { throttle } from "lodash";
import { useEffect, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { MdDragIndicator as DragIndicator } from "react-icons/md";
import Select from "react-select";

import FORM_TYPE from "../../../../../../../FlowEditor/constants/constants";
import FormikFormItemDraggableContextMenu from "./componentes/FormikFormItemDraggableContextMenu/FormikFormItemDraggableContextMenu";
import RemoteDataModal from "./componentes/RemoteDataModal/RemoteDataModal";

export const FIELDS_WITHOUT_LABELS = [FormItemType.DescriptionText];
export const FIELDS_WITHOUT_CAPTIONS = [FormItemType.DescriptionText];
const REMOTE_DATA_ENABLED_FIELDS = [FormItemType.Option, FormItemType.LongText];

interface FormikFormItemDraggableProps {
  formItem: NonNullableOrderedFormItem;
  index: number;
  isDraggable?: boolean;
  remoteDataEnabled?: boolean;
  variables?: Record<string, any>;
}

function FormikFormItemDraggable({
  formItem,
  index,
  isDraggable = true,
  remoteDataEnabled = false,
  variables,
}: FormikFormItemDraggableProps) {
  const {
    id,
    label,
    text,
    itemType,
    remoteDataUrl,
    useRemoteData,
    useCaptionText,
    useLabel,
    options,
    defaultText,
    isOptional,
  } = formItem;
  const itemRef = useRef<HTMLDivElement>(null);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const {
    values: {
      form: { orderedFormItems },
    },
    setFieldValue,
  } = useFormikContext<{
    form: { orderedFormItems: NonNullableOrderedFormItem[] };
  }>();

  const orderedFormItemsPath = "form.orderedFormItems";

  const [{ opacity }, drag, dragPreview] = useDrag({
    item: () => ({
      id,
      text,
      index,
      label,
      itemType,
      useLabel,
      useCaptionText,
      useRemoteData,
      options,
      defaultText,
      remoteDataUrl,
      isOptional,
    }),
    canDrag: !isOpen,
    type: FORM_TYPE,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.25 : 1,
    }),
    options: {
      dropEffect: "move",
    },
  });

  const [, drop] = useDrop({
    accept: [FORM_TYPE],
    hover: throttle((draggedStep, monitor) => {
      if (!itemRef.current) {
        return;
      }
      const dragIndex = draggedStep.index;
      const hoverIndex = index;

      if (shouldExecuteDragMove(monitor, itemRef, dragIndex, hoverIndex)) {
        const items = Array.from(orderedFormItems);
        if (items[dragIndex].id !== draggedStep.id) return;
        items.splice(dragIndex, 1);
        items.splice(hoverIndex, 0, draggedStep);
        setFieldValue(orderedFormItemsPath, items);
        /**
         * Note: we're mutating the monitor item here!
         * Generally it's better to avoid mutations,
         * but it's good here for the sake of performance
         * to avoid expensive index searches.
         */
        draggedStep.index = hoverIndex; // eslint-disable-line
      }
    }, 300),
  });

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  });

  if (isDraggable) {
    drag(drop(itemRef));
  }

  const disconnectRemoteData = () => {
    setFieldValue(`form.orderedFormItems[${index}].useRemoteData`, false);
    setFieldValue(`form.orderedFormItems[${index}].remoteDataUrl`, "");
  };

  return (
    <HStack
      opacity={opacity}
      ref={itemRef}
      alignItems="flex-start"
      w="full"
      py={4}
      px={2}
      sx={{ _hover: { bg: "gray.50" } }}
      transition="transform .4s"
      transform="translateX(0px, -5px)"
      data-testid="draggable-form-item"
    >
      {isDraggable && (
        <IconButton
          variant="link"
          aria-label="Move flow step"
          cursor="grab"
          color="gray.300"
          icon={<DragIndicator size={20} />}
          alignSelf="center"
        />
      )}
      <VStack w="full" alignItems="flex-start">
        {!FIELDS_WITHOUT_LABELS.includes(formItem.itemType) && useLabel && (
          <HStack>
            <FormikField
              name={`form.orderedFormItems[${index}].label`}
              variant="unstyled"
              renderElement={(props) => <ResizableInput {...props} placeholder="Label" />}
            />
            {isOptional && <Text color="gray.400">(optional)</Text>}
          </HStack>
        )}
        {formItem.itemType === FormItemType.Boolean && (
          <Box w="75%">
            <BooleanFormField />
          </Box>
        )}
        {formItem.itemType === FormItemType.ImageUpload && (
          <Box w="75%">
            <FileUploader accessToken={""} fileUrls={[]} />
          </Box>
        )}
        {formItem.itemType === FormItemType.FileUpload && (
          <Box w="75%">
            <FileUploader accessToken={""} fileUrls={[]} renderInactive={FileUploadCaption} />
          </Box>
        )}
        {!FIELDS_WITHOUT_CAPTIONS.includes(formItem.itemType) && useCaptionText && (
          <FormikField
            name={`form.orderedFormItems[${index}].text`}
            variant="unstyled"
            placeholder="Enter a helper text"
            renderElement={(props) => <Input {...props} fontSize="sm" color="gray.500" />}
          />
        )}
        {formItem.itemType === FormItemType.Option && (
          <Box w="75%">
            <Select
              isSearchable={false}
              options={orderedFormItems[index]?.options?.map((opt) => ({
                label: opt,
                value: opt,
                disabled: true,
              }))}
            />
          </Box>
        )}
        {formItem.itemType === FormItemType.DescriptionText && (
          <RichTextEditor
            name={`form.orderedFormItems[${index}].text`}
            placeholder="Description text"
            minHeight={150}
            background="white"
            variables={variables}
          />
        )}
        {formItem.itemType === FormItemType.ShortText && (
          <Box w="75%">
            <Input placeholder="Short text field" isDisabled />
          </Box>
        )}
        {formItem.itemType === FormItemType.LongText && (
          <Box w="75%">
            <Textarea placeholder="Long text field" isDisabled />
          </Box>
        )}
        {formItem.itemType === FormItemType.Number && (
          <Box w="75%">
            <Input placeholder="Number field" isDisabled type="number" />
          </Box>
        )}
        {formItem.itemType === FormItemType.Date && (
          <Box w="75%">
            <Input isDisabled type="date" />
          </Box>
        )}
        {formItem.itemType === FormItemType.Membership && (
          <Box w="75%">
            <Select
              isDisabled
              isSearchable
              components={{
                Control: SearchControl,
              }}
              options={[]}
              placeholder="Select a member of your workspace..."
            />
          </Box>
        )}
      </VStack>
      <HStack>
        {remoteDataEnabled && REMOTE_DATA_ENABLED_FIELDS.includes(formItem.itemType) && (
          <RemoteDataModal
            prefix={`form.orderedFormItems[${index}]`}
            onSave={() => {
              setFieldValue(`form.orderedFormItems[${index}].useRemoteData`, !!remoteDataUrl);
            }}
          />
        )}
        <FormikFormItemDraggableContextMenu
          formItem={formItem}
          index={index}
          orderedFormItems={orderedFormItems}
          orderedFormItemsPath={orderedFormItemsPath}
          remoteDataEnabled={remoteDataEnabled}
          disconnectRemoteData={disconnectRemoteData}
          setFieldValue={setFieldValue}
          onOpen={onOpen}
          isOpen={isOpen}
          onClose={onClose}
        />
      </HStack>
    </HStack>
  );
}

export default FormikFormItemDraggable;
