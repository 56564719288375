/* eslint-disable react/jsx-props-no-spreading */
import type { ImageProps } from "@chakra-ui/react";
import { Box } from "@chakra-ui/react";
import EncodedImagesViewer from "@core/components/EncodedImagesViewer";
import { Transforms } from "slate";
import type { RenderElementProps } from "slate-react";
import { ReactEditor, useFocused, useSelected, useSlateStatic } from "slate-react";

import { HighlightStyles } from "../../styles";
import type { ExtendedElement } from "../../types";

const ImageStyles: (selected: boolean, focused: boolean) => ImageProps = (selected, focused) => ({
  display: "block",
  maxWidth: "100%",
  maxHeight: "20em",
  ...(selected && focused ? HighlightStyles : {}),
});

function EmbeddedImage({
  attributes,
  element,
  children,
}: RenderElementProps & { element: ExtendedElement }) {
  const editor = useSlateStatic() as ReactEditor;
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  return (
    <Box {...attributes} _first={{ marginTop: 0 }} my={5}>
      {children}
      <Box display="inline-block">
        <EncodedImagesViewer
          images={element.url ? [element.url] : []}
          {...(element.fileId ? { id: element.fileId } : {})}
          onDeleteFile={() => {
            Transforms.removeNodes(editor, { at: path });
          }}
          {...ImageStyles(selected, focused)}
        />
      </Box>
    </Box>
  );
}

export default EmbeddedImage;
