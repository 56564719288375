import stripHtml from "@core/utils/stripHtml";
import escapeHtml from "escape-html";
import type { Descendant } from "slate";
import { Editor, Node as SlateNode, Text } from "slate";
import { jsx } from "slate-hyperscript";

import { BlockTypes, MarkTypes, TagTypes } from "./constants";
import type { ElementProperties, TextProperties } from "./types";

export type SerializableNode = Descendant & ElementProperties & TextProperties;

/**
 * This function will convert a Slate JSON object into an HTML string
 *
 * @param {Element} node Slate JSON object
 * @returns HTML string
 */
export const serialize = (node: SerializableNode) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }
    return string;
  }

  const children: string = node.children.map((n) => serialize(n as SerializableNode)).join("");
  let blockStyles = "";

  if (Editor.isEditor(node) && node.children.length === 1) {
    if (stripHtml(children)?.trim() === "") return "";
  }

  if (!Editor.isEditor(node)) {
    if (node.align) {
      blockStyles += `text-align: ${node.align};`;
    }

    switch (node.type) {
      case MarkTypes.code:
        return `<pre><code>${children}</code></pre>`;
      case BlockTypes.quote:
        return `<blockquote><p>${children}</p></blockquote>`;
      case BlockTypes.paragraph:
        return `<p style='${blockStyles}'>${children}</p>`;
      case MarkTypes.link:
        return `<a href="${escapeHtml(node.url)}">${children}</a>`;
      case BlockTypes["numbered-list"]:
        return `<ol style='${blockStyles}'>${children}</ol>`;
      case BlockTypes["bulleted-list"]:
        return `<ul style='${blockStyles}'>${children}</ul>`;
      case BlockTypes["list-item"]:
        return `<li>${children}</li>`;
      case BlockTypes.placeholder:
        return `<span class="placeholder-variable">{{${node.character || ""}}}</span>`;
      case BlockTypes.image:
        return `<img ${node.fileId ? `id="${node.fileId}"` : ""} src="${node.url || ""} "/>`;
      case BlockTypes.file:
        return `<a class="file" href="${node.url || ""}">${SlateNode.string(node) || ""}</a>`;
      default:
        return children;
    }
  }
  return children;
};

/**
 * This function will convert a DOM element into a Slate-compatible JSON structure
 *
 * @param {Element} el Element to be deserialized
 * @param {Object} markAttributes Element attributes
 * @returns Slate JSON Object
 */
export const deserialize = (el: HTMLElement, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    return jsx("text", markAttributes, el.textContent);
  }
  if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = { ...markAttributes } as TextProperties;

  switch (el.nodeName) {
    case "STRONG":
      nodeAttributes.bold = true;
      break;
    case "EM":
      nodeAttributes.italic = true;
      break;
    case "U":
      nodeAttributes.underline = true;
      break;
    default:
      break;
  }

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node as HTMLElement, nodeAttributes))
    .flat() as Descendant[];

  if (children.length === 0) {
    children.push(jsx("text", nodeAttributes, ""));
  }

  switch (el.nodeName) {
    case TagTypes.BODY:
      return jsx("fragment", {}, children);
    case TagTypes.BR:
      return jsx("text", {}, "\n");
    case TagTypes.BLOCKQUOTE:
      return jsx("element", { type: BlockTypes.quote }, children);
    case TagTypes.P:
      return jsx("element", { type: BlockTypes.paragraph, align: el.style.textAlign }, children);
    case TagTypes.OL:
      return jsx(
        "element",
        { type: BlockTypes["numbered-list"], align: el.style.textAlign },
        children
      );
    case TagTypes.UL:
      return jsx(
        "element",
        { type: BlockTypes["bulleted-list"], align: el.style.textAlign },
        children
      );
    case TagTypes.LI:
      return jsx("element", { type: BlockTypes["list-item"] }, children);
    case TagTypes.A:
      if (el.className === "file") {
        return jsx(
          "element",
          {
            type: BlockTypes.file,
            url: el.getAttribute("href"),
          },
          [{ text: el.innerHTML }]
        );
      }

      return jsx("element", { type: MarkTypes.link, url: el.getAttribute("href") }, children);
    case TagTypes.IMG:
      return jsx(
        "element",
        {
          type: BlockTypes.image,
          url: el.getAttribute("src"),
          fileId: el.getAttribute("id"),
        },
        [{ text: "" }]
      );
    case TagTypes.SPAN:
      if (el.className === "placeholder-variable") {
        const placeholderVariable = el.innerHTML.replace(/\{\{(.+)\}\}/i, "$1");
        return jsx(
          "element",
          {
            type: BlockTypes.placeholder,
            character: placeholderVariable.trim(),
          },
          [{ text: "" }]
        );
      }
      return children;
    default:
      return children;
  }
};
