import { Box } from "@chakra-ui/react";
import { useKwestAuth } from "@core/modules/Core/providers/KwestAuthProvider";

import useFileUploadService from "../../modules/Shared/hooks/useFileUploadService";
import useImageEncoder from "../../modules/Shared/hooks/useImageEncoder";
import type { ImageViewerProps } from "../FileUploader/components/ImageViewer";
import ImageViewer from "../FileUploader/components/ImageViewer";
import ProgressIndicator from "../FileUploader/components/ProgressIndicator/ProgressIndicator";
import type { FileUploaderFileUrl } from "../FileUploader/constants/types";

interface EncodedImagesViewerProps extends Omit<ImageViewerProps, "images"> {
  images: FileUploaderFileUrl[];
}

function EncodedImagesViewer({ images, ...restProps }: EncodedImagesViewerProps) {
  const { accessToken } = useKwestAuth();
  const { downloadFile } = useFileUploadService({
    accessToken,
  });
  const { encodedImages, imageIsDownloading } = useImageEncoder({
    imageUrls: images,
    downloadFile,
  });

  if (imageIsDownloading) {
    return (
      <Box padding={7} width="full">
        <ProgressIndicator progress={100} loadingText="Loading image" />
      </Box>
    );
  }

  const allEncodedImageFiles = Object.values(encodedImages)
    .map((encodedFile) => encodedFile.encodedFile as string)
    .filter(Boolean);

  return allEncodedImageFiles.length ? (
    <ImageViewer images={allEncodedImageFiles} {...restProps} />
  ) : null;
}

export default EncodedImagesViewer;
