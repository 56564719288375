import { Accordion } from "@chakra-ui/react";
import {
  useGetTeamClientQuery,
  useJourneyStepsAwaitingUserQuery,
  useProjectsAwaitingUserQuery,
} from "@flows-platform/generated/graphql";
import TablePageLayout from "@flows-platform/modules/Shared/UI/layout/MainLayout/components/TablePageLayout/TablePageLayout";
import AccordionItemWithHeader from "@flows-platform/modules/Shared/UI/molecules/layout/AccordionItemWithHeader/AccordionItemWithHeader";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import { ErrorBoundary } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import ProjectTasksTodosBoard from "./components/ProjectTasksTodosBoard";
import TodoCardsBoard from "./components/TodoCardsBoard";

export default function TodosView() {
  const { t } = useTranslation();

  const { teamId } = useParams<{ teamId: string }>();

  const variables = teamId ? { forTeams: [teamId] } : undefined;

  const {
    data: stepsData,
    loading: isLoadingSteps,
    error: stepsError,
  } = useJourneyStepsAwaitingUserQuery({ variables });

  const {
    data: projectsData,
    loading: isLoadingProjects,
    error: projectsError,
  } = useProjectsAwaitingUserQuery({ variables });

  const { data: teamData, error: teamError } = useGetTeamClientQuery({
    variables: { teamId: teamId! },
    skip: !teamId,
  });

  if (stepsError || projectsError || teamError)
    return <Placeholder type="error" header={t("pages.inbox.error.loading")} />;

  return (
    <ErrorBoundary>
      <TablePageLayout
        title={`${t("pages.inbox.title")}: ${
          teamId ? teamData?.getTeam?.name || "" : t("pages.inbox.forMe")
        }`}
      >
        <Accordion defaultIndex={[0, 1]} allowMultiple>
          <AccordionItemWithHeader title={t("pages.inbox.table.section.do_now.title")} withPadding>
            <TodoCardsBoard
              todos={stepsData?.journeyStepsAwaitingUser}
              isLoading={isLoadingSteps}
            />
          </AccordionItemWithHeader>
          <AccordionItemWithHeader
            title={t("pages.inbox.table.section.projects.title")}
            withPadding
          >
            <ProjectTasksTodosBoard
              todos={projectsData?.projectsAwaitingUser}
              isLoading={isLoadingProjects}
            />
          </AccordionItemWithHeader>
        </Accordion>
      </TablePageLayout>
    </ErrorBoundary>
  );
}
