import type { ModalProps, ThemingProps } from "@chakra-ui/react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import type { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface GenericConfirmationModalProps extends Pick<ModalProps, "isOpen" | "onClose" | "size"> {
  title: JSX.Element | string;
  onAction: () => void;
  actionText?: string;
  actionColor?: ThemingProps["colorScheme"];
  actionDisabled?: boolean;
}

export default function GenericConfirmationModal({
  title,
  isOpen,
  onClose,
  onAction,
  actionText = "Save",
  actionColor = "purple",
  actionDisabled = false,
  children,
  size = "xl",
}: PropsWithChildren<GenericConfirmationModalProps>) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button
            variant="outline"
            onClick={onClose}
            mr={3}
            size="sm"
            data-testid="confirm-modal-cancel-button"
          >
            {t("global.actions.cancel")}
          </Button>
          <Button
            colorScheme={actionColor}
            onClick={onAction}
            size="sm"
            isDisabled={actionDisabled}
            data-testid="confirm-modal-confirm-button"
          >
            {actionText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
