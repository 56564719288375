import { HStack, Checkbox, Text } from "@chakra-ui/react";
import { components, OptionProps } from "react-select";

export default function MultiSelectOption({ children, ...props }: OptionProps) {
  const { isSelected } = props;
  return (
    <components.Option {...props}>
      <HStack pointerEvents="none">
        <Checkbox isChecked={isSelected} />
        <Text>{children}</Text>
      </HStack>
    </components.Option>
  );
}
