interface IndexedList extends Record<string, any> {
  index?: number;
}

/**
 * Set position prop and optionally omit index property
 */
export default <T extends IndexedList>(
  items?: (T | null)[] | null,
  omitIndex = false
): (Omit<T, "index"> & { position: number })[] | undefined =>
  items?.filter(Boolean).map((el: T, i) => {
    const item = el;

    if (omitIndex) delete item.index;
    return { ...item, position: i };
  });
