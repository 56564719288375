import { Heading, HStack, Input, Text } from "@chakra-ui/react";
import useEditableInput from "@flows-platform/modules/Shared/hooks/useEditableInput";
import ConfirmChanges from "@kwest_fe/core/src/components/UI/molecules/forms/ConfirmChanges";
import { useTranslation } from "react-i18next";

import { PROJECT_META_DATA_LINE_HEIGHT } from "../../constants";

interface DateInputMetaDataFieldProps {
  identifier: string;
  label: string;
  value: number | string;
  handleUpdate: (label: string, type: string, newValue: number | string) => void;
  isLoading: boolean;
  type: string;
  readOnly?: boolean;
}

// TODO: Abstract elements that are reused on the normal input component
function DateInputMetaDataField({
  identifier,
  label,
  value,
  handleUpdate,
  isLoading,
  type,
  readOnly,
}: DateInputMetaDataFieldProps) {
  const {
    inputRef,
    isEditing,
    valueState,
    setValueState,
    toggleEditingOn,
    toggleEditingOff,
    handleCancel,
    handleConfirm,
    isChanged,
  } = useEditableInput({
    value,
    handleUpdate: (newValue) => {
      handleUpdate(identifier, type, newValue);
    },
  });

  const { t } = useTranslation();

  return (
    <HStack alignItems="center" w="100%">
      <Heading fontSize="md" textColor="gray.500" flex="1" w="50%">
        {label}
      </Heading>
      <HStack alignItems="center" flex="1" onClick={toggleEditingOn}>
        {(!isEditing || readOnly) && (
          <Text
            fontSize="md"
            textColor="gray.500"
            flex="1"
            minHeight={PROJECT_META_DATA_LINE_HEIGHT}
            lineHeight={PROJECT_META_DATA_LINE_HEIGHT}
          >
            {valueState}
          </Text>
        )}
        {!readOnly && isEditing && (
          <Input
            ref={inputRef}
            variant={"ghost"}
            placeholder={t("pages.project_instance.variables.placeholder")}
            type={"date"}
            onChange={(e) => {
              setValueState(e.target.value);
            }}
            flex={1}
            height={PROJECT_META_DATA_LINE_HEIGHT}
            px={1}
            onBlur={toggleEditingOff}
            value={valueState}
          />
        )}
        <ConfirmChanges
          isLoading={isLoading}
          display={isChanged}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          buttonSize="xs"
        />
      </HStack>
    </HStack>
  );
}

export default DateInputMetaDataField;
