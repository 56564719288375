import {
  Box,
  Checkbox,
  CheckboxGroup,
  Divider,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAllTeamsQuery } from "@flows-platform/generated/graphql";
import type { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

interface MemberTeamsProps {
  teams: string[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
const MemberTeams = ({ teams, onChange }: MemberTeamsProps) => {
  const { data: allTeams, loading: isLoadingAllTeams } = useAllTeamsQuery();

  const { t } = useTranslation();

  if (isLoadingAllTeams)
    return (
      <Box borderWidth={1} borderRadius="lg" paddingX={5} paddingY={3}>
        <Spinner />
      </Box>
    );

  return (
    <VStack borderWidth={1} borderRadius="lg" paddingX={5} paddingY={3} spacing={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        width="100%"
        alignItems="center"
      >
        <Text size="14px" color="primary.xdark">
          {t("pages.people.table.headers.teams")}
        </Text>
      </Box>
      <Divider />
      <Box w={"full"} display={"flex"} flexDir={"column"} gap={4}>
        <CheckboxGroup colorScheme="green" defaultValue={teams}>
          {!allTeams?.allTeams?.length ? (
            <div> No teams are set up in this workspace yet </div>
          ) : (
            allTeams.allTeams.map((team) => {
              if (!team) return null;

              return (
                <VStack alignItems="flex-start" spacing={0} key={team.id}>
                  <Checkbox value={team.id} name={`teams`} onChange={onChange}>
                    <HStack>
                      <Text>{team.name} </Text>
                      {team.externalSystem && (
                        <Text fontWeight="normal" fontSize="small">
                          ({team.externalSystem?.name})
                        </Text>
                      )}
                    </HStack>
                  </Checkbox>
                </VStack>
              );
            })
          )}
        </CheckboxGroup>
      </Box>
    </VStack>
  );
};

export default MemberTeams;
