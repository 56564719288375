import * as Yup from "yup";

const AddProjectStepFormSchema = Yup.object().shape({
  name: Yup.string().required("forms.custom_task_name.validation_message"),
  status: Yup.boolean(),
  dueDate: Yup.string().nullable(),
  receiverId: Yup.string().nullable(),
  content: Yup.string(),
});

export default AddProjectStepFormSchema;
