import { Box, HStack, Input } from "@chakra-ui/react";
import FormikField from "@flows-platform/modules/Shared/components/FormikField";
import CloseButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/CloseButton";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";

import FormikDropdown from "./components/FormikDropdown";

export const VARIABLE_TO_RESPONSE_KEY = "httpRequest.variableToResponseKeys";

const VARIABLE_TYPES = [
  {
    label: "String",
    value: FlowStepVariableTypeEnum.STRING,
  },
  {
    label: "Number",
    value: FlowStepVariableTypeEnum.NUMBER,
  },
  {
    label: "Boolean",
    value: FlowStepVariableTypeEnum.BOOLEAN,
  },
  {
    label: "File URL",
    value: FlowStepVariableTypeEnum.FILE_URL,
  },
];

/**
 * Component for individual Variable to Response Key Fields
 *
 */
interface VariableToResponseKeyFieldProps {
  fieldName: string;
  onRemove: () => void;
}

export default function VariableToResponseKeyField({
  fieldName,
  onRemove,
}: VariableToResponseKeyFieldProps) {
  return (
    <HStack gap={5}>
      <Box flexGrow={1}>
        <FormikField
          name={`${fieldName}.variable.label`}
          label="Variable Name"
          renderElement={(props) => <Input {...props} />}
        />
      </Box>
      <Box flexGrow={1}>
        <FormikDropdown
          name={`${fieldName}.variable.type`}
          label="Variable Type"
          menuItems={VARIABLE_TYPES}
        />
      </Box>

      <HStack alignItems="end" flexGrow={1}>
        <FormikField
          name={`${fieldName}.responseKey`}
          label="Response Key"
          renderElement={(props) => <Input {...props} />}
        />

        <Box paddingBottom={1}>
          <CloseButton onClick={onRemove} />
        </Box>
      </HStack>
    </HStack>
  );
}
