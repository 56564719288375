import * as Yup from "yup";

import stripHtml from "./stripHtml";

export const validateHtmlEmailField = async (value?: string) => {
  const textValue = value && stripHtml(value);
  const variableMatcher = /\{\{\s*([\w.]+)\s*\}\}/;
  if (!textValue || variableMatcher.test(textValue)) {
    return true;
  }
  return Yup.string().email().isValid(textValue);
};
