import { useFlowInstanceQuery } from "@flows-platform/generated/graphql";
import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";
import { useParams } from "react-router-dom";

import FlowInstancePage from "./FlowInstancePage";

export default function FlowInstanceContainer() {
  const { journeyId } = useParams();

  const oneJourneyQuery = useFlowInstanceQuery({
    variables: { journeyId: journeyId! },
  });

  if (oneJourneyQuery.loading) return <LoadingSpinnerOverlay />;

  if (oneJourneyQuery.error)
    return (
      <Placeholder type="error" header="Something went wrong while trying to load the person" />
    );

  return (
    <ErrorBoundary>
      <FlowInstancePage flow={oneJourneyQuery.data?.oneJourney} />
    </ErrorBoundary>
  );
}
