import { Box } from "@chakra-ui/react";
import type { JourneyStepResourceQuery } from "@todo-viewer/generated/graphql";
import { useEffect, useRef } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import messageParent from "../Core/utils/messageParent";
import BackButton from "./components/BackButton";
import TodoViewApproval from "./components/TodoViewApproval";
import TodoViewForm from "./components/TodoViewForm";
import TodoViewMessage from "./components/TodoViewMessage";
import TodoViewTask from "./components/TodoViewTask";
import TodoViewTodoList from "./components/TodoViewTodoList";

interface TodoViewPageProps {
  stepResource: NonNullable<JourneyStepResourceQuery["getJourneyStepResource"]>;
}

export default function TodoViewPage({ stepResource }: TodoViewPageProps) {
  const location = useLocation();
  const todoFormRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();

  const isPreview = location.pathname.includes("/preview");

  const showBackButton = searchParams.get("back") === "true";
  const getComponentForResource = () => {
    switch (stepResource.__typename) {
      case "TodoListResourceType":
        return (
          <TodoViewTodoList resource={{ ...stepResource, __typename: "TodoListResourceType" }} />
        );
      case "FormResourceType":
        return <TodoViewForm resource={{ ...stepResource, __typename: "FormResourceType" }} />;
      case "TaskResourceType":
        return (
          <TodoViewTask
            resource={{ ...stepResource, __typename: "TaskResourceType" }}
            isPreview={isPreview}
          />
        );
      case "ApprovalResourceType":
        return (
          <TodoViewApproval resource={{ ...stepResource, __typename: "ApprovalResourceType" }} />
        );
      case "MessageResourceType":
        return (
          <TodoViewMessage
            resource={{ ...stepResource, __typename: "MessageResourceType" }}
            isPreview={isPreview}
          />
        );
      default:
        return null;
    }
  };

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      messageParent("update_height", { height: entry.contentRect.height });
    }
  });

  useEffect(() => {
    if (todoFormRef.current) {
      resizeObserver.observe(todoFormRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [todoFormRef.current]);

  return (
    <Box minH="100vh" background="gray.50">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pos={"relative"}
        ref={todoFormRef}
      >
        {showBackButton && <BackButton />}
        {getComponentForResource()}
      </Box>
    </Box>
  );
}
