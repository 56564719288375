import { Tag } from "@chakra-ui/react";

interface FlowLabelsProps {
  labels: ({ id: string; name?: string } | null)[];
}
const FlowLabels = ({ labels }: FlowLabelsProps) => {
  return (
    <Tag fontSize={"14px"} py={2}>
      {labels[0]?.name}
    </Tag>
  );
};

export default FlowLabels;
