import type {
  AllJourneysQueryResult,
  AllJourneysWithProjectTasksQuery,
} from "@flows-platform/generated/graphql";
import { FeatureFlags } from "@flows-platform/modules/Core/constants/enums";
import useAllJourneysQueryWithPagination from "@flows-platform/modules/Shared/hooks/useAllJourneysQueryWithPagination";
import useClientFeatureFlags from "@flows-platform/modules/Shared/hooks/useClientFeatureFlags";
import CompositeLoader from "@kwest_fe/core/src/components/CompositeLoader";
import LoadingSpinnerOverlay from "@kwest_fe/core/src/components/LoadingSpinnerOverlay";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";

const DEFAULT_PAGE_LIMIT = 50;
const PROJECT_TASKS_DISPLAY_PAGE_LIMIT = 10;

interface ProjectsContainerProps {
  projectFilters: string;
  projectSort: string;
  showProjectTasks?: boolean;
  children: ({
    allProjects,
    fetchMoreData,
    totalRetrieved,
    refresh,
  }: {
    allProjects: AllJourneysWithProjectTasksQuery["allJourneys"];
    fetchMoreData: () => void;
    totalRetrieved: number;
    refresh: AllJourneysQueryResult["refetch"];
  }) => JSX.Element;
}

function ProjectsContainer({
  projectFilters,
  projectSort,
  showProjectTasks = false,
  children,
}: ProjectsContainerProps) {
  const [installionExtrasEnabled] = useClientFeatureFlags([FeatureFlags.InstallionExtrasEnabled]);
  const { loading, error, data, fetchMoreData, totalRetrieved, refetch } =
    useAllJourneysQueryWithPagination({
      variables: {
        pagination: {
          limit: showProjectTasks ? PROJECT_TASKS_DISPLAY_PAGE_LIMIT : DEFAULT_PAGE_LIMIT,
          offset: 0,
        },
        filters: projectFilters,
        sort: projectSort,
        includeContextSection: installionExtrasEnabled,
        includeProjectTasks: showProjectTasks,
      },
      fetchPolicy: "network-only",
    });
  const { allJourneys: allProjects } = data || {};

  if (!loading && !error && !allProjects?.total) {
    return <Placeholder type="empty-state" header="You haven't added any projects" />;
  }

  return (
    <CompositeLoader
      loading={loading}
      error={error}
      loader={<LoadingSpinnerOverlay />}
      errorMessage="Error loading projects"
    >
      {children({
        allProjects,
        fetchMoreData,
        totalRetrieved,
        refresh: refetch,
      })}
    </CompositeLoader>
  );
}

export default ProjectsContainer;
