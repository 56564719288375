import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import {
  FlowStepTypeEnum,
  useFormQuery,
  useUpdateFormClientMutation,
} from "@flows-platform/generated/graphql";
import { FlowStepObserver } from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import useFormObserver from "@flows-platform/modules/Shared/hooks/useFormObserver";
import setItemPosition from "@flows-platform/utils/setItemPosition";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import FlowStepResourceLoaderContainer from "../../../../../FlowStepResourceLoaderContainer/FlowStepResourceLoaderContainer";
import { useFlowEditorDrawer } from "../../../../providers/FlowEditorDrawerProvider";
import FormEditor from "./FormEditor";

/**
 * Only the fields we need to store and validate in the drawer
 * are present here (label, text, options).
 */
export const FormValidationSchema = {
  form: Yup.object()
    .shape({
      id: Yup.string(),
      receiverId: Yup.string().nullable(),
      receiverRole: Yup.string(),
      orderedFormItems: Yup.array().of(
        Yup.object({
          id: Yup.string(),
          label: Yup.string(),
          text: Yup.string(),
          position: Yup.number(),
        })
      ),
    })
    .nullable(),
};

interface FormContainerProps {
  initialFormValuesLoaded: boolean;
}

function FormContainer({ initialFormValuesLoaded }: FormContainerProps) {
  const { updateActiveFlowStep } = useFlowEditor();
  const { flowStep } = useFlowEditorDrawer() || {};
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [updateForm] = useUpdateFormClientMutation({
    onCompleted(data) {
      const { flow, ...updatedFlowStep } = data?.updateForm?.form?.flowStep ?? {};
      updateActiveFlowStep(updatedFlowStep);
      successToast(t("pages.flow_step.toast.update_form.success.title"));
    },
    onError: errorToast(t("pages.flow_step.toast.update_form.error.title")),
  });

  useFormObserver(
    new FlowStepObserver({
      fields: ["form"],
      flowStepType: FlowStepTypeEnum.Form,
      async onChanged(modifiedValues) {
        const items = setItemPosition(modifiedValues.form?.orderedFormItems, true);
        const form = {
          ...modifiedValues.form,
          ...(modifiedValues.form?.orderedFormItems && {
            orderedFormItems: items,
          }),
        };
        const res = await updateForm({
          variables: {
            input: {
              id: flowStep?.formId,
              ...form,
            },
          },
        });
        return res;
      },
    })
  );

  return (
    <FlowStepResourceLoaderContainer
      query={useFormQuery}
      initialValuesKey="form"
      queryOptions={{ variables: { formId: flowStep?.formId } }}
      loadingErrorMessage="Error loading form contents"
      resolveInitialValue={(data) => data?.getForm}
      initialFormValuesLoaded={initialFormValuesLoaded}
    >
      <FormEditor />
    </FlowStepResourceLoaderContainer>
  );
}

export default FormContainer;
