import { VStack } from "@chakra-ui/react";
import { useOneJourneyQuery } from "@flows-platform/generated/graphql";
import AccordionItemWithHeader from "@flows-platform/modules/Shared/UI/molecules/layout/AccordionItemWithHeader/AccordionItemWithHeader";
import type { VariableType } from "@flows-platform/types";
import mapObjectValues from "@flows-platform/utils/mapUtils";

import JourneyVariable from "./components/JourneyVariable/JourneyVariable";

interface ConnectedJourneyMetadataProps {
  journeyId: string;
}

export default function ConnectedJourneyMetadata({ journeyId }: ConnectedJourneyMetadataProps) {
  const oneJourneyQuery = useOneJourneyQuery({
    variables: { journeyId },
  });
  const journey = oneJourneyQuery.data?.oneJourney;
  const journeySteps = journey?.orderedJourneySteps;

  if (!journeySteps || journeySteps.length === 0) return null;

  const parsedJourneyVariables: Record<string, VariableType> = JSON.parse(
    journey?.allVariables || "{}"
  );

  const allJourneyVariableComponents = journeySteps.reduce<Record<string, JSX.Element>>(
    (acc, journeyStep) => {
      const parsedVariables: Record<string, VariableType> = JSON.parse(
        journeyStep?.variables || "{}"
      );
      return {
        ...acc,
        ...mapObjectValues(parsedVariables, (identifier, value) => (
          <JourneyVariable
            key={identifier}
            identifier={identifier}
            variable={value}
            linkedResourceType={journeyStep?.stepType || undefined}
            linkedResourceId={journeyStep?.formId || undefined}
          />
        )),
      };
    },
    mapObjectValues(parsedJourneyVariables, (identifier, value) => (
      <JourneyVariable
        key={identifier}
        identifier={identifier}
        variable={value}
        linkedResourceId={journey.id}
      />
    ))
  );

  const variableComponents = Object.values(allJourneyVariableComponents);
  if (!variableComponents.length) return null;

  return (
    <AccordionItemWithHeader title={oneJourneyQuery.data?.oneJourney?.flow.name || ""}>
      <VStack alignItems="flex-start" gap={3} width="full">
        {variableComponents}
      </VStack>
    </AccordionItemWithHeader>
  );
}
