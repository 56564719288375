import { Box, Divider, Flex, Link as ChakraLink, Text } from "@chakra-ui/react";
import DateView from "@flows-platform/modules/Shared/UI/atoms/Text/DateView/DateView";
import { Link } from "react-router-dom";

interface InstallerUpcomingTodoCardProps {
  journey: {
    projectId: string;
    projectName: string;
    projectTasks: {
      stepId: string;
      stepName: string;
      dueDate: string;
      taskId: string;
    }[];
  };
  onProjectSelect: () => void;
}

const InstallerUpcomingTodoCard = ({
  journey,
  onProjectSelect,
}: InstallerUpcomingTodoCardProps) => {
  return (
    <Flex rounded={"md"} flexDirection={"column"} rowGap={2}>
      <Flex flexDir={"column"} p={4} gap={2}>
        <Flex justifyContent={"space-between"} alignContent={"center"}>
          <ChakraLink
            fontSize={"14px"}
            color={"gray.700"}
            fontWeight={600}
            textDecoration={"underline"}
            textUnderlineOffset={"3px"}
            onClick={onProjectSelect}
          >
            {journey?.projectName}
          </ChakraLink>
          <Box bg={"gray.100"} p={1}>
            <DateView date={new Date(journey.projectTasks[0].dueDate)} />
          </Box>
        </Flex>
        {journey.projectTasks.map((task) => (
          <Flex flexDir={"column"} gap={4} key={task.stepId}>
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              bg={"gray.50"}
              py={3}
              px={2}
              gap={8}
            >
              <Link to={`/projects/tasks/${task.taskId}`}>
                <Text fontSize={"14px"} color={"gray.700"} fontWeight={600} noOfLines={1}>
                  {task.stepName}
                </Text>
              </Link>
              {task.dueDate && <DateView date={new Date(task.dueDate)} />}
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Divider />
    </Flex>
  );
};

export default InstallerUpcomingTodoCard;
