import { Spinner, useDisclosure } from "@chakra-ui/react";
import { useDeleteJourneyTagClientMutation } from "@flows-platform/generated/graphql";
import GenericDeleteModal from "@kwest_fe/core/src/components/UI/organisms/modals/GenericDeleteModal";
import { CancelIcon } from "@kwest_fe/core/src/theme/icons";
import { useParams } from "react-router-dom";

interface RemoveTagButtonProps {
  tagId: string;
}

const RemoveTagButton = ({ tagId }: RemoveTagButtonProps) => {
  const { projectId } = useParams<{ projectId: string }>();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [removeTagMutation, { loading: isRemoving }] = useDeleteJourneyTagClientMutation();

  const removeTag = async () => {
    if (isRemoving) return;
    await removeTagMutation({
      variables: { journeyId: projectId, tagId },
    });
  };

  return (
    <>
      <button onClick={onOpen}>
        {isRemoving ? <Spinner size="xs" /> : <CancelIcon size="sm" />}
      </button>
      <GenericDeleteModal header="tag" isOpen={isOpen} onClose={onClose} onConfirm={removeTag} />
    </>
  );
};

export default RemoveTagButton;
