import type { QueryHookOptions, QueryResult } from "@apollo/client";
import type { PropsWithChildren } from "react";

import CompositeLoader from "../CompositeLoader";
import LoadingListSkeleton from "../LoadingListSkeleton";

interface QueryLoaderContainerProps<TData> {
  query: (baseOptions?: QueryHookOptions<TData>) => QueryResult<TData>;
  queryOptions?: QueryHookOptions<TData>;
  loadingErrorMessage: string;
  render: ({ data, refetch }: { data: TData; refetch: () => void }) => JSX.Element;
}

function QueryLoaderContainer<TData>({
  query,
  queryOptions,
  loadingErrorMessage,
  render,
}: PropsWithChildren<QueryLoaderContainerProps<TData>>) {
  const { data, loading, error, refetch } = query(queryOptions);
  return (
    <CompositeLoader
      loading={loading}
      error={error}
      loader={<LoadingListSkeleton />}
      errorMessage={loadingErrorMessage}
    >
      {data && render({ data, refetch })}
    </CompositeLoader>
  );
}

export default QueryLoaderContainer;
