import { HStack, Spinner, Text } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";
import type { Props as InfinteScrollDefaultProps } from "react-infinite-scroll-component";
import InfiniteScroll from "react-infinite-scroll-component";

interface InfiniteScrollerWithAutoLoadProps {
  hasMore: boolean;
  fetchMoreData: () => void;
  refresh: () => void;
  totalRetrieved: number;
  height?: string;
}

type InfiniteScrollComponentProps = Omit<
  InfinteScrollDefaultProps,
  "dataLength" | "hasMore" | "height" | "loader" | "next" | "refreshFunction"
>;

function InfinteScrollLoader() {
  return (
    <HStack w="full" justifyContent="center" py={4}>
      <Spinner color="purple.500" size="lg" emptyColor="purple.200" />
      <Text>Loading</Text>
    </HStack>
  );
}

export default function InfiniteScrollerWithAutoLoad({
  hasMore,
  fetchMoreData,
  refresh,
  totalRetrieved,
  children,
  height,
  scrollThreshold = 0.98,
  ...rest
}: PropsWithChildren<InfiniteScrollComponentProps & InfiniteScrollerWithAutoLoadProps>) {
  return (
    <InfiniteScroll
      refreshFunction={refresh}
      height={height}
      {...rest}
      loader={<InfinteScrollLoader />}
      dataLength={totalRetrieved}
      hasMore={hasMore}
      next={fetchMoreData}
      scrollThreshold={scrollThreshold}
    >
      {children}
    </InfiniteScroll>
  );
}
