import { useToast } from "@chakra-ui/react";
import useCreateFlowMutationWithCacheUpdate from "@flows-platform/modules/Shared/hooks/useCreateFlowMutationWithCacheUpdate";
import toastSettings from "@flows-platform/theme/settings";
import AddButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/AddButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NewTemplateButton = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();

  const { createFlow } = useCreateFlowMutationWithCacheUpdate({
    onCompleted(data) {
      if (data?.createFlow?.flow) navigate(`/flow-templates/${data.createFlow.flow.id}`);
    },
    onError(error) {
      toast({
        ...toastSettings,
        status: "error",
        title: "Error creating flow",
        description: error.message,
      });
    },
  });

  return (
    <AddButton onClick={createFlow} data-testid="create-flow-button">
      {t("models.flow.actions.new")}
    </AddButton>
  );
};

export default NewTemplateButton;
