import { MenuItem } from "@chakra-ui/react";
import type { AuthenticatorType } from "@flows-platform/generated/graphql";
import { useAllAuthenticatorsQuery } from "@flows-platform/generated/graphql";
import Dropdown from "@kwest_fe/core/src/components/Dropdown";
import { useField } from "formik";

function getAuthName(authenticators: (AuthenticatorType | null)[], id: string) {
  return authenticators.find((auth) => auth?.id === id)?.name || "";
}

interface AuthenticatorSelectorProps {
  fieldName: string;
}

export default function AuthenticatorSelector({ fieldName }: AuthenticatorSelectorProps) {
  const [authIdField, , authIdHelpers] = useField(`${fieldName}.id`);
  const [, , authTypeHelpers] = useField(`${fieldName}.type`);

  const allAuthenticatorsQuery = useAllAuthenticatorsQuery();
  const authenticators = allAuthenticatorsQuery.data?.allAuthenticators || [];

  if (authenticators.length === 0) {
    return null;
  }

  const value = authIdField ? getAuthName(authenticators, authIdField.value) : "Select";

  return (
    <Dropdown
      optionalSubText
      label="Select an Authenticator"
      value={value}
      menuItems={authenticators.map(
        (auth) =>
          auth && (
            <MenuItem
              key={auth.id}
              onClick={() => {
                authIdHelpers.setValue(auth.id);
                authTypeHelpers.setValue(auth.type);
              }}
            >
              {auth.name}
            </MenuItem>
          )
      )}
    />
  );
}
