export const PHASE_COLORS = [
  "blue.400",
  "teal.400",
  "pink.400",
  "yellow.400",
  "purple.300",
  "orange.300",
  "cyan.400",
  "green.400",
  "blue.600",
  "teal.600",
  "pink.700",
  "yellow.600",
  "purple.700",
  "cyan.600",
  "orange.600",
];
