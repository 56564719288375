import * as Yup from "yup";

import type {
  CreateAuthenticatorBasicInputType,
  CreateAuthenticatorBearerInputType,
  CreateAuthenticatorOAuth2InputType,
} from "../../../../../../generated/graphql";
import {
  AuthenticatorTypeEnum,
  FlowEnum as AuthenticatorOAuth2FlowEnum,
} from "../../../../../../generated/graphql";

const NewAuthenticatorSchema = Yup.object().shape({
  name: Yup.string().required("A name is required for this connection"),
  type: Yup.string().oneOf(Object.values(AuthenticatorTypeEnum)).required(),
  authorizationFlow: Yup.string()
    .oneOf(Object.values(AuthenticatorOAuth2FlowEnum))
    .when("type", {
      is: AuthenticatorTypeEnum.Oauth2,
      then: Yup.string().required("Please select an authorization flow for this connection"),
    }),
  clientId: Yup.string().when("type", {
    is: AuthenticatorTypeEnum.Oauth2,
    then: Yup.string().required("Please enter a valid Client ID for this connection"),
  }),
  clientSecret: Yup.string().when("type", {
    is: AuthenticatorTypeEnum.Oauth2,
    then: Yup.string().required("Please enter a valid Client Secret for this connection"),
  }),
  urlToken: Yup.string().when("type", {
    is: AuthenticatorTypeEnum.Oauth2,
    then: Yup.string().required("Please enter a valid Url Token for this connection"),
  }),
  scope: Yup.string(),
  username: Yup.string().when("type", {
    is: AuthenticatorTypeEnum.Basic,
    then: Yup.string().required("forms.username.validation_message"),
  }),
  password: Yup.string().when("type", {
    is: AuthenticatorTypeEnum.Basic,
    then: Yup.string().required("forms.password.validation_message"),
  }),
  token: Yup.string().when("type", {
    is: AuthenticatorTypeEnum.Bearer,
    then: Yup.string().required("forms.token.validation_message"),
  }),
});

export type AuthenticatorFormValues = CreateAuthenticatorBasicInputType &
  CreateAuthenticatorBearerInputType &
  CreateAuthenticatorOAuth2InputType & { type: AuthenticatorTypeEnum };

export const NewAuthenticatorInitialValues: AuthenticatorFormValues = {
  name: "",
  authorizationFlow: "" as AuthenticatorOAuth2FlowEnum,
  type: "" as AuthenticatorTypeEnum,
  clientId: "",
  clientSecret: "",
  urlToken: "",
  scope: "",
  username: "",
  password: "",
  token: "",
};

export default NewAuthenticatorSchema;
