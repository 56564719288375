import { Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface DropdownConfirmChangesProps {
  onClose: () => void;
  isLoading?: boolean;
}

const DropdownConfirmChanges = ({ onClose, isLoading }: DropdownConfirmChangesProps) => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      gap={3}
      paddingTop={3}
      alignItems="center"
    >
      <Button variant="outline" onClick={onClose}>
        {t("global.actions.cancel")}
      </Button>
      <Button colorScheme="purple" type="submit" disabled={isLoading} isLoading={isLoading}>
        {t("global.actions.save")}
      </Button>
    </Box>
  );
};

export default DropdownConfirmChanges;
