import { Box } from "@chakra-ui/react";
import { SearchIcon } from "@core/theme/icons";
import { components, ControlProps } from "react-select";

export default function SearchControl({ children, ...props }: ControlProps) {
  return (
    <components.Control {...props}>
      <Box
        fontSize={"14px"}
        display={"inline-flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        width={"100%"}
        marginLeft={".7em"}
      >
        <SearchIcon />
        {children}
      </Box>
    </components.Control>
  );
}
