import { Box, Tag, Text } from "@chakra-ui/react";
import type { ConnectedFlowQuery } from "@flows-platform/generated/graphql";
import StartInstanceButton from "@flows-platform/modules/Shared/components/StartInstanceButton";
import type { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ConnectedJourneyRowProps {
  connectedJourneyId: string | null;
  flow: NonNullable<ConnectedFlowQuery["oneFlow"]>;
  setModal: Dispatch<SetStateAction<{ display: boolean; autoStartFlow: boolean }>>;
}

const ConnectedJourneyRow = ({ connectedJourneyId, flow, setModal }: ConnectedJourneyRowProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { name, isDraft, currentEditor } = flow;

  return (
    <Box display={"flex"} justifyContent={"space-between"} w={"full"} alignItems={"center"}>
      <Box display={"inline-flex"} alignItems={"center"} gap={2}>
        {connectedJourneyId ? (
          <Text
            fontSize={"14px"}
            textDecorationLine={"underline"}
            textUnderlineOffset={4}
            cursor={"pointer"}
            onClick={() => {
              navigate(`/flows/${connectedJourneyId}`);
            }}
          >
            {name}
          </Text>
        ) : (
          <Text fontSize={"14px"}>{name}</Text>
        )}
        <Box fontSize={12} fontWeight={400} textColor={"gray.500"} textAlign={"left"}>
          {connectedJourneyId && "1/1"}
        </Box>
      </Box>
      <Text width="100px">{isDraft && <Tag>{t("pages.flows_templates.status.draft")}</Tag>}</Text>
      <Text>{currentEditor?.workspaceEmail}</Text>
      {!connectedJourneyId && (
        <StartInstanceButton
          onClickStart={() => {
            setModal({ display: true, autoStartFlow: true });
          }}
          onClickSchedule={() => {
            setModal({ display: true, autoStartFlow: false });
          }}
        />
      )}
    </Box>
  );
};

export default ConnectedJourneyRow;
