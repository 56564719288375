import { Box, CloseButton, HStack } from "@chakra-ui/react";
import QueryLoaderContainer from "@kwest_fe/core/src/components/QueryLoaderContainer";
import TruncatedText from "@kwest_fe/core/src/components/UI/atoms/Text/TruncatedText";

import { useOneJourneyQuery } from "../../../../generated/graphql";
import ProjectBadges from "../../../ProjectInstance/components/ProjectMetadata/ProjectBadges";
import ProjectDataPanel from "../../../ProjectInstance/components/ProjectMetadata/ProjectDataPanel";

interface InstallerProjectMetadataProps {
  projectId: string;
  onClose: () => void;
}

export default function InstallerProjectMetadata({
  projectId,
  onClose,
}: InstallerProjectMetadataProps) {
  return (
    <QueryLoaderContainer
      query={useOneJourneyQuery}
      queryOptions={{
        variables: {
          journeyId: projectId || "",
        },
        fetchPolicy: "cache-first",
        skip: !projectId,
      }}
      loadingErrorMessage="Project not found"
      render={({ data: project }) => (
        <Box
          display={"flex"}
          flexDirection={"column"}
          width="100%"
          maxH={"99vh"}
          bgColor={"white"}
          overflowY={"auto"}
        >
          <HStack alignItems="center" pb={1.5} mb={1.5} px={"20px"} py={"14px"}>
            <HStack alignItems="center" flex={1} gap={2}>
              <TruncatedText maxW={{ base: 350, xl: 450 }} fontWeight={600} color="gray.700">
                {project.oneJourney?.flow.name}
              </TruncatedText>
              <ProjectBadges
                isArchived={!!project.oneJourney?.isArchived}
                isPaused={!!project.oneJourney?.isPaused}
              />
            </HStack>
            <CloseButton onClick={onClose} />
          </HStack>

          <Box px={6} minH={"40%"}>
            <ProjectDataPanel projectId={projectId} readOnly />
          </Box>
        </Box>
      )}
    />
  );
}
