import defaultNull from "@kwest_fe/core/src/utils/defaultNull";
import * as Yup from "yup";

const CreateMembershipSchema = Yup.object().shape({
  firstName: Yup.string().required("Please provide a first name"),
  lastName: Yup.string().required("Please provide a last name"),
  workspaceEmail: Yup.string()
    .email("That's not a valid email address")
    .required("Please provide a company email address"),
  startDate: Yup.string()
    .required("Please provide a start date")
    .nullable()
    .default(null)
    .transform(defaultNull),
});

export default CreateMembershipSchema;
