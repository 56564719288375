import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  Menu,
  MenuButton,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { FormItemItemType as FormItemType } from "@flows-platform/generated/graphql";
import BooleanFormField from "@flows-platform/modules/Shared/components/BooleanFormField";
import FileUploadCaption from "@flows-platform/modules/Shared/components/FileUploadCaption";
import { SearchControl } from "@flows-platform/modules/Shared/components/MembershipSelector/components/FormikMembershipSelectField";
import type { NonNullableOrderedFormItem } from "@flows-platform/types";
import FileUploader from "@kwest_fe/core/src/components/FileUploader";
import { useRef } from "react";
import { GoKebabHorizontal as KebabIcon } from "react-icons/go";
import { MdDragIndicator as DragIndicator } from "react-icons/md";
import Select from "react-select";

interface StaticFormItemProps {
  formItem: NonNullableOrderedFormItem;
}

function StaticFormItem({ formItem }: StaticFormItemProps) {
  const { text, label } = formItem;
  const labelValue = label.length > 0 ? label : "Label";
  const itemRef = useRef(null);
  const { getAccessTokenSilently } = useAuth0();
  return (
    <HStack ref={itemRef} width="670px" bg="white">
      <IconButton
        variant="ghost"
        aria-label="Move flow step"
        cursor="grab"
        color="gray.300"
        icon={<DragIndicator size={20} />}
        alignSelf="center"
      />

      <VStack w="full" alignItems="flex-start">
        {formItem.itemType !== FormItemType.DescriptionText && (
          <Input variant="unstyled" defaultValue={labelValue} color="gray.500" />
        )}
        {formItem.itemType === FormItemType.Boolean && (
          <Box w="75%">
            <BooleanFormField captionText={text || ""} />
          </Box>
        )}
        {formItem.itemType === FormItemType.ImageUpload && (
          <Box w="75%">
            <FileUploader
              accessToken={getAccessTokenSilently()}
              fileUrls={[]}
              captionText={text || ""}
            />
          </Box>
        )}
        {formItem.itemType === FormItemType.FileUpload && (
          <Box w="75%">
            <FileUploader
              accessToken={getAccessTokenSilently()}
              fileUrls={[]}
              captionText={text || ""}
              renderInactive={FileUploadCaption}
            />
          </Box>
        )}
        {formItem.itemType === FormItemType.DescriptionText && (
          <Textarea defaultValue={text || ""} resize="none" />
        )}
        {formItem.itemType === FormItemType.ShortText && <Input placeholder="Short text" />}
        {formItem.itemType === FormItemType.LongText && <Textarea placeholder="Long text" />}
        {formItem.itemType === FormItemType.Number && <Input placeholder="Number" />}
        {formItem.itemType === FormItemType.Date && <Input type="date" />}
        {formItem.itemType === FormItemType.Membership && (
          <Box w="75%">
            <Select
              components={{
                Control: SearchControl,
              }}
            />
          </Box>
        )}
        {formItem.itemType === FormItemType.Option && (
          <Box w="75%">
            <Select />
          </Box>
        )}
      </VStack>

      <Menu>
        <MenuButton as={Button} size="sm">
          <KebabIcon />
        </MenuButton>
      </Menu>
    </HStack>
  );
}

export default StaticFormItem;
