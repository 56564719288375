import { Button, DrawerBody, DrawerContent, DrawerHeader, HStack } from "@chakra-ui/react";
import { ChannelEnum } from "@flows-platform/generated/graphql";
import FormikField from "@flows-platform/modules/Shared/components/FormikField/FormikField";
import ResizableInput from "@flows-platform/modules/Shared/components/ResizableInput/ResizableInput";
import PreviewButton from "@flows-platform/modules/Shared/UI/atoms/Buttons/PreviewButton";
import PreloadableDrawer from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/components/PreloadableDrawer/PreloadableDrawer";
import {
  FlowEditorDrawerContextConsumer,
  FlowEditorDrawerProvider,
} from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import compareObjects from "@kwest_fe/core/src/utils/compareObjects";
import type { FormikProps } from "formik";
import { Formik } from "formik";
import { t } from "i18next";
import { useCallback, useRef } from "react";

import { flowStepTypes } from "../FlowEditor/components/FlowStep/constants/constants";
import FlowStepTemplateRenderer from "./components/FlowStepTemplateRenderer/FlowStepTemplateRenderer";
import { FlowEditorDrawerSchema } from "./constants/schema";

interface FlowEditorDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onDrawerOpened: () => Promise<any>;
  savingChanges: boolean;
}

function FlowEditorDrawer({
  isOpen,
  onClose,
  onDrawerOpened,
  savingChanges,
}: FlowEditorDrawerProps) {
  const formRef = useRef<FormikProps<any>>(null);

  const onDrawerClosed = useCallback(() => {
    formRef.current?.submitForm();
    onClose();
  }, [formRef, onClose]);

  return (
    <PreloadableDrawer
      size="lg"
      placement="right"
      onClose={onDrawerClosed}
      isOpen={isOpen}
      preloadAction={onDrawerOpened}
      savingChanges={savingChanges}
    >
      {({ preloadComplete }) => (
        <FlowEditorDrawerProvider>
          <FlowEditorDrawerContextConsumer>
            {({ updateFlowStep, initialFormValues, setInitialFormValues, flowStep }) => {
              const { stepType } = flowStep || {};
              if (!stepType) return null;
              return (
                <Formik
                  innerRef={formRef}
                  initialValues={initialFormValues}
                  validationSchema={FlowEditorDrawerSchema}
                  onSubmit={(values, { resetForm, setSubmitting }) => {
                    const modifiedValues = compareObjects(values, initialFormValues);
                    updateFlowStep(modifiedValues).then(() => {
                      resetForm({ values });
                      setInitialFormValues(values);
                      setSubmitting(false);
                    });
                  }}
                  enableReinitialize
                  validateOnChange={false}
                >
                  {({ isValid, isSubmitting, dirty, submitForm }) => (
                    <DrawerContent>
                      <DrawerHeader borderBottomWidth="1px">
                        <HStack spacing={3} justifyContent="space-between">
                          <FormikField
                            name="name"
                            renderElement={(props) => (
                              <ResizableInput
                                {...props}
                                variant="titleInput"
                                data-testid="flow-step-name-input"
                                placeholder={t(flowStepTypes[stepType]?.defaultName)}
                              />
                            )}
                          />

                          <HStack>
                            {(flowStep?.todoList ||
                              flowStep?.formId ||
                              flowStep?.taskId ||
                              (flowStep?.message &&
                                flowStep.message.channel === ChannelEnum.Email)) && (
                              <PreviewButton
                                onClick={() =>
                                  window.open(`/todo/${flowStep?.id}/preview`, "_blank")
                                }
                              />
                            )}

                            <Button
                              disabled={false}
                              variant="outline"
                              onClick={onClose}
                              data-testid="flow-step-cancel-button"
                            >
                              {t("global.actions.cancel")}
                            </Button>
                            <Button
                              onClick={submitForm}
                              disabled={(dirty && !isValid) || isSubmitting}
                              isLoading={isSubmitting}
                              loadingText="Saving"
                              colorScheme="purple"
                              data-testid="flow-step-save-button"
                            >{`${t("global.actions.save")} ${t(
                              flowStepTypes?.[stepType]?.sidebarLabel || ""
                            )}`}</Button>
                          </HStack>
                        </HStack>
                      </DrawerHeader>
                      <DrawerBody p={0}>
                        <FlowStepTemplateRenderer loading={!preloadComplete} />
                      </DrawerBody>
                    </DrawerContent>
                  )}
                </Formik>
              );
            }}
          </FlowEditorDrawerContextConsumer>
        </FlowEditorDrawerProvider>
      )}
    </PreloadableDrawer>
  );
}

export default FlowEditorDrawer;
