import { FormControl, FormLabel, HStack, Switch } from "@chakra-ui/react";

interface SwitchWithLabelProps {
  label: string;
  labelFor?: string;
  isChecked: boolean;
  setChecked: (value: boolean) => void;
}

export default function SwitchWithLabel({
  labelFor,
  label,
  isChecked,
  setChecked,
}: SwitchWithLabelProps) {
  return (
    <HStack as={FormControl} alignItems="center" w="auto">
      <Switch
        colorScheme="purple"
        onChange={(e) => {
          setChecked(e.target.checked);
        }}
        isChecked={isChecked}
      />
      <FormLabel htmlFor={labelFor} mb="0">
        {label}
      </FormLabel>
    </HStack>
  );
}
