import type { OneJourneyQuery } from "@flows-platform/generated/graphql";
import { useLoadContextSectionClientMutation } from "@flows-platform/generated/graphql";
import type { LabelValuePair } from "@flows-platform/types";
import { useCallback, useEffect, useState } from "react";

function parseContext(contextVariables: string) {
  const contextJSON = JSON.parse(contextVariables);
  const parsedContext = Object.keys(contextJSON).map((keys) => ({
    label: contextJSON[keys].label,
    value: contextJSON[keys].value,
  }));
  return parsedContext;
}

export default function useProjectContext(
  project: OneJourneyQuery["oneJourney"]
): [LabelValuePair[], () => Promise<void>] {
  const [context, setContext] = useState<LabelValuePair[]>([]);
  const [loadContextSection] = useLoadContextSectionClientMutation({
    onCompleted: (data) => {
      setContext(parseContext(data.loadContextSection?.variables));
    },
    onError: () => {
      setContext([
        {
          label: "Error",
          value: "Could not load project data",
        },
      ]);
    },
  });

  const contextSectionId = project?.contextSectionId;
  const fetchContextSection = useCallback(async () => {
    if (!contextSectionId) return;
    await loadContextSection({
      variables: {
        contextId: contextSectionId,
      },
    });
  }, [contextSectionId, loadContextSection]);
  useEffect(() => {
    fetchContextSection();
  }, [fetchContextSection]);

  return [context, fetchContextSection];
}
