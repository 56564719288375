import { Tag, TagLabel, TagLeftIcon, TagRightIcon } from "@chakra-ui/react";
import { useState } from "react";
import { MdOutlineArticle } from "react-icons/md";

import { CancelIcon, DownloadIcon } from "../../theme/icons";
import { parseFileName } from "../../utils";

interface FileListItemProps {
  filePath: string;
  onDownloadFile?: () => void;
  onDeleteFile?: () => void;
}

function FileListItem({ filePath, onDownloadFile, onDeleteFile }: FileListItemProps) {
  const parsedFileName = parseFileName(filePath);
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <Tag
      shadow={"none"}
      key={parsedFileName}
      variant="outline"
      border="1px solid tako.input.border"
      color={"tako.input.border"}
      cursor="pointer"
      w="full"
      size="lg"
    >
      <TagLeftIcon as={MdOutlineArticle} />
      <TagLabel
        _hover={{ textDecoration: "underline" }}
        onClick={() => {
          onDownloadFile?.();
        }}
        title={parsedFileName}
        flexGrow={1}
      >
        {parsedFileName}
      </TagLabel>
      {onDeleteFile ? (
        <TagRightIcon
          as={CancelIcon}
          onClick={() => {
            if (!isDeleting) {
              onDeleteFile();
              setIsDeleting(true);
            }
          }}
          _hover={{ color: "gray.700" }}
        >
          <CancelIcon size="xs" />
        </TagRightIcon>
      ) : (
        <TagRightIcon
          as={DownloadIcon}
          onClick={() => {
            onDownloadFile?.();
          }}
          _hover={{ color: "gray.700" }}
        />
      )}
    </Tag>
  );
}

export default FileListItem;
