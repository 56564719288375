import { todoViewerUrl } from "@flows-platform/config/todoViewer";
import type { FlowInstanceQuery } from "@flows-platform/generated/graphql";
import PageWrapper from "@flows-platform/modules/Shared/UI/layout/PageWrapper";
import PageHeader from "@kwest_fe/core/src/components/UI/layout/PageHeader/PageHeader";
import usePageTitle from "@kwest_fe/core/src/hooks/usePageTitle";
import { FlowInstanceSummaryTable } from "@kwest_fe/core/src/modules/FlowInstance/FlowInstanceSummaryTable";

import type { LayoutReducerAction } from "../Shared/UI/layout/LayoutProvider";
import { useLayout } from "../Shared/UI/layout/LayoutProvider";
import FlowContextMenu from "./components/FlowContextMenu/FlowContextMenu";

interface FlowInstancePageProps {
  flow: FlowInstanceQuery["oneJourney"];
}

export default function FlowInstancePage({ flow }: FlowInstancePageProps) {
  const { dispatch } = useLayout();

  usePageTitle(flow?.flow.name || "Flow");

  return (
    <PageWrapper>
      <PageHeader
        title={flow?.flow.name}
        headerContent={flow?.flow && <FlowContextMenu id={flow.id} />}
        toggleSidebar={(toggleString: LayoutReducerAction) => {
          dispatch({ type: toggleString });
        }}
      />
      <FlowInstanceSummaryTable flow={flow} stepUrl={(stepId) => todoViewerUrl(`todo/${stepId}`)} />
    </PageWrapper>
  );
}
