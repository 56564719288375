import type { VariableType } from "@flows-platform/types";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import * as Yup from "yup";

const triggerVariableValidator = (variable: VariableType) => {
  const variableErrorMessage = "global.generic_is_required";

  const variableTypeValidatorMap = {
    [FlowStepVariableTypeEnum.DATE]: Yup.date().required(variableErrorMessage),
    [FlowStepVariableTypeEnum.BOOLEAN]: Yup.bool().required(variableErrorMessage),
    [FlowStepVariableTypeEnum.NUMBER]: Yup.number().required(variableErrorMessage),
    [FlowStepVariableTypeEnum.FILE]: Yup.mixed().required(variableErrorMessage),
    [FlowStepVariableTypeEnum.IMAGE]: Yup.mixed().required(variableErrorMessage),
    [FlowStepVariableTypeEnum.ROLE]: Yup.mixed().required(variableErrorMessage),
  };

  return variable.type in variableTypeValidatorMap
    ? variableTypeValidatorMap[variable.type]
    : Yup.string().required(variableErrorMessage);
};

export const triggerVariablesValidationSchema = (triggerVariables: Record<string, VariableType>) =>
  Object.keys(triggerVariables)
    .filter((identifier) => identifier !== "project_lead")
    .reduce(
      (acc, identifier) => ({
        ...acc,
        [identifier]: triggerVariableValidator(triggerVariables[identifier]),
      }),
      {}
    ) || {};

const FlowAssignmentFormSchema = (triggerVariables: Record<string, VariableType>) =>
  Yup.object().shape({
    journeyStartDate: Yup.string().required(
      "modals.flow_assignment.form.journey_start_date.validation_message"
    ),
    requireCustomInstanceNames: Yup.boolean(),
    instanceName: Yup.string()
      .nullable()
      .when("requireCustomInstanceName", {
        is: true,
        then: Yup.string()
          .nullable()
          .required("modals.flow_assignment.form.instance_name.validation_message"),
      }),
    variables: Yup.object().shape(triggerVariablesValidationSchema(triggerVariables)),
  });

export default FlowAssignmentFormSchema;
