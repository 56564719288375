import { useApolloClient } from "@apollo/client";
import { IconButton, useDisclosure, useToast } from "@chakra-ui/react";
import type { AllPhasesSettingsQuery } from "@flows-platform/generated/graphql";
import {
  AllPhasesSettingsDocument,
  useRemoveProjectPhaseMutation,
} from "@flows-platform/generated/graphql";
import toastSettings from "@flows-platform/theme/settings";
import GenericDeleteModal from "@kwest_fe/core/src/components/UI/organisms/modals/GenericDeleteModal";
import { DeleteIcon } from "@kwest_fe/core/src/theme/icons";
import { useTranslation } from "react-i18next";

interface DeletePhaseProps {
  id: string;
}
const DeletePhase = ({ id }: DeletePhaseProps) => {
  const toast = useToast();
  const client = useApolloClient();
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deletePhase, { loading: isDeleting }] = useRemoveProjectPhaseMutation({
    onError: () => {
      client.refetchQueries({
        include: "active",
      });
      toast({
        ...toastSettings,
        status: "error",
        title: t("pages.settings.sections.phases.actions.delete.error"),
      });
    },
  });

  const handleDelete = () => {
    deletePhase({ variables: { id } });

    const allPhases = client.readQuery<AllPhasesSettingsQuery>({
      query: AllPhasesSettingsDocument,
    });

    client.writeQuery({
      query: AllPhasesSettingsDocument,
      data: {
        allProjectPhases: allPhases?.allProjectPhases?.filter((phase) => phase?.id !== id),
      },
    });
  };

  return (
    <>
      <IconButton
        _hover={{
          backgroundColor: "gray.100",
        }}
        variant="ghost"
        aria-label="Delete phase"
        icon={<DeleteIcon color="red.400" />}
        isDisabled={isDeleting}
        onClick={onOpen}
        size="xs"
        data-testid="delete-phase-button"
      />
      <GenericDeleteModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleDelete}
        header="Phase"
        body={t("pages.settings.sections.phases.modal.delete.body")}
        size="2xl"
      />
    </>
  );
};

export default DeletePhase;
