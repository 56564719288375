import type { TableCellProps } from "@chakra-ui/react";

export const TableColumnStyle: TableCellProps = {
  paddingLeft: 2,
  paddingRight: 0,
  py: 1,
  borderBottom: "none",
  fontSize: "md",
  verticalAlign: "top",
};

export const TableHeaderStyle: TableCellProps = {
  ...TableColumnStyle,
  fontWeight: "medium",
};

export const FixedWidthTableHeaderStyle: TableCellProps = {
  ...TableHeaderStyle,
  w: "32%",
  maxW: "35%",
};
