import { CircularProgress, Text, VStack } from "@chakra-ui/react";

interface ProgressIndicatorProps {
  loadingText?: string;
  progress?: number;
  showProgressIndicator?: boolean;
}

function ProgressIndicator({
  loadingText = "Uploading image...",
  progress,
  showProgressIndicator = false,
}: ProgressIndicatorProps) {
  return (
    <VStack spacing={2} align="center">
      <CircularProgress
        value={progress}
        thickness="4px"
        color="purple.500"
        size="120px"
        isIndeterminate={!showProgressIndicator}
      />
      <Text color="purple.600" fontWeight="bold">
        {loadingText}
      </Text>
    </VStack>
  );
}

export default ProgressIndicator;
