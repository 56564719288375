import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { useAllJourneysSearchLazyQuery } from "@flows-platform/generated/graphql";
import { useDebounce } from "@kwest_fe/core/src/modules/Shared/hooks/useDebounce";
import isMacintosh from "@kwest_fe/core/src/utils/isMacintosh";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";

import SearchResult from "./SearchResult";

interface SearchModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const menuShortcut = isMacintosh ? "meta" : "ctrl";

const SearchModal = ({ isOpen, onOpen, onClose }: SearchModalProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFn, { loading, data }] = useAllJourneysSearchLazyQuery({});

  const ref = useHotkeys<HTMLInputElement>(`${menuShortcut}+k`, onOpen);

  const { t } = useTranslation();

  const handleClose = () => {
    setSearchTerm("");
    onClose();
  };

  const debouncedSearch = useDebounce(() => {
    searchFn({
      variables: {
        filters: JSON.stringify({
          instance_name__icontains: searchTerm,
          or: {
            flow__name__icontains: searchTerm,
          },
        }),
      },
    });
  }, 400);

  useEffect(() => {
    if (searchTerm.length > 2) {
      debouncedSearch();
    }
  }, [searchTerm]);

  const results = data?.allJourneys?.results;

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size={"3xl"}>
      <ModalOverlay />
      <ModalContent>
        <Divider />
        <ModalBody py={5} display={"flex"} alignItems={"center"} flexDirection={"column"}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" color={"gray.600"}>
              <SearchIcon />
            </InputLeftElement>
            <Input
              ref={ref}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              value={searchTerm}
              type="search"
              placeholder={t("modals.global_search.placeholder")}
              color={"gray.700"}
            />
          </InputGroup>
          {loading ? (
            <Box
              display={"flex"}
              w={"100%"}
              alignItems={"center"}
              my={4}
              justifyContent={"center"}
              color={"primary.main"}
            >
              <Spinner />
            </Box>
          ) : (
            <Box mt={4} w={"100%"}>
              {searchTerm.length > 2 && !results?.length && (
                <Box color={"gray.500"} textAlign={"center"}>
                  {t("modals.global_search.no_results")}
                </Box>
              )}
              {results
                ?.filter((journey) => journey?.id)
                .map((journey) => journey && <SearchResult journey={journey} key={journey?.id} />)}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;
