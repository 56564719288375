import type { ButtonProps } from "@chakra-ui/react";
import { Button, HStack, Text, VStack } from "@chakra-ui/react";

const ACTIVE_COLOR = "gray.500";
const INACTIVE_COLOR = "gray.100";

const ButtonStyles: (activeState?: boolean) => ButtonProps = (activeState) => ({
  display: "block",
  flexGrow: 1,
  _hover: {
    bgColor: activeState ? ACTIVE_COLOR : "gray.300",
  },
  bgColor: activeState ? ACTIVE_COLOR : INACTIVE_COLOR,
  color: activeState ? "white" : undefined,
});

interface BooleanFormFieldProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  captionText?: string;
  disabled?: boolean;
}

function BooleanFormField({ checked, onChange, captionText, disabled }: BooleanFormFieldProps) {
  return (
    <VStack alignItems="flex-start">
      <HStack width="full">
        <Button
          {...ButtonStyles(checked === true)}
          onClick={() => {
            onChange?.(true);
          }}
          disabled={disabled}
        >
          Yes
        </Button>
        <Button
          {...ButtonStyles(checked === false)}
          onClick={() => {
            onChange?.(false);
          }}
          disabled={disabled}
        >
          No
        </Button>
      </HStack>
      {captionText && (
        <Text fontSize="sm" color={ACTIVE_COLOR}>
          {captionText}
        </Text>
      )}
    </VStack>
  );
}

export default BooleanFormField;
