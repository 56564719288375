import { FormItemItemType as FormItemType } from "@flows-platform/generated/graphql";
import {
  CalendarFieldIcon,
  CameraIcon,
  DescriptionTextIcon,
  FileUploadIcon,
  InitiatorIcon,
  LongTextIcon,
  NumberIcon,
  OptionIcon,
  RuleIcon,
  ShortTextIcon,
} from "@kwest_fe/core/src/theme/icons";

const FormItemTypes = {
  [FormItemType.DescriptionText]: {
    label: "Description text",
    icon: <DescriptionTextIcon />,
  },
  [FormItemType.ShortText]: {
    label: "Short text",
    icon: <ShortTextIcon />,
  },
  [FormItemType.LongText]: {
    label: "Long text",
    icon: <LongTextIcon />,
  },
  [FormItemType.Number]: {
    label: "Number",
    icon: <NumberIcon />,
  },
  [FormItemType.Option]: {
    label: "Option",
    icon: <OptionIcon />,
  },
  [FormItemType.Date]: {
    label: "Date",
    icon: <CalendarFieldIcon />,
  },
  [FormItemType.ImageUpload]: {
    label: "Image Upload",
    icon: <CameraIcon />,
  },
  [FormItemType.Boolean]: {
    label: "Yes/No",
    icon: <RuleIcon />,
  },
  [FormItemType.Membership]: {
    label: "Workspace Member",
    icon: <InitiatorIcon />,
  },
  [FormItemType.FileUpload]: {
    label: "File Upload",
    icon: <FileUploadIcon />,
  },
};

export default FormItemTypes;
