import { Box, Button } from "@chakra-ui/react";
import { useConnectedFlow } from "@flows-platform/context/ConnectedFlow";
import { FlowsIcon } from "@flows-platform/theme/icons";
import CloseButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/CloseButton";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

import ConnectedFlowName from "./components/ConnectedFlowName/ConnectedFlowName";
import FormikFlowSelector from "./components/FormikFlowSelector/FormikFlowSelector";

interface SelectFlowProps {
  onClose: () => void;
}

const SelectFlow = ({ onClose }: SelectFlowProps) => {
  const [, , helper] = useField("task.connectedFlow");
  const [, , { setValue: setConnectedFlowInitialVariableValues }] = useField(
    "task.connectedFlowInitialVariableValues"
  );
  const { connectedFlow } = useConnectedFlow();

  const { t } = useTranslation();

  return (
    <Box position="relative" gap={1} display="inline-flex">
      <Button size="sm" onClick={() => {}} borderRightRadius={0} fontWeight="medium" px={1} gap={1}>
        <FlowsIcon color="gray.600" />
        {t("models.task.connected_flow")}
      </Button>

      {connectedFlow ? (
        <Box display="inline-flex" alignItems={"center"} gap={1}>
          <ConnectedFlowName />
          <CloseButton
            onClick={() => {
              onClose();
              helper.setValue(null);
              setConnectedFlowInitialVariableValues({});
            }}
          />
        </Box>
      ) : (
        <FormikFlowSelector
          onClose={onClose}
          onChange={(newId) => {
            helper.setValue(newId);
            onClose();
          }}
        />
      )}
    </Box>
  );
};

export default SelectFlow;
