import { ReceiverTypesEnum } from "@core/modules/Core/constants/enums";
import { InitiatorIcon, MemberIcon, RoleIcon, TagIcon, TeamIcon } from "@core/theme/icons";
import type { StylesConfig } from "react-select";

export const FLOATING_SELECT_MARGIN = "-13px";
export const FLOATING_SELECT_MIN_WIDTH = "-13px";

export const FloatingSelectStyles: StylesConfig = {
  menu: (baseStyles) => ({
    ...baseStyles,
    width: "auto",
    minWidth: FLOATING_SELECT_MIN_WIDTH,
    right: FLOATING_SELECT_MARGIN,
    left: FLOATING_SELECT_MARGIN,
    boxShadow: "none",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    maxHeight: "120px",
    fontSize: "14px",
    borderLeft: "1px solid var(--chakra-colors-chakra-border-color)",
    borderBottom: "1px solid var(--chakra-colors-chakra-border-color)",
    borderRight: "1px solid var(--chakra-colors-chakra-border-color)",
  }),
};

export const ReceiverTypes = {
  [ReceiverTypesEnum.role]: {
    icon: <RoleIcon />,
    label: "Role",
  },
  [ReceiverTypesEnum.initiator]: {
    icon: <InitiatorIcon />,
    label: "User who triggered this instance",
  },
  [ReceiverTypesEnum.member]: {
    icon: <MemberIcon />,
    label: "Member",
  },
  [ReceiverTypesEnum.slack]: {
    icon: <TagIcon />,
    label: "Slack",
  },
  [ReceiverTypesEnum.team]: {
    icon: <TeamIcon />,
    label: "Team",
  },
};
