import { Box, VStack } from "@chakra-ui/react";
import type { FlowStepTypeEnum } from "@flows-platform/generated/graphql";
import { v4 as uuidv4 } from "uuid";

import { StackingContextEnum } from "../../../../../../utils/enums";
import { flowStepTypes } from "../FlowStep/constants/constants";
import FlowStep from "../FlowStep/FlowStep";

interface FlowEditorLibraryProps {
  accept: FlowStepTypeEnum[];
  excluded: string[];
}

function FlowEditorLibrary({ accept, excluded }: FlowEditorLibraryProps) {
  return (
    <Box
      top={0}
      right={0}
      bottom={0}
      bg="white"
      pos="absolute"
      w="container.flowEditorLibrary"
      p={5}
      zIndex={StackingContextEnum.Foreground}
      data-testid="flow-editor-library"
    >
      <VStack alignItems="flex-start" spacing={6}>
        {accept
          .filter((stepType) => !excluded.includes(stepType))
          .map((stepType) => (
            <FlowStep
              key={stepType}
              mode="pickable"
              step={{
                id: uuidv4(),
                stepType: flowStepTypes[stepType].pickableType,
              }}
            />
          ))}
      </VStack>
    </Box>
  );
}

export default FlowEditorLibrary;
