import {
  useAllWorkspacesQuery,
  useChangeWorkspaceClientMutation,
  useMyWorkspaceQuery,
} from "@flows-platform/generated/graphql";
import { useMyMembership } from "@flows-platform/modules/Core/providers/MyMembershipProvider";
import ErrorBoundary from "@kwest_fe/core/src/components/ErrorBoundary";
import Placeholder from "@kwest_fe/core/src/components/UI/molecules/display/Placeholder";

import SideNavigation from "./SideNavigation";

interface SideNavigationContainerProps {
  onChange: () => void;
}

export default function SideNavigationContainer({ onChange }: SideNavigationContainerProps) {
  const { myMembership } = useMyMembership();
  const myWorkspaceQuery = useMyWorkspaceQuery();
  const allWorkspacesQuery = useAllWorkspacesQuery();
  const [changeWorkspace] = useChangeWorkspaceClientMutation();

  if (myWorkspaceQuery.error)
    return <Placeholder type="empty-state" header="Error loading workspace " />;

  return (
    <ErrorBoundary>
      <SideNavigation
        onChange={onChange}
        isLoading={myWorkspaceQuery.loading}
        workspaceData={myWorkspaceQuery.data?.myWorkspace}
        allWorkspaces={allWorkspacesQuery.data?.allWorkspaces}
        changeWorkspace={changeWorkspace}
        myMembership={myMembership}
      />
    </ErrorBoundary>
  );
}
