import { Box, MenuItem } from "@chakra-ui/react";
import type { AllProjectTasksQuery } from "@flows-platform/generated/graphql";
import { JourneyStepStatus } from "@flows-platform/generated/graphql";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import type { Filter, SingleOption } from "../../types";
import GenericFilterDropdown from "../GenericFilterDropdown/GenericFilterDropdown";

interface FilterByTaskStatusProps {
  /**
   * Currently selected filter
   */
  selectedFilter: Filter | null;
  /**
   * AllProjectsTaskQuery results - List[FlowStepType]
   */
  allProjectTasks: AllProjectTasksQuery["allProjectTasks"];
  onClose: () => void;
  /**
   * Called once the status has been selected and also when a task is selected
   * @param taskId Flowstep ID of the selected task
   * @param status Status selected
   * @returns void
   */
  onSaveFilter: (taskId: string, status?: JourneyStepStatus) => void;
}

/**
 * Filter by Task Status
 * @param props FilterByTaskStatusProps
 * @returns JSX.Element
 */
export default function FilterByTaskStatus({
  allProjectTasks,
  selectedFilter,
  onClose,
  onSaveFilter,
}: FilterByTaskStatusProps) {
  const tasks =
    allProjectTasks?.map((task) => ({
      label: task?.name || "",
      value: task?.name || "",
    })) || [];

  const { t } = useTranslation();

  const selectedTask = useMemo(
    () => tasks.find((field) => field.value === selectedFilter?.value),
    [tasks, selectedFilter]
  );

  const applyFilter = useCallback(
    (status: JourneyStepStatus) => () => {
      if (!status) return;
      if (!selectedTask) return;
      onSaveFilter(selectedTask.value, status);
      onClose();
    },
    [onSaveFilter, onClose, selectedTask]
  );

  const onFieldChanged = useCallback((field: SingleOption) => {
    if (field) {
      onSaveFilter(field.value);
    }
  }, []);

  return (
    <>
      {!selectedTask && (
        <Box w="full" px={3}>
          <GenericFilterDropdown
            placeholder={t("pages.projects.placeholders.task")}
            options={tasks}
            onChange={onFieldChanged}
            onBlur={onClose}
          />
        </Box>
      )}
      {selectedTask && (
        <Box pb={3}>
          <MenuItem
            onClick={applyFilter(JourneyStepStatus.AwaitUser)}
            fontSize="md"
            _hover={{ backgroundColor: "blue.50" }}
          >
            Open
          </MenuItem>
          <MenuItem
            onClick={applyFilter(JourneyStepStatus.Completed)}
            fontSize="md"
            _hover={{ backgroundColor: "blue.50" }}
          >
            Done
          </MenuItem>
        </Box>
      )}
    </>
  );
}
