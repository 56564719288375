import type { VariableType } from "@flows-platform/types";
import * as Yup from "yup";

import { triggerVariablesValidationSchema } from "../../../../../FlowTemplates/components/FlowAssignmentModal/schema";

const ProjectAssignmentFormSchema = (triggerVariables: Record<string, VariableType>) =>
  Yup.object().shape({
    journeyStartDate: Yup.string().required(
      "modals.flow_assignment.form.journey_start_date.validation_message"
    ),
    useCustomInstanceNames: Yup.boolean(),
    instanceName: Yup.string()
      .nullable()
      .when("useCustomInstanceNames", {
        is: true,
        then: Yup.string()
          .nullable()
          .required("modals.flow_assignment.form.instance_name.validation_message"),
      }),
    variables: Yup.object().shape(triggerVariablesValidationSchema(triggerVariables)),
  });

export default ProjectAssignmentFormSchema;
