import { Box, Checkbox, HStack, useDisclosure } from "@chakra-ui/react";
import type {
  JourneyStepFieldsWithProjectTasksSummaryFragment,
  ProjectStepFieldsFragment,
} from "@flows-platform/generated/graphql";
import {
  useReassignJourneyStepClientMutation,
  useToggleTaskStatusClientMutation,
} from "@flows-platform/generated/graphql";
import { useProjectSteps } from "@flows-platform/modules/Shared/providers/ProjectStepContext";
import FlowConnectedBadge from "@flows-platform/modules/Shared/UI/atoms/Decorations/Shortcut/FlowConnectedBadge";
import DateView from "@flows-platform/modules/Shared/UI/atoms/Text/DateView";
import TruncatedText from "@flows-platform/modules/Shared/UI/atoms/Text/TruncatedText/TruncatedText";
import { ChatBubbleIcon, CustomTaskIcon } from "@flows-platform/theme/icons";
import { TEXT_STYLES } from "@flows-platform/theme/text";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { captureException } from "@sentry/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { useChatContext } from "stream-chat-react";

import AddCustomTaskModal from "./components/AddCustomTaskModal";
import ProjectStepModal from "./components/ProjectStepModal";
import ProjectStepPhaseIndicator from "./components/ProjectStepPhaseIndicator";
import ProjectTaskAssigneeSelector from "./components/ProjectTaskAssigneeSelector";
import SubtaskProgress from "./components/SubtaskProgress";

interface ProjectStepCheckboxProps {
  step: JourneyStepFieldsWithProjectTasksSummaryFragment | ProjectStepFieldsFragment;
  isChecked: boolean;
  isFirst: boolean;
  isLast: boolean;
  dense?: boolean;
  hideControls?: boolean;
}

const isProjectStep = (
  step: JourneyStepFieldsWithProjectTasksSummaryFragment | ProjectStepFieldsFragment
): step is ProjectStepFieldsFragment => {
  return step.__typename === "ProjectStepType";
};

const TASK_NAME_WIDTH = 600;
export default function ProjectStepCheckbox({
  step,
  isChecked,
  isLast,
  isFirst,
  dense = false,
  hideControls = false,
}: ProjectStepCheckboxProps) {
  const [toggleTaskStatus] = useToggleTaskStatusClientMutation();
  const { onUpdate } = useProjectSteps();
  const { projectId } = useParams<{ projectId: string }>();

  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCheckedLocalState, setIsCheckedLocalState] = useState(isChecked);
  const { errorToast } = useDefaultToasts();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const [reassignJourneyStep] = useReassignJourneyStepClientMutation({
    onError(e) {
      errorToast(t("pages.project_instance.toast.update_task_assignee.error.title"))({
        message: e.message,
      });
      onUpdate();
    },
  });

  const defaultBorderWidth = isLast ? 0 : 2;
  const borderWidth = dense ? defaultBorderWidth : 1;

  const subTasks = isProjectStep(step) ? step.orderedSubtasks : step?.task?.orderedSubtasks ?? [];

  const openTaskModal = () => {
    if (!hideControls) {
      onOpen();
      window.history.pushState(null, document.title, `?task=${step.id}`);
    }
  };

  const closeTaskModal = () => {
    onClose();
    window.history.pushState(null, document.title, window.location.pathname);
  };

  useEffect(() => {
    const taskId = searchParams.get("task");
    if (taskId === step.id && !isOpen) {
      onOpen();
    }
  }, [searchParams]);

  // Always keep the local state in sync with the server
  useEffect(() => {
    setIsCheckedLocalState(isChecked);
  }, [isChecked]);

  const handleOnToggleTaskStatus = useCallback(async () => {
    setLoading(true);
    try {
      const { errors } = await toggleTaskStatus({ variables: { stepId: step.id } });
      if (errors) {
        throw Error("Error while updating task status");
      }
      setIsCheckedLocalState(!isCheckedLocalState);
      // await onUpdate(); We are avoiding fetching for now because its to slow
    } catch (e) {
      errorToast("An error occurred while updating the task status");
      captureException(e);
      onUpdate(); // Incase there is an error, we want to refetch the data
    } finally {
      setLoading(false);
    }
  }, [step.id, onUpdate, toggleTaskStatus, loading, isCheckedLocalState]);

  const { client } = useChatContext();

  const channel = useMemo(() => {
    // Shorten the UUIDs to make the channel name shorter, the max is 64 characters.

    if (!client) return null;
    if (client.user?.id) {
      const streamChannel = client.channel("messaging", step.id);
      return streamChannel;
    }

    return null;
  }, [client, projectId, step.id]);

  const onTaskAssigneeChanged = (
    receiver:
      | {
          id: string;
          firstName: string;
          lastName: string;
          _typename: "MembershipType";
        }
      | {
          id: string;
          name: string;
          _typename: "TeamType";
        }
  ) => {
    const updatePayload =
      receiver._typename === "MembershipType"
        ? { receiverId: receiver.id }
        : { receiverTeamId: receiver.id };

    reassignJourneyStep({
      variables: {
        input: {
          id: step.id,
          ...updatePayload,
        },
      },
    });
  };
  const taskAssignee = (() => {
    if (step.task?.receiver)
      return {
        id: step.task?.receiver?.id,
        firstName: step.task?.receiver?.firstName,
        lastName: step.task?.receiver?.lastName,
        _typename: "MembershipType" as const,
      };
    if (step.task?.receiverTeam) {
      return {
        id: step.task?.receiverTeam?.id,
        name: step.task?.receiverTeam?.name,
        _typename: "TeamType" as const,
      };
    }
    return undefined;
  })();

  return (
    <>
      {step.phase && <ProjectStepPhaseIndicator phase={step.phase} />}
      <Box position="relative" overflow="visible" data-testid="project-task-row">
        {!hideControls && isFirst && (
          <AddCustomTaskModal beforeStepId={step.id} key={step.id} controlPosition="top" />
        )}
        {!hideControls && <AddCustomTaskModal afterStepId={step.id} key={step.id} />}
        <HStack
          borderBottomWidth={borderWidth}
          borderColor="gray.100"
          transition="all .3s"
          cursor={!dense ? "pointer" : undefined}
          _hover={{
            background: dense ? "gray.100" : "gray.50",
          }}
          px={3}
          py={2}
          bg={dense ? "gray.50" : undefined}
          spacing={4}
        >
          <Checkbox
            background="white"
            isChecked={isCheckedLocalState}
            disabled={dense || loading}
            onChange={handleOnToggleTaskStatus}
          />
          <HStack onClick={openTaskModal} spacing={4} flexGrow={1}>
            <TruncatedText maxWidth={TASK_NAME_WIDTH} textStyle={TEXT_STYLES.checkbox}>
              {step.name}
            </TruncatedText>
            <HStack spacing={2}>
              {step.task?.isCustom && <CustomTaskIcon />}
              {subTasks && subTasks.length > 0 && <SubtaskProgress subTasks={subTasks} />}
              {step.task?.connectedFlowId && <FlowConnectedBadge compact />}
            </HStack>
          </HStack>
          {channel?.state.messages.length && (
            <ChatBubbleIcon color={channel.state.unreadCount > 0 ? "primary" : "gray.600"} />
          )}
          {step.task?.dueDate && (
            <DateView date={new Date(step.task.dueDate)} onClick={openTaskModal} />
          )}
          <ProjectTaskAssigneeSelector
            iconOnly
            assignee={taskAssignee}
            onChange={onTaskAssigneeChanged}
          />
          {!hideControls && isProjectStep(step) && (
            <ProjectStepModal
              step={step}
              isChecked={isCheckedLocalState}
              isOpen={isOpen}
              onClose={closeTaskModal}
              onToggle={handleOnToggleTaskStatus}
            />
          )}
        </HStack>
      </Box>
    </>
  );
}
