import { Flex } from "@chakra-ui/react";
import type { ReactElement } from "react";
import { FaSlack as SlackIcon } from "react-icons/fa";

import { FlowStepTypeEnum } from "../../generated/graphql";
import type { IconProps } from "./icon";
import { Icon } from "./icon";

type LibraryIconsType = {
  [key in FlowStepTypeEnum]: ReactElement;
};

function FormIcon() {
  return <Icon name="edit_square" size="sm" />;
}

function HttpRequestIcon() {
  return <Icon name="http" size="sm" />;
}

function TaskIcon() {
  return <Icon name="center_focus_strong" size="sm" />;
}

function DocumentIcon() {
  return <Icon name="description" size="sm" />;
}

function ApprovalIcon() {
  return <Icon name="rule" size="sm" />;
}

export function ListIcon() {
  return <Icon name="list" size="sm" />;
}

function EmailIcon() {
  return <Icon name="mail" size="sm" />;
}

function TriggerIcon() {
  return <Icon name="play_arrow" />;
}

export const LibraryIcons: LibraryIconsType = {
  [FlowStepTypeEnum.Email]: <EmailIcon />,
  [FlowStepTypeEnum.List]: <ListIcon />,
  [FlowStepTypeEnum.Trigger]: <TriggerIcon />,
  [FlowStepTypeEnum.Slack]: <SlackIcon />,
  [FlowStepTypeEnum.Form]: <FormIcon />,
  [FlowStepTypeEnum.HttpRequest]: <HttpRequestIcon />,
  [FlowStepTypeEnum.Task]: <TaskIcon />,
  [FlowStepTypeEnum.Document]: <DocumentIcon />,
  [FlowStepTypeEnum.Approval]: <ApprovalIcon />,
};

interface LibraryIconProps extends IconProps {
  type: FlowStepTypeEnum;
}

export function LibraryIcon({
  type,
  background = "purple.200",
  color = "gray.700",
  ...rest
}: LibraryIconProps) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      backgroundColor={background}
      p={1}
      borderRadius="md"
      w="32px"
      h="32px"
      fontSize="lg"
      color={color}
      {...rest}
    >
      {LibraryIcons[type]}
    </Flex>
  );
}
