import type {
  AllMembershipsQuery,
  AllProjectTasksQuery,
  AllSearchableFieldsQuery,
  MyWorkspaceTagsClientQuery,
} from "@flows-platform/generated/graphql";
import type { ProgressiveRevealMenuItems } from "@kwest_fe/core/src/components/ProgressiveRevealMenu/ProgressiveRevealMenu";
import ProgressiveRevealMenu from "@kwest_fe/core/src/components/ProgressiveRevealMenu/ProgressiveRevealMenu";
import {
  ContentSearchIcon,
  ProjectLeadIcon,
  TagIcon,
  TaskIcon,
  TextFieldsIcon,
} from "@kwest_fe/core/src/theme/icons";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { FilterOptions } from "../../../../constants/constants";
import FilterByContent from "./components/FilterByContent/FilterByContent";
import FilterByField from "./components/FilterByField/FilterByField";
import FilterByProjectLead from "./components/FilterByProjectLead/FilterByProjectLead";
import FilterByTag from "./components/FilterByTag/FilterByTag";
import FilterByTaskStatus from "./components/FilterByTaskStatus/FilterByTaskStatus";
import ProjectFilterMenuButton from "./components/ProjectsFilterMenuButton/ProjectsFilterMenuButton";
import ProjectsFilterMenuHeader from "./components/ProjectsFilterMenuHeader/ProjectsFilterMenuHeader";
import type { Filter } from "./types";

interface ProjectsFilterProps {
  selectedFilter: Filter | null;
  onFilterUpdate: (filter: Filter | null) => void;
  allMemberships: AllMembershipsQuery["allMemberships"];
  allSearchableFields: AllSearchableFieldsQuery["allSearchableFields"];
  allProjectTasks: AllProjectTasksQuery["allProjectTasks"];
  allProjectTags: NonNullable<MyWorkspaceTagsClientQuery["myWorkspace"]>["journeyTags"];
  filterIndex: number;
}

function ProjectsFilter({
  selectedFilter,
  onFilterUpdate,
  allMemberships,
  allSearchableFields,
  allProjectTasks,
  allProjectTags,
  filterIndex,
}: ProjectsFilterProps) {
  const { t } = useTranslation();
  /**
   * When filtering by project lead, get the name of the selected project lead
   */
  const getMembershipName = useCallback(
    (id: string) => {
      const membership = allMemberships?.find((m) => m?.id === id);
      if (!membership) return "";
      return `${membership.firstName} ${membership.lastName}`;
    },
    [allMemberships]
  );

  /**
   * When filtering by field name, get the name of the selected field
   */
  const getFieldName = useCallback(() => {
    const identifier = selectedFilter?.parameters?.fieldName;
    const found = allSearchableFields?.find((field) => field?.identifier === identifier);
    if (!found) return "";
    return `${found.label}`;
  }, [selectedFilter, allSearchableFields]);

  /**
   * When filtering by task status, get the name of the selected task
   */
  const getTaskName = useCallback(
    (name: string) => {
      const selectedTask = allProjectTasks?.find((task) => task?.name === name);
      if (!selectedTask) return "";
      return selectedTask.name;
    },
    [allProjectTasks]
  );
  /**
   * When filtering by tag, get the name of the tag
   */
  const getTagName = useCallback(
    (tagId: string) => allProjectTags?.find((tag) => tag?.id === tagId)?.name || "",
    [allProjectTasks]
  );

  const menuItems: ProgressiveRevealMenuItems<FilterOptions> = {
    [FilterOptions.TASK]: {
      label: t("pages.projects.filters.task"),
      icon: <TaskIcon />,
      component: ({ onClose }) => (
        <FilterByTaskStatus
          allProjectTasks={allProjectTasks}
          onClose={onClose}
          onSaveFilter={(flowStepName, status) => {
            onFilterUpdate({
              type: FilterOptions.TASK,
              value: flowStepName,
              and: {
                type: FilterOptions.TASK_STATUS,
                value: status,
              },
            });
          }}
          selectedFilter={selectedFilter}
        />
      ),
    },
    [FilterOptions.CONTENT]: {
      label: t("pages.projects.filters.content"),
      caption: "(string)",
      icon: <ContentSearchIcon />,
      component: ({ onClose }) => (
        <FilterByContent
          onClose={onClose}
          onSaveFilter={(content) => {
            onFilterUpdate({
              type: FilterOptions.CONTENT,
              value: content,
              or: {
                type: FilterOptions.CONTENT_IN_CONTEXT_SECTION,
                value: content,
              },
            });
            onClose();
          }}
          contentValue={selectedFilter?.value || ""}
        />
      ),
    },
    [FilterOptions.FIELD_VALUE_IN_VARIABLES]: {
      label: t("pages.projects.filters.field"),
      icon: <TextFieldsIcon />,
      component: ({ onClose }) => (
        <FilterByField
          allSearchableFields={allSearchableFields}
          onClose={onClose}
          onSaveFilter={(field, content) => {
            onFilterUpdate({
              type: FilterOptions.FIELD_VALUE_IN_VARIABLES,
              parameters: { fieldName: field },
              value: content,
              or: {
                type: FilterOptions.FIELD_VALUE_IN_CONTEXT_SECTION,
                parameters: { fieldName: field },
                value: content,
              },
            });
          }}
          selectedFilter={selectedFilter}
        />
      ),
    },
    [FilterOptions.PROJECT_LEAD]: {
      label: t("pages.projects.filters.projectLead"),
      icon: <ProjectLeadIcon />,
      component: ({ onClose }) => (
        <FilterByProjectLead
          onClose={onClose}
          onSaveFilter={(projectLead) => {
            onFilterUpdate({
              type: FilterOptions.PROJECT_LEAD,
              value: projectLead?.id,
            });
            onClose();
          }}
        />
      ),
    },
    [FilterOptions.TAGS]: {
      label: t("pages.projects.filters.tag"),
      icon: <TagIcon />,
      component: ({ onClose }) => (
        <FilterByTag
          onClose={onClose}
          allTags={allProjectTags}
          onSaveFilter={(selectedTag) => {
            onFilterUpdate({
              type: FilterOptions.TAGS,
              value: selectedTag,
            });
            onClose();
          }}
        />
      ),
    },
  };

  return (
    <ProgressiveRevealMenu
      menuHeader={({ filterDisplayed }) => (
        <ProjectsFilterMenuHeader
          filterDisplayed={filterDisplayed as FilterOptions}
          selectedFilter={selectedFilter}
          getFieldName={getFieldName}
        />
      )}
      menuItems={menuItems}
      selected={selectedFilter?.type}
      onFilterCleared={() => {
        onFilterUpdate(null);
      }}
    >
      <ProjectFilterMenuButton
        selectedFilter={selectedFilter}
        getMembershipName={getMembershipName}
        getFieldName={getFieldName}
        getTaskName={getTaskName}
        getTagName={getTagName}
        showCompact={filterIndex > 0}
      />
    </ProgressiveRevealMenu>
  );
}

export default memo(ProjectsFilter);
