import axios from "axios";
import { useNavigate } from "react-router-dom";

import type { FormFilesServiceOptions } from "../../../services/formFiles";
import FormFilesService from "../../../services/formFiles";

function useFileUploadService({
  uploadUrl,
  onFileUploadProgress = () => {},
  onFileUploadedSuccess,
  onFileUploadError,
  onFileDeletedSuccess,
  onFileDeletedError,
  accessToken,
}: FormFilesServiceOptions) {
  const navigate = useNavigate();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        navigate("/logout");
      }
    }
  );

  const { uploadFile, downloadFile, deleteFile } = FormFilesService({
    accessToken,
    uploadUrl,
    onFileUploadedSuccess,
    onFileUploadProgress,
    onFileUploadError,
    onFileDeletedSuccess,
    onFileDeletedError,
  });

  return {
    uploadFile,
    deleteFile,
    downloadFile,
  };
}

export default useFileUploadService;
