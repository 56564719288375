import { Flex, Text, Tooltip, VStack } from "@chakra-ui/react";
import type { FlowStepFieldsFragment } from "@flows-platform/generated/graphql";
import { useFlowEditorDrawer } from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/providers/FlowEditorDrawerProvider";
import { InformationIcon } from "@flows-platform/theme/icons";
import { Formik, useField } from "formik";
import { useTranslation } from "react-i18next";

import RulesConfigForm from "./components/RulesConfigForm";
import type { RulesConfigFormValues } from "./components/RulesConfigForm/RulesConfigForm";
import RulesConfigSectionSchema from "./schema";
import { formatConditionalVariableValue, parseConditionalStatement } from "./utils";

export type SingleRule = NonNullable<FlowStepFieldsFragment["rulesChain"]>[number];

export default function RulesConfigSection() {
  const { t } = useTranslation();
  const { ruleVariableOptions = {} } = useFlowEditorDrawer() ?? {};
  const [{ value: rulesChain }, , { setValue }] =
    useField<FlowStepFieldsFragment["rulesChain"]>("rulesChain");

  const hasInitialValue = !!rulesChain && rulesChain?.length > 0;

  const onRuleChanged = (updatedRules: RulesConfigFormValues) => {
    setValue(
      updatedRules.rules.map((rule) => {
        const { action, actionValue } = rule;
        const condition = rule.conditions
          .map(({ conditional, variable, operator, variableValue }) =>
            conditional === "if"
              ? `{{${variable}}} ${operator} ${formatConditionalVariableValue(variableValue)}`
              : "true"
          )
          .join(" && ");
        return {
          action,
          condition,
          evaluateNext: false,
          actionConfig: {
            step: actionValue,
            newValue: "",
            variable: "",
          },
        };
      })
    );
  };

  return (
    <VStack p={8} align="start" data-testid="rules-config-section">
      <Flex alignItems={"center"} gap={2}>
        <Text fontWeight="bold" fontSize={"medium"}>
          {t("pages.flows_templates.drawer.rules_setup.title")}
        </Text>
        <Tooltip hasArrow label={t("pages.flows_templates.drawer.rules_setup.tooltip")}>
          <span>
            <InformationIcon color="purple.500" />
          </span>
        </Tooltip>
      </Flex>
      <Formik
        initialValues={{
          rules: rulesChain?.map((rule) => ({
            conditions: rule?.condition.split("&&").map(parseConditionalStatement),
            action: rule?.action,
            actionValue: rule?.actionConfig.step,
          })),
        }}
        onSubmit={() => {}}
        validationSchema={RulesConfigSectionSchema(Object.keys(ruleVariableOptions))}
        validateOnBlur
        validateOnChange
      >
        <RulesConfigForm onRuleChanged={onRuleChanged} hasInitialValue={hasInitialValue} />
      </Formik>
    </VStack>
  );
}
