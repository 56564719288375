import { useDeleteFlowClientMutation } from "@flows-platform/generated/graphql";
import ALL_FLOWS from "@flows-platform/graphql/queries/flows/allFlows";
import GenericDeleteModal from "@kwest_fe/core/src/components/UI/organisms/modals/GenericDeleteModal";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

interface DeleteFlowModalProps {
  activeFlow: string | null;
  isOpen: boolean;
  onClose: () => void;
}

const DeleteFlowModal = ({ activeFlow, isOpen, onClose }: DeleteFlowModalProps) => {
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [deleteFlow] = useDeleteFlowClientMutation({
    refetchQueries: [ALL_FLOWS, "AllFlows"],
    onCompleted: () => {
      onClose();
      successToast(t("pages.flows_templates.toast.flow_delete.success.title"));
    },
    onError: (error) => {
      errorToast(t("pages.flows_templates.toast.flow_delete.error.title"))(error);
    },
  });

  const handleDeleteFlow = () => {
    deleteFlow({ variables: { flowId: activeFlow || "" } });
  };
  return (
    <GenericDeleteModal
      header="flow"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleDeleteFlow}
    />
  );
};

export default DeleteFlowModal;
