import { Box, Input } from "@chakra-ui/react";
import type { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";

import type { FileUploaderCaptionProps } from "../../constants/types";
import ProgressIndicator from "../ProgressIndicator/ProgressIndicator";

interface DefaultUploadedViewProps {
  isDragActive: boolean;
  getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps;
  uploadProgress: number | null;
  loadingText: string | undefined;
  imageIsProcessing: boolean;
  renderInactive?: ({ isDragActive }: FileUploaderCaptionProps) => JSX.Element;
}

export function DefaultUploadedView({
  isDragActive,
  getRootProps,
  getInputProps,
  uploadProgress,
  loadingText,
  imageIsProcessing,
  renderInactive,
}: DefaultUploadedViewProps) {
  const uploaderInactive = !(imageIsProcessing || uploadProgress);
  return (
    <Box
      borderColor={isDragActive ? "purple.600" : "chakra-border-color"}
      borderStyle={"dashed"}
      borderWidth={1}
      borderRadius={5}
      bgColor="#fcfcfc"
      cursor="pointer"
      padding={7}
      width="full"
      {...getRootProps()}
    >
      <Input {...getInputProps()} size={undefined} />
      {uploadProgress !== null && (
        <ProgressIndicator
          progress={uploadProgress}
          showProgressIndicator
          loadingText={loadingText}
        />
      )}
      {imageIsProcessing && <ProgressIndicator progress={100} loadingText="Preparing image" />}
      {uploaderInactive && renderInactive?.({ isDragActive })}
    </Box>
  );
}
