import { FlowStepVariableTypeEnum } from "@core/enums";
import type { TriggerVariableFormFieldInitialValue } from "@core/types/TriggerVariableFormFieldInitialValue";

export const isObjectValue = (
  variableValue: TriggerVariableFormFieldInitialValue
): variableValue is Record<string, string> =>
  !!variableValue && typeof variableValue === "object" && !Array.isArray(variableValue);

export default function parseFormInitialValue(
  key: string,
  allVariableValues: Record<string, TriggerVariableFormFieldInitialValue>,
  type?: keyof typeof FlowStepVariableTypeEnum
) {
  const variableValue = allVariableValues[key];
  if (typeof variableValue === "string") {
    if (/true|false/.test(variableValue)) {
      return Boolean(variableValue);
    }
    if (/^\d+$/.test(variableValue) && type === FlowStepVariableTypeEnum.NUMBER) {
      return parseInt(variableValue, 10);
    }
  }
  if (type === FlowStepVariableTypeEnum.ROLE) {
    // Role is split into two values. One for the teamId if provided and another for the membershipId
    if (isObjectValue(variableValue)) {
      return {
        id: variableValue.team_id ?? variableValue.membership_id,
      };
    }

    const membershipIdKey = `${key}-team-member`;
    if (
      key !== membershipIdKey &&
      membershipIdKey in allVariableValues &&
      allVariableValues[membershipIdKey]
    ) {
      const membershipId = allVariableValues[membershipIdKey];
      const membershipIdString = membershipId ? String(membershipId) : "";
      return {
        id: variableValue ?? membershipIdString ?? "",
      };
    }

    const variableValueString = variableValue ? String(variableValue) : "";
    return {
      id: variableValueString,
    };
  }
  return variableValue;
}

export function reverseParseFormInitialValue(variableValue: TriggerVariableFormFieldInitialValue) {
  if (isObjectValue(variableValue)) {
    if (variableValue?.value) {
      if (isObjectValue(variableValue.value)) {
        if (variableValue.value._typename === "MembershipType") {
          return variableValue.value.id;
        }
        return {
          team_id: variableValue.value.id,
        };
      }
      return variableValue.value;
    }
    return null;
  }
  return variableValue ?? null;
}
