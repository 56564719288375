import CompositeLoader from "@kwest_fe/core/src/components/CompositeLoader";
import { useParams } from "react-router-dom";

import { useGetProjectStepsQuery, useOneJourneyQuery } from "../../generated/graphql";
import ProjectInstancePage from "./ProjectInstancePage";

export default function ProjectInstanceContainer() {
  const { projectId } = useParams();
  const {
    data: projectData,
    loading: projectLoading,
    error: projectError,
  } = useOneJourneyQuery({
    variables: {
      journeyId: projectId || "",
    },
  });

  const {
    data: projectStepsData,
    loading: projectStepsLoading,
    error: projectStepsError,
    refetch: refetchProjectSteps,
  } = useGetProjectStepsQuery({
    variables: {
      journeyId: projectId || "",
    },
    fetchPolicy: "network-only",
  });

  const project = projectData?.oneJourney;
  const projectSteps = projectStepsData?.getProjectSteps || [];

  return (
    <CompositeLoader
      loading={projectLoading || projectStepsLoading}
      error={projectStepsError || projectError}
    >
      <ProjectInstancePage
        project={project}
        projectSteps={projectSteps}
        onUpdate={async () => {
          await refetchProjectSteps();
        }}
      />
    </CompositeLoader>
  );
}
