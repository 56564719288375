import { Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const DoButton = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation();

  return (
    <Button colorScheme="purple" height={"40px"} onClick={onClick}>
      {t("global.actions.do")}
    </Button>
  );
};

export default DoButton;
