import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { DeleteIcon, DuplicateIcon, EditIcon, KebabIcon } from "@core/theme/icons";
import type { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface ContextMenuProps extends PropsWithChildren {
  onEdit?: () => void;
  onRemove?: () => void;
  onDuplicate?: () => void;
}

const ContextMenu = ({ children, onEdit, onRemove, onDuplicate }: ContextMenuProps) => {
  const { t } = useTranslation();
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghostDark"
        size={"xs"}
        h={"32px"}
        px={0}
        data-testid="context-menu-button"
      >
        <KebabIcon />
      </MenuButton>
      <MenuList>
        {onDuplicate && (
          <MenuItem
            icon={<DuplicateIcon />}
            onClick={onDuplicate}
            fontSize={"14px"}
            data-testid="context-menu-item-duplicate"
          >
            {t("global.actions.duplicate")}
          </MenuItem>
        )}
        {onEdit && (
          <MenuItem
            icon={<EditIcon />}
            onClick={onEdit}
            fontSize={"14px"}
            data-testid="context-menu-item-edit"
          >
            {t("global.actions.edit")}
          </MenuItem>
        )}
        {onRemove && (
          <MenuItem
            icon={<DeleteIcon />}
            onClick={onRemove}
            color={"red.500"}
            fontSize={"14px"}
            data-testid="context-menu-item-delete"
          >
            {t("global.actions.delete")}
          </MenuItem>
        )}
        {children}
      </MenuList>
    </Menu>
  );
};

export default ContextMenu;
