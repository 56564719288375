import { Box, Container, VStack } from "@chakra-ui/react";

import { StackingContextEnum } from "../../../../../utils/enums";
import LayoutProvider from "../LayoutProvider";
import MobileSidebar from "./components/SideNavigationContainer/MobileSidebar";
import SideNavigationContainer from "./components/SideNavigationContainer/SideNavigationContainer";

interface MainLayoutProps {
  children?: React.ReactNode;
  isFullWidth?: boolean;
}

const sidebarWidth = 250;

export default function MainLayout({ children, isFullWidth = true }: MainLayoutProps) {
  return (
    <LayoutProvider>
      <VStack
        w={sidebarWidth}
        h="full"
        p={0}
        pb={8}
        spacing="spacing.xl"
        alignItems="flex-center"
        bg="white"
        borderRight="1px"
        borderColor="primary.border"
        position="fixed"
        display={{ base: "none", lg: "flex" }}
        zIndex={StackingContextEnum.Foreground + 1}
      >
        <SideNavigationContainer />
      </VStack>

      <MobileSidebar />

      <Box
        ml={{ base: "none", lg: sidebarWidth }}
        overflow="auto"
        bg="primary.background"
        id="page-scroll-container"
      >
        <Container
          maxW="full"
          w={isFullWidth ? "full" : "container.centerStage"}
          centerContent
          m="auto"
          p={0}
        >
          <VStack
            w={isFullWidth ? "full" : "container.centerStage"}
            h="max-content"
            alignItems="flex-start"
            m="auto"
          >
            {children}
          </VStack>
        </Container>
      </Box>
    </LayoutProvider>
  );
}
