export function parseConditionalStatement(condition: string) {
  const conditionalStatementRegex = /^\{\{(\w+)\}\} ([=<>!]+) (.+)$/;
  const [, variable, operator, variableValue] = conditionalStatementRegex.exec(condition) || [];
  return {
    conditional: condition === "true" ? "always" : "if",
    variable,
    operator,
    variableValue: variableValue && JSON.parse(variableValue),
  };
}

export function formatConditionalVariableValue(variableValue: string) {
  const variableValueRepr = /^(\d+|true|false)$/.test(variableValue)
    ? variableValue
    : `"${variableValue}"`;
  return variableValueRepr;
}
