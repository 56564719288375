/* eslint-disable @typescript-eslint/no-invalid-this */
import makeIdentifier from "@flows-platform/utils/makeIdentifier";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import * as Yup from "yup";

const VariableValidationSchema = Yup.object().shape({
  label: Yup.string().required("Label is a required field"),
  type: Yup.string()
    .required("Type is a required field")
    .oneOf(Object.values(FlowStepVariableTypeEnum)),
});

type VariableSchemaType = Yup.InferType<typeof VariableValidationSchema>;

const FlowTriggerDrawerSchema = Yup.object().shape({
  variables: Yup.array().of(
    VariableValidationSchema.test(
      "unique",
      "Variables must have unique identifiers",
      function test(variable: VariableSchemaType) {
        if (!variable?.label) {
          return true;
        }
        if (
          this.parent
            .filter((v: VariableSchemaType) => v !== variable)
            .some(
              (v: VariableSchemaType) =>
                !!v?.label && makeIdentifier(v.label) === makeIdentifier(variable.label ?? "")
            )
        ) {
          throw this.createError({
            path: `${this.path}.label`,
          });
        }

        return true;
      }
    )
  ),
});

export default FlowTriggerDrawerSchema;
