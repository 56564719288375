import { MenuItem } from "@chakra-ui/react";
import Dropdown from "@kwest_fe/core/src/components/Dropdown";
import { useField } from "formik";

interface DropdownItem {
  label: string;
  value: string;
}

interface DropdownProps {
  name: string;
  label: string;
  menuItems: DropdownItem[];
}

export default function FormikDropdown({ name, label, menuItems }: DropdownProps) {
  const [field, , helpers] = useField<string>(name);

  return (
    <Dropdown
      label={label}
      value={menuItems.find((item) => item.value === field.value)?.label || "Select"}
      menuItems={menuItems.map((item) => {
        return (
          <MenuItem
            key={item.value}
            onClick={() => {
              helpers.setValue(item.value);
            }}
          >
            {item.label}
          </MenuItem>
        );
      })}
    />
  );
}
