import { Text, VStack } from "@chakra-ui/react";
import type { AxiosError } from "axios";
import { memo, useState } from "react";
import type { Accept, DropzoneInputProps, DropzoneRootProps } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import useDefaultToasts from "../../modules/Shared/hooks/useDefaultToasts";
import useFileUploader from "../../modules/Shared/hooks/useFileUploader";
import { parseFileName } from "../../utils";
import EncodedImagesViewer from "../EncodedImagesViewer/EncodedImagesViewer";
import { DefaultUploadedView } from "./components/DefaultUploadedView/DefaultUploadedView";
import FileUploaderMultiFileControls from "./components/FileUploaderMultiFileControls/FileUploaderMultiFileControls";
import ImageUploadCaption from "./components/ImageUploadCaption";
import type {
  FileUploaderCaptionProps,
  FileUploaderFileUrl,
  FileUploaderObjectRendererProps,
} from "./constants/types";
import downloadFileFromResponse from "./utils/downloadFile";

const DEFAULT_MAX_UPLOAD_SIZE = 1;
const ALLOWED_IMAGE_FILE_EXTENSIONS = [".png", ".jpeg", ".gif", ".jpg", ".heic", ".heif"];

interface FileUploaderProps {
  accessToken: Promise<string> | string;
  fileUrls: FileUploaderFileUrl[];
  uploadUrl?: string;
  deleteUrl?: (index: number) => FileUploaderFileUrl;
  downloadUrl?: (index: number) => string | undefined;
  onFileUploaded?: (files: File[], data?: any) => void;
  onFileDeleted?: (fileUrl: FileUploaderFileUrl) => void;
  onFileUploadError?: (err: AxiosError, failedFiles: File[]) => void;
  onFileDeletedError?: (err: AxiosError, deleteFileUrl: string, fileUrl: string) => void;
  /* Object representing the accepted file extensions. See https://react-dropzone.js.org/#section-accepting-specific-file-types */
  acceptedFileTypes?: Accept;
  maxFileSize?: number;
  /* Caption text displayed underneath the uploader component */
  captionText?: string;
  /* Component which renders when the uploader is empty and inactive  */
  renderInactive?: ({ isDragActive }: FileUploaderCaptionProps) => JSX.Element;
  /* Component which renders the objects already uploaded  */
  renderUploaded?: ({
    fileUrls,
    onDeleteFile,
    onDownloadFile,
    onDownloadAllFiles,
  }: FileUploaderObjectRendererProps) => JSX.Element;
  renderUpload?: (
    getRootProps: (props?: DropzoneRootProps) => DropzoneRootProps,
    getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps,
    uploadProgress: number | null,
    imageIsProcessing: boolean
  ) => JSX.Element;
  loadingText?: string;
  disabled?: boolean;
  disableMultiple?: boolean;
}

function FileUploader({
  fileUrls,
  uploadUrl,
  deleteUrl = () => uploadUrl ?? "",
  downloadUrl = () => uploadUrl,
  onFileUploaded,
  onFileDeleted,
  onFileUploadError,
  onFileDeletedError,
  acceptedFileTypes = {
    "image/*": ALLOWED_IMAGE_FILE_EXTENSIONS,
  },
  maxFileSize = DEFAULT_MAX_UPLOAD_SIZE,
  captionText,
  renderInactive = ImageUploadCaption,
  renderUploaded = ({ fileUrls: images, onDeleteFile }) => (
    <EncodedImagesViewer images={images} onDeleteFile={onDeleteFile} boxWidth="full" />
  ),
  renderUpload,
  loadingText,
  disabled,
  disableMultiple = false,
  accessToken,
}: FileUploaderProps) {
  const { t } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const { errorToast, successToast } = useDefaultToasts();

  const { doCompressAndUploadImages, downloadFile, deleteFile, imageIsProcessing } =
    useFileUploader({
      accessToken,
      uploadUrl,
      maxImageSizeMB: maxFileSize,
      onFileUploadProgress(progressEvent) {
        if (progressEvent.total)
          setUploadProgress(Math.ceil((progressEvent.loaded / progressEvent.total) * 100));
      },
      onFileUploadError(err, files) {
        setUploadProgress(null);
        if (onFileUploadError) {
          onFileUploadError(err, files);
        } else {
          errorToast(t("forms.file_uploader.toasts.upload.error.title"))(err);
        }
      },
      onFileUploadedSuccess({ files, data }) {
        successToast(t("forms.file_uploader.toasts.upload.success.title"));
        setUploadProgress(null);
        onFileUploaded?.(files, data);
      },
      onFileDeletedSuccess(fileUrl) {
        successToast(t("forms.file_uploader.toasts.delete.success.title"));
        if (onFileDeleted) onFileDeleted(fileUrl);
      },
      onFileDeletedError(err, deleteFileUrl, fileUrl) {
        if (onFileDeletedError) onFileDeletedError(err, deleteFileUrl, fileUrl);
        else errorToast(t("forms.file_uploader.toasts.delete.error.title"))(err);
      },
    });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: doCompressAndUploadImages,
    accept: acceptedFileTypes,
    disabled,
    multiple: !disableMultiple,
  });

  const downloadAllFiles = async () => {
    if (uploadUrl) {
      const response = await downloadFile(uploadUrl);
      downloadFileFromResponse("download.zip", response?.data, response?.headers["content-type"]);
    }
  };

  const onDownloadFile = async (index: number, fileName: string | undefined) => {
    const downloadFileUrl = downloadUrl(index);
    const fileUrl = fileUrls[index];
    if (typeof fileUrl !== "string") {
      const offlineFile = fileUrl.file;
      downloadFileFromResponse(offlineFile.name, offlineFile, offlineFile.type);
      return;
    }
    if (downloadFileUrl) {
      const response = await downloadFile(downloadFileUrl);
      downloadFileFromResponse(
        fileName || parseFileName(fileUrl),
        response?.data,
        response?.headers["content-type"]
      );
    }
  };

  const onDeleteFile = (index: number) => {
    const deleteFileUrl = deleteUrl(index);
    const fileUrl = fileUrls[index];
    if (typeof fileUrl !== "string") {
      onFileDeleted?.(fileUrl);
      return;
    }
    if (deleteFileUrl && typeof deleteFileUrl === "string") {
      deleteFile(deleteFileUrl, fileUrl);
    }
  };

  /* Images not uploaded view */
  const uploadView = renderUpload ? (
    renderUpload(getRootProps, getInputProps, uploadProgress, imageIsProcessing)
  ) : (
    <DefaultUploadedView
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      isDragActive={isDragActive}
      renderInactive={renderInactive}
      loadingText={loadingText}
      uploadProgress={uploadProgress}
      imageIsProcessing={imageIsProcessing}
    />
  );

  return (
    <VStack alignItems="flex-start" w="full">
      {/* Images uploaded - display images and add control */}
      {fileUrls.length > 0 ? (
        <>
          {renderUploaded({
            fileUrls,
            onDeleteFile: disabled ? undefined : onDeleteFile,
            onDownloadFile,
          })}
          {!disableMultiple && (
            <FileUploaderMultiFileControls
              getInputProps={getInputProps}
              uploadProgress={uploadProgress}
              downloadAllFiles={downloadAllFiles}
            />
          )}
        </>
      ) : (
        uploadView
      )}
      {captionText && (
        <Text fontSize="sm" color="gray.500">
          {captionText}
        </Text>
      )}
    </VStack>
  );
}

export default memo(FileUploader);
