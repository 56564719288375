import type { OperationVariables, QueryHookOptions, QueryResult } from "@apollo/client";

type PaginatedQueryResult =
  | {
      offset: number;
      limit: number;
      count: number;
      total: number;
      more: boolean;
      first: boolean;
      results: unknown[];
    }
  | undefined;

export default function usePaginatedQuery<
  TData,
  TVariables extends OperationVariables = OperationVariables,
>(
  resultKey: keyof TData,
  query: (baseOptions?: QueryHookOptions<TData, TVariables>) => QueryResult<TData>,
  queryOptions: QueryHookOptions<TData, TVariables>
) {
  const { loading, error, data, fetchMore, refetch } = query(queryOptions);

  const paginationQueryResult = data?.[resultKey] as PaginatedQueryResult;
  const limit = paginationQueryResult?.limit;
  const totalRetrieved = (paginationQueryResult?.offset ?? 0) + (paginationQueryResult?.count ?? 0);

  const fetchMoreData = () => {
    if (!limit) return;
    void fetchMore({
      variables: {
        pagination: {
          limit,
          offset: totalRetrieved,
        },
      },
      updateQuery(prev, { fetchMoreResult }) {
        const newResults = fetchMoreResult?.[resultKey] as PaginatedQueryResult;
        const previousResults = prev?.[resultKey] as PaginatedQueryResult;
        if (!newResults) return prev;
        const { offset, limit: fetchMoreLimit, count, total, more, first } = newResults;
        return {
          [resultKey]: {
            __typename: "PaginatedJourneyList",
            offset,
            limit: fetchMoreLimit,
            count,
            total,
            more,
            first,
            results: [...(previousResults?.results ?? []), ...(newResults?.results ?? [])],
          },
        } as TData;
      },
    });
  };

  return { data, loading, error, fetchMore, fetchMoreData, refetch, totalRetrieved };
}
