import { ChannelEnum } from "@flows-platform/generated/graphql";
import { validateHtmlEmailField } from "@kwest_fe/core/src/utils/validateHtmlEmailField";
import * as Yup from "yup";

export default {
  message: Yup.object({
    id: Yup.string(),
    reviewBeforeSending: Yup.bool().nullable(),
    slackChannelId: Yup.string(),
    receiverId: Yup.string().nullable(),
    receiverRole: Yup.string(),
    channel: Yup.string(),
    toEmail: Yup.string()
      .when("channel", {
        is: ChannelEnum.Email,
        then: Yup.string()
          .nullable()
          .test("valid-email", "forms.email.validation_message", validateHtmlEmailField)
          .when("reviewBeforeSending", {
            is: false,
            then: Yup.string().required("forms.email.required_message").nullable(),
          }),
      })
      .nullable(),
    showCc: Yup.bool(),
    showBcc: Yup.bool(),
    ccEmail: Yup.string()
      .test("valid-email", "forms.email.validation_message", validateHtmlEmailField)
      .nullable(),
    bccEmail: Yup.string()
      .test("valid-email", "forms.email.validation_message", validateHtmlEmailField)
      .nullable(),
    enableReplyTo: Yup.bool(),
    replyToEmail: Yup.string()
      .test("valid-email", "forms.email.validation_message", validateHtmlEmailField)
      .nullable(),
    subject: Yup.string(),
    content: Yup.string(),
  }).nullable(),
};
