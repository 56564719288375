import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface DeleteJourneyModalProps {
  onDeleteJourney: () => void;
  isOpen: boolean;
  onClose: () => void;
}

function DeleteJourneyModal({ onDeleteJourney, isOpen, onClose }: DeleteJourneyModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("pages.flows.modals.delete_journey.title")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{t("pages.flows.modals.delete_journey.body")}</Text>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="red" mr={3} onClick={onDeleteJourney}>
            {t("global.actions.delete")}
          </Button>
          <Button variant="ghost" onClick={onClose}>
            {t("global.actions.cancel")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DeleteJourneyModal;
