import { Box, Text } from "@chakra-ui/react";
import {
  useAllFlowLabelsQuery,
  useOneFlowQuery,
  useUpdateFlowLabelsClientMutation,
} from "@flows-platform/generated/graphql";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { SingleValue } from "react-select";
import Select from "react-select";

interface LabelSelectorProps {
  flowId: string;
}
const LabelSelector = ({ flowId }: LabelSelectorProps) => {
  const { t } = useTranslation();
  const { data, loading: isLoadingLabels } = useAllFlowLabelsQuery();

  const { data: oneFlow } = useOneFlowQuery({
    variables: {
      flowId,
    },
  });

  const [mutate, { loading: isUpdatingLabels }] = useUpdateFlowLabelsClientMutation();

  const handleSelection = (
    selectedOption: SingleValue<{ value: string; label?: string }> | undefined
  ) => {
    mutate({
      variables: {
        flowId,
        labelsId: !selectedOption ? [] : [selectedOption.value],
      },
    });
  };

  const options = useMemo(() => {
    return data?.allFlowLabels?.map((label) => ({ value: label?.id, label: label?.name })) || [];
  }, [data?.allFlowLabels]);

  const defaultValue = useMemo(() => {
    if (!oneFlow?.oneFlow?.labels?.length) return null;
    return oneFlow.oneFlow.labels.map((label) => ({
      value: label?.id,
      label: label?.name,
    }))[0];
  }, [oneFlow?.oneFlow?.labels]);

  return (
    <Box w={"full"} fontSize={"14px"}>
      <Text fontWeight={500}>{t("forms.label_selector.label")}</Text>
      <Select
        isClearable
        placeholder={t("forms.label_selector.placeholder")}
        options={options}
        isLoading={isLoadingLabels || isUpdatingLabels}
        onChange={handleSelection}
        blurInputOnSelect
        defaultValue={defaultValue}
      />
    </Box>
  );
};

export default LabelSelector;
