/**
 * Reference https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
 *
 * @param filename Eventual name of the downloaded file
 * @param responseData File Blob
 */
export default function downloadFileFromResponse(
  filename: string,
  responseData: BlobPart,
  contentType?: string
) {
  const url = URL.createObjectURL(
    new Blob([responseData], contentType ? { type: contentType } : undefined)
  );
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}
