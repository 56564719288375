import { Flex } from "@chakra-ui/react";

interface PageWrapperProps {
  children: JSX.Element | JSX.Element[];
  height?: string;
}
function PageWrapper({ children, height = "99vh" }: PageWrapperProps) {
  return (
    <Flex alignItems="flex-start" w="100%" direction={"column"} h={height}>
      {children}
    </Flex>
  );
}

export default PageWrapper;
