import { useDisclosure } from "@chakra-ui/react";
import { useUpdateProjectPhaseClientMutation } from "@flows-platform/generated/graphql";
import EditableTextField from "@kwest_fe/core/src/components/EditableTextField";
import GenericConfirmationModal from "@kwest_fe/core/src/components/UI/organisms/modals/GenericConfirmationModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface PhaseNameInput {
  phase: { id: string; color: string; name: string };
}

const PhaseName = ({ phase }: PhaseNameInput) => {
  const [updatePhase] = useUpdateProjectPhaseClientMutation();
  const [newName, setNewName] = useState(phase.name);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();
  const handleConfirm = (name: string) => {
    updatePhase({
      variables: {
        id: phase.id,
        data: { name },
      },
      optimisticResponse: {
        updateProjectPhase: {
          phase: {
            id: phase.id,
            name,
            color: phase.color,
            __typename: "ProjectPhaseType",
          },
          __typename: "UpdateProjectPhaseMutation",
        },
      },
    });
  };

  return (
    <>
      <EditableTextField
        onChange={(value: string) => {
          setNewName(value);
          onOpen();
        }}
        text={phase.name}
        textProps={{
          fontSize: "14px",
        }}
        w={"full"}
      />
      <GenericConfirmationModal
        isOpen={isOpen}
        onClose={onClose}
        onAction={() => {
          handleConfirm(newName);
          onClose();
        }}
        title={t("pages.settings.sections.phases.modal.rename.title")}
      >
        <p>{t("pages.settings.sections.phases.modal.rename.body")}</p>
      </GenericConfirmationModal>
    </>
  );
};

export default PhaseName;
