import { HStack, Text } from "@chakra-ui/react";
import type { FileUploaderCaptionProps } from "@kwest_fe/core/src/components/FileUploader/constants/types";
import { MdOutlineFileUpload } from "react-icons/md";

function FileUploadCaption({ isDragActive }: FileUploaderCaptionProps) {
  return (
    <HStack justifyContent="center" color="#4C1397">
      <MdOutlineFileUpload />
      {isDragActive ? <Text>Drop files here</Text> : <Text>File Upload</Text>}
    </HStack>
  );
}

export default FileUploadCaption;
