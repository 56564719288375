/* eslint-disable react/jsx-props-no-spreading */
import type { Text } from "slate";
import type { RenderElementProps, RenderLeafProps } from "slate-react";

import EmbeddedFile from "./components/EmbeddedFile";
import EmbeddedImage from "./components/EmbeddedImage";
import PlaceholderVariable from "./components/PlaceholderVariable";
import { BlockTypes } from "./constants";
import type { ExtendedElement, TextProperties } from "./types";

const LIST_PADDING = "2em";

export function Element(props: RenderElementProps & { element: ExtendedElement }) {
  const { attributes, children, element } = props;
  const style = { textAlign: element.align };
  const listStyles = { padding: 0, margin: 0, marginLeft: LIST_PADDING };

  switch (element.type) {
    case BlockTypes.quote:
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case BlockTypes["bulleted-list"]:
      return (
        <ul style={{ ...style, ...listStyles }} {...attributes}>
          {children}
        </ul>
      );
    case BlockTypes["numbered-list"]:
      return (
        <ol style={{ ...style, ...listStyles }} {...attributes}>
          {children}
        </ol>
      );
    case BlockTypes["heading-one"]:
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case BlockTypes["heading-two"]:
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case BlockTypes["list-item"]:
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case BlockTypes.placeholder:
      return <PlaceholderVariable {...props} />;
    case BlockTypes.image:
      return <EmbeddedImage {...props} />;
    case BlockTypes.file:
      return <EmbeddedFile {...props} />;

    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
}

export function Leaf({
  attributes,
  children,
  leaf,
}: RenderLeafProps & { leaf: Text & TextProperties }) {
  let childComponents = children;
  if (leaf.bold) {
    childComponents = <strong>{childComponents}</strong>;
  }

  if (leaf.code) {
    childComponents = <code>{childComponents}</code>;
  }

  if (leaf.italic) {
    childComponents = <em>{childComponents}</em>;
  }

  if (leaf.underline) {
    childComponents = <u>{childComponents}</u>;
  }

  return <span {...attributes}>{childComponents}</span>;
}
