import { Box, VStack } from "@chakra-ui/react";
import type { MyWorkspaceTagsClientQuery } from "@flows-platform/generated/graphql";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import type { SingleOption } from "../../types";
import GenericFilterDropdown from "../GenericFilterDropdown/GenericFilterDropdown";

interface FilterByTagProps {
  onSaveFilter: (field: string, content?: string) => void;
  allTags: NonNullable<MyWorkspaceTagsClientQuery["myWorkspace"]>["journeyTags"];
  onClose: () => void;
}

export default function FilterByTag({ allTags, onSaveFilter, onClose }: FilterByTagProps) {
  const { t } = useTranslation();

  const fields =
    allTags?.map((tag) => ({
      label: tag?.name || "",
      value: tag?.id || "",
    })) || [];

  const onFieldChanged = useCallback((field: SingleOption) => {
    if (field) {
      onSaveFilter(field.value);
    }
  }, []);

  return (
    <VStack px={3} paddingBottom={1} alignItems="flex-end" gap={1} w="full">
      <Box w="full">
        <GenericFilterDropdown
          placeholder={t("pages.projects.placeholders.tag")}
          options={fields}
          onChange={onFieldChanged}
          onBlur={onClose}
        />
      </Box>
    </VStack>
  );
}
