import { Badge } from "@chakra-ui/react";
import isMacintosh from "@kwest_fe/core/src/utils/isMacintosh";
import type { PropsWithChildren } from "react";

const Shortcut = ({ children }: PropsWithChildren) => {
  return (
    <Badge
      bg={"gray.50"}
      py={0.5}
      px={1}
      border={"gray.200"}
      borderWidth={"1px"}
      rounded={"lg"}
      borderBottomWidth={"3px"}
      color={"gray.600"}
      display={{ base: "none", lg: "inline-flex" }}
    >
      {isMacintosh ? "⌘" : "ctrl+"}
      {children}
    </Badge>
  );
};

export default Shortcut;
