import { Box, VStack } from "@chakra-ui/react";
import { TodoListEnum } from "@kwest_fe/core/src/modules/Core/constants/enums";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import type { JourneyStepResourceQuery, TodoItemToggleInput } from "@todo-viewer/generated/graphql";
import { useTodoItemToggleClientMutation } from "@todo-viewer/generated/graphql";
import messageParent from "@todo-viewer/modules/Core/utils/messageParent";
import StaticTodoItem from "@todo-viewer/modules/Shared/components/StaticTodoItem";

import { CaptionBoxStyles } from "../../constants/constants";
import useRedirectToNextStep from "../../hooks/useRedirectToNextStep";
import TodoViewLayout from "../TodoViewLayout";
import TodoViewPageHeader from "../TodoViewPageHeader";

interface TodoViewTodoListProps {
  resource: JourneyStepResourceQuery["getJourneyStepResource"] & {
    __typename: "TodoListResourceType";
  };
}

function TodoViewTodoList({ resource }: TodoViewTodoListProps) {
  const { title, orderedTodoItems, receiver, todoListType, caption, journeyStep, receiverTeam } =
    resource;

  const [toggleTodoItemMutation] = useTodoItemToggleClientMutation();

  const { successToast } = useDefaultToasts();
  const redirectToNextStep = useRedirectToNextStep();

  const toggleTodoItem = (todoItem: TodoItemToggleInput) => {
    toggleTodoItemMutation({
      variables: {
        input: {
          id: todoItem.id,
          isDone: !todoItem.isDone,
        },
      },
      optimisticResponse: {
        toggleTodoItem: {
          todoItem: {
            ...todoItem,
            isDone: !todoItem.isDone,
            todoList: {
              __typename: "TodoListResourceType",
              shouldBeCompleted: false,
            },
          },
          nextStep: null,
        },
      },
      onCompleted(data) {
        const toggleTodoItemData = data.toggleTodoItem;
        const todoListCompleted = toggleTodoItemData?.todoItem?.todoList.shouldBeCompleted;
        if (todoListCompleted) {
          setTimeout(() => {
            successToast("Todo list completed successfully");
            messageParent("task_completed");
            redirectToNextStep(toggleTodoItemData.nextStep, resource?.journeyStep?.projectId);
          }, 2000);
        }
      },
    });
  };

  return (
    <TodoViewLayout
      isCompleted={!!journeyStep?.completedAt}
      heading={
        <TodoViewPageHeader
          journeyStep={journeyStep}
          receiver={receiver}
          receiverTeam={receiverTeam}
          title={title}
        />
      }
    >
      <Box
        my={4}
        w="full"
        dangerouslySetInnerHTML={{ __html: caption || "" }}
        {...CaptionBoxStyles}
      />

      <VStack spacing={4} w="full">
        {orderedTodoItems?.map(
          (item) =>
            item && (
              <StaticTodoItem
                checkboxDisabled={todoListType === TodoListEnum.FlowStep}
                toggleTodoItem={toggleTodoItem}
                key={item.id}
                todoItem={item}
              />
            )
        )}
      </VStack>
    </TodoViewLayout>
  );
}

export default TodoViewTodoList;
