import type { ButtonProps, IconButtonProps } from "@chakra-ui/react";
import { HStack, Input } from "@chakra-ui/react";
import { forwardRef, useRef } from "react";
import type { DropzoneInputProps } from "react-dropzone";

import LabelledIconButton from "../LabelledIconButton";

interface UploadIconButtonProps extends DropzoneInputProps {
  "aria-label": IconButtonProps["aria-label"];
  icon: IconButtonProps["icon"];
  isLoading?: IconButtonProps["isLoading"];
  variant?: ButtonProps["variant"];
  children?: ButtonProps["children"];
}

const ButtonBorderColor = "#291544";
const UploadIconButton = forwardRef<HTMLInputElement, UploadIconButtonProps>(
  ({ icon, "aria-label": ariaLabel, variant, isLoading, children, ...restProps }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const resolvedRef = ref || inputRef;
    return (
      <HStack alignItems="center">
        <Input
          type="file"
          display="none"
          ref={ref || inputRef}
          required={false}
          {...restProps}
          size={undefined}
        />
        <LabelledIconButton
          isLoading={isLoading}
          icon={icon}
          size="md"
          variant={variant}
          backgroundColor={"white"}
          border={`1px solid ${ButtonBorderColor}`}
          color={ButtonBorderColor}
          _hover={{
            backgroundColor: "#F5EFFD",
          }}
          aria-label={ariaLabel}
          onClick={(event) => {
            event.preventDefault();
            if (typeof resolvedRef !== "function") resolvedRef?.current?.click();
          }}
        >
          {children}
        </LabelledIconButton>
      </HStack>
    );
  }
);

export default UploadIconButton;
