import { useQuery } from "@apollo/client";
import { HStack, Text, theme } from "@chakra-ui/react";
import FileListViewer from "@kwest_fe/core/src/components/FileListViewer";
import FileUploader from "@kwest_fe/core/src/components/FileUploader/FileUploader";
import HTMLContentRenderer from "@kwest_fe/core/src/components/HTMLContentRenderer";
import MembershipSelector from "@kwest_fe/core/src/components/MembershipSelector/MembershipSelector";
import UploadIconButton from "@kwest_fe/core/src/components/UI/atoms/Buttons/UploadIconButton";
import config from "@kwest_fe/core/src/config";
import { useKwestAuth } from "@kwest_fe/core/src/modules/Core/providers/KwestAuthProvider";
import { getOptionsFromList } from "@kwest_fe/core/src/utils/generateOptions";
import { FormItemItemType as FormItemType } from "@todo-viewer/generated/graphql";
import REMOTE_FORM_ITEM_DATA from "@todo-viewer/graphql/queries/flows/remoteFormItemData";
import BooleanFormField from "@todo-viewer/modules/Shared/components/BooleanFormField";
import FileUploadCaption from "@todo-viewer/modules/Shared/components/FileUploadCaption/FileUploadCaption";
import FormikField from "@todo-viewer/modules/Shared/components/FormikField";
import FormikSelectField from "@todo-viewer/modules/Shared/components/FormikSelectField/FormikSelectField";
import ResizableTextarea from "@todo-viewer/modules/Shared/components/ResizableTextarea";
import useOfflineEnabledFileField from "@todo-viewer/modules/Shared/hooks/useOfflineEnabledFileField";
import { FileUploadIcon } from "@todo-viewer/theme/icons";
import type { NonNullableOrderedFormItem } from "@todo-viewer/types";
import { useField } from "formik";
import { memo, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

interface FormikFormItemProps {
  formItem: NonNullableOrderedFormItem;
  formId: string;
  bgOnHover?: boolean;
  disabled?: boolean;
}

function FormikFormItem({
  formItem,
  formId,
  disabled = false,
  bgOnHover = false,
}: FormikFormItemProps) {
  const { t } = useTranslation();
  const { accessToken } = useKwestAuth();

  const {
    label,
    text,
    options,
    useRemoteData,
    id,
    variableIdentifier,
    itemType,
    defaultText,
    isOptional,
    isList,
  } = formItem;
  const { data, loading, error } = useQuery(REMOTE_FORM_ITEM_DATA, {
    variables: {
      formItemId: id,
    },
    skip: !useRemoteData,
  });

  const remoteFormItemData = useMemo(() => {
    if (!data) return itemType === FormItemType.Option ? [] : "";
    const jsonData = data.remoteFormItemData ? JSON.parse(data.remoteFormItemData) : null;
    return jsonData?.data;
  }, [data, itemType]);

  const itemRef = useRef(null);
  const [{ name }, { value }, { setValue }] = useField(`form.stepVariables[${variableIdentifier}]`);
  const fileUploadUrl = `${config.backend.uri}/api/forms/${formId}/files/${variableIdentifier}`;

  const {
    deleteFileByFileUrl,
    deleteUrlByIndex,
    cacheDeletedImages,
    onFileUploadError,
    onFileUploaded,
    fileUrls,
  } = useOfflineEnabledFileField({
    itemType,
    isList,
    variableIdentifier,
    value,
    setValue,
    fileUploadUrl,
  });

  // Load default value
  useEffect(() => {
    if (useRemoteData) {
      // Load default value using remote data for long text fields
      if (remoteFormItemData && itemType === FormItemType.LongText) {
        setValue(remoteFormItemData);
      }
    } else if (defaultText) {
      setValue(defaultText);
    }
  }, [useRemoteData, remoteFormItemData, defaultText, itemType]);

  return (
    <HStack
      ref={itemRef}
      w="full"
      p={bgOnHover ? 2 : 0}
      sx={{ _hover: { bg: bgOnHover ? "gray.50" : "" } }}
    >
      {itemType === FormItemType.DescriptionText && (
        <HTMLContentRenderer htmlContent={text || ""} />
      )}

      {itemType === FormItemType.Option && (
        <FormikSelectField
          loading={loading && !error}
          label={label}
          name={name}
          options={
            useRemoteData ? getOptionsFromList(remoteFormItemData) : getOptionsFromList(options)
          }
          loadingText="Loading options..."
          required={!isOptional}
          disabled={disabled}
          isMulti={isList}
          captionText={text ?? ""}
          styles={{
            control(base, props) {
              return {
                ...base,
                backgroundColor: props.isDisabled ? "#E9E9EC" : "#FCFCFC",
                borderColor: theme.colors.gray[200],
                ":hover": {
                  borderColor: theme.colors.gray[300],
                },
              };
            },
            option(base, { isSelected, isMulti }) {
              return {
                ...base,
                fontSize: "14px",
                backgroundColor: isSelected && !isMulti ? theme.colors.purple[700] : "transparent",
                color: isSelected && !isMulti ? "white" : theme.colors.gray[700],
                ":hover": {
                  backgroundColor:
                    isSelected && !isMulti ? theme.colors.purple[800] : theme.colors.gray[50],
                },
              };
            },
            multiValue: (base) => ({
              ...base,
              backgroundColor: theme.colors.gray[200],
              borderRadius: theme.sizes[1],
            }),
            multiValueLabel: (base) => ({
              ...base,
              padding: theme.sizes[1.5],
            }),
            multiValueRemove: (base) => ({
              ...base,
              color: theme.colors.gray[500],
              ":hover": {
                ...base[":hover"],
                color: theme.colors.gray[700],
                backgroundColor: theme.colors.gray[200],
              },
            }),
          }}
        />
      )}

      {itemType === FormItemType.Date && (
        <FormikField
          label={label}
          name={name}
          type="date"
          required={!isOptional}
          disabled={disabled}
          showOptionalIndicator
          captionText={text ?? ""}
        />
      )}

      {itemType === FormItemType.ShortText && (
        <FormikField
          label={label}
          name={name}
          placeholder="Enter text"
          required={!isOptional}
          disabled={disabled}
          showOptionalIndicator
          captionText={text ?? ""}
        />
      )}

      {itemType === FormItemType.LongText && (
        <FormikField
          renderElement={(props) => (
            <ResizableTextarea
              {...props}
              style={{
                transition: "height none",
                height: "auto",
                minHeight: "70px",
                backgroundColor: "#FCFCFC",
                borderColor: "#e0dee3",
              }}
              resize="none"
              isDisabled={disabled || (useRemoteData && loading)}
            />
          )}
          label={label}
          name={name}
          placeholder={useRemoteData && loading ? "Loading..." : "Enter text"}
          required={!isOptional}
          showOptionalIndicator
          captionText={text ?? ""}
        />
      )}

      {itemType === FormItemType.Number && (
        <FormikField
          label={label}
          name={name}
          placeholder="Enter a number"
          type="number"
          required={!isOptional}
          showOptionalIndicator
          disabled={disabled}
          captionText={text ?? ""}
        />
      )}

      {itemType === FormItemType.ImageUpload && (
        <FormikField
          required={!isOptional}
          renderElement={() => (
            <FileUploader
              accessToken={accessToken}
              uploadUrl={fileUploadUrl}
              deleteUrl={deleteUrlByIndex}
              fileUrls={fileUrls.filter(Boolean)}
              onFileUploaded={onFileUploaded}
              onFileUploadError={onFileUploadError}
              onFileDeleted={deleteFileByFileUrl}
              onFileDeletedError={cacheDeletedImages}
              disableMultiple={!isList}
              captionText={text ?? ""}
              disabled={disabled}
            />
          )}
          label={label}
          name={name}
          showOptionalIndicator
        />
      )}

      {itemType === FormItemType.FileUpload && (
        <FormikField
          required={!isOptional}
          renderElement={() => (
            <FileUploader
              accessToken={accessToken}
              acceptedFileTypes={{ "application/pdf": [".pdf"] }}
              renderUploaded={FileListViewer}
              renderInactive={FileUploadCaption}
              uploadUrl={fileUploadUrl}
              deleteUrl={deleteUrlByIndex}
              fileUrls={fileUrls.filter(Boolean)}
              onFileUploaded={onFileUploaded}
              onFileUploadError={onFileUploadError}
              onFileDeleted={deleteFileByFileUrl}
              onFileDeletedError={cacheDeletedImages}
              captionText={text || ""}
              loadingText={t("global.loaders.uploading_file")}
              disabled={disabled}
              disableMultiple={!isList}
              renderUpload={(getRootProps, getInputProps, uploadProgress, imageIsProcessing) => (
                <UploadIconButton
                  variant="outline"
                  aria-label="Upload File"
                  icon={<FileUploadIcon />}
                  isLoading={imageIsProcessing || uploadProgress !== null}
                  {...getInputProps()}
                >
                  <Text>Upload File</Text>
                </UploadIconButton>
              )}
            />
          )}
          label={label}
          name={name}
          showOptionalIndicator
        />
      )}

      {formItem.itemType === FormItemType.Boolean && (
        <FormikField
          required={!isOptional}
          renderElement={(props) => (
            <BooleanFormField
              checked={props.value}
              onChange={setValue}
              captionText={text || ""}
              disabled={disabled}
            />
          )}
          label={label}
          name={name}
          showOptionalIndicator
        />
      )}

      {formItem.itemType === FormItemType.Membership && (
        <FormikField
          required={!isOptional}
          renderElement={() => <MembershipSelector name={name} disabled={disabled} />}
          label={label}
          name={name}
          showOptionalIndicator
        />
      )}
    </HStack>
  );
}

export default memo(FormikFormItem);
