import { InstallionNoteRecipientTypeEnum } from "@flows-platform/generated/graphql";

export default function mapRecipientName(recipientName: InstallionNoteRecipientTypeEnum | null) {
  switch (recipientName) {
    case InstallionNoteRecipientTypeEnum.Auftraggeber:
      return "Auftraggeber";
    case InstallionNoteRecipientTypeEnum.Customer:
      return "Customer";
    case InstallionNoteRecipientTypeEnum.Ausgangsrechnungen:
      return "Ausgangsrechnungen";
    case InstallionNoteRecipientTypeEnum.Einkauf:
      return "Einkauf";
    case InstallionNoteRecipientTypeEnum.Installateur:
      return "Installateur";
    case InstallionNoteRecipientTypeEnum.LichtBlick:
      return "Licht Blick";
    case InstallionNoteRecipientTypeEnum.Netzanmeldung:
      return "Netzanmeldung";
    case InstallionNoteRecipientTypeEnum.PartnerCompany:
      return "Partner Company";
    case InstallionNoteRecipientTypeEnum.Planung:
      return "Planung";
    case InstallionNoteRecipientTypeEnum.Service:
      return "Service";
    default:
      return "Unknown";
  }
}
