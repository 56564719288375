import { useAuth0 } from "@auth0/auth0-react";
import { Button, Input, Text } from "@chakra-ui/react";
import FileUploader from "@kwest_fe/core/src/components/FileUploader";
import NoPreviewUploadedView from "@kwest_fe/core/src/components/UI/organisms/NoPreviewUploadedView";
import { FlowStepVariableTypeEnum } from "@kwest_fe/core/src/enums/flowStep";
import { AddIcon } from "@kwest_fe/core/src/theme/icons";
import parseFileName from "@kwest_fe/core/src/utils/parseFileName";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface FileFieldProps {
  variableValue: string;
  fileUrl?: string;
  uploadUrl: string;
  type: string;
  readOnly?: boolean;
}

export default function FileField({
  variableValue,
  fileUrl,
  uploadUrl,
  type,
  readOnly,
}: FileFieldProps) {
  const [fileUrlState, setFileUrlState] = useState(fileUrl);
  const [variableValueState, setVariableValueState] = useState(variableValue);
  const fileName = parseFileName(variableValueState || "");
  const { getAccessTokenSilently } = useAuth0();

  const { t } = useTranslation();
  return (
    <FileUploader
      accessToken={getAccessTokenSilently()}
      disableMultiple
      disabled={readOnly}
      acceptedFileTypes={
        type === FlowStepVariableTypeEnum.FILE ? { "application/pdf": [".pdf"] } : undefined
      }
      uploadUrl={uploadUrl}
      fileUrls={fileUrlState ? [fileUrlState] : []}
      onFileDeleted={() => {
        setFileUrlState("");
      }}
      onFileUploaded={(files) => {
        if (files.length > 0) {
          setFileUrlState(files[0].name);
          setVariableValueState(files[0].name);
        }
      }}
      renderUploaded={({ onDeleteFile, onDownloadFile }) => (
        <NoPreviewUploadedView
          onDeleteFile={onDeleteFile}
          onDownloadFile={onDownloadFile}
          type={type}
          fileUrl={fileUrlState}
          fileName={fileName}
          readOnly={readOnly}
        />
      )}
      renderUpload={(getRootProps, getInputProps, uploadProgress, imageIsProcessing) => {
        if (readOnly)
          return (
            <Text fontSize={"14px"} color={"gray.500"}>
              {t("pages.project_instance.variables.no_file")}
            </Text>
          );
        return (
          <Button
            {...getRootProps()}
            size="sm"
            padding={0}
            isLoading={imageIsProcessing || uploadProgress !== null}
          >
            <AddIcon size="sm" />
            <Input {...getInputProps()} size={undefined} />
          </Button>
        );
      }}
    />
  );
}
