import { Alert, Box, FormControl, FormLabel, Portal, Text } from "@chakra-ui/react";
import { VariableStoreProvider } from "@core/components/context/VariableStore";
import useRichTextEditor from "@core/modules/Shared/hooks/useRichTextEditor";
import type { VariableType } from "@core/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Editable, ReactEditor, Slate } from "slate-react";

import MultiPicker from "./components/MultiPicker/MultiPicker";
import Toolbar from "./components/Toolbar";
import { INITIAL_TEXT_EDITOR_VALUE } from "./constants";
import { Element, Leaf } from "./renderers";

interface RichTextEditorProps {
  name: string;
  variables?: Record<string, VariableType>;
  placeholder?: string;
  minHeight?: number;
  background?: string;
  hideBorder?: boolean;
  enableImageUploads?: boolean;
  allowAttachments?: boolean;
  showPlaceholderHint?: boolean;
  hideToolbar?: boolean;
  isSingleLine?: boolean;
  label?: string;
  onChange?: (value: string) => void;
  emailFieldsOnly?: boolean;
  excludedVariableTypes?: string[];
  readOnly?: boolean;
}

function RichTextEditor({
  name,
  variables = {},
  placeholder = "Type here...",
  minHeight = 300,
  background = "transparent",
  allowAttachments = false,
  hideBorder = false,
  hideToolbar = false,
  isSingleLine = false,
  enableImageUploads = false,
  showPlaceholderHint = false,
  label,
  onChange,
  emailFieldsOnly = false,
  excludedVariableTypes = [],
  readOnly = false,
}: RichTextEditorProps) {
  const {
    editor,
    index,
    target,
    isMarkActive,
    toggleMark,
    isBlockActive,
    toggleBlock,
    filteredPlaceholderVariables,
    handleEditorContentChange,
    handleEditableOnKeyDown,
    undefinedVariables,
    selected,
    setSelected,
    onImageSelected,
  } = useRichTextEditor({ name, variables, onChange, emailFieldsOnly, excludedVariableTypes });

  const { t } = useTranslation();

  const variablePickerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null && target !== null) {
        const domRange = ReactEditor.toDOMRange(editor, target);
        const rect = domRange.getBoundingClientRect();
        // eslint-disable-next-line no-param-reassign
        node.style.position = "absolute";
        // eslint-disable-next-line no-param-reassign
        node.style.top = `${rect.top + window.scrollY + 24}px`;
        // eslint-disable-next-line no-param-reassign
        node.style.left = `${rect.left + window.scrollX}px`;
      }
    },
    [target, editor]
  );

  return (
    <>
      <FormControl>
        {label && <FormLabel fontSize="sm">{label}</FormLabel>}
        <Box
          borderWidth={!hideBorder ? 1 : undefined}
          borderRadius={isSingleLine ? "md" : undefined}
          minHeight={!isSingleLine ? minHeight : "40px"}
          px={isSingleLine ? "1" : undefined}
          transition="all .3s"
          _hover={isSingleLine ? { borderColor: "gray.300" } : undefined}
          backgroundColor={background}
          bg={background}
        >
          <Slate
            editor={editor}
            initialValue={INITIAL_TEXT_EDITOR_VALUE}
            onChange={handleEditorContentChange}
          >
            {!hideToolbar && (
              <Toolbar
                isBlockActive={isBlockActive}
                isMarkActive={isMarkActive}
                toggleBlock={toggleBlock}
                toggleMark={toggleMark}
                onFileSelected={
                  allowAttachments
                    ? (files) => {
                        onImageSelected(files);
                      }
                    : undefined
                }
                onImageSelected={
                  enableImageUploads
                    ? (files) => {
                        onImageSelected(files);
                      }
                    : undefined
                }
              />
            )}
            <Box padding={isSingleLine ? 2 : 4} overflow={isSingleLine ? "hidden" : undefined}>
              <VariableStoreProvider variables={variables}>
                <Editable
                  data-testid={`rich-text-editor`}
                  placeholder={placeholder}
                  renderElement={Element}
                  renderLeaf={Leaf}
                  onKeyDown={handleEditableOnKeyDown}
                  readOnly={readOnly}
                  style={{
                    fontSize: "14px",
                    outline: "none",
                  }}
                />
              </VariableStoreProvider>
            </Box>
            {target && (
              <Portal>
                <MultiPicker
                  ref={variablePickerRef}
                  index={index}
                  selected={selected}
                  options={filteredPlaceholderVariables}
                  onChanged={setSelected}
                />
              </Portal>
            )}
          </Slate>
        </Box>
      </FormControl>
      {undefinedVariables.size ? (
        <Box padding={hideBorder ? 4 : undefined}>
          <Text fontSize="sm" color="red.500">
            {t("forms.rich_text_editor.hints.undefined_variable")}
          </Text>
        </Box>
      ) : null}
      {showPlaceholderHint && (
        <Alert borderRadius="md">
          <Text fontSize="sm">{t("forms.rich_text_editor.hints.placeholders")}</Text>
        </Alert>
      )}
    </>
  );
}

export default RichTextEditor;
