import { withSentryReactRouterV6Routing } from "@sentry/react";
import { Navigate, Route, Routes } from "react-router-dom";

import Providers from "./modules/Core/components/Providers/Providers";
import type { RouteType } from "./modules/Core/constants/routes";
import routes from "./modules/Core/constants/routes";
import PrivateRoute from "./modules/Shared/components/PrivateRoute/PrivateRoute";

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

type RouteElementType = JSX.Element | RouteElementType[] | null;

export default function Root() {
  const getRouteComponent = (route: RouteType) => {
    if (route.isPublicRoute) {
      return <Route path={route.route} element={route.page} key={route.key} />;
    }

    return (
      <Route
        path={route.route}
        element={
          <PrivateRoute isDashboardRoute={route.isDashboardRoute} isFullWidth={route.isFullWidth} />
        }
        key={route.key}
      >
        <Route path={route.route} element={route.page} />
      </Route>
    );
  };

  const getRoutes = (allRoutes: RouteType[]): RouteElementType =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return getRouteComponent(route);
      }

      return null;
    });

  return (
    <SentryRoutes>
      <Route element={<Providers />}>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/todos" />} />
      </Route>
    </SentryRoutes>
  );
}
