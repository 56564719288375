import { IconButton } from "@chakra-ui/react";

import { TEXT_ALIGN_TYPES } from "../../../../constants";
import type { BlockFormatOperations, TextAlignType } from "../../../../types";

interface BlockButtonProps {
  icon: JSX.Element;
  format: BlockFormatOperations;
  toggleBlock: (format: BlockFormatOperations) => void;
  isBlockActive: (format: BlockFormatOperations, blockType: "align" | "type") => boolean;
}

function BlockButton({ icon, format, toggleBlock, isBlockActive }: BlockButtonProps) {
  const blockType = TEXT_ALIGN_TYPES.includes(format as TextAlignType) ? "align" : "type";
  return (
    <IconButton
      aria-label={format}
      variant={isBlockActive(format, blockType) ? "solid" : "ghost"}
      icon={icon}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(format);
      }}
    />
  );
}

export default BlockButton;
