import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { ProjectLeadIcon, TeamIcon } from "@core/theme/icons";
import type { OptionProps, StylesConfig } from "react-select";
import { components } from "react-select";

import type { FormikMembershipSelectFieldSelectOption } from "./components/FormikMembershipSelectField";

const ALREADY_ASSIGNED_MESSAGE = "This use has already been assigned to the flow";

const CUSTOM_SELECT_PADDING = ".7em 1.5em";

export const SelectFieldStyles: StylesConfig = {
  option: (base) => ({
    ...base,
    padding: CUSTOM_SELECT_PADDING,
    cursor: "pointer",
  }),
};

export interface CustomUserSelectOptionData extends FormikMembershipSelectFieldSelectOption {
  isAssigned: boolean;
  warningMessage: string;
  workspaceEmail: string;
}

interface CustomUserSelectProps {
  useTeamIndicators?: boolean;
}

function CustomUserSelectOption<M extends boolean>({
  children,
  useTeamIndicators,
  ...props
}: CustomUserSelectProps & OptionProps<CustomUserSelectOptionData, M>) {
  const { workspaceEmail, warningMessage, isAssigned } = props.data;

  return (
    <components.Option {...props}>
      <HStack
        spacing={3}
        borderBottomWidth={1}
        borderBottomColor="gray.300"
        _last={{ borderBottomWidth: 0 }}
      >
        {/* <Avatar w={32} h={32} initials={initials} fontSize="l" /> */}
        {useTeamIndicators &&
          (props.data.value?._typename === "MembershipType" ? <ProjectLeadIcon /> : <TeamIcon />)}
        <VStack alignItems="flex-start" spacing={0} flexGrow="1">
          <Text fontSize="sm" fontWeight="bold">
            {children}
          </Text>
          <Text fontSize="sm" color={props.isSelected ? "white" : "gray.500"}>
            {workspaceEmail}
          </Text>
        </VStack>
        {warningMessage && (
          <Tooltip label={warningMessage} aria-label={warningMessage} placement="top">
            <WarningIcon color="orange" />
          </Tooltip>
        )}
        {isAssigned && (
          <Tooltip
            label={ALREADY_ASSIGNED_MESSAGE}
            aria-label={ALREADY_ASSIGNED_MESSAGE}
            placement="top"
          >
            <CheckCircleIcon color="green" />
          </Tooltip>
        )}
      </HStack>
    </components.Option>
  );
}

export default CustomUserSelectOption;
