import { useApolloClient } from "@apollo/client";
import { VStack } from "@chakra-ui/react";
import {
  AllPhasesSettingsDocument,
  useAllPhasesSettingsQuery,
  useReorderProjectPhasesClientMutation,
} from "@flows-platform/generated/graphql";

import PhaseRow from "./PhaseRow";

const switchElementsPosition = <T,>(
  array: T[],
  sourcePosition: number,
  destinationPosition: number
) => {
  const newArray = [...array];
  [newArray[sourcePosition], newArray[destinationPosition]] = [
    newArray[destinationPosition],
    newArray[sourcePosition],
  ];
  return newArray;
};

const PhasesTable = () => {
  const { data } = useAllPhasesSettingsQuery();

  const [reOrder] = useReorderProjectPhasesClientMutation();

  const client = useApolloClient();

  const handleReOrder = async (sourcePosition: number, destinationPosition: number) => {
    const allPhasesIds = data?.allProjectPhases?.map((phase) => phase?.id) || [];

    const reorderedPhasesIds = switchElementsPosition(
      allPhasesIds,
      sourcePosition,
      destinationPosition
    );

    client.writeQuery({
      query: AllPhasesSettingsDocument,
      data: {
        allProjectPhases: reorderedPhasesIds.map(
          (id) => data?.allProjectPhases?.find((phase) => phase?.id === id)
        ),
      },
    });

    await reOrder({
      variables: { order: reorderedPhasesIds },
      onError: () => {
        client.refetchQueries({
          include: "active",
        });
      },
    });
  };

  if (!data?.allProjectPhases?.length) return null;

  return (
    <VStack pl={2} alignItems={"start"} w={"full"} spacing={4}>
      {data.allProjectPhases.filter(Boolean).map((phase) => {
        if (phase?.__typename)
          return <PhaseRow key={phase?.id} phase={phase} reOrder={handleReOrder} />;
        return null;
      })}
    </VStack>
  );
};

export default PhasesTable;
