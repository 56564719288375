import { HStack, IconButton } from "@chakra-ui/react";
import { MoveDownIcon, MoveUpIcon } from "@flows-platform/theme/icons";

interface SortIconsProps {
  onMoveUp: () => void;
  onMoveDown: () => void;
  disableMoveDown?: boolean;
  disableMoveUp?: boolean;
}

export default function SortIcons({
  onMoveUp,
  onMoveDown,
  disableMoveDown = false,
  disableMoveUp = false,
}: SortIconsProps) {
  return (
    <HStack minH={10} alignItems="center" spacing={0}>
      <IconButton
        variant="ghost"
        aria-label="move-up"
        icon={<MoveUpIcon />}
        onClick={onMoveUp}
        size="xs"
        isDisabled={disableMoveUp}
      />
      <IconButton
        variant="ghost"
        aria-label="move-down"
        icon={<MoveDownIcon />}
        onClick={onMoveDown}
        size="xs"
        isDisabled={disableMoveDown}
      />
    </HStack>
  );
}
