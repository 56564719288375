import { Editor } from "@tinymce/tinymce-react";
import { useField } from "formik";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import type { Editor as TinyMCEEditor } from "tinymce";

import type { VariableType } from "../../../../types";
import { VARIABLE_EXTRA_PROPERTIES } from "../RichTextEditor/components/MultiPicker/constants/constants";

interface TinyMCERichTextEditorProps {
  name: string;
  variables?: Record<string, VariableType>;
}

function TinyMCERichTextEditor({ name, variables }: TinyMCERichTextEditorProps) {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const { t } = useTranslation();

  const [, { initialValue }, { setValue }] = useField(name);

  const mergeTagsList = variables
    ? Object.keys(variables).map((identifier) => ({
        title: variables[identifier].label,
        value: identifier,
        ...(variables[identifier].type in VARIABLE_EXTRA_PROPERTIES
          ? {
              menu: VARIABLE_EXTRA_PROPERTIES[variables[identifier].type].map((prop) => ({
                title: `${variables[identifier].label} ${t(`forms.${prop}.label`)}`,
                value: `${identifier}.${prop}`,
              })),
            }
          : {}),
      }))
    : [];

  return (
    <Editor
      apiKey={import.meta.env.VITE_TINY_MCE_API_KEY}
      onInit={(evt, editor) => {
        editorRef.current = editor;
      }}
      onEditorChange={(updatedEditorString) => {
        setValue(updatedEditorString);
      }}
      initialValue={initialValue}
      init={{
        height: 500,
        branding: false,
        plugins: [
          "advcode",
          "advlist",
          "advtable",
          "anchor",
          "autocorrect",
          "autolink",
          "autosave",
          "casechange",
          "charmap",
          "checklist",
          "codesample",
          "directionality",
          "editimage",
          "emoticons",
          "export",
          "footnotes",
          "formatpainter",
          "help",
          "image",
          "insertdatetime",
          "link",
          "linkchecker",
          "lists",
          "media",
          "mediaembed",
          "mergetags",
          "nonbreaking",
          "pagebreak",
          "permanentpen",
          "powerpaste",
          "searchreplace",
          "table",
          "tableofcontents",
          "tinymcespellchecker",
          "typography",
          "visualblocks",
          "visualchars",
          "wordcount",
        ],
        toolbar:
          "bold italic underline forecolor backcolor | " +
          "align lineheight checklist bullist numlist | " +
          "link image mergetags | " +
          "indent outdent | " +
          "undo redo spellcheckdialog  | " +
          "blocks fontfamily fontsize | " +
          "removeformat typography",
        mergetags_list: mergeTagsList,
        mergetags_prefix: "{{",
        mergetags_sufix: "}}",
        browser_spellcheck: true,
        toolbar_sticky: true,
        content_style: `
          body {
            background: #fff;
          }

          .tox-tinymce-aux {
            z-index: 2000;
          }
  
          @media (min-width: 840px) {
            html {
              background: #eceef4;
              min-height: 100%;
              padding: 0 .5rem;
            }
  
            body {
              background-color: #fff;
              box-shadow: 0 0 4px rgba(0, 0, 0, .15);
              box-sizing: border-box;
              margin: 1rem auto 0;
              max-width: 820px;
              min-height: calc(100vh - 1rem);
              padding:4rem 6rem 6rem 6rem;
            }
          }
        `,
      }}
    />
  );
}

export default TinyMCERichTextEditor;
