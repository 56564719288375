/* eslint-disable no-console */
import type { DefaultContext } from "@apollo/client";
import { ApolloClient, ApolloLink, concat, createHttpLink, from } from "@apollo/client";

import cache from "./cache";

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key: string, value: unknown) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename); // eslint-disable-line
  }
  return forward(operation).map((data) => data);
});

const operationNameLink = new ApolloLink((operation, forward) => {
  const cypressAuth0Token = window.localStorage.getItem("cypressAuth0Token");
  operation.setContext(({ headers }: DefaultContext) => ({
    headers: {
      "x-gql-operation-name": operation.operationName,
      ...(cypressAuth0Token ? { Authorization: cypressAuth0Token } : {}),
      ...headers,
    },
  }));
  return forward(operation);
});

export function getClient({
  httpLink,
  authLink,
  errorLink,
  graphQLEndpoint,
}: {
  httpLink?: ApolloLink;
  authLink?: ApolloLink;
  errorLink?: ApolloLink;
  graphQLEndpoint: string;
}) {
  const backendGraphQLHttpLink = createHttpLink({
    uri: graphQLEndpoint,
  });

  return new ApolloClient({
    connectToDevTools: true,
    cache,
    link:
      errorLink && authLink
        ? from([cleanTypeName, errorLink, authLink, httpLink || backendGraphQLHttpLink])
        : concat(operationNameLink, httpLink || backendGraphQLHttpLink),
  });
}
