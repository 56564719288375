import { useFlowEditor } from "@flows-platform/context/FlowEditor";
import {
  FlowStepTypeEnum,
  useTaskQuery,
  useUpdateTaskClientMutation,
} from "@flows-platform/generated/graphql";
import { FlowStepObserver } from "@flows-platform/modules/Shared/hooks/useFlowStepUpdater";
import useFormObserver from "@flows-platform/modules/Shared/hooks/useFormObserver";
import TaskEditor from "@flows-platform/modules/TemplateEditor/components/FlowEditorDrawer/components/FlowStepTemplateRenderer/components/TaskContainer/TaskEditor";
import FlowStepResourceLoaderContainer from "@flows-platform/modules/TemplateEditor/components/FlowStepResourceLoaderContainer";
import omitFields from "@flows-platform/utils/omitFields";
import setItemPosition from "@flows-platform/utils/setItemPosition";
import useDefaultToasts from "@kwest_fe/core/src/modules/Shared/hooks/useDefaultToasts";
import { useTranslation } from "react-i18next";

import { useFlowEditorDrawer } from "../../../../providers/FlowEditorDrawerProvider";

interface TaskContainerProps {
  initialFormValuesLoaded: boolean;
}

function TaskContainer({ initialFormValuesLoaded }: TaskContainerProps) {
  const { flowStep } = useFlowEditorDrawer() || {};
  const { updateActiveFlowStep } = useFlowEditor();
  const { successToast, errorToast } = useDefaultToasts();
  const { t } = useTranslation();

  const [updateTask] = useUpdateTaskClientMutation({
    onCompleted(data) {
      const { flow, ...updatedFlowStep } = data?.updateTask?.task?.flowStep || {};
      updateActiveFlowStep(updatedFlowStep);
      successToast(t("pages.flow_step.toast.update_task.success.title"));
    },
    onError: errorToast(t("pages.flow_step.toast.update_task.error.title")),
  });

  useFormObserver(
    new FlowStepObserver({
      fields: ["task"],
      flowStepType: FlowStepTypeEnum.Task,
      async onChanged(modifiedValues) {
        if (!flowStep?.taskId) return null;
        const { task } = modifiedValues;
        const items = setItemPosition(task?.orderedSubtasks, true);

        const connectedFlowId = task?.connectedFlow?.id;
        const connectedFlowInitialVariableValues = JSON.stringify(
          task?.connectedFlowInitialVariableValues
        );

        // If we separate fragments, we dont need to do this
        const taskClone = { ...task };
        delete taskClone?.connectedFlow;
        delete taskClone?.connectedFlowInitialVariableValues;

        return updateTask({
          variables: {
            input: {
              ...taskClone,
              orderedSubtasks: task?.orderedSubtasks && items,
              id: flowStep?.taskId,
              connectedFlowId: connectedFlowId || null,
              connectedFlowInitialVariableValues,
            },
          },
        });
      },
    })
  );

  return (
    <FlowStepResourceLoaderContainer
      query={useTaskQuery}
      initialValuesKey="task"
      queryOptions={{ variables: { taskId: flowStep?.taskId }, fetchPolicy: "cache-and-network" }}
      loadingErrorMessage="Error loading task"
      resolveInitialValue={(data) => ({
        ...(data?.getTask &&
          omitFields(data?.getTask, [
            "receiver",
            "receiverTeam",
            "journeyStep",
            "connectedJourneysId",
          ])),
        connectedFlowInitialVariableValues: JSON.parse(
          data?.getTask?.connectedFlowInitialVariableValues || "{}"
        ),
      })}
      initialFormValuesLoaded={initialFormValuesLoaded}
    >
      <TaskEditor />
    </FlowStepResourceLoaderContainer>
  );
}

export default TaskContainer;
