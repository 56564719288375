import { Text } from "@chakra-ui/react";
import type { PropsWithChildren } from "react";

export default function CaptionText({ children }: PropsWithChildren) {
  return (
    <Text mt={2} fontSize="sm" color="gray.500">
      {children}
    </Text>
  );
}
