import { Box, Flex, VStack } from "@chakra-ui/react";
import type { GetProjectStepsQuery, OneJourneyQuery } from "@flows-platform/generated/graphql";
import ProjectContextMenu from "@flows-platform/modules/ProjectInstance/components/ProjectContextMenu/ProjectContextMenu";
import ProjectMetadata from "@flows-platform/modules/ProjectInstance/components/ProjectMetadata";
import ProjectSteps from "@flows-platform/modules/ProjectInstance/ProjectsOverview/components/ProjectsTable/components/ProjectsTableRow/components/ProjectSteps/ProjectSteps";
import { ProjectStepsProvider } from "@flows-platform/modules/Shared/providers/ProjectStepContext";
import type { LayoutReducerAction } from "@flows-platform/modules/Shared/UI/layout/LayoutProvider";
import { useLayout } from "@flows-platform/modules/Shared/UI/layout/LayoutProvider";
import MotionBox from "@flows-platform/modules/Shared/UI/layout/MotionBox";
import PageHeader from "@kwest_fe/core/src/components/UI/layout/PageHeader/PageHeader";
import usePageTitle from "@kwest_fe/core/src/hooks/usePageTitle";
import { AnimatePresence } from "framer-motion";

import PageWrapper from "../Shared/UI/layout/PageWrapper";

interface ProjectInstancePageProps {
  project?: OneJourneyQuery["oneJourney"];
  projectSteps: GetProjectStepsQuery["getProjectSteps"];
  onUpdate: () => Promise<void>;
}

export default function ProjectInstancePage({
  projectSteps,
  onUpdate,
  project,
}: ProjectInstancePageProps) {
  const { state, dispatch } = useLayout();

  usePageTitle(project?.instanceName ?? project?.flow.name ?? "Project Instance");

  if (!projectSteps || !project) return null;

  return (
    <PageWrapper height="100vh">
      <VStack w="full" spacing={0} h="full">
        <PageHeader
          toggleSidebar={(toggleString: LayoutReducerAction) => {
            dispatch({ type: toggleString });
          }}
          title={project.instanceName ?? project.flow.name}
          hasRightSidebar
          headerContent={
            project && (
              <ProjectContextMenu
                projectId={project.id}
                isArchived={project.isArchived}
                isPaused={!!project.isPaused}
              />
            )
          }
        />
        <Box flexGrow={1} w="full" overflow="hidden">
          <Flex flexDirection="row" width="100%" h="full">
            <Box
              height="full"
              overflowY={"auto"}
              w={"100%"}
              borderRightWidth={1}
              borderRightColor="#E2E8F0"
            >
              <ProjectStepsProvider projectId={project.id} onUpdate={onUpdate}>
                <ProjectSteps projectSteps={projectSteps} />
              </ProjectStepsProvider>
            </Box>
            <Box width="45%" display={{ base: "none", lg: "flex" }}>
              <ProjectMetadata project={project} projectSteps={projectSteps} />
            </Box>
            <AnimatePresence>
              {state.isRightSidebarOpen && (
                <MotionBox
                  initial={{ x: window.innerWidth }}
                  animate={{ x: 0 }}
                  exit={{ x: window.innerWidth }}
                  transition={"ease-in-out"}
                  flexGrow={1}
                  width="100%"
                  position="fixed"
                  right={0}
                  bg={"white"}
                  zIndex={10}
                  display={{ base: "flex", lg: "none" }}
                  h={"100%"}
                >
                  {project && <ProjectMetadata project={project} projectSteps={projectSteps} />}
                </MotionBox>
              )}
            </AnimatePresence>
          </Flex>
        </Box>
      </VStack>
    </PageWrapper>
  );
}
