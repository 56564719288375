import type { ImageProps } from "@chakra-ui/react";
import { Box, Image, VStack } from "@chakra-ui/react";

import { CancelIcon } from "../../../theme/icons";
import LabelledIconButton from "../../UI/atoms/Buttons/LabelledIconButton";

export interface ImageViewerProps extends ImageProps {
  images: string[];
  boxWidth?: string;
  onDeleteFile?: (index: number) => void;
  deleteControl?: JSX.Element;
}

interface SingleImageViewProps extends Omit<ImageViewerProps, "images"> {
  imageUrl: string;
  deleteIndex: number;
}

function SingleImageView({
  imageUrl,
  boxWidth,
  deleteIndex,
  onDeleteFile,
  deleteControl,
  ...restProps
}: SingleImageViewProps) {
  return (
    <Box width={boxWidth} position="relative">
      {deleteControl ||
        (onDeleteFile && (
          <LabelledIconButton
            aria-label="Delete Image"
            position="absolute"
            top={5}
            right={5}
            bgColor={"white"}
            border="1px solid tako.input.border"
            color={"tako.input.border"}
            icon={<CancelIcon />}
            onClick={() => {
              onDeleteFile?.(deleteIndex);
            }}
          />
        ))}
      <Image src={imageUrl} {...restProps} />
    </Box>
  );
}

function ImageViewer({ images, ...restProps }: ImageViewerProps) {
  return (
    <VStack>
      {images.map((image, index) => (
        <SingleImageView key={index} imageUrl={image} deleteIndex={index} {...restProps} />
      ))}
    </VStack>
  );
}

export default ImageViewer;
